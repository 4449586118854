$Poppins: 'Poppins', sans-serif;

#attendance_wrapper{
    padding: 20px 20px 7px !important;

    .initial{
        align-items: center;
        border-radius: 2px;
        display: flex;
        font: 9px/14px $Poppins;
        height: 14px;
        justify-content: center;
        width: 14px;

        &.green{
            background: rgba(84, 204, 176, 0.4);
            color: #05DA80;
        }

        &.orange{
            background: rgba(255, 198, 86, 0.4);
            color: #FFC656;
        }

        &.dark_purple{
            background: rgba(252, 187, 230, 0.3);
            color: rgba(255, 188, 232, 0.88);
        }

        &.red{
            background: rgba(217, 75, 75, 0.4);
            color: #F56B6B;
        }
    }

    .attendance_header{
        align-items: center;
        border-bottom: 1px solid #333333;
        display: flex;
        justify-content: flex-start;
        margin-bottom: 7px;
        padding-bottom: 18px;

        p{
            color: #fff;
            font: 12px/16px $Poppins;
            font-weight: 400;
            margin: 0;

            >span{
                font-weight: 600;
            }
        }

        .student_attendance_status_list{
            align-items: center;
            display: flex;
            justify-content: flex-start;
            list-style: none;
            margin: 0 0 0 10px;
            padding: 0;

            li{
                align-items: center;
                display: flex;
                justify-content: flex-start;
                margin-right: 10px;

                span{
                    &:last-child{
                        color: #fff;
                        font-weight: 600;
                        font: 12px/16px $Poppins;
                        margin-left: 5px;
                    }
                }
            }
        }
    }

    .student_profile_table{
        tbody{
            tr{
                td{
                    &:first-child{
                        width: 180px;
                    }
                }
            }
        }
    }
}