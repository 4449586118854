#create_event_modal, #edit_event_modal{
    font-family: "Poppins", sans-serif;
    max-width: 400px;

    button:focus{
        outline: 5px transparent;
    }

    .modal-content{
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), #121212;
        border: none;
        box-sizing: border-box;

        .modal-header{
            background: linear-gradient(0deg, rgba(255, 255, 255, 0.14), rgba(255, 255, 255, 0.14)), #121212;
            border: none;
            color: rgba(255, 255, 255, 0.88);
            font-family: "Poppins", sans-serif;
            font-size: 14px;
            font-weight: 700;
            padding: 16px 25px 14px;
            position: relative;

            .close_button{
                background: url("https://assets.codingdojo.com/learn_platform/admin/course_calendar/course_calendar_icon.png") no-repeat -66px -5px;
                border: none;
                display: inline-block;
                height: 14px;
                position: absolute;
                right: 23px;
                top: 19px;
                width: 14px;
            }
        }

        form{
            .modal-body{
                padding: 20px 24px 32px 26px;

                p{
                    color: rgba(255, 255, 255, 0.88);
                    font-family: "Poppins", sans-serif;
                    font-size: 14px;
                    margin-bottom: 0;
                }

                .selected_multi_item{
                    display: inline-block;
                    .remove{
                        background: url("https://assets.codingdojo.com/learn_platform/admin/global/dropdown_icons.png") no-repeat -24px -4px;
                        border: none;
                        cursor: pointer;
                        display: inline-block;
                        height: 12px;
                        margin-left: 9.17px;
                        margin-top: -1px;
                        outline: none;
                        position: absolute;
                        right: 10px;
                        top: 10px;
                        vertical-align: middle;
                        width: 12px;
                    }
                }

                label{
                    color: rgba(255, 255, 255, 0.4);
                    font-family: "Poppins", sans-serif;
                    font-size: 12px;
                    font-weight: 600;
                    margin-bottom: 9px;
                    margin-top: 20px;
                    &.select_program_label{
                        margin-top: 0px;
                    }
                    &[for=event_type]{
                        display: block;
                    }
                }

                .react-dropdown-select{
                    border-radius: 4px;
                    font-family: "Poppins", sans-serif;
                    height: 40px;

                    .react-dropdown-select-content{
                        color: rgba(255, 255, 255, 0.58);
                        font-family: "Poppins", sans-serif;
                        font-size: 14px;
                        font-weight: 400;
                    }
                }

                .react-dropdown-select.has_value {
                    background: rgba(204, 224, 255, 0.14);
                    border: none !important;
                    padding: 2px 37px 2px 11px !important;
                    
                    .react-dropdown-select-content{
                        color: rgb(255, 255, 255);
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }

                    .custom_dropdown_handle{
                        position: absolute;
                        right: 12px;
                    }
                }

                .react-dropdown-select-dropdown{
                    top: 42px;
                    width: calc(100% + 2px);
                }

                .custom_dropdown_handle{
                    border-left: 4px solid transparent;
                    border-right: 4px solid transparent;
                    border-top: 4px solid rgba(129, 160, 234, 1);
                    height: 0;
                    margin-right: 2px;
                    position: absolute;
                    right: 13px;
                    width: 0;
            
                    &.upward{
                        margin: 0;
                        position: absolute;
                        right: 39px;
                        top: 33px;
                        transform: rotate(180deg);
                    }
                }

                #event_duration{
                    background-color: rgba(204, 224, 255, 0.14);
                    border-radius: 4px;
                    padding: 8px 0px 8px 11px;

                    .react-datepicker-popper{
                        top: -1px !important;
                        left: -11px !important;
                    }

                    .calendar_icon, 
                    .date_pickers, 
                    .arrow_left_icon{
                        opacity: .58;
                    }

                    &.has_start_date .calendar_icon, 
                    &.has_start_date .date_pickers:nth-child(2),
                    &.has_end_date .arrow_left_icon, 
                    &.has_end_date .date_pickers:nth-child(4){
                        opacity: 1;
                    }
                }

                #cohort_checkbox{
                    color: rgba(255, 255, 255, 0.38);
                    cursor: pointer;
                    font-size: 14px;
                    font-weight: 400;
                    padding-left: 27px;
                    position: relative;
                    user-select: none;

                    &::before{
                        background-color: white;
                        border-radius: 4px;
                        border: 1px solid #A4B0C0;
                        content: "";
                        height: 15px;
                        left: 0;
                        position: absolute;
                        top: 3px;
                        width: 15px;
                    }

                    input[type="checkbox"]{
                        display: none;
                    }

                    svg{
                        color: white;
                        display: none;
                        left: 3px;
                        position: absolute;
                        top: 3px;
                        width: 9px;
                    }

                    &:has(input:checked){
                        &::before{
                            background-color: #81A0EA;
                            border: none;
                        }

                        svg{
                            display: block;
                        }
                    }
                }
            }
        }

        .modal-footer{
            background: linear-gradient(0deg, rgba(255, 255, 255, 0.14), rgba(255, 255, 255, 0.14)), #121212;
            border: none;
            padding: 16px 20px 15px;
            
            button{
                background: rgba(204, 224, 255, 0.14);
                border-radius: 4px;
                border: none;
                color: #81a0ea;
                font: 600 14px "Poppins", sans-serif;
                padding: 10px 16px;
                &[type="button"]{
                    background: transparent;
                    border: none;
                    color: #fff;
                    outline: none;
                }

                &.disabled{
                    pointer-events: none;
                }
            }
        }
    }
}