#course_container.container {

    .left_container.collapse_sidebar ~ .right_container{
        .content{
            .slide_ratings_list{
                label{
                    .rating_label{
                        left: -24px;
                    }
                }
            }
        }
    }
    .right_container {
        .content {
            .slide_ratings_list{
                display: flex;
                margin-bottom: 102px;
                margin-top: 29px;
                position: relative;

                input[type=radio]{
                    display: none;
                }
                input[type=radio]:checked ~ .rating_value{
                    background-color: #2C6BFF !important;   
                    color: #fff;
                }
            
                label{
                    color: #152C61;        
                    cursor: pointer;
                    flex: 1 1 0;
                    font: normal 300 16px/24px 'Poppins', sans-serif;
                    margin-bottom: 0px;    
                    margin-right: 1px;
                    position: relative;
                    text-align: center;

                    &:first-child{
                        padding-left: 0px;
                        text-align: left;
                        .rating_value{
                            border-radius: 5px 0px 0px 5px;
                        }

                        .rating_label{
                            left: 0;
                            text-align: left;
                        }
                    }

                    &:nth-child(3){
                        .rating_label{
                            left: -37px;
                        }
                    }

                    &:nth-child(6){
                        .rating_label{
                            left: -37px;
                        }
                    }

                    &:nth-child(9){
                        .rating_label{
                            left: -37px;
                        }
                    }
                    &:last-child{
                        margin-right: 0px;
                        padding-right: 0px;
                        text-align: right;
                        .rating_value{
                            border-radius: 0px 5px 5px 0px;
                        }

                        .rating_label{
                            left: -72px;
                            text-align: right;
                        }
                    }
            
                    .rating_value{
                        align-items: center;
                        background: #E5E5E5 !important;
                        border-radius: 0;
                        color: #152C61;
                        display: flex;
                        font-family: 'Poppins', sans-serif;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 300;
                        height: 44px;
                        justify-content: center;
                        line-height: 24px;
                        width: 100%;
                    }

                    .rating_label{
                        font-family: 'Poppins', sans-serif;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 300;
                        line-height: 24px;
                        margin-top: 19px;
                        position: absolute;
                        text-align: center;
                        width: 140px;
                    }
                }
            }
        }
    } 
}