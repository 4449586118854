#student_accommodation_records{
    padding: 7px 20px 20px 20px !important;
    table{
        tbody{
            tr{
                td:nth-child(1){
                    button{
                        background-color: transparent;
                        border: none;   
                        box-shadow: none;
                        color: #7099E4 !important;

                        &:hover{
                            opacity: .8;
                        }
                    }
                     
                }
            }
        }
    }
}