$Poppins: "Poppins", sans-serif;

#holiday_tooltip{
    .arrow{
        display: none;
    }

    .tooltip-inner{
        align-items: center;
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.24), rgba(255, 255, 255, 0.24)), rgba(6, 6, 6, 0.94);
        border-radius: 4px;
        border: 1px solid rgba(204, 224, 255, 0.14);
        box-sizing: border-box;
        display: flex;
        flex-wrap: wrap;
        max-width: 350px;
        padding: 18px 16px;
        width: 350px;

        p{
            margin-bottom: 10px;
            text-align: left;
            &:last-child{
                margin-bottom: 0px;
            }
            span{
                font-weight: bold;
            }
        }
    }
}
.table_container#stack_table{
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.24), rgba(255, 255, 255, 0.24)), #121212;

    .hidden{
        display: none !important;
    }
    #table_navigation{
        background:#121212;
        display: flex;
        list-style: none;
        margin: 0px;
        padding: 0px;
        position: relative;
        
        li{
            align-items: center;
            display: flex;

            &.active{
                #asignment_filter_dropdowns{
                    opacity: 1;
                    pointer-events: initial;
                }
            }

            &:first-child{
                >button{
                    margin-right: 15px;
                }
            }

            &.active{
                button{
                    border-bottom: 2px solid #81A0EA;
                    color: #81A0EA;
                    font-weight: 600;
                }

                .checkbox_label{
                    &:after{
                        color: rgba(255, 255, 255, 0.88);
                    }
                }
            }
            >button{
                background-color: transparent;
                border-bottom: 2px solid transparent;
                border: none;
                color: rgba(255, 255, 255, 0.48);
                display: flex;
                font-family: $Poppins;
                font-size: 12px;
                font-style: normal;
                font-weight: normal;
                justify-content: space-between;
                line-height: 130%;
                outline: none;
                padding: 8px 16px;

                span{
                    background-image: url("https://assets.codingdojo.com/learn_platform/admin/student_progress/table_navigation_icons.png");
                    background-repeat: no-repeat;
                    display: flex;
                    height: 16px;
                    margin-right: 12px;
                    width: 16px;
                }

                .checklist_icon{
                    background-position: -4px -4px;
                }

                .calendar_icon{
                    background-position: -28px -4px;
                }
            }
            /* checkbox */
            input[type="checkbox"]{
                display: none;
                &:checked ~ span{
                    background: #81A0EA;
                }
                &:checked ~ span{
                    svg{
                        opacity: 1;
                    }
                }
            }
            .checkbox_label{
                cursor: pointer;
                display: block;
                margin-bottom: 0px;
                margin-right: 20px;
                position: relative;

                &:after{
                    color: rgba(255, 255, 255, 0.48);
                    content: attr(data-text);
                    font-family: $Poppins;
                    font-size: 12px;
                    margin-left: 6px;
                    vertical-align: middle;
                }
                &[disabled]{
                    cursor: not-allowed;
                    opacity: .3;
                }
                span{
                    align-items: center;
                    background: rgba(204, 224, 255, 0.14);
                    border-radius: 4px;
                    cursor: pointer;
                    display: inline-flex;
                    height: 15px;
                    justify-content: center;
                    vertical-align: middle;
                    width: 15px;

                    svg{
                        font-size: 9px;
                        left: 3px;
                        opacity: 0;
                        position: absolute;
                        top: 6px;
                    }
                }
            }
            #asignment_filter_dropdowns{
                display: flex;
                >div{
                    margin-right: 12px;
                }

                .react-dropdown-select{
                    background-color: transparent;
                    border: none !important;
                    margin: 0px;
                    max-width: inherit;
                    min-height: 22px !important;
                    padding: 0px !important;

                    &[aria-expanded="true"]{
                        .react-dropdown-select-dropdown-handle{
                            margin-left: 0px;
                            margin-top: -2px;
                        }
                    }

                    .react-dropdown-select-content{
                        color: rgba(255, 255, 255, 0.88);
                        font-family: $Poppins;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: normal;
                        margin-right: 4px;
                        
                        .react-dropdown-select-input{
                            display: none;
                        }
                    }

                    .react-dropdown-select-dropdown-handle{
                        color: #81A0EA;
                        margin: 0px;
                    }

                    .react-dropdown-select-dropdown{
                        background: linear-gradient(0deg, rgba(255, 255, 255, 0.24), rgba(255, 255, 255, 0.24)), #121212;
                        border-radius: 6px;
                        border: 1px solid #81A0EA;
                        box-sizing: border-box;
                        min-width: 160px;
                        
                        span{
                            border: none;
                            color: rgba(255, 255, 255, 0.88);
                            font-family: $Poppins;
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 18px;
                            padding: 10px 15px;
                            position: relative;

                            &.react-dropdown-select-item-selected{
                                background-color: transparent;

                                &:after{
                                    color: #81A0EA;
                                    content: "\2714";
                                    position: absolute;
                                    right: 16px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .sticky-table{
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.24), rgba(255, 255, 255, 0.24)), #121212;
        height: calc(100% - 34px);

        .sticky-table-table{
            height: 100%;
            .sticky-table-row{ 
                /* Table HEAD */ 
                &:nth-child(1){
                    background: #4b4b4b;
                    position: sticky;
                    top: 0px;
                    z-index: 3;
                    .sticky-table-cell{
                        border-bottom: 1px solid #5D5D5D;
                        color: rgba(255, 255, 255, 0.6);
                        font-family: $Poppins;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 130%;
                        padding-top: 12px;

                        &:last-child{
                            border: none;
                            display: flex;
                            left: 136px;
                            min-width: 381px;
                            padding: 14px 0px 11px 0px;
                            position: sticky;
                        }

                        &:nth-child(1), &:nth-child(2){
                            background: linear-gradient(0deg, rgba(255, 255, 255, 0.24), rgba(255, 255, 255, 0.24)), #121212;
                        }

                        #name_sort_table_head{
                            align-items: center;
                            display: flex;

                            #name_sort_icon{
                                display: flex;
                                flex-direction: column;
                                justify-content: center;

                                svg{
                                    color: #B2B2B2;

                                    &.light{
                                        color: #81A0EA;
                                    }

                                    &:first-child{
                                        margin-bottom: -1px;
                                    }
                                    &:last-child{
                                        margin-top: -1px;
                                    }
                                }
                            }
                        }
                    }

                    #weeks_tab_list{
                        display: flex;
                        margin: 0px 0px 0px 112px;

                        li{
                            position: relative;
                            margin-right: 30px;

                            &:last-child{
                                margin-right: 0px;
                            }

                            &.active{
                                &:after{
                                    background-color: #81A0EA;
                                    bottom: -11px;
                                    content: '';
                                    display: flex;
                                    height: 2px;
                                    left: 0;
                                    position: absolute;
                                    width: 100%;
                                }
                                button{
                                    color: #81A0EA;
                                    font-weight: bold;
                                }
                            }
                            button{
                                background-color: transparent;
                                border: none;
                                color: rgba(255, 255, 255, 0.88);
                                font-family: $Poppins;
                                font-size: 12px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: 130%;
                                outline: none;
                            }
                        }
                    }
                }
                &:nth-child(2){
                    .sticky-table-cell{
                        background: #333333;
                        border-bottom: 1px solid #5D5D5D;
                        color: rgba(255, 255, 255, 0.6);
                        font-family: $Poppins;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 500;
                        border-top: none;
                        padding-bottom: 0px;
                        height: 30px;
                        padding-top: 0px;

                        &:last-child{
                            background: linear-gradient(0deg, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.3)), #121212;
                            z-index: 8;
                        }
                        
                        &:nth-child(1), &:nth-child(2){
                            z-index: 105;
                        }

                        &:nth-child(3){
                            left: -263px;
                            position: sticky;
                            z-index: 9;
                        }
                        #table_head_days_list{
                            display: flex;
                            margin-bottom: 0px;
                            margin-left: 100px;

                            li{
                                align-items: center;
                                color: rgba(255, 255, 255, 0.58);
                                display: flex;
                                font-family: $Poppins;
                                font-size: 12px;
                                font-style: normal;
                                font-weight: normal;
                                justify-content: center;
                                margin-right: 20px;
                                width: 85px;

                                time{
                                    margin-left: 5px;
                                }

                                button{
                                    align-items: center;
                                    background: transparent;
                                    border: none;
                                    cursor: pointer;
                                    display: flex;
                                    margin-left: 2px;
                                    outline: none;
                                
                                    span{
                                        align-items: center;
                                        color: #81A0EA;
                                        display: flex;
                                        font-family: "Poppins", sans-serif;
                                        font-size: 14px;
                                        font-weight: 500;
                                        height: 15px;
                                        margin-left: 0px;
                                        position: relative;
                                        width: 15px;
                                        &.plane_icon{
                                            background-color: transparent;
                                            background-image: url("https://codingdojo_assets.s3.us-east-1.amazonaws.com/learn_platform/admin/student_progress/holiday_icon.png");
                                            background-position: 0px 1px;
                                            background-repeat: no-repeat;
                                            margin-right: 4px;
                                            width: 13px;
                                        }
                                        &.is_holiday{
                                            background-position: -14px 1px;
                                        }
                                    }
                                }

                                time{
                                    margin-left: 5px;
                                }
                            }
                        }
                        
                    }
                }
                /* Table BODY */
                &:nth-child(n+3){
                    .sticky-table-cell{
                        border-bottom: 1px solid rgba(255,255,255, .1);
                        border-top: 0px;

                        &:nth-child(1), &:nth-child(2){
                            background: linear-gradient(0deg, rgba(255, 255, 255, 0.24), rgba(255, 255, 255, 0.24)), #121212;
                        }
                        
                        &.attendance_row{
                            background: linear-gradient(0deg, rgba(255, 255, 255, 0.34), rgba(255, 255, 255, 0.34)), #121212;
                        
                            .attendance_row_container{
                                align-items: center;
                                display: flex;

                                >div{
                                    margin-right: 20px;
                                    width: 80px;

                                    span{
                                        color: rgba(255, 255, 255, 0.58);
                                        display: block;
                                        font-family: $Poppins;
                                        font-size: 12px;
                                        font-style: normal;
                                        font-weight: normal;
                                        line-height: 130%;
                                        margin-right: 5px;
                                    }
                                }

                                .week_progress_list{
                                    align-items: center;
                                    display: flex;
                                    margin-bottom: 0px;
                                
                                    .days_column{
                                        display: flex;
                                        flex-wrap: wrap;
                                        margin-right: 20px;
                                        width: 85px;
                                      
                                        &:last-child{
                                            margin-right: 0px;
                                        }
                                
                                        >span{
                                            color: rgba(255, 255, 255, 0.58);
                                            display: flex;
                                            font-family: $Poppins;
                                            font-size: 12px;
                                            font-style: normal;
                                            font-weight: normal;
                                            line-height: 130%;
                                            margin-bottom: 8px;
                                            width: 100%;
                                        }
                                
                                        .attendance_list{
                                            align-items: center;
                                            display: flex;
                                            justify-content: flex-start;
                                            flex-wrap: wrap;
                                
                                            li{
                                                align-items: center;
                                                background: rgba(204, 224, 255, 0.14);
                                                border-radius: 4px;
                                                cursor: pointer;
                                                display: flex;
                                                font-family: $Poppins;
                                                font-size: 12px;
                                                font-style: normal;
                                                font-weight: bold;
                                                height: 20px;
                                                justify-content: center;
                                                margin-right: 6px;
                                                text-transform: uppercase;
                                                width: 20px;
                                
                                                &:nth-child(n+4){
                                                    margin-top: 6px;
                                                }

                                                &:last-child{
                                                    margin-right: 0px;
                                                }
                                
                                                &.present{
                                                    background: rgba(84, 204, 176, 0.4);
                                                    color: #05DA80;
                                                }
                                                &.absent{
                                                    background: rgba(217, 75, 75, 0.4);
                                                    color: #F56B6B;
                                                }
                                                &.late{
                                                    background: rgba(255, 198, 86, 0.4);
                                                    color: #FFC656;
                                                }
                                                &.excused{
                                                    background: rgba(252, 187, 230, 0.3);
                                                    color: rgba(255, 188, 232, 0.88);
                                                }
                                            }
                                        }
                                
                                        .core_assignments_list, .practice_assignments_list, .optional_assignments_list{
                                            display: flex;
                                            flex-wrap: wrap;
                                            width: 104px;

                                            &.practice_assignments_list{
                                                li{
                                                    border-radius: 100%;
                                                }
                                            }
                                
                                            &.optional_assignments_list{
                                                margin-bottom: 0px;
                                                
                                                li{
                                                    background-color: transparent !important;
                                                    border-radius: 100%;
                                                    border: 2px solid rgba(255, 255, 255, 0.18);
                                                }
                                            }
                                
                                            li{
                                                background: rgba(255, 255, 255, 0.18);
                                                border-radius: 2px;
                                                cursor: pointer;
                                                height: 10px;   
                                                margin-right: 16px;
                                                width: 10px;
                                                margin-bottom: 6px;
                                
                                                &:last-child{
                                                    margin-right: 0px;
                                                }
                                                
                                                &.completed{
                                                    background: #05DA80;
                                                    border-color: #05DA80;
                                                }
                                                &.late{
                                                    background: #F59D6B;
                                                    border-color: #F59D6B;
                                                }
                                                &.missed, &.need_review{
                                                    background: #F56B6B;
                                                    border-color: #F56B6B;
                                                }
                                                &.no_status{
                                                    background: rgba(255, 255, 255, 0.18);
                                                    border-color: rgba(255, 255, 255, 0.18);
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        .accommodation_label{
                            align-items: center;
                            background: #FF6E1B;
                            border-radius: 4px;
                            color: #E4E4E4;
                            display: flex;
                            height: 20px;
                            justify-content: center;
                            width: 21px;
                        }

                        .view_time_display_btn{
                            background-color: transparent;
                            background-image: url("./../../../../assets/images/admin/table_icons.png");
                            background-position: 64px;
                            border: none;
                            height: 16px;
                            margin-left: 4px;
                            outline: none;
                            padding: 0px;
                            width: 16px;
                        }
                    }
                }
            }

            #table_loading_container{
                background: linear-gradient(0deg, rgba(255, 255, 255, 0.24), rgba(255, 255, 255, 0.24)), #121212;
            }
        }
    }
}

.readiness_flag_tooltip.tooltip{
    .arrow:before{
        border-left-color: #4B4B4B;
        border-top-color: transparent !important;
    }
}

#customize_columns_popover{
    background: #333333;
    border-radius: 2px;
    border: 1px solid #81A0EA;
    margin-top: 0px !important;
    width: 300px;

    .arrow{
        display: none;
    }

    .popover-body{
        padding: 20px;

        h5{
            color: #FFFFFF;
            font-family: $Poppins;
            font-size: 12px;
            font-style: normal;
            font-weight: 600;
            line-height: 130%;
        }

        ul{
            margin-bottom: 0px;
            max-height: calc(100vh - 300px);
            overflow-y: auto;
            li{
                align-items: center;
                background: #2E2E2E;
                border-radius: 2px;
                border: 1px solid rgba(178, 178, 178, 0.2);
                color: #FFFFFF;
                display: flex;
                font-family: $Poppins;
                font-size: 12px;
                font-style: normal;
                font-weight: 600;
                justify-content: space-between;
                line-height: 130%;
                margin-bottom: 10px;
                padding: 10px 20px;

                &:last-child{
                    margin: 0px;
                }

                .checkbox_button_container{
                    input{
                        display: none;
                    }

                    input:checked ~ label{
                        justify-content: flex-end;
                        .fill{
                            background: #81A0EA;
                        }
                    }

                    label{
                        align-items: center;
                        background: #121212;
                        border-radius: 48px;
                        cursor: pointer;
                        display: flex;
                        height: 16px;
                        margin-bottom: 0px;
                        padding: 0px 2px;
                        width: 32px;
                        
                        .fill{
                            background-color: #B2B2B2;
                            border-radius: 100%;
                            display: flex;
                            height: 12px;
                            width: 12px;
                        }
                    }
                }
            }
        }
    }
}