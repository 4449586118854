#alumni_pass_page_container{
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    max-width: 1055px;
    min-height: 100vh;
    padding: 50px;
    width: 90%;
}

@media only screen and (max-width: 625px){
    #alumni_pass_page_container{
        padding: 20px;
    }
}
