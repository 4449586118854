.belt_icon{
    background-image: url("https://assets.codingdojo.com/learn_platform/global/new_belts_v3.png");
    background-position: -375px -4138px;

    &.orange_belt{
        background-position: 0px  -4138px;
    }
    &.red_belt{
        background-position: -125px  -4138px;
    }
    &.black_belt{
        background-position: -247px  -4138px;
    }

    &.webfun_belt{
        background-position: -373px 0px;
        &.yellow_belt{
            background-position: -4px 0px;
        }
    }
    &.mean_belt{ 
        background-position: -373px -70px;
        &.black_belt{
            background-position: -251px -70px;
        }
        &.red_belt{
            background-position: -127px -70px;
        }
        &.orange_belt{
            background-position: -4px -70px;
        }
    }
    &.rails_belt{
        background-position: -373px -140px;
        &.black_belt{
            background-position: -251px -140px;
        }
        &.red_belt{
            background-position: -127px -140px;
        }
        &.orange_belt{
            background-position: -4px -140px;
        }
    }
    &.python_belt{
        background-position: -373px -210px;
        &.black_belt{
            background-position: -251px -210px;
        }
        &.red_belt{
            background-position: -127px -210px;
        }
        &.orange_belt{
            background-position: -4px -210px;
        }
    }
    &.c_net_belt{
        background-position: -373px -280px;
        &.black_belt{
            background-position: -251px -280px;
        }
        &.red_belt{
            background-position: -127px -280px;
        }
        &.orange_belt{
            background-position: -4px -280px;
        }
    }
    &.java_belt{
        background-position: -373px -350px;
        &.black_belt{
            background-position: -251px -350px;
        }
        &.red_belt{
            background-position: -127px -350px;
        }
        &.orange_belt{
            background-position: -4px -350px;
        }
    }
    &.mern_belt{
        background-position: -373px -420px;
        &.black_belt{
            background-position: -251px -420px;
        }
        &.red_belt{
            background-position: -127px -420px;
        }
        &.orange_belt{
            background-position: -4px -420px;
        }
    }
    &.lamp_belt{
        background-position: -373px -490px;
        &.black_belt{
            background-position: -250px -490px;
        }
        &.red_belt{
            background-position: -127px -490px;
        }
        &.orange_belt{
            background-position: -4px -490px;
        }
    }
    &.ios_belt{
        background-position: -373px -560px;
        &.black_belt{
            background-position: -246px -560px;
        }
        &.red_belt{
            background-position: -123px -560px;
        }
        &.orange_belt{
            background-position: 0px -560px;
        }
    }
    &.programming_basics_belt{
        background-position: -373px -630px;
        &.black_belt{
            background-position: -246px -630px;
        }
        &.red_belt{
            background-position: -123px -630px;
        }
        &.orange_belt{
            background-position: 0px -630px;
        }
        &.white_belt{
            background-position: 0px -4068px;
        }
    }
    &.blockchain_fundamentals_belt{
        background-position: -373px -700px;
        &.black_belt{
            background-position: -246px -700px;
        }
        &.red_belt{
            background-position: -123px -700px;
        }
        &.orange_belt{
            background-position: 0px -700px;
        }
    }
    &.part_time_data_science_belt{
        background-position: -373px -910px;
        &.black_belt{
            background-position: -246px -910px;
        }
        &.red_belt{
            background-position: -123px -910px;
        }
        &.orange_belt{
            background-position: 0px -910px;
        }
    }
    &.blockchain_belt{
        background-position: -373px -980px;
        &.black_belt{
            background-position: -246px -980px;
        }
        &.red_belt{
            background-position: -123px -980px;
        }
        &.orange_belt{
            background-position: 0px -980px;
        }
    }
    &.blockchain_practicals_belt{
        background-position: -373px -1050px;
        &.black_belt{
            background-position: -246px -1050px;
        }
        &.red_belt{
            background-position: -123px -1050px;
        }
        &.orange_belt{
            background-position: 0px -1050px;
        }
    }
    &.software_testing_beginner_belt{
        background-position: -373px -1120px;
        &.black_belt{
            background-position: -246px -1120px;
        }
        &.red_belt{
            background-position: -123px -1120px;
        }
        &.orange_belt{
            background-position: 0px -1120px;
        }
    }
    &.data_science_fundamentals_belt{
        background-position: -373px -1190px;
        &.black_belt{
            background-position: -246px -1190px;
        }
        &.red_belt{
            background-position: -123px -1190px;
        }
        &.orange_belt{
            background-position: 0px -1190px;
        }
    }
    &.data_science_machine_learning_belt{
        background-position: -373px -1260px;
        &.black_belt{
            background-position: -246px -1260px;
        }
        &.red_belt{
            background-position: -123px -1260px;
        }
        &.orange_belt{
            background-position: 0px -1260px;
        }
    }
    &.data_science_advanced_machine_learning_belt{
        background-position: -373px -1330px;
        &.black_belt{
            background-position: -246px -1330px;
        }
        &.red_belt{
            background-position: -123px -1330px;
        }
        &.orange_belt{
            background-position: 0px -1330px;
        }
    }
    &.unity_3d_belt{
        background-position: -373px -1400px;
        &.black_belt{
            background-position: -246px -1400px;
        }
        &.red_belt{
            background-position: -123px -1400px;
        }
        &.orange_belt{
            background-position: 0px -1400px;
        }
    }
    &.cybersecurity_core_belt{
        background-position: -373px -1540px;
        &.black_belt{
            background-position: -246px -1540px;
        }
        &.red_belt{
            background-position: -123px -1540px;
        }
        &.orange_belt{
            background-position: 0px -1540px;
        }
    }
    &.cybersecurity_intermediate_belt{
        background-position: -373px -1610px;
        &.black_belt{
            background-position: -246px -1610px;
        }
        &.red_belt{
            background-position: -123px -1610px;
        }
        &.orange_belt{
            background-position: 0px -1610px;
        }
    }
    &.cybersecurity_specialist_belt{
        background-position: -373px -1680px;
        &.black_belt{
            background-position: -246px -1680px;
        }
        &.red_belt{
            background-position: -123px -1680px;
        }
        &.orange_belt{
            background-position: 0px -1680px;
        }
    }
    &.iot_advanced_belt{
        background-position: -373px -1750px;
        &.black_belt{
            background-position: -246px -1750px;
        }
        &.red_belt{
            background-position: -123px -1750px;
        }
        &.orange_belt{
            background-position: 0px -1750px;
        }
    }
    &.iot_fundamentals_belt{
        background-position: -373px -1820px;
        &.black_belt{
            background-position: -246px -1820px;
        }
        &.red_belt{
            background-position: -123px -1820px;
        }
        &.orange_belt{
            background-position: 0px -1820px;
        }
    }
    &.iot_intermediate_belt{
        background-position: -373px -1890px;
        &.black_belt{
            background-position: -246px -1890px;
        }
        &.red_belt{
            background-position: -123px -1890px;
        }
        &.orange_belt{
            background-position: 0px -1890px;
        }
    }
    &.blockchain_capstone_project_belt{
        background-position: -373px -1960px;
        &.black_belt{
            background-position: -246px -1960px;
        }
        &.red_belt{
            background-position: -123px -1960px;
        }
        &.orange_belt{
            background-position: 0px -1960px;
        }
    }

    &.saudi_user_experience_belt{
        background-position: -373px -2030px;
        &.black_belt{
            background-position: -246px -2030px;
        }
        &.red_belt{
            background-position: -123px -2030px;
        }
        &.orange_belt{
            background-position: 0px -2030px;
        }
    }
    &.saudi_software_testing_belt{

        background-position: -373px -2100px;
        &.black_belt{
            background-position: -246px -2100px;
        }
        &.red_belt{
            background-position: -123px -2100px;
        }
        &.orange_belt{
            background-position: 0px -2100px;
        }
    }
    &.saudi_project_management_belt{

        background-position: -373px -2170px;
        &.black_belt{
            background-position: -246px -2170px;
        }
        &.red_belt{
            background-position: -123px -2170px;
        }
        &.orange_belt{
            background-position: 0px -2170px;
        }
    }
    &.devops_belt{
        background-position: -373px -2240px;
        &.black_belt{
            background-position: -246px -2240px;
        }
        &.red_belt{
            background-position: -123px -2240px;
        }
        &.orange_belt{
            background-position: 0px -2240px;
        }
    }
    &.software_testing_selenium_belt{
        background-position: -373px -2310px;
        &.black_belt{
            background-position: -246px -2310px;
        }
        &.red_belt{
            background-position: -123px -2310px;
        }
        &.orange_belt{
            background-position: 0px -2310px;
        }
    }
    &.software_testing_jmeter_belt{
        background-position: -373px -2380px;
        &.black_belt{
            background-position: -246px -2380px;
        }
        &.red_belt{
            background-position: -123px -2380px;
        }
        &.orange_belt{
            background-position: 0px -2380px;
        }
    }
    &.cloud_computing_gcp_solution_planning_belt{
        background-position: -373px -2450px;
        &.black_belt{
            background-position: -246px -2450px;
        }
        &.red_belt{
            background-position: -123px -2450px;
        }
        &.orange_belt{
            background-position: 0px -2450px;
        }
    }
    &.android_studio_kotlin_belt{
        background-position: -373px -2520px;
        &.black_belt{
            background-position: -246px -2520px;
        }
        &.red_belt{
            background-position: -123px -2520px;
        }
        &.orange_belt{
            background-position: 0px -2520px;
        }
    }
    &.unity_3d_game_design_belt{
        background-position: -373px -2590px;
        &.black_belt{
            background-position: -246px -2590px;
        }
        &.red_belt{
            background-position: -123px -2590px;
        }
        &.orange_belt{
            background-position: 0px -2590px;
        }
    }
    &.unity_3d_collaborative_games_belt{
        background-position: -373px -2660px;
        &.black_belt{
            background-position: -246px -2660px;
        }
        &.red_belt{
            background-position: -123px -2660px;
        }
        &.orange_belt{
            background-position: 0px -2660px;
        }
    }
    &.ar_vr_belt{
        background-position: -373px -2730px;
        &.black_belt{
            background-position: -246px -2730px;
        }
        &.red_belt{
            background-position: -123px -2730px;
        }
        &.orange_belt{
            background-position: 0px -2730px;
        }
    }
    &.mobile_ios_project_belt{
        background-position: -373px -2800px;
        &.black_belt{
            background-position: -246px -2800px;
        }
        &.red_belt{
            background-position: -123px -2800px;
        }
        &.orange_belt{
            background-position: 0px -2800px;
        }
    }
    &.android_studio_kotlin_advanced_belt{
        background-position: -373px -2870px;
        &.black_belt{
            background-position: -246px -2870px;
        }
        &.red_belt{
            background-position: -123px -2870px;
        }
        &.orange_belt{
            background-position: 0px -2870px;
        }
    }
    &.foundations_of_digital_belt{
        background-position: -373px -2940px;
        &.black_belt{
            background-position: -246px -2940px;
        }
        &.red_belt{
            background-position: -123px -2940px;
        }
        &.orange_belt{
            background-position: 0px -2940px;
        }
    }
    &.fintech_fundamentals_belt{
        background-position: -373px -3010px;
        &.black_belt{
            background-position: -246px -3010px;
        }
        &.red_belt{
            background-position: -123px -3010px;
        }
        &.orange_belt{
            background-position: 0px -3010px;
        }
    }
    &.alibaba_cloud_computing_fundamentals_belt{
        background-position: -373px -3080px;
        &.black_belt{
            background-position: -246px -3080px;
        }
        &.red_belt{
            background-position: -123px -3080px;
        }
        &.orange_belt{
            background-position: 0px -3080px;
        }
    }
    &.ar_app_belt{
        background-position: -373px -3150px;
        &.black_belt{
            background-position: -246px -3150px;
        }
        &.red_belt{
            background-position: -123px -3150px;
        }
        &.orange_belt{
            background-position: 0px -3150px;
        }
    }

    &.vr_app_belt{
        background-position: -373px -3220px;
        &.black_belt{
            background-position: -246px -3220px;
        }
        &.red_belt{
            background-position: -123px -3220px;
        }
        &.orange_belt{
            background-position: 0px -3220px;
        }
    }

    &.data_science_enrichment_belt{
        background-position: -373px -3290px;
        &.black_belt{
            background-position: -246px -3290px;
        }
        &.red_belt{
            background-position: -123px -3290px;
        }
        &.orange_belt{
            background-position: 0px -3290px;
        }
    }

    &.cybersecurity_ot_basics_belt{
        background-position: -373px -3360px;
        &.black_belt{
            background-position: -246px -3360px;
        }
        &.red_belt{
            background-position: -123px -3360px;
        }
        &.orange_belt{
            background-position: 0px -3360px;
        }
    }

    &.cybersecurity_ot_intermediate_belt{
        background-position: -373px -3430px;
        &.black_belt{
            background-position: -246px -3430px;
        }
        &.red_belt{
            background-position: -123px -3430px;
        }
        &.orange_belt{
            background-position: 0px -3430px;
        }
    }

    &.cybersecurity_ot_advanced_belt{
        background-position: -373px -3500px;
        &.black_belt{
            background-position: -246px -3500px;
        }
        &.red_belt{
            background-position: -123px -3500px;
        }
        &.orange_belt{
            background-position: 0px -3500px;
        }
    }

    &.cybersecurity_ot_capstone_belt{
        background-position: -373px -3570px;
        &.black_belt{
            background-position: -246px -3570px;
        }
        &.red_belt{
            background-position: -123px -3570px;
        }
        &.orange_belt{
            background-position: 0px -3570px;
        }
    }

    &.sda_devops_belt{
        background-position: -373px -3640px;
        &.black_belt{
            background-position: -246px -3640px;
        }
        &.red_belt{
            background-position: -123px -3640px;
        }
        &.orange_belt{
            background-position: 0px -3640px;
        }
    }

    &.data_governance_belt{
        background-position: -373px -3710px;
        &.black_belt{
            background-position: -246px -3710px;
        }
        &.red_belt{
            background-position: -123px -3710px;
        }
        &.orange_belt{
            background-position: 0px -3710px;
        }
    }

    &.sw_qa_sda_belt{
        background-position: -373px -3780px;
        &.black_belt{
            background-position: -246px -3780px;
        }
        &.red_belt{
            background-position: -123px -3780px;
        }
        &.orange_belt{
            background-position: 0px -3780px;
        }
    }

    &.game_design_principles_belt{
        background-position: -373px -3290px;
        &.black_belt{
            background-position: -246px -3290px;
        }
        &.red_belt{
            background-position: -123px -3290px;
        }
        &.orange_belt{
            background-position: 0px -3290px;
        }
    }

    &.game_design_practice_belt{
        background-position: -373px -3360px;
        &.black_belt{
            background-position: -246px -3360px;
        }
        &.red_belt{
            background-position: -123px -3360px;
        }
        &.orange_belt{
            background-position: 0px -3360px;
        }
    }

    &.ui_ux_design_web_fundamentals_belt{
        background-position: -373px -3430px;
        &.black_belt{
            background-position: -246px -3430px;
        }
        &.red_belt{
            background-position: -123px -3430px;
        }
        &.orange_belt{
            background-position: 0px -3430px;
        }
    }
    &.cybersecurity_fundamentals_1{
        background-position: -374px -4208px;
        &.black_belt{
            background-position: -246px -4208px;
        }
        &.red_belt{
            background-position: -123px -4208px;
        }
        &.orange_belt{
            background-position: 0px -4208px;
        }
    }
    &.cybersecurity_fundamentals_2{
        background-position: -374px -4559px;
        &.black_belt{
            background-position: -246px -4559px;
        }
        &.red_belt{
            background-position: -123px -4559px;
        }
        &.orange_belt{
            background-position: 0px -4559px;
        }
    }
    &.security_analyst{
        background-position: -375px -4278px;
        &.black_belt{
            background-position: -246px -4278px;
        }
        &.red_belt{
            background-position: -123px -4278px;
        }
        &.orange_belt{
            background-position: 0px -4278px;
        }
    }
    &.security_operations{
        background-position: -373px -4488px;
        &.black_belt{
            background-position: -246px -4488px;
        }
        &.red_belt{
            background-position: -123px -4488px;
        }
        &.orange_belt{
            background-position: 0px -4488px;
        }
    }
    &.ethical_hacking{
        background-position: -373px -4348px;
        &.black_belt{
            background-position: -246px -4348px;
        }
        &.red_belt{
            background-position: -123px -4348px;
        }
        &.orange_belt{
            background-position: 0px -4348px;
        }
    }
    &.penetration_testing{
        background-position: -373px -4418px;
        &.black_belt{
            background-position: -246px -4418px;
        }
        &.red_belt{
            background-position: -123px -4418px;
        }
        &.orange_belt{
            background-position: 0px -4418px;
        }
    }

    
}