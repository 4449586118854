$Poppins: "Poppins", sans-serif;

.loading{
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 80px;
}

#course_intro_container{
    margin: 0px auto;
    padding: 0px 0px 50px 0px;
    position: relative;

    #sub_navigation_container{
        justify-content: flex-end;
        #switch_back_to_classic_learn{
            visibility: hidden;
        }
    }

    >.dropdown{
        align-items: center;
        display: flex;
        left: 0;
        position: absolute;
        top: 47px;
        h2{
            font: normal 500 22px/33px 'Poppins', sans-serif;
            margin-bottom: 0px;
            margin-right: 21px;
        }

        #course_intro_dropdown{
            align-items: center;
            background: #FFFFFF;
            border-radius: 6px;
            border:  1px solid rgba(0, 0, 0, 0.2);
            box-shadow: none;
            color: #152C61;
            display: flex;
            font: normal 500 16px/24px $Poppins;
            height: 36px;
            
            > span {
                display: block;
                max-width: 800px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
            &:after{
                background-image: url("https://assets.codingdojo.com/learn_platform/user/course_intro/course_intro_icons.png");
                border: none !important;
                height: 16px;
                margin-left: 10px;
                width: 16px;
            }

            &[aria-expanded="true"]{
                border: 1px solid #2C6BFF;
            }

            &[aria-expanded="false"]{
                &:after{
                    -webkit-transform: rotate(180deg);
                    -moz-transform: rotate(180deg);
                    -ms-transform: rotate(180deg);
                    -o-transform: rotate(180deg);
                    transform: rotate(180deg);
                }
            }
        }

        .dropdown-menu{
            background: #FFFFFF;
            border-radius: 6px;
            border: 1px solid #2C6BFF;
            max-width: 850px;
            padding: 20px 0px;

            input{
                background: #FFFFFF;
                border-radius: 6px;
                border: 2px solid #E6E6E6;
                box-sizing: border-box;
                color: #152C61;
                font: normal normal 14px/21px "Poppins", sans-serif;
                height: 39px;
                margin: 0px 24px 10px 24px;
                padding: 5px 20px;
                width: calc(100% - 48px);
            }

            .track_list{
                max-height: 400px;
                overflow: auto;

                a{
                    color: #152C61;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 24px;
                    white-space: inherit;
                }
            }

            p{
                color: #152C61;
                font-family: $Poppins;
                font-size: 16px;
                font-style: normal;
                line-height: 24px;
                margin-bottom: 0px;
                margin-top: 10px;
                text-align: center;
            }
        }
    }

    #course_intro_content{
        background: #FFFFFF;
        border-radius: 6px;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
        padding: 30px;
        position: relative;

        #course_details_widget{
            background: #FFFFFF;
            border-radius: 6px;
            border: 1px solid rgba(21, 44, 97, 0.1);
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            margin-bottom: 24px;
            padding: 20px;
            position: relative;
            width: 919px;

            .course_icon_container{
                align-items: center;
                background: #FFFFFF;
                border-radius: 100%;
                box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.12);
                display: flex;
                height: 66px;
                justify-content: center;
                margin-right: 20px;
                width: 66px;
            }

            h1{
                color: #152C61;
                font-family: $Poppins;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 24px;
                margin-bottom: 0;
                padding-right: 50px;
            }

            >span{
                color: #152C61;
                font-family: $Poppins;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 21px;
                margin-top: 6px;
                opacity: 0.4;
            }

            ul{
                align-items: center;
                display: flex;
                margin : 19px 0px 0px 0px;

                li{
                    align-items: center;
                    background: #C2D4FF;
                    border-radius: 6px;
                    color: #2C6BFF;
                    display: flex;
                    font-family: $Poppins;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 600;
                    height: 33px;
                    justify-content: center;
                    line-height: 21px;
                    margin-right: 20px;
                    padding: 0px 20px;
                }
            }
            #add_to_bookmarked_button{
                align-items: center;
                background: #DEE8FF;
                border-radius: 100%;
                border: none;
                display: flex;
                height: 46px;
                justify-content: center;
                outline: none;
                padding: 0px;
                position: absolute;
                right: 25px;
                top: 50%;
                transform: translateY(-50%);
                width: 46px;

                span{
                    background-color: transparent;
                    background-image: url(https://assets.codingdojo.com/learn_platform/user/courses/bookmark_and_unbookmark_icon.png);
                    background-repeat: no-repeat;
                    background-size: 31px;
                    border: none;
                    height: 22px;
                    outline: none;
                    padding: 0px;
                    width: 15px;
                    &.is_bookmarked{
                        background-position: -15px 0px;
                    }
                }
            }
        }

        #course_intro_widget{
            background: #FFFFFF;
            border-radius: 6px;
            border: 1px solid rgba(21, 44, 97, 0.1);
            box-sizing: border-box;
            padding: 20px;
            width: 919px;

            >h3{
                border-bottom: 1px solid rgba(21, 44, 97, 0.1);
                color: #152C61;
                font-family: $Poppins;
                font-size: 20px;
                font-style: normal;
                font-weight: 500;
                line-height: 30px;
                margin-bottom: 15px;
                padding-bottom: 18px;
            }

            #course_overview_container{
                border-bottom: 1px solid rgba(21, 44, 97, 0.1);
                margin-bottom: 21px;
                position: relative;
                
                h4{
                    color: #152C61;
                    font-family: $Poppins;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 24px;
                    margin-bottom: 20px;
                }

                #instructor_container{
                    align-items: center;
                    display: flex;
                    height: 41px;
                    margin-bottom: 24px;
                    list-style: none;
                    padding-left: 0px;
                    li{
                        padding-left: 51px;
                        position: relative;
                        margin-right: 30px;
                        &:last-child{
                            margin-right: 0px;
                        }
                        button{
                            align-items: center;
                            background-color: transparent;
                            border: none;
                            color: #2C6BFF;
                            display: flex;
                            font-family: $Poppins;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 21px;
                            outline: none;
                            padding: 0px;
                        }
                        img{
                            border-radius: 100%;
                            border: 1px solid #293854;
                            height: 41px;
                            left: 0px;
                            position: absolute;
                            width: 41px;
                            top: -3px;
                        }
                        h6{
                            color: #152C61;
                            display: flex;
                            flex-direction: column;
                            font-family: $Poppins;
                            font-size: 14px;
                            font-weight: 600;
                            margin-bottom: 0px;
                            span{
                                font-weight: 400;
                            }
                        }
                    }

                }

                #course_description, #course_description_edit textarea{
                    *{
                        color: #152C61;
                        font-family: $Poppins;
                        font-size: 14px;
                        font-style: normal;
                        line-height: 21px;
                    }
                    .cd_blue{
                        color: #007bff;
                    }
                }

                #course_description_edit{
                    textarea{
                        width: 100%;
                        height: 20vh;
                        margin-bottom: 60px;
                        border-radius: 4px;
                        font-family: "Poppins", sans-serif;
                        font-size: 12px;
                        outline: none;
                        padding: 10px 16px;
                        resize: none;
                    } 

                    button{
                        position: absolute;
                        right: 0px;
                        top: 75%;
                        background: #2C6BFF;
                        border-radius: 6px;
                        border: none;
                        color: #fff;
                        display: block;
                        height: 46px;
                        margin: 0px auto;
                        padding: 0px 30px;
                        width: 210px;

                        &.disabled{
                            opacity: .4;
                        }
                    }
                }

                p{
                    color: #152C61;
                    font-family: $Poppins;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: normal;
                    line-height: 21px;
                    margin-bottom: 15px;
                }
            }

            #courses_container{
                h4{
                    color: #152C61;
                    font-family: $Poppins;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 24px;
                    margin-bottom: 19px;
                }

                ul{
                    column-gap: 5px;
                    display: inline-grid;
                    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
                    margin-bottom: 0px;
                    margin-top: 20px;
                    row-gap: 5px;
                    width: 100%;
                    li{
                        position: relative;
                        text-align: center;
                        button { 
                            overflow: initial;
                            padding: 0;
                            span{
                                word-wrap: break-word;
                            }
                        }
                        &.course_item{
                            margin-bottom: 0;
                            &.completed{
                                &::before{
                                    right: 50px;
                                }
                            }
                        }
                        &:nth-child(8n+8){
                            margin-right: 0px;
                        }
                        &:nth-last-child(-n+5){
                            margin-bottom: 0px;
                        }
                        a{
                            text-decoration: none;
                        
                            img{
                                height: 55px;
                                width: 47px;
                            }
                            span{
                                color: #152C61;
                                display: inline-block;
                                font-family: 'Poppins', sans-serif;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: normal;
                                line-height: 22px;
                                margin-top: 10px;
                                overflow: hidden;
                                text-align: center;
                                text-overflow: ellipsis; 
                                white-space: nowrap; 
                            }
                        }
                    }
                }
            }

            #video_lectures_container{
                h4{
                    color: #152C61;
                    font-family: $Poppins;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 24px;
                    margin-bottom: 19px;
                }
                ul{
                    display: flex;
                    flex-wrap: wrap;
                    margin-bottom: 0px;

                    li{
                        background: #FAFBFF;
                        border-radius: 6px;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        margin-bottom: 20px;
                        margin-right: 20px;
                        padding: 22px 20px 20px 20px;
                        text-align: center;
                        width: 279px;

                        &:nth-child(3n+3){
                            margin-right: 0px;
                        }
                        
                        h6{
                            color: #152C61;
                            font-family: $Poppins;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 24px;
                            margin-bottom: 21px;
                        }

                        p{
                            color: #152C61;
                            font-family: $Poppins;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: normal;
                            line-height: 24px;
                            margin: 0px auto 19px auto;
                            text-align: center;
                            width: 171px;
                        }

                        a, button{
                            align-items: center;
                            background: #FFFFFF;
                            border-radius: 6px;
                            border: 2px solid #2C6BFF;
                            box-sizing: border-box;
                            color: #2C6BFF;
                            display: flex;
                            font-family: $Poppins;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 500;
                            height: 46px;
                            justify-content: center;
                            line-height: 24px;
                            margin: 0px auto;
                            outline: none;
                            text-decoration: none;
                            width: 180px;

                            &.live_lecture_btn{
                                width: 242px;
                            }

                            .live_now{
                                align-items: center;
                                background: #FCEEEE;
                                border-radius: 6px;
                                color: #E1554C;
                                display: flex;
                                font-family: $Poppins;
                                font-size: 12px;
                                font-style: normal;
                                font-weight: 500;
                                height: 22px;
                                justify-content: center;
                                line-height: 18px;
                                margin-right: 10px;
                                width: 71px;
                
                                &:before{
                                    background: #E1554C;
                                    border-radius: 100%;
                                    content: "";
                                    height: 6px;
                                    margin-right: 3px;
                                    width: 6px;
                                }
                            }

                            .arrow_right_icon{
                                background-image: url("https://assets.codingdojo.com/learn_platform/user/course_intro/course_intro_icons.png");
                                background-position: -22px 0px;
                                display: flex;
                                height: 14px;
                                width: 14px;
                                margin-left: 10px;
                            }
                        }
                    }
                }
            }
        }

        #course_progress_widget{
            background: #FFFFFF;
            border-radius: 6px;
            border: 1px solid rgba(21, 44, 97, 0.1);
            box-sizing: border-box;
            padding: 25px 20px 20px 20px;
            position: absolute;
            right: 30px;
            top: 30px;
            width: 290px;

            h2{
                color: #152C61;
                font-family: $Poppins;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 24px;
                margin-bottom: 20px;
            }

            p{
                color: #4561A2;
                font-family: $Poppins;
                font-size: 14px;
                font-style: normal;
                font-weight: normal;
                line-height: 21px;
                margin-bottom: 10px;
            }

            .progress_bar_container{
                background: #A3A3A3;
                border-radius: 6px; 
                height: 4px;
                width: 100%;

                .progress_bar{
                    background: #2C6BFF;
                    border-radius: 6px;
                    height: 100%;

                    &.completed{
                        background: #4CE164;
                    }
                }
            }


            >a{
                align-items: center;
                background: #2C6BFF;
                border-radius: 6px;
                border: none;
                color: #FFFFFF;
                display: flex;
                font-family: $Poppins;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                height: 46px;
                justify-content: center;
                line-height: 21px;
                margin-top: 20px;
                outline: none;
                padding: 2px 16px;
                width: 100%;

                &.revisit_btn{
                    background: #FFFFFF;
                    border: 2px solid #2C6BFF;
                    color: #2C6BFF;
                }
            }
        }
    }
}

body.is_dark_mode{
    #course_intro_container{
        >.dropdown{
            #course_intro_dropdown{
                background: #333333;
                border: 1px solid rgba(21, 44, 97, 0.4);
                color: #fff;
    
                &:after{
                    background-position: 0px 24px;
                }
    
                &[aria-expanded="true"]{
                    border: 1px solid #81A0EA;
                }
            }
            h2{
                color: #FFFFFF;
            }
    
            .dropdown-menu{
                background: #333333;
                border: 1px solid #81A0EA;

                input{
                    background: #121212;
                    border-color:rgba(255, 255, 255, 0.88);
                    border: none;
                    color: rgba(255, 255, 255, 0.88);
                }

                .track_list{
                    max-height: 400px;
                    overflow: auto;
    
                    a{
                        color:rgba(255, 255, 255, 0.88);
                        &:hover{
                            background-color: #81A0EA;
                            color: #fff;
                        }
                    }
                }
    
                p{
                    color: #fff;
                }
            }
        }
    
        #course_intro_content{
            background: #2A2A2A;

            #course_details_widget{
                background: #333333;
                #add_to_bookmarked_button{
                    background-color: #5b5b5b;
                    .icon{
                        background-image: url(https://assets.codingdojo.com/learn_platform/dark_mode/courses/bookmark_and_unbookmark_icon_dark_mode.png);
                    }
                }
    
                h1{
                    color: #FFFFFF;
                }

                span{
                    color: #CCCCCC;
                }
    
                ul{
                    li{
                        background: rgba(21, 44, 97, 0.4);
                        color: #81A0EA;
                    }
                }
            }
    
            #course_intro_widget{
                background: #2A2A2A;
    
                h3{
                    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
                    color: #fff;
                }
    
                #course_overview_container{
                    border-bottom: 1px solid rgba(255, 255, 255, 0.3);

                    h4{
                        color: #FFFFFF;
                    }
    
                    button{
                        color: #81A0EA;
    
                        &:after{
                            background-position: 0px 24px;
                        }
                    }
    
                    #instructor_container{
                        h5{
                            color: #FFFFFF;
                            opacity: 0.4;
                        }
                        h6{
                            color: #FFFFFF;
                        }
    
                        ul{
                            li{
                                img{
                                    border: 1px solid #293854;
                                }
    
                                span{
                                    color: #FFFFFF;
                                    opacity: 0.4;
                                }
                            }
                        }
                    }
                    #course_description{
                        color: #CCCCCC;
                        *{
                            color: #CCCCCC;
                        }
                        .cd_blue{
                            color: #81A0EA;
                        }
                    }
                }


                #courses_container{
                    h4{
                        color: #FFFFFF;
                    }
    
                    ul{
                        li{
                            button{
                                span{
                                    color: #FFFFFF;
                                }
                            }
                        }
                    }
                }
    
    
                #video_lectures_container{
                    h4{
                        color: #FFFFFF;
                    }
                    ul{
    
                        li{
                            background: #333333;
    
                            h6{
                                color: #FFFFFF;
                            }
    
                            p{
                                color: #FFFFFF;
                            }
    
                            a, button{
                                background: rgba(21, 44, 97, 0.4);
                                border: 2px solid #81A0EA;
                                color: #81A0EA;
    
                                .live_now{
                                    background: rgba(217, 75, 75, 0.1);
                                    color: #E1554C;
                    
                                    &:before{
                                        background: #E1554C;
                                    }
                                }
    
                                .arrow_right_icon{
                                    background-position: -22px -19px;
                                }
                            }
                        }
                    }
                }
            }
    
            #course_progress_widget{
                background: #333333;
    
                h2{
                    color: #FFFFFF;
                }
    
                p{
                    color: #FFFFFF;
                }
    
                .progress_bar_container{
                    background: #001D61;
                    .progress_bar{
                        background: #81A0EA;
    
                        &.completed{
                            background: #4CE164;
                        }
                    }
                }
    
                >a{
                    background: #81A0EA;
                    border: 1px solid rgba(21, 44, 97, 0.1);
                    color: #fff;
                }
            }
        }
    }
}

@media only screen and (max-width: 1354px){
    #course_intro_container {
        > .dropdown{
            left: 16px;
        }
    }
}
@media only screen and (max-width: 768px){
    #course_intro_container{
        max-width: 100%;
        padding: 22px 20px 102px 20px;

        #sub_navigation_container{
            padding-top: 0px;
        }

        >.dropdown{
            left: unset;
            margin-bottom: 24px;
            position: relative;
            top: unset;

            #course_intro_dropdown{
                display: unset;
                justify-content: space-between;
                overflow: hidden;
                padding-right: 32px;
                text-overflow: ellipsis;
                white-space: nowrap;
                width: 100%;

                &:after{
                    background-image: url("https://assets.codingdojo.com/learn_platform/user/course_intro/course_intro_icons.png");
                    border: none !important;
                    height: 16px;
                    margin-left: 10px;
                    position: absolute;
                    right: 6px;
                    top: 11px;
                    width: 16px;
                }
            }

            .dropdown-menu{
                width: 100%;

                .track_list{
                    a{
                        white-space: unset;
                    }
                }
            }
        }

        #course_intro_content{
            display: flex;
            flex-direction: column;
            padding: 24px 16px;
            

            #course_details_widget{
                display: flex;
                flex-wrap: wrap;
                padding: 20px 65px 20px 20px;
                width: 100%;

                .course_icon_container{
                    margin-bottom: 22px;
                }
                h1{
                    padding-right: 0;
                }
                #add_to_bookmarked_button{
                    right: 12px;
                }

                div{
                    margin-bottom: 20px;
                }

                ul{
                    display: flex;
                    flex-wrap: wrap;
                    position: unset;

                    li{
                        margin-bottom: 12px;
                        margin-left: 12px;

                        &:first-child{
                            margin-left: 0px;
                        }
                    }
                }
            }

            #course_intro_widget{
                order: 1;
                width: 100%;

                #course_overview_container{
                    button{
                        left: unset;
                        right: 0px;
                    }
                    #instructor_container{
                        align-items: flex-start;
                        flex-direction: column;
                        height: unset;
                        margin-bottom: 0px;
                        li{
                            margin-bottom: 22px;
                        }
                    }
                }
            }

            #course_progress_widget{
                margin-bottom: 24px;
                position: unset;
                width: 100%;
            }
        }
        
    }
}
@media only screen and (max-width: 680px){
    #course_intro_container{
        #course_intro_content{
            #course_details_widget{
                ul{
                    align-items: baseline;
                    flex-direction: column;
                    li{
                        margin: 0 0 10px;
                    }
                }
            }
        }
    }
}