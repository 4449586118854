.tuition_reminder_modal{
    z-index: 99999 !important;

    .modal-dialog{
        max-width: 791px;
        width: 791px;
        .modal-content{
            background: #FFFFFF;
            border-radius: 6px;
            box-shadow: 0px 4px 2px rgba(0, 0, 0, 0.05);
            .modal-header{
                border: none;
                justify-content: center;
                padding: 61px 40px 34px 40px;
                position: relative;
                .modal-title{
                    color: #152C61;
                    font-family: 'Poppins', sans-serif;
                    font-size: 36px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 54px;
                }
                button{
                    background-color: transparent;
                    border: none;
                    outline: none;
                    padding: 0px;
                    position: absolute;
                    right: 26px;
                    top: 26px;
                }
            }
            .modal-body{
                padding: 5px 0px 0px 0px;
                text-align: center;
                .clock_icon{
                    background-image: url("https://assets.codingdojo.com/learn_platform/global/clock_red_and_blue_sprite.png");
                    background-position: -91px 0px;
                    height: 90px;
                    margin: 0px auto 41px;
                    width: 90px;
                    &.red_clock{
                        background-position: 0px 0px;
                    }
                }
                p{
                    color: #152C61;
                    font-family: 'Poppins', sans-serif;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: normal;
                    line-height: 24px;
                    margin: 0px auto 24px auto;
                    text-align: center;
                    width: 435px;
                    &#extend_eaa_description{
                        width: 485px;
                    }
                    >span{
                        color: #A93333;
                    }
                    a{
                        color: #152C61;

                        span{
                            color: #2C6BFF;
                        }
                    }
                }
            }

            .modal-footer{
                border: none;
                justify-content: center;
                padding-bottom: 60px;
                >*{
                    margin: 0px;
                }
                a, button{
                    align-items: center;
                    border: none;
                    color: #2C6BFF;
                    display: flex;
                    font-family: 'Poppins', sans-serif;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 500;
                    justify-content: center;
                    line-height: 27px;
                    outline: none;
                    text-align: center;
                    
                    &.btn-primary{
                        background: #2C6BFF;
                        border-radius: 6px;
                        color: #fff;
                        height: 46px;
                        width: 210px;
                    }
                }
                span{
                    color: #152C61;
                    font-family: 'Poppins', sans-serif;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: 21px;
                    margin: 0px 38px;
                    text-align: center;
                }
            }
        }
    }
}
@media only screen and (max-width: 768px){
    .modal.tuition_reminder_modal{
        .modal-dialog{
            .modal-content{
                .modal-header{
                    .modal-title{
                        font-size: 28px;
                        line-height: 42px;
                        margin-bottom: 21px;
                        text-align: center;
                    }
                }
                .modal-body{
                    flex: unset;
                    padding: 5px 20px 0px;
                    .clock_icon{
                        margin: 0px auto 23px;
                    }
                    p{
                        font-size: 14px;
                        line-height: 21px;
                        width: 100%;
                    }
                }
                .modal-footer{
                    display: flex;
                    flex-direction: column;
                    span{
                        margin: 17px 38px 24px;
                    }
                    button.btn-primary{
                        font-size: 16px;
                    }
                }
            }
        }
    }
}