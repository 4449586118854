
#workspace_switch_modal{
    height: 100%;
    margin: 0px;
    max-width: 100%;
    width: 100%;
    .modal-content{
        background: #FFFFFF;
        border-radius: 0px;
        border-radius: 6px;
        box-shadow: 0px 4px 2px rgba(0, 0, 0, 0.05);
        height: 100%;
        .modal-body{
            align-items: center;
            display: flex;
            justify-content: center;
            overflow: auto;
            text-align: center;
            form{
                width: 360px;
                img{
                    height: 72px;
                    margin-bottom: 48px;
                    width: 58px;
                }
                p{
                    color: #152C61;
                    font: normal 300 14px/24px "Poppins", sans-serif;
                    text-align: center;
                }
                .react-dropdown-select{
                    border-radius: 6px;
                    border: 1px solid #C2D4FF !important;
                    box-sizing: border-box;
                    height: 46px;
                    margin-bottom: 20px;
                    padding: 2px 20px !important;
                    &[aria-expanded="true"]{
                        .react-dropdown-select-dropdown-handle{
                            margin: 0px;
                            transform: rotate(180deg);
                            transition:  all .2s;
                        }
                    }
                    .react-dropdown-select-dropdown{
                        background: #Fff;
                        border-radius: 6px;
                        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
                        margin-top: 14px;
                        max-height: 259px;
                        text-align: left;
                        width: 100%;
                        .react-dropdown-select-item{
                            font: normal 300 14px/24px "Poppins", sans-serif;
                            padding: 9px 20px;
                        }
                        .react-dropdown-select-item-selected{
                            background: transparent;
                            color: #2C6BFF;
                            font: normal 500 14px/24px "Poppins", sans-serif;
                        }
                    }
                    .react-dropdown-select-content{
                        input{
                            font: normal 300 14px/24px "Poppins", sans-serif;
                        }
                        span{
                            color: #152C61;
                            font: normal 300 14px/24px "Poppins", sans-serif;
                        }
                    }
                    .react-dropdown-select-dropdown-handle{
                        transform: rotate(270deg);
                        transition:  all .2s;
                        svg{
                            color: #2c6bff;
                            height: 28px;
                            width: 26px;
                        }
                    }
                }
                button[type="submit"]{
                    background: #2C6BFF;
                    border-radius: 6px;
                    border: none;
                    color: #fff;
                    font: normal 500 18px/27px "Poppins", sans-serif;
                    height: 46px;
                    width: 100%;
                }
            }
        }
    }
}
.is_dark_mode{
    #workspace_switch_modal{
        .modal-content{
            .modal-body{
                form{
                    p{
                        color: rgba(255, 255, 255, 0.88);
                    }
                    .react-dropdown-select{
                        .react-dropdown-select-dropdown-handle{
                            svg{
                                color: #81A0EA;
                            }
                        }
                        .react-dropdown-select-dropdown{
                            .react-dropdown-select-item{
                                background: #2c2f33;
                                color: #fff;
                            }
                            .react-dropdown-select-item-selected{
                                color: #81A0EA;
                            }
                        }
                        .react-dropdown-select-content{
                            span{
                                color: #81A0EA;
                            }
                            input{
                                color: rgba(255, 255, 255, 0.88);
                            }
                        }
                    }
                    button[type="submit"]{
                        background: rgba(204, 224, 255, 0.14);
                        color: #81A0EA;
                    }
                }
            }
        }
    }
}