#belt_achievement_modal{
    max-width: 791px;
    width: 791px;
    .modal-content{
        background: #FFFFFF;
        border-radius: 6px;
        box-shadow: 0px 4px 2px rgba(0, 0, 0, 0.05);
        .modal-header{
            border: none;
            justify-content: center;
            padding: 61px 40px 34px 40px;
            position: relative;
            .modal-title{
                color: #FCCB3D;
                font: normal 500 36px/54px 'Poppins', sans-serif;
            }
            button{
                background-color: transparent;
                border: none;
                outline: none;
                padding: 0px;
                position: absolute;
                right: 26px;
                top: 26px;
            }
        }
        .modal-body{
            padding: 5px 0px 0px 0px;
            text-align: center;
            img{
                margin-bottom: 33px;
            }
            p{
                color: #152C61;
                font: normal normal 16px/24px 'Poppins', sans-serif;
                margin: 0px auto 20px auto;
                width: 380px;
                >span{
                    color: #2C6BFF;
                    font-weight: 600;
                }
            }
        }
        .modal-footer{
            border: none;
            justify-content: center;
            padding: 0px 36px 80px 36px;
            >*{
                margin: 0px;
            }
            p{
                color: #2C6BFF;
                font: normal 300 14px/21px 'Poppins', sans-serif;
                margin-bottom: 40px;
                margin-top: 18px;
                position: relative;
                text-align: center;
                width: 100%;
                &:before{
                    background-color: #DEDEDE;
                    content: "";
                    height: 1px;
                    left: 0;
                    position: absolute;
                    top: 9px;
                    width: 208px;
                }
                &:after{
                    background-color: #DEDEDE;
                    content: "";
                    height: 1px;
                    position: absolute;
                    right: 0;
                    top: 9px;
                    width: 208px;
                }
            }
            a{
                background-image: url('https://assets.codingdojo.com/learn_platform/global/social_icons.png');
                height: 26px;
                margin-right: 68px;
                width: 26px;
                &.instagram_icon{
                    background-position-x: -36px;
                }
                &.linkedin_icon{
                    background-position-x: -72px;
                }
                &.facebook_icon{
                    background-position-x: -108px;
                }
                &.reddit_icon{
                    background-position-x: -144px;
                    margin-right: 0px;
                }
            }
        }
    }
}