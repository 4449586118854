
pre,
pre{
    white-space: pre-wrap !important;
}


pre[data-language],
pre[data-language] {
    background: #121212!important;
    word-wrap: break-word !important;
    margin: 0px !important;
    padding: 16px 20px 17px 20px !important;
    color: #fff !important;
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 20px !important;
    overflow: none !important;
    white-space: pre-wrap !important;
    border-radius: 6px;
}

pre[data-language],
pre[data-language] span,
pre[data-language],
pre[data-language] span,
code {
    font-family: SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace !important;
    font-style: normal;
    font-weight: normal;
}

pre[data-language] .comment,
pre[data-language] span.comment,
pre[data-language] .comment,
pre[data-language] span.comment {
    color: #727272 !important;
}

pre[data-language] .constant,
pre[data-language] .constant {
    color: #AE81FF !important;
}

pre[data-language] .storage,
pre[data-language] .storage {
    font-style: italic;
    color: #66D9EF !important;
}

pre[data-language] .string,
pre[data-language] .comment.docstring,
pre[data-language] .string,
pre[data-language] .comment.docstring {
    color: #E6DB74 !important;
}

pre[data-language] .keyword.operator + .function.call,
pre[data-language] .variable,
pre[data-language] .string.regexp,
pre[data-language] .support.tag.script,
pre[data-language] .support.tag.style,
pre[data-language] .support.tag,
pre[data-language] .support.operator,
pre[data-language] .keyword.operator + .function.call,
pre[data-language] .variable,
pre[data-language] .string.regexp,
pre[data-language] .support.tag.script,
pre[data-language] .support.tag.style,
pre[data-language] .support.tag,
pre[data-language] .support.operator {
    color: #fff !important;
}

pre[data-language] .keyword,
pre[data-language] .selector,
pre[data-language] .support.tag-name,
pre[data-language] *[data-language="ruby"] .storage,
pre[data-language] .keyword,
pre[data-language] .selector,
pre[data-language] .support.tag-name,
pre[data-language] *[data-language="ruby"] .storage {
    font-style: normal;
    color: #F92672 !important;
}

pre[data-language] .variable.parameter.function,
pre[data-language] .variable.parameter.function {
    color: #FD971F !important;
}

pre[data-language] .inherited-class,
pre[data-language] .inherited-class {
    font-style: italic;
}

pre[data-language] .entity,
pre[data-language] .support.attribute,
pre[data-language] .entity,
pre[data-language] .support.attribute {
    color: #A6E22E !important;
}

pre[data-language] .function.call,
pre[data-language] .support,
pre[data-language] .function.call,
pre[data-language] .support,
*[data-language="c"] .function.call {
    color: #66D9EF !important;
}

pre[data-language] .variable.global,
pre[data-language] .variable.class,
pre[data-language] .variable.instance,
pre[data-language] .variable.global,
pre[data-language] .variable.class,
pre[data-language] .variable.instance {
    color: #A6E22E !important;
}

pre[data-language] .close {
    float: none !important;
    font-size: initial !important;
    opacity: 1 !important; 
    text-shadow: none !important;
    line-height: inherit !important;
}

/* Custom */
pre[data-language="csharp"] .operator {
    font-style: normal;
    color: #F92672 !important;
}

pre[data-language="csharp"] .integer {
    font-style: normal;
    color: #fff !important;
}