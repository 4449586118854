$Poppins: 'Poppins', sans-serif;
#popover_container{
    inset: unset !important;
    position: unset !important;
    transform: none !important;

    &:not(.show){
        display: none;
    }
}
#report_curriculum_popover{
    background-color: #121212;
    border: none;
    max-width: 476px;
    position: absolute;
    z-index: 10;

    .popover-body{
        padding: 10px ​18px 14px 18px;
        form{
            position: relative;
            span{
                color: #fff;
                display: flex;
                font-family: $Poppins;
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: 18px;
                margin-bottom: 6px;
            }
    
            textarea{
                background-color: transparent;
                border-radius: 2px;
                border: 1px solid rgba(248, 250, 255, 0.4);
                box-sizing: border-box;
                color: #fff;
                font-family: $Poppins;
                font-size: 12px;
                font-style: normal;
                font-weight: normal;
                height: 120px;
                line-height: 18px;
                margin-bottom: 31px;
                outline: none;
                padding: 5px 10px;
                resize: none;
                width: 440px;
            }
    
            button{
                background: rgba(204, 224, 255, 0.14);
                border-radius: 6px;
                border: none;
                bottom: 0px;
                color: #81A0EA;
                font-family: $Poppins;
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                height: 26px;
                line-height: 18px;
                outline: none;
                position: absolute;
                right: 0px;
                text-align: center;
                width: 86px;

                &:disabled{
                    opacity: .5;
                }
            }
        }
        
    }

    .arrow{
        left: 44%;
        &:before{
            border-top-color: #121212;
            bottom: 1px;
        }

        &:after{
            border-top-color: #121212;
        }
    }
    button{
        background-color: transparent;
        border: none;
        color: #fff;
        font-family: $Poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px;
        margin-bottom: 0px;
        outline: none;
        padding: 0px;

        svg{
            margin-right: 5px;
        }
    }
}