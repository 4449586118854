$Poppins: 'Poppins', sans-serif;

#admin_exam_container{
    background-color: #121212;
    display: flex;
    height: 100vh;

    #admin_right_container{
        display: flex;
        flex-direction: column;
        flex: 1;
        overflow: hidden;
        padding: 12px 30px 20px 30px;
        position: relative;
  
        #admin_exam_table_filter_container{   
            display: flex;
            position: relative;
            .button_container{
                display: flex;
                position: absolute;
                right: 0px;
                top: 17px;
                button{
                    align-items: center;
                    background-color: rgba(204, 224, 255, 0.14);
                    border-radius: 2px;
                    border: none;
                    color: #81A0EA;
                    display: inline-flex;
                    font-family: $Poppins;
                    font-size: 12px;
                    font-weight: 600;
                    height: 32px;
                    outline: none;
                    padding: 0px 10px;
                    span{
                        background-image: url("https://assets.codingdojo.com/learn_platform/admin/exam/admin_exam_icons_new.png");
                        background-repeat: no-repeat;
                        display: inline-block;
                        height: 21px;
                        margin-right: 14px;
                        width: 21px;
                    }
                }
                
                #exam_code_btn{
                    margin-right: 20px;
                    span{
                        background-position: -2px -1px;
                    }     
                }
                #add_exam_record_btn{
                    span{
                        background-position: -34px -1px;
                    }
                }
            }
            form{
                padding-right: 333px;
            }
        }

        /* Use for belt exam summaries and table */
        .belt_icon{
            background-image: url("https://assets.codingdojo.com/learn_platform/admin/exam/admin_exam_icons_new.png");
        }
    }
}

form#search_student_name_and_email_form{
    display: flex;
    position: absolute;
    p{
        color: #B2B2B2;
        display: none;
        font-family: "Poppins", sans-serif;
        font-size: 12px;
        line-height: 14px;
        margin:6px 0px 0px 10px;
        width: 320px;
    }
    svg{
        color: #73839C;
        font-size: 14px;
        left: 12px;
        margin-right: 10px;
        position: absolute;
        top: 12px;
        z-index: 2;
    }
    input{
        align-items: center;
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), #121212;
        border-radius: 4px;
        border: 1px solid transparent;
        color: rgba(255, 255, 255, 0.88);
        font-family: $Poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: normal;
        height: 40px;
        line-height: 140%;
        max-width: calc(100vw - 1093px);
        min-width: 256px;
        padding: 0px 12px 0px 36px;
        position: relative;

        &:focus{
            background: #2A2A2A;
            border: 1px solid #81A0EA;
            box-shadow: 0px 0px 6px #81A0EA;
            outline: none;
        }

        &:focus + p{
            display: block;
        }
    }
}
