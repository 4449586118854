#receiving_instructors_container{
    color: #F6F9FD;
    padding-right: 30px;
    text-align: center;
    width: 459px;
    
    ul#receiving_instructors_list{
        padding: 0px;
        list-style: none;
        >li{
            background: linear-gradient(0deg, rgba(255, 255, 255, 0.24), rgba(255, 255, 255, 0.24)), #121212;
            border-radius: 2px;
            margin-bottom: 20px;
            padding: 20px 20px 3px;
            border: 2px solid transparent;
            &.has_student_content{
                padding-bottom: 20px;
            }
            &.is_selected_ondrag{
                background: #333333;
                border: 2px solid rgba(154, 194, 255, 0.3) !important;
            }
            div{
                align-items: flex-start;
                display: flex;
                justify-content: space-between;
                margin-bottom: 6px;
                h5{
                    margin-right: 30px;
                    padding-left: 56px;
                    position: relative;
                    text-align: left;
                    font: normal 500 16px/20px 'Poppins', sans-serif;

                    span{
                        background: #333333;
                        border-radius: 2px;
                        color: rgba(255, 255, 255, 0.58);
                        display: inline-block;
                        font: normal 700 20px/35px 'Poppins', sans-serif;
                        height: 40px;
                        left: 0px;
                        min-width: 40px;
                        padding: 3px 1px;
                        position: absolute;
                        text-align: center;
                    }
                }
                p{
                    color: rgba(255, 255, 255, 0.88);
                    font-size: 12px;
                    margin: 0px 0px 0px;
                    opacity: 0.6;
                    text-align: left;
                    width: 155px;
                   span{
                    display: block;
                   }
                }
            }
            h6{
                align-items: center;
                display: flex;
                font-size: 12px;
                justify-content: space-between;
                margin-bottom: 11px;
                padding-left: 56px;

                span{
                    color: rgba(255, 255, 255, 0.88);
                    display: flex;
                    font: normal 400 10px/14px 'Poppins', sans-serif;
                    opacity: .6;
                    width: 155px;
                }
            }
            button{
                color: #81A0EA;
                font-size: 18px;
                line-height: 16px;
                margin-bottom: 9px;
            }
            ul{
                display: flex;
                padding: 0px 0px 10px 56px;
                li{
                    align-items: center;
                    display: flex;
                    list-style: none;
                    margin-right: 15px;
                    &:last-child{
                        margin-right: 0px;
                    }
                    span{
                        background-image: url("./../../../../assets/images/admin/rostering/matching_gender_icon.png");
                        display: inline-block;
                        height: 20px;
                        margin-right: 3px;
                        width: 20px;
                        &.male_gender_icon{
                            background-position: -4px -3px;
                            height: 13px;
                            margin-right: 8px;
                            width: 13px;
                        }
                        &.female_gender_icon{
                            background-position: -25px -4px;
                            height: 13px;
                            width: 9px;
                        }
                        &.others_gender_icon{
                            background-position: -44px -4px;
                            height: 13px;
                            width: 12px;
                        }
                        &.veteran_icon{
                            background-position: -65px -2px;
                            height: 20px;
                            width: 9px;
                        }
                        &.accommodation_icon{
                            background-image: none;
                            color: #FF6E1B;
                            margin-right: 0px;
                            width: 17px;
                        }
                    }
                }
            }
            .scroll_container{
                -webkit-transition: max-height 0.3s ease-in-out;
                -moz-transition: max-height 0.3s ease-in-out;
                -o-transition: max-height 0.3s ease-in-out;
                transition: max-height 0.3s ease-in-out;
                &.hide{
                    max-height: 0px;
                    -webkit-transition: max-height 0.1s ease-in-out;
                    -moz-transition: max-height 0.1s ease-in-out;
                    -o-transition: max-height 0.1s ease-in-out;
                    transition: max-height 0.1s ease-in-out;
                }
                table{
                    thead{
                        tr{
                            th{
                                background: #121212;
                                padding: 5px 7px;
                                color: rgba(255, 255, 255, 0.58);           
                                font-weight: 500;
                               
                                &:first-child{
                                    padding-left: 8px;
                                    width: 20px;
                                }
                                &:nth-child(2){
                                    width: 79px;
                                }
                                &:nth-child(3){
                                    width: 25px;
                                }
                                &:nth-child(4){
                                    width: 21px;
                                }
                                &:nth-child(5){
                                    width: 41px;
                                }
                                &:last-child{
                                    width: 113px;
                                }

                                .checkbox_label{
                                    width: 12px;
                                    height: 12px;   
                                    svg{
                                        font-size: 8px;
                                        margin-top: 0px;
                                    }
                                }
                            }
                        }
                    }
                    tbody{
                        tr{
                            &.is_dragging{
                                min-height: 38px;
                                td{
                                    #student_count_on_drag {
                                        top: 5px !important;
                                    }
                                }
                            }
                            
                            &:nth-child(even){
                                background: linear-gradient(0deg, rgba(255, 255, 255, 0.14), rgba(255, 255, 255, 0.14)), #121212;
                            }
                            &:nth-child(odd){
                                background: linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)), #121212;
                            }
                            
                            td{
                                border: none;
                                font-size: 12px;
                                padding: 5px;
                                text-align: left;
                                font-weight: 500;
                                &:first-child{
                                    padding-left: 8px;
                                }
                                &:nth-child(3){
                                    text-align: center;
                                }

                                .checkbox_label{
                                    height: 12px;
                                    margin-top: 4px;
                                    width: 12px;
                                    svg{
                                        font-size: 8px;
                                    }
                                }
                                .is_veteran{
                                    background-position: -62px -4px;
                                    height: 16px;
                                    margin-top: 1px;
                                    vertical-align: top;
                                    width: 16px;
                                }
                                .has_accommodation{
                                    background-image: none;
                                    background: #FF6E1B;
                                    border-radius: 4px;
                                    color: #E4E4E4;
                                    font-size: 11px;
                                    margin-top: 1px;
                                    padding: 0px 4px;
                                    vertical-align: middle;
                                }
                                .last_belt{
                                    background-color: rgba(84, 204, 176, 0.4);
                                    border-radius: 4px;
                                    color: rgba(5, 218, 128, 1);
                                    font-weight: 500;
                                    padding: 2px 17px 2px 6px;
                                    position: relative;
                                    white-space: nowrap;
                                    &:after{
                                        background-image: url("https://assets.codingdojo.com/learn_platform/admin/rostering/belts_icon_new.png");
                                        background-position: 1px -1px;
                                        background-repeat: no-repeat;
                                        content: "";
                                        height: 10px;
                                        position: absolute;
                                        right: 5px;
                                        top: 5px;
                                        width: 10px;
                                    }
                                    
                                    &.yellow{
                                        &:after{
                                            background-position: 0px 0px;
                                        }
                                    }
                                    &.red{
                                        &:after{
                                            background-position: -12px 0px;
                                        }
                                    }
                                    &.black{
                                        &:after{
                                            background-position: -24px 0px;
                                        }
                                    }
                                    &.orange{
                                        &:after{
                                            background-position: -36px 0px;
                                        }
                                    }
                                    &.white{
                                        &:after{
                                            background-position: -48px 0px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    @media screen and (min-width: 1367px) {
        width: 540px;
        ul#receiving_instructors_list{
            > li{
                .scroll_container{
                    table{
                        tbody{
                            tr{
                                td:nth-child(2){
                                    width: 180px;
                                }
                            }
                        }
                    }
                }

            }

        }
    }
    @media screen and (max-width: 1366px) {
        padding-right: 20px;
        ul#receiving_instructors_list{
            > li{
                div{
                    p{
                        span:last-child{
                            width: 127px;
                        }
                    }
                }
            }
        }
    }
}

#program_type_tooltip{
    .tooltip-inner{
        background: #080808 !important;
    }
    .arrow:before{
        border-top-color: #080808 !important; 
    }
    #instructor_program_type_list{
        list-style: none;
        margin-bottom: 0px;
        padding: 10px;
        li{
            margin-bottom: 10px;
            text-align: left;
            &:last-child{
                margin-bottom: 0px;
            }
        }
    }
}
