
$semi_dark_bg:linear-gradient( 0deg , rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), #121212;

#view_feedback_modal{
    font-family: "Poppins", sans-serif;
    max-width: 600px;
    width: 600px;
    .modal-content{
        background: #FFFFFF;
        border-radius: 6px;
        border: 1px solid #DCE6FF;
        box-shadow: 0px 4px 2px rgba(0, 0, 0, 0.05);
        box-sizing: border-box;

        .modal-header{
            border: none;
            border-bottom: 1px solid #2C6BFF;
            justify-content: flex-start;
            margin: 20px 20px 18px 20px;
            padding: 0px 0px 16px 0px;
            position: relative;
            
            img{
                border-radius: 100%;
                height: 40px;
                width: 40px;
            }

            .right_container{
                align-items: baseline;
                display: flex;
                flex-wrap: wrap;
                margin-left: 20px;

                h5{
                    color: #152C61;
                    font-family: "Poppins", sans-serif;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: normal;
                    line-height: 24px;
                    margin-bottom: 0px;
                    width: 100%;
                }

                span{
                    color: #152C61;
                    font-family: "Poppins", sans-serif;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: 17px;
                }

                ul{
                    display: flex;
                    flex-wrap: wrap;
                    flex: 1;
                    margin: 0px 0px 0px 22px;

                    li{
                        line-height: 18px;
                        &:not(:last-child){
                            margin-right: 30px;
                            position: relative;

                            &:after{
                                -webkit-transform: rotate(-45deg);
                                border: solid #152C61;
                                border-width: 0 1.9px 1.9px 0;
                                content: "";
                                display: inline-block;
                                padding: 1.9px;
                                position: relative;
                                transform: rotate(-45deg);
                                position: absolute;
                                top: 7px;
                                right: -17px;
                            }
                        }
                        
                        a{
                            color: #152C61;
                            font-family: "Poppins", sans-serif;
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 300;
                        }
                    }
                }
            }

            button{
                background-color: transparent;
                border: none;
                outline: none;
                padding: 0px;
                position: absolute;
                right: -9px !important;
                top: -10px !important;

                .close_icon{
                    background-image: url("https://assets.codingdojo.com/learn_platform/global/close_icon_new.png");
                    display: inline-block;
                    height: 33px;
                    width: 33px;
                }
            }
        }
        .modal-body{
            padding: 0px 80px 6px;
            
            p{
                color: #152C61;
                font-family: "Poppins", sans-serif;
                font-size: 12px;
                font-style: normal;
                font-weight: 300;
                line-height: 18px;
                white-space: pre-wrap;
            }
        }
    }
}

.is_dark_mode{
    #view_feedback_modal{
        
        .modal-content{
            background: $semi_dark_bg !important;
            border: none;
            .modal-header{
                border-bottom-color: #81A0EA;
                button{
                    .close_icon{
                        background-position: -61px 0px;
                    }
                }
                .right_container{
                    h5, span{
                        color: #CCCCCC;
                    }

                    ul{
                        li{
                            &:after{
                                border-color:#CCCCCC;
                            }
                            a{
                                color: #CCCCCC;
                            }
                        }
                    }
                }
            }
            .modal-body{
                p{
                    color: #CCCCCC;
                }
            }
        }
    }
}