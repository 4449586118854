
$Poppins: "Poppins", sans-serif;

.new_product_tour_tooltip{
    background: #121212;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    padding: 25px 20px 20px;
    width: 390px;

    .notch{
        display: none;
    }

    > p{
        color: #F8FAFF;
        font: 12px/15.6px $Poppins;
    }

    > h3{
        color: #DCE6FF;
        font: 500 14px/21px $Poppins;        
        margin-bottom: 10px;
    }

    .current_step{
        margin-bottom: 10px;
    }

    .img_container{
        background: #272727;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        margin-bottom: 20px;
        padding-top: 14px;

        .img{
            border-radius: 6px 6px 0 0;
            height: 146px;
            width: 171px;
        }
    }

    .new_product_tour_btn_container{
        display: flex;
        margin-top: 5px;
        width: 100%;

        button {
            font: 400 14px/21px $Poppins;
            height: 36px;

            &.skip_btn{
                background: none;
                border-radius: 6px;
                border: 1px solid #81A0EA;
                color: #DCE6FF;
                margin-right: auto;
                width: 80px;
            }
    
            &.prev_btn{
                background: none;
                border: none;
                color: #81A0EA;
                margin-left: auto;
                margin-right: 20px;
                outline: none;
            }
    
            &.next_btn{
                background: #2B2E33;
                border-radius: 6px;
                border: none;
                color: #81A0EA;
                outline: none;
                width: 80px;
            }
        }
    }
}

#react-joyride-step-0{
    .new_product_tour_tooltip{
        align-items: center;
        background: #2B2E33;
        padding: 30px 0 28px 0;
        text-align: center;

        .img{
            background: url("https://assets.codingdojo.com/learn_platform/global/walkthrough_light_mode.png") -5px -5px;
            height: 146px;
            width: 171px;         
        }
        
        .img_container{
            background: none;
            padding-top: 0;

        }

        .new_product_tour_btn_container{
            margin-top: 13px;
            padding-inline: 20px;
            button {
                &.skip_btn{
                    width: 129px;
                }
                &.next_btn{
                    padding-right: 0;
                    width: 180px;
                }
            }
        }

        > h3{
            font: 700 32px/48px $Poppins;
        }
    }
}

#react-joyride-step-1{
    .img{
        background: url("https://assets.codingdojo.com/learn_platform/global/walkthrough_light_mode.png") -186px -5px;
        height: 163px;
        width: 176px; 
    }
}

#react-joyride-step-2{
    .img{/* 
        background: url("https://assets.codingdojo.com/learn_platform/global/walkthrough_light_mode.png") -372px -5px; */
        background: url("https://assets.codingdojo.com/learn_platform/global/walkthrough_light_mode.png") -558px -5px;
        height: 163px;
        width: 176px; 
    }
}

#react-joyride-step-3{
    .img{/*
        background: url("https://assets.codingdojo.com/learn_platform/global/walkthrough_light_mode.png") -558px -5px;
        height: 170px;
        width: 176px; */ 

        background: url("https://assets.codingdojo.com/learn_platform/global/walkthrough_light_mode.png") -744px -5px;
        height: 166px; 
        width: 195px;  
    }
}

/*
#react-joyride-step-4{
    .img{
        background: url("https://assets.codingdojo.com/learn_platform/global/walkthrough_light_mode.png") -744px -5px;
        height: 166px;
        width: 195px; 
    }
} */

.new_product_tour_tooltip + .__floater__arrow{
    span{
        top: 20px !important;
        svg{
            polygon{
                fill: #121212;
            }
        }
    }
}

#react-joyride-step-1{
    .new_product_tour_tooltip + .__floater__arrow{
        right: 8px !important;
    }
}

#react-joyride-step-2,
#react-joyride-step-3,
#react-joyride-step-4{
    .new_product_tour_tooltip + .__floater__arrow{
        left: 8px !important;
    }
}

.is_dark_mode{

    .new_product_tour_tooltip{
        background: #423D4D;
        outline: 2px solid #595959;

        .img_container{
            background: #1E4BB3;

        }

        > h3{
            color: #E5E5E5;
        }

        > p{
            color: #B3B3B3;
        }

        button{
            &.skip_btn{
                color: #B3B3B3
            }
            &.next_btn{
                background: #2C6BFF;
                color: #FFFFFF;
            }
            &.prev_btn{
                color: #B2B2B2;
            }
        }
    }

    #react-joyride-step-0{
        .new_product_tour_tooltip{
            background: #423D4D;
    
            .img_container{
                background: none;
            }
    
            .new_product_tour_btn_container{
                button{
                    &.skip_btn{
                        color: #B3B3B3
                    }
                    &.next_btn{
                        background: #2C6BFF;
                        color: #FFFFFF;
                    }
                    &.prev_btn{
                        color: #2C6BFF;
                    }
                }
            }
    
            > h3{
                color: #E5E5E5;
            }

            > p{
                color: #B3B3B3;
            }
        }
    }

    #react-joyride-step-1{
        .img{
            background: url("https://assets.codingdojo.com/learn_platform/dark_mode/global/walkthrough_dark_mode.png") -5px -5px;
            height: 163px;
            width: 176px; 
        }
    }
    #react-joyride-step-2{
        .img{
            background: url("https://assets.codingdojo.com/learn_platform/dark_mode/global/walkthrough_dark_mode.png") -191px -5px;
            height: 162px;
            width: 176px; 

        }
    }
    #react-joyride-step-3{
        .img{
            background: url("https://assets.codingdojo.com/learn_platform/dark_mode/global/walkthrough_dark_mode.png") -377px -5px;
            height: 166px;
            width: 176px; 
        }
    }
    #react-joyride-step-4{
        .img{
            background: url("https://assets.codingdojo.com/learn_platform/dark_mode/global/walkthrough_dark_mode.png") -563px -5px;
            height: 167px;
            width: 176px; 
        }
    }
    
    #react-joyride-step-1,
    #react-joyride-step-4{
        .new_product_tour_tooltip + .__floater__arrow{
            span{
                svg{
                    polygon{
                        stroke-dasharray: 20 50;
                        stroke-dashoffset: -12;
                        stroke-width: 2px;
                        stroke: #595959;
                    }
                }
            }
        }
    }

    #react-joyride-step-2,
    #react-joyride-step-3{
        .new_product_tour_tooltip + .__floater__arrow{
            span{
                svg{
                    polygon{
                        stroke-dasharray: 20 50;
                        stroke-dashoffset: 25;
                        stroke-width: 2px;
                        stroke: #595959;
                    }
                }
            }
        }
    }

    .new_product_tour_tooltip + .__floater__arrow{
        span{
            top: 20px !important;
            svg{
                polygon{
                    fill: #423D4D;
                }
            }
        }
    }

    .react-joyride__overlay{
        background-color: rgba(0, 0, 0, .5) !important;
    }
    
}

@media only screen and (max-width: 768px){
    .react-joyride__overlay{
        background-color: rgba(0, 0, 0, 0.5) !important;
    }

    .__floater.__floater__open{
        bottom: 0 !important;
        left: 50% !important;
        padding: 0 !important;
        position: fixed !important;
        top: unset !important;
        transform: translate(-50%, 0%) !important;
        transition: none !important;

        .__floater__arrow{
            display: none !important;
        }

        .new_product_tour_tooltip{
            border-radius: 28px 28px 0px 0px;
            max-width: 100% !important;
            padding: 16px 44px 20px;
            width: 388px !important;


            &::before{
                background: #938F99;
                border-radius: 100px;
                content: '';
                display: block;
                height: 4px;
                margin: 0 auto;
                opacity: 0.4;
                width: 32px;
            }

            > h3{      
                &.large{
                    font: 700 24px/36px $Poppins;
                }
            }

            .img_container{            
                margin-top: 34px;
            }

            .new_product_tour_btn_container{
                align-items: center;
                flex-direction: column;
        
                button {
                    &.skip_btn{
                        margin-bottom: 10px;
                        margin-right: 0;
                        width: 300px !important;
                    }
            
                    &.prev_btn{
                        margin: 0 auto !important;
                    }
            
                    &.next_btn{
                        margin-top: 10px;
                        width: 300px !important;
                    }
                }
            }
        }
    }

    #react-joyride-step-0{
        .new_product_tour_tooltip{
            border-radius: 0;
            height: 100vh;
            justify-content: center;
            margin: 0 !important;
            padding: 0;
            width: 100vw !important;

            &::before{
                display: none;
            }

            .img_container{            
                margin-top: 0;
            }

            .new_product_tour_btn_container{
                margin-top: 8px;
            }
        }
    }

    #react-joyride-step-4{
        .__floater__open {
            bottom: 0px !important;
        }
    }
}