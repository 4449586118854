$Poppins: "Poppins", sans-serif;

.chile_sence_modal{
    .modal-dialog{
        width: 380px;

        &#failed_sence_request_modal{
            z-index: 9999999 !important;
            .modal-content{
                .modal-body{
                    padding-top: 0px;
                    
                    form{
                        justify-content: center;
                    }
                }
            }
        }

        .modal-content{
            border: none;

            .modal-header{
                border: none;

                button{
                    background-color: transparent;
                    border: none;
                    outline: none;
                    padding: 0px;
                    position: absolute;
                    right: 8px;
                    top: 8px;
                    .close_icon{
                        background-image: url("https://assets.codingdojo.com/learn_platform/global/close_icon_new.png");
                        display: inline-block;
                        height: 33px;
                        width: 33px;
                    }
                }
            }

            .modal-body{
                padding: 24px;

                form{
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: flex-end;

                    .warning_icon{
                        background-image: url("./../../../../assets/images/global/chile_sence_icons.png");
                        background-position: -40px 0px;
                        display: flex;
                        height: 78px;
                        margin-bottom: 20px;
                        width: 78px;
                    }

                    p{
                        color: #152C61;
                        font-family: $Poppins;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: normal;
                        line-height: 24px;
                        margin-bottom: 25px;
                    }

                    button{
                        border-radius: 6px;
                        border: none;
                        color: #FFFFFF;
                        font-family: $Poppins;
                        font-size: 18px;
                        font-style: normal;
                        font-weight: normal;
                        height: 45px;
                        line-height: 27px;
                        outline: none;
                        padding: 0px 10px;
                        text-align: center;

                        &[type="button"]{
                            background: #D8D8D8;
                            margin-right: 12px;
                        }
                        &[type="submit"]{
                            background: #2C6BFF;
                        }
                    }
                }
            }
        }
    }
}

body.is_dark_mode{
    .chile_sence_modal{
        .modal-dialog{
            .modal-content{
                .modal-header{
                    button{
                        .close_icon{
                            background-position: -61px 0px;
                        }
                    }
                }
    
                .modal-body{
                    form{
                        p{
                            color: #ffffff;
                        }
    
                        button{
                            &[type="button"]{
                                background: #979797;
                            }
                            &[type="submit"]{
                                background: #81A0EA;
                            }
                        }
                    }
                }
            }
        }
    }
}