$Poppins: "Poppins", sans-serif;
$blue: #2C6BFF;
$purple: #81A0EA;
$white: #fff;

.curriculum_programs_table_container{
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.14), rgba(255, 255, 255, 0.14)), #121212;
    border-radius: 4px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.07);
    height: 100%;
    margin-top: 20px;
    overflow: hidden;
    position: relative;
    width: 100%;

    *::-webkit-scrollbar {
        height: 9px;
        width: 9px;
    }

    #table_loading_container{
        align-items: center;
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.14), rgba(255, 255, 255, 0.14)), #121212;
        display: flex;
        flex-direction: column;
        left: 50%;
        margin-left: 0;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 50px;
    
        div{
            background-image: url("https://assets.codingdojo.com/learn_platform/admin/global/table_loader.gif");
            background-size: cover;
            height: 30px;
            margin-bottom: 7px;
            mix-blend-mode: lighten;
            width: 30px;
        }
    
        span{
            color: rgba(255, 255, 255, 0.88);
            font-family: $Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 130%;
        }
    }

    .sticky-table{
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.14), rgba(255, 255, 255, 0.14)), #121212;
        overflow: auto;
        position: relative;
        width: 100%;

        .sticky-table-table{
            width: 100%;

            #no_results{
                font: 500 14px $Poppins;
                left: 50%;
                position: absolute;
                top: 50%;
                transform: translate(-50%, -50%);
            }
        }

        .sticky-table-row{
            .sticky-table-cell{
                background: #3D3D3D;
                border-color: #5D5D5D !important;
                border-width: 1px !important;
                font: 400 14px/18.2px $Poppins;
                height: 50px;
                vertical-align: middle;

                .action_btn{
                    background: transparent url("https://assets.codingdojo.com/learn_platform/admin/curriculum_management/admin_curriculum_management_sprite.png") no-repeat -112px -18px;
                    border: none;
                    height: 24px;
                    outline: none;
                    width: 24px;
                }

                &:nth-child(1){
                    border-right: none;
                    cursor: pointer;
                    max-width: 500px;
                    overflow: hidden;
                    padding-left: 25px;
                    text-overflow: ellipsis;
                    width: 500px;
                }
            }

            &:nth-child(odd){
                .sticky-table-cell{
                    background: #444444;
                    color: rgba(255, 255, 255, 0.88);
                }
            }

            /* Table Head */
            &:nth-child(1){
                .sticky-table-cell{
                    background: linear-gradient(0deg, rgba(255, 255, 255, 0.24), rgba(255, 255, 255, 0.24)), #121212;
                    color: rgba(255, 255, 255, .6);
                    font: 600 12px/15.6px $Poppins;
                    height: 40px;
                    z-index: 12;

                    &:nth-child(1),
                    &:nth-child(2){
                        cursor: pointer;
                        .sort_container{
                            align-items: center;
                            display: flex;
                            gap: 22px;
                            .sort_button_container{
                                display: flex;
                                flex-direction: column;
                                > svg{
                                    cursor: pointer;
                                    height: 12px;
                                    &:first-child{
                                        transform: translateY(1.5px) scale(1.2);
                                    }
                                    &:last-child{
                                        transform: translateY(-1.5px) scale(1.2);
                                    }
                                    &.light{
                                        color: $purple;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}