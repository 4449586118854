$blue: #2C6BFF;
$green: #38903C;
$orange: #F2994A;
$purple_color: #81A0EA;
$dark_purple: #501BEE;
$red: #E1554C;
$white_color:rgba(255,255,255,0.88);

#stats_container{
    font: normal 400 12px/18px 'Poppins', sans-serif;
    padding-bottom: 40px;
    position: relative;
    .select_container{
        align-items: center;
        display: flex;
        left: 210px;
        position: absolute;
        top: 130px;
        z-index: 10;
        .fa-caret-right{
            color: #2C6BFF; 
            font-size: 20px;
        }
        .react-dropdown-select{
            background-color: transparent;
            border-radius: 6px;
            display: inline-flex;
            padding: 6px 11px !important;
            width: unset;
            .react-dropdown-select-dropdown-handle{
                display: none;
            }
            .react-dropdown-select-dropdown{
                background: #fff;
                border-radius: 6px;
                border: 1px solid #2C6BFF;
                .react-dropdown-select-item{
                    font: normal 500 14px/18px 'Poppins', sans-serif;
                    padding: 10px;
                }
                .react-dropdown-select-item-selected{
                    background: #fff;
                    color: #2C6BFF;
                }
            }
            .react-dropdown-select-content{
                span{
                    color: #152C61;
                    font: normal 500 16px/22px 'Poppins', sans-serif;
                }
            }
        }
    }
    

    .dropdown_select_container{
        display: inline-flex;
        .dropdown_select{
            margin-right: 10px;
            .nav-item{
                &.show ~ svg{
                    -moz-transform: rotate(180deg);
                    -ms-transform: rotate(180deg);
                    -o-transform: rotate(180deg);
                    -webkit-transform: rotate(180deg);
                    transform: rotate(180deg);
                }
                .dropdown-toggle.nav-link{
                    padding: 3px 20px 3px 10px;
                }
            }

            svg{
                -khtml-transition: all .25s ease;
                -moz-transition: all .25s ease;
                -ms-transition: all .25s ease;
                -o-transition: all .25s ease;
                -webkit-transition: all .25s ease;
                transition: all .25s ease;
            }
        }
    }

    h2{
        align-items: center;
        display: flex;
        font: normal 500 22px/33px 'Poppins', sans-serif;
        justify-content: space-between;
        margin-bottom: 28px;
        position: relative;
    }
    #catalog_link{
        color: #333333;
        font: normal normal 12px/18px 'Poppins', sans-serif;
        opacity: .5;
    }
    #stats_widget_container{        
        align-items: stretch;
        display: flex;
        flex: 0;
        justify-content: center;
        margin-bottom: 20px;
    }
    h3{
        font: normal 500 16px/24px 'Poppins', sans-serif;
    }
    #stats_loading_img{
        display: block;
        margin: 150px auto;
        text-align: center;
        width: 40px;
    }
    button{
        outline: none !important;
    }
    #loading_container{
        margin: 217px auto;
        text-align: center;
        img{
            margin-bottom: 10px;
            width: 50px;
        }
        p{
            font: normal 500 16px/24px 'Poppins', sans-serif;
        }
    }

    table{
        margin-bottom: 20px;
        width: 100%;
        th,td{
            padding: 10px;
        }
        thead{
            tr{
                th{
                    background: #F8FAFF;
                    color: #333333;
                    font: normal 400 12px/18px 'Poppins', sans-serif;
                    opacity: 0.8;
                }
            }
        }
        tbody{
            tr{
                &:last-child{
                    td{
                        border-bottom: none;
                    }
                }
                td{
                    border-bottom: 1px solid #d6d6d6;
                    color: #333333;
                    font: normal 500 12px/18px 'Poppins', sans-serif;

                    &.no_results_found{
                        padding: 50px 0px;
                        text-align: center;
                    }
                    &.red{
                        color: $red;
                    }
                    &.blue, a{
                        color: $blue;
                    }
                    &.green{
                        color: $green;
                    }
                    &.dark_purple{
                        color: $dark_purple;
                    }
                    &.orange{
                        color: $orange;
                    }
                    svg{
                        color: $green;;
                    }
                    button{
                        background: transparent;
                        border: none;
                        color: #2C6BFF;
                        font: normal 500 12px/18px 'Poppins', sans-serif;
                    }
                    .comment_icon{
                           color: #2C6BFF;
                           margin-left: 10px;
                           position: relative;
                        }
                    .has_comment{
                        position: relative;
                        &:after{
                            background: #EB5757;
                            border-radius: 100%;
                            border: 1px solid #fff;
                            content: "";
                            display: block;
                            height: 6px;
                            position: absolute;
                            right: 4px;
                            top: 3px;
                            width: 6px;
                        }
                    }
                }
            }
        }
    }
    #pagination_btns_container{
        display: flex;
        justify-content: center;
        button{
            background: transparent;
            border: none;
            color: #2C6BFF;
            height: 34px;
        }
        #left_btn_pagination{
            margin-right: -27px;
            z-index: 1;
        }
        #right_btn_pagination{
            margin-left: -27px;
        }
        ul{
            border-radius: 6px;
            border: 1px solid #DCE6FF;
            box-shadow: 0px 2.76726px 2.21381px rgba(0, 0, 0, 0.0196802);
            display: inline-flex;
            margin-bottom: 0px;
            padding: 5px 40px;
            li{
                button{
                    color: #333333;
                    font: normal normal 12px/19px 'Poppins', sans-serif;
                    height: unset;
                    padding: 1px 10px;
                    &.active{
                        background: #2C6BFF;
                        border-radius: 6px;
                        color: #fff;
                    }
                }
            }
        }
    }
}
.is_dark_mode{
    #stats_container{
        h2, h3{
            color: rgba(255, 255, 255, 0.88);
            #catalog_link{
                color: #81A0EA;
                opacity: 1;
            }
        }
        .select_container{
            .fa-caret-right{
                color: #81A0EA; 
            }
            .react-dropdown-select{
                background: transparent;
                color: #fff; 
                .react-dropdown-select-content{
                    span{
                        color: rgba(255, 255, 255, 0.88);
                    }
                }
                .react-dropdown-select-dropdown{
                    background: #333333;
                    border: none;
                    .react-dropdown-select-item{
                        border-bottom: 1px solid transparent
                    }
                    .react-dropdown-select-item-selected{
                        background: transparent;
                        color: #81A0EA;
                    }
                }
            }
        }
        #loading_container{
            p{
                color: rgba(255, 255, 255, 0.88);
            }
        }
        .dropdown_select{
            > svg{
                color: #6F81AF;
            }
            .nav-item{
                .dropdown-toggle.nav-link{
                    color: #E5E5E5;
                }

                .dropdown-menu{
                    background: #333333;
                    
                    .nav-link{
                        color: $white_color;

                        &:hover{
                            color: $purple_color;
                        }

                        &.active{
                            color: $purple_color;

                            svg{
                                color: $purple_color;
                            }
                        }
                    }
                }
            }
        }
        #attendance_rate_container{
            background: #292929;
            h3{
                color: #6F81AF;
            }
        }

        #stats_belt_exam_history_container{
            background: #292929;
            h3{
                color: #6F81AF;
            }
        }

        #quiz_summary_table_container{
            background: #292929;
            h3{
                color: #6F81AF;
            }
        }
        
        #belt_exam_history_block{
            img{
                filter: drop-shadow(0px 100px 80px rgba(31, 70, 112, 0.07));
            }
        }
        #core_assignment_completion_rate_container{
            background: #292929;
            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
            h3{
                color: #6F81AF;
            }
        }
        #pagination_btns_container{
            ul{
                border: 1px solid #DCE6FF;
                button{
                    color: #E5E5E5;
                    &.active{
                        background: #6F81AF;
                    }
                }
            }
            button{
                color: #6F81AF;
            }
        }
        
        table{
            thead{
                tr{
                    th{
                        background: #415075;
                        color: $white_color;
                        opacity: 0.8;
                    }
                }
            }
            tbody{
                tr{
                    td{
                        color: #E5E5E5;
                        &.blue, a{
                            color: #6F81AF;
                        }
                        &.orange{
                            color: #EF7E1B;
                        }
                        &.red{
                            color: #E1554C;
                        }
                        &.green{
                            color: #4ce164;
                        }
                        &.dark_purple{
                            color: $purple_color;
                        }
                        .comment_icon{
                            color: #81A0EA;
                         }
                    }
                }
            }
        }
        .e_learning_table_container{
            background: #292929;
        }
        .widget_block, .e_learning_widget {
            background: #292929;
            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
            &.is_active{
                border: 2px solid #81A0EA;
            }
            p{
                color: #BEBEBE;
            }
            button{
                color: #BEBEBE;
                &.active{
                    border-bottom: 1px solid $purple_color;
                    color: $purple_color;
                }

            }
            #belt_exam_history_list{
                li{
                    color: $white_color;
                }
            }
            
            .progress_container{
                .CircularProgressbar-text{
                    fill: #FFFFFF !important;
                }

            }
        }
        #default_content_block{
            background: #292929;
            p{
                color: $white_color;
            }
        }
    }
}

@media only screen and (max-width: 768px){
    #stats_container{
        padding-top: 22px;
        width: 100%;
        .select_container{
            position: unset;
            .react-dropdown-select{
                .react-dropdown-select-content{
                    .react-dropdown-select-input{
                        display: none;
                    }
                }
            }
        }
        #stats_container_mobile{
            background-color: #fff;
            border-radius: 6px;
            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
            margin-bottom: 50px;
            margin-top: 5px;
            padding: 20px;
            position: relative;
            &.no_dropdown{
                margin-top: 0 !important;
            }
            h2{
                color: transparent;
                font-size: 0;
                margin: 10px 0 18px 0;
                &::before{
                    color: black;
                    content: "My Stats";
                    font: normal 500 23px/34.5px 'Poppins', sans-serif;
                }
            }
            #stats_widget_container{
                flex-direction: column;
                margin-bottom: 0;
                .widget_block{
                    border: 1px solid rgba(21, 44, 97, 0.1);
                    box-shadow: unset;
                    margin-bottom: 20px;
                    margin-right: 0px;
                    min-height: 200px;
                    padding: 30px 20px;
                    width: 100% !important;

                    &.is_active{
                        border: 2px solid #2C6BFF;
                    }

                    &#belt_exam_history_block{
                        padding: 30px;
                        
                        img{
                            margin-top: unset;
                        }

                        #belt_exam_history_list{
                            justify-content: space-around;
                            margin-bottom: 0px;

                            li{
                                margin-bottom: 20px;
                                margin-right: 0px;
                            }
                        }

                        .note{
                            position: initial;
                            width: 100%;
                        }
                    }

                    h3{
                        font-size: 14px;
                        font-weight: 500;
                        line-height: 21px;
                        text-align: center;
                    }

                    .progress_container{
                        display: flex;
                        height: auto;
                        width: auto;

                        svg{
                            margin: 0px auto;
                            width: 120px;
                        }
                        &::after{
                            display: none;
                        }
                    }
                }

                h3{
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 21px;
                    text-align: center;
                }

                .progress_container{
                    display: flex;
                    height: auto;
                    width: auto;

                    svg{
                        margin: 0px auto;
                        width: 80%;
                    }
                }
            }
        }

        .dropdown_select_container{
            flex-direction: column;
            margin-bottom: 20px;
            width: 100%;

            .dropdown_select{
                margin-bottom: 15px;
                width: 100%;

                .dropdown{
                    width: 100%;

                    a{
                        white-space: normal;
                    }
                }
            }
        }

        .scroll_container{
            overflow-y: auto;

            table{
                width: 800px;
            }
        }

        #quiz_summary_table_container{
            padding-bottom: 60px;
        }
    }

    .is_dark_mode{
        #stats_container_mobile{
            background: linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), #121212 !important;
            h2{
                &::before{
                    color: rgba(255, 255, 255, 0.88) !important;
                    content: "My Stats";
                }
            }
            .widget_block{
                border: 1px solid #666666 !important;
                &.is_active{
                    border: 2px solid #81A0EA !important;
                }
            }

            #stats_widget_container{
                .button_container{

                    button{
                        border: 1px solid #81A0EA !important;
                        color: #B3B3B3 !important;
                        &.active{
                            background: #2C6BFF;
                            border: 1px solid #2C6BFF !important;
                            color: #fff !important;
                        }
                    }
                }
                & + div{
                    border: 1px solid #666666 !important;
                    box-shadow: unset;
                }
            }
        }
    }
}


@media only screen and (max-width: 520px){
    #stats_container{
        #stats_widget_container{
            .widget_block{
                width: 48%;

                .button_container{
                    display: flex;
                    gap: 20px;
                    flex-wrap: wrap;
                    justify-content: center;

                    button{
                        border-radius: 50px;
                        border: 1px solid #2C6BFF;
                        color: #2C6BFF;
                        font: 600;
                        height: 30px;
                        margin-right: 0px;
                        width: 96px;

                        &.active{
                            background: #2C6BFF;
                            color: #fff;
                        }
                    }
                }

                & + div{
                    box-shadow: unset;
                    border: 1px solid rgba(21, 44, 97, 0.1);
                }
            }
            .react-dropdown-select{
                left: 0;
                position: absolute;
                top: -55px;
                width: 100%;
            }

            .dropdown_select_container{
                flex-direction: column;
                margin-bottom: 20px;
                width: 100%;

                .dropdown_select{
                    margin-bottom: 15px;
                    width: 100%;

                    .dropdown{
                        width: 100%;

                        a{
                            white-space: normal;
                        }
                    }
                }
            }
            #core_assignment_completion_rate_container{
                position: relative;
                .scroll_container{
                    margin-bottom: 40px;
                    overflow-y: auto;
                    #core_assignment_completion_rate_table{
                        margin-bottom: 0;
                    }
                    #pagination_btns_container{
                        bottom: 30px;
                        left: 50%;
                        position: absolute;
                        transform: translateX(-50%);
                    }
                    table{
                        width: 800px;
                    }
                }
            }
        }
    }
}