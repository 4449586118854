$Poppins: "Poppins", sans-serif;

#discussion_questions_container{
    font: normal 500 16px/normal $Poppins;
    margin-top: 16px;
    h6{
        color: #152C61;
    }
    div.skeleton_loading{
        border-radius: 6px;
        height: 90px;
        margin-top: 16px;
    }
    .discussion_questions{
        align-items: center;
        border-radius: 6px;
        display: flex;
        justify-content: space-between;
        margin-top: 16px;
        min-height: 90px;
        padding: 20px;
        &.unanswered{
            background-color: #FEF5ED;
            border: 1px solid rgba(21, 44, 97, 0.10);
            a{
                background-color: #F2994A !important;
                border-radius: 6px;
                color: #fff !important;
                font: normal 500 14px/normal $Poppins;
                min-width: 125px;
                padding: 4px 12px;
                text-decoration: none;
            }
        }
        &.completed{
            background-color: rgb(207, 247, 213);
            border: 1px solid rgba(21, 44, 97, 0.10);
            > div{
                span{
                    background-color: transparent;
                    padding: 0;
                    margin-left: 4px;
                    svg{
                        position: relative;
                        top: -1px;
                        > circle{
                            fill: #4CE164;
                        }
                    }
                }
            }
            a{
                color: #1DAA33 !important;
                font: normal 500 14px/normal $Poppins;
                min-width: 113px;
                text-decoration: none;
            }
        }
        > div{
            display: flex;
            flex-direction: column;
            > div{
                color: #152C61;
                font: normal 500 14px/normal $Poppins;
                span{
                    background-color: #FBE0C9;
                    border-radius: 6px;
                    color: #F2994A;
                    font: normal 500 12px/normal $Poppins;
                    margin-left: 20px;
                    padding: 2px 12px;
                }
            }
            p{
                color: #666;
                font: normal 400 10px/180% $Poppins;
                margin: 10px 0 0 0;
            }
        }
    }
}
body.is_dark_mode{
    #discussion_questions_container{
        h6{
            color: #e5e5e5;
        }
        .discussion_questions{
            &.completed{
                background-color: #037846;
                a{
                    color: #05DA80 !important;
                }
                > div{
                    > div{
                        color: rgba(255, 255, 255, 0.88);
                    }
                    p{
                        color: #e5e5e5;
                    }
                }
            }
            &.unanswered{
                background-color: #FCF4D9;
                a{
                    background-color: #FFC656 !important;
                    color: #fff !important;
                }
                > div{
                    > div{
                        color: #81A0EA;
                        span{
                            background-color: #F9E5A9;
                            color: #BD7D00;
                        }
                    }
                }       
            }
        }
    }
}

@media only screen and (max-width: 768px){
    #discussion_questions_container{
        .discussion_questions{
            align-items: flex-start;
            flex-direction: column;
            height: 100%;
            &.completed{
                a{
                    padding-right: 0px;
                }
            }
            a{
                margin-top: 10px;
                text-align: center;
                width: 100%;
            }
        }
    }
}