
$Poppins: "Poppins", sans-serif;

.forum_tour_tooltip{
    background: #fff;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    padding: 20px;
    width: 425px;

    > .content{
        color: #152c61;
        font: 400 12px/15.6px $Poppins;
        margin-bottom: 35px;
    }

    > h3{
        color: #DCE6FF;
        font: 500 14px/21px $Poppins;        
        margin-bottom: 10px;
    }

    .product_tour_btn_container{
        display: flex;
        margin-top: 5px;
        width: 100%;

        button {
            font: 400 14px/21px $Poppins;
            height: 30px;

            &.skip_btn{
                background: none;
                border-radius: 6px;
                border: 1px solid #dce6ff;
                color: #2c6bff;
                margin-right: auto;
                width: 80px;
            }
    
            &.prev_btn{
                background: none;
                border: none;
                color: #2c6bff;
                margin-left: auto;
                margin-right: 20px;
                outline: none;

                &:disabled{
                    cursor: initial;
                    opacity: .5;
                }
            }
    
            &.next_btn{
                background: #2c6bff;
                border-radius: 6px;
                border: none;
                color: #fff;
                font: 400 14px/21px $Poppins;
                outline: none;
                width: 80px;
            }
        }
    }
}

.react-joyride__overlay{
    z-index: 10001 !important;
}

.__floater__open{
    z-index: 10002 !important;
    &[x-placement="left"]{
        .product_tour_tooltip{
            .current_step{
                top: -10px;
            }
        }
    }
    &[x-placement="top-start"]{
        .product_tour_tooltip{
            .current_step{
                top: -15px;
            }
        }
    }
}

.is_dark_mode{
    .forum_tour_tooltip{
        background: #444444;

        & + .__floater__arrow{
            span{
                svg{
                    polygon{
                        fill: #444444;
                    }
                }
            }
        }

        > .content{
            color: #fff;
        }

        button{
            &.skip_btn{
                border: 1px solid #81a0ea;
                color: #81a0ea;
            }
            &.next_btn{
                background: #81a0ea;
                color: #FFFFFF;
            }
            &.prev_btn{
                color: #81a0ea;
            }
        }
    }
}
