$Poppins: 'Poppins', sans-serif;

.student_profile_dropdown_select{
    background: rgba(204, 224, 255, 0.14);
    border-radius: 2px;
    height: 40px;
    outline: none;
    padding: 0 16px !important;

    &.has_value{
        background: rgba(204, 224, 255, 0.14);
        border: none !important;

        .react-dropdown-select-content{
            color: rgba(255, 255, 255, 0.88);
            font-weight: 400;
        }
    }

    &.is_locked{
        background: rgba(217, 75, 75, 0.1);
        transition: .25s ease-in-out;

        .react-dropdown-select-content{
            color: #F56B6B;
            justify-content: flex-start;

            .locked_btn{
                background: url("https://assets.codingdojo.com/learn_platform/admin/student_access/admin_student_access_icon.svg") -122px 2px #571E1E;
            }
        }
    }

    &:hover{
        .react-dropdown-select-content{
            >button{
                opacity: 1;
                transition: .25s ease-in-out;
            }
        }
    }

    .react-dropdown-select-content{
        color: #757575;

        span{
            font-family: $Poppins;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
        }

        >button{
            margin-left: 10px;
        }

        .locked_btn{
            align-items: center;
            background: url("https://assets.codingdojo.com/learn_platform/admin/student_access/admin_student_access_icon.svg") -94px 2px #343A43;
            border-radius: 2px;
            border: none;
            display: flex;
            height: 24px;
            justify-content: center;
            margin-left: auto;
            opacity: 0;
            outline: none;
            transition: .25s ease-in-out;
            width: 24px;
        }

        .delete_btn{
            align-items: center;
            background: #343A43;
            border-radius: 2px;
            border: none;
            display: flex;
            height: 24px;
            justify-content: center;
            opacity: 0;
            outline: none;
            transition: .25s ease-in-out;
            width: 24px;

            .delete_icon{
                background: url("https://assets.codingdojo.com/learn_platform/admin/student_access/upload_csv_icons.svg") no-repeat -18px 5px #343A43;
                display: block;
                height: 24px;
                width: 24px;
            }
        }
    }
    .react-dropdown-select-dropdown-handle{
        color: #E5E5E5;
        display: flex;
        margin: 0px 0px 0px 3px;

        svg{
            height: 12px;
            width: 12px;
        }
    }

    .react-dropdown-select-dropdown {
        background: #5E6065;
        border-radius: 4px;
        padding: 20px 20px 0 20px;
        top: 40px;
        width: 100%;

        span{
            background-color: transparent !important;
            border: none;
            color: rgba(255, 255, 255, 0.88);
            font-family: $Poppins;
            font-size: 12px;
            font-style: normal;
            font-weight: normal;
            line-height: 130%;
            margin-bottom: 0;
            padding: 0px;

            &:not(:last-child){
                margin-bottom: 27px;
            }

            &.react-dropdown-select-item-selected{
                color: #81A0EA;
                font-weight: 600;
            }
        }

        .dropdown_search_container{
            padding: 0 0 20px;
        }

        .dropdown_menu{
            padding: 20px;
        }
    }
}