$Poppins: 'Poppins', sans-serif;

.is_show_sucess_message{
    #add_workspace_modal{
        height: unset;
        min-height: unset;
    }
}
#add_workspace_modal{
    height: 735px;
    left: 50%;
    max-width: 920px;
    min-height: 735px;
    min-width: 680px;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    .modal-content{
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.24), rgba(255, 255, 255, 0.24)), #121212;
        border-radius: 0px;
        box-shadow: 0px 4px 2px rgba(0, 0, 0, 0.05);
        color: rgba(255, 255, 255, 0.88);
        font-family: $Poppins;
        height: 100%;

        input{
            outline: none;
        }

        .modal-header{
            background: linear-gradient(0deg, rgba(255, 255, 255, 0.14), rgba(255, 255, 255, 0.14)), #121212;
            border-radius: 0px;
            border: none;
            justify-content: left;
            padding: 18px 23px;
            position: relative;
    
            h4{
                font-family: $Poppins;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: 16px;
                margin-bottom: 0px;
            }
            button{
                background-color: transparent;
                background-image: url("https://codingdojo_assets.s3.us-east-1.amazonaws.com/learn_platform/admin/edit_course/admin_edit_course_sprite.png");
                background-position: -65px 0px;
                border: none;
                height: 15px;
                outline: none;
                padding: 0px;
                position: absolute;
                right: 22px;
                top: 20px;
                width: 15px;
            }
        }
        .modal-body{
            display: flex;
            padding: 0px;
            .success_container{
                background: linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), #121212;
                padding: 55px;
                p{
                    font-family: $Poppins;
                    font-size: 14px;
                    margin-bottom: 0px;
                    text-align: center;
                }
            }
            .dropdown_select{
                background: #4B4B4B;
                border-radius: 6px;
                height: 50px;
                padding: 2px 14px !important;
                &[aria-expanded="true"]{
                    border: 1px solid #81A0EA !important;
                    box-shadow: 0px 0px 6px #81A0EA !important;
                }
                .react-dropdown-select-content{
                    justify-content: unset;
                }
                .react-dropdown-select-input{
                    padding: unset !important;
                    width: unset !important;
                    &:focus{
                        outline: none;
                    }
                    &:focus-within{
                        border: none !important;
                        box-shadow: none !important;
                    }
                }
                .react-dropdown-select-dropdown-handle{
                    svg{
                        height: 27px;
                    }
                }
                .react-dropdown-select-dropdown{
                    border-radius: 4px;
                    border: 1px solid #81A0EA;
                    box-shadow: 0px 0px 6px #81A0EA;
                    margin-top: 15px;
                    max-height: 189px;
                    width: 100%;
                    .react-dropdown-select-no-data{
                        color: #81A0EA;
                        font-family: $Poppins;
                        font-size: 12px;
                    }
                    .react-dropdown-select-item{
                        border: none;
                        font-size: 12px;
                        &.react-dropdown-select-item-selected{
                            background: transparent;
                            color: #81A0EA;
                            position: relative;
                            &:after{
                                background-image: url("https://assets.codingdojo.com/learn_platform/admin/access_control/acl_icons_sprite.svg");
                                background-position: 14px -12px;
                                content: "";
                                display: inline-block;
                                height: 11px;
                                position: absolute;
                                right: 19px;
                                width: 13px;
                            }
                        }
                    }
                }
            }
            #workspace_tab_list{
                background: #2E2E2E;
                list-style: none;
                margin-bottom: 0px;
                padding: 24px;
                width: 173px;
                li{
                    border-radius: 2px;
                    color: #fff;
                    display: flex;
                    font-family: $Poppins;
                    font-size: 12px;
                    margin-bottom: 42px;
                    padding: 8px 9px;
                    position: relative;
                    
                    &.active{
                        background: rgba(204, 224, 255, 0.14);
                        color: #81A0EA;
                        font-weight: 600;
                        span{
                            &.general_icon{
                                background-position: -40px -3px;
                            }
                            &.permission_icon{
                                background-position: -154px -3px;
                            }
                            &.curriculum_icon{
                                background-position: -269px -3px;
                            }
                        }
                        ~ li {
                            color: #777777;
                            span{
                                &.permission_icon{
                                    background-position: -79px -3px;
                                }
                                &.curriculum_icon{
                                    background-position: -193px -3px;
                                }
                            }
                        }
                    }
                    &#permission_tab_button, &#curriculum_tab_button{
                        &::after{
                            background-image: url("https://assets.codingdojo.com/learn_platform/admin/workspace_management/workspace_modal_icons.svg");
                            background-position: -368px 0px;
                            background-repeat: no-repeat;
                            content: "";
                            display: block;
                            height: 28px;
                            position: absolute;
                            right: 58px;
                            top: -33px;
                            width: 10px;
                        }

                    }
                    span{
                        background-image: url("https://assets.codingdojo.com/learn_platform/admin/workspace_management/workspace_modal_icons.svg");
                        background-position: -2px -3px;
                        display: inline-block;
                        height: 18px;
                        margin-right: 14px;
                        width: 18px;
                        &.permission_icon{
                            background-position: -116px -3px;
                        }
                    }
                }
            }

            .workspace_tab{
                background: linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), #121212;
                flex-grow: 1;
                padding: 26px 26px 47px;

                label.upload_icon_label{
                    align-items: center;
                    background-color: #333333;
                    background-image: url(https://assets.codingdojo.com/learn_platform/admin/workspace_management/workspace_modal_icons.svg);
                    background-position: -289px 10px;
                    background-repeat: no-repeat;
                    border: 1px solid rgba(129, 160, 234, 0.2);
                    cursor: pointer;
                    display: inline-flex;
                    flex-wrap: wrap;
                    height: 50px;
                    justify-content: center;
                    margin-bottom: 0px;
                    margin-top: 13px;
                    text-align: center;
                    width: 50px;

                    &:hover{
                        background-position: -323px 10px;
                        .icon_img{
                            opacity: .2;
                        }

                    }
                    input{
                        display: none !important;
                    }
                    &.disabled{
                        background-image: none;
                    }
                }
                .delete_icon_button{
                    background: transparent;
                    border: none;
                    color: #F56B6B;
                    height: 20px;
                    margin-left: 13px;
                    margin-top: 36px;
                    outline: none;
                    vertical-align: bottom;
                }
                .icon_img{
                    width: 37px;
                }
                .loading_image{
                    position: absolute;
                    width: 30px;
                }
                
                .error_message{
                    color: #F56B6B;
                    font-family: $Poppins;
                    font-size: 12px;
                    margin-top: 11px;
                }

                &#general_tab{
                    h6{
                        color: rgba(255, 255, 255, 0.88);
                        font-family: $Poppins;
                        font-size: 14px;
                        span{
                            color: #B3B3B3;
                            font-family: $Poppins;
                            font-size: 12px;
                        }
                    }
                    
                    .form_control{
                        margin-bottom: 27px;
                        position: relative;
                        &:last-child{
                            margin-bottom: 0px;
                        }
                        input{
                            background: linear-gradient(0deg, rgba(255, 255, 255, 0.24), rgba(255, 255, 255, 0.24)), #121212;
                            border-radius: 4px;
                            border: none;
                            color: rgba(255, 255, 255, 0.88);
                            display: block;
                            font-family: $Poppins;
                            font-size: 12px;
                            padding: 16px 14px;
                            width: 100%;
                        }
                        #workspace_icon_list{
                            display: flex;
                            list-style: none;
                            margin-top: 24px;
                            padding-left: 0px;
                            li{
                                margin-right: 33px;
                                text-align: center;
                                width: 67px;
                                h5{
                                    color: #B3B3B3;
                                    font-family: "Poppins", sans-serif;
                                    font-size: 12px;            
                                    font-weight: 400;
                                    margin-bottom: 11px;
                                }
                                label{
                                    margin-top: 7px;
                                }
                                .delete_icon_button{
                                    margin-left: 5px;
                                    position: absolute;
                                }
                            }
                        }
                    }
                    .workspace_url{
                        align-items: center;
                        display: flex;
                        input{
                            margin: 0px 13px;
                        }
                        span{
                            color: rgba(255, 255, 255, 0.58);
                            font-family: $Poppins;
                            font-size: 12px;
                        }
                    }
                }
                &#permissions_tab{
                    padding: 26px;
                    .form_control{
                        margin-bottom: 27px;
                        position: relative;
                        button{
                            outline: none;
                        }
                        &:last-child{
                            margin-bottom: 0px;
                        }
                        h6{
                            color: rgba(255, 255, 255, 0.88);
                            font-family: $Poppins;
                            font-size: 14px;
                            margin-bottom: 10px;
                            button{
                                background: transparent;
                                border: none;
                                color: #81A0EA;
                                font-size: 12px;
                            }
                        }
                        p{
                            color: #B3B3B3;
                            font-family: $Poppins;
                            font-size: 12px;
                            margin-bottom: 0px;
                            max-width: 404px;
    
                        }
                        .permission_switch_container{
                            display: inline-block;
                            margin-bottom: 0px;
                            position: absolute;
                            right: 0px;
                            top: -2px;
                            vertical-align: top;
                            input[type=checkbox]{
                                display: none;
                            }
                            label {
                                background: linear-gradient(0deg, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.3)), #121212;
                                border-radius: 100px;
                                cursor: pointer;
                                display: block;
                                height: 20px;
                                margin-bottom: 0px;
                                position: relative;
                                text-indent: -9999px;
                                width: 40px;
                                
                            }
                            label:after {
                                background: #B3B3B3;
                                border-radius: 90px;
                                content: '';
                                height: 16px;
                                left: 3px;
                                position: absolute;
                                top: 2px;
                                transition: 0.3s;
                                width: 16px;
                            }
                            input:checked + label {
                                background: rgba(84, 204, 176, 0.4);
                            }
                            input:checked + label:after {
                                background: #05DA80;
                                left: calc(100% - 3px);
                                transform: translateX(-100%);
                            }
                            label:active:after {
                                width: 20px;
                            }
                        }
                    }
                }
                &#curriculum_tab{
                    .form_control{
                        margin-bottom: 8px;
                        &#manage_certificate_container{
                            h6{        
                                margin-bottom: 31px;
                            }
                            p{
                                margin-bottom: 0px;
                                margin-top: 10px;
                            }
                        }
                        &#manage_tracks_or_stacks_container{
                            margin-bottom: 12px;
                            h6{
                                margin-bottom: 19px;
                            }
                            p{
                                margin-bottom: 35px;
                            }
                            .is_major_or_minor_track{
                                margin-left: 14px;
                                margin-right: 8px;
                            }
                        }
                        h6{
                            color: rgba(255, 255, 255, 0.88);
                            font-family: $Poppins;
                            font-size: 14px;
                            margin-bottom: 24px;
                            button{
                                align-items: center;
                                background: transparent;
                                border: none;
                                border-bottom: 1px solid transparent;
                                color: #81A0EA;
                                display: inline-flex;
                                font-size: 12px;
                                margin-left: 20px;
                                outline: none;
                                padding: 0px 0px 2px 6px;
                                &::after{
                                    background-image: url("https://codingdojo_assets.s3.us-east-1.amazonaws.com/learn_platform/admin/workspace_management/curriculum_tab_icons.svg");
                                    background-position: -30px 0px;
                                    content: "";
                                    display: inline-block;
                                    height: 18px;
                                    vertical-align: top;
                                    width: 23px;
                                }
                                &[aria-describedby="manage_program_and_stack_popover"], &[aria-describedby="manage_stack_and_version_popover"]{
                                    border-bottom: 1px solid #81A0EA;
                                    padding: 0px 0px 2px 6px;
                                }
                            }
                        }
                        p{
                            color: #B3B3B3;
                            font-family: $Poppins;
                            font-size: 12px;
                            &.error_message{
                                color: #F56B6B;
                                font-family: $Poppins;
                                font-size: 12px;
                                margin-top: 11px;
                            }
                        }
                        >p{
                            font-size: 14px;
                            margin-bottom: 21px;
                        }
                        ul{
                            display: flex;
                            flex-wrap: wrap;
                            margin-bottom: 0px;
                            padding: 0px;
                            li{
                                align-items: center;
                                background: #2E2E2E;
                                border-radius: 4px;
                                border: 1px solid transparent;
                                display: flex;
                                font-family: $Poppins;
                                font-size: 12px;
                                list-style: none;
                                margin-bottom: 20px;
                                margin-right: 20px;
                                padding: 10px 9px;
                                .radio_container{
                                    display: flex;
                                    margin-left: 12px;
                                    label{
                                        font-family: $Poppins;
                                        margin-bottom: 0px;
                                        margin-right: 6px;
                                        cursor: pointer;
                                        span{
                                            font-family: $Poppins;
                                        }
                                        input{
                                            display: none;
                                            &[data-ischecked="true"]{
                                                + span{
                                                    color: #81A0EA;
                                                    position: relative;
                                                    &:before{
                                                        background: #81A0EA;
                                                        bottom: -5px;
                                                        content: "";
                                                        height: 1px;
                                                        left: 0px;
                                                        position: absolute;
                                                        width: 100%;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                button{
                                    background-color: transparent;
                                    background-image: url("https://codingdojo_assets.s3.us-east-1.amazonaws.com/learn_platform/admin/workspace_management/curriculum_tab_icons.svg");
                                    border: none;
                                    display: inline-block;
                                    height: 20px;
                                    outline: none;
                                    width: 24px;
                                }
                                &.active{
                                    border: 1px solid #81A0EA;
                                    box-shadow: 0px 0px 6px #81A0EA;
                                }
                            }
                        }

                    }
                    #upload_belt_icon_list{
                        li{
                            background: transparent;
                            display: flex;
                            flex-direction: column;
                            flex-wrap: wrap;
                            margin-bottom: 0px;
                            margin-right: 0px;
                            padding: 10px 5px 0px;
                            position: relative;
                            width: 105px;
                            &:first-child{
                                margin-right: 15px;
                                width: 72px;
                                .delete_icon_button{
                                    right: -14px;
                                }
                            }
                            .delete_icon_button{
                                background: transparent;
                                bottom: -3px;
                                margin: 0px;
                                position: absolute;
                                right: 2px;
                            }
                            span{
                                align-items: center;
                                color: #B3B3B3;
                                display: grid;
                                font-family: $Poppins;
                                font-size: 12px;
                                font-style: normal;
                                height: 32px;
                                text-align: center;
                            }
                        }
                    }
                    .checkbox{
                        margin-bottom: 16px;
                        margin-right: 20px;
    
                        &:last-child{
                            margin-bottom: 0px;
                        }
                        input[type="checkbox"]{
                            display: none;
                        }
    
                        input[type="checkbox"]:checked ~ label{
                            .checkbox_fill_container{
                                background: #81A0EA;
                                border-color: #81A0EA;
    
                                .check_icon{
                                    background-image: url("https://assets.codingdojo.com/learn_platform/admin/curriculum_management/curriculum_management_icons.png");
                                    background-position: -198px -7px;
                                    display: flex;
                                    height: 100%;
                                    width: 100%;
                                }
                            }
                        }
                        label{
                            align-items: center;
                            cursor: pointer;
                            display: flex;
                            margin-bottom: 0px;
                            
                            .checkbox_fill_container{
                                align-items: center;
                                background: #121212;
                                border-radius: 4px;
                                border: 1px solid #B2B2B2;
                                display: flex;
                                height: 15px;
                                justify-content: center;
                                margin-right: 10px;
                                width: 15px;
                            }
                            span{
                                color: #E5E5E5;
                                font-family: $Poppins;
                                font-size: 12px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 130%;
                            }
                        }
                    }
                }
            }
            
        }
        .modal-footer{
            background: #333333;
            border: none;
            padding: 20px;
            button, a{
                background: transparent;
                border: none;
                color: #81A0EA;
                font-family: $Poppins;
                font-size: 14px;
                outline: none;
                text-decoration: none;
                &:first-child{
                    margin-right: 31px;
                }
                &.next_btn{
                    background: rgba(204, 224, 255, 0.14);
                    border-radius: 2px;
                    margin-left: 10px;
                    margin: 0px;
                    padding: 10px 15px;
                }
            }
        }
    }
}
#manage_program_and_stack_popover, #manage_stack_and_version_popover{
    border: none;
    z-index: 10000;  
    .popover-body{
        background: #4B4B4B;
        border-radius: 4px;
        display: flex;
        flex-direction: column;
        padding: 12px 0px 0px;
    }
    &>.arrow::after{
        border-bottom-color: transparent !important;
    }
    &>.arrow::before{
        border-bottom-color: transparent;
    }
}