#coming_soon_container{
    margin: 20px auto;
    text-align: center;
    color: #152C61;
    h5{
        font: 500 16px/24px 'Poppins', sans-serif;
        margin-bottom: 19px;
    }
    p{
        font: normal 16px/24px 'Poppins', sans-serif;
    }
}