$Poppins: "Poppins", sans-serif;

#student_profile_information_wrapper{
    >p{
        color: #FFFFFF;
        font-family: $Poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 120%;
    }

    #student_info_list{
        margin-bottom: 0px;
        max-height: 350px;
        overflow: auto;
        padding: 20px;
        
        >li{
            border-bottom: 1px solid rgba(255, 255, 255, 0.1);
            margin-bottom: 20px;
            padding-bottom: 16px;

            &:last-child{
                border: none;
                margin-bottom: 0px;
                padding-bottom: 0px;
            }

            h5{
                color: #FFFFFF;
                font-family: $Poppins;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: 120%;
                margin-bottom: 17px;
            }

            p{
                color: #FFFFFF;
                font-family: $Poppins;
                font-size: 12px;
                font-style: normal;
                font-weight: 600;
                line-height: 120%;
                opacity: 0.8;
            }

            .student_answers{
                margin-bottom: 18px;

                li{
                    align-items: center;
                    background: rgba(204, 224, 255, 0.14);
                    border-radius: 2px;
                    color: #81A0EA;
                    display: inline-flex;
                    font-family: $Poppins;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 600;
                    height: 22px;
                    justify-content: center;
                    line-height: 120%;
                    opacity: 0.8;
                    padding: 0px 6px;
                }
            }

            .student_answer_notes{
                *{
                    color: rgba(255, 255, 255, 0.55);
                    font-family: $Poppins;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 120%;
                }

                h6{
                    font-size: 12px;
                    font-weight: 600;
                    opacity: .8;
                }

                p{
                    margin-bottom: 3px;
                    b{
                        font-weight: 700;
                    }
                }
            }
        }
    }
}