/* COLOR */
$dark_gray: #282828;
$white: #fff;
$black: #000;
$white_gray: rgba(255, 255, 255, 0.58);

.duration_date_picker{
    .react-datepicker-wrapper{ 
        .react-datepicker__input-container{
            .date_pickers{
                color: $white; 
                cursor: pointer;
                font: normal 400 14px/140% "Poppins";
                margin: 0px 12px 0px 12px;
            }
            span:nth-child(4){
                display: inline-block;
                margin-right: 0px !important;
                overflow: hidden;
                text-overflow: ellipsis;
                vertical-align: middle;
                white-space: nowrap;
                width: 120px; 
            }
            .calendar_icon{
                background: url("https://assets.codingdojo.com/learn_platform/admin/course_calendar/course_calendar_icon.png") no-repeat -36px -9px;
                border: none;
                display: inline-block;
                height: 16px;
                vertical-align: middle;
                width: 16px;
            }
        
            .arrow_left_icon{
                align-items: center;
                background: url("https://assets.codingdojo.com/learn_platform/admin/course_calendar/course_calendar_icon.png") no-repeat -5px -5px;
                border: none;
                display: inline-block;
                height: 16px;
                vertical-align: middle;
                width: 17px;
            }
        }
    }

    .message_error{
        bottom: 9px;
        left: 0;
    }

    .react-datepicker-popper{
        z-index: 3;
        .react-datepicker{
            background-color: rgba(75, 75, 75, 1);
            border: none;
            display: flex;
            gap: 1em;
            justify-content: space-evenly;
            margin-top: 4px;
            min-width: 660px;
            padding: 16px;
    
            .react-datepicker__triangle{
                display: none;
            }
            .react-datepicker__month-container{
                width: 100%;
                .react-datepicker__header{
                    .react-datepicker__current-month{
                        color: $white;
                        font: 14px/18px "Poppins";
                        margin-bottom: .875em;
                    }
    
                    .react-datepicker__day-names{
                        display: flex;
                        justify-content: space-evenly;
                        margin-bottom: .875em;
                        .react-datepicker__day-name{
                            align-items: center;
                            color: $white;
                            display: flex;
                            font: 12px/18px "Poppins";
                            justify-content: center;
                            margin-top: 10px;
                        }
                    }
                }
                
                .react-datepicker__month{
                    .react-datepicker__week{
                        align-items: flex-start;
                        display: flex;
                        flex-wrap: nowrap;
                        margin-top: 4px; 
                        .react-datepicker__day{
                            align-items: center;
                            border-radius: 0px;
                            color: $white;
                            display: flex;
                            font: 12px/16px "Poppins";
                            height: 32px;
                            justify-content: center;
                            margin: 0px;
                            padding: 2px;
                            width: 42.57px;
                            &.react-datepicker__day--keyboard-selected{
                                background-color: transparent;
                            }
                            &.react-datepicker__day--today{
                                background-color: #2F2F2F;
                            }
                            &.react-datepicker__day--in-range,
                            &.react-datepicker__day--in-selecting-range{
                                background-color: #2F2F2F;
                            }

                            &.react-datepicker__day--selecting-range-start,
                            &.react-datepicker__day--range-start{
                                border-radius: 8px 0px 0px 8px;
                            }
                            
                            &.react-datepicker__day--selecting-range-end,
                            &.react-datepicker__day--range-end{
                                border-radius: 0px 8px 8px 0px;
                            }

                            &.react-datepicker__day--range-start.react-datepicker__day--range-end{
                                border-radius: 8px;
                            }

                            &.react-datepicker__day--in-selecting-range:hover{
                                border-radius: 0px 8px 8px 0px;
                            }
    
                            &.react-datepicker__day--selected, 
                            &.react-datepicker__day--range-start, 
                            &.react-datepicker__day--range-end,
                            &.react-datepicker__day--selecting-range-start{
                                background-color: #5865F2;
                            }
    
                            &.react-datepicker__day--outside-month{
                                background-color: transparent;
                            }
                            &.react-datepicker__day--disabled, &.react-datepicker__day--outside-month{
                                color: rgba(255, 255, 255, 0.4);
                            }

                            &:hover{
                                background-color: #5865F2;
                                border-radius: 8px 0px 0px 8px;
                                opacity: .5;
                            }
                        }
                    }
                }
            }
    
            .react-datepicker__navigation{
                top: 20px;
    
                .react-datepicker__navigation-icon{
                    &::before{
                        border-color: $white;
                        opacity: .5;
                    }
    
                    &:hover{
                        &::before{
                            opacity: 1;
                        }
                    }
                }
            }
    
            .react-datepicker__navigation--previous{
                background: url("https://assets.codingdojo.com/learn_platform/admin/course_calendar/course_calendar_icon.png") no-repeat -31px -39px;
                border: none;
                height: 12px;
                left: 35px;
                outline: none;
                top: 30px;
                width: 8px;
            }
            
            .react-datepicker__navigation--next{
                background: url("https://assets.codingdojo.com/learn_platform/admin/course_calendar/course_calendar_icon.png") no-repeat -49px -39px;
                border: none;
                height: 12px;
                outline: none;
                right: 30px;
                top: 30px;
                width: 8px;
            }
        }
    }

    .react-datepicker__header{
        background-color: rgba(75, 75, 75, 1);
        border: none;
    }
}

.date_duration_filter{
    background: #000000;
    border-radius: 2px;
    border: 2px solid rgba(154, 194, 255, 0.3);
    cursor: pointer;
    display: inherit;
    padding: 4px 9px 3px 10px;
    .react-datepicker-wrapper{ 
        .react-datepicker__input-container{
            .date_pickers{
                color: rgba(255, 255, 255, 0.88);
                font: normal 600 12px/130% 'Poppins';
            }
            span:nth-child(1){
                &::after{
                    content: " - ";
                }
            }
            span:nth-child(4){
                display: inline-block;
            }
        }
    }

    .message_error{
        bottom: 9px;
        left: 0;
    }

    .react-datepicker-popper{
        left: -12px !important;
        top: -7px !important;
        z-index: 5;
        .react-datepicker{
            background-color: rgba(75, 75, 75, 1);
            border: none;
            display: flex;
            gap: 1em;
            justify-content: space-evenly;
            margin-top: 4px;
            min-width: 660px;
            padding: 16px;
    
            .react-datepicker__triangle{
                display: none;
            }
            .react-datepicker__month-container{
                width: 100%;
                .react-datepicker__header{
                    .react-datepicker__current-month{
                        color: $white;
                        font: 14px/18px "Poppins";
                        margin-bottom: .875em;
                    }
    
                    .react-datepicker__day-names{
                        display: flex;
                        justify-content: space-evenly;
                        margin-bottom: .875em;
                        .react-datepicker__day-name{
                            align-items: center;
                            color: $white;
                            display: flex;
                            font: 12px/18px "Poppins";
                            justify-content: center;
                            margin-top: 10px;
                        }
                    }
                }
                
                .react-datepicker__month{
                    .react-datepicker__week{
                        align-items: flex-start;
                        display: flex;
                        flex-wrap: nowrap;
                        margin-top: 4px; 
                        .react-datepicker__day{
                            align-items: center;
                            border-radius: 0px;
                            color: $white;
                            display: flex;
                            font: 12px/16px "Poppins";
                            height: 32px;
                            justify-content: center;
                            margin: 0px;
                            padding: 2px;
                            width: 42.57px;
                            &.react-datepicker__day--keyboard-selected{
                                background-color: transparent;
                            }
                            &.react-datepicker__day--today{
                                background-color: #2F2F2F;
                                outline: none;
                            }
                            &.react-datepicker__day--in-range,
                            &.react-datepicker__day--in-selecting-range{
                                background-color: #2F2F2F;
                            }

                            &.react-datepicker__day--selecting-range-start,
                            &.react-datepicker__day--range-start{
                                border-radius: 8px 0px 0px 8px;
                            }
                            
                            &.react-datepicker__day--selecting-range-end,
                            &.react-datepicker__day--range-end{
                                border-radius: 0px 8px 8px 0px;
                            }

                            &.react-datepicker__day--in-selecting-range:hover{
                                border-radius: 0px 8px 8px 0px;
                            }
    
                            &.react-datepicker__day--selected, 
                            &.react-datepicker__day--range-start, 
                            &.react-datepicker__day--range-end,
                            &.react-datepicker__day--selecting-range-start{
                                background-color: #5865F2;
                                outline: none;
                            }
    
                            &.react-datepicker__day--outside-month{
                                background-color: transparent;
                            }
                            &.react-datepicker__day--disabled{
                                color: rgba(255, 255, 255, 0.4);
                            }

                            &:hover{
                                background-color: #5865F2;
                                border-radius: 8px 0px 0px 8px;
                                opacity: .5;
                            }
                        }
                    }
                }
            }
    
            .react-datepicker__navigation{
                top: 20px;
    
                .react-datepicker__navigation-icon{
                    &::before{
                        border-color: $white;
                        opacity: .5;
                    }
    
                    &:hover{
                        &::before{
                            opacity: 1;
                        }
                    }
                }
            }
    
            .react-datepicker__navigation--previous{
                background: url("https://assets.codingdojo.com/learn_platform/admin/course_calendar/course_calendar_icon.png") no-repeat -31px -39px;
                border: none;
                height: 12px;
                left: 35px;
                outline: none;
                top: 30px;
                width: 8px;
            }
            
            .react-datepicker__navigation--next{
                background: url("https://assets.codingdojo.com/learn_platform/admin/course_calendar/course_calendar_icon.png") no-repeat -49px -39px;
                border: none;
                height: 12px;
                outline: none;
                right: 30px;
                top: 30px;
                width: 8px;
            }
        }
    }

    .react-datepicker__header{
        background-color: rgba(75, 75, 75, 1);
        border: none;
    }

    .remove_icon{
        background-image: url(./../../../../assets/images/admin/dropdown_icons.png);
        background-position: 20px;
        display: inline-block;
        height: 20px;
        margin-left: 4px;
        width: 20px;
        vertical-align: bottom;
    }
}