$Poppins: 'Poppins', sans-serif;

.student_access_select{
    background-color: transparent;
    outline: none;
    padding: 0 16px !important;

    &.has_value{
        border: none !important;
        .react-dropdown-select-content{
            color: rgba(255, 255, 255, 0.88);

        }
    }

    .react-dropdown-select-content{
        color: #757575;
        span{
            font-family: $Poppins;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
        }
    }
    .react-dropdown-select-dropdown-handle{
        color: #E5E5E5;
        display: flex;
        margin: 0px 0px 0px 3px;

        svg{
            height: 12px;
            width: 12px;
        }
    }

    .react-dropdown-select-dropdown {
        background: #4B4B4B;
        border-radius: 4px;
        padding: 21px 0 0;
        top: 40px;
        // width: 316px !important;

        span{
            background-color: transparent !important;
            border: none;
            color: rgba(255, 255, 255, 0.88);
            font-family: $Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: normal;
            line-height: 130%;
            margin-bottom: 0;
            padding: 0px;

            &:not(:last-child){
                margin-bottom: 27px;
            }

            &.react-dropdown-select-item-selected{
                color: #81A0EA;
                font-weight: 600;
            }
        }

        .dropdown_search_container{
            padding: 0 20px;

            input[type="text"]{
                background: rgba(204, 224, 255, 0.14) !important;
                font: 400 14px/18px "Poppins", sans-serif !important;
                width: 100% !important;
            }
        }
    }
}