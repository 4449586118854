$Poppins: 'Poppins', sans-serif;

#access_control_table{
    
    #table_loading_container{
        margin-top: 0px;
    }
    #no_results_found{
        justify-content: center;
        margin-top: 100px;
    }
}
#access_control_action_popover{
    border: none;
    z-index: 2;
    .popover-body{
        background: #4B4B4B;
        border-radius: 4px;
        display: flex;
        flex-direction: column;
        padding: 12px 14px;
        button{
            align-items: center;
            background: transparent;
            border: none;
            color: #fff;
            display: flex;
            font-size: 12px;
            margin-bottom: 10px;
            padding: 0px;
            &:last-child{
                margin-bottom: 0px;
            }
            span{
                background-image: url("https://assets.codingdojo.com/learn_platform/admin/access_control/acl_icons_sprite.svg");
                display: inline-block;
                height: 16px;
                margin-right: 14px;
                vertical-align: top;
                width: 14px;
            }
            .disabled_icon{
                background-position: -60px -9px;
            }
            .delete_icon{
                background-position: -92px -9px;
                width: 16px;
            }
            .enable_icon{
                background-position: 56px -9px;
                width: 16px;
            }
        }
    }
    &>.arrow::after{
        border-bottom-color: #4b4b4b;
    }
}

#access_control_table{
    background: #333333;
    .sticky-table-table{
        width: 100%;
        .sticky-table-row{
            .sticky-table-cell{
                vertical-align: middle;
            }
            /* Table Head */
            &:nth-child(1){
                height: 40px;
                z-index: 5;
                .sticky-table-cell{
                    background: linear-gradient(0deg, rgba(255, 255, 255, 0.24), rgba(255, 255, 255, 0.24)), #121212;
                    color: #F6F9FD;
                    font-family: $Poppins;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 130%;
                    cursor: pointer;
                    &:first-child{
                        z-index: 5;
                        padding-left: 60px;
                    }
                    &:nth-child(2){
                        z-index: 3;
                    }
                    &:nth-child(6){
                        cursor: default;
                    }
                    .fa-caret-down, .fa-caret-up{
                        color: #fff;
                        margin-left: 6px;
                        &.light{
                            color: rgba(255, 255, 255, 0.4);
                        }
                    }

                    #name_sort_table_head{
                        align-items: center;
                        display: flex;

                        #name_sort_icon{
                            display: flex;
                            flex-direction: column;
                            justify-content: center;

                            svg{
                                color: #B2B2B2;

                                &.light{
                                    color: #81A0EA;
                                }

                                &:first-child{
                                    margin-bottom: -1px;
                                }
                                &:last-child{
                                    margin-top: -1px;
                                }
                            }
                        }
                    }
                }
            }

            /* Table Body */
            &:nth-child(n+2){
                .sticky-table-cell{
                    background: linear-gradient(0deg, rgba(255, 255, 255, 0.14), rgba(255, 255, 255, 0.14)), #121212;
                    border-top: 1px solid #5D5D5D;
                    color: rgba(255, 255, 255, 0.88);
                    font-family: $Poppins;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    .access_action_btn{
                        background-color: transparent;
                        background-image: url("https://assets.codingdojo.com/learn_platform/admin/access_control/acl_icons_sprite.svg");
                        background-position: 2px 0px;                    
                        border: none;
                        height: 32px;
                        width: 10px;
                    }
                    .resend_invite_button{
                        background: transparent;
                        border: none;
                        color: #81A0EA;
                        font-family: "Poppins", sans-serif;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 500;
                    }
                    .active{
                        background: rgba(84, 204, 176, 0.4);
                        border-radius: 4px;
                        color: #05DA80;
                        font-weight: 600;
                        padding: 2px 6px;
                    }
                    .disabled{
                        background: rgba(217, 75, 75, 0.4);
                        border-radius: 4px;
                        color: #F56B6B;
                        font-weight: 600;
                        opacity: 1;
                        padding: 2px 6px;
                        pointer-events: unset;
                    }
                    .pending{
                        background: rgba(255, 255, 255, 0.18);
                        border-radius: 4px;
                        color: rgba(255, 255, 255, 0.88);
                        font-weight: 600;
                        padding: 2px 6px;
                    }
                    &:first-child{
                        font-size: 14px;
                        padding-left: 60px;
                        z-index: 3;
                        span{
                            display: block;
                            font-size: 10px;
                            font-weight: 400;
                        }
                    }
                    &:last-child{
                        padding-left: 30px;
                        button{
                            outline: none;
                        }
                    }
                    .user_role_select{
                        border-radius: 4px;
                        min-height: 20px !important;
                        padding: 0px 6px !important;
                        width: fit-content;
                        
                        &[aria-expanded="true"]{
                            border: 1px solid #81A0EA !important;
                            box-shadow: 0px 0px 6px #81A0EA !important;
                            z-index: 2;
                        }
                        .react-dropdown-select-content{
                            span{
                                color: #81A0EA;
                            }
                        }
                        .react-dropdown-select-input{
                            margin: 0px;
                            &:focus{
                                outline: none;
                            }
                            &:focus-within{
                                border: none !important;
                                box-shadow: none !important;
                            }
                        }
                        .react-dropdown-select-dropdown-handle{
                            align-items: center;
                            display: flex;
                            justify-content: center;
                            margin: 0px;
                            padding: 0px;
                        }
                        .react-dropdown-select-dropdown{
                            border-radius: 4px;
                            border: 1px solid #81A0EA;
                            box-shadow: 0px 0px 6px #4c4d50;
                            margin-top: -12px;
                            min-width: 145px;
                            padding: 5px 0px;
                            .react-dropdown-select-item{
                                border: none;
                                font-size: 12px;
                                &:hover{
                                    background: transparent;
                                }
                                &.react-dropdown-select-item-selected{
                                    background: transparent;
                                    color: #81A0EA;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}