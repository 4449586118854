.release_notes_banner{
    background: #FFFFFF;
    border-radius: 5px;
    border: 1px solid #2C6BFF;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    margin: 40px 0px 0px 0px;
    padding: 23px 19px 23px 19px;
    width: 100%;

    p{
        align-items: center;
        color: #152C61;
        display: flex;
        font-family: "Poppins", sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: normal;
        line-height: 24px;
        margin-bottom: 0px;

        svg{
            color: #2C6BFF;
            font-size: 20px;
            margin-right: 15px; 
        }
        
        b{
            color: #2C6BFF;
            font-weight: bold;
            margin-right: 3px;
        }
    }

    div{
        align-items: center;
        display: flex;


        button{
            &:first-child{
                background: #2C6BFF;
                border-radius: 6px;
                border: none;
                color: #FFFFFF;
                font-family: "Poppins", sans-serif;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                height: 32px;
                line-height: 24px;
                margin-right: 9px;
                outline: none;
                padding: 4px 5px;
                width: 133px;
            }

            &:last-child{
                background-color: transparent;
                border: none;
                display: flex;
                margin-left: 7px;
                outline: none;
                padding: 0;
    
                .close_icon{
                    background-image: url("https://assets.codingdojo.com/learn_platform/global/close_icon_new.png");
                    background-position: 0px 0px;
                    display: inline-block;
                    height: 33px;
                    width: 33px;
                }
            }
        }
    }
    @media only screen and (max-width: 768px){
        flex-direction: column;
        margin: 20px 0px 24px 0px;
        padding-left: 60px;
        position: relative;
        width: 100%;
        p{
            display: inline-block;
            margin-bottom: 9px;
            padding-right: 10px;
            svg{
                left: 20px;
                position: absolute;
            }
        }
    }
}

.is_dark_mode{
    .release_notes_banner{
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.12)), #121212 !important;
        border-color: #81A0EA;

        p{
            color: rgba(255, 255, 255, 0.88) !important;
            svg{
                color: #81A0EA;
            }
            b{
                color: #81A0EA !important;
            }
        }
        div{

            button{
                &:first-child{
                    background-color: #81A0EA;
                }

                &:last-child{
                    span{
                        background-position: -61px 0px;
                    }
                }
            }
        }
    }
}