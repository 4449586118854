$Poppins: "Poppins", sans-serif;

#duplicate_ongoing_modal{
    left: 50%;
    max-width: none;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 480px;
    .modal-content{
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.24), rgba(255, 255, 255, 0.24)), #121212;
        border-radius: 0px;
        box-shadow: 0px 4px 2px rgba(0, 0, 0, 0.05);
        color: rgba(255, 255, 255, 0.88);
        font-family: $Poppins;
        .modal-header{
            background: linear-gradient(0deg, rgba(255, 255, 255, 0.14), rgba(255, 255, 255, 0.14)), #121212;
            border-radius: 0px;
            border: none;
            justify-content: left;
            padding: 18px 23px;
            position: relative;
            h4{
                font: normal 700 14px/16px $Poppins;
                margin-bottom: 0px;
            }
        }
        .modal-body{
            background: #292929;
            padding: 24px;
            p{
                font: normal 400 12px $Poppins;
                margin-bottom: 0px;
                text-align: center;
                span{
                    text-transform: lowercase;
                }
            }
            .duplicate_loading{
                background-image: url("https://assets.codingdojo.com/learn_platform/admin/global/table_loader.gif");
                background-size: cover;
                height: 35px;
                margin: 0 auto 12px;
                width: 35px;
            }
        }
    }
}