$Poppins: "Poppins", sans-serif;
$purple: #81A0EA;
$white: #fff;

#create_program_course_modal{
    max-width: 950px;
    .modal-content{
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.24), rgba(255, 255, 255, 0.24)), #121212;
        border: none;
        box-shadow: 0px 4px 2px rgba(0, 0, 0, 0.05);
        color: rgba(255, 255, 255, 0.88);

        .modal-header{
            align-items: center;
            background: linear-gradient(0deg, rgba(255, 255, 255, 0.14), rgba(255, 255, 255, 0.14)), #121212;
            border: none;
            display: flex;
            font: 700 14px/18.2px $Poppins;
            height: 50px;
            justify-content: space-between;
            padding: 0 17px 0 24px;

            button{
                align-items: center;
                background: transparent;
                border: none;
                display: flex;
                justify-content: center;
                margin-left: auto;
                outline: none;
                padding: 0;

                .close_icon{
                    background: url("https://assets.codingdojo.com/learn_platform/admin/curriculum_management/admin_curriculum_management_sprite.png") no-repeat -173px -20px;
                    height: 20px;
                    width: 20px;
                }
            }
        }

        .modal-footer{
            background: linear-gradient(0deg, rgba(255, 255, 255, 0.14), rgba(255, 255, 255, 0.14)), #121212;
            border: none;
            height: 80px;
            padding: 16px 20px;

            button{
                background: rgba(204, 224, 255, 0.14);
                border-radius: 2px;
                border: none;
                color: $purple;
                font: 600 14px/18px $Poppins;
                height: 40px;
                min-width: 64px;
                outline: none;
                padding: 0px 10px;

                &:disabled{
                    opacity: .5;
                }
            }
        }

        .modal-body{
            background: #282828;
            height: 646px;
            overflow: auto;
            padding: 20px 30px;

            .input_details_block{
                position: relative;
            
                .label_title{
                    color: rgba(255, 255, 255, 0.4);
                    display: block;
                    font: 600 12px/15.6px $Poppins;
                    margin-bottom: 12px;
                }
        
                input[type="text"]{
                    background: rgba(204, 224, 255, 0.14);
                    border-radius: 4px;
                    border: none;
                    color: rgba(255, 255, 255, 0.58);
                    font: 400 14px/20px $Poppins;
                    height: 40px;
                    outline: none;
                    padding: 0 17px;
                    width: 100%;
                }
            }
        }

        #search_course{
            display: flex;
            position: relative;

            svg{
                color: #73839C;
                font-size: 14px;
                left: 12px;
                margin-right: 10px;
                position: absolute;
                top: 12px;
                z-index: 2;
            }
            input{
                align-items: center;
                background: #3F4146;
                border-radius: 4px;
                border: 1px solid transparent;
                color: rgba(255, 255, 255, 0.88);
                font: 400 12px/16.8px $Poppins;
                height: 40px;
                padding: 0px 12px 0px 36px;
                position: relative;
                width: 100%;
        
                &:focus{
                    border: 1px solid $purple;
                    box-shadow: 0px 0px 6px $purple;
                    outline: none;
                }

                &::placeholder{
                    color: #A4A5A8;
                }
        
                &:focus + p{
                    display: block;
                }
            }
        }

        .filter_container{
            border-bottom: 1px solid #787878;
            padding-bottom: 6px;
            .react-dropdown-select-dropdown{
                min-width: 163px;
            }
            div:nth-child(7){
                min-width: 100px;
                .react-dropdown-select-dropdown{
                    min-width: 150px;
                }
            }
        }

        #courses_list_container{
            min-height: 374px;
            .no_course{
                color: #939393;
                font: 400 14px/18.2px $Poppins;
                margin-top: 40px;
                text-align: center;
            }

            .accordion{
                max-height: 447px;
                overflow-y: auto;
                &.with_pagination{
                    max-height: 395px;
                }

                .card-header{
                    align-items: center;
                    background: #2A2A2A;
                    border-bottom: 1px solid #3B3B3B;
                    cursor: pointer;
                    display: flex;
                    height: 58px;
                    
                    p{
                        font: 700 14px/18.2px $Poppins;
                        margin-bottom: 0;
                    }

                    .unselect_btn{
                        background: none;
                        border: none;
                        color: #81A0EA;
                        font: 400 12px $Poppins;
                        margin-left: 10px;
                        outline: none;
                    }

                    .accordion_arrow{
                        background: url("https://assets.codingdojo.com/learn_platform/admin/curriculum_management/admin_curriculum_management_sprite.png") no-repeat -207px -20px;
                        border: none;
                        height: 20px;
                        margin-left: auto;
                        outline: none;
                        transform: rotate(180deg);
                        transition: all .2s;
                        width: 20px;

                        &.active{
                            transform: rotate(0deg);
                        }
                    }
                }

                .current_selection_header{
                    position: sticky;
                    top: 0;
                    z-index: 99;
                }

                .card{
                    background: #282828;
                    border: none;

                    .card-body{
                        margin: 0;
                        padding: 0;
                    }
                }

                .current_selected_show{
                    position: sticky;                    
                    top: 0;
                    z-index: 99;
                }

                .search_results_container{
                    position: relative;
                }

                ul{
                    list-style-type: none;
                    margin: 0;
                    padding: 0;

                    li{
                        align-items: center;
                        display: flex;
                        gap: 10px;
                        min-height: 58px;
                        padding: 10px 0;

                        &:not(:last-of-type){
                            border-bottom: 1px solid #3A3A3A;
                        }

                        p{
                            font: 400 14px/18.2px $Poppins;
                            margin-bottom: 0;

                            &.course_name{
                                width: 400px;
                                span{
                                    background: #595959; 
                                    border-radius: 100px;
                                    color: #DEDEDE;
                                    font: 400 8px/10.4px $Poppins;
                                    margin-left: 7px;
                                    padding: 3px 10px;
                                }
                            }
                        }
                    }
                }
            }

            #table_loading_container{
                align-items: center;
                background: #282828;
                display: flex;
                flex-direction: column;
                margin: 25px auto 0;
                position: initial;
                transform: initial;
                width: 50px;
            
                div{
                    background-image: url("https://assets.codingdojo.com/learn_platform/admin/global/table_loader.gif");
                    background-size: cover;
                    height: 30px;
                    margin-bottom: 7px;
                    mix-blend-mode: lighten;
                    width: 30px;
                }
            
                span{
                    color: rgba(255, 255, 255, 0.88);
                    font: 500 14px/20.8px $Poppins;
                }
            }
        }

        .checkbox{
            display: flex;
            height: 100%;
            justify-content: center;
            
            input[type="checkbox"]{
                display: none;
            }

            .fa-check{
                display: none;
            }

            input[type="checkbox"]:checked ~ label{
                .checkbox_container{
                    background-color: #2C6BFF;
                    border: none;
                    margin-right: 20px;
                    .fa-check{
                        color: $white;
                        display: inline;
                        font-size: 10px;
                        margin-top: 2px;
                    }
                }    
            }

            input:disabled ~ label{
                cursor: not-allowed;
                opacity: 0.5;
                .checkbox_container{
                    cursor: not-allowed;
                }
            }

            label{
                align-items: center;
                cursor: pointer;
                display: flex;
                margin-bottom: 0px;
                min-width: 435px;

                .checkbox_container{ 
                    align-items: center;
                    background: $white;
                    border-radius: 4px;
                    border: 1px solid #A4B0C0;
                    cursor: pointer;
                    display: flex;
                    height: 15px;
                    justify-content: center;
                    margin-right: 20px;
                    width: 15px;
                }                       
            }
        }
    }
}