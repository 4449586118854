$Poppins: "Poppins", sans-serif;

.program_course_table_container{
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.14), rgba(255, 255, 255, 0.14)), #121212;
    border-radius: 4px;
    height: calc(100% - 180px);
    margin-top: 10px;
    overflow: hidden;
    position: relative;
    width: 100%;
    *::-webkit-scrollbar {
        width: 5px;
    }
    .program_course_table_header{
        align-items: center;
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.24), rgba(255, 255, 255, 0.24)), #121212;
        border-bottom: 1px solid #5D5D5D;
        color: rgba(255, 255, 255, .6);
        display: flex;
        font: 600 12px/15.6px $Poppins;
        height: 40px;

        > div{

            &:nth-child(1){
                padding-left: 20px;
                width: 800px;
            }

            &:nth-child(2),
            &:nth-child(3){
                width: 300px;
            }
            &:nth-child(4){
                flex: 1;
                padding-right: 60px;
                text-align: right;
            }

        }
    }

    #program_course_list{
        height: calc(100% - 40px);
        list-style-type: none;
        margin: 0;
        overflow-y: auto;
        padding: 0;

        > li {
            align-items: center;
            border-bottom: 1px solid #5D5D5D;
            display: flex;
            font: 400 14px/18.2px $Poppins;
            height: 80px;
            padding: 0 8px;

            > div{
                &:nth-child(1){
                    align-items: center;
                    display: flex;
                    width: 800px;

                    .course_name_container{
                        display: inline-block;
                        p{
                            font: 400 12px/15.6px $Poppins;
                            &.course_name{
                                color: #fff;
                                margin-bottom: 5px;
                            }
                            &.course_desc{
                                color: #999;
                                margin-bottom: 0;
                            }
                        }
                    }
                }

                &:nth-child(2),
                &:nth-child(3){
                    width: 300px;
                }

                &:nth-child(4){
                    flex: 1;
                    padding-right: 30px;
                    text-align: right;
                    .remove_btn{
                        background: none;
                        border: none;
                        color: #81A0EA;
                        font: 400 14px/18.2px $Poppins;
                        outline: none;
                    }
                }
            }
        }
    }

    .empty_course{
        color: #999;
        font: 400 14px/18.2px $Poppins;
        left: 50%;
        margin-top: 50px;
        position: absolute;
        top: 50px;
        transform: translateX(-50%);

        button{
            background: none;
            border: none;
            color: #5A698E;
            outline: none;
            padding: 0;
            text-decoration: underline;
        }
    }

    #courses_loading{
        display: flex;
        flex-direction: column;
        left: 50%;
        position: absolute;
        text-align: center;
        top: 45%;
        transform: translate(-50%, 50%);
        div{
            background-image: url("https://codingdojo_assets.s3.us-east-1.amazonaws.com/learn_platform/admin/global/table_loader.gif");
            background-size: cover;
            height: 30px;
            margin: 0 auto 7px;
            mix-blend-mode: lighten;
            width: 30px;
        }
        span{
            color: rgba(255, 255, 255, 0.88);
            font: normal 500 14px/130% $Poppins;
        }
    }
}