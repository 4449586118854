$Poppins: "Poppins", sans-serif;
#chile_countdown_banner{
    align-items: center;
    background: #94B4FF;
    display: flex;
    height: 52px;
    width: 100%;

    .container{
        align-items: center;
        display: flex;
        justify-content: space-between;

        .left_container{
            align-items: center;
            display: flex;

            p{
                color: #392C04;
                font-family: $Poppins;
                font-size: 14px;
                font-style: normal;
                font-weight: normal;
                line-height: 21px;
                margin-bottom: 0px;
                padding-right: 9px;
                position: relative;

                &:after{
                    background: #ACC1F1;
                    content: "";
                    height: 30px;
                    position: absolute;
                    right: -5px;
                    top: -5px;
                    width: 2px;
                }
            }
            .timer_icon{
                background-image: url("./../../../assets/images/global/chile_sence_icons.png");
                background-position: 0px 0px;
                display: flex;
                height: 24px;
                margin: 0px 7px 0px 15px;
                width: 24px;
            }

            .timer{
                color: #392C04;
                font-family: $Poppins;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: 21px;
            }
        }

        .right_container{
            align-items: center;
            display: flex;

            #sence_logo_icon{
                background-image: url("./../../../assets/images/global/chile_sence_icons.png");
                background-position: -118px 0px;
                display: flex;
                height: 20px;
                margin-right: 14px;
                width: 52px;
            }

            button{
                align-items: center;
                background: #D8E1F8;
                border-radius: 4px;
                border: 1px solid #295EDB;
                box-sizing: border-box;
                color: #295EDB;
                display: flex;
                font-family: $Poppins;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                height: 38px;
                justify-content: center;
                line-height: 21px;
                outline: none;
                position: relative;
                width: 140px;

                .stop_icon{
                    background-image: url("./../../../assets/images/global/chile_sence_icons.png");
                    background-position: -24px 0px;
                    display: flex;
                    height: 16px;
                    width: 16px;
                    margin-right: 6px;
                }
            }
        }
    }
}
@media only screen and (max-width: 1323px){
    #chile_countdown_banner{
        width: 1309px;
    }
}

@media only screen and (max-width: 768px){
    #chile_countdown_banner{
        height: auto;
        padding: 15px 0px;
        width: 100%;

        .container{
            flex-direction: column;

            .left_container{
                margin-bottom: 15px;
            }
        }
    }
}

body.is_dark_mode{
    #chile_countdown_banner{
        background: #557FE2;
    
        .container{
            background: #557FE2;

            .left_container{
                p{
                    color: #FFFFFF;

                    &:after{
                        background: #ACC1F1;
                    }
                }
                .timer_icon{
                    background-position: 0px -24px;
                }
    
                .timer{
                    color: #FFFFFF;
                }
            }
        }
    }
}