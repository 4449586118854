#faq_container{
    background: #FFFFFF;
    border-radius: 6px;
    box-shadow: 0px 4px 2px rgba(0, 0, 0, 0.05);
    margin-bottom: 20px;
    margin-top: 20px;
    padding: 21px 30px 19px 30px;
    position: relative;
    width: 100%;
    
    h5{
        color: #152C61;
        cursor: pointer;
        font-family: 'Poppins', sans-serif;
        font-size: 26px;
        font-style: normal;
        font-weight: 500;
        line-height: 39px;
        margin-bottom: 0px;
    }

    #toggle_show_faq_btn{
        -webkit-transition: all .3s;
        background-color: transparent;
        border: none;
        color: #152C61;
        font-size: 30px;
        outline: none;
        position: absolute;
        right: 30px;
        top: 16px;
        transition: all .3s;

        &.rotate{
            -ms-transform: rotate(180deg); /* IE 9 */
            -webkit-transition: all .3s;
            transform: rotate(180deg);
            transition: all .3s;
        }
    }

    ul{
        -webkit-transition: all .3s;
        height: auto;
        margin: 34px auto 59px auto;
        transition: all .3s;
        width: 1044px;

        &.hide{
            -webkit-transition: all .3s;
            height: 0px;
            margin: 0px;
            overflow: hidden;
            transition: all .3s;
        }

        li{
            border-bottom: 2px solid rgba(0, 0, 0, 0.2);
            cursor: pointer;
            padding: 60px 0px 17px 0px;

            &:last-child{
                border-bottom: none;
            }

            .question_head{
                align-items: center;
                display: flex;
                margin-bottom: 17px;

                .question_title{
                    color: #152C61;
                    flex: 1;
                    font-family: 'Poppins', sans-serif;
                    font-size: 22px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 33px;    
                    text-align: left;
                }

                .creator_container{
                    align-items: center;
                    display: flex;
                    
                    img{
                        border-radius: 100%;
                        height: 42px;
                        width: 42px;
                    }

                    .details{
                        display: flex;
                        flex-direction: column;
                        margin-left: 20px;

                        .name{
                            margin-bottom: 6px;
                        }


                        .position, .name{
                            color: #152C61;
                            font-family: 'Poppins', sans-serif;
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 200;
                            line-height: 18px;
                        }
                    }
                }
            }

            p{
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                color: #152C61;
                display: -webkit-box;
                font-family: 'Poppins', sans-serif;
                font-size: 16px;
                font-style: normal;
                font-weight: normal;
                line-height: 24px;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }
}