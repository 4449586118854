$Poppins: 'Poppins', sans-serif;

#student_notes_wrapper{
    .note_message{
        color: #fff;
        font: 12px/14px $Poppins;
        font-weight: 400;
    }

    .add_student_note_block{
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), #121212;
        border-radius: 2px;
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
        padding: 20px;

        >textarea{
            background: transparent;
            border: none;
            color: #fff;
            font: 12px/16px $Poppins;
            min-height: 50px;
            outline: none;
            resize: none;
        }

        .add_note_btn{
            background: rgba(204, 224, 255, 0.14);
            border-radius: 2px;
            border: none;
            color: #81A0EA;
            font: 12px/16px $Poppins;
            font-weight: 600;
            height: 32px;
            margin: 20px 0 0 auto;
            outline: none;
            width: 106px;
        }

        #add_note_button_container{
            display: flex;
            justify-content: flex-end;

            >button{
                background: rgba(204, 224, 255, 0.14);
                border-radius: 2px 0px 0px 2px;
                border: none;
                color: #81A0EA;
                font-family: 'Poppins';
                font-size: 12px;
                font-style: normal;
                font-weight: 600;
                height: 40px;
                line-height: 130%;
                outline: none;
                padding: 12px 10px;
                position: relative;

                &:after{
                    background-color: #81A0EA;
                    content: "";
                    height: 16px;
                    position: absolute;
                    right: -1px;
                    width: 1px;
                }
            }

            div{
                .react-dropdown-select {
                    border-radius: 0px 2px 2px 0px;
                    height: 40px;
                    .react-dropdown-select-content{
                        svg{
                            color: #81A0EA;
                            font-size: 14px;
                        }
                    }

                    .react-dropdown-select-dropdown{
                        background: linear-gradient(0deg, rgba(255, 255, 255, 0.24), rgba(255, 255, 255, 0.24)), #121212;
                        filter: drop-shadow(0px 3px 4px rgba(0, 0, 0, 0.14)) drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.12)) drop-shadow(0px 1px 8px rgba(0, 0, 0, 0.2));
                        left: -164px;
                        min-width: 196px;
                        top: 0px;

                        span{
                            border: none;
                            color: rgba(255, 255, 255, 0.88);
                            font-family: 'Poppins';
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 140%;
                            padding: 11px 10px;

                            &.react-dropdown-select-item-selected{
                                background-color: transparent;
                                color: #81A0EA;
                            }
                        }
                    }
                }
            }
        }
    }

    #note_list{
        border-radius: 2px;
        margin: 0;
        padding: 0;
        
        li{
            background: rgba(204, 224, 255, 0.14);
            border-radius: 2px;
            margin-bottom: 20px;
            padding: 20px;

            &.no_note_item{
                background: transparent;
                color: #FFFFFF;
                font: 12px/16px "Poppins", sans-serif;
                padding: 10px;
                text-align: center;
            }

            &.show_update_note_block{
                padding: 0;
            }

            &:last-child{
                margin-bottom: 0;
            }

            p{
                color: #E5E5E5;
                font: 12px/16px $Poppins;
                font-weight: 400;
                margin-bottom: 37px;
            }

            .action_details_wrapper{
                align-items: center;
                display: flex;
                justify-content: flex-start;

                .admin_details{
                    align-items: center;
                    display: flex;
                    justify-content: flex-start;

                    img{
                        border-radius: 50%;
                        height: 20px;
                        width: 20px;
                    }

                    span{
                        color: #FFFFFF;
                        font: 12px/16px $Poppins;
                        font-weight: 400;
                        margin-left: 10px;

                        &.admin_name{
                            font-weight: 600;
                            max-width: 380px;
                        }

                        &.user_level, &.note_type{
                            background: rgba(204, 224, 255, 0.14);
                            border-radius: 4px;
                            font-weight: 600;
                            padding: 3px 6px;
                        }
                        &.user_level{
                            color: #81A0EA;
                            
                        }
                        &.note_type{
                            color: #B3B3B3;
                        }
                    }
                }

                .action_btn{
                    align-items: center;
                    display: flex;
                    justify-content: flex-end;
                    margin-left: auto;

                    button{
                        background: transparent;
                        background: url("https://assets.codingdojo.com/learn_platform/admin/student_access/upload_csv_icons.svg") no-repeat;
                        border: none;
                        display: block;
                        height: 20px;
                        margin-left: 15px;
                        outline: none;
                        width: 20px;

                        &.edit_note{
                            background: url("https://assets.codingdojo.com/learn_platform/admin/student_access/update_icon.svg") no-repeat;
                            background-position: 2px 2px;
                        }

                        &.delete_note{
                            background-position: -14px 3px;
                        }
                    }
                }
            }

            .update_selected_note_block{
                background: linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), #121212;
                border-radius: 2px;
                display: flex;
                flex-direction: column;
                padding: 20px;
        
                >textarea{
                    background: transparent;
                    border: none;
                    color: #fff;
                    font: 12px/16px $Poppins;
                    min-height: 50px;
                    outline: none;
                    resize: none;
                }
        
                .save_note_changes_btn{
                    background: rgba(204, 224, 255, 0.14);
                    border-radius: 2px;
                    border: none;
                    color: #81A0EA;
                    font: 12px/16px $Poppins;
                    font-weight: 600;
                    height: 32px;
                    margin: 20px 0 0 auto;
                    outline: none;
                    width: 106px;
                }
            }
        }
    }
}