$Poppins: "Poppins", sans-serif;

#course_content_container{
    margin-top: 52px;
    .course_container{
        height: 100%;
        list-style: none;
        margin: unset;
        padding: unset;
        & > li{
            & > div{
                align-items: center;
                border-radius: 2px;
                border: 1px solid rgba(255, 255, 255, 0.4);
                color: rgba(255, 255, 255, 0.88);
                display: flex;
                font: normal 400 14px/140% $Poppins;
                height: 52px;
                padding-left: 20px;

                .edit_chapter_title{
                    background: transparent url("https://assets.codingdojo.com/learn_platform/admin/curriculum_management/edit_icon.png") no-repeat;
                    border: none;
                    height: 24px;
                    margin: 0 10px 0 auto;
                    outline: none;
                    width: 24px;
                }
            }
        }
        .chapter_container{
            list-style: none;
            padding-left: 50px;
            position: relative;
            &::before{
                border-left: 1px solid #5B3B85;                
                content: "";
                display: block;
                height: calc(100% - 23px);
                left: 20px;
                position: absolute;
                top: 0;
                width: 30px;
            }
            & > li{
                align-items: center;
                border-radius: 2px;
                border: 1px solid rgba(255, 255, 255, 0.4);
                display: flex;
                font: normal 400 14px/140% $Poppins;
                height: 52px;
                padding-left: 20px;
                position: relative;
                &::before{
                    border-bottom: 1px solid #5B3B85;
                    content: "";
                    display: block;
                    height: 29px;
                    left: -30px;
                    position: absolute;
                    top: -1px;
                    width: 30px;
                }
                span{
                    background: #595959;
                    border-radius: 20px;
                    color: rgba(255, 255, 255, 0.8);
                    font: normal 400 8px/130% $Poppins;
                    margin-left: 20px;
                    padding-block: 4px;
                    padding-inline: 12px;
                    text-align: center;
                }
            }
        }
    }
    button{
        background: rgba(204, 224, 255, 0.14);
        border-radius: 2px;
        border: none;
        color: #81A0EA;
        height: 60px;
        margin-top: 20px;
        text-align: center;
        width: 100%;
    }
}