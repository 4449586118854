#exam_content_container{
    display: flex;
    flex-wrap: wrap;
    h3{
        padding-top: 24px;
        width: 470px;
    }
    .exam_timer_container{
        margin-left: auto;
    }
    .exam_content{
        width: 100%;
    }

    #exam_questions_container{
        display: flex;
        margin: 57px 0px 44px 0px;
        min-height: 300px;
        padding: 0px 150px;
        width: 100%;

        ol{
            width: 100%;
            >li{
                margin-bottom: 33px;
                width: 100%;

                &:last-child{
                    margin-bottom: 0px;
                }

                >p{
                    color: #152C61; 
                    font: normal 500 16px/normal "Poppins";
                    margin: 0px auto 29px auto;
                    padding-left: 10px;
                }

                h5{
                    color: #152C61;
                    font: normal 300 14px/21px 'Poppins', sans-serif;
                    margin: 17px auto 14px auto;
                }

                ul{
                    margin: 0px auto 0px auto;

                    li{ 
                        margin-bottom: 10px;

                        &:last-child{
                            margin-bottom: 0px;
                        }
                        
                        input[type="radio"]{
                            display: none;
                        }

                        input[type="radio"]:checked ~ label{
                            background: #F8FAFF;
                            border-radius: 2px;
                            border: 2px solid #2C6BFF;
                            box-sizing: border-box;
                            color: #2C6BFF;
                            font: normal 600 16px/24px 'Poppins', sans-serif;
                        }
                        label{
                            align-items: center;
                            background: #FFF;
                            border-radius: 2px;
                            border: 2px solid #E5EEFD;
                            box-sizing: border-box;
                            color: #000000;         
                            cursor: pointer;
                            display: flex;
                            font: normal 300 16px/normal "Poppins";
                            margin-bottom: 0px;
                            min-height: 50px;
                            padding: 10px 27px;
                            width: 100%;
                        }

                    }
                }
            }
        }
    }

    .btns_container{
        align-items: center;
        display: flex;
        justify-content: space-between;
        margin: 60px auto 0px auto;
        width: 100%;

        button{
            background-color: #fff;                
            border-radius: 6px;
            border: 1px solid #505170;
            box-sizing: border-box;
            color: #505170;
            font: normal 500 15px/normal 'Poppins', sans-serif;
            min-width: 160px;
            outline: none;
            padding: 6px 0px;
            text-align: center;

            &.is_hide_prev_button{
                visibility: hidden;
            }

            &#beginning_review_btn{
                padding: 6px 8px;
            }

            &#next_question_btn, &#show_exam_results_btn{
                background: #505170;
                border-radius: 6px;
                border: 1px solid #505170;
                color: #fff;
            }
        }

        span{
            color: rgba(21, 44, 97, 0.6);
            font: normal 500 16px/24px 'Poppins', sans-serif;
            text-align: right;
        }
    }


}

.exam_timer_container{
    display: flex;
    div.exam_date_container{
        align-items: center;
        background: #FFF;
        border-radius: 6px;
        box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.05);
        display: flex;
        margin-left: 10px;
        min-width: 318px;
        padding: 10px 20px;
        &:nth-child(1){
            h5{
                padding-top: 14px;
            }
        }
        &#timer_container{
            h5{
                margin: 16px 82px 0px 0px;
                min-width: max-content;
            }
        }
        h5{
            font: normal 600 14px/normal "Poppins";
            margin-bottom: 0px;
            button{
                background-color: transparent;
                border: none;
                color: #666;
                display: block;
                font: normal 400 10px/normal "Poppins";
                margin: 1px 0px 0px 0px;
                outline: none;
                padding: 0px;
                text-decoration-line: underline;
            }
        }
        span{
            color: #666;
            font: normal 400 14px/normal "Poppins";
            margin-left: 36px;    
        }
        #timer{
            background-color: #39a4e7;
            border-radius: 50%;
            height: 72px;
            position: relative;
            width: 72px;
            span{
                color: #E5EEFD;
                font-size: 14px;
                left: 11.5px;
                margin: 0px;
                padding: 0px;
                position: absolute;
            }
        }
        .countdown_container{
            background-color: transparent;
            margin-left: auto;
            svg{
                height: 0px;
                position: absolute;
                width: 0px;
            }
            div{
                height: 72px !important;   
                width: 72px !important;
                svg, div{
                    height: 72px !important;   
                    width: 72px !important;
                }
                div{
                    background-color: transparent !important;
                    top: 26px !important;
                    .timer{
                        background-color: transparent !important;
                        color: #003BC7;
                        font: normal 500 14px/normal "Poppins";
                        text-align: center;
                        .timer_value{
                            background-color: transparent !important;
                            &.times_up{
                                color: #F56B6B;
                                font: normal 600 12px/normal "Poppins";
                            }
                            &.five_min_left{
                                color: #D62E24;
                            }
                            span{
                                color: #B3B3B3;
                                font: normal 500 10px/normal "Poppins";
                                margin: 0px !important;
                                text-align: center;
                            }
                        }
                    } 
                }
            }
        }
    } 
}

.is_dark_mode{
    #exam_content_container{
        form{
            #exam_questions_container{
                ol{
                    li{
                        p{
                            color: rgba(255, 255, 255, 0.88);
                        }
                        .question_code_snippet{
                            .rainbow{
                                background: #D1CDE0 !important;
                            }
                        }
                        ul{
                            li{
                                input[type="radio"]:checked ~ label{
                                    background: rgba(234, 240, 255, 0.20);
                                    border: 2px solid #81A0EA;
                                    color: #81A0EA;
                                }
                                label{
                                    background: rgba(255, 255, 255, 0.08);
                                    border: 2px solid transparent;
                                    color: rgba(255, 255, 255, 0.88);
                                }
                            }
                        }
                    }
                }
            }
            .btns_container{
                &#top_btns_container{
                    background: linear-gradient(0deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.1) 100%), #121212;
                }
                button{
                    background-color: transparent;
                    border: 1px solid #81A0EA;
                    color: #81A0EA;
                }
                #next_question_btn, .is_for_review, #review_selections_btn, #submit_exam_btn{
                    background: rgba(129, 160, 234, 0.20);
                    border: 1px solid transparent;
                    color: #81A0EA;
                }
            }
        }
    }
    .exam_timer_container{
        div.progress_container{
            div[data-test-id="CircularProgressbarWithChildren"]{
                background-color: transparent !important;
                div{
                    background-color: transparent !important;
                    div{
                        div{
                            color:#E5EEFD;
                            span{
                                color: #B3B3B3;
                            }
                        }
                    }
                }
            }
        }
        div.exam_date_container{
            .countdown_container{
                div{
                    div{
                        .timer{
                            .timer_value{
                                &.five_min_left{
                                    color: #E5EEFD;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}