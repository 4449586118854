footer#dashboard_footer{
    border-top: 1px solid rgba(21, 44, 97, 0.1);
    min-width: 1309px;
    padding: 41px 0px 15px 0px;
    transform: rotate(-0.08deg);
    width: 100%;
    div.container{
        display: flex;
        div{
            p{
                color: #152C61;
                font: normal 400 12px/18px 'Poppins';
                opacity: 0.6;
            }
        }
        div.coding_dojo_container_info{
            width: 100%;
            span#coding_dojo_logo{
                background: url("https://assets.codingdojo.com/learn_platform/global/coding_dojo_black_new_version.svg") no-repeat 0 0;
                background-size: cover;
                display: inline-block;
                height: 24px;
                margin-bottom: 12px;
                width: 130px;
            }
            p{
                font-weight: 500;
                margin-bottom: 18px; 
            }
        }
        div.coding_dojo_container_desc{
            p{
                a{
                    color: #152C61;
                    text-decoration: underline;
                }
            }
            p:nth-child(1){
               margin-bottom: 16px; 
            }
        }
    }
}
@media screen and (max-width: 768px) {
    footer#dashboard_footer{
        min-width: unset;
    }
}