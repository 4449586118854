$Poppins: "Poppins", sans-serif;

#belt_exam_summary_container{
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), #121212;
    border-radius: 2px;
    min-width: 1112px;
    padding: 20px;
    h6{
        color: rgba(255, 255, 255, 0.58);
        font-family: $Poppins;
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 12px;
    }
    ul{
        display: flex;
        list-style: none;
        margin-bottom: 0px;
        padding-left: 0px;
        li{
            align-items: center;
            background: linear-gradient(0deg, rgba(255, 255, 255, 0.14), rgba(255, 255, 255, 0.14)), #121212;
            border-radius: 2px;
            border: 1px solid rgba(204, 224, 255, 0.14);
            color: rgba(255, 255, 255, 0.88);
            display: flex;
            font-family: $Poppins;
            font-size: 14px;
            font-weight: 500;
            padding: 8px 14px;
            &:not(:last-child){
                margin-right: 12px;
            }
            .rate{
                color: rgba(255, 255, 255, 0.88);
                font-family: $Poppins;
                font-size: 20px;
                font-weight: 700;
                margin-left: 14px;
                &.green{
                    color: #05DA80;
                }
            }
            .belt_icon{
                background: #292929;
                border-radius: 100%;
                height: 24px;
                margin-right: 14px;
                width: 24px;
                &.red{
                    background-position: -57px 0px;
                }
                &.black{
                    background-position: -76px 0px;
                }
                &.orange{
                    background-position: -94px 0px;
                }
                &.yellow{
                    background-position: -113px 0px;
                }
                &.white{
                    background-position: -273px 0px;
                }
            }
        }
    }
}