#add_bookmark_modal{
    max-width: 791px;
    width: 791px;
    .modal-content{
        background: #FFFFFF;
        border-radius: 6px;
        box-shadow: 0px 4px 2px rgba(0, 0, 0, 0.05);
        .modal-header{
            border: none;
            justify-content: center;
            padding: 48px 40px 35px 40px;
            position: relative;
            .modal-title{
                color: #152C61;
                font: normal 500 22px/33px 'Poppins', sans-serif;
            }
            button{
                background-color: transparent;
                border: none;
                outline: none;
                padding: 0px;
                position: absolute;
                right: 26px;
                top: 26px;
                .close_icon{
                    background-image: url("https://assets.codingdojo.com/learn_platform/global/close_icon_new.png");
                    display: inline-block;
                    height: 33px;
                    width: 33px;
                }
            }
        }
        .modal-body{
            padding: 5px 0px 48px 0px;
            form{
                margin: 0px auto;
                width: 676px;
                input{
                    border-radius: 2px;
                    border: 2px solid #BDBDBD;
                    box-sizing: border-box;
                    color: #152C61;
                    font: normal normal 16px/24px 'Poppins', sans-serif;
                    height: 50px;
                    padding: 0px 20px;
                    width: 100%;
                    &::-webkit-input-placeholder {
                        color: rgba(21, 44, 97, 0.6);
                        font: italic 200 16px/24px 'Poppins', sans-serif;
                    }
                    &:-ms-input-placeholder {
                        color: rgba(21, 44, 97, 0.6);
                        font: italic 200 16px/24px 'Poppins', sans-serif;
                    }
                    &::placeholder {
                        color: rgba(21, 44, 97, 0.6);
                        font: italic 200 16px/24px 'Poppins', sans-serif;
                        line-height: 24px;
                    }
                }
                .btn_container{
                    display: flex;
                    justify-content: space-between;
                    margin: 60px auto 0px auto;
                    width: 476px;
                    button[type=button]{
                        background-color: transparent;
                        border: none;
                        color: #2C6BFF;
                        font: normal normal 18px/27px 'Poppins', sans-serif;
                        height: 46px;
                        outline: none;
                        width: 158px;
                    }
                    button[type=submit]{
                        background: #2C6BFF;
                        border-radius: 6px;
                        color: #FFFFFF;
                        font: normal normal 18px/27px 'Poppins', sans-serif;
                        height: 46px;
                        text-align: center;
                        width: 158px;
                    }
                }
            }
        }
    }
    @media only screen and (max-width: 768px){
        align-items: unset;
        .modal-content{
            .modal-header{
                .modal-title{
                    font: normal 500 16px/24px 'Poppins', sans-serif;
                }

            }
            .modal-body{
                padding: 29px 20px 20px;
                form{
                    width: 100%;
                    input{
                        border-radius: 20px; 
                        height: 40px;
                        padding: 0px 16px;
                    }
                    .btn_container{
                        justify-content: center;
                        margin: 40px auto 0px auto;
                        width: 100%;
                        button[type=button]{
                            margin-right: 40px;
                            width: unset;
                        }
                        button[type=submit], button[type=button]{
                            font: normal normal 16px/24px 'Poppins', sans-serif;
                        }
                    }
                }
            }
        }
    }
}