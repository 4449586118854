#delete_discussion_modal, #delete_discussion_module_modal, #confirm_update_discussion_modal{
    max-width: 660px;
    &#confirm_update_discussion_modal{
        .modal-content{
            .modal-body{
                padding: 42px 0px 39px;
                h6{
                    color: #2C6BFF;
                    margin-bottom: 22px;
                }
            }
            form{
                padding-bottom: 44px;
                .btn{
                    width: 176px;
                    &#edit_discussion_btn{
                        background: #2C6BFF;
                        color: #fff;
                        width: 137px;
                    }
                }
            }
        }
    }
    .modal-content{
        border-radius: 6px;
        border: none;
        color: #152C61;
        font-family: 'Poppins', sans-serif;
        font-size: 16px;
        padding: 0px 72px;
        .modal-body{
            padding: 32px 0px 37px;
            text-align: center;
            svg, h6{
                color: #F56B6B;
            }
            svg{
                font-size: 18px;
                margin-bottom: 13px;
            }
            h6{
                margin-bottom: 23px;
            }
            p{
                margin-bottom: 0px;
                font-size: 16px;
            }
        }
        form{
            padding-bottom: 30px;
            text-align: center;
            .btn{
                border-radius: 6px;
                box-shadow: none;
                color: #152C61;
                font-size: 18px;
                outline: none;
                padding: 8px;
                width: 206px;
                &#delete_discussion_btn, &#delete_discussion_module_btn{
                    background: #F56B6B;
                    color: #fff;
                }
            }
        }
        
    }
}
.fade.modal.show{
    background: rgba(45, 45, 45, 0.45);
}

.is_dark_mode{
    #confirm_update_discussion_modal{
        .modal-content{
            background: linear-gradient(0deg, rgba(255, 255, 255, 0.28), rgba(255, 255, 255, 0.28)), #121212 !important;
            color: rgba(255, 255, 255, 0.88);
        }
        .modal-body{
            h6{
                color: #81A0EA !important;
            }
        }
        form{
            .btn{
                color: rgba(255, 255, 255, 0.88);
            }
            #edit_discussion_btn{
                background: #81A0EA !important;
            }
        }
    }
}

@media only screen and (max-width: 768px){
    html{
        body.is_show_delete_discussion_modal{
            &.modal-open{
                .modal.show{
                    background: rgba(45, 45, 45, 0.45);
                    #delete_discussion_module_modal, #delete_discussion_modal{
                        align-items: center;
                        margin: 0px auto;
                        max-width: 350px !important;
                        .modal-content{
                            border-radius: 6px !important;
                            .modal-body{
                                flex: unset;
                                padding: 48px 29px 10px;
                                svg{
                                    margin-bottom: 25px;
                                }
                                p{
                                    margin-bottom: 22px;
                                }
                            }
                            form{
                                align-items: center;
                                display: flex;
                                flex-direction: column;
                                justify-content: center;
                                padding-bottom: 17px;
                                .btn{
                                    font-size: 16px;
                                    order: 2;
                                }
                                #delete_discussion_module_btn{
                                    margin-bottom: 14px;
                                    margin-top: 16px;
                                    order: 1;
                                }
                            }
                        }
                    }
                }
            }
        }
        #confirm_update_discussion_modal{
            .modal-content{
                padding: 0px;
                form{
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;
                    padding-bottom: 30px !important;
                    button[type="button"]{
                        width: 100% !important;
                    }
                    #edit_discussion_btn{
                        width: 206px !important;
                    }

                }
            }
        }
    }
    html{

        body.modal-open.is_dark_mode.is_show_delete_discussion_modal{
            .modal.show{
                background: rgba(45, 45, 45, 0.45) !important;
                #delete_discussion_module_modal, #delete_discussion_modal{
                    .modal-content{
                        .modal-header{
                            .modal-title{
                                color: #E5E5E5;    
                            }
                        }
                        .modal-body{
                            p{
                                color: #E5E5E5;
                            }
                            .checkbox{
                                input:checked ~ label{
                                    .checkbox_container{
                                        background-color: #81A0EA;
                                        border-color: #81A0EA;
                                    }    
                                }
                                label{
                                    .checkbox_container{ 
                                        border-color: #E5E5E5;
                                    }
                                    >span{
                                        color: #E5E5E5;
                                    }                        
                                }
                            }
                        }
                
                        .modal-footer{
                            button{
                                color: #E5E5E5;
                                &[type="submit"]{
                                    color: #fff;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}