#trial_platform_message_modal{
    max-width: 600px;
    .modal-content{
        background: #FFFFFF;
        border-radius: 6px;
        box-shadow: 0px 4px 2px rgba(0, 0, 0, 0.05);
        height: 100%;
        max-width: 600px;
        width: 100%;
        .modal-body{
            text-align: center;
            padding: 84px 70px 65px;
            h6{
                color: #152C61; 
                font: normal 500 26px/30px 'Poppins', sans-serif;
                margin-bottom: 40px;
            }
            p{
                color: #152C61;    
                font: normal normal 14px/20px 'Poppins', sans-serif;
                margin-bottom: 50px;            
                text-align: center;
            }
            img{
                margin-bottom: 62px;
            }

            .btn-primary{
                border-radius: 6px;
                border: none;
                color: #FFFFFF;
                font: normal 500 18px/27px 'Poppins', sans-serif;
                height: 46px;
                text-align: center;
                max-width: 290px;
                width: 100%;
            }
        }
    }
}
.is_dark_mode{
    #trial_platform_message_modal{
        .modal-content{
            background-color: #2E2E2E !important;
            box-shadow: 0px 4px 2px rgba(0, 0, 0, 0.05);
            .modal-body{
                h6, p{
                    color: rgba(255, 255, 255, 0.88);
                }
            }
        }
    }
}