$Poppins: 'Poppins', sans-serif;

#admin_container{
    background-color: #121212;
    display: flex;
    height: 100vh;

    #admin_right_container{
        /* all */
        color: rgba(255,255,255,0.88);
        flex: 1;
        font-family: $Poppins;
        font-size: 14px;
        overflow-y: auto;
        padding: 12px 30px 20px 30px;
        #table_tools_container{
            align-items: flex-end;
            display: flex;
            justify-content: space-between;
            
            #other_tools_container{
                align-items: center;
                display: flex;
                margin-bottom: 14px;

                #download_button_tooltip{
                    .tooltip-inner{
                        background: linear-gradient(0deg, rgba(255, 255, 255, 0.24), rgba(255, 255, 255, 0.24)), rgba(6, 6, 6, 0.94);
                        border-radius: 4px;
                        border: 1px solid rgba(204, 224, 255, 0.14);
                        box-sizing: border-box;
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: space-between;
                        max-width: 100px;
                        padding: 20px;
                
                        h5{
                            color: #E5E5E5;
                            font-family: $Poppins;
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 500;
                            margin-bottom: 0px;
                            max-width: 80%;
                            text-align: left;
                            
                            span{
                                margin-left: 10px;
                            }
                        }
                    }
                }

                button, a{
                    align-items: center;
                    background: rgba(204, 224, 255, 0.14);
                    border-radius: 2px;
                    border: none;
                    display: flex;
                    height: 32px;
                    justify-content: center;
                    margin-left: 12px;
                    width: 38px;
                    
                    svg{
                        color: #81A0EA;
                    }

                    &:disabled{
                        opacity: 0.5;
                    }
                }
            }
        }
    }
}

