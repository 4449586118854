$Poppins: "Poppins", sans-serif;
$blue: #81A0EA;
$white: #fff;

.table_container#unit_course_associated_table{
    border-radius: 4px;
    margin-top: 0px;
    overflow: auto;
    position: relative;
    width: 100%;
    #table_loading_container{
        align-items: center;
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.14), rgba(255, 255, 255, 0.14)), #121212;
        display: flex;
        flex-direction: column;
        left: 50%;
        margin-left: 135px;
        position: fixed;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 50px;
    
        div{
            background-image: url("https://assets.codingdojo.com/learn_platform/admin/global/table_loader.gif");
            background-size: cover;
            height: 30px;
            margin-bottom: 7px;
            mix-blend-mode: lighten;
            width: 30px;
        }
    
        span{
            color: rgba(255, 255, 255, 0.88);
            font: normal 500 14px/130% $Poppins;
        }
    }
    *::-webkit-scrollbar {
        height: 9px;
        width: 9px;
    }
    .sticky-table{
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.14), rgba(255, 255, 255, 0.14)), #121212;
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.07);
        width: 100%;
        &.table_loading{
            .sticky-table-table{
                height: 100%;
                #no_results_found{
                    align-items: center;
                    justify-content: center;
                    width: 50px;
                }
            }
        }
        .checkbox{
            display: flex;
            justify-content: center;
            input[type="checkbox"]{
                display: none;
            }
            .fa-check{
                display: none;
            }
            input[type="checkbox"]:checked ~ label{
                .checkbox_container{
                    background-color: $blue;
                    .fa-check{
                        color: $white;
                        display: inline;
                        font-size: 10px;
                    }
                }    
            }
            label{
                align-items: center;
                cursor: pointer;
                display: flex;
                margin-bottom: 0px;
                .checkbox_container{ 
                    align-items: center;
                    background: $white;
                    border-radius: 4px;
                    border: 1px solid transparent;
                    cursor: pointer;
                    display: flex;
                    height: 15px;
                    justify-content: center;
                    width: 15px;
                }                       
            }
        }
        .sticky-table-row{
            /* Table Head */
            &:nth-child(1){
                .sticky-table-cell{
                    background: linear-gradient(0deg, rgba(255, 255, 255, 0.24), rgba(255, 255, 255, 0.24)), #121212;
                    color: rgba(255, 255, 255, 0.6);
                    font: normal 600 12px/130% $Poppins;
                    width: 100% !important;
                    &:nth-child(1){
                        text-align: unset;
                    }
                    &:nth-child(2){
                        cursor: unset;
                        width: unset;
                    }
                    &:last-child{
                        width: 100%;
                    }
                    span{
                        display: inline-block;
                        padding-bottom: 4px;
                        vertical-align: bottom;
                    }
                    .sort_icon_container{
                        cursor: pointer;
                        display: inline-flex;
                        flex-direction: column;
                        margin-left: 10px;
                        svg{
                            color: rgba(255, 255, 255, 0.6);
                            height: 12px;
                            width: 10px;
                            &:first-child{
                                transform: translateY(1.5px) scale(1.2);
                            }
                            &:last-child{
                                transform: translateY(-1.5px) scale(1.2);
                            }
                            &.fa-caret-up.sort_desc{
                                color: #81A0EA !important;
                            }
                            &.fa-caret-down.sort_asc{
                                color: #81A0EA !important;
                            }
                        }
                    }
                }
            }
            /* Table Body */
            &:nth-child(n+2){
                height: 50px;
                .sticky-table-cell{
                    background: #333333;
                    border-top: 1px solid #5D5D5D;
                    color: $white;
                    cursor: unset;
                    font: normal 400 14px/130% $Poppins;
                    padding: 18px 15px;
                    &:nth-child(1){
                        > div{
                            align-items: start;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            gap: 5px;

                            .checkbox{
                                margin-right: 25px;
                            }
                            .drag_drop_icon{
                                background: url("https://assets.codingdojo.com/learn_platform/admin/curriculum_management/admin_curriculum_management_sprite.png") no-repeat -144px -17px;
                                height: 27px;
                                margin-right: 25px;
                                width: 13px;
                            }
                            img{
                                margin-right: 20px;
                            }
                            .course_name{
                                align-items: center;
                                color: $white;
                                display: flex;
                                font: normal 400 12px/130% $Poppins;
                            }
                            .course_description{
                                align-items: center;
                                color: rgba(255, 255, 255, 0.5);
                                display: flex;
                                font: normal 400 12px/130% $Poppins;
                            }
                        }
                    }
                }
            }
        }
        #table_loading_container{
            margin-left: unset;
            position: absolute;
        }
        #no_results_found{
            margin-left: unset;
            position: absolute;
        }
    }
}