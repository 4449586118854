#quiz_result_container{
    h2{
        color: #152C61;
        margin-bottom: 60px;
        font: normal 400 26px/30px 'Poppins', sans-serif;

        span{
            color: #333333;
        }
    }

    ol{
        li{
            margin-bottom: 52px;

            &:last-child{
                margin-bottom: 0px;
            }

            .blank_question_container{
                margin: 18px auto 0;
                width: 78%;

                input{ 
                    background: #F8FAFF;
                    border-radius: 2px;
                    border: 2px solid #A4B0C0;
                    box-sizing: border-box;
                    color: #000;
                    font: normal 300 16px/24px 'Poppins', sans-serif;
                    height: 49px;
                    width: 320px;
                    padding: 12px 24px;
                    outline: none;

                    &.wrong{
                        border-radius: 2px;
                        border: 2px solid #CC392E !important;
                        box-sizing: border-box;
                    }
    
                    &.correct{
                        border-radius: 2px;
                        border: 2px solid #2ECC70 !important;
                        box-sizing: border-box;
                    }
                }
            }

            .question_header{
                align-items: center;
                display: flex;
                justify-content: space-between;
                margin: 0px auto;
                width: 78%;
                                    
                p{
                    color: #152C61;
                    font: normal 500 16px/24px 'Poppins', sans-serif;
                    margin: 0px 20px 0px 0px;
                }

                >img{
                    height: 22px;
                    width: 22px;
                }
            }

            .question_choices{
                color: #fff;
                list-style-type: none;

                li{
                    background: #DCE6FF !important;
                    border-radius: 2px !important;
                    border: 2px solid #2C6BFF !important;
                    box-sizing: border-box;
                    color: #000 !important;
                    font: normal 600 16px/24px 'Poppins', sans-serif !important;
                    margin-bottom: 5px;
                }
            }


            .question_choice{
                align-items: center;
                background: #FFFFFF;
                border-radius: 2px;
                border: 2px solid #E5EEFD;
                box-sizing: border-box;
                color: #000000;         
                display: flex;
                font: normal 300 16px/24px 'Poppins', sans-serif;
                margin: 18px auto 0px auto;
                min-height: 50px;
                padding: 10px 51px;
                width: 78%;

                &.checked{
                    &::after,
                    &::before{
                        border-radius: 0 !important;
                        content: "";
                        position: absolute;
                    }

                    &::after{
                        background: url("https://assets.codingdojo.com/learn_platform/admin/student_progress/quizzes_sprite.png") no-repeat -118px -22px !important;
                        border-radius: 2px !important;
                        border: 2px solid #295EDB !important;
                        height: 16px;
                        left: 144px;
                        width: 16px;
                    }

                    &::before{
                        background-color: #295EDB !important;
                        height: 16px;
                        left: 144px;
                        opacity: 1;
                        visibility: visible;
                        width: 16px;
                    }
                }

                &.selected{
                    &::after,
                    &::before{
                        border-radius: 50%;
                        content: "";
                        position: absolute;
                    }

                    &::after{
                        border: 4px solid #295EDB;
                        height: 16px;
                        left: 144px;
                        width: 16px;
                    }

                    &::before{
                        background: transparent;
                        height: 16px;
                        left: 144px;
                        opacity: 1;
                        visibility: visible;
                        width: 16px;
                    }
                }

                &.checkbox{
                    &::after,
                    &::before{
                        border-radius: 2px !important;
                        content: "";
                        position: absolute;
                    }

                    &::after{
                        border: 1px solid #A4B0C0;
                        height: 16px;
                        left: 144px;
                        width: 16px;
                    }

                    &::before{
                        background: transparent;
                        height: 16px;
                        left: 144px;
                        opacity: 0;
                        visibility: hidden;
                        width: 16px;
                    }
                }

                &.radio_btn{
                    &::after,
                    &::before{
                        border-radius: 50%;
                        content: "";
                        position: absolute;
                    }

                    &::after{
                        border: 1px solid #A4B0C0;
                        height: 16px;
                        left: 144px;
                        width: 16px;
                    }

                    &::before{
                        background: transparent;
                        height: 16px;
                        left: 144px;
                        opacity: 0;
                        visibility: hidden;
                        width: 16px;
                    }
                }

                &.wrong{
                    background: #F8FAFF;
                    border-radius: 2px;
                    border: 2px solid #CC392E !important;
                    box-sizing: border-box;
                }

                &.correct{
                    background: #F8FAFF;
                    border-radius: 2px;
                    border: 2px solid #2ECC70 !important;
                    box-sizing: border-box;
                }
            }

            .code_editor_container{
                margin: 19px 0px 20px 0px;
            }

            .notes{
                align-items: flex-start;
                border-radius: 2px;
                box-sizing: border-box;
                display: flex;
                flex-direction: column;
                font: normal 400 12px/18px 'Poppins', sans-serif;
                margin: 18px auto 0px auto;
                min-height: 50px;
                padding: 12px 24px;
                width: 78%;

                p{
                    margin: 0 !important;
                }
            }

            .notes_correct_answer{
                background: #EFF3FE;
                border: 2px solid #E5EEFD;
                color: #152C61;         
            }

            .notes_wrong_answer{
                background: #FEEFEF;
                border: 2px solid #FDE5E5 !important;      
                color: #5B1010;
            }
        }
    }

    button{
        align-items: center;
        background: transparent;
        border: 2px solid #505170;
        border-radius: 6px;
        box-sizing: border-box;
        color: #505170;
        display: flex;
        font: normal 500 15px/22px 'Poppins', sans-serif;
        height: 36px;
        justify-content: center;
        margin-top: 63px;
        margin: 0px auto;
        outline: none;
        text-align: center;
        width: 120px;
    }
}

.is_dark_mode{
    #quiz_result_container{
        h2{
            color: #fff !important;

            span{
                color: #fff !important;
            }
        }

        .question_header{
            p{
                color: #fff !important;
            }
        }

        button{
            border: 2px solid #81A0EA;
            color: #81A0EA;
        }

        ol{
            li{
    
                .blank_question_container{
                    input{ 
                        background: #404040;
                        color: rgba(255, 255, 255, 0.88);
    
                        &.wrong{
                            border: 2px solid #F56B6B !important;
                        }
        
                        &.correct{
                            border: 2px solid #05DA80 !important;
                        }
                    }
                }
     
                .question_choice{
                    background: #404040;
                    border: none;
                    color: rgba(255, 255, 255, 0.88);
    
                    &.selected{
                        &::before{
                            background: #fff;
                            height: 12px;
                            left: 145px;
                            width: 12px;
                        }
                    }
    
                    &.checkbox{
                        &::after{
                            background: #fff;
                        }
    
                        &::before{
                            background: #fff;
                        }
                    }
    
                    &.radio_btn{
    
                        &::after{
                            background: #fff;
                            border: 1px solid #A4B0C0;
                        }
                    }
    
                    &.wrong{
                        background: #595959;
                        border: 2px solid #CC392E !important;
                    }
    
                    &.correct{
                        background: #595959;
                        border: 2px solid #05DA80 !important;
                    }
                }
    
                .notes_correct_answer{
                    background: #4B4B4B;
                    border: 1px solid #E5EEFD;
                    color: rgba(255, 255, 255, 0.88);        
                }
    
                .notes_wrong_answer{
                    background: #FBCACA;
                    border: 1px solid #FDE5E5 !important;      
                    color: #5B1010;
                }
            }
        }
    }
}