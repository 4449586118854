#discussion_answer_container{
    background: #fff;
    border-radius: 6px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
    font-family: 'Poppins', sans-serif;
    margin-top: 20px;
    padding: 25px 28px 25px;
    width: 100%;
    h2{
        margin-bottom: 43px;
        span{
            background: #FFF1C7;
            border-radius: 6px;
            color: #F2994A;
            font-size: 16px;
            margin-left: 12px;
            padding: 2px 30px;
            vertical-align: middle;
        }
    }
    ul{
        list-style: none;
        li{
            background: #FBFBFB;
            border-radius: 2px;
            margin-bottom: 20px;
            padding: 51px 55px 40px;
            position: relative;
            p{
                word-wrap: break-word;
            }
            #online_discussion_item_form{
                display: flex;
                flex-wrap: wrap;
                #online_discussion_item_textarea{
                    border-radius: 2px;
                    border: 2px solid #505170;
                    color: #152C61;
                    font: normal 400 16px/normal "Poppins";
                    margin-bottom: 30px;
                    min-height: 280px;
                    outline: none;
                    padding: 30px 20px;
                    resize: none;
                    width: 100%;
                }
                button{
                    font: normal 400 18px/normal "Poppins";
                    margin: 0px;
                    position: relative;
                    text-align: center;
                    &[type=button]{
                        background-color: transparent;
                        color: #2C6BFF;
                        margin-left: auto;
                    }
                    &[type=submit]{
                        background: #2C6BFF;
                        border-radius: 6px;
                        color: #FFF;
                        margin-left: 76px;
                        padding: 10px 15px;
                    }
                }
            }

            button{
                position: absolute;
                &#remove_button{
                    background: #fff;
                    border-radius: 100%;
                    border: none;
                    color: #5E6871;
                    height: 16px;
                    line-height: 13px;
                    margin: 0px;
                    outline: none;
                    padding: 0px;
                    right: 22px;
                    top: 27px;
                    width: 16px;
                    svg{
                        margin-left: 0px;
                    }
                    &:hover{
                        color: #F56B6B;
                    }
                }
                &#edit_button{
                    background: url("https://assets.codingdojo.com/learn_platform/user/courses/discussion_question_icon.png") no-repeat -4px -4px;
                    width: 22px;
                    height: 22px;
                    margin: 0px;
                    right: 67px;
                    top: 23px;
                }
                &#remove_button{
                    background: url("https://assets.codingdojo.com/learn_platform/user/courses/discussion_question_icon.png") no-repeat -34px -7px;
                    height: 16px;
                    right: 24px;
                    top: 26px; 
                    width: 16px;
                }
                
            }
            .blk{
                align-items: center;
                border-top: 1px solid #DCE6FF;
                display: flex;
                justify-content: space-between;
                margin-top: 40px;
                padding-top: 40px;
                form{
                    label{
                        align-items: center;
                        cursor: pointer;
                        display: inline-flex;
                        font-size: 20px;
                        margin-bottom: 0px;
                        margin-right: 39px;
                        &:last-child{
                            margin-right: 0px;
                        }
                        input{
                            display: none;
                        }
                        input:checked ~  span {
                            color: #4CE164;
                            &.like_icon{
                                background-position: -40px -2px;
                            }
                            &.dislike_icon{
                                & ~ span{
                                    color:rgba(225,85,76,1);
                                }
                            }
                        }
                        .dislike_icon, .like_icon{
                            background-color: #E0E0E0;
                            background-image: url("https://assets.codingdojo.com/learn_platform/user/courses/like_dislike_icon.png");
                            background-position: 0px -2px;
                            border-radius: 100%;
                            display: inline-block;
                            height: 40px;
                            margin-right: 20px;
                            width: 40px;
                        }
                        .dislike_icon{
                            background-position: 41px 1px;
                        }
                    }
                }
                h6{
                    margin-bottom: 0px;
                    span{
                        margin-left: 34px;
                    }
                }
            }
        }
    }
    button{
        background: none;
        border: none;
        color: #2C6BFF;
        display: block;
        font-family: 'Poppins', sans-serif;
        font-size: 16px;
        margin: 43px auto 127px;
        outline: none;
        svg{
            margin-left: 7px;
        }
    }
}
@media only screen and (max-width: 768px){
    #discussion_answer_container{
        & > ul > li:nth-child(1) > p{
            word-break: break-word;
        }
        ul{
            .is_hide_edit_delete{
                padding-top: 16px !important;
            }
        }
    }
}
.is_dark_mode{
    #discussion_answer_container{
        button{
            color: #81a0ea;
            &#edit_button{
                background: url("https://assets.codingdojo.com/learn_platform/user/courses/discussion_question_icon.png") no-repeat -58px -4px;               ;
            }
            &#remove_button{
                background: url("https://assets.codingdojo.com/learn_platform/user/courses/discussion_question_icon.png") no-repeat -88px -7px;
            }
        }
        ul{
            li{
                #online_discussion_item_form{
                    #online_discussion_item_textarea{
                        background: linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), #121212;
                        color: rgba(255, 255, 255, 0.88);
                    }
                    button{
                        color: #81A0EA !important;
                        &[type=button]{
                            background-color: transparent !important;
                        }
                        &[type=submit]{
                            background: rgba(204, 224, 255, 0.14) !important;
                        }
                    }
                }
            }
        }
    }
}