#admin_header_container{
    align-items: center;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), #121212;
    display: flex;
    height: 56px;
    justify-content: space-between;
    padding-left: 240px;
    padding-right: 40px;

    ul{
        align-items: center;
        display: flex;
        margin-bottom: 0px;
        li{
            a{
                color: #DEE5EF;
                font-family: 'Poppins', sans-serif;
                font-size: 12px;
                font-style: normal;
                font-weight: 600;
                line-height: 130%;

                &:after{
                    content: "/";
                    margin: 0px 4px;
                }
            }
        }
    }  
    
    #profile_container{
        align-items: center;
        display: flex;

        img{
            background: #F6F9FD;
            border-radius: 50px;
            border: 2px solid #FFFFFF;
            box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.07);
            height: 40px;
            margin-right: 10px;
            width: 40px;
        }

        div{
            align-items: center;
            color: #F6F9FD;
            display: flex;
            font-family: 'Poppins', sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: normal;
            line-height: 140%;

            span{
                align-items: center;
                color: #F6F9FD;
                display: flex;
                font-family: 'Poppins', sans-serif;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: 130%;
                margin-left: 3px;
            }
        }
    }
}

#course_container.admin_container{
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.04), rgba(255, 255, 255, 0.04)), #121212;
    display: flex;
    padding-top: 17px;
    aside{
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.12)), #121212;
        height: 100vh;
        left: 0;  
        padding-top: 31px;  
        position: fixed;
        top: 0;
        width: 200px;
        z-index: 991;

        #chapter_list{
            height: calc(100% - 78px);
            margin-bottom: 30px;
            overflow-y: auto;

            >li{
                margin-bottom: 30px;

                &:last-child{
                    margin-bottom: 0px;
                }

                &.active{
                    >a{
                        background: rgba(196, 196, 196, 0.1);
                        border-left: 4px solid rgba(129, 160, 234, 0.4);
                    }

                    .module_list{
                        display: block;
                    }
                }
                >a{
                    color: #DEE5EF;
                    display: inline-block;
                    font-family: 'Poppins', sans-serif;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    height: 40px;
                    line-height: 140%;
                    margin-bottom: 14px;
                    overflow: hidden;
                    padding: 10px 24px;
                    text-decoration: none;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    width: 100%;
                }

                .module_list{
                    display: none;
                    >li{
                        align-items: center;
                        display: flex;
                        margin-bottom: 8px;
                        padding: 0px 10px 0px 46px;
                        width: 100%;

                        &:last-child{
                            margin-bottom: 0px;
                        }


                        &:hover{
                            .dropdown{
                                .dropdown-toggle{
                                    opacity: 1;
                                }
                            }
                            .drag_handle{
                                visibility: visible;
                            }
                        }

                        &.active{   
                            background: rgba(131, 179, 252, 0.26);
                        }
                        >button{
                            background-color: transparent;
                            border: none;
                            color: #fff;
                            font-family: 'Poppins', sans-serif;
                            font-size: 12px;
                            font-style: normal;
                            font-weight: normal;
                            height: 28px;
                            line-height: 130%;
                            outline: none !important;
                            overflow: hidden;
                            padding: 7px 7px 7px 0px;                            
                            text-align: left;
                            text-decoration: none;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            width: 100%;

                            &.quiz_module{
                                color: #F2994A;
                            }

                            &.lesson_module{
                                color: #F7C96E;
                            }

                            &.admin_module{
                                color: #6EF7D7;
                            }

                            &.todo_module{
                                color: #AB8BE9;
                            }

                            &.chapter_survey_module{
                                color: #FFE3E3;
                            }
                        }

                        .dropdown{
                            position: absolute;
                            right: 10px;
                            .dropdown-toggle{
                                -webkit-transition: all .3s;
                                align-items: center;
                                background: transparent;
                                border: none;
                                box-shadow: none;
                                display: flex;
                                justify-content: center;
                                opacity: 0;     
                                outline: none;
                                padding: 0px;
                                transition: all .3s;  
                                
                                &:after{
                                    display: none;
                                }
                                svg{
                                    color: #F6F9FD;
                                    font-size: 14px;                 
                                }
                            }

                            .dropdown-menu{
                                background: linear-gradient(0deg, rgba(255, 255, 255, 0.22), rgba(255, 255, 255, 0.22)), #121212;
                                border-radius: 4px;
                                border: none;
                                margin-left: 85px !important;
                                padding: 5px 20px ;

                                >ul{
                                    margin-bottom: 0px;

                                    >li{
                                        border-bottom: 1px solid rgba(131, 179, 252, 0.26);

                                        &:last-child{
                                            border-bottom: none !important;
                                        }

                                        >a, >button{
                                            align-items: center;
                                            background-color: transparent;
                                            border: none;                                 
                                            color: #F6F9FD;
                                            display: flex;
                                            font-family: 'Poppins', sans-serif;
                                            font-size: 14px;
                                            font-style: normal;
                                            font-weight: normal;
                                            justify-content: space-between;   
                                            line-height: 140%;
                                            outline: none;
                                            padding: 16px 0px 15px 0px;
                                            width: 100%;
        
                                            &:hover{
                                                background-color: transparent !important;
                                            }
                                        }

                                        .dropdown_sub_menu{
                                            background: linear-gradient(0deg, rgba(255, 255, 255, 0.22), rgba(255, 255, 255, 0.22)), #121212;
                                            border-radius: 4px;
                                            border: none;
                                            left: 165px;
                                            padding: 5px 20px;
                                            position: fixed;
                                            top: 56px;
                                            width: 160px;
                                            li{
                                                align-items: center;
                                                background-color: transparent;
                                                border-bottom: 1px solid rgba(131, 179, 252, 0.26) !important;
                                                border: none;  
                                                color: #F6F9FD;
                                                cursor: pointer; 
                                                display: flex;
                                                font-family: 'Poppins', sans-serif;
                                                font-size: 14px;
                                                font-style: normal;
                                                font-weight: normal;
                                                justify-content: space-between;   
                                                line-height: 140%;
                                                outline: none;
                                                padding: 16px 0px 15px 0px;
                                                width: 100%;

                                                &:last-child{
                                                    border-bottom: none !important;
                                                }                             
                                            }
                                        }
                                    }
                                }

                            }
                        }


                        .drag_handle{
                            background-image: url("https://assets.codingdojo.com/learn_platform/admin/edit_course/admin_edit_course_sprite.png");
                            background-position: -33px 0px;
                            background-repeat: no-repeat;
                            cursor: move;
                            display: flex;
                            height: 13px;
                            margin-right: 14px;
                            visibility: hidden;
                            width: 13px;
                        }
                   } 
                }
            }
        }

        #exit_edit_mode_btn{
            align-items: center;
            color: #DEE5EF;
            display: flex;
            font-family: 'Poppins', sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: normal;
            justify-content: center;
            line-height: 140%;
            text-decoration: none;

            span{
                background-image: url("https://assets.codingdojo.com/learn_platform/admin/edit_course/admin_edit_course_sprite.png");
                background-position: -135px 0px;
                background-repeat: no-repeat;
                display: flex;
                height: 16px;
                margin-right: 20px;
                width: 16px;
            }
        }
    }

    .right_container{
        padding: 0px 40px 0px 240px;
        width: 100%;

        #module_controls_container{
            align-items: center;
            display: flex;
            justify-content: space-between;
            margin-bottom: 17px;

            #module_updated_at{
                background: rgba(207, 252, 242, 0.26);
                border-radius: 4px;
                color: #6EF7D7;
                font-family: 'Poppins', sans-serif;
                font-size: 10px;
                font-style: normal;
                font-weight: 600;
                line-height: 120%;
                padding: 4px 5px;
            }

            form{
                align-items: center;
                display: flex;
                justify-content: center;

                a{
                    align-items: center;
                    border-radius: 4px;
                    border: 2px solid rgba(131, 179, 252, 0.26);
                    box-sizing: border-box;
                    color: #81A0EA;
                    display: flex;
                    font-family: 'Poppins', sans-serif;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 600;
                    height: 40px;
                    justify-content: center;
                    line-height: 130%;
                    width: 140px;
                }

                button{
                    align-items: center;
                    background: rgba(131, 179, 252, 0.26);
                    border-radius: 4px;
                    border: none;
                    color: #81A0EA;
                    display: flex;
                    font-family: 'Poppins', sans-serif;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 600;
                    height: 40px;
                    justify-content: center;
                    line-height: 130%;
                    margin-left: 20px;
                    outline: none;
                    width: 140px;
                }
            }
        }

        #module_description_container{
            .redactor-box{
                background-color: transparent;
                border: none;
                margin-bottom: 25px;

                .redactor-toolbar-wrapper{
                    border-top-left-radius: 4px;
                    border-top-right-radius: 4px;
                    height: 44px;

                    .redactor-toolbar{
                        background: linear-gradient(0deg, rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.12)), #121212;
                        padding: 0px;
                        .re-button{
                            background-color: transparent;
                            border: none;
                            box-shadow: none;
                            color: #A4B0C0;
                            margin: 0px;
                            padding: 14px 15px 12px 15px;
                            text-decoration: none;

                            &.re-underline{
                                margin-right: 33px;
                            }
                            &.re-redo{
                                position: absolute;
                                right: 0;
                                top: 0px;
                            }
                            &.re-undo{
                                position: absolute;
                                right: 46px;
                                top: 0px;
                            }
                        }
                    }
                }

                .redactor-in{
                    background: #FFFFFF;
                    border-bottom-left-radius: 4px;
                    border-bottom-right-radius: 4px;
                    color: #000000;
                    font-family: 'Poppins', sans-serif;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: normal;
                    line-height: 140%;
                    padding: 43px 58px;
                }
            }
        }

        #questions_container{
            border: none;
            margin-bottom: 40px;

            .card-header{
                align-items: center;
                background: linear-gradient(0deg, rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.12)), #121212;
                display: flex;
                height: 60px;
                justify-content: space-between;
                padding: 0px 40px;

                h6{
                    align-items: center;
                    color: #DEE5EF;
                    display: flex;
                    font-family: 'Poppins', sans-serif;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: bold;
                    letter-spacing: -0.01em;
                    line-height: 120%;
                    margin-bottom: 0px;
                }

                .dropdown{
                    #add_new_question_btn{
                        align-items: center;
                        background: #DEE5EF;
                        border-radius: 4px;
                        border: none;
                        color: #2C6BFF;
                        display: flex;
                        font-family: Poppins;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 600;
                        height: 32px;
                        justify-content: center;
                        line-height: 130%;
                        width: 134px;

                        svg{
                            margin-left: 5px;
                        }

                        &:after{
                            display: none;  
                        }
                    }

                    .dropdown-menu{
                        border-radius: 4px;
                        border: none;
                        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.07);
                        padding: 5px 20px 5px 20px;
                        width: 202px;

                        a{
                            border-bottom: 1px solid #DEE5EF;
                            color: #394A64;
                            font-family: 'Poppins', sans-serif;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: normal;
                            line-height: 140%;
                            padding: 16px 0px 15px 0px;
                            &:hover{
                                background-color: #fff;
                            }

                            &:last-child{
                                border-bottom: none;
                            }
                        }
                    }
                }
            }

            .card-body{
                padding: 40px 59px;
                ul#questions_list{
                    margin-bottom: 0px;

                    >li{
                        margin-bottom: 40px;

                        &:last-child{
                            margin-bottom: 0px;
                        }
                        
                        &:hover{
                            >div{
                                .drag_handle, .delete_btn{
                                    visibility: visible;
                                }
                            }
                        }
                        .drag_handle{
                            background-image: url("https://assets.codingdojo.com/learn_platform/admin/edit_course/admin_edit_course_sprite.png");
                            background-position: 0px 0px;
                            background-repeat: no-repeat;
                            cursor: move;
                            display: flex;
                            height: 13px;
                            margin-right: 14px;
                            visibility: hidden;
                            width: 13px;
                        }

                        .delete_btn{
                            background-image: url("https://assets.codingdojo.com/learn_platform/admin/edit_course/admin_edit_course_sprite.png");
                            background-position: -101px 0px;
                            background-repeat: no-repeat;
                            border: none;
                            display: flex;
                            height: 18px;
                            outline: none;
                            visibility: hidden;
                            width: 14px;
                        }

                        >div:not(.code_editor_container){
                            align-items: center;
                            background: #F8FAFF;
                            border-radius: 4px;
                            display: flex;
                            min-height: 44px;
                            padding: 0px 20px;

                            input{
                                background-color: transparent;
                                border: none;
                                color: #121212;
                                flex: 1;
                                font-family: 'Poppins', sans-serif;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 600;
                                height: 28px;
                                line-height: 130%;
                                outline: none;
                                padding: 0px 25px;
                            }

                            .drag_handle{
                                margin-right: 0px;
                            }
                        }

                        .code_editor_container{
                            background: #F8FAFF;
                            margin: 20px 46px 0px 46px;
                            .editor{
                                counter-reset: line;

                                textarea {
                                    outline: none;
                                    padding: 21px 0px 20px 80px !important;
                                }
                                  
                                pre {
                                    padding: 21px 0px 20px 80px !important;
                                    position: relative;

                                    &:before{
                                        background: #DCE6FF;
                                        content: "";
                                        height: 21px;
                                        left: 0;
                                        position: absolute;
                                        top: 0;
                                        width: 60px;
                                    }

                                    &:after{
                                        background: #DCE6FF;
                                        bottom: 0;
                                        content: "";
                                        height: 21px;
                                        left: 0;
                                        position: absolute;
                                        width: 60px;
                                    }
                                }
                                  
                                .editorLineNumber {
                                    background: #DCE6FF;
                                    color: #BDBDBD;
                                    font-family: 'Poppins', sans-serif;
                                    font-size: 16px;
                                    font-style: normal;
                                    font-weight: 500;
                                    left: 0px;
                                    line-height: 24px;
                                    padding: 0px 20px;
                                    position: absolute;
                                    text-align: left;
                                    width: 60px;
                                }
                                  
                            }
                            
                            .dc_iframe{
                                iframe{
                                    width: 100% !important;
                                }
                            }
                        }

                        ul.options_list{
                            padding-left: 77px;

                            .multiple{
                                align-items: center;
                                background: #F8FAFF;
                                border-radius: 4px;
                                border: 2px solid #DCE6FF;
                                box-sizing: border-box;
                                display: flex;
                                margin-top: 20px;
                                min-height: 44px;
                                padding: 0px 18px 0px 20px;

                                &:hover{
                                    .drag_handle, .delete_btn{
                                        visibility: visible;
                                    }
                                }

                                input[type=text] {
                                    background: transparent;
                                    border: none;
                                    color: #121212;
                                    flex: 1;
                                    font: normal normal 14px/140% 'Poppins', sans-serif;
                                    height: 28px;
                                    outline: none;
                                    padding: 0px 23px;
                                }

                                input[type=checkbox]:checked ~ label{  
                                    background: #295EDB;

                                    .fill{
                                        background: url("https://assets.codingdojo.com/learn_platform/admin/student_progress/quizzes_sprite.png") no-repeat -118px -22px !important;
                                        border: 1px solid #295EDB !important;
                                        display: flex;
                                        height: 15px;
                                        width: 15px;
                                    }
                                }

                                label{
                                    align-items: center;
                                    background: #DEE5EF;
                                    border-radius: 0 !important;
                                    border: 1px solid #DEE5EF;
                                    box-sizing: border-box;
                                    cursor: pointer;
                                    display: flex;
                                    height: 15px;
                                    justify-content: center;
                                    margin-bottom: 0px;
                                    width: 15px;
                                }
                            }

                            >li{
                                align-items: center;
                                background: #F8FAFF;
                                border-radius: 4px;
                                border: 2px solid #DCE6FF;
                                box-sizing: border-box;
                                display: flex;
                                margin-top: 20px;
                                min-height: 44px;
                                padding: 0px 18px 0px 20px;

                                &:hover{
                                    .drag_handle, .delete_btn{
                                        visibility: visible;
                                    }
                                }

                                input[type=text]{
                                    background-color: transparent;
                                    border: none;
                                    color: #121212;
                                    flex: 1;
                                    font-family: 'Poppins', sans-serif;
                                    font-size: 14px;
                                    font-style: normal;
                                    font-weight: normal;
                                    height: 28px;
                                    line-height: 140%;
                                    outline: none;
                                    padding: 0px 23px;
                                }

                                input[type=radio]:checked ~ label{  
                                    background: #295EDB;

                                    .fill{
                                        background: #FFFFFF;
                                        border-radius: 60px;
                                        display: flex;
                                        height: 7px;
                                        width: 7px;
                                    }
                                }

                                label{
                                    align-items: center;
                                    background: #DEE5EF;
                                    border-radius: 60px;
                                    border: 1px solid #DEE5EF;
                                    box-sizing: border-box;
                                    cursor: pointer;
                                    display: flex;
                                    height: 15px;
                                    justify-content: center;
                                    margin-bottom: 0px;
                                    width: 15px;
                                }
                            }
                        }

                        #blank_question_answer{
                            align-items: center;
                            background: #F8FAFF;
                            border-radius: 4px;
                            border: 2px solid #DCE6FF;
                            box-sizing: border-box;
                            display: flex;
                            margin: 20px 0 0 77px;
                            min-height: 44px;
                            padding: 0px 18px 0px 20px;

                            >span{
                                margin-left: 20px;
                            }
                        }

                        form{
                            align-items: center;
                            background: #FFFFFF;
                            border-radius: 4px;    
                            border: 2px solid #DEE5EF;
                            box-sizing: border-box;
                            display: flex;
                            justify-content: center;
                            margin-left: 77px;
                            margin-top: 20px;
                            min-height: 44px;   

                            input[type=text]{
                                background-color: transparent;
                                border: none;
                                color: #121212;
                                flex: 1;
                                font-family: 'Poppins', sans-serif;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: normal;
                                height: 28px;
                                line-height: 140%;
                                outline: none;
                                padding: 0px 23px;
                            }

                            button{
                                background: #DEE5EF;
                                border: none;
                                color: #394A64;
                                font-family: 'Poppins', sans-serif;
                                font-size: 12px;
                                font-style: normal;
                                font-weight: normal;
                                height: 44px;
                                line-height: 130%;
                                outline: none;
                                width: 179px;
                            }
                        }
                    }
                }
            }
        }

    }
}


  
