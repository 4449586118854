#edit_discussion_board_modal{
    max-width: 570px;
    .modal-content{
        border-radius: 6px;
        border: none;
        color: #333333;
        font-family: 'Poppins', sans-serif;
        font-size: 16px;
        .modal-header{
            background: #2C6BFF;
            color: #fff;
            font-size: 22px;
            font-weight: 500;
            padding: 10px 30px;
        }
        .modal-body{
            padding: 30px 30px;
            label{
                display: block;
                font-weight: 600;
                margin-bottom: 10px;

                span.red{
                    color: #f56b6b;
                    font-size: 12px;
                    font-weight: normal;
                    margin-left: 3px;
                }
            }
            input, textarea{
                border: 1px solid #DCE6FF;
                color: #152C61;
                margin-bottom: 31px;
                padding: 10px 20px;
                width: 100%;
            }
            textarea{
               min-height: 160px;
               resize: none;
            }
            .react-datepicker-wrapper{
                display: block;
                position: relative;
                svg{
                    color: #2C6BFF;
                    left: 23px;
                    position: absolute;
                    top: 14px;
                }
                input{
                    padding-left: 47px;
                }
            }
            .blk{
                align-items: center;
                display: flex;
                justify-content: flex-end;
                button{
                    font-weight: 500;
                }
                button[type="submit"]{
                    background: #2C6BFF;
                    border-radius: 6px;
                    border: none;
                    color: #fff;
                    height: 41px;
                    width: 137px;
                }
                button[type="button"]{
                    background: transparent;
                    border: none;
                    color: #152C61;
                    margin-right: 48px;
                }
            }
        }
    }
}
.fade.modal.show{
    background: rgba(45, 45, 45, 0.45);
}
@media only screen and (max-width: 768px){
    #edit_discussion_board_modal{
        .modal-content{
            .modal-header{
                align-items: center;
                background: #ffffff;
                color: #152C61;
                display: block;
                font-size: 16px;
                height: 80px;
                justify-content: center;
                line-height: 21px;
                margin-bottom: 0px;
                overflow: hidden;
                padding: 45px 84px 10px 98px;
                text-align: center;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
            .modal-body{
                .blk{
                    height: 80px;
                    justify-content: space-between;
                    left: 0px;
                    padding: 38px 20px 7px;
                    position: absolute;
                    top: -80px;
                    width: 100%;
                    button[type="submit"]{
                        height: 30px;
                        width: unset;
                    }
                    button[type="button"]{
                        color: #707070;
                    }
                }
            }
        }
    }
    body.is_dark_mode{
        .modal{
            #edit_discussion_board_modal{
                .modal-content{
                    .modal-header{
                        color: #ffff;
                    }
                }
            }
        }
    }
}