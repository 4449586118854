#failed_create_event_modal{
    color: rgba(255, 255, 255, .88);
    width: 400px;

    button:focus{
        outline: 5px transparent;
    }

    .modal-content{
        border: none;
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), #121212;
        box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.15);
    }

    header{
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.14), rgba(255, 255, 255, 0.14)), #121212;
        font-family: "Poppins", sans-serif;
        font-size: 14px;
        font-weight: 700;
        border-radius: 2px 2px 0 0;
        padding: 16px 25px 14px;

        #close_button{
            background: url("https://assets.codingdojo.com/learn_platform/admin/course_calendar/course_calendar_icon.png") no-repeat -66px -5px;
            border: none;
            display: inline-block;
            height: 14px;
            position: absolute;
            right: 23px;
            top: 19px;
            width: 14px;
        }
    }

    ul{
        margin-left: 20px;
        max-width: 355px;
        &#approved_all_list{
            margin-left: -16px;
            list-style: none;
            li{
                margin: 15px 0px 15px 0px;
                &:first-child{
                    margin-top: 10px;
                }
            }
            ul{
                list-style: disc;
                margin-left: 0px;
                li{
                    margin: 5px 0px 5px 0px !important;
                }
            }
        }
        li{
            font: 400 13px "Poppins", sans-serif;
        }
    }

    p{
        font: 400 13px "Poppins", sans-serif;
        margin-bottom: 5px;
        padding: 20px 25px 5px;
        text-align: justify;
    }

    footer{
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.14), rgba(255, 255, 255, 0.14)), #121212;
        border-radius: 0 0 2px 2px;
        text-align: right;
        padding: 20px 20px 19px;

        button{
            border: none;

            &#okay_button{
                background: rgba(204, 224, 255, 0.14);
                border-radius: 2px;
                color: #81A0EA;
                font-weight: 600;
                font-size: 14px;
                font-family: "Poppins", sans-serif;
                padding: 10px 16px;
                margin-left: 30px;
            }

            &#cancel_button{
                background-color: transparent;
                color: rgba(255, 255, 255, .88);
                font-family: "Poppins", sans-serif;
                font-size: 14px;
                font-weight: 600;
            }
        }
    }
}