$Poppins: 'Poppins', sans-serif;
#workspace_management_action_popover{
    border: none;
    z-index: 2;
    .popover-body{
        background: #4B4B4B;
        border-radius: 4px;
        display: flex;
        flex-direction: column;
        padding: 12px 14px;
        button{
            align-items: center;
            background: transparent;
            border: none;
            color: #fff;
            display: flex;
            font-family: $Poppins;
            font-size: 12px;
            margin-bottom: 10px;
            outline: none !important;
            padding: 0px;
            &:last-child{
                margin-bottom: 0px;
            }
            span{
                background-image: url("https://assets.codingdojo.com/learn_platform/admin/workspace_management/workspace_management_icons.svg");
                display: inline-block;
                height: 16px;
                margin-right: 14px;
                vertical-align: top;
                width: 14px;
            }
            .disabled_icon{
                background-position: -42px 0px;
                height: 19px;
                width: 16px;
            }
            .enabled_icon{
                background-position: -13px 0px;
                height: 19px;
                width: 16px;
            }
            .account_management_icon{
                background-position: -73px 0px;
                height: 19px;
                width: 16px;
            }
            .setting_up_icon{
                background-position: -104px 0px;
                height: 19px;
                width: 16px;
            }
            .delete_icon{
                background-position: -133px 0px;
                height: 20px;
                width: 16px;
            }
        }
    }
    &>.arrow::after{
        border-bottom-color: #4b4b4b;
    }
}

#workspace_list{
    color: #fff;
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    max-height: calc(100vh - 179px);
    overflow: auto;
    padding-left: 0px;

    li{
        background: #2A2A2A;
        border-radius: 2px;
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
        margin-right: 20px;
        padding: 20px 20px 20px 90px;
        position: relative;
        width: 357px;
        h5{
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            display: -webkit-box;
            font-family: $Poppins;
            font-size: 14px;
            margin-bottom: 18px;
            overflow: hidden;
            padding-right: 19px;
            text-overflow: ellipsis;
        }
        h6{
            font-family: $Poppins;
            font-size: 11px;
            font-weight: 400;
            margin-bottom: 0px;
            span{
                display: inline-block;
                font-weight: 600;
                margin-right: 7px;
                padding-left: 10px;
                position: relative;
                &::before{
                    border-radius: 100%;
                    content: "";
                    display: block;
                    height: 5px;
                    left: 0px;
                    position: absolute;
                    top: 3px;
                    width: 5px;
                }
            }
            .live{
                color: #05DA80;
                &:before{
                    background: #05DA80;
                }
            }
            .disabled{
                color: #F56B6B;
                opacity: 1;
                &:before{
                    background: #F56B6B;
                }
            }
            .unpublished{
                color: #B3B3B3;
                &:before{
                    background: #B3B3B3;
                }
            }
        }
        img{
            border: 1px solid rgba(204, 224, 255, 0.14);
            height: 50px;
            left: 20px;
            padding: 10px;
            position: absolute;
            width: 50px;
        }

        .workspace_management_action_btn{
            background-color: transparent;
            background-image: url("https://assets.codingdojo.com/learn_platform/admin/workspace_management/workspace_management_icons.svg");
            background-position: 2px 0px;                    
            background-repeat: no-repeat;
            border: none;
            height: 21px;
            padding: 0px;
            position: absolute;
            right: 20px;
            width: 7px;
        }
    }
}