#processing_container{
    align-items: center;
    background: #fff;
    display: flex;
    flex-direction: column;
    gap: 15px;
    height: 100%;
    justify-content: center;
    position: absolute;
    width: 100%;

    p{
        color: #152C61;
        font: 400 32px/48px "Poppins", sans-serif;
    }

    .dots{
        animation: dot-flashing 1s infinite linear alternate .5s;
        background-color: #6190FF;
        border-radius: 50%;
        height: 18px;
        position: relative;
        width: 18px;

        &::before, 
        &::after{
            animation: dot-flashing 1s infinite alternate;
            background-color: #2C6BFF;
            border-radius: 50%;
            content: "";
            display: inline-block;
            height: 18px;
            position: absolute;
            top: 0;
            width: 18px;
        }

        &::before{
            animation-delay: 0s;
            background: #94B4FF;
            right: 39px;
        }

        &::after{
            animation-delay: 1s;
            background: #2C6BFF;
            left: 39px;
        }
    }
}

.is_dark_mode #processing_container{
    background: #121212;

    p{
        color: #fff;
    }
}
@keyframes dot-flashing {
    0% {
        background-color: #2C6BFF;
    }
    100% {
        background-color: #94B4FF;
    }
}