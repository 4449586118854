$Poppins: 'Poppins', sans-serif;
#admin_navigation_container{
    background-color: #282828;
    display: flex;
    flex-direction: column;
    height: 100%;
    min-width: 208px;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 20px 15px 15px 15px;
    position: relative;
    transition: all .3s;
    width: 208px;

    *{
        transition: opacity .3s;
    }

    &.collapse{
        min-width: 20px;
        overflow: hidden;
        padding: 20px 10px;
        transition: all .3s;
        width: 20px;

        *:not(#toggle_collapse_sidebar_btn, .fa-chevron-left){
            -webkit-transition: all .3s;
            opacity: 0;
            transition: all .3s;
        }
        #toggle_collapse_sidebar_btn{
            -ms-transform: rotate(180deg);
            -webkit-transition: all .3s;
            padding: 0px 10px;
            right: -4px;
            transform: rotate(180deg);
            transition: all .3s;
        }
    }

    #toggle_collapse_sidebar_btn{
        -webkit-transition: all .3s;
        background-color: transparent;
        border: none;
        color: #fff;
        font-size: 12px;
        opacity: 1 !important;
        outline: none;
        padding: 0px 5px;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translate(0%, -50%);
        transition: all .3s;
      
        *{
            opacity: 1 !important; 
        }
    }

    h3{
        align-items: center;
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
        color: #fff;
        display: flex;
        font-family: $Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: bold;
        letter-spacing: -0.01em;    
        line-height: 120%;
        margin-bottom: 33px;
        padding: 8px 0px 28px 10px;
    }

    #navigation_list{
        margin-bottom: 0px;

        >li{
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-bottom: 21px;

            &:last-child{
                margin-bottom: 0px;
            }

            &.active{
                >a{
                    background: rgba(204, 224, 255, 0.14);
                    border-radius: 2px;
                    color:  #81A0EA;
                    pointer-events: none;
                }
            }
            
            >a{
                align-items: center;
                color: #fff;
                display: flex;
                font-family: $Poppins;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                height: 38px;
                padding: 0px 10px;
                text-align: left;
                text-decoration: none;
                width: 100%;
            }

            ul{
                display: flex;
                flex-direction: column;
                padding: 21px 0px 0px 20px;

                li{
                    padding: 8px 0px;

                    &.active{
                        a{
                            color: #81A0EA;
                        }
                    }
                    
                    a{
                        align-items: center;
                        color: rgba(255, 255, 255, 0.58);
                        display: flex;
                        font-family: $Poppins;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 500;
                    }
                }
            }
        }
    }
}