$blue: #2C6BFF;
#login_container{
    display: flex;
    min-height: 100vh;
    .left_container{
        padding: 140px 120px 140px 60px;
        width: 474px;
        @media screen and (max-width: 1366px) {
            padding: 48px 120px 140px 60px;
        }
        h5{
            color: $blue;
            font: normal 600 36px/54px 'Poppins', sans-serif;
            margin: 86px 0px 70px;
        }
        form{
            .form-group{
                align-items: center;
                display: flex;
                justify-content: space-between;
                margin-bottom: 30px;
                .form-control{
                    background-color: transparent;
                    border: none;
                    border-bottom: 2px solid  $blue;
                    border-radius: 0px;
                    box-shadow: none;
                    font: normal 300 14px/21px 'Poppins', sans-serif;
                    outline: none;
                    padding: 19px 0px;
                    &::placeholder{
                        color: rgba(21, 44, 97, 0.4);
                    }
                    &[type=password]{
                        font-size: 16px;
                        letter-spacing: 14px;
                    }
                }
                #show_forgot_password{
                    background-color: transparent;
                    border: none;
                    color: $blue;
                    font: normal normal 14px/21px 'Poppins', sans-serif;
                    outline: none;
                    padding: 0px;
                }
                .checkbox{
                    .fa-check{
                        display: none;
                    }
                    #remember_me_checkbox:checked ~ label{
                        .checkbox_container{
                            background-color: $blue;
                            .fa-check{
                                color: #fff;
                                display: inline;
                                font-size: 10px;
                            }
                        }    
                    }
                    label{
                        align-items: center;
                        cursor: pointer;
                        display: flex;
                        margin-bottom: 0px;
                        .checkbox_container{ 
                            align-items: center;
                            border-radius: 2px;
                            border: 2px solid $blue;
                            cursor: pointer;
                            display: flex;
                            height: 16px;
                            justify-content: center;
                            margin-right: 10px;
                            width: 16px;
                        }
                        >span{
                            color: #081022;
                            font: normal normal 14px/21px 'Poppins', sans-serif;                
                        }                        
                    }
                }
            }
            .color_red{
                color: #E1554C !important;
                font: normal normal 14px/21px "Poppins", sans-serif;
                margin-bottom: 30px;
            }
            .btn-primary{
                background-color: $blue;
                border-radius: 6px;
                border: none;
                font: normal 500 18px/21px 'Poppins', sans-serif;    
                height: 46px;
                line-height: 27px;
                margin-top: 51px;
                max-width: 290px;
                text-align: center;
                width: 100%;
            }
            p{
                margin-bottom: 0;
                &.trial_link{
                    font: normal normal 14px/21px "Poppins", sans-serif;
                    margin-top: 30px;
                    a, button{
                        background: transparent;
                        border: none;
                        color: #2C6BFF;
                        outline: none;
                        padding: 0px;
                    }
                }
            }
        }
    }
    .right_container{
        background: $blue url("https://assets.codingdojo.com/learn_platform/user/login/login_bg.svg") no-repeat 429px 337px;
        flex: 1;
        padding: 71px 62px;
        position: relative;
        @media screen and (max-width: 1366px) {
            background-position: 364px 236px;
        }
        &:before{
            background: transparent url("https://assets.codingdojo.com/learn_platform/user/login/login_blks.png") no-repeat -1px -1px;
            content: "";
            height: 60px;
            left: -60px;
            position: absolute;
            top: 140px;
            width: 60px;
        }
        &:after{
            background: transparent url("https://assets.codingdojo.com/learn_platform/user/login/login_blks.png") no-repeat -71px -1px;
            bottom: 100px;
            content: "";
            height: 60px;
            left: -60px;
            position: absolute;
            width: 60px;
        }
        h1{
            color: #fff;
            font: 600 120px/1.2 'Poppins', sans-serif;
            width: 534px;
            @media screen and (max-width: 1366px) {
                font: 600 88px/1.2 'Poppins', sans-serif;
            }
        }
        #block_background_1{
            background: transparent url("https://assets.codingdojo.com/learn_platform/user/login/login_blks.png") no-repeat 267px -1px;
            width: 90px;
            height: 90px;
            position: fixed;
            top: 100px;
            right: 145px;
        }
        #block_background_2{
            background: transparent url("https://assets.codingdojo.com/learn_platform/user/login/login_blks.png") no-repeat 466px -1px;
            height: 189px;
            left: 538px;
            position: fixed;
            top: 863px;
            width: 189px;
        }
        #block_background_3{
            background: transparent url("https://assets.codingdojo.com/learn_platform/user/login/login_blks.png") no-repeat 167px -1px;
            width: 189px;
            height: 189px;
            position: fixed;
            top: 892px;
            right: -21px;
        }
    }
    @media screen and (max-width: 1323px) {
        .right_container {
            #block_background_1, #block_background_3{
                position: absolute !important;
                right: 0 !important;
            }
        }
    }

    @media screen and (max-width: 1202px) {
        .right_container{
            background-position: 131px 274px;
            h1{
                font-size: 76px;
                width: 451px;
            }
        }
    }
    @media screen and (max-width: 1038px) {
        .left_container{
            padding: 48px 87px 140px 60px;
        }
    }

    @media screen and (max-width: 935px) {
        .right_container{
            background-position: 30px 274px;
            h1{
                font-size: 60px;
                width: 317px;
            }
        }
        .left_container{
            padding: 48px 70px 140px 40px;
        }
    }
    @media screen and (max-width: 768px) {
        background: #fff;
        .right_container{
            background-color: transparent;
            background-position: 0px 9px;
            background-size: 303px;
            height: 308px;
            left: 0;
            margin: auto;
            padding: 0px;
            position: absolute;
            right: 0;
            top: 70px;
            width: 320px;

            &::before, &::after{
                display: none;
            }
            *{
                display: none;

            }
        }
        .left_container{
            margin: 0px auto;
            max-width: 358px;
            padding: 48px 20px;
            position: relative;
            width: 100%;
            form{
                padding-bottom: 30px;
                .btn-primary{
                    margin-top: 32px;
                    width: 100%;
                }
                .form-group{
                    margin-bottom: 16px;
                    #show_forgot_password{
                        margin-top: 16px;
                    }
                }

            }
            h5{
                font-size: 28px;
                margin: 351px 0px 16px;
            }
            img{
                left: 17px;
                position: absolute;
                top: 25px;
                width: 160px;
            }
        }
    }
}