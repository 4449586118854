#admin_student_progress_graduating_student_table{
    width: 100%;
    .sticky-table{
        height: calc(100% - 32px);
        .sticky-table-row:nth-child(1){
            .sticky-table-cell:nth-child(2){
                width: unset;
                color: #F6F9FD;
            }
        }
        .sticky-table-table{
            width: 100%;
            .sticky-table-row{
                /* Table Head */ 
                &:nth-child(1){
                    .sticky-table-cell{
                        background: linear-gradient(0deg, rgba(255, 255, 255, 0.24), rgba(255, 255, 255, 0.24)), #121212;
                    }
                }
                .sticky-table-cell{
                    background: #333333;
                    text-align: left;
                }
            }
        }
    }
}