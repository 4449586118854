$Poppins: 'Poppins', sans-serif;
$blue: #81A0EA;
$white: rgba(255, 255, 255, 0.88);

#student_profile_modal{
    font-family: $Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    max-width: 820px;
    width: 820px;
    ul{
        margin: 0px;
        padding-left: 0px;
        li{
            list-style: none;
        }
    }
    .modal-content{
        background: #333333;
        border-radius: 4px;
        .modal-body{
            background: linear-gradient(0deg, rgba(255, 255, 255, 0.14), rgba(255, 255, 255, 0.14)), #121212;
            border-radius: 4px;
            color: #fff;
            display: flex;
            padding: 25px;
            .hidden{
                display: none !important;
            }
            .backdrop{
                background: rgba(0, 0, 0, 0.6);
                display: none;
                height: 100%;
                position: absolute;
                right: 0px;
                top: 0px;
                width: 100%;
            }
            #confirm_delete_stack_container{
                background: linear-gradient(0deg, rgba(255, 255, 255, 0.24), rgba(255, 255, 255, 0.24)), #121212;
                border-radius: 2px;
                border: 1px solid #F56B6B;
                display: none;
                margin-left: 9px;
                padding: 19px;
                position: absolute;
                right: 25px;
                text-align: center;
                width: 329px;
                z-index: 2;
                p{
                    margin-bottom: 19px;
                }
                .buttons_container{
                    margin-top: 0px;
                    text-align: center;
                    button{
                        border-radius: 2px;
                        font-weight: 500;
                        padding: 7px 15px;
                        &#delete_stack_confirmation_btn{
                            background: rgba(204, 224, 255, 0.14);
                            color: #F56B6B;
    
                        }
                       
                    }
                }
            }

            &.show_delete_stack{
                #confirm_delete_stack_container{
                    display: block;
                }
                .backdrop{
                    display: block;
                }
                form{
                    div.block{
                        &.active{
                            .delete_stack_schedule_btn{
                                border: 1px solid #F56B6B;
                                display: block;
                                z-index: 2;
                                
                            }
                        }
                    }
                }
            }
            &.active_dropdown{
                position: relative;
                .backdrop{
                    display: block;
                }
                .block{
                    .right_container{
                        button{
                            &:nth-child(2){
                                border: 1px solid #81a0ea;
                                padding: 4px 6px;
                                position: relative;
                                z-index: 2;
                            }
                        }
                        ul{
                            display: block;
                            z-index: 2;
                        }
                    }
                }
            }
            button{
                background: transparent;
                border: none;
                color: #fff;
                outline: none;
            }
            .left_content{
                margin-right: 54px;
            }
            .block{
                align-items: flex-end;
                display: flex;
                flex-wrap: wrap;
                margin-bottom: 20px;
                width: 358px;
                &#info_block{
                    justify-content: space-between;
                    margin-bottom: 0px;
                }
                .right_container{
                    position: relative;
                    button, .button{
                        align-items: center;
                        background: rgba(204, 224, 255, 0.14);
                        border-radius: 2px;
                        border: none;
                        color: #81A0EA;
                        display: inline-flex;
                        font-size: 12px;
                        font-weight: 500;
                        height: 28px;
                        padding: 4px 8px;
                        text-decoration: none;
                        &:first-child{     
                            margin-right: 10px;
                            svg{
                                margin-right: 7px;
                            }
                        }
                    }
                    ul{
                        background: linear-gradient( 0deg, rgba(255, 255, 255, 0.24), rgba(255, 255, 255, 0.24)), #121212;
                        border: 1px solid #81A0EA;
                        color: #fff;
                        display: none;
                        padding: 20px 16px;
                        position: absolute;
                        right: 0px;
                        top: 37px;
                        width: 185px;
                        li{
                            margin-bottom: 20px;
                            &:last-child{
                                margin-bottom: 0px;
                            }
                            a{
                                color: rgba(255, 255, 255, 0.88);
                                text-decoration: none;
                                &:hover{
                                    opacity: .8;
                                }
                                span{
                                    background-image: url("./../../../../assets/images/admin/rostering/student_profile_icons.png");
                                    display: inline-block;
                                    height: 16px;
                                    margin-right: 8px;
                                    vertical-align: middle;
                                    width: 16px;
                                    &.eye{
                                        background-position: -126px 0px;
                                    }
                                    &.enroll_app{
                                        background-position: -152px 0px;
                                    }
                                    &.transcript{
                                        background-position: -178px 0px;
                                    }
                                    &.progress_report{
                                        background-position: -204px 0px;
                                    }
                                    &.warning{
                                        background-position: -229px 0px;
                                    }
                                }
                            }
                        }
                    }
                }
                .left_container{
                    margin-right: 15px;
                    padding-left: 46px;
                    position: relative;
                    h6{
                        font-size: 14px;
                        font-weight: 500;
                        margin-bottom: 4px;
                        .is_veteran{
                            background-color: rgba(245, 157, 107, 0.4);
                            background-image: url("./../../../../assets/images/admin/rostering/matching_gender_icon.png");
                            background-position: -62px -4px;
                            background-repeat: no-repeat;
                            border-radius: 4px;
                            display: inline-block;
                            height: 16px;
                            margin-left: 1px;
                            vertical-align: middle;
                            width: 16px;
                        }
                    }
                    img{
                        border-radius: 4px;
                        border: 2px solid rgba(204, 224, 255, 0.14);
                        height: 36px;
                        left: 0px;
                        position: absolute;
                        top: 0px;
                        width: 36px;
                    }
                    p{
                        color: rgba(255, 255, 255, 0.58);
                        margin-bottom: 0px;
                    }
                }
            }
            #belt_list{
                border-bottom: 1px solid rgba(204, 224, 255, 0.14);
                margin-bottom: 23px;
                padding-bottom: 17px;

                li{
                    margin-right: 40px;
                    text-align: center;
                    &:last-child{
                        margin-right: 0px;
                    }
                    span{
                        color: rgba(255, 255, 255, 0.58);
                        display: block;
                        &.belt{
                            background-image: url("https://assets.codingdojo.com/learn_platform/global/belts.png");
                            background-repeat: no-repeat;
                            display: inline-block;
                            height: 46px;
                            width: 61px;
                            &.webfun_belt{
                                background-position: -369px 0px;
                                &.yellow_belt{
                                    background-position: 0 0px;
                                }
                            }
                            &.mean_belt{
                                background-position: -369px -70px;
                                &.black_belt{
                                    background-position: -246px -70px;
                                }
                                &.red_belt{
                                    background-position: -123px -70px;
                                }
                                &.orange_belt{
                                    background-position: 0px -70px;
                                }
                            }
                            &.rails_belt{
                                background-position: -369px -140px;
                                &.black_belt{
                                    background-position: -246px -140px;
                                }
                                &.red_belt{
                                    background-position: -123px -140px;
                                }
                                &.orange_belt{
                                    background-position: 0px -140px;
                                }
                            }
                            &.python_belt{
                                background-position: -369px -210px;
                                &.black_belt{
                                    background-position: -246px -210px;
                                }
                                &.red_belt{
                                    background-position: -123px -210px;
                                }
                                &.orange_belt{
                                    background-position: 0px -210px;
                                }
                            }
                            &.c_net_belt{
                                background-position: -369px -280px;
                                &.black_belt{
                                    background-position: -246px -280px;
                                }
                                &.red_belt{
                                    background-position: -123px -280px;
                                }
                                &.orange_belt{
                                    background-position: 0px -280px;
                                }
                            }
                            &.java_belt{
                                background-position: -369px -350px;
                                &.black_belt{
                                    background-position: -246px -350px;
                                }
                                &.red_belt{
                                    background-position: -123px -350px;
                                }
                                &.orange_belt{
                                    background-position: 0px -350px;
                                }
                            }
                            &.mern_belt{
                                background-position: -369px -420px;
                                &.black_belt{
                                    background-position: -246px -420px;
                                }
                                &.red_belt{
                                    background-position: -123px -420px;
                                }
                                &.orange_belt{
                                    background-position: 0px -420px;
                                }
                            }
                            &.lamp_belt{
                                background-position: -369px -490px;
                                &.black_belt{
                                    background-position: -246px -490px;
                                }
                                &.red_belt{
                                    background-position: -123px -490px;
                                }
                                &.orange_belt{
                                    background-position: 0px -490px;
                                }
                            }
                            &.ios_belt{
                                background-position: -369px -560px;
                                &.black_belt{
                                    background-position: -246px -560px;
                                }
                                &.red_belt{
                                    background-position: -123px -560px;
                                }
                                &.orange_belt{
                                    background-position: 0px -560px;
                                }
                            }
                        }
                    }
                }
            }
            #rating_list{
                margin-bottom: 0px;
                li{
                    margin: 0px 6px 0px 0px ;
                    h6{
                        align-items: center;
                        color: rgba(255, 255, 255, 0.58);
                        display: flex;
                        font-size: 12px;
                        font-weight: 400;
                        span{
                            background-image: url("./../../../../assets/images/admin/rostering/student_profile_icons.png");
                            display: flex;
                            height: 16px;
                            margin-right: 4px;
                            width: 16px;
                            &.pen{
                                background-position: -75px 0px;
                            }
                            &.calendar{
                                background-position: -99px 0px;
                            }
                        }
                    }
                    h5{
                        font-size: 14px;
                        font-weight: 500;
                        padding-left: 21px;
                    }
                    .green_bg{
                        color: #05DA80; 
                    }
                    .yellow_bg{
                        color: #FFC656;
                    }
                    .red_bg{
                        color: #F56B6B;
                    }
                    .blue_bg{
                        color: #81A0EA;
                    }
                    .gray_bg{
                        color: rgba(255, 255, 255, 0.58);
                    }
                }
            }
            #location_region_list{
                align-items: flex-start;
                margin-bottom: 14px;
                li{
                    width: 106px;
                    margin-right: 20px;
                    &:last-child{
                        margin-right: 0px;
                    }
                    h6{
                        color: rgba(255, 255, 255, 0.58);
                        font-size: 12px;
                        font-weight: 400;
                        margin-bottom: 5px;
                        span{
                            background-image: url("./../../../../assets/images/admin/rostering/student_profile_icons.png");
                            display: inline-block;
                            height: 16px;
                            margin-right: 4px;
                            vertical-align: top;
                            width: 16px;
                            &.program{
                                background-position: 1px -1px;
                            }
                            &.location{
                                background-position: -25px 0px;
                            }
                            &.region{
                                background-position: -49px 0px;
                            }

                        }
                    }
                    h5{
                        font-size: 14px;
                        font-weight: 500;
                        padding-left: 20px;
                    }
                }
            }
            #show_stack_schedule_btn{
                border: none;
                color: rgba(255, 255, 255, 0.88);
                display: block;
                margin: 0px auto;
                svg{
                    color: #81A0EA;
                    font-size: 20px;
                    margin-left: 3px;
                    vertical-align: middle;
                }
            }
            form{
                width: 100%;
                h5{
                    font-size: 12px;
                    font-weight: 400;
                    margin-bottom: 22px;
                    margin-top: 7px;
                    text-align: center;
                }
                >div.block{
                    &:nth-last-child(2){
                        margin-bottom: 0px;
                    }
                }
                
                div.block{
                    display: block;
                    position: relative;
                    &.disabled_stack_schedule{
                        .react-dropdown-select-content{
                            opacity: .5;
                            width: 100%;
                            
                            svg{
                                display: none;
                            }
                        }
                        .react-dropdown-select-dropdown{
                            display: none !important;
                        }
                    }
                    &:hover{
                        .delete_stack_schedule_btn{
                            display: flex;
                        }
                    }
                    h6{
                        color: rgba(255, 255, 255, 0.58);
                        display: flex;
                        font-size: 12px;
                        font-style: italic;
                    }
                    .delete_stack_schedule_btn{
                        align-items: center;
                        background: rgba(204, 224, 255, 0.14);
                        border-radius: 2px;
                        display: none;
                        height: 18px;
                        justify-content: center;
                        line-height: 12px;
                        padding: 0px;
                        position: absolute;
                        right: 0px;
                        top: -1px;
                        width: 18px;
                        svg{
                            font-size: 10px;
                            color: #F56B6B;
                            pointer-events: none;
                        }
                    }
                    
                }
                p{
                    color: #F56B6B;
                    font-weight: 500;
                    line-height: 15px;
                    margin-top: -5px;
                }
                #add_a_new_stack_btn{
                    background: rgba(255, 255, 255, 0.18);
                    border-radius: 2px;
                    color: rgba(255, 255, 255, 0.58);
                    font-style: italic;
                    height: 40px;
                    margin: 30px 0px 0px;
                    width: 100%;
                    &.is_show_save_container{
                        margin: 10px 0px 0px;
                    }
                    svg{
                        font-size: 14px;
                        margin-right: 8px;
                    }
                }
                .buttons_container{
                    margin-bottom: 0px;
                    margin-top: 50px;
                    text-align: right;
                    button{
                        border-radius: 2px;
                        font-size: 14px;
                        font-weight: 500;
                        padding: 10px 16px;
                        &:last-child{
                            background: rgba(204, 224, 255, 0.14);
                            color: #81A0EA;
                        }
                        &:first-child{
                            margin-right: 26px;
                        }
                    }
                }
            }
            #assign_student_stack_container{
                margin: 0px auto;
                min-height: 320px;
                text-align: center;
                width: 562px;
                >button{
                    color: rgba(255, 255, 255, 0.88);
                    font-size: 24px;
                    left: 29px;
                    position: absolute;
                    top: 19px;
                }
                .form-group{
                    margin-bottom: 23px;

                    label{
                        color: rgba(255, 255, 255, 0.88);
                        font-family: $Poppins;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 130%;
                        margin-bottom: 21px;
                    }
                    select{
                        background: rgba(204, 224, 255, 0.14);
                        display: block;
                        height: 40px;
                        width: 100%;
                    }

                    #assign_stack_dropdown{
                        background: rgba(204, 224, 255, 0.14);
                        border-radius: 2px;
                        min-height: 40px;
                        
                        #assign_stack_dropdown_toggle{
                            align-items: center;
                            display: flex;
                            padding: 10px 16px;

                            button{
                                background: transparent;
                                border: none;
                                outline: none;

                                &:first-child{
                                    justify-content: flex-start;
                                    display: flex;
                                    flex: 1;
                                    color: $white;
                                    font-family: $Poppins;
                                    font-size: 14px;
                                    font-style: normal;
                                    font-weight: 500;
                                    line-height: 140%;
                                    text-align: left;
                                    width: 100%;

                                    span{
                                        color: #F56B6B;
                                        margin-left: 5px;
                                    }

                                    span.placeholder{
                                        color: $white;
                                        font-family: $Poppins;
                                        font-size: 14px;
                                        font-style: italic;
                                        font-weight: 300;
                                        line-height: 140%;
                                        opacity: 0.6;
                                    }
                                }

                                &:last-child{
                                    svg{
                                        color: $white;
                                    }
                                }
                            }

                            input{
                                background: rgba(204, 224, 255, 0.14);
                                border-radius: 4px;
                                border: none;
                                color: $white;
                                flex: 1;
                                font-family: $Poppins;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 500;
                                height: 32px;
                                line-height: 140%;
                                outline: none;
                                padding: 0px 16px;

                                &::placeholder {
                                    color: #A4B0C0;
                                    font-weight: normal;
                                }
                                  
                                &:-ms-input-placeholder {
                                    color: #A4B0C0;
                                    font-weight: normal;
                                }
                            
                                &::-ms-input-placeholder {
                                    color: #A4B0C0;
                                    font-weight: normal;
                                }
                            }

                            .fa-caret-down{
                                margin-left: 23px;
                                margin-right: 7px;
                                cursor: pointer;
                            }
                        }

                        ul{
                            border-top: 1px solid rgba(255, 255, 255, .1);
                            padding: 8px 0px;
                            overflow: auto;
                            max-height: 255px;

                            li{
                                button{
                                    background-color: transparent;
                                    border: none;
                                    color: $white;
                                    font-family: $Poppins;
                                    font-size: 14px;
                                    font-style: normal;
                                    font-weight: 500;
                                    line-height: 140%;
                                    outline: none;
                                    padding: 8px 16px;
                                    text-align: left;
                                    width: 100%;

                                    span{
                                        color: #F56B6B;
                                        margin-left: 5px;
                                    }
                                }
                            }
                        }
                    }
                    p{
                        color: #F56B6B;
                        font-weight: 500;
                        line-height: 15px;
                        margin-top: 10px;
                    }
                }

                .btn_container{
                    align-items: center;
                    display: flex;
                    justify-content: center;

                    button{
                        background-color: transparent;
                        border: none;
                        color: $white;
                        font-family: $Poppins;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                        height: 40px;
                        line-height: 130%;
                        min-width: 90px;
                        outline: none;

                        &#add_new_stack_confirm_btn{
                            background: rgba(204, 224, 255, 0.14);
                            border-radius: 2px;
                            color: $blue;
                            margin-left: 20px;   
                        }
                    }
                }
            }
        }
    }
}

#stack_schedule_tooltip{
    z-index: 9999;

    .arrow{
        &:before{
            border-top-color: #121212 !important;
        }
    }

    .tooltip-inner{
        background-color: #121212;
        line-height: 22px;
        max-width: 358px;
        padding: 10px;
    }
}