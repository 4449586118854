#faq_modal{
    max-width: 1280px;
    width: 1280px;
    .modal-content{
        border: none;
        height: 100%;
        .modal-header{
            border: none;
            padding: 49px 40px 35px 40px;
            position: relative;
            button{
                background-color: transparent;
                border: none;
                outline: none;
                padding: 0px;
                position: absolute;
                right: 26px;
                top: 26px;
            }
        }
        .modal-body{
            overflow: auto;
            padding: 63px 120px 60px 120px;
            #faq_question_container{
                border-bottom: 2px solid rgba(0, 0, 0, 0.2);
                margin-bottom: 61px;
                padding-bottom: 21px;
                .question_head{
                    align-items: center;
                    display: flex;
                    margin-bottom: 17px;
                    .question_title{
                        color: #000000;
                        flex: 1;
                        font-family: 'Poppins', sans-serif;
                        font-size: 22px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 33px;    
                        text-align: left;
                    }
                    .creator_container{
                        align-items: center;
                        display: flex;
                        
                        img{
                            border-radius: 100%;
                            height: 42px;
                            width: 42px;
                        }

                        .details{
                            display: flex;
                            flex-direction: column;
                            margin-left: 20px;
                            .name{
                                margin-bottom: 6px;
                            }
                            .position, .name{
                                color: #000000;
                                font-family: 'Poppins', sans-serif;
                                font-size: 12px;
                                font-style: normal;
                                font-weight: 200;
                                line-height: 18px;
                            }
                        }
                    }
                }
                p{
                    color: #000000;
                    font-family: 'Poppins', sans-serif;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: normal;
                    line-height: 24px;
                    overflow: hidden;
                }
            }
            #faq_answer_container{
                h5{
                    color: #000000;
                    font-family: 'Poppins', sans-serif;
                    font-size: 22px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 33px;
                    margin-bottom: 31px;
                }
                p{
                    color: #000000;
                    font-family: 'Poppins', sans-serif;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: normal;
                    line-height: 24px;
                }
            }
        }
    }
}