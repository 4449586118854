$black: #000;
$blue: #2C6BFF;
$border_gray: #F5F5F5;
$dark_blue: #152C61;
$gray: #707070;
$none: transparent;
$orange: #EF7E1B;
$red: #E1554C;
$Poppins: "Poppins", sans-serif;
$white: #fff;

body{
    &.is_show_backdrop_notification{
        &:after{
            background: gray;
            content: "";
            height: 100vh;
            opacity: .5;
            position: fixed;
            right: 0px;
            top: 0px;
            width: 100vw;
            z-index: 30;
        }
    }
    &.is_dark_mode{
        &.is_show_backdrop_notification{
            &:after{
                background-color: #212121;
                opacity: 0.8;
                z-index: 30;
            }
        }
    }
}
#notification_popover{
    -moz-filter: drop-shadow(0px 2px 10px rgba(0, 0, 0, 0.1));
    -webkit-filter: drop-shadow(0px 2px 10px rgba(0, 0, 0, 0.1));
    border-radius: 6px;
    border: none;
    filter: drop-shadow(0px 2px 10px rgba(0, 0, 0, 0.1));
    max-width: 381px;
    min-width: 381px;
    padding-top: 0px;
    will-change: filter;
    top: 8px !important;
    .arrow{
        &:before{
            border-bottom-color: transparent;
        }
    }
    #sub_notification_content,
    #notification_content{
        background: #ffffff;
        border-radius: 6px;
        font-family: "Poppins", sans-serif;
        font-size: 14px;
        max-height: 520px;
        overflow-x: hidden;
        overflow-y: auto;
        padding: 0px 32px 12px;
        z-index: 99;
        #notification_header{
            background: #ffffff;
            border-bottom: 1px solid rgba(151, 151, 151, 0.3);
            padding: 26px 0px 1px;
            position: sticky;
            top: 0px;
            z-index: 2;
            h2{
                color: #152C61;
                font-size: 16px;
            }
        }
        .dropdown{
            border: none;
            bottom: 8px;
            position: absolute;
            right: 0px;
            &.show{
                #notification_dropdown{
                    background: #C2D4FF;
                    color: #2C6BFF;
                    &:hover{
                        background: #C2D4FF;
                    }
                }
            }
            button{
                background: transparent;
                border: none;
                box-shadow: none;
                color: #5E6871;
                outline: none;
                padding: 0px;

                &:after{
                    display: none;
                }
            }
            #notification_dropdown{
                border-radius: 2px;
                color: #81A0EA;
                height: 24px;
                width: 24px;
                &:hover{
                    background: #F0F0F0;
                }
            }
            .dropdown-menu.show{
                border-radius: 6px;
                border: 1px solid #DCE6FF;
                box-shadow: 0px 10px 8px rgba(0, 0, 0, 0.05);
                box-sizing: border-box;
                display: flex;
                flex-direction: column;
                min-width: 132px;
                button, .dropdown-item{
                    color: #152C61;
                    font: 12px/18px $Poppins;
                    padding: 10px 14px;
                    text-align: left;
                    &:hover{
                        color: #152C61;
                        font-weight: bold;
                    }
                    &:active{
                        color: #A3A3A3;
                        font-weight: normal;
                    }
                    &:disabled{
                        color: #A3A3A3;
                        font-weight: normal;
                        &:hover{
                            color: #A3A3A3;
                            font-weight: normal;
                        }
                    }
                    &:hover{
                        background: none;
                    }
                }
            }
        }
        #notification_tab{
            margin: 11px 0 19px;
            button{
                background: none;
                border: none;
                color: rgba(0, 0, 0, .3);
                cursor: pointer;
                font: 400 12px/18px $Poppins;
                margin-right: 13px;
                outline: none;
                padding: 0 0 12px 0;
                position: relative;
                width: fit-content;
                &.active{
                    color: #152C61;
                    font: 600 12px/18px $Poppins;
                    &::before{
                        background: #2C6BFF;
                        border-radius: 10px;
                        bottom: 0;
                        content: "";
                        height: 2px;
                        left: 0;
                        position: absolute;
                        width: 100%;
                    }
                }
            }
        }
        #empty_box_icon{
            background-image: url("https://assets.codingdojo.com/learn_platform/global/modals/empty_box_icon.png");
            height: 60px;
            margin: 22px auto 3px;
            width: 53px;
        }
        p{
            color: rgba(21, 44, 97, 0.3);
            font-family: "Poppins", sans-serif;
            font-size: 12px;
            margin-bottom: 7px;
            text-align: center;
        }
        form{
            text-align: center;
            button{
                background: transparent;
                border-radius: 6px;
                border: none;
                color: #8A8A8A;
                height: 33px;
                outline: none !important;
                width: 203px;
                svg{
                    color: #8A8A8A;
                    font-size: 21px;
                    margin-left: 8px;
                    vertical-align: top;
                }
            }
        }
        #mark_all_as_read_form{
            font-weight: 500;
            padding: 44px 0px 17px;
            button{
                background: #FAFBFF;
                color: #2C6BFF;
            }
        }
        .block{
            padding: 0px 0px 10px;
            h2{
                color: #152C61;
                font: 600 12px/18px $Poppins;
            }
            ul{
                list-style: none;
                margin-bottom: 0px;
                padding: 0px;
                li{
                    border-bottom: 1px solid #dee5ef;
                    margin: 10px 0px 2px;
                    padding-bottom: 11px;
                    position: relative;

                    > div {
                        >a, .question_text{
                            -webkit-box-orient: vertical;
                            -webkit-line-clamp: 2;
                            color: #152C61;
                            display: -webkit-box;
                            font-size: 12px;
                            font-weight: 400;
                            line-height: 18px;
                            overflow: hidden;
                            padding-right: 28px;
                            text-decoration: none;
                            text-overflow: ellipsis;
                            white-space: initial;
                            &:hover{
                                .cd_blue:not(.name){
                                    text-decoration: underline;
                                }
                            }
                        }
                    }

                    .question_text{
                        color: #152C61;
                        margin-bottom: 5px;
                        padding-right: 27px;
                        text-align: left;
                        &:hover{
                            -webkit-box-orient: unset; 
                            -webkit-line-clamp: unset; 
                            display: -webkit-box;
                            overflow: unset; 
                            text-decoration: unset; 
                            text-overflow: unset; 
                            white-space: unset;
                        }
                        a, button{
                            color: #2C6BFF;
                            &:hover{
                                text-decoration: underline;
                            }
                        }
                        button{
                            background: none;
                            border: none;
                            margin: 0px;
                            outline: none;
                            padding: 0px;
                        }
                        span{
                            font-weight: 600;
                        }
                    }
                    .response_text{
                        color: #E1554C;
                        font: 10px/20px $Poppins;
                        text-align: left;
                        ~ .delete_notification_form{
                            top: 30px;
                        }
                    }
                    .reminder_text{
                        align-items: center;
                        display: flex;
                        flex-direction: row;
                        font: 12px/18px $Poppins;
                        margin-top: 8px;
                        p{
                            color: #152C61;
                            margin: 0 0 0 25px;
                            position: relative;
                            &::before{
                                background: #5E6871;
                                border-radius: 50%;
                                content: "";
                                height: 5px;
                                left: -15px;
                                position: absolute;
                                top: 50%;
                                transform: translateY(-50%);
                                width: 5px;
                            }
                        }
                        a{
                            color: #2c6bff;
                        }
                    }
                    .dropdown{
                        position: initial;  
                        z-index: 1;

                        .dropdown-menu.show{
                            left: 24px !important;
                        }
                    }
                    &.unread_notification{
                        padding-left: 0px;

                        .question_text{
                            position: relative;
                            &::before{
                                background: #E1554C;
                                border-radius: 100%;
                                content: "";
                                display: block;
                                height: 10px;
                                position: absolute;
                                right: 10px;
                                top: 3px;
                                width: 10px;
                            }
                        }
         
                        &:hover{
                            .question_text::before{
                                display: none;
                            }
                        }
                        &.mandatory_survey{
                            &:hover{
                                &::before{
                                    display: block !important;
                                }
                            }
                            .delete_notification_form{
                                display: none;
                            }
                        }
                        a{
                            &:hover{
                                background-position-x: 637px;
                            }
                        }
                    }
                    &:hover{
                        .delete_notification_form{
                            display: block;
                        }
                    }
                    .delete_notification_form{
                        display: none;
                        margin: 0px;
                        position: absolute;
                        right: 10px;
                        top: 3px;
                        width: unset;
                        button{
                            background: #8A8A8A;
                            display: block;
                            height: 10px;
                            margin: 0px;
                            padding: 1px;
                            width: 10px;
                            svg{
                                color: #fff;
                                font-size: 8px;
                                margin: 0px;
                            }
                        }
                    }
                    svg{
                        margin-right: 24px;
                    }
                    .date_text{
                        color: #A3A3A3;
                        font: 10px/15px $Poppins;
                    }
                    .cd_blue{
                        color: #2C6BFF;
                    }
                }
            }
        }
        #view_all{
            color: #2C6BFF;
            display: block;
            font: 500 14px/21px $Poppins;
            padding-bottom: 2px;
            text-align: center;
        }
    }
    .notification_loading{
        display: block;
        height: 50px;
        margin: 20px auto;
        width: 50px;
    }
}

.is_dark_mode{
    #notification_popover{
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.14), rgba(255, 255, 255, 0.14)), #121212;
        &[x-placement^=bottom]>.arrow::after, 
        &>.arrow::after{
            border-bottom-color: #333;
        }
        &[x-placement^=top]>.arrow::after, 
        &>.arrow::after{
            border-top-color: #333;
        }
        #sub_notification_content,
        #notification_content{
            background: linear-gradient(0deg, rgba(255, 255, 255, 0.14), rgba(255, 255, 255, 0.14)), #121212;
            #notification_header{
                background: linear-gradient(0deg, rgba(255, 255, 255, 0.14), rgba(255, 255, 255, 0.14)), #121212;
                border-bottom: 1px solid #666666;
                h2{
                    color: rgba(255, 255, 255, 0.88);
                }
            }
            .dropdown{
                &.show{
                    #notification_dropdown{
                        background: #C0D0F4;
                        color: #81A0EA;
                        &:hover{
                            background: #C0D0F4;
                            color: #81A0EA;
                        }
                    }
                }
                #notification_dropdown{
                    &:hover{
                        background: rgba(204, 224, 255, 0.14);
                    }
                }
                .dropdown-menu.show{
                    background: #333333;
                    border: 1px solid #DCE6FF;
                    button, .dropdown-item{
                        color: #ffffff;
                        &:active{
                            color: rgba(255, 255, 255, 0.48);
                        }
                        &:disabled{
                            color: rgba(255, 255, 255, 0.48);
                            &:hover{
                                color: rgba(255, 255, 255, 0.48);
                            }
                        }
                    }
                }
            }
            #notification_tab{
                button{
                    color: #b3b3b3;
                    opacity: .3;
                    &.active{
                        color: #e5e5e5;
                        opacity: 1;
    
                        &::before{
                            background: #81A0EA;
                            bottom: 0;
                            content: "";
                            height: 2px;
                            left: 0;
                            position: absolute;
                            width: 100%;
                        }
                    }
                }
            }
            #view_all{
                color: #81A0EA;
            }
            .block{
                &:last-of-type::after{
                    color: rgba(255, 255, 255, 0.58);
                }
                h2{
                    color: #e5e5e5;
                }
                ul{
                    li{
                        border-bottom: 1px solid #666;

                        .delete_notification_form{
                            button{
                                background: rgba(255, 255, 255, 0.48);
                                svg{
                                    color: #333333;
                                }
                            }
                        }
                        .date_text{
                            color: #B2B2B2;
                        }
                        .cd_blue{
                            color: #718CCD;
                        }
                        a{
                            color: #E5E5E5;
                        }
                        .question_text{
                            color: #E5E5E5;
                            a, button{
                                color: #718CCD;
                            }
                            button{
                                background: none;
                                border: none;
                                margin: 0px;
                                outline: none;
                                padding: 0px;
                            }
                        }
                        .response_text{
                            color: #f56b6b;
                        }
                        .reminder_text{
                            p{
                                color: #e5e5e5;
                                &::before{
                                    background: #666;
                                }
                            }
                            a{
                                color: #81a0ea;
                            }
                        }
                        &.unread_notification{
                            .question_text{
                                &::before{
                                    background: #F56B6B;
                                }
                            }
                        }             
                    }                
                }                
            }
            #empty_box_icon{
                background-position: -87px 0px;
            }
            p{
                color: #e2e2e2;
            }
        }
    }
}

@media only screen and (max-width: 768px){
    #notification_popover{
        max-width: 350px;
        min-width: 350px;
        #sub_notification_content,
        #notification_content{
            max-height: 300px;
            .dropdown{
                .dropdown-menu{
                    transform: translateX(calc(-100% + 25px));
                }
            }
            .block{
                ul{
                    li > div{
                        .dropdown{
                            .dropdown-menu.show{
                                transform: translate(80px, -10px) !important;
                            }
                        }
                    }
                }
            }
        }
    }

    .is_dark_mode{
        #sub_notification_content,
        #notification_content{
            background-color: #333;
            #notification_header{
                background-color: #333;
                h2{
                    color: rgba(255, 255, 255, 0.88);
                }
            }
            .dropdown{
                &.show{
                    #notification_dropdown{
                        background: #C0D0F4;
                        color: #81A0EA;
                        &:hover{
                            background: #C0D0F4;
                            color: #81A0EA;
                        }
                    }
                }
                #notification_dropdown{
                    &:hover{
                        background: rgba(204, 224, 255, 0.14);
                    }
                }
                .dropdown-menu.show{
                    background: #333333;
                    border: 1px solid #DCE6FF;
                    button, .dropdown-item{
                        color: #ffffff;
                        &:active{
                            color: rgba(255, 255, 255, 0.48);
                        }
                        &:disabled{
                            color: rgba(255, 255, 255, 0.48);
                            &:hover{
                                color: rgba(255, 255, 255, 0.48);
                            }
                        }
                    }
                }
            }
            #notification_tab{
                button{
                    color: #b3b3b3;
                    opacity: .3;
                    &.active{
                        color: #e5e5e5;
                        opacity: 1;
    
                        &::before{
                            background: #81A0EA;
                            bottom: 0;
                            content: "";
                            height: 2px;
                            left: 0;
                            position: absolute;
                            width: 100%;
                        }
                    }
                }
            }
            #view_all{
                color: #81A0EA !important;
            }
            .block{
                &:last-of-type::after{
                    color: rgba(255, 255, 255, 0.58);
                }
                h2{
                    color: #e5e5e5;
                }
                ul{
                    li{
                        border-bottom: 1px solid #666;

                        .delete_notification_form{
                            button{
                                background: rgba(255, 255, 255, 0.48);
                                svg{
                                    color: #333333;
                                }
                            }
                        }
                        .date_text{
                            color: rgba(255, 255, 255, 0.58);
                        }
                        .cd_blue{
                            color: #718CCD;
                        }
                        a{
                            color: #ffffff;
                        }
                        .question_text{
                            color: #ffffff;
                            a, button{
                                color: #718CCD;
                            }
                            button{
                                background: none;
                                border: none;
                                margin: 0px;
                                outline: none;
                                padding: 0px;
                            }
                        }
                        .response_text{
                            color: #f56b6b;
                        }
                        .reminder_text{
                            p{
                                color: #e5e5e5;
                                &::before{
                                    background: #666;
                                }
                            }
                            a{
                                color: #81a0ea;
                            }
                        }
                        &.unread_notification{
                            &::before{
                                background: #F56B6B;
                            }
                        }             
                    }                
                }                
            }
            #empty_box_icon{
                background-position: -87px 0px;
            }
            p{
                color: #e2e2e2;
            }
        }
    }
}
