.profile_nav_dropdown{
    .dropdown-menu{
        border-radius: 6px;
        border: none;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
        font: normal 16px/24px "Poppins", sans-serif;
        left: -186px;
        top: 60px;
        width: 239px;
        #basic_info_container{
            display: grid;
            grid-template-areas: 
            "image name"
            "button button";
            grid-template-columns: 30% 70%;
            padding: 10px 14px 14px;
            word-wrap: break-word;
            label{
                cursor: pointer;
                grid-area: image;
                height: 52px;
                margin-right: 13px;
                width: 52px;
                &.is_uploading, &:hover{
                    background-image: url("https://assets.codingdojo.com/learn_platform/global/profile_placeholder.png");                
                    background-repeat: no-repeat;
                    background-size: cover;
                    
                    img{
                        display: none;
                    }
                }
                &.is_uploading{
                    img{
                        &.loading{
                            display: block;
                            position: absolute;
                            right: 0;
                            top: 0;
                        }
                    }
                }
                &:hover{
                    svg{
                        color: #5E6871;
                        display: block;
                        font-size: 17px;
                        margin: 18px auto;
                    }
                }
                #upload_profile_pic_input, svg{
                    display: none;
                }
                img{
                    border-radius: 100%;
                    height: 100%;
                    object-fit: cover;
                    outline-offset: 3px;
                    outline: 1px solid rgb(196, 196, 196);
                    width: 100%;
                    &.loading{
                        display: none;
                    }
                }
            }
            .name_label{
                display: inline-block;
                grid-area: name;
                margin: 5px 0px 0px 2px;
                p{
                    color: #333;
                    font-size: 12px;
                    line-height: 20px;
                    margin: 0;
                    &.name{
                        font-size: 14px;
                        font-weight: 500;
                    }
                }
            }
            #manage_account_button{
                background-color: transparent;
                border-radius: 6px;
                border: 1px solid rgba(0, 0, 0, 0.2);
                color: #333;
                font-size: 12px;
                grid-area: button;
                margin-top: 10px;
                padding: 4px;
                text-align: center;
            }
        }
        button, a{
            background-color: transparent;
            border: none;
            color: #333;
            font-size: 12px;
            font-weight: 500;
            outline: none;
            padding: 0;
            width: 100%;
        }
        #profile_nav_footer{    
            padding: 12px 14px 8px; 
        }
        #profile_navigation_container{
            border-bottom: 1px solid rgba(21, 44, 97, .1);  
            border-top: 1px solid rgba(21, 44, 97, .1);  
            padding: 14px;
        }
        #profile_nav_footer, #profile_navigation_container{
            button, a{
                display: block;
                margin-bottom: 6px;
                text-decoration: none;
                &:hover{
                    opacity: 1;
                }
                &:last-child{
                    margin-bottom: 0px;
                }
            }

            .indicator{
                height: 12px;
                left: 54px;
                right: unset;
                top: 13px;
                width: 12px;
            }
            svg{
                margin-right: 31px;
                margin-top: 2px;
            }
        }
        &.show{
            button{
                margin-right: 0px;
                text-align: left;
                &:hover{
                    background: transparent;
                    opacity: .6;
                }
            }
            .dropdown-divider{
                margin: 26px 0;
            }
        }
    }
    .dot{
        background: #E1554C;
        border-radius: 50%;
        height: 12px;
        outline: 1.5px solid #fff;
        position: absolute;
        right: -4px;
        top: -2.5px;
        width: 12px;
    }
}
.is_dark_mode{
    .profile_nav_dropdown{
        .dropdown-menu{
            .name_label p:nth-child(1), button:not(#manage_account_button), a{
                color: rgba(255, 255, 255, 0.88) !important;
            }
            #basic_info_container{
                #close_right_nav_btn{
                    background: url("https://assets.codingdojo.com/learn_platform/user/dashboard/dashboard_redesign_icons_mobile.png") no-repeat -77px -10px;
                }
                .name_label p:nth-child(2){
                    color: #b3b3b3;
                }
                img{
                    outline-color: #333;
                }
                #manage_account_button{
                    border: 1px solid #81a0ea;
                    color: #81a0ea;
                }
            }
            #profile_navigation_container{
                border-bottom: 2px solid #333;
                border-top: 2px solid #333;
            }
        }
    }
}
@media only screen and (max-width: 768px){
    .profile_nav_dropdown{
        &.show::before{
            background-color: rgba(0, 0, 0, 0.7);
            bottom: 0;
            content: "";
            left: 0;
            position: fixed;
            right: 0;
            top: 0;
            z-index: 10;
        }
        .dropdown-menu{
            border-radius: 16px;
            left: 50%;
            position: fixed;
            top: 89px;
            transform: translateX(-50%);
            width: 314px;
            svg{
                display: none;
            }
            label{
                &:hover{
                    svg{
                        font-size: 19px;
                        margin: 5px auto;
                    }
                }
            }
            .navbar-text{
                &.location{
                    margin-bottom: 0px;
                }
            }
            #basic_info_container{
                grid-template-areas: 
                "image name name name"
                "button button button button";
                grid-template-columns: 25% 25% 25% 25%;
                padding: 60px 19px 18px;
                label{
                    height: 52px;
                    margin-left: 5px;
                    width: 52px;
                }
                #close_right_nav_btn{
                    background: url("https://assets.codingdojo.com/learn_platform/user/dashboard/dashboard_redesign_icons_mobile.png") no-repeat -53px -10px;
                    height: 14px;
                    left: 25px;
                    position: absolute;
                    top: 25px;
                    width: 14px;
                }
                #manage_account_button{
                    font: 600 14px "Poppins", sans-serif;
                    margin-top: 14px;
                    padding-block: 9px;
                }
            }
            .navbar-text, .navbar-text.light{
                color: #4561A1;
                font: normal 12px/18px "Poppins", sans-serif;
                padding: 5px 0px;
                &.name {
                    color: #152C61;
                    font: 500 18px/27px "Poppins", sans-serif;
                    margin-bottom: 9px;
                    padding-left: 45px;
                }
            }
            #profile_navigation_container{
                border-bottom: none;
                margin: 0px 20px 0px;
                padding: 20px 0px 0px;
                position: relative;
            }
            
            #profile_navigation_container, #profile_nav_footer{
                button, a{
                    font: 600 normal 14px/18px "Poppins", sans-serif;
                    margin-bottom: 20px;
                    svg{
                        display: none;
                    }
                }
            }

            #profile_nav_footer{
                padding: 20px 20px 26px;
            }
        }
    }
    .is_dark_mode{
        .profile_nav_dropdown{
            .dropdown-menu{
                color: rgba(255, 255, 255, 0.88);
                #basic_info_container{
                    #manage_account_button{
                        border: 1px solid #595959;
                    }
                }
                #profile_navigation_container{
                    border-bottom: none !important;
                    border-top: 1px solid #595959;
                }
                button, a{
                    color: rgba(255, 255, 255, 0.88) !important;
                }
                .navbar-text, .navbar-text.light{
                    color: rgba(255, 255, 255, 0.7);
                }
                .navbar-text.name, .navbar-text.light.name{
                    color: rgba(255, 255, 255, 0.88);
                }
            }
        }
        .dot{
            background: #E87E77;
            outline: 1.5px solid #121212;
        }
    }
}