$Poppins: "Poppins", sans-serif;

#track_history_tooltip{
    .tooltip-inner{
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.24), rgba(255, 255, 255, 0.24)), rgba(6, 6, 6, 0.94);
        border-radius: 4px;
        border: 1px solid rgba(204, 224, 255, 0.14);
        box-sizing: border-box;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        max-width: 429px;
        padding: 20px;

        h5{
            color: #E5E5E5;
            font-family: $Poppins;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            margin-bottom: 0px;
            max-width: 80%;
            text-align: left;
            
            span{
                margin-left: 10px;
            }
        }

        #belt_score{
            align-items: center;
            display: flex;
            font-family: $Poppins;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;

            &.passed{
                color: #05DA80;
            }

            &.failed{
                color: #F56B6B;
            }

            .belt_icon{
                background-image: url('./../../../../assets/images/admin/rostering/belt_icons.png');
                display: flex;
                height: 13px;
                margin-left: 7px;
                width: 12px;
                
                &.yellow{
                    background-position: -26px 0px;
                }
                &.red{
                    background-position: -15px 0px;
                }
                &.black{
                    background-position: -4px 0px;
                }
                &.orange{
                    background-position: -40px 0px;
                }
                &.white{
                    background-position: -52px 0px;
                }
            }
        }

        div:not(#belt_score){
            margin-top: 15px;
            text-align: left;
            width: 100%;
            span{
                font-family: $Poppins;
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                color: #E5E5E5;
                margin-right: 20px;
            }
        }
    }
}