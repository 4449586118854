$Poppins: "Poppins", sans-serif;

#admin_container.curriculum_management_course_container {
    .course_header_container{
        border-bottom: 1px solid #2A2A2A;
        padding-left: 260px;
        #search_by_keyword_form {
            display: flex;
            left: 30px;
            position: absolute;
            p {
                color: #B2B2B2;
                display: none;
                font: normal normal 12px/14px $Poppins;
                margin: 6px 0px 0px 10px;
                width: 320px;
            }
            svg {
                color: #73839C;
                font-size: 14px;
                left: 12px;
                margin-right: 10px;
                position: absolute;
                top: 12px;
                z-index: 2;
            }
            input {
                align-items: center;
                background: linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), #121212;
                border-radius: 4px;
                border: 1px solid transparent;
                color: rgba(255, 255, 255, 0.88);
                font: normal normal 12px/140% $Poppins;
                height: 40px;
                padding: 0px 12px 0px 36px;
                position: relative;
                width: 256px;
                &:focus {
                    background: #2A2A2A;
                    border: 1px solid #81A0EA;
                    box-shadow: 0px 0px 6px #81A0EA;
                    outline: none;
                }
                &:focus+p {
                    display: block;
                }
            }
        }
        #header_container{
            border-bottom: none;
        }
    }
    .curriculum_management_course_tools_container {
        align-items: center;
        border-bottom: 1px solid rgba(217, 217, 217, 0.45);
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
        padding-bottom: 6px;
        .create_course_btn {
            background: rgba(204, 224, 255, 0.14);
            border-radius: 2px;
            border: none;
            color: #81A0EA;
            font: normal 600 12px/15.6px $Poppins;
            margin-top: 5px;
            min-height: 32px;
            padding: 0;
            width: 143px;
        }
    }
    .checked_buttons_container {
        margin-bottom: 17px;
        button {
            background: #2B2E33;
            border-radius: 2px;
            border: none;
            color: #E5E5E5;
            line-height: 130%;
            margin-right: 10px;
            padding: 7px 12px 7px 40px;
            position: relative;
    
            &::before {
                background: url("../../../assets/images/admin/curriculum_management/admin_curriculum_management_sprite.png") no-repeat;
                content: "";
                height: 20px;
                left: 10px;
                position: absolute;
                top: 5px;
                width: 20px;
            }
            &:nth-child(1){
                &::before{
                    background-position: -361px -19px;
                }
            }
            &:nth-child(2){
                &::before{
                    background-position: -361px -19px;
                }
            }
            &:nth-child(3){
                &::before{
                    background-position: -84px -19px;
                }
            }
            &:nth-child(4){
                &::before{
                    background-position: -16px -19px;
                }
            }
            &:nth-child(5){
                &::before{
                    background-position: -330px -19px;
                }
            }
            &:disabled{
                opacity: 0.5;
            }
        }
    }
}