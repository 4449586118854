#forum_image_modal{
    max-width: 1200px;
    width: fit-content;

    .modal-content{
        .modal-header{
            border: none;
            padding: 0px;

            button{
                background-color: transparent;
                border: none;
                outline: none;
                padding: 0px;
                position: absolute;
                right: 0px;
                top: 3px;
                z-index: 9;

                .close_icon{
                    background-image: url("https://assets.codingdojo.com/learn_platform/global/close_icon_new.png");
                    display: inline-block;
                    height: 33px;
                    width: 33px;
                }
            }
        }

        .modal-body{
            padding: 30px;

            img{
                max-height: 600px;
                width: auto;
            }
        }
    }
}

.is_dark_mode{
    #forum_image_modal{
        .modal-content{
            .modal-header{
                border-bottom-color: #81A0EA;
                button{
                    .close_icon{
                        background-position: -61px 0px;
                    }
                }
            }
        }
    }
}