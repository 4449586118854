$Poppins: "Poppins", sans-serif;

#grading_modal{
    max-width: 400px;

    
    .modal-content{
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.24), rgba(255, 255, 255, 0.24)), #121212;
        border-radius: 0px;
        box-shadow: 0px 4px 2px rgba(0, 0, 0, 0.05);
        color: rgba(255, 255, 255, 0.88);
        font-family: $Poppins;
        .modal-header{
            background: linear-gradient(0deg, rgba(255, 255, 255, 0.14), rgba(255, 255, 255, 0.14)), #121212;
            border-radius: 0px;
            border: none;
            justify-content: left;
            padding: 18px 23px;
            position: relative;
    
            h4{
                font-family: 'Poppins', sans-serif;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: 16px;
                margin-bottom: 0px;
            }
    
            button{
                background-color: transparent;
                background-image: url("https://assets.codingdojo.com/learn_platform/admin/edit_course/admin_edit_course_sprite.png");
                background-position: -65px 0px;
                border: none;
                height: 15px;
                outline: none;
                padding: 0px;
                position: absolute;
                right: 22px;
                top: 17px;
                width: 15px;
            }
        }
        form{
            .modal-body{
                background: #292929;
                padding: 20px 24px;
                text-align: left;
                min-height: 700px;

                .loading_container{
                    align-items: center;
                    background: transparent;
                    display: flex;
                    flex-direction: column;
                    left: 50%;
                    margin-left: 0px;
                    position: absolute;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    width: 50px;
                    div{
                        background-image: url("https://assets.codingdojo.com/learn_platform/admin/global/table_loader.gif");
                        background-size: cover;
                        height: 30px;
                        margin-bottom: 7px;
                        mix-blend-mode: lighten;
                        width: 30px;
                    }
                    span{
                        color: rgba(255, 255, 255, 0.88);
                        font-family: $Poppins;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 130%;
                    }
                }
                span.download_loader{
                    background-image: url(https://assets.codingdojo.com/learn_platform/global/loading.svg);
                    background-size: cover;
                    display: inline-block;
                    height: 17px;
                    margin-right: 5px;
                    vertical-align: middle;
                    width: 17px;
                }
                .color_red{
                    color: #F56B6B;
                }
                .color_green{
                    color: #05DA80;
                }

                p{
                    font-family: $Poppins;
                    font-size: 12px;
                    font-weight: 400;
                }
                h6{
                    color: rgba(255, 255, 255, 0.58);
                    font-family: $Poppins;
                    font-size: 12px;
                    margin-bottom: 15px;
                }
                #exam_name_and_grade_container{
                    border-bottom: 1px solid #3F3F3F;
                    display: flex;
                    margin-bottom: 22px;
                    padding-bottom: 20px;
                }
                input[type="number"] {
                    -moz-appearance:textfield;
                }
                input::-webkit-outer-spin-button,
                input::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                }
                .navigation_container{
                    align-items: center;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    margin-bottom: 23px;
                    padding: 0px 40px;
                    position: relative;
                    h6{
                        color: rgba(255, 255, 255, 0.88);
                        margin-bottom: 4px;
                    }
                    button{
                        background: transparent;
                        border: none;
                        color: #E5E5E5;
                        outline: none;
                        position: absolute;
                        &.right_btn{
                            right: 25px;
                        }
                        &.left_btn{
                            left: 25px;
                        }
                    }
                    p{
                        margin-bottom: 0px;
                    }
                    p, h6{
                        text-align: center;
                        white-space: break-spaces;
                        width: 232px;
                        word-break: break-all;
                    }
                }
                .content{
                    flex-wrap: wrap;
                    &#feedback_container{
                        border-bottom: 1px solid #3F3F3F;
                        margin-bottom: 24px;
                        padding-bottom: 14px;
                        textarea{
                            background: #2E2E2E;
                            border-radius: 2px;
                            border: none;
                            color: rgba(255, 255, 255, 0.88);
                            font-family: "Poppins", sans-serif;
                            font-size: 12px;
                            font-weight: 400;
                            min-height: 130px;
                            padding: 10px;
                            resize: none;
                            width: 100%;
                            outline: none;
                        }
                    }
                    &#file_submission_container{
                        margin-bottom: 28px;
                        .inner_content{
                            align-items: center;
                            display: flex;
                            .upload_loading_animation{
                                align-items: center;
                                background: rgba(204, 224, 255, 0.14);
                                border-radius: 4px;
                                display: flex;
                                height: 10px;
                                margin-right: 46px;
                                margin-top: 27px;
                                width: 230px;
                                p{
                                    margin-top: -46px;
                                    position: absolute;
                                }
                                span{
                                    background: #81A0EA;
                                    border-radius: 4px;
                                    display: inline-block;
                                    height: 100%;
                                    transition: 1s;
                                    width: 0%;
                                }
                            }
                            p{
                                font-family: $Poppins;
                                margin-bottom: 0px;
                                margin-right: 16px;
                                padding-right: 34px;
                                position: relative;
                                width: 260px;
                                word-break: break-all;
                                #file_download{
                                    background-color: transparent;
                                    background-image: url("https://assets.codingdojo.com/learn_platform/admin/exam/admin_exam_icons_new.png");
                                    background-position: -139px -5px;
                                    border: none;
                                    display: inline-block;
                                    height: 14px;
                                    outline: none;
                                    position: absolute;
                                    right: 6px;
                                    top: 0px;
                                    width: 21px;
                                }
                            }
                            label{
                                color: #81A0EA;
                                cursor: pointer;
                                font-family: $Poppins;
                                font-size: 12px;
                                font-weight: 600;
                                margin-bottom: 0px;
                            }
                        }
                        #resubmit_file_input{
                            display: none;
                        }
                    }
                    &#url_submission_container{
                        h6{
                            margin-bottom: 20px;
                        }
                        p{
                            display: inline-block;
                            max-width: 252px;
                            vertical-align: top;
                            word-break: break-word;
                        }
                        #url_input{
                            background: #121212;
                            border-radius: 4px;
                            border: none;
                            color: #fff;
                            font-family: $Poppins;
                            font-size: 12px;
                            font-size: 12px;
                            font-weight: 500;
                            outline: none;
                            padding: 4px 10px;
                            width: auto;
                        }
                        button{
                            background: transparent;
                            border: none;
                            color: #81A0EA;
                            font-family: $Poppins;
                            font-size: 12px;
                            font-weight: 600;
                            margin-bottom: 0px;
                            outline: none;
                        }
                        #save_url_button{
                            margin-left: 10px;
                            vertical-align: middle;
                        }
                        #resubmit_url_button{
                            margin-left: 20px;
                            vertical-align: top;
                        }
                    }

                    .left_container{
                        margin-right: 54px;
                        max-width: 176px;
                        p{
                            margin-bottom: 0px;
                        }
                    }
                    
                    .right_container{
                        position: relative;
                        width: 85px;
                        input{
                            background: #333333;
                            border-radius: 4px;
                            border: none;
                            font-family: "Poppins", sans-serif;
                            font-size: 32px;
                            font-weight: 600;
                            height: 60px;
                            max-width: 85px;
                            min-width: 60px;
                            outline: none;
                            text-align: center;
                            &.show_not_applicable_text {
                                max-width: 64px;
                                 ~ .not_applicable_text{
                                    display: block;
                                }
                            }
                            &:focus{
                                background: #121212;
                                ~ .not_applicable_text{
                                    display: none;
                                }
                            }
                        }
                        .not_applicable_text{
                            display: none;
                            font-family: "Poppins", sans-serif;
                            font-size: 16px;
                            font-weight: 600;
                            left: 15px;
                            pointer-events: none;
                            position: absolute;
                            top: 46px;
                        }
                        .belt_icon{
                            background-image: url("https://assets.codingdojo.com/learn_platform/admin/exam/admin_exam_icons_new.png");
                            display: inline-flex;
                            height: 17px;
                            margin-left: 7px;
                            position: absolute;
                            top: 50px;
                            width: 14px;
                        
                            &.red_belt{
                                background-position: -173px -2px;
                            }
                            &.black_belt{
                                background-position: -200px -2px;
                            }
                            &.orange_belt{
                                background-position: -227px -2px;
                            }
                            &.yellow_belt{
                                background-position: -254px -2px;
                            }
                            &.white_belt{
                                background-position: -301px -2px;
                            }
                        }
                        .invalid_grade{
                            margin: 7px 0px 0px;
                            width: 127px;
                        }
                    }
                    
                }
                
            }
            .modal-footer{
                align-items: center;
                background: #333333;
                border: none;
                display: flex;
                height: 79px;
                justify-content: end;
                &.hidden{
                    display: none;
                }

                button{
                    background: transparent;
                    border: none;
                    color: #fff;
                    font-size: 14px;
                    outline: none;
                    &[type="submit"]{
                        background: rgba(204, 224, 255, 0.14);
                        border-radius: 2px;
                        color: #81A0EA;
                        margin-left: 30px;
                        padding: 8px 16px;
                        &:disabled{
                            cursor: none;
                            opacity: .5;
                        }
                    }
                }
            }
        }
    }
}