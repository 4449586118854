$Poppins: "Poppins", sans-serif;

.show_typeform_modal{
    #bootcamp_readiness_modal{
        height: calc(100% - 3.5rem);
        max-width: 1000px !important;
        width: 1000px !important;
        .modal-content{
            .modal-body{
                height: 100%;
                padding: 45px 55px;
                iframe{
                    height: 100%;
                }
            }
        }
    }
}

#bootcamp_readiness_modal{
    max-width: 600px;
    width: 600px;
    .modal-content{
        background: #FFFFFF;
        border-radius: 6px;
        box-shadow: 0px 4px 2px rgba(0, 0, 0, 0.05);
        height: 100%;
       
        .modal-body{
            padding: 39px 40px 14px 40px;
            text-align: center;

            img{
                margin-bottom: 23px;
            }

            h2{
                color: #152C61;
                font-family: $Poppins;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
                margin-bottom: 23px;
                text-align: center;
            }

            .btn-primary{
                color: #FFFFFF;
                font: normal 500 16px/24px 'Poppins', sans-serif;
                height: 46px;
                margin-bottom: 14px;
                text-align: center;
                width: 368px;
            }

            p{
                color: #152C61;
                font-family: $Poppins;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 30px;
                text-align: center;

                button{
                    background-color: transparent !important;
                    border: none;
                    color: #007bff;
                    outline: none;
                    padding: 0px;
                }
            }
        }

    }
}

.is_dark_mode{
    #bootcamp_readiness_modal{
        .modal-content{
            .modal-body{
                h2{
                    color: rgba(255, 255, 255, 0.88);
                }

                button{
                    background-color:#81A0EA;
                    border-color: #81A0EA;
                }
    
                p{
                    color: rgba(255, 255, 255, 0.88);
                    a, button{
                        color: #81A0EA;
                    }
                }
            }
        }
    }
}
@media only screen and (max-width: 768px){
    #bootcamp_readiness_modal{
        .modal-content{
            .modal-body{
                padding: 18px 20px 56px;
                .btn-primary{
                    max-width: 350px;
                    width: 100%;
                }
                iframe{
                    *{
                        width: 100% !important;
                    }
                }
            }
        }
    }
    .show_typeform_modal {
        #bootcamp_readiness_modal{
            height: 100% !important;
            max-width: 100% !important;
            min-height: 100vh !important;
            width: 100% !important;
            .modal-content{
                .modal-body{
                    padding: 0px;
                    iframe{
                        width: 100%;
                    }
                }
            }
        }
    }
}
