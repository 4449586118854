$blue: #81A0EA;
$Poppins: 'Poppins', sans-serif;
$white: #fff;

#admin_container{
    background-color: #121212;
    display: flex;
    height: 100vh;

    #admin_right_container{
        display: flex;
        flex-direction: column;
        flex: 1;
        overflow: hidden;
        padding: 12px 30px 20px 30px;
        position: relative;

        .release_notes_banner{
            background-color: transparent;
            border-radius: 5px;
            border: 1px solid #81A0EA;
            box-sizing: border-box;
            margin: 16px 0px 0px 0px;
            padding: 14px 20px;

            p{
                color: #fff;
                font-size: 12px;
                svg, b{
                    color: #81A0EA;
                }
            }
            button{
                align-items: center;
                background-color: #81A0EA;
                display: flex;
                font-size: 12px;
                font-weight: 500;
                height: 28px;
                justify-content: center;
                margin-right: 0px;
            }
        }

        #table_tools_container{
            align-items: flex-end;
            display: flex;
            justify-content: space-between;
            
            #other_tools_container{
                align-items: center;
                display: flex;
                margin-bottom: 14px;

                button, a{
                    align-items: center;
                    background: rgba(204, 224, 255, 0.14);
                    border-radius: 2px;
                    border: none;
                    display: flex;
                    height: 32px;
                    justify-content: center;
                    margin-left: 12px;
                    width: 38px;
                    
                    svg{
                        color: #81A0EA;
                    }
                }
            }
        }

        #program_filter_note{
            align-items: flex-end;
            color: #B3B3B3;
            display: flex;
            font-family: $Poppins;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 130%;
            margin-bottom: 10px;
        }
    }
}


#admin_tooltip{
    .arrow{
        &:before{
            border-top-color: #636363 !important;
        }
    }
    .tooltip-inner{
        background: #636363 !important;
        border-radius: 2px;
        border-radius: 4px;
        color: rgba(255, 255, 255, 0.88);
        font-family: 'Poppins', sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: normal;
        line-height: 18px;
        max-width: 240px;
        padding: 14px 10px;
    }
}


.admin_modal{
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    .modal-content{
        background: #2A2A2A;
        border-radius: 6px;
        box-shadow: 0px 4px 2px rgba(0, 0, 0, 0.05);
        color: rgba(255, 255, 255, 0.88);
        .modal-header{
            background: #333333;
            border: none;
            justify-content: end;
            padding: 17px 23px;
            position: relative;
            h4{
                font-family: 'Poppins', sans-serif;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 16px;
                margin-bottom: 0px;
            }
            button{
                background-color: transparent;
                background-image: url("https://codingdojo_assets.s3.us-east-1.amazonaws.com/learn_platform/admin/edit_course/admin_edit_course_sprite.png");
                background-position: -65px 0px;
                border: none;
                height: 15px;
                outline: none;
                padding: 0px;
                position: absolute;
                right: 22px;
                top: 17px;
                width: 15px;
            }
        }
        .modal-footer{
            background: #333333;
            border: none;
            padding: 16px 20px;
            button{
                background: #494B50;
                border-radius: 2px;
                border: none;
                color: #81A0EA;
                font-size: 14px;
                height: 40px;
                outline: none;
                width: 193px;
            }
        }
    }
}

.admin_notes_modal{
    #release_notes_modal{
        .modal-content{
            background: #333333;
            .modal-header{
                background: #415075;
            }
            .modal-body{
                *{
                    color: #FFFFFF !important;
                }
            }
        }
    }
}