$blue: #81A0EA;
$Poppins: "Poppins", sans-serif;
$white: rgba(255, 255, 255, 0.88); 

#belt_details_popover{
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.24), rgba(255, 255, 255, 0.24)), rgba(6, 6, 6, 0.94);
    border-radius: 4px;
    border: 1px solid rgba(204, 224, 255, 0.14);
    box-sizing: border-box;
    font-family: $Poppins;
    font-size: 12px;
    font-weight: 300;
    max-width: max-content;
    padding: 20px 20px 15px 20px;
    
    &.behind{
        z-index: 0;
    }
    .arrow{
        display: none; 
    }

    .popover-body{
        color: white;
        padding: 0;

        .title{
            font-weight: bold;
            margin-bottom: 3px;
        }

        .exam_name{
            margin-bottom: 14px;
        }

        .exam_results_list{
            width: 100%;
            
            thead{
                tr{
                    th{
                        padding-bottom: 3px;
                    }
                }
            }
            tbody{
                td{
                    padding-bottom: 4px;
                    &:last-child{
                        text-align: right;
                    }
                    &.exam_date{
                        text-align: center;
                    }
                }

                .exam_option,
                .exam_date{
                    font: normal 300 12px/130% $Poppins;
                }

                .exam_option{
                    padding: 0px 48px 0px 0px;
                }

                .exam_date{
                    padding: 0px 38px 0px 0px; 
                }

                .green_bg{
                    background: rgba(84, 204, 176, 0.4);
                    color: #05DA80;                                
                }

                .yellow_bg{
                    background: rgba(255, 198, 86, 0.4);
                    color: #FFC656;
                }

                .red_bg{
                    background: rgba(217, 75, 75, 0.4);
                    color: #F56B6B;
                }

                .blue_bg{
                    background: rgba(204, 224, 255, 0.14);
                    color: $blue;
                }

                .gray_bg{
                    background: rgba(255, 255, 255, 0.18);
                    color: #81A0EA;
                    font-weight: 400;
                }

                .belt_label{
                    font: normal 600 12px/130% $Poppins;
                    align-items: center;
                    border-radius: 4px;
                    display: inline-flex;
                    justify-content: center;      
                    padding: 1px 6px; 
                    .belt_icon{
                        background-image: url("https://assets.codingdojo.com/learn_platform/admin/rostering/belts_icon_new.png");
                        display: flex;
                        height: 12px;
                        margin-left: 3px;
                        width: 12px;

                        &.yellow{
                            background-position: 0px 0px;
                        }
                        &.red{
                            background-position: -12px 0px;
                        }
                        &.black{
                            background-position: -24px 0px;
                        }
                        &.orange{
                            background-position: -36px 0px;
                        }
                        &.white{
                            background-position: -48px 0px;
                        }

                    }
                }
            }
        }
    }
}