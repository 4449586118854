$Poppins: "Poppins", sans-serif;

#access_granted_container{
    text-align: center;

    .logo{
        background: url("https://assets.codingdojo.com/learn_platform/global/alumni_pass_sprite.png") no-repeat -20px -19px;
        height: 60px;
        left: 50px;
        position: absolute;
        top: 64px;
        width: 320px;
    }

    h4{
        color: #152C61;
        font: 700 32px/48px $Poppins;
    }

    p{
        color: #152C61;
        font: 500 18px/27px $Poppins;
    }

    a{
        align-items: center;
        background: #2C6BFF;
        border-radius: 6px;
        color: #fff;
        display: flex;
        font: 500 16px/24px $Poppins;
        height: 60px;
        justify-content: center;
        margin-top: 12px;
        margin: 0 auto;
        text-decoration: none;
        width: 420px;
    }
}

@media only screen and (max-width: 625px){
    #access_granted_container{
        .logo{
            left: 50%;
            transform: translateX(-50%) scale(0.8);
        }
        h4{
            color: #152C61;
            font: 700 28px/48px $Poppins;
        }
    
        p{
            color: #152C61;
            font: 500 14px/27px $Poppins;
        }
        a{
            height: 50px;
            width: 100%;
        }
    }
}

.is_dark_mode #access_granted_container{
    h4,
    p{
        color: #fff;
    }

    .logo{
        background: url("https://assets.codingdojo.com/learn_platform/global/alumni_pass_sprite.png") no-repeat -355px -19px;
    }

    a{
        background: #81A0EA;
    }
}