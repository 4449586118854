.show_typeform_modal{
    #survey_modal{
        height: 1080px;
        max-width: 1000px !important;
        width: 1000px !important;
        .modal-content{
            .modal-body{
                padding: 45px 55px;
                height: 100%;
                iframe{
                    height: 100%;
                }
            }
        }
    }
}
#survey_modal{
    max-width: 791px;
    width: 791px;
    .modal-content{
        background: #FFFFFF;
        border-radius: 6px;
        box-shadow: 0px 4px 2px rgba(0, 0, 0, 0.05);
        height: 100%;
        .modal-header{
            border: none;
            justify-content: center;
            padding: 41px 40px 23px 40px;
            position: relative;
            .modal-title{
                color: #152C61;
                font: normal 500 36px/54px 'Poppins', sans-serif;
                text-align: center;
            }
            button{
                background-color: transparent;
                border: none;
                outline: none;
                padding: 0px;
                position: absolute;
                right: 26px;
                top: 26px;
                .close_icon{
                    background-image: url("https://assets.codingdojo.com/learn_platform/global/close_icon_new.png");
                    display: inline-block;
                    height: 33px;
                    width: 33px;
                }
            }
        }
        .modal-body{
            text-align: center;
            p{
                color: #152C61;    
                font: normal normal 16px/24px 'Poppins', sans-serif;
                margin-bottom: 0px;            
                text-align: center;
            }
            img{
                margin-bottom: 42px;
            }
        }

        .modal-footer{
            border: none;
            justify-content: space-between;
            padding: 18px 112px 56px 112px;
            &.center{
                justify-content: center !important; 
            }
            #skip_survey_btn{
                background: transparent;
                border: none;
                color: #2C6BFF;          
                font: normal 500 18px/27px 'Poppins', sans-serif;
                height: 46px;
                outline: none;
                text-align: center;
                width: 210px;
            }

            .btn-primary{
                color: #FFFFFF;
                font: normal 500 18px/27px 'Poppins', sans-serif;
                height: 46px;
                text-align: center;
                width: 210px;
            }
        }
    }
}
@media only screen and (max-width: 768px){
    #survey_modal{
        .modal-content{
            .modal-footer{
                padding: 18px 20px 56px;
                .btn-primary{
                    max-width: 350px;
                    width: 100%;
                }
            }
            .modal-body{
                iframe{
                    *{
                        width: 100% !important;
                    }
                }
            }
        }
    }
    .show_typeform_modal {
        #survey_modal{
            height: 100% !important;
            max-width: 100% !important;
            min-height: 100vh !important;
            width: 100% !important;
            .modal-content{
                .modal-body{
                    padding: 0px;
                    iframe{
                        width: 100%;
                    }
                }

            }
        }
    }
}
