#consent_banner_container{
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 99999;
    background-color: rgba(0, 0, 0, .80);
    color: #efefef;
    padding: 30px 0;

    transition: all 500ms cubic-bezier(0.250, 0.460, 0.450, 0.940);
    word-break: break-word;

    .content {
        color: #efefef;
        margin: 20px auto;
        width: 70%;
    }

    .consent_action_btn{
        display: flex;
        margin: auto;
        width: 70%;
        justify-content: end;
        gap: 20px;

        button {
            outline: none;
            border: 1px solid #efefef;
            color: #efefef;
            background-color: transparent;
            padding: 5px 20px;
            border-radius: 5px;
        }

        #accept_consent_btn{
            background-color: #007bff;
            border: none;
        }
    }
}