$Poppins: "Poppins", sans-serif;
$blue: #81A0EA;
$white: #fff;

#course_programs_associated_table_container{
    height: 100%;
    .table_container#course_programs_associated_table{
        border-radius: 4px;
        margin-top: 0px;
        overflow: auto;
        position: relative;
        width: 100%;
        *::-webkit-scrollbar {
            height: 9px;
            width: 9px;
        }
        .sticky-table{
            background: linear-gradient(0deg, rgba(255, 255, 255, 0.14), rgba(255, 255, 255, 0.14)), #121212;
            box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.07);
            width: 100%;
            &.table_loading{
                .sticky-table-table{
                    height: 100%;
                    #no_results_found{
                        align-items: center;
                        justify-content: center;
                        width: 50px;
                    }
                }
            }
            .checkbox{
                display: flex;
                justify-content: center;
                input[type="checkbox"]{
                    display: none;
                }
                .fa-check{
                    display: none;
                }
                input[type="checkbox"]:checked ~ label{
                    .checkbox_container{
                        background-color: $blue;
                        .fa-check{
                            color: $white;
                            display: inline;
                            font-size: 10px;
                        }
                    }    
                }
                label{
                    align-items: center;
                    cursor: pointer;
                    display: flex;
                    margin-bottom: 0px;
                    .checkbox_container{ 
                        align-items: center;
                        background: $white;
                        border-radius: 4px;
                        border: 1px solid transparent;
                        cursor: pointer;
                        display: flex;
                        height: 15px;
                        justify-content: center;
                        width: 15px;
                    }                       
                }
            }
            .sticky-table-row{
                /* Table Head */
                &:nth-child(1){
                    .sticky-table-cell{
                        background: linear-gradient(0deg, rgba(255, 255, 255, 0.24), rgba(255, 255, 255, 0.24)), #121212;
                        color: rgba(255, 255, 255, 0.6);
                        font: normal 600 12px/130% $Poppins;
                        &:nth-child(1){
                            text-align: unset;
                        }
                        &:nth-child(2){
                            width: unset;
                        }
                        &:last-child{
                            cursor: default;
                            width: 100%;
                        }
                        span{
                            display: inline-block;
                            padding-bottom: 4px;
                            vertical-align: bottom;
                        }
                        .sort_icon_container{
                            cursor: pointer;
                            display: inline-flex;
                            flex-direction: column;
                            margin-left: 10px;
                            svg{
                                color: rgba(255, 255, 255, 0.6);
                                height: 12px;
                                width: 10px;
                                &:first-child{
                                    transform: translateY(1.5px) scale(1.2);
                                }
                                &:last-child{
                                    transform: translateY(-1.5px) scale(1.2);
                                }
                                &.fa-caret-up.sort_desc{
                                    color: #81A0EA !important;
                                }
                                &.fa-caret-down.sort_asc{
                                    color: #81A0EA !important;
                                }
                            }
                        }
                    }
                }
                /* Table Body */
                &:nth-child(n+2){
                    height: 50px;
                    .sticky-table-cell{
                        background: #333333;
                        border-top: 1px solid #5D5D5D;
                        color: $white;
                        cursor: unset;
                        font: normal 400 14px/130% $Poppins;
                        &:nth-child(1){
                            cursor: pointer;
                            > div{
                                align-items: center;
                                display: flex;
                            }
                        }
                        &:last-child{
                            position: relative;
                            button{
                                background: none;
                                border: none;
                                color: #81A0EA;
                                padding: 0;
                                position: absolute;
                                right: 80px;
                                text-decoration: none;
                                top: 15px;
                            }
                        }
                    }
                }
            }
            #no_results_found{
                margin-left: unset;
                position: absolute;
            }
        }
    }
    
    #program_details_container{
        height: 100%;
        padding-right: 0px;
        &.show{
            right: 30px;
        }
        p.tab{
            padding-bottom: 23px;
        }
        .course_name_container{
            p{
                font: 400 12px/15.6px "Poppins", sans-serif;
            }
        }
    }
}