#lesson_content{
    color: #152C61;
    margin: 12px auto 0px auto;
    width: 83%;
    
    *{
        color: #152C61;
        font-family: "Poppins", sans-serif !important;
        text-shadow: none;
    }

    iframe{
        border: none !important;
        height: 410px !important;
        margin-bottom: 20px;
        width: 100% !important;
    }
    p, li{
        color: #152C61;
        font-family: 'Poppins', sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: 200% !important;
    }

    p span{
        color: inherit !important;
    }

    li{
        margin-bottom: 4px;
    }

    a{
        background-color: transparent !important;
        color: #2C6BFF;
        cursor: pointer;
        font-family: 'Poppins', sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 200%;
        text-decoration-line: underline;
    }

    img{
        max-width: 100%;
    }

    h1, h2, h3{
        color: #152C61;
        margin-bottom: 20px;
    }
    h1{
        font-size: 32px;
        font-weight: 500;
        line-height: 48px;
    }
    h2{
        font-size: 26px;
        font-weight: 500;
        line-height: 39px;
    }
    h3, h4{
        font-size: 22px;
        font-weight: 500;
        line-height: 33px;
    }
    h5, h6{
        font-size: 18px;
        font-weight: 500;
        line-height: 24px;
    }
    select{
        margin-bottom: 10px;
        outline: none;
    }
}

#like_dislike_form{
    margin-top: 51px;
    text-align: center;

    p{
        color: #000000;
        font-family: 'Poppins', sans-serif;
        font-size: 18px;
        font-style: normal;
        font-weight: normal;
        line-height: 27px;
        margin-bottom: 29px;
    }

    button[type=submit]{
        background-color: transparent;
        border: none;
        outline: none;

        input[type=radio]{
            display: none;
        }
        
        label{
            cursor: pointer;
            height: 60px;
            margin: 0px;
            width: 60px;
        }

        &#like_btn{
            margin-left: 45px;

            input[type=radio]:checked ~ label{
                background-image: url("./../../../../assets/images/global/like_fill.png");
            }
            label{
                background-image: url("./../../../../assets/images/global/like.png");
            }
        }

        &#dislike_btn{
            margin-right: 45px;

            input[type=radio]:checked ~ label{
                background-image: url("./../../../../assets/images/global/dislike_fill.png");
            }

            label{
                background-image: url("./../../../../assets/images/global/dislike.png");
            }
        }
    }
}

