$Poppins: "Poppins", sans-serif;

/* COLOR */
$dark_gray: #282828;
$white: #fff;
$black: #000;

.modal.show{
    z-index: 9999;
}

#holiday_modal{
    font-family: "Poppins", sans-serif;
    max-width: 465px;
    width: 465px;
    .modal-content{
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), #121212;
        border: none;
        box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.15);
        box-sizing: border-box;

        .modal-header{
            background: linear-gradient(0deg, rgba(255, 255, 255, 0.14), rgba(255, 255, 255, 0.14)), #121212;
            border: none;
            justify-content: flex-start;
            padding: 17px 24px;
            position: relative;

            .modal-title{
                color: rgba(255, 255, 255, 0.88);
                font-family: $Poppins;
                font-size: 14px;
                font-style: normal;
                font-weight: bold;
            }

            button{
                background-color: transparent;
                border: none;
                outline: none;
                padding: 0px;
                position: absolute;
                right: 17px;
                top: 9px;

                .close_icon{
                    background-image: url("https://assets.codingdojo.com/learn_platform/global/close_icon_new.png");
                    background-position: -61px 0px;
                    display: inline-block;
                    height: 33px;
                    width: 33px;
                }
            }
        }
        .modal-body{
            flex-wrap: wrap;
            padding: 24px;
            p{
                color: rgba(255, 255, 255, 0.88);
                font-family: $Poppins;
                font-size: 14px;
                font-weight: 400;
                line-height: 140%;
                margin-bottom: 20px;
                &.attendance_label{
                    margin-top: 18px;
                }
                
                span{
                    font-weight: bold;
                }

                a{
                    color: #81A0EA;
                }

                &:last-child{
                    margin-bottom: 0px;
                }
            }
            ol{
                color: rgba(255, 255, 255, 0.88);
                font: 400 14px/150% $Poppins;
                &:first-child{
                    margin-bottom: 20px;
                }
                li{
                    ul{
                        line-height: 140%;
                        list-style: disc;
                        margin-bottom: 15px;
                        padding-left: 18px;
                        li{
                            margin-top: 6px;
                        }
                    }
                }
            }
            h6.title{
                color: rgba(255, 255, 255, 0.4);
                display: block;
                font-family: $Poppins;
                font-size: 12px;
                font-weight: 600;
                margin-bottom: 13px;
                width: 100%;
            }
            #break_type_container{
                margin-bottom: 11px;
                display: flex;
                label{
                    cursor: pointer;
                    display: block;
                    position: relative;
                    margin-right: 15px;
                   
                    &:after{
                        content: attr(data-text);
                        font-size: 12px;
                        margin-left: 12px;
                        vertical-align: middle;
                    }
                    &[disabled]{
                        cursor: not-allowed;
                        opacity: .3;
                    }
                }
                /* radio */
                input[type="radio"]{
                    display: none;
                    &:checked ~ .radio_indicator{
                        border: 4px solid #81A0EA;
                    }
                }
                .title{
                    color: #fff;
                    margin-left: 10px;
                    font-family: "Poppins", sans-serif;
                    font-size: 14px;
                }
                .radio_indicator{
                    background: #fff;
                    border-radius: 100%;
                    border: 1px solid #A4B0C0;
                    display: inline-block;
                    height: 14px;
                    vertical-align: middle;
                    width: 14px;
                }
            }
            #attendance_date_list{
                display: flex;
                flex-wrap: wrap;
                list-style: none;
                margin-bottom: 10px;
                padding: 0px;
                li{
                    align-items: center;
                    background: #000000;
                    border-radius: 2px;
                    border: 2px solid rgba(154, 194, 255, 0.3);
                    color: rgba(255, 255, 255, 0.88);
                    display: flex;
                    font-size: 12px;
                    justify-content: space-between;
                    margin-bottom: 10px;
                    margin-right: 10px;
                    padding: 5px 9px 5px 12px;
                    width: 168px;
              

                    button.attendance_button{
                        background-color: transparent;
                        background-image: url(./../../../../assets/images/admin/dropdown_icons.png);
                        background-position: 20px;
                        border: none;
                        cursor: pointer;
                        height: 20px;
                        margin-left: 7px;
                        outline: none;
                        width: 20px;
                    }
                    &#add_date_item{
                        background: rgba(204, 224, 255, 0.14);
                        border: none;
                        cursor: pointer;
                        padding: 5px 6px 5px 12px;
                        width: unset;
                        button{
                            align-items: center;
                            background: transparent;
                            border: none;
                            color: #e8e8e9;
                            display: flex;
                            outline: none;
                        }
                        .add_icon{
                            background-color: transparent;
                            background-image: url(./../../../../assets/images/admin/dropdown_icons.png);
                            background-position: -2px;
                            border: none;
                            cursor: pointer;
                            display: inline-block;
                            height: 20px;
                            margin-left: 7px;
                            outline: none;
                            width: 20px;
                        }
                        .rmdp-container{
                            .rmdp-day.rmdp-today span{
                                background: #282828;
                                color: #e8e8e9;
                            }
                            .rmdp-day.rmdp-selected span:not(.highlight){
                                background: #81A0EA;
                                box-shadow: none;
                                color: #e8e8e9;
                            }
                            .rmdp-month-picker, .rmdp-year-picker{
                                background: #282828;
                            }
                            .rmdp-disabled{
                                span{
                                    background: #282828;
                                    color: #05DA80;

                                    &[title]:hover::after {
                                        background: #484343;
                                        border-radius: 4px;
                                        color: #fff;
                                        content: attr(title);
                                        left: -50px;
                                        margin-top: -20px;
                                        padding: 10px;
                                        position: absolute;
                                        top: -100%;
                                        width: 150px;
                                        z-index: 1;
                                    }
                                }
                            }
                            .rmdp-header-values, .rmdp-week-day, .rmdp-day{
                                color: #e8e8e9;
                                font-family: $Poppins, sans-serif;
                            }
                            .rmdp-ep-arrow{
                                display: none;
                            }
                            .rmdp-arrow {
                                border: solid #e8e8e9;
                                border-width: 0 2px 2px 0;
                                display: inline-block;
                                height: 10px;
                                margin-top: 5px;
                                padding: 2px;
                                width: 10px;
                            }
                            .rmdp-day span{
                                border-radius: 4px;
                            }
                            .rmdp-arrow-container:hover{
                                background: transparent;
                                box-shadow: none;
                                opacity: .8;
                            }
                            
                            .rmdp-wrapper{
                                background-color: #282828;
                                border-radius: 4px;
                                .rmdp-calendar{
                                    padding: 0px;
                                    .rmdp-header{
                                        background: #282828;
                                        height: unset;
                                        margin-top: 0px;
                                        padding: 11px 11px 0px;
                                    }
                                    .rmdp-week-day{
                                        background: #282828;
                                    }
                                    .rmdp-week{
                                        background: #333333;
                                        padding: 0px 11px;
                                        &:first-child{
                                            background: #282828;
                                            border-bottom: 1px solid #AEAEAE;
                                            padding: 0px 11px 11px;
                                        }
                                        &:nth-child(2){
                                            padding-top: 10px;
                                        }
                                        &:last-child{
                                            padding-bottom: 10px;
                                        }
                                    }
                                    .rmdp-day-picker{
                                        padding: 0px;
                                    }
                                }
                            }
                        } 
                    }
                }
            }

            ul#program_list{
                display: flex;
                flex-wrap: wrap;
                list-style: none;
                margin-bottom: 0px;
                padding-left: 0px;
                li{
                    margin-bottom: 8px;
                    padding-right: 15px;
                    width: 50%;                    
                    &:nth-last-child(2), &:last-child{
                        margin-bottom: 0px;
                    }
                    &:first-child{
                        width: 100%;
                    }
                    .checkbox{
                        input[type="checkbox"]{
                            display: none;
                        }
                        .fa-check{
                            display: none;
                        }
                        input[type="checkbox"]:checked ~ label{
                            .checkbox_container{
                                background-color: #81A0EA;
                                .fa-check{
                                    color: #fff;
                                    display: inline;
                                    font-size: 10px;
                                }
                            }    
                        }
                        label{
                            align-items: center;
                            cursor: pointer;
                            display: inline-flex;
                            margin-bottom: 0px;
                            padding-left: 15px;
                            position: relative;
                            .checkbox_container{ 
                                align-items: center;
                                background: rgba(204, 224, 255, 0.14);
                                border-radius: 4px;
                                border: 1px solid transparent;
                                cursor: pointer;
                                display: flex;
                                height: 15px;
                                justify-content: center;
                                left: 0px;
                                position: absolute;
                                top: 2px;
                                width: 15px;
                            }
                            p{
                                margin-left: 10px;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                                width: 176px;
                                &[title]:hover::after {
                                    background: #484343;
                                    border-radius: 4px;
                                    color: #fff;
                                    content: attr(title);
                                    margin-top: -20px;
                                    padding: 10px;
                                    position: absolute;
                                    right: 0px;
                                    top: -100%;
                                    width: unset;
                                  }
                            }                       
                        }
                    }
                }
            }
            
        }

        .modal-footer{
            background: linear-gradient(0deg, rgba(255, 255, 255, 0.14), rgba(255, 255, 255, 0.14)), #121212;
            border: none;
            padding: 20px 24px;
            
            button{
                background-color: transparent;
                border: none;
                color: rgba(255, 255, 255, 0.88);
                font-family: $Poppins;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                margin-right: 30px;
                outline: none;
            }
            button[type="submit"], .next{
                background: rgba(204, 224, 255, 0.14);
                border-radius: 4px;
                border: none;
                color: #81A0EA;
                font-family: $Poppins;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                height: 40px;
                margin: 0px;
                outline: none;
                padding: 10px 14px;
            }

        }
    }
}
