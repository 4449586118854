$Poppins: 'Poppins', sans-serif;

$white: #fff;

#upload_student_account_modal, #success_modal{
    left: 50%;
    max-width: none;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 500px;

    &#success_modal{
        width: 500px;
    }

    .modal-content{
        background: #282828;
        border-radius: 0px;
        box-shadow: 0px 4px 2px rgba(0, 0, 0, 0.05);
        color: rgba(255, 255, 255, 0.88);
        font-family: $Poppins;

        .modal-header{
            background: #333333;
            border-radius: 0px;
            border: none;
            justify-content: left;
            padding: 18px 23px;
            position: relative;
    
            h4{
                font-family: 'Poppins', sans-serif;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: 16px;
                margin-bottom: 0px;
            }

            button{
                background-color: transparent;
                background-image: url("https://assets.codingdojo.com/learn_platform/admin/edit_course/admin_edit_course_sprite.png");
                background-position: -65px 0px;
                border: none;
                height: 15px;
                outline: none;
                padding: 0px;
                position: absolute;
                right: 22px;
                top: 20px;
                width: 15px;
            }
        }
        .modal-body{
            padding: 24px 24px 4px;

            p{
                background: #292929;
                font-family: $Poppins;
                font-size: 12px;
                font-weight: 400;
                margin-bottom: 0px;
                padding: 0 0 20px 0;

                span{
                    font-weight: 600;
                }
            }

            #upload_student_account_details{
                align-items: center;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;

                .input_details_block{
                    display: block;
                    position: relative;
                    width: 100%;

                    .email_error{
                        border: 1px solid #FF6B6B !important;
                    }

                    .error_message{
                        position: absolute;
                        color: #FF6B6B;
                        font: 12px/16px $Poppins;
                        top: -19px;
                    }

                    .label_title{
                        color: rgba(255, 255, 255, 0.88);
                        display: block;
                        font: 600 14px/18px $Poppins;
                        margin-bottom: 10px;

                        a{
                            color: #81A0EA;
                            font-weight: 600 !important;
                            font: 12px/16px $Poppins;
                            margin-left: 5px;
                            text-decoration: none;
                        }
                    }

                    p{
                        padding-bottom: 0;
                    }
    
                    input[type="text"], textarea, .react-dropdown-select, .upload_student_access_csv_file_block{
                        background: linear-gradient(0deg, rgba(255, 255, 255, 0.24), rgba(255, 255, 255, 0.24)), #121212;
                        border-radius: 4px;
                        border: none;
                        color: #E5E5E5;
                        font: 600 12px/16px $Poppins;
                        height: 40px;
                        margin-bottom: 20px;
                        outline: none;
                        padding: 0 15px;
                        width: 100%;

                        &.upload_student_access_csv_file_block{
                            align-items: center;
                            border: 2px dashed #91ACED;
                            display: flex;
                            height: 50px;

                            &.is_uploaded{
                                border: 2px solid transparent;
                            }

                            p{
                                align-items: center;
                                background: transparent;
                                color: #E5E5E5;
                                display: flex;
                                font-weight: 400;
                                font: 12px/16px;
                                padding-bottom: 0;

                                &.placeholder{
                                    color: rgba(255, 255, 255, 0.48);
                                    font-weight: 600;
                                }

                                .upload_icon{
                                    background-image: url("https://assets.codingdojo.com/learn_platform/admin/student_access/upload_csv_icons.svg");
                                    display: block;
                                    height: 14px;
                                    margin-right: 15px;
                                    width: 14px;
                                    
                                    &.is_uploaded{
                                        align-items: center;
                                        background-color: #4F7F73;
                                        background-image: none;
                                        border-radius: 50%;
                                        display: flex;
                                        justify-content: center;

                                        svg{
                                            color: #05DA80;
                                            font-size: 8px;
                                        }
                                    }
                                }
                            }

                            label{
                                margin: 0 0 0 auto;
                                span{
                                    align-items: center;
                                    background: #81A0EA;
                                    border-radius: 6px;
                                    cursor: pointer;
                                    display: flex;
                                    height: 33px;
                                    justify-content: center;
                                    width: 75px;
                                }
                            }

                            button{
                                background: transparent;
                                border: none;
                                margin-left: auto;
                                outline: none;

                                .remove_icon{
                                    background: url("https://assets.codingdojo.com/learn_platform/admin/student_access/upload_csv_icons.svg") no-repeat -19px 0px;
                                    display: block;
                                    height: 14px;
                                    width: 11px;
                                }
                            }
                        }
                    }
                    
                    textarea{
                        height: 120px;
                        margin-bottom: 0px;
                        padding: 15px;
                        resize: none;
                    }
    
                    &.disabled{
                        .react-dropdown-select{
                            pointer-events: none;
                        }
                    }
                    .date_picker{
                        input{
                            padding-left: 46px;
                        }
                    }
                    .fa-calendar{
                        color: rgba(255, 255, 255, 0.58);
                        left: 20px;
                        position: absolute;
                        top: 36px;
                    }
    
                    >div{
                        .react-dropdown-select{
                            .react-dropdown-select-dropdown{
                                width: 450px;
                            }
                        }
                    }
                }
            }
        }

        .modal-footer{
            align-items: center;
            background: #333333;
            border-top: none;
            display: flex;
            height: 79px;
            justify-content: flex-end;

            button{
                background: transparent;
                border-radius: 2px;
                border: none;
                color: $white;
                font: 600 14px/18px $Poppins;
                height: 40px;
                min-width: 90px;
                outline: none;

                &.confirm_btn{
                    background: rgba(204, 224, 255, 0.14);
                    color: #81A0EA;
                    margin: 0 24px 0 20px;
                }

                &.confirm_btn{
                    min-width: 145px;
                }
            }
        }
    }
}