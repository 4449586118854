$Poppins: 'Poppins', sans-serif;

$white: #fff;

#delete_note_confirmation_modal{
    left: 50%;
    max-width: none;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 500px;

    .modal-content{
        background: #282828;
        border-radius: 0px;
        box-shadow: 0px 4px 2px rgba(0, 0, 0, 0.05);
        color: rgba(255, 255, 255, 0.88);
        font-family: $Poppins;

        .modal-header{
            background: #333333;
            border-radius: 0px;
            border: none;
            justify-content: left;
            padding: 18px 23px;
            position: relative;
    
            h4{
                font-family: 'Poppins', sans-serif;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: 16px;
                margin-bottom: 0px;
            }

            button{
                background-color: transparent;
                background-image: url("https://codingdojo_assets.s3.us-east-1.amazonaws.com/learn_platform/admin/edit_course/admin_edit_course_sprite.png");
                background-position: -65px 0px;
                border: none;
                height: 15px;
                outline: none;
                padding: 0px;
                position: absolute;
                right: 22px;
                top: 20px;
                width: 15px;
            }
        }
        .modal-body{
            padding: 24px 24px 4px;

            p{
                background: #292929;
                font-family: $Poppins;
                font-size: 12px;
                font-weight: 400;
                margin-bottom: 0px;
                padding: 0 0 20px 0;

                span{
                    font-weight: 600;
                }
            }
        }

        .modal-footer{
            align-items: center;
            background: #333333;
            border-top: none;
            display: flex;
            height: 79px;
            justify-content: flex-end;

            button{
                background: transparent;
                border-radius: 2px;
                border: none;
                color: $white;
                font: 600 14px/18px $Poppins;
                height: 40px;
                min-width: 90px;
                outline: none;

                &.confirm_btn{
                    background: rgba(217, 75, 75, 0.1);
                    color: #E1554C;
                    margin: 0 24px 0 20px;
                    min-width: 145px;
                } 
            }
        }
    }
}