$Poppins: 'Poppins', sans-serif;

#add_live_lecture_modal, #edit_live_lecture_modal, #video_record_access_modal{
    max-width: none;
    width: 887px;
    .modal-content{
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.24), rgba(255, 255, 255, 0.24)), #121212;
        border-radius: 0px;
        border: none;
        box-shadow: 0px 4px 2px rgba(0, 0, 0, 0.05);
        color: rgba(255, 255, 255, 0.88);
        font-family: $Poppins;
        .modal-header{
            background: linear-gradient(0deg, rgba(255, 255, 255, 0.14), rgba(255, 255, 255, 0.14)), #121212;
            border-radius: 0px;
            border: none;
            justify-content: left;
            padding: 18px 23px;
            position: relative;
    
            h4{
                font-family: 'Poppins', sans-serif;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: 16px;
                margin-bottom: 0px;
            }
            button{
                background-color: transparent;
                background-image: url("https://codingdojo_assets.s3.us-east-1.amazonaws.com/learn_platform/admin/edit_course/admin_edit_course_sprite.png");
                background-position: -65px 0px;
                border: none;
                font: 12px/18px $Poppins;
                height: 15px;
                outline: none;
                padding: 0px;
                position: absolute;
                right: 22px;
                top: 20px;
                width: 15px;
            }
        }
        .modal-body{
            padding: 18px 0px 0px;
            background: #292929;

            .form-group{
                display: flex;
                padding: 0px 24px;
                position: relative;
                &.select_container{
                    div.form-content{
                        width: 194px;
                        div{
                            div.react-dropdown-select{
                                div.react-dropdown-select-content{
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    white-space: nowrap;
                                }
                            }
                        }
                    }
                }
                .color_red{
                    color: #F56B6B;
                    font: 14px/18px $Poppins;
                    margin-bottom: 0;
                    margin-top: 10px;
                }
                .one_time_date_text{
                    bottom: 11px;
                    font-family: "Poppins", sans-serif;
                    font-size: 12px;
                    left: 99px;
                    pointer-events: none;
                    position: absolute;
                }
                input{
                    background: #4B4B4B;
                    border-radius: 4px;
                    border: none;
                    color: #fff;
                    font-family: $Poppins;
                    font-size: 12px;
                    height: 40px;
                    padding: 8px 15px;
                    &.border_red{
                        border: 1px solid #F56B6B;
                    }
                }
                .react-datepicker__triangle{
                    display: none;
                }
                .react-datepicker{
                    background: #4B4B4B;
                    border: 1px solid rgba(255, 255, 255, 0.88);
                    position: absolute;
                    
                    .react-datepicker__day, .react-datepicker__time-name{
                        color: rgba(255, 255, 255, 0.88);
                        font-family: $Poppins;
                        &:hover{
                            background: transparent;
                        }
                    }
                    .react-datepicker__navigation{
                        top: 12px;
                    }
                    .react-datepicker__day--outside-month, .react-datepicker__day--disabled{
                        color: rgba(255, 255, 255, 0.88);
                        opacity: 0.4;
                    }
                    .react-datepicker__day-name{
                        color: #AEB9C5;
                    }
                    .react-datepicker__day--keyboard-selected, .react-datepicker__day--selected{
                        background: transparent;
                        color: #81A0EA;
                    }
                }
                .react-datepicker__header:not(.react-datepicker__header--has-time-select){
                    background: #4B4B4B;
                    border: none;
                    padding-top: 0px;
                    .react-datepicker__current-month{
                        background: #81A0EA;
                        color: rgba(255, 255, 255, 0.88);
                        height: 40px;
                        margin-top: -1px;
                        padding-top: 7px;
                    }
                }
                .react-dropdown-select{
                    padding: 7px 11px !important;
                    background: #4b4b4b;
                    border-radius: 2px !important;
                    height: 40px;
                    &.program_select{
                        .react-dropdown-select-content{
                            justify-content: unset;
                            #selected_program{
                                margin-right: 2px;
                                max-width: 120px;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                                &.has_one{
                                    max-width: unset;
                                }
                            }
                            span{
                                margin: 0px;
                                overflow: unset;
                                text-overflow: unset;
                                white-space: unset;
                                width: unset;
                            }
                            .react-dropdown-select-input{
                                margin-left: 5px;
                                outline: none;
                            }
                        }
                        .react-dropdown-select-dropdown{
                            padding: 0px;
                            .dropdown_menu{
                                padding: 10px 0px;
                                .dropdown_item{
                                    font: 12px "Poppins",sans-serif;
                                    margin-bottom: 0px;
                                    padding: 6px 18px 6px 18px;
                                    &:hover{
                                        background: rgba(0, 116, 217, 0.1);
                                    }
                                    .checkbox{
                                        opacity: 1;
                                        label{
                                            .checkbox_container{
                                                height: 14px;
                                                width: 14px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .react-dropdown-select-dropdown-handle{
                        display: flex;
                        height: 18px;
                    }
                    .dropdown_handle_icon.clear{
                        background-color: #4b4b4b;
                        position: absolute;
                        right: 14px;
                        z-index: 9;
                    }
                    .react-dropdown-select-content{
                        span{
                            font-family: $Poppins;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            width: 220px;
                            ~ input{
                                display: none;
                            }
                        }
                        input{
                            background: transparent;
                            font-family: $Poppins;
                            font-size: 12px;
                            height: unset;
                            padding: 0px;
                            width: 100%;
                        }
                    }
                    .react-dropdown-select-no-data{
                        color: #81A0EA;
                    }
                    
                    .react-dropdown-select-dropdown{
                        border-radius: 4px;
                        margin-top: 6px;
                        padding: 10px 0px;
                        max-height: 320px;
                        min-width: 266px;
                        .react-dropdown-select-item{
                            border: none;
                            font-family: $Poppins;
                            font-size: 12px;
                            padding: 6px 18px;
                            &.react-dropdown-select-item-disabled{
                                display: none;
                                ins{
                                    display: none;
                                }                            
                            }
                            &.react-dropdown-select-item-selected{
                                background: transparent;
                                color: #81A0EA;
                                padding-right: 30px;
                                position: relative;
                                &:after{
                                    background-image: url("https://assets.codingdojo.com/learn_platform/admin/access_control/acl_icons_sprite.svg");
                                    background-position: 14px -12px;
                                    content: "";
                                    display: inline-block;
                                    height: 11px;
                                    position: absolute;
                                    right: 10px;
                                    width: 13px;
                                }
                            }
                        }
                    }
                }
                &:nth-child(4){
                    margin-bottom: 20px;
                    .form-content{
                        &:first-child{
                            max-width: 266px;
                        }
                    }
                }
                &.column{
                    flex-direction: column;
                }
                label{
                    display: block;
                    font-family: $Poppins;
                    font-size: 14px;
                    span.color_red{
                        font-size: 12px;  
                        margin-left: 5px;
                    }
                }
                .form-content{
                    margin-right: 20px;
                    width: 100%;
                    &:last-child{
                        margin-right: 0px;
                    }
                    &.flex-grow{
                        flex-grow: 1;
                        input{
                            width: 100%;
                        }
                    }
                }
                .error_msg_container{
                    > div{
                        display: flex;
                    }
                    #error_button{
                        background-color: transparent;
                        border: none;
                        color: #81A0EA;
                        font: 14px/18px $Poppins;
                        margin-top: 10px;
                        opacity: 0.8;
                        &:focus{
                            outline: none !important;
                        }
                    }
                    > ul{
                        margin: 0;
                        li{
                            font: 14px/18px $Poppins;
                            margin-top: 5px;
                        }
                    }
                }
            }
            .footer{
                background: linear-gradient(0deg, rgba(255, 255, 255, 0.14), rgba(255, 255, 255, 0.14)), #121212;
                border: none;
                height: 79px;
                margin-top: 20px;
                padding: 21px 24px;
                text-align: right;
                button{
                    background: transparent;
                    border: none;
                    color: #fff;
                    font: 14px/18px $Poppins;
                    &[type="submit"]{
                        background: rgba(204, 224, 255, 0.14);
                        border-radius: 2px;
                        color: #81A0EA;
                        margin-left: 30px;
                        padding: 8px 16px;
                    }
                }
            }
        }
    }
}