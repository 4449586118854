$black: #000;
$blue: #2C6BFF;
$border_gray: #F5F5F5;
$dark_blue: #152C61;
$gray: #707070;
$none: transparent;
$orange: #EF7E1B;
$red: #E1554C;
$Poppins: "Poppins", sans-serif;
$white: #fff;

.delete_forum_modal{
    .modal-dialog{
        max-width: 508px;
        width: 508px;

        .modal-content{
            border: none;

            .modal-header{
                border: none;
                padding: 32px 30px 0px 30px;

                .modal-title{
                    color: $dark_blue;
                    font-family: $Poppins;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 24px;
                }
            }

            .modal-body{
                border: none; 
                padding: 18px 30px 2px 30px;

                p{
                    color: $gray;
                    font-family: $Poppins;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: normal;
                    line-height: 21px;
                }
            }

            .modal-footer{
                background-color: #F5F8FF;
                border: none;
                height: 60px;
                padding: 0px 20px;

                form{
                    align-items: center;
                    display: flex;
                    justify-content: flex-end;
                    margin: 0px;
                    width: 100%;

                    button{
                        align-items: center;
                        background-color: transparent;
                        border-radius: 6px;
                        border: none;
                        display: flex;
                        font-family: $Poppins;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                        height: 34px;
                        justify-content: center;
                        line-height: 21px;
                        outline: none;
                        padding: 0px 19px;

                        &[type="button"]{
                            color: $dark_blue;
                            margin-right: 37px;
                        }
                        &[type="submit"]{
                            background-color: #FCEEEE;
                            color: $red;
                        }
                    }
                }
            }
        }
    }
}

/* DARK MODE */ 
.is_dark_mode{
    .delete_forum_modal{
        .modal-dialog{
            .modal-content{
                .modal-header{
                    background: #333333;
                    .modal-title{
                        color: #E5E5E5;
                    }
                }

                .modal-body{
                    background: #333333;

                    p{
                        color: #CCCCCC;
                    }
                }

                .modal-footer{
                    background-color: #4B4B4B;

                    form{
                        button{
                            &[type="button"]{
                                color: #E5E5E5;
                            }

                            &[type="submit"]{
                                background: rgba(217, 75, 75, 0.1);
                                border: 1px solid $red;
                            }
                        }
                    }
                }
            }
        }
    }
}