#admin_program_calendar_container{
    background-color: #121212;
    display: flex;
    font-family: "Poppins", sans-serif;
    height: 100vh;

    button:focus{
        outline: 5px transparent;
    }

    #program_calendar_right_container{
        display: flex;
        flex-direction: column;
        flex: 1 1;
        padding: 12px 30px 20px 30px;
    }

    #program_calendar_filters{
        align-items: center;
        border-bottom: 1px solid #6c6c6c;
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
        padding-bottom: 3px;

        #toggle_create_event_button{
            background-color: rgba(204, 224, 255, 0.14);
            border-radius: 2px;
            border: none;
            color: #81a0ea;
            font-family: "Poppins", sans-serif;
            font-size: 12px;
            font-weight: 600;
            margin-top: 3px;
            padding: 8px 23px;
        }
    }

    #event_type_checkboxes_container{
        background-color: #2e2e2e;
        border-radius: 2px;
        display: flex;
        font-size: 14px;
        padding: 11px 10px 8px 11px;

        label{
            -moz-user-select: none;
            -ms-user-select: none;
            -webkit-user-select: none;
            color: rgba(255,255,255,.88);
            cursor: pointer;
            font-family: "Poppins", sans-serif;
            margin-bottom: 0;
            margin-right: 40px;
            position: relative;
            user-select: none;

            input{
                appearance: none;
                margin-right: 21px;
            }
            
            &::before{
                background-color: #2e2e2e;
                border-radius: 2px;
                border: 1px solid rgba(255, 255, 255, 0.55);
                content: "";
                height: 16px;
                left: 0;
                position: absolute;
                top: 1px;
                width: 16px;
            }

            &::after{
                background: url("https://assets.codingdojo.com/learn_platform/admin/course_calendar/course_calendar_icon.png") no-repeat -67px -51px;
                content: "";
                display: none;
                height: 8px;
                left: 3px;
                position: absolute;
                top: 5px;
                width: 10px;
            }


            &.is_checked{
                &::after{
                    display: block;
                }

                &::before{
                    border: none;
                }

                &#cohort_start::before{
                    background-color: rgba(29, 170, 51, 1);
                }
                &#holiday::before{
                    background-color: rgba(129, 160, 234, 1);
                }
                &#break::before{
                    background-color: rgba(210, 81, 255, 1);
                }
                &#training::before{
                    background-color: rgba(255, 181, 36, 1);
                }
                &#for_approval::before{
                    background-color: rgba(255, 255, 255, 1);
                }
            }
        }
    }

    #months_container{
        margin-top: 5px;
        overflow: auto;
        width: 100%;

        > div{
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            margin: 14px auto 63px;
            max-width: 1096px;
            width: 100%;
        }
    }

    .calendar_loading_prompt{
        background-image: url("https://assets.codingdojo.com/learn_platform/admin/global/table_loader.gif");
        background-size: cover;
        height: 30px;
        left: 50%;
        position: relative;
        top: 35%;
        transform: translate(-50%, -50%);
        width: 30px;

        &::after{
            bottom: -80%;
            color: rgba(255,255,255,.88);
            content: "Loading...";
            font-size: 14px;
            font-weight: 500;
            left: -50%;
            position: absolute;
        }
    }

    #pending_calendar_updates_prompt{
        background-color: #2e2e2e;
        border-radius: 2px;
        bottom: 40px;
        box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12), 0px 3px 5px rgba(0, 0, 0, 0.2);
        color: white;
        font-family: "Poppins", sans-serif;
        font-size: 14px;
        font-weight: 500;
        margin-left: auto;
        padding: 13px 13px 13px 24px;
        position: fixed;
        right: 30px;
        width: fit-content;

        button{
            background-color: #484B50;
            border-radius: 4px;
            border: none;
            color: #81a0ea;
            font-family: "Poppins", sans-serif;
            font-weight: 600;
            margin-left: 22px;
            padding: 8px 10px;
        }
    }

    ::-webkit-scrollbar-thumb {
        background: #595959;
        border-radius: 10px;
    }

    ::-webkit-scrollbar-track{
        box-shadow: unset;
    }
}

#notification_success_toast{
    left: 50%;
    max-width: unset;   
    position: fixed;
    top: 114px;
    transform: translate(-50%, -50%);
    z-index: 99999;
    .toast-body{
        background: #04aa64;
        border-radius: 4px;
        box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.14), 0px 3px 3px rgba(0, 0, 0, 0.12), 0px 1px 8px rgba(0, 0, 0, 0.2);
        color: #fff;
        padding-right: 43px;
        button{
            background-color: transparent;
            background-image: url(https://assets.codingdojo.com/learn_platform/admin/edit_course/admin_edit_course_sprite.png);
            background-position: -65px 0px;
            border: none;
            height: 15px;
            outline: none;
            padding: 0px;
            position: absolute;
            right: 15px;
            top: 15px;
            width: 15px;
        }
        p{
            font-family: "Poppins", sans-serif;
            font-size: 14px;
            margin-bottom: 0px;
            text-align: justify;
            span{
                font-weight: 600;
            }
        }
    }
}