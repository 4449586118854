#discussion_question_modal{
    &.modal-dialog{
        max-width: 600px;
        width: 600px;
        .modal-content{
            background: #FFFFFF;
            border-radius: 6px;
            box-shadow: 0px 4px 2px rgba(0, 0, 0, 0.05);
            .modal-body{
                height: 364px;
                padding: 40px 0px 0px 0px;
                text-align: center;
                button{
                    background-color: transparent;
                    background-image: url("https://assets.codingdojo.com/learn_platform/global/close_icon_new.png");
                    border: none;
                    display: inline-block;
                    height: 33px;
                    outline: none;
                    padding: 0px;
                    position: absolute;
                    right: 26px;
                    top: 26px;
                    width: 33px;
                }
                .checklist_icon{
                    background-image: url("https://codingdojo_assets.s3.us-east-1.amazonaws.com/learn_platform/global/checklist_icon.png");
                    height: 90px;
                    margin: 0px auto 29px;
                    width: 90px;
                    &.red_clock{
                        background-position: 0px 0px;
                    }
                }
                h6{
                    color: #152C61;
                    font-family: 'Poppins', sans-serif;
                    font-size: 16px;
                    margin-bottom: 28px;
                    text-align: center;   
                }

                p{
                    color: #152C61;
                    font-family: 'Poppins', sans-serif;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: normal;
                    line-height: 24px;
                    margin: 0px auto 24px auto;
                    text-align: center;
                    width: 435px;
                }

                a{
                    background: #2C6BFF;
                    border-radius: 5px;
                    color: #FFFFFF;
                    display: inline-block;
                    max-width: 368px;
                    padding: 11px 10px;
                    text-decoration: none;
                    width: 100%;
                    
                }
            }
        }
    }
}
.is_dark_mode{
    #discussion_question_modal.modal-dialog{
        .modal-content{
            background: #2A2A2A !important;
            .modal-body{
                background: #2A2A2A;
                p, h6{
                    color: #E5E5E5;
                }
                button{
                    background-position: -29px 0px;
                }
            }
        }
    }
}
@media only screen and (max-width: 768px){
    #discussion_question_modal{
        &.modal-dialog{
            .modal-content{
                .modal-body{
                    flex: unset;
                    padding: 5px 20px 0px;
                    .checklist_icon{
                        margin: 0px auto 23px;
                    }
                    p{
                        font-size: 14px;
                        line-height: 21px;
                        width: 100%;
                    }
                }
            }
        }
    }
}