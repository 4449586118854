$Poppins: 'Poppins', sans-serif;
#assign_to_next_stack_popover{
    -webkit-backdrop-filter: blur(10px);
    align-items: center;
    backdrop-filter: blur(10px);
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.24), rgba(255, 255, 255, 0.24)), linear-gradient(180deg, #121212 0%, rgba(18, 18, 18, 0) 100%);
    border-radius: 4px;
    bottom: 50px;
    display: flex;
    height: 70px;
    justify-content: space-between;
    left: 50%;
    padding: 20px;
    position: absolute;
    transform: translate(-50%, 0%);
    width: 575px;
    z-index: 9;

    p{
        color: rgba(255, 255, 255, 0.88);
        font-family: $Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: normal;
        line-height: 130%;
        margin-bottom: 0px;

        span{
            font-weight: 500;
        }
    }

    button{
        align-items: center;
        background-color: transparent;
        border-radius: 4px;
        border: 1px solid #81A0EA;
        box-sizing: border-box;
        display: flex;
        height: 38px;
        justify-content: center;
        outline: none;
        padding: 10px;
        width: 180px;

        &#copy_emails_button{
            width: 220px;
        }
        
        svg{
            color: #fff;
            font-size: 16px;
            margin-right: 10px;
        }

        span{
            color: rgba(255, 255, 255, 0.88);
            font-family: $Poppins;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 130%;
        }
    }
}