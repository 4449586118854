$Poppins: 'Poppins', sans-serif;
.admin_workspace_management_container{
    #workspace_dropdown{
        display: none;
    }
    #workspace_management_container{
        padding-top: 30px;
        button{
            outline: none;
        }
        #workspace_header{
            position: relative;
            h1{
                color: #fff;
                font-family: $Poppins;
                font-size: 16px;
                margin-bottom: 43px;
            }
            button{
                background-color: rgba(204, 224, 255, 0.14);
                background-image: url("https://assets.codingdojo.com/learn_platform/admin/workspace_management/workspace_management_icons.svg");
                background-position: -152px 6px;
                background-repeat: no-repeat;
                border-radius: 2px;
                border: none;
                height: 32px;
                padding: 0px;
                position: absolute;
                right: 0px;
                top: 0px;
                width: 36px;
            }
    
        }
    }
    form#search_workspace_form{
        display: flex;
        position: absolute;
        p{
            color: #B2B2B2;
            display: none;
            font-family: "Poppins", sans-serif;
            font-size: 12px;
            line-height: 14px;
            margin:6px 0px 0px 10px;
            width: 320px;
        }
        svg{
            color: #73839C;
            font-size: 14px;
            left: 12px;
            margin-right: 10px;
            position: absolute;
            top: 12px;
            z-index: 2;
        }
        input{
            align-items: center;
            background: linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), #121212;
            border-radius: 4px;
            border: 1px solid transparent;
            color: rgba(255, 255, 255, 0.88);
            font-family: $Poppins;
            font-size: 12px;
            font-style: normal;
            font-weight: normal;
            height: 40px;
            line-height: 140%;
            padding: 0px 12px 0px 36px;
            position: relative;
            width: 256px;
    
            &:focus{
                background: #2A2A2A;
                border: 1px solid #81A0EA;
                box-shadow: 0px 0px 6px #81A0EA;
                outline: none;
            }
    
            &:focus + p{
                display: block;
            }
        }
    }
}
#demographic_tooltip{
    background: #333333;
    max-width: 491px;
    text-align: center;
    width: 100%;
    z-index: 10000;
    &[x-placement^=bottom]>.arrow::after, >.arrow::after{
        border-bottom-color: #333333;
    }
    .popover-body{
        background: #333333;
        border-radius: 2px;
        border: 1px solid #81A0EA;
        padding: 20px;
        img{
            margin-bottom: 20px;
        }
        p{
            color: #E5E5E5;
            font-family: "Poppins", sans-serif;
            font-size: 12px;
            margin-bottom: 0px;
            text-align: left;
        }
    }
}