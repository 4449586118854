.admin_student_matching{
    #admin_right_container{
        .filter_container{
            margin-bottom: 6px;
        }
    }
}
#student_matching_container{
    background: #2a2a2a;
    color: #F6F9FD;
    display: flex;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    height: calc(100% - 133px);
    min-width: 1020px;
    overflow-x: auto;
    padding-top: 23px;
    position: relative;
    *::-webkit-scrollbar{
        width: 6px;
        height: 6px;
    }
    *::-webkit-scrollbar-track{
        box-shadow: inset 0 0 5px transparent;
        border-radius: 10px;
    }
    *::-webkit-scrollbar-thumb{
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.4)), #121212;
        border-radius: 10px;
    }
    *::-webkit-scrollbar-thumb:hover{
        background: #E0E0E0;
    }
    .hidden{
        display: none !important;
    }
    input[type="checkbox"]{
        display: none;
        &:checked~label{
            background: #81A0EA;
            svg{
                opacity: 1;
            }
        }
    }
    .checkbox_label{
        align-items: center;
        background: rgba(204, 224, 255, 0.14);
        border-radius: 4px;
        cursor: pointer;
        display: inline-flex;
        height: 15px;
        justify-content: center;
        margin: 0px;
        width: 15px;
        &[for="student_checkbox"]{
            background: rgba(255, 255, 255, 0.58);
            margin-top: 2px;
            vertical-align: top;
        }
        svg{
            font-size: 10px;
            margin-top: 1px;
            opacity: 0;
        }
    }
    button{
        background: transparent;
        border: none;
        color: #ffff;
        outline: none;
    }
    table{
        width: 100%;
        thead{
            tr{
                th{
                    background: linear-gradient(0deg, rgba(255, 255, 255, 0.24), rgba(255, 255, 255, 0.24)), #121212;
                    font-size: 12px;
                    padding: 10px 7px;
                    text-align: left;
                    button{
                        padding: 0px;
                        svg{
                            margin-left: 2px;
                            color: #fff;
                            &.light{
                                color: rgba(255, 255, 255, 0.4);
                            }
                        }
                    }
                    &:nth-child(1){
                        padding-left: 15px;
                    }
                    &:nth-child(2){
                        width: 146px;
                    }
                    &:nth-child(3){
                        width: 72px;
                    }
                    &:nth-child(4){
                        width: 92px;
                    }
                    &:nth-child(5){
                        width: 89px;
                    }
                    &:last-child{
                        width: 124px;
                    }
                }
            }
        }
        tbody{
            &.is_active_dragging{
                tr{
                    &.is_ghosting{
                        display: none !important;
                    }
                }
            }
            tr{
                &.move_disable{
                    cursor: pointer;
                }
                &.is_dragging{
                    align-items: center;
                    background: linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), #121212 !important;
                    border: 1px dashed #fff;
                    display: flex;
                    justify-content: space-between;
                    max-height: 60px;
                    td{
                        background: linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), #121212 !important;
                        border-bottom: none;
                        font-size: 12px;
                        padding: 7px !important;
                        &:last-child{
                            padding-right: 20px !important;
                        }
                        &:first-child{
                            padding-left: 20px !important;
                        }
                        &:nth-child(2){
                            overflow: hidden;
                            padding-right: 106px !important;
                            position: relative;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        }
                        #student_count_on_drag{
                            color: #b3b3b3;
                            position: absolute;
                            right: 10px;
                            top: 7px;
                        }
                    }
                }
                &.hidden_tr{
                    height: calc(100vh - 296px);
                    td{
                        border: none;
                    }
                }
                td{
                    border-bottom: 1px solid #fff;
                    padding: 22px 7px;
                    text-align: left;
                    &:nth-child(1){
                        padding-left: 15px;
                    }
                    .is_veteran{
                        background-image: url("./../../../assets/images/admin/rostering/matching_gender_icon.png");
                        background-position: -60px -1px;
                        background-repeat: no-repeat;
                        background-color: rgba(245, 157, 107, 0.4);
                        border-radius: 4px;
                        display: inline-block;
                        height: 20px;
                        width: 20px;
                    }
                }
            }
        }
    }
    h4{
        background: #2a2a2a;
        color: #a5a6a9;
        font-size: 16px;
        font-weight: 700;
        margin: 0px;
        padding-bottom: 20px;
        position: sticky;
        top: 0px;
    }
    .scroll_container{
        height: calc(100% - 52px);
        overflow: auto;
    }
    #table_loading_container{
        align-items: center;
        background: #2a2a2a;
        display: flex;
        flex-direction: column;
        left: 50%;
        margin-left: 0px;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        div{
            background-image: url("https://assets.codingdojo.com/learn_platform/admin/global/table_loader.gif");
            background-size: cover;
            height: 30px;
            margin-bottom: 7px;
            mix-blend-mode: lighten;
            width: 30px;
        }
        span{
            color: rgba(255, 255, 255, 0.88);
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 130%;
        }
    }
}

#program_type_tooltip{
    .tooltip-inner{
        background: #080808 !important;
    }
    .arrow:before{
        border-top-color: #080808 !important;
    }
}