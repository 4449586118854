$Poppins: 'Poppins', sans-serif;
#multi_select_student_popover{
    -webkit-backdrop-filter: blur(10px);
    align-items: center;
    backdrop-filter: blur(10px);
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.24), rgba(255, 255, 255, 0.24)), linear-gradient(180deg, #121212 0%, rgba(18, 18, 18, 0) 100%);
    border-radius: 4px;
    bottom: 50px;
    display: flex;
    height: 70px;
    justify-content: space-between;
    left: 50%;
    min-width: 350px;
    padding: 20px;
    position: absolute;
    transform: translate(-50%, 0%);
    z-index: 9;

    p{
        color: rgba(255, 255, 255, 0.88);
        flex: 1;
        font-family: $Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: normal;
        line-height: 130%;
        margin-bottom: 0px;

        span{
            font-weight: 500;
        }
    }

    button{
        align-items: center;
        background: rgba(204, 224, 255, 0.14);
        border-radius: 2px;
        border: 1px solid #81A0EA;
        box-sizing: border-box;
        color: #FFFFFF;
        display: flex;
        font-family: $Poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        height: 38px;
        justify-content: center;
        line-height: 130%;
        margin-right: 20px;
        min-width: 180px;
        outline: none;
        padding: 10px;

        &:last-child{
            margin-right: 0px;
        }
        
        
        &#export_progress_report_btn{
            width: 214px;

            span{
                background-position: 0px 0px;
            }
        }
        
        &#export_transcript_btn{
            span{
                background-position: 48px 0px;
            }
        }

        &#take_attendance_btn{
            background-color: #81A0EA;

            span{
                background-position: 0px 0px;
            }
        }

        span{
            background-image: url("./../../../../assets/images/admin/student_progress/multi_select_popover_icons.png");
            height: 24px;
            margin-right: 10px;
            width: 24px;
        }
    }
}