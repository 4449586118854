$Poppins: 'Poppins', sans-serif;

#assign_student_via_email_modal{
    left: 50%;
    max-width: none;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 600px;

    .modal-content{
        background: #333333;
        border-radius: 6px;
        box-shadow: 0px 4px 2px rgba(0, 0, 0, 0.05);
        color: rgba(255, 255, 255, 0.88);

        .modal-header{
            border: none;
            height: 51px;
            justify-content: flex-start;
            position: relative;

            h4{
                font-family: 'Poppins', sans-serif;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 16px;
                margin-bottom: 0px;
            }

            button{
                background-color: transparent;
                background-image: url("https://codingdojo_assets.s3.us-east-1.amazonaws.com/learn_platform/admin/edit_course/admin_edit_course_sprite.png");
                background-position: -65px 0px;
                border: none;
                height: 15px;
                outline: none;
                padding: 0px;
                position: absolute;
                right: 22px;
                top: 18px;
                width: 15px;
            }
        }

        .modal-body{
            background-color: #282828;

            .input_details_block{
                margin-bottom: 20px;

                .label_title{
                    color: rgba(255, 255, 255, 0.88);
                    display: block;
                    font: 14px/18px $Poppins;
                    font-weight: 600;
                    margin-bottom: 10px;
                }

                textarea{
                    background: linear-gradient(0deg, rgba(255, 255, 255, 0.24), rgba(255, 255, 255, 0.24)), #121212;
                    border-radius: 4px;
                    border: none;
                    color: rgba(255, 255, 255, 0.58);
                    font: 12px/16px $Poppins;
                    font-weight: 400;
                    max-height: 150px;
                    min-height: 150px;
                    outline: none;
                    padding: 20px;
                    resize: none;
                    width: 100%;
                }

                >span{
                    color: rgba(255, 255, 255, 0.58);
                    display: block;
                    font: 12px/16px $Poppins;
                    font-style: italic;
                    font-weight: 400;
                }
            }
        }

        .modal-footer{
            border-top: none;
            height: 84px;

            .action_btn{
                button{
                    background: #484B50;
                    border-radius: 2px;
                    border: none;
                    color: #81A0EA;
                    font: 14px/18px $Poppins;
                    font-weight: 600;
                    height: 40px;
                    margin-left: 20px;
                    outline: none;
                    padding: 0 15px;

                    &.cancel_btn{
                        background: transparent;
                        color: rgba(255, 255, 255, 0.88);
                    }
                }
            }
        }
    }
}