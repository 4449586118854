.hidden{
    display: none;
}

.border_red{
    border-color: #E1554C !important;  
}

.container{
    max-width: 1309px;
    width: 1309px;
    @media screen and (min-width: 1200px) {
        max-width: 1309px;
        width: 1309px;
    }
}

#root, html, body{
    background-color: #FBFBFB !important;
}

.tooltip{
    .arrow{
        &:before{
            border-top-color: #4B4B4B !important;
        }
    }
    .tooltip-inner{
        background: #4B4B4B;
        border-radius: 4px;
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 18px;
        color: rgba(255, 255, 255, 0.88);
    }
}

.input_error{
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 21px;
    text-align: left;
    color: #E1554C;
    margin-bottom: 6px;
    display: flex;
    text-align: left;
}

.disabled{
    opacity: .5;
    pointer-events: none;
}

body{
    &.is_last_platform_demo{
        .navbar{
            z-index: 1 !important;
        }

        .introjs-helperLayer {
            background: transparent;
        }
        
        .introjs-overlay {
            display: none;
        }
        
        .introjs-helperLayer:before {
            opacity: 0;
            content: '';
            position: fixed;
            width: inherit;
            height: inherit;
            border: 1px solid rgba(33, 33, 33, 0.5);
            border-radius: 4px;
            box-shadow: 0 0 0 1000em rgba(0,0,0, .7);
            opacity: 1;
        }
        
        .introjs-helperLayer:after {
            content: '';
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            position: fixed;
            z-index: 1000;
        }
    }

    /* Intercom widget custom position */ 
    .intercom-launcher, .intercom-app > div, .intercom-messenger{
        right: 80px !important;
    }

    /* Accessibe */ 
    .acsb-trigger{
        height: 48px !important;
        width: 48px !important;
    }

    /* width */
    *::-webkit-scrollbar {
        width: 6px;
        height: 6px;
    }
    /* Track */
    *::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px #E5E5E5; 
        border-radius: 10px;
    }
    /* Handle */
    *::-webkit-scrollbar-thumb {
        background: #E0E0E0;
        border-radius: 10px;
    }
    /* Handle on hover */
    *::-webkit-scrollbar-thumb:hover {
        background: #E0E0E0;
    }
    
    .skeleton_loading{
        animation-duration: 2s;
        animation-fill-mode: forwards;
        animation-iteration-count: infinite;
        animation-name: skeleton_loader;
        animation-timing-function: linear;
        background-image: linear-gradient(to right, #F9FAFB 25%, #E5E7EB 50%, #F9FAFB 75%);
        background-position: -500px 0;
        background-size: 1000px;
    }
    @keyframes skeleton_loader{
        0%{
            background-position: -500px 0
        }
        100%{
            background-position: 500px 0
        }
    }
}

@media only screen and (max-width: 1323px) {
    body.modal-open{
        overflow-x: auto !important;

        .modal{
            position: absolute;
            width: 1309px !important;
            max-width: 1309px !important;

        }
    }
}
@media only screen and (max-width: 1024px){
    body{
        .acsb-trigger{
            bottom: 20px !important;
            right: 20px !important;
        }
    }
}


/* GLOBAL MODAL STYLE FOR MOBILE */
@media only screen and (max-width: 768px){
    body{
        .acsb-trigger{
            bottom: 135px !important;
            right: 5px !important;
        }

        /* Intercom widget custom position mobile view */ 
        .intercom-launcher, .intercom-app > div, .intercom-messenger{
            bottom: 80px !important;
            right: 5px !important;
        }

        #intercom-container > div > div.intercom-messenger-frame{
            bottom: 135px !important;
        }
    }
	body.modal-open{
        height: calc(100vh - calc(100vh - 100%));
        min-height: -webkit-fill-available;
        overflow: hidden !important;
		.modal.show{
            background: #fff;
            padding-left: 0px !important;
            width: 100% !important;
			 .modal-dialog{
                 align-items: unset;
                 margin: 0px;
                 max-width: 100% !important;
                 width: 100% !important;
                .modal-content{
                    border-radius: 0px !important;
                    border: none;
                    box-shadow: none !important;
                    .modal-header{
                        padding: 97px 40px 0px 40px;
                        button{
                            right: 14px !important;
                            top: 21px !important;
                        }
                        h4{
                            font-size: 28px; 
                        }
                    }
                }
			 }
		}
	}
    body.modal-open.is_dark_mode{
		.modal.show{
            background: #121212 !important;
        }
    }
    body{
        .intercom-launcher{
            bottom: 57px;
            height: 30px;
            right: 8px!important;
            width: 30px;
            .intercom-lightweight-app-launcher-icon-open{
                height: 30px;
                width: 30px;
            }
            svg{
                height: 16px;
                width: 16px;
            }
        }
    }
}