$Poppins: "Poppins", sans-serif;
$white: #fff;
$black: #000;
$blue: #2C6BFF;

#edit_course_modal,
#create_course_modal{
    .course_select{
        background: rgba(204, 224, 255, 0.14);
        border-radius: 4px;
        min-height: 40px !important;
        outline: none;
        padding: 0 16px !important;
        &.react-dropdown-select[aria-expanded="true"]{
            border: #81A0EA 1px solid !important;
            .react-dropdown-select-dropdown{
                border: #81A0EA 1px solid !important;
            }
        }
        .react-dropdown-select-no-data{
            color: #81A0EA;
            font-family: $Poppins, sans-serif;
            font-size: 14px;
            padding: 0px;
        }
        
        &.has_value{
            background: rgba(204, 224, 255, 0.14);
            border: none !important;
            .react-dropdown-select-content{
                color: rgba(255, 255, 255, 0.88);
            }
        }

        &.multi_select_dropdown{
            height: 100% !important;
        }
    
        .react-dropdown-select-content{
            color: rgba(255, 255, 255, 0.58);
            font-family: $Poppins;
            font-size: 14px;
            .multi_selected_container{
                max-height: 125px;
                overflow: auto;
            }
            .multi_selected{
                background: #81A0EA;
                border-radius: 4px;
                display: inline-block;
                font: normal 400 12px/210% $Poppins;
                margin: 6px 10px 0 0;
                padding-inline: 8px;
                svg{
                    margin-top: 5px;
                    margin-left: 2px;
                    transform: scale(.9);
                }
                &:last-child{
                    margin-bottom: 6px;
                }
            }
            .multi_select_indicator{
                font-weight: 600;
                margin: 0px 2px 0px 5px;
            }
        }
        .react-dropdown-select-dropdown-handle{
            color: #81A0EA;
            display: flex;
            margin: 0px 0px 0px 3px;
            svg{
                height: 12px;
                width: 12px;
            }
        }
        .react-dropdown-select-dropdown {
            background: #5E6065;
            border-radius: 4px;
            max-height: 200px;
            padding: 16px 10px;
            top: 100%;
            width: 100%;
            span{
                background-color: transparent !important;
                border: none;
                color: rgba(255, 255, 255, 0.88);
                font-family: $Poppins;
                font-size: 14px;
                font-style: normal;
                font-weight: normal;
                line-height: 130%;
                margin-bottom: 0;
                padding: 0px;
                &:not(:last-child){
                    margin-bottom: 27px;
                }
            }
    
            .visibility_dropdown_container{
                ul{
                    list-style: none;
                    margin: 0;
                    padding-left: 0;
                    li{
                        cursor: pointer;
                        margin-bottom: 16px;
                        width: 100%;
                        &:last-child{
                            margin-bottom: 0px;
                        }
                        .checkbox{
                            input[type="checkbox"], .fa-check{
                                display: none;
                            }
                            input[type="checkbox"]:checked ~ label{
                                .checkbox_container{
                                    background-color: $blue;
                                    .fa-check{
                                        color: $white;
                                        display: inline;
                                        font-size: 10px;
                                    }
                                }    
                            }
                            label{
                                align-items: center;
                                cursor: pointer;
                                display: flex;
                                margin-bottom: 0px;
                                width: 100%;
                                .checkbox_container{ 
                                    align-items: center;
                                    background: $white;
                                    border-radius: 4px;
                                    border: 1px solid transparent;
                                    cursor: pointer;
                                    display: flex;
                                    height: 15px;
                                    justify-content: center;
                                    margin-right: 10px;
                                    width: 15px;
                                }                       
                            }
                        }
                        span{
                            font: normal 400 12px/130% $Poppins;
                        }
                    }
                }
            }
        }
    }
}