
#bookmarks_card{
    background: #FFFFFF;
    border-radius: 6px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
    margin-bottom: 30px;
    padding: 31px 16px 40px 18px;
    width: 100%;
    h2{
        color: #152C61;
        font: normal 500 23px/34px 'Poppins';
        margin-bottom: 34px;
    }
    >p{
        color: #152C61;
        font: normal 300 16px/24px 'Poppins', sans-serif;
        text-align: center;
    }
    >ul{
        margin: 0px auto;
        width: 100%;
        >li{
            background: #FFFFFF;
            border-radius: 2px;
            border: 2px solid #DCE6FF;
            box-sizing: border-box;
            margin-bottom: 15px;
            padding: 14px 20px 20px 20px;
            position: relative;
            &:last-child{
                margin-bottom: 0px;
            }
            form{
                position: absolute;
                right: 14px;
                top: 5px;
                button{
                    background-color: transparent;
                    border: none;
                    color: #4CE164;
                    font-size: 26px;
                    outline: none;
                    padding: 0px;
                    &.outline_button{
                        color: #152C61;
                    }
                }
            }
            a{
                align-items: flex-start;
                display: flex;
                text-decoration: none;
                .left_container{
                    margin-right: 20px;
                    min-width: 88px;
                    h5{
                        color: #152C61;
                        font: normal 500 16px/30px 'Poppins', sans-serif;
                        margin-bottom: 11px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap; 
                    }
                    .icons_container{
                        align-items: center;
                        display: flex;
                        span{
                            align-items: center;
                            background-color: #EEC10C;
                            border-radius: 100%;
                            display: flex;
                            height: 30px;
                            justify-content: center;
                            margin-right: 25px;
                            width: 30px;
                            &.todo{
                                background-color: #B286C3;
                                .icon{
                                    background-position: -73px 0px;
                                }
                            }
                            &.lesson{
                                background-color: #EEC10C;
                                .icon{
                                    background-position: -2px 0px;
                                }
                            }
                            &.chapter_survey{
                                background-color: #E06666;
                                .icon{
                                    background-position: -20px 0px;
                                }
                            }
                            &.quiz{
                                background-color: #F78723;
                                .icon{
                                    background-position: -54px 0px;
                                }
                            }
                            .icon{
                                background-image: url("https://assets.codingdojo.com/learn_platform/global/icons.png");
                                background-repeat: no-repeat;
                                height: 18px;
                                width: 18px;
                                &.lesson{
                                    background-position: -73px 0px;
                                }
                                &.chapter_survey{
                                    background-position: -73px 0px;
                                }
                            }
                        }
                        img{
                            height: 33px;
                            width: 28px;
                        }
                    }                        
                }
                .right_container{
                    align-content: space-between;
                    display: flex;
                    flex-wrap: wrap;
                    min-height: 90px;
                    min-width: 50px;
                    ul{
                        display: inline-flex;
                        flex-wrap: wrap;
                        padding-right: 35px;
                        li{
                            color: #152C61;
                            font: normal 300 14px/21px 'Poppins', sans-serif;
                            margin-right: 15px;
                            padding-right: 10px;
                            position: relative;
                            &:last-child{
                                margin-right: 0px;
                                padding-right: 0px;
                                &:after{
                                    display: none;
                                }
                            }
                            &:after{
                                background-image: url("https://assets.codingdojo.com/learn_platform/global/small_arrow_right.png");
                                content: "";
                                display: inline-block;
                                height: 11px;
                                position: absolute;
                                right: -6px;
                                top: 5px;
                                width: 7px;
                            }
                        }
                    }
                    p{
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 3;
                        color: #152C61;
                        display: -webkit-box !important;
                        font: normal 300 14px/21px 'Poppins', sans-serif;
                        margin-bottom: 0px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: normal;
                    }
                }
            }
        }
    }
}

.is_dark_mode{
    #bookmarks_card{
        background-color: #333;
        h2{
            color: #E5E5E5;
        }
        p{
            color: rgba(255, 255, 255, 0.88);
        }
        ul{
            li{
                background-color: transparent;
                border-color: #666666;
                a{
                    div.left_container{
                        h5{
                            color: rgba(255, 255, 255, 0.88);
                        }
                    }
                    div.right_container{
                        ul{
                            li{
                                color: rgba(255, 255, 255, 0.88);
                            }
                        }
                        p{
                            color: rgba(255, 255, 255, 0.88);
                        }
                    }
                }
            }
        }
    }
    @media only screen and (max-width: 768px){
        #bookmarks_card{
            ul{
                li{
                    background-color: #292929;
                    border-color: transparent;
                    border-radius: 3px;
                    form{
                        button{
                            &.outline_button{
                                color: #4CE164;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 768px){
    #bookmarks_card{
        & ~ * {
            display: none !important;
        }
    }
}