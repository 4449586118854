$Poppins: "Poppins", sans-serif;

.modal.show{
    z-index: 9999;
}

#give_assignment_feedback_modal{
    font-family: "Poppins", sans-serif;
    max-width: 600px;
    width: 600px;
    .modal-content{
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), #121212;
        border: none;
        box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.15);
        box-sizing: border-box;

        .modal-header{
            background: linear-gradient(0deg, rgba(255, 255, 255, 0.14), rgba(255, 255, 255, 0.14)), #121212;
            border: none;
            justify-content: flex-start;
            padding: 17px 24px;
            position: relative;

            .modal-title{
                color: rgba(255, 255, 255, 0.88);
                font-family: $Poppins;
                font-size: 14px;
                font-style: normal;
                font-weight: bold;
                line-height: 130%;
            }

            button{
                background-color: transparent;
                border: none;
                outline: none;
                padding: 0px;
                position: absolute;
                right: 17px;
                top: 9px;

                .close_icon{
                    background-image: url("https://assets.codingdojo.com/learn_platform/global/close_icon_new.png");
                    background-position: -61px 0px;
                    display: inline-block;
                    height: 33px;
                    width: 33px;
                }
            }
        }
        .modal-body{
            display: flex;
            flex-wrap: wrap;
            padding: 24px 24px 24px 24px;

            h5{
                color: rgba(255, 255, 255, 0.88);
                font-family: $Poppins;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: 130%;
                margin-bottom: 17px;
                width: 100%;
            }

            ul{
                display: flex;
                flex-wrap: wrap;
                list-style: none;
                margin-bottom: 22px;

                li{
                    color: #E5E5E5;
                    font-family: $Poppins;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: 18px;

                    &:not(:last-child){
                        margin-right: 30px;
                        position: relative;

                        &:after{
                            -webkit-transform: rotate(-45deg);
                            border: solid #E5E5E5;
                            border-width: 0 1.9px 1.9px 0;
                            content: "";
                            display: inline-block;
                            padding: 1.9px;
                            position: absolute;
                            right: -17px;
                            top: 7px;
                            transform: rotate(-45deg);
                        }
                    }
                }
            }

            span{
                font-family: $Poppins;
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                margin-left: 12px;
                text-transform: capitalize;
                &.on-time{
                    color: #05DA80;
                }

                &.out-of-schedule{
                    color: #F59D6B;
                }                
                &.need-review{
                    color: #F56B6B;
                }
            }

            textarea{
                background: linear-gradient(0deg, rgba(255, 255, 255, 0.24), rgba(255, 255, 255, 0.24)), #121212;
                border-radius: 4px;
                border: none;
                color: rgba(255, 255, 255, 0.88);
                font-family: $Poppins;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                height: 300px;;
                line-height: 28px;
                outline: none;
                padding: 12px 16px;
                resize: none;
                width: 100%;

                &::placeholder, &:-ms-input-placeholder, &::-ms-input-placeholder {
                    color: rgba(255, 255, 255, 0.58);
                }
            }
        }

        .modal-footer{
            background: linear-gradient(0deg, rgba(255, 255, 255, 0.14), rgba(255, 255, 255, 0.14)), #121212;
            border: none;
            padding: 20px 24px;
            
            button{
                background: rgba(204, 224, 255, 0.14);
                border-radius: 4px;
                border: none;
                color: #81A0EA;
                font-family: $Poppins;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                height: 40px;
                margin: 0px;
                outline: none;
                padding: 10px 14px;
            }
        }
    }
}
