$Poppins: 'Poppins', sans-serif;
$blue: #81A0EA;
$white: rgba(255, 255, 255, 0.88);

#table_view_setting_modal{
    font-family: $Poppins;
    max-width: 286px;
    .modal-content{
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.24), rgba(255, 255, 255, 0.24)), #121212;
        border-radius: 4px;
        color: #fff;
        .modal-header{
            background: linear-gradient(0deg, rgba(255, 255, 255, 0.14), rgba(255, 255, 255, 0.14)), #121212;
            border: none;
            padding: 17px 25px 10px;
            h4{
                font-size: 14px;
                font-weight: 700;
            }
            button{
                background-color: transparent;
                border: none;
                outline: none;
                padding: 0px;
                position: absolute;
                right: 16px;
                top: 9px;
                .close_icon{
                    background-image: url("./../../../../assets/images/admin/student_progress/close_icon.png");
                    background-position: 10px 9px;
                    background-repeat: no-repeat;
                    display: inline-block;
                    height: 33px;
                    width: 33px;
                }
            }
        }
        .modal-body{
            display: flex;
            justify-content: center;
            padding: 20px 20px 30px;
            form{
                width: 193px;
                /* used for checkbox and radio */
                label{
                    cursor: pointer;
                    display: block;
                    position: relative;
                   
                    &:after{
                        content: attr(data-text);
                        font-size: 12px;
                        margin-left: 12px;
                        vertical-align: middle;
                    }
                    &[disabled]{
                        cursor: not-allowed;
                        opacity: .3;
                    }
                }
                /* radio */
                input[type="radio"]{
                    display: none;
                    &:checked ~ .radio_indicator{
                        border: 4px solid #81A0EA;
                    }
                }
                .radio_indicator{
                    background: #fff;
                    border-radius: 100%;
                    border: 1px solid #A4B0C0;
                    display: inline-block;
                    height: 14px;
                    vertical-align: middle;
                    width: 14px;
                }
                /* checkbox */
                input[type="checkbox"]{
                    display: none;
                    &:checked ~ span{
                        background: #81A0EA;
                    }
                    &:checked ~ svg{
                        opacity: 1;
                    }
                }
                .checkbox_label{
                    span{
                        align-items: center;
                        background: rgba(204, 224, 255, 0.14);
                        border-radius: 4px;
                        cursor: pointer;
                        display: inline-flex;
                        height: 15px;
                        justify-content: center;
                        vertical-align: middle;
                        width: 15px;
                    }
                    svg{
                        font-size: 10px;
                        left: 2px;
                        opacity: 0;
                        position: absolute;
                        top: 8px;
                    }
                }
                div{
                    display: flex;
                    flex-direction: column;
                    padding-left: 19px;
                }
                button{
                    background: rgba(204, 224, 255, 0.14);
                    border-radius: 4px;
                    border: none;
                    color: #81A0EA;
                    font-size: 14px;
                    margin-top: 6px;
                    padding: 9px 0px;
                    width: 100%;
                }
            }
        }
    }
}
