$blue: #81A0EA;
$Poppins: 'Poppins', sans-serif;
$white: #fff;

.filter_container{
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;

    >div{
        margin-bottom: 14px;
        margin-right: 20px;
    }

    button{
        outline: none !important;
        &[type="submit"]{
            align-items: center;
            background: rgba(204, 224, 255, 0.14);
            border-radius: 2px;
            border: 1px solid $blue;
            box-sizing: border-box;
            color: $blue;
            display: flex;
            font-family: $Poppins;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            height: 32px;
            justify-content: center;
            margin-bottom: 14px;
            width: 143px;
        }

        &[type="button"]{
            background-color: transparent;
            border: none;
            color: #81A0EA;
            font-family: $Poppins;
            font-size: 12px;
            font-style: normal;
            font-weight: normal;
            line-height: 130%;
            margin: 0px 0px 15px 14px;
            outline: none;
        }

        &.disable_button{
            opacity: .5;
            pointer-events: none;
            user-select: none;
        }
    }
    
}