#discussion_container{
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    margin: 0px auto;
    text-align: center;
    width: 735px;
    >p{
        color: #828282;
        font-style: italic;
        line-height: 13px;
    }
    .blk{
        border-radius: 2px;
        border: 2px solid #DCE6FF;
        font-weight: 600;
        margin-bottom: 40px;
        padding: 20px;
        text-align: left;
        white-space: pre-wrap;
        
        *{
            color: #152C61;
        }
        p{
            &:last-child{
                margin-bottom: 0px;
            }
        }
    }
    form{
        textarea{
            border-radius: 2px;
            border: 2px solid #505170;
            margin-bottom: 13px;
            min-height: 440px;
            outline: none;
            padding: 20px;
            resize: none;
            width: 100%;
        }
        p{
            color: #2C6BFF;
            margin-bottom: 39px;
            text-align: left;
        }
        button{
            background: #505170;
            border-radius: 6px;
            border: none;
            color: #fff;
            padding: 12px 51px;
        }
    }
}