$Poppins: 'Poppins', sans-serif;

#select_different_start_date_modal{
    font-family: $Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    max-width: 380px;
    .modal-content{
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.24), rgba(255, 255, 255, 0.24)), #121212;
        border-radius: 2px;
        color: rgba(255, 255, 255, 0.58);
        .modal-header{
            align-items: center;
            border-bottom: none;
            flex-direction:column;
            justify-content: center;
            padding-bottom: 16px;
            p{
                margin-bottom: 0px;
                text-align: center;
            }
        }
        p{
            font-weight: 400;
        }
        .modal-body{
            padding: 0px 20px 20px;
            label{
                display: block;
                margin-top: 19px;
                &:first-child{
                    margin-top: 0px;
                }
            }
        }
    }
}