$Poppins: 'Poppins', sans-serif;
$blue: #81A0EA;
$white: rgba(255, 255, 255, 0.88);


#assign_students_stacks_modal{
    max-width: none;
    width: 397px;
    .modal-content{
        background: #333333;
        min-height: 256px;
        .modal-header{
            justify-content: center;
            padding: 22px 23px 21px 23px;

            h4{
                font-weight: 500;
            }
        }

        .modal-body{
            padding: 17px 35px 25px;
            #assign_students_form_note{
                text-align: center;
            }
            #loading_container{
                align-items: center;
                display: flex;
                flex-direction: column;
                left: 50%;
                margin-left: 0px;
                position: absolute;
                top: 50%;
                transform: translate(-50%, -50%);
                width: 50px;
                div{
                    background-image: url("https://assets.codingdojo.com/learn_platform/admin/global/table_loader.gif");
                    background-size: cover;
                    height: 30px;
                    margin-bottom: 7px;
                    mix-blend-mode: lighten;
                    width: 30px;
                }
                span{
                    color: rgba(255, 255, 255, 0.88);
                    font-family: $Poppins;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 130%;
                }
            }

            >div{
                align-items: center;
                display: flex;
                font-family: $Poppins;
                font-size: 14px;
                font-style: normal;
                font-weight: normal;
                justify-content: center;
                margin-block-end: 33px;

                >p{
                    margin-bottom: 0px;
                    text-align: center;

                    span{
                        font-weight: 500;
                    }
                }

                button{
                    align-items: center;
                    background-color: transparent;
                    border: none;
                    color: $blue;
                    display: flex;
                    flex-basis: 74px;
                    flex-grow: 0;
                    flex-shrink: 0;
                    justify-content: space-between;
                    margin-left: 12px;
                    outline: none;

                    svg{
                        transition: all .5s ease;
                        -moz-transition: all .5s ease;
                        -webkit-transition: all .5s ease;
                        -o-transition: all .5s ease;
                        -ms-transition: all .5s ease;
                        -khtml-transition: all .5s ease;
                    }

                    &.show{
                        svg{
                            -webkit-transform: rotate(0deg);
                            -moz-transform: rotate(0deg);
                            -ms-transform: rotate(0deg);
                            -o-transform: rotate(0deg);
                            transform: rotate(180deg);
                        }
                    }
                }
            }

            >ul{
                display: flex;
                flex-wrap: wrap;
                margin-bottom: 0px;
                
                li{
                    display: flex;
                    flex-wrap: wrap;
                    font-family: $Poppins;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: normal;
                    line-height: 140%;
                    margin-right: 5px;
                    &:after{
                        content: ",";
                        display: flex;
                    }

                    &:last-child{
                        &:after{
                            display: none;
                        }
                    }
                }
            }

            form{
                margin-top: 20px;
                .form-group{
                    margin-bottom: 20px;
                    label{
                        color: rgba(255, 255, 255, 0.58);
                        font-family: $Poppins;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 130%;
                        margin-bottom: 13px;
                    }

                    select{
                        background: rgba(204, 224, 255, 0.14);
                        display: block;
                        height: 40px;
                        width: 100%;
                    }

                    .dropdown_container{
                        background: rgba(204, 224, 255, 0.14);
                        border-radius: 2px;
                        min-height: 40px;
                        &#assign_stack_dropdown{    
                            margin-bottom: 17px;
                            ul{
                                z-index: 3 !important;
                            }
                        }
                        
                        .dropdown_toggle{
                            align-items: center;
                            display: flex;
                            padding: 10px;

                            button{
                                background: transparent;
                                border: none;
                                outline: none;

                                &:first-child{
                                    color: $white;
                                    display: inline-block;
                                    flex: 1;
                                    font-family: $Poppins;
                                    font-size: 14px;
                                    font-style: normal;
                                    font-weight: 500;
                                    justify-content: flex-start;
                                    line-height: 140%;
                                    overflow: hidden;
                                    text-align: left;
                                    text-overflow: ellipsis;
                                    white-space: nowrap;
                                    width: 100%;

                                    span{
                                        color: #F56B6B;
                                        margin-left: 5px;
                                    }

                                    span.placeholder{
                                        color: $white;
                                        font-family: $Poppins;
                                        font-size: 14px;
                                        font-style: italic;
                                        font-weight: 300;
                                        line-height: 140%;
                                        opacity: 0.6;
                                    }
                                }

                                &:last-child{
                                    width: 25px;
                                    svg{
                                        color: $white;
                                    }
                                }
                            }

                            input{
                                background: rgba(204, 224, 255, 0.14);
                                border-radius: 4px;
                                border: none;
                                color: $white;
                                flex: 1;
                                font-family: $Poppins;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 500;
                                height: 32px;
                                line-height: 140%;
                                outline: none;
                                padding: 0px 16px;

                                &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
                                    color: #A4B0C0;
                                    font-weight: normal;
                                }
                                  
                                &:-ms-input-placeholder { /* Internet Explorer 10-11 */
                                    color: #A4B0C0;
                                    font-weight: normal;
                                }
                            
                                &::-ms-input-placeholder { /* Microsoft Edge */
                                    color: #A4B0C0;
                                    font-weight: normal;
                                }
                            }

                            .fa-caret-down{
                                margin-right: 7px;
                                cursor: pointer;
                            }
                        }

                        ul{
                            background: #494c50;
                            border-top: 1px solid rgba(255, 255, 255, .1);
                            max-height: 255px;
                            overflow: auto;
                            padding: 8px 0px;
                            position: absolute;
                            width: 326px;
                            z-index: 2;

                            li{

                                &.active{
                                    button{
                                        color: #81A0EA;
                                        font-weight: 500;
                                    }
                                }
                                button{
                                    background-color: transparent;
                                    border: none;
                                    color: $white;
                                    font-family: $Poppins;
                                    font-size: 14px;
                                    font-style: normal;
                                    font-weight: normal;
                                    line-height: 140%;
                                    outline: none;
                                    padding: 8px 16px;
                                    text-align: left;
                                    width: 100%;

                                    span{
                                        color: #F56B6B;
                                        margin-left: 5px;
                                    }
                                }
                            }
                        }
                    }
                }

                #checkbox_container{
                    margin-top: 18px;
                    input{
                        display: none;
                        &:checked ~ label{
                            &:after{
                                background: #81A0EA;
                            }
                            svg{
                                opacity: 1;
                            }
                        }
                    }
                    label{
                        cursor: pointer;
                        font-weight: 400;
                        margin-bottom: 0px;
                        padding-left: 30px;
                        position: relative; 
                        &:after{
                            background: #121212;
                            border-radius: 4px;
                            content: "";
                            display: block;
                            height: 16px;
                            left: 0px;
                            position: absolute;
                            top: 2px;
                            width: 16px;
                        }
                        svg{
                            color: #fff;
                            font-size: 9px;
                            left: 0px;
                            margin-left: 4px;
                            margin-right: 17px;
                            opacity: 0;
                            position: absolute;
                            top: 6px;
                            z-index: 1;
                        }
                    }
                }
                .btn_container{
                    align-items: center;
                    display: flex;
                    justify-content: flex-end;
                    button{
                        background-color: transparent;
                        border: none;
                        color: $white;
                        font-family: $Poppins;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                        height: 40px;
                        line-height: 130%;
                        min-width: 90px;
                        outline: none;

                        &[type="submit"]{
                            background: rgba(204, 224, 255, 0.14);
                            border-radius: 2px;
                            color: $blue;
                            margin-left: 20px;   
                        }
                    }
                }
            }
        }
    }
}
.expand_modal{
    #assign_students_stacks_modal{
        max-width: 750px;
        min-height: 250px;
        width: 100%;
       
        .modal-content{
            background: #292929;
            min-height: 250px;

            .modal-header{
                button{
                    top: 24px;
                }
            }
        }
        h6{
            font-family: $Poppins, sans-serif;
            font-size: 14px;
            &.color_red{
                color: #f56b6b;
            }
            &.color_green{
                color: #05DA80;
            }
        }

        h5{
            font-family: $Poppins, sans-serif;
            font-size: 14px;
            margin-bottom: 27px;
            margin-top: 10px;
            text-align: center;
            
        }
        p{
            font-family: $Poppins, sans-serif;
            font-size: 12px;
            margin-bottom: 15px;
            &:last-child{
                margin-bottom: 0px;
            }
            &.note{
                color: rgba(255, 255, 255, 0.4);  
                font-style: italic;  
                &.additional_margin {   
                    margin-bottom: 30px;    
                }
            }
            a{
                color: #81A0EA; 
            }
        }
        table{
            margin-bottom: 15px;
            width: 100%;

            label {
                cursor: pointer;
                margin-bottom: 0px;
                white-space: pre-wrap;
                word-break: break-all;
            }

            .checkbox{
                display: flex;
                justify-content: left;
                
                input[type="checkbox"]{
                    display: none;
                }
                .fa-check{
                    display: none;
                }
                input[type="checkbox"]:checked ~ label{
                    .checkbox_container{
                        background-color: #81A0EA;
                        .fa-check{
                            color: #fff;
                            display: inline;        
                            font-size: 10px;
                        }
                    }    
                }
                label{
                    align-items: center;
                    color: #E5E5E5;
                    display: flex;
                    font-size: 12px;

                    .checkbox_container{ 
                        align-items: center;
                        background: rgba(204, 224, 255, 0.14);
                        border-radius: 4px;
                        border: 1px solid transparent;
                        cursor: pointer;
                        display: flex;
                        height: 15px;
                        justify-content: center;
                        width: 15px;
                    }                       
                }
            }

            thead{
                tr{
                    th{     
                        background: linear-gradient(0deg, rgba(255, 255, 255, 0.24), rgba(255, 255, 255, 0.24)), #121212;
                        border-bottom: 1px solid #5D5D5D;
                        font-size: 12px;
                        font-weight: 500;
                        padding: 10px;
                        &:first-child{
                            width: 25px;
                        }
                        &:nth-child(2){
                            width: 180px;
                        }
                        &.no_instructor{
                            width: 47% !important;
                        }
                    }
                }
            }
            tbody{
                tr{
                    td{
                        background: linear-gradient(0deg, rgba(255, 255, 255, 0.14), rgba(255, 255, 255, 0.14)), #121212;
                        border-bottom: 1px solid #474747;
                        color: rgba(255, 255, 255, 0.88);
                        font-family: $Poppins, sans-serif;
                        font-size: 12px;
                        font-weight: 400;
                        padding: 10px;
                        word-break: break-word;
                        &:last-child{
                            max-width: 240px;
                        }
                    }
                }
            }
        }
    }
}
