.assignment_file_container{
    span.download_loader{
        background-image: url(https://assets.codingdojo.com/learn_platform/global/loading.svg);
        background-size: cover;
        display: inline-block;
        height: 17px;
        vertical-align: middle;
        width: 17px;
    }
}

