div.event_type{
    border-radius: 2px;
    height: 100%;
    opacity: 0.5;
    position: absolute;
    top: 0px;
    z-index: 0;
    &.break{
        background: rgba(210, 81, 255, 0.2);
        border: 2px solid rgba(210, 81, 255, 0.35);
    }
    &.holiday{
        background: rgba(129, 160, 234, 0.2);
        border: 2px solid rgba(129, 160, 234, 0.35);
    }
    &.training{
        background: rgba(255, 181, 36, 0.2);
        border: 2px solid rgba(255, 181, 36, 0.35);
    }
}