$Poppins: "Poppins", sans-serif;

.show_typeform_modal{
    #new_survey_modal{
        height: 1080px;
        max-width: 1000px !important;
        width: 1000px !important;
        .modal-content{
            .modal-body{
                height: 100%;
                padding: 45px 55px;
                iframe{
                    height: 100%;
                }
            }
        }
    }
}

#new_survey_modal{
    max-width: 600px;
    width: 600px;
    .modal-content{
        background: #FFFFFF;
        border-radius: 6px;
        box-shadow: 0px 4px 2px rgba(0, 0, 0, 0.05);
        height: 100%;
        .modal-header{
            padding: 15px 15px 0px 15px;
            border-bottom: none;
            button{
                align-items: center;
                background: transparent;
                border: none;
                display: flex;
                justify-content: center;
                margin-left: auto;
                outline: none;

                .close_icon{
                    background-image: url("https://assets.codingdojo.com/learn_platform/global/close_icon_new.png");
                    background-position: -7px -7px;
                    display: inline-block;
                    height: 18px;
                    width: 18px;
                }
            }
        }
        .modal-body{
            padding: 5px 40px 40px 40px;
            text-align: center;

            img{
                margin-bottom: 26px;
            }

            h2{
                color: #152C61;
                font-family: $Poppins;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
                margin-bottom: 23px;
                text-align: center;
            }

            .btn-primary{
                color: #FFFFFF;
                font: normal 500 16px/24px 'Poppins', sans-serif;
                height: 46px;
                text-align: center;
                width: 250px;
            }

            p{
                color: #152C61;
                font-family: $Poppins;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
                text-align: center;
                margin-bottom: 24px;
            }
        }

    }
}

.is_dark_mode{
    #new_survey_modal{
        .modal-content{
            .modal-header{
                padding: 7px 15px 0px 15px;
                button{
                    .close_icon{
                        background-image: url("https://assets.codingdojo.com/learn_platform/global/close_icon_new.png");
                        background-position: -30px 0px;
                        height: 26px;
                        width: 26px;
                    }
                }
            }
            .modal-body{
                h2{
                    color: rgba(255, 255, 255, 0.88);
                }

                button{
                    background-color:#81A0EA;
                    border-color: #81A0EA;
                }

                p{
                    color: rgba(255, 255, 255, 0.88);
                    a{
                        color: #81A0EA;
                    }
                }
            }
        }
    }
}
@media only screen and (max-width: 768px){
    #new_survey_modal{
        .modal-content{
            .modal-body{
                padding: 18px 20px 56px;
                .btn-primary{
                    max-width: 350px;
                    width: 100%;
                }
                iframe{
                    *{
                        width: 100% !important;
                    }
                }
            }
        }
    }
    .show_typeform_modal {
        #new_survey_modal{
            height: 100% !important;
            max-width: 100% !important;
            min-height: 100vh !important;
            width: 100% !important;
            .modal-content{
                .modal-body{
                    padding: 0px;
                    iframe{
                        width: 100%;
                    }
                }
            }
        }
    }
}