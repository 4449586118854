$Poppins: "Poppins", sans-serif;
#delete_assignment_modal{
    max-width: 680px;
    width: 680px;

    .modal-content{
        border: none;
        
        .modal-header{
            border-bottom: none;
            justify-content: center;
            padding: 27px 0px 3px 0px;

            .modal-title{
                color: #152C61;
                font-family: $Poppins;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 24px;
            }
    
            button{
                background-color: transparent;
                background-image: url("https://assets.codingdojo.com/learn_platform/global/close_icon_new.png");
                border: none;
                display: inline-block;
                height: 33px;
                outline: none;
                padding: 0px;
                position: absolute;
                right: 26px;
                top: 26px;
                width: 33px;
            }
        }

        .modal-body{
            p{
                font-family: $Poppins;
                font-size: 16px;
                font-style: normal;
                font-weight: normal;
                line-height: 24px;
                margin: 0px auto;
                text-align: center;
                width: 526px;
            }

            .checkbox{
                display: flex;
                justify-content: center;
                margin-top: 18px;

                .fa-check, input{
                    display: none;
                }
                input:checked ~ label{
                    .checkbox_container{
                        background-color: #2C6BFF;
                        border-color: #2C6BFF;

                        .fa-check{
                            color: #fff;
                            display: inline;
                            font-size: 10px;
                        }
                    }    
                }
                label{
                    align-items: center;
                    cursor: pointer;
                    display: flex;
                    margin-bottom: 0px;
                    .checkbox_container{ 
                        align-items: center;
                        border-radius: 2px;
                        border: 1px solid #152C61;
                        cursor: pointer;
                        display: flex;
                        height: 16px;
                        justify-content: center;
                        margin-right: 10px;
                        width: 16px;
                    }
                    >span{
                        color: #152C61;
                        font-family: $Poppins;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: normal;
                        line-height: 21px;
                        text-align: center;
                    }                        
                }
            }
        }

        .modal-footer{
            border: none;
            justify-content: center;
            padding: 0px 0px 27px 0px;

            button{
                color: #152C61;
                font-family: $Poppins;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                height: 36px; 
                line-height: 24px;
                text-align: center;
                width: 130px;
                
                &[type="submit"]{
                    background: #E1554C;
                    border-radius: 6px;
                    color: #fff;
                    margin-left: 20px;
                }
            }
        }
    }
}
html{
    body.modal-open.is_dark_mode.is_show_delete_assignment_modal{
        .modal.show{
            background: rgba(45, 45, 45, 0.45) !important;
            #delete_assignment_modal{
                .modal-content{
                    .modal-header{
                        .modal-title{
                            color: #E5E5E5;    
                        }
                    }
                    .modal-body{
                        p{
                            color: #E5E5E5;
                        }
                        .checkbox{
                            input:checked ~ label{
                                .checkbox_container{
                                    background-color: #81A0EA;
                                    border-color: #81A0EA;
                                }    
                            }
                            label{
                                .checkbox_container{ 
                                    border-color: #E5E5E5;
                                }
                                >span{
                                    color: #E5E5E5;
                                }                        
                            }
                        }
                    }
            
                    .modal-footer{
                        button{
                            color: #E5E5E5;
        
                            &[type="submit"]{
                                color: #fff;
                            }
                        }
                    }
                }
            }
        }
    }
}
@media only screen and (max-width: 768px){
    html{
        body.is_show_delete_assignment_modal{
            &.modal-open{
                .modal.show{
                    background: rgba(45, 45, 45, 0.45);
                    #delete_assignment_modal{
                        align-items: center;
                        margin: 0px auto;
                        max-width: 350px !important;
                        .modal-content{
                            border-radius: 6px !important;
                            .modal-body{
                                flex: unset;
                                padding: 10px 29px;
                                p{
                                    width: 100%;
                                }
                                .checkbox{
                                    label{
                                        > span{
                                            margin-left: 13px;
                                            text-align: left;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

