#upload_resume_modal{
    font-family: 'Poppins', sans-serif;
    max-width: 791px;
    width: 791px;
    .modal-content{
        background: #FFFFFF;
        border-radius: 6px;
        box-shadow: 0px 4px 2px rgba(0, 0, 0, 0.05);
        .modal-header{
            border: none;
            justify-content: center;
            padding: 61px 40px 19px 40px;
            position: relative;
            h4{
                color: #152C61;
                font: normal 500 36px/54px 'Poppins', sans-serif;
                margin-bottom: 0px;
            }
            button{
                background-color: transparent;
                border: none;
                outline: none;
                padding: 0px;
                position: absolute;
                right: 26px;
                top: 26px;
            }
        }
        .modal-body{
            padding: 20px 80px 59px;
            text-align: center;
            img{
                margin-bottom: 45px;
            }
            p{
                color: #152C61;
                font: normal normal 16px/24px 'Poppins', sans-serif;
                margin: 0px auto 19px auto;
                text-align: center;
                width: 358px;
            }
            small{
                color: #212529;
                display: block;
            }
            label{
                border-radius: 2px;
                border: 2px dashed #BDBDBD;
                box-sizing: border-box;
                display: block;
                margin: 0px auto 40px;
                max-width: 555px;
                padding: 16px;
                &.border_red{
                    border: 2px dashed #8A2020 !important;
                }
            }
            button{
                background: #2C6BFF;
                border-radius: 6px;
                border: none;
                color: #fff;
                height: 46px;
                padding: 0px 30px;
                width: 210px;
            }
        }
    }
}