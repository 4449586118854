#todo_task_items{
    clear: both;
    margin: 0px auto;
    padding-top: 15px;
    width: 83%;

    *{
        color: #152C61;
        font-family: 'Poppins', sans-serif;
        text-shadow: none;
    }

    input[type=checkbox]{
        display: none;
    }


    li{
        display: block;
        line-height: 1.8em;
        margin: 0 10px;
        padding: 0px !important;

        label{
            cursor: pointer;
            display: flex;
            margin-bottom: 5px;
            max-width: 100%;
            
            .item_checkbox{
                align-items: center;
                border-radius: 3px;
                border: 2px solid #152C61;
                display: flex;
                height: 20px;
                justify-content: center;
                margin-top: 6px;
                width: 20px;

                svg{
                    display: none;
                    font-size: 12px;
                }
            }
    
            p{
                flex: 1;
                font-family: 'Poppins', sans-serif;
                font-size: 16px;
                font-style: normal;
                font-weight: 300;
                line-height: 200% !important;
                padding: 0px 0px 0px 20px;
            }
        }

        input[type="checkbox"]:checked ~ label {
            p{
                opacity: .3;
                text-decoration: line-through;
            }

            .item_checkbox{
                background-color: #152C61;
                svg{
                    color: #fff;
                    display: flex;
                }
            }
        }
    }
}