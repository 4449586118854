@mixin applyEventColor($color){
    position: relative;

    &.approved{

        &.start_only{
            background-color: $color;
            border-radius: 50%;
            border: none;
            cursor: pointer;
        }

        &.event_start{
            background-color: $color;
            cursor: pointer;
        }

        &.event_end{
            background-color: $color;
        }

        &.event_between{
            background-color: rgba($color, .1);
            border: none;
        }

        &.multiple_events{
            z-index: 1;
            &.cohort_start_as_diff_event{
                &::after,
                &::before{
                    box-shadow: inset 0 -2px #1DAA33 !important;
                }
            }
            &.holiday_as_diff_event{
                &::after,
                &::before{
                    box-shadow: inset 0 -2px #81A0EA !important;
                }
            }
            &.break_as_diff_event{
                &::after,
                &::before{
                    box-shadow: inset 0 -2px #D251FF !important;
                }
            }
            &.training_as_diff_event{
                &::after,
                &::before{
                    box-shadow: inset 0 -2px #FFB524 !important;
                }
            }
            &::before{
                box-shadow: inset 0 -2px $color;
                border-radius: 50%;
                bottom: -3px;
                content: "";
                height: 32px;
                opacity: .5;
                position: absolute;
                width: 32px;
                z-index: -1;
            }

            &::after{
                box-shadow: inset 0 -2px $color;
                border-radius: 50%;
                bottom: -6px;          
                content: "";
                height: 32px;
                opacity: .2;
                position: absolute;
                width: 32px;
                z-index: -1;  
            }
        }
    }

    &.not_approved{

        &.start_only{
            border-radius: 50%;
            border: 2px solid $color;
            background-color: rgba($color, .2);
            cursor: pointer;
        }
    
        &.event_start{
            border: 2px solid $color;
            background-color: rgba($color, .35);
            border-right: none;
            cursor: pointer;
        }

        &.event_end{
            border: 2px solid $color;
            background-color: rgba($color, .35);
            border-left: none;
        }

        &.event_between{
            border: 2px solid $color;
            background-color: rgba($color, .2);
            border-left: none;
            border-right: none;
        }

        &.multiple_events{
            z-index: 1;
            &.cohort_start_as_diff_event{
                &::after,
                &::before{
                    box-shadow: inset 0 -2px #1DAA33 !important;
                }
            }
            &.holiday_as_diff_event{
                &::after,
                &::before{
                    box-shadow: inset 0 -2px #81A0EA !important;
                }
            }
            &.break_as_diff_event{
                &::after,
                &::before{
                    box-shadow: inset 0 -2px #D251FF !important;
                }
            }
            &.training_as_diff_event{
                &::after,
                &::before{
                    box-shadow: inset 0 -2px #FFB524 !important;
                }
            }
            &::before{
                box-shadow: inset 0 -2px $color;
                border-radius: 50%;
                bottom: -5px;
                content: "";
                height: 32px;
                opacity: .5;
                position: absolute;
                width: 32px;
                z-index: -1;
            }

            &::after{
                box-shadow: inset 0 -2px $color;
                border-radius: 50%;
                bottom: -8px;          
                content: "";
                height: 32px;
                opacity: .2;
                position: absolute;
                width: 32px;
                z-index: -1;  
            }
        }
    }
}

#month_calendar_container{
    color: rgba(255, 255, 255, .88);
    font-family: "Poppins", sans-serif;
    font-size: 12px;
    margin-inline: 5px;
    padding: 20px;
    width: 264px;

    #month_name{
        font-family: "Poppins", sans-serif;
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 18px;
        text-align: center;
    }

    #weekdays{
        display: grid;
        font-family: "Poppins", sans-serif;
        grid-template-columns: repeat(7, 1fr);
        margin-bottom: 9px;
        place-items: center;
    }

    #dates{
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        row-gap: 4px;
    }

    .date{
        align-items: center;
        color: white;
        display: flex;
        font-family: "Poppins", sans-serif;
        height: 32px;
        justify-content: center;
        text-align: center;
    }

    .one_day_event{
        border-radius: 50%;
    }

    .event_start{
        border-radius: 8px 0 0 8px;
    }

    .event_end{
        border-radius: 0 8px 8px 0;
    }

    .cohort_start_range{
        border-radius: 8px 0 0 8px !important;
    }

    .cohort_end_range{
        background-color: #1DAA33 !important;
        border-radius: 0 8px 8px 0 !important;
        border: none !important;
    }

    .cohort_start{
        @include applyEventColor(#1DAA33);
    }

    .holiday{
        @include applyEventColor(#81A0EA);
    }

    .break{
        @include applyEventColor(#D251FF);
    }

    .training{
        @include applyEventColor(#FFB524);
    }
}