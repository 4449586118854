$Poppins: 'Poppins', sans-serif;

#apply_filter_modal{
    left: 50%;
    max-width: none;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 363px;
    .modal-content{
        font-family: $Poppins;
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.24), rgba(255, 255, 255, 0.24)), #121212;
        border-radius: 6px;
        box-shadow: 0px 4px 2px rgba(0, 0, 0, 0.05);
        color: rgba(255, 255, 255, 0.88);
        .modal-body{
            align-items: center;
            border: 1px solid rgba(204, 224, 255, 0.14);
            display: flex;
            flex-direction: column;
            padding: 20px;
            h2{
                font-size: 14px;
            }
            p{
                font-size: 12px;
                text-align: center;
            }
            button{
                background: rgba(204, 224, 255, 0.14);
                border-radius: 2px;
                border: none;
                color: #81A0EA;
                font-size: 12px;
                padding: 7px 18px;
            }
        }
    }
}