#pending_calendar_updates_modal{
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    max-height: 904px;
    max-width: 869px;
    overflow: auto;

    button:focus{
        outline: 5px transparent;
    }
    
    .modal-content{
        background-color: #292929;
        border-radius: 4px;
        border: none;

        > div{
            margin: 20px 14px 40px 20px;
            max-height: 735px;
            overflow: auto;
        }
    }

    p{
        margin: 0;
    }

    header{
        align-items: center;
        background-color: #292929;
        border-radius: 2px 2px 0 0;
        color: white;
        display: flex;
        flex-wrap: wrap;
        font-size: 14px;
        justify-content: space-between;

        .modal_title{
            background: linear-gradient(0deg, rgba(255, 255, 255, 0.14), rgba(255, 255, 255, 0.14)), #121212;
            border-radius: 2px 2px 0px 0px;
            color: rgba(255, 255, 255, .88);
            font-family: "Poppins", sans-serif;
            font-weight: 700;
            margin-bottom: 20px;
            padding: 16px 24px 14px;
            width: 100%;

            .close_button{
                background-color: transparent;
                background: url("https://assets.codingdojo.com/learn_platform/admin/course_calendar/course_calendar_icon.png") no-repeat -66px -5px;
                border: none;
                display: inline-block;
                height: 14px;
                position: absolute;
                right: 23px;
                top: 19px;
                width: 14px;
            }
        }

        p:nth-child(2){
            font-family: "Poppins", sans-serif;
            margin-left: 24px;
        }

    }

    header > button, .approve_button{
        background-color: #484B50;
        border-radius: 4px;
        border: none;
        color: #81A0EA;
        font-family: "Poppins", sans-serif;
        font-size: 14px;
        font-weight: 600;
        margin-right: 19px;
        padding: 9px 10px 8px 9px;
    }

    table{
        background-color: #333;
        color: #F6F9FD;
        width: 100%;

        thead{
            background: linear-gradient(0deg, rgba(255, 255, 255, 0.24), rgba(255, 255, 255, 0.24)), #121212;
            border-bottom: 1px solid #626262;
            position: sticky;
            top: 0;
            
            th{
                font-family: "Poppins", sans-serif;
                font-size: 12px;
                font-weight: 500;
                padding: 11px 20px;

                &:nth-child(3), &:nth-child(4){
                    text-align: center;
                }
            }
        }

        tbody{
            tr{
                border-bottom: 1px solid rgb(78, 78, 78);

                &:last-child{
                    border: none;
                }
            }

            td{
                padding: 18px 20px;
                font-family: "Poppins", sans-serif;

                &:nth-child(1), &:nth-child(2){
                    font-size: 12px;
                    font-weight: 500;
                }

                &:nth-child(3), &:nth-child(4){
                    text-align: center;
                }

                &:nth-child(3){
                    width: 17%;
                }

                &:nth-child(4){
                    width: 42%;
                }
            }

            .view_button, .reject_button{
                background-color: transparent;
                border: none;
                color: rgba(#7893d4, .88);
                font-family: "Poppins", sans-serif;
            }

            .view_button{
                margin-right: 20px;
            }

            .reject_button{
                margin-right: 16px;
            }

            .approve_button{
                margin-right: unset;
            }
        }
    }

    ::-webkit-scrollbar-track {
        box-shadow: none;
    }

    ::-webkit-scrollbar-thumb {
        background: #595959;
    }
}