$Poppins: "Poppins", sans-serif;

#student_checklist_container{
    -webkit-transition: all .5s;
    background: #121212;
    bottom: 0;
    height: calc(100% - 73px);
    padding-right: 30px;
    position: absolute;
    right: -100%;
    transition: all .5s;  
    width: calc(100% - 30px);
    z-index: 9999;

    &.show{
        -webkit-transition: all .5s;
        right: 0;
        transition: all .5s;  
    }

    #checklist_breadcrumb{
        align-items: center;
        display: flex;
        padding: 25px 0px 19px 0px;

        >button{
            background-color: transparent;
            border: none;
            color: #E5E5E5;
            margin-right: 25px;
            outline: none;
        }

        ul{
            display: flex;
            list-style: none;
            margin-bottom: 0px;
            padding: 0px;

            li{
                color: #B3B3B3;
                font-family: $Poppins;
                font-size: 14px;
                font-style: normal;
                font-weight: normal;
                line-height: 21px;
                margin-right: 24px;

                &:not(:last-child){
                    position: relative;

                    &:after{
                        background: #B3B3B3;
                        border-radius: 100%;
                        content: "";
                        display: flex;
                        height: 4px;
                        position: absolute;
                        right: -14px;
                        top: 8px;
                        width: 4px;
                    }
                }

                &:last-child{
                    color: #FFFFFF;
                    font-weight: 500;
                }
            }
        }
    }

    .loading{
        align-items: center;
        display: flex;
        flex-direction: column;
        left: 50%;
        position: sticky;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 50px;
    
        div{
            background-image: url("https://codingdojo_assets.s3.us-east-1.amazonaws.com/learn_platform/admin/global/table_loader.gif");
            background-size: cover;
            height: 30px;
            margin-bottom: 7px;
            mix-blend-mode: lighten;
            width: 30px;
        }
    
        span{
            color: rgba(255, 255, 255, 0.88);
            font-family: $Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 130%;
        }
    }

    #student_checklist_content{
        background: #2E2E2E;
        display: flex;
        flex-wrap: wrap;
        height: calc(100% - 97px);
        overflow: auto;
        padding: 20px;

        #student_name_container, #track_name_container{
            &#student_name_container{
                padding-right: 20px;
                width: 40%;

                span{
                    align-items: center;
                    background: linear-gradient(0deg, rgba(255, 255, 255, 0.14), rgba(255, 255, 255, 0.14)), #121212;
                    border-radius: 4px;
                    color: #E5E5E5;
                    display: flex;
                    font-family: $Poppins;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    height: 50px;
                    line-height: 28px;
                    padding: 0px 16px;
                    width: 100%;
                }
            }

            &#track_name_container{
                width: 60%;

                .react-dropdown-select{
                    background: linear-gradient(0deg, rgba(255, 255, 255, 0.14), rgba(255, 255, 255, 0.14)), #121212;
                    border-radius: 4px;
                    border: 1px solid #4B4B4B !important;
                    height: 50px;
                    padding: 0px 16px !important;
                }
                .react-dropdown-select-dropdown{
                    margin-top: 17px;
                    padding: 13px 17px 16px 17px;

                    span{
                        border: none;
                        font-size: 12px;
                        margin-bottom: 3px;
                        padding: 4px 0px;

                        &:hover{
                            background-color: transparent;
                            color: #81A0EA;
                        }

                        &:last-child{
                            margin-bottom: 0px;
                        }

                        &.react-dropdown-select-item-selected{
                            background-color: transparent;
                            color: #81A0EA;
                            font-weight: 600;
                        }
                    }
                }
            }

            h5{
                color: rgba(255, 255, 255, 0.88);     
                font-family: $Poppins;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: 130%; 
                margin-bottom: 12px;
            }       
        }

        #assignment_type_progress_list{
            column-gap: 20px;
            display: flex;
            justify-content: space-between;
            list-style: none;
            margin-top: 20px;
            padding: 0px;
            width: 100%;

            >li{
                background: linear-gradient(0deg, rgba(255, 255, 255, 0.14), rgba(255, 255, 255, 0.14)), #121212;
                border-radius: 2px;
                display: flex;
                flex-wrap: wrap;
                flex: 1;
                justify-content: space-between;
                padding: 16px 20px 14px 20px;

                /* Practice */ 
                &:nth-child(2){
                    h5{
                        span{
                            background-position: -35px 25px;
                        }
                    }

                }
                
                /* Optional */
                &:nth-child(3){
                    h5{
                        span{
                            background-position: 26px 26px;
                        }
                    }

                    ul{
                        li{
                            color: #05DA80;

                            &:before{
                                background-color: #05DA80;
                            }
                        }
                    }

                    .completion_progress_container{
                        .on_time_progress{
                            background-color: #05DA80; 
                        }
                    }
                }

                h5{
                    color: rgba(255, 255, 255, 0.88);
                    display: flex;
                    font-family: $Poppins;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    justify-content: space-between;
                    line-height: 21px;
                    margin-bottom: 15px;
                    text-transform: uppercase;
                    width: 100%;

                    span{
                        background-color: #4B4B4B;
                        background-image: url(https://codingdojo_assets.s3.us-east-1.amazonaws.com/learn_platform/user/assignment/assignment_icons.png);
                        background-position: 3px 25px;
                        border-radius: 100%;
                        display: flex;
                        height: 28px;
                        margin-top: -5px;
                        width: 28px;
                    }
                }

                ul{
                    height: 69px;
                    list-style: none;
                    padding-left: 0px;
                    width: 100%;

                    li{
                        color: #81A0EA;     
                        font-family: $Poppins;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 21px;
                        margin-bottom: 9px;
                        padding-left: 12px;
                        position: relative;

                        &:before{
                            background-color: #81A0EA; 
                            border-radius: 100%;
                            content: "";
                            height: 6px;
                            left: 0px;
                            position: absolute;
                            top: 7px;
                            width: 6px;
                        }

                        &:nth-child(2){
                            color: #F2994A;
                        }
                    }
                }

                .completion_progress_container{
                    background: rgba(255, 255, 255, 0.58);
                    border-radius: 6px;
                    display: flex;
                    height: 6px;
                    margin-bottom: 9px;
                    overflow: hidden;
                    width: 100%;

                    div{
                        border-radius: 6px;
                        height: 100%;
                    }

                    .on_time_progress{
                        background: #81A0EA;
                        z-index: 1;
                    }

                    .late_progress{
                        background: #F2994A;
                        margin-left: -3px;
                        position: relative;

                        &:after{
                            background-color: #F2994A;
                            border-bottom-right-radius: 6px;
                            border-top-right-radius: 6px;
                            content: "";
                            display: flex;
                            height: 100%;
                            position: absolute;
                            right: -3px;
                            width: 5px;
                        }
                    }
                }

                >span{
                    color: rgba(255, 255, 255, 0.88);                    
                    font-family: $Poppins;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: normal;
                    line-height: 18px;
                }
            }
        }

        h4{
            color: rgba(255, 255, 255, 0.88);
            font-family: $Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 130%;
            margin: 4px 0px 10px 0px;
            width: 100%;
        }

        #assignment_breakdown_container{
            background: linear-gradient(0deg, rgba(255, 255, 255, 0.14), rgba(255, 255, 255, 0.14)), #121212;
            border-radius: 4px;
            border: 1px solid #4B4B4B;
            padding: 20px;
            width: 100%;

            .card{
                background: linear-gradient(0deg, rgba(255, 255, 255, 0.24), rgba(255, 255, 255, 0.24)), #121212;
                border-radius: 4px;
                border: none;
                margin-bottom: 20px;
                overflow: unset;
                width: 100%;

                &:last-child{
                    margin-bottom: 0px;
                }

                .card-header{
                    align-items: center;
                    background-color: transparent;
                    border: none;
                    cursor: pointer;
                    display: flex;
                    justify-content: space-between;
                    padding: 15px 15px 15px 20px;
                    .left_container{
                        align-items: center;
                        display: flex;
                        img{
                            height: 30px;
                            margin-right: 20px;
                            width: 27px;
                        }
                        h5{
                            color: #E5E5E5;
                            font: normal 600 14px/22px "Poppins", sans-serif;
                            margin-bottom: 0px;
                        }
                    }
                    .right_container{
                        align-items: center;
                        display: flex;

                        .core_on_time_label{
                            color: #B3B3B3;
                            font-family: $Poppins;
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 18px;
                            margin-right: 10px;
                        }

                        .badge{
                            background: rgba(204, 224, 255, 0.14);
                            border-radius: 4px;
                            color: #81A0EA;
                            font-size: 14px;
                            font-weight: 600;
                            margin-right: 15px;
                            padding: 6px 16px;
                            span:first-child{
                                margin-right: 10px;
                            }
                        }

                        button{
                            background-color: transparent;
                            border: none;
                            outline: none;
                            padding: 10px;
                            span{
                                border-color: #81A0EA transparent transparent transparent;
                                border-style: solid;
                                border-width: 4px 4px 0 4px;
                                display: flex;
                                height: 0;
                                width: 0;
                            }
                        }
                    }
                }

                .collapse, .collapsing{
                    padding: 0px 20px 20px 20px;
                    
                    #assignment_list_header{
                        display: flex;
                        list-style: none;
                        margin-bottom: 7px;
                        padding: 0px;
                        width: 100%;

                        li{
                            color: #B3B3B3;
                            font-family: $Poppins;
                            font-size: 12px;
                            font-style: normal;
                            font-weight: normal;
                            line-height: 18px;
                            text-align: center;
                            /* Assignment */ 
                            &:nth-child(1){
                                width: 30%;
                            }
                            /* Status */ 
                            &:nth-child(2){
                                width: 160px;
                            }
                            /* Type */ 
                            &:nth-child(3){
                                width: 75px;
                            }
                            /* Due Date */ 
                            &:nth-child(4){
                                width: 110px;
                            }
                            /* Track End Date */ 
                            &:nth-child(5){
                                width: 140px;
                            }
                            /* Submitted */ 
                            &:nth-child(6){
                                width: 160px;
                            }
                            /* Time Spent */ 
                            &:nth-child(7){
                                width: 130px;
                            }
                            /* File and Link */ 
                            &:nth-child(8), &:nth-child(9){
                                width: 50px;
                            }
                        }
                    }

                    #assignment_list_body{
                        list-style: none;
                        margin: 0px;
                        padding: 0px;
                        width: 100%;

                        .assignment_item{
                            border-radius: 4px;

                            &:hover{
                                background: rgba(204, 224, 255, 0.14);
                            }
                            &.active{
                                background: rgba(204, 224, 255, 0.14);
                                padding-bottom: 14px;

                                .assignment_item_details{
                                    border-bottom: 1px solid #6B6D71;
                                }
                            }

                            &.highlight_assignment{
                                animation-name: blinkingBackground;
                                animation-duration: .5s;
                                animation-iteration-count: 3;
                                animation-timing-function: linear;
                            }
                            .assignment_item_details{
                                border-bottom: 1px solid #5A5A5A;
                                display: flex;
                                list-style: none;
                                padding: 0px;
                                width: 100%;

                                li{
                                    color: #E5E5E5;
                                    font-family: $Poppins;
                                    font-size: 12px;
                                    font-style: normal;
                                    font-weight: normal;
                                    line-height: 18px;
                                    padding: 19px 0px;
                                    text-align: center;

                                    /* Assignment */ 
                                    &:nth-child(1){
                                        text-align: left;
                                        width: 30%;
                                    }
                                    /* Status */ 
                                    &:nth-child(2){
                                        width: 160px;
                                    }
                                    /* Type */ 
                                    &:nth-child(3){
                                        width: 75px;
                                    }
                                    /* Due Date */ 
                                    &:nth-child(4){
                                        width: 110px;
                                    }
                                    /* Track End Date */ 
                                    &:nth-child(5){
                                        width: 140px;
                                    }
                                    /* Submitted */ 
                                    &:nth-child(6){
                                        width: 160px;
                                    }
                                    /* Time Spent */ 
                                    &:nth-child(7){
                                        width: 130px;
                                    }
                                    /* File and Link */ 
                                    &:nth-child(8), &:nth-child(9){
                                        width: 50px;
                                    }

                                    .assignment_name_container{
                                        padding: 0px 20px 0px 30px;

                                        a{
                                            color: #E5E5E5;
                                            font-family: $Poppins;
                                            font-size: 12px;
                                            font-style: normal;
                                            font-weight: normal;
                                            line-height: 18px;
                                            text-align: center; 
                                        }

                                        .feedback_icon{
                                            background-color: transparent;
                                            background: url("https://assets.codingdojo.com/learn_platform/admin/student_progress/assignment_checklist_icons.png") no-repeat -49px 0px;
                                            border: 0px;
                                            height: 13px;
                                            margin-left: 5px;
                                            opacity: 1;
                                            outline: none;
                                            width: 16px;

                                            &.with_feedback{
                                                background-position: -33px 0px;
                                            }
                                        }
                                    }

                                    >div:not(.assignment_name_container){
                                        display: flex;
                                        justify-content: center;
                                    }

                                    .react-dropdown-select{
                                        background-color: transparent;
                                        min-height: auto !important;
                                        outline: none;
                                        padding: 0px !important;
                                        width: auto;
                        
                                        &.on-time{
                                            .react-dropdown-select-content{
                                                span{
                                                    color: #05DA80;
                                                }
                                            }
                                        }
                        
                                        &.out-of-schedule{
                                            .react-dropdown-select-content{
                                                span{
                                                    color: #F59D6B;
                                                }
                                            }
                                        }

                                        &.need-review{
                                            .react-dropdown-select-content{
                                                span{
                                                    color: #F56B6B;
                                                }
                                            }
                                        }
                        
                                        .react-dropdown-select-content{
                                            span{
                                                font-family: $Poppins;
                                                font-size: 12px;
                                                font-style: normal;
                                                font-weight: normal;
                                            }

                                            .react-dropdown-select-input{
                                                display: none;
                                            }
                                        }
                                        .react-dropdown-select-dropdown-handle{
                                            color: #E5E5E5;
                                            display: flex;
                                            margin: 0px 0px 0px 4px;
                                            svg{
                                                height: 12px;
                                                width: 12px;
                                            }
                                        }
                        
                                        .react-dropdown-select-dropdown {
                                            background: #5E6065;
                                            border-radius: 4px;
                                            min-width: 144px;
                                            padding: 10px 20px;
                                            text-align: left;
                                            top: 20px;
                                            width: 144px;
                        
                                            span{
                                                background-color: transparent !important;
                                                border: none;
                                                color: rgba(255, 255, 255, 0.58);
                                                font-family: $Poppins;
                                                font-size: 12px;
                                                font-style: normal;
                                                font-weight: normal;
                                                line-height: 130%;
                                                padding: 0px;
                        
                                                &:not(:last-child){
                                                    margin-bottom: 10px;
                                                }
                        
                                                &.react-dropdown-select-item-selected{
                                                    color: #81A0EA;
                                                    font-weight: 600;
                                                }
                                            }
                                        }
                                    }
                                    .edited_assignment_button{
                                        background-color: transparent;
                                        border: none;
                                        color: #81A0EA;
                                        display: flex;
                                        padding: 0px;

                                        svg{
                                            margin-left: 5px;
                                            margin-top: 2px;                                            
                                        }                                    
                                    }

                                    .file_icon{
                                        background: url("https://assets.codingdojo.com/learn_platform/admin/student_progress/assignment_checklist_icons.png") no-repeat 0px 0px;
                                        background-color: transparent;
                                        border: none;
                                        display: flex;
                                        height: 16px;
                                        margin: 0px auto;
                                        opacity: 1;
                                        outline: none;
                                        width: 16px;
                                    }

                                    .link_icon{
                                        background: url("https://assets.codingdojo.com/learn_platform/admin/student_progress/assignment_checklist_icons.png") no-repeat -16px 0px;
                                        display: flex;
                                        height: 16px;
                                        margin: 0px auto;
                                        opacity: 1;
                                        width: 16px;
                                    }
                                }    
                            }

                            .submitted_assignment_history{
                                margin: 13px auto 0px auto;
                                tbody{
                                    tr{
                                        td{
                                            color: #FFFFFF;
                                            font-family: $Poppins;
                                            font-size: 12px;
                                            font-style: normal;
                                            font-weight: normal;
                                            height: 30px;
                                            line-height: 130%;
                                            &:first-child{
                                                padding-right: 18px;
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        @keyframes blinkingBackground{
                            0%		{ background-color: transparent}
                            50%     { background-color: rgba(204, 224, 255, 0.14)}
                            100%	{ background-color: transparent}
                        }
                    }
                }
            }
        }
    }
}