



.admin_modal{
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    .modal-content{
        background: #2A2A2A;
        border-radius: 6px;
        box-shadow: 0px 4px 2px rgba(0, 0, 0, 0.05);
        color: rgba(255, 255, 255, 0.88);
        .modal-header{
            background: #333333;
            border: none;
            justify-content: end;
            padding: 17px 23px;
            position: relative;
            h4{
                font-family: 'Poppins', sans-serif;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 16px;
                margin-bottom: 0px;
            }
            button{
                background-color: transparent;
                background-image: url("https://codingdojo_assets.s3.us-east-1.amazonaws.com/learn_platform/admin/edit_course/admin_edit_course_sprite.png");
                background-position: -65px 0px;
                border: none;
                height: 15px;
                outline: none;
                padding: 0px;
                position: absolute;
                right: 22px;
                top: 17px;
                width: 15px;
            }
        }
        .modal-footer{
            background: #333333;
            border: none;
            padding: 16px 20px;
            button{
                background: #494B50;
                border-radius: 2px;
                border: none;
                color: #81A0EA;
                font-size: 14px;
                height: 40px;
                outline: none;
                width: 193px;
            }
        }
    }
}

#customize_view_modal, #export_to_csv_modal{
    .modal-content{
        .modal-body{
            padding: 23px 23px 17px;
            h6{
                font-size: 12px;
                font-weight: 400;
                margin-bottom: 19px;
            }

            ul{
                margin-bottom: 0px;
                padding-left: 0;
                li{
                    display: inline-block;
                    margin-bottom: 18px;
                    vertical-align: top;
                    width: 47%;
                    &:nth-child(odd){
                        margin-right: 25px;
                    }
                    &:last-child{
                        margin-bottom: 0px;
                    }
                    input[type="checkbox"]{
                        display: none;
                    }
                    input[type="checkbox"]:checked ~ span.indicator{
                        svg{
                            display: inline-block;
                        }
                    }
                    span.indicator{
                        align-items: center;
                        background: #414348;
                        border-radius: 4px;
                        color: #81A0EA;
                        display: inline-flex;
                        font-size: 10px;
                        height: 15px;
                        justify-content: center;
                        margin-right: 10px;
                        margin-top: 3px;
                        vertical-align: top;
                        width: 15px;
                        svg{
                            display: none;
                        }
                    }
                    label{
                        font-size: 14px;
                        font-weight: 500;
                        margin-bottom: 0px;
                        cursor: pointer;
                        width: 174px;
                        span{
                            color: rgba(255, 255, 255, 0.58);
                            display: block;
                            font-size: 12px;
                            font-weight: 400;
                        }
                    }
                }
            }
        }

        #data_exporting_container{
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 40px;
            
            span{
                background-image: url("./../../../../assets/images/admin/rostering/export_loader.gif");
                background-size: cover;
                display: flex;
                height: 30px;
                margin-bottom: 7px;
                mix-blend-mode: lighten;
                width: 30px;
            }
            p{
                color: rgba(255, 255, 255, 0.88);
                font-family: 'Poppins', sans-serif;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 130%;
                margin: 13px 0px 20px 0px;
            }
            button{
                font-family: 'Poppins', sans-serif;
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 130%;
                color: #81A0EA;
                border: none;
                background-color: transparent;
                outline: none;
            }
        }
    }
}
