$Poppins: "Poppins", sans-serif;

#student_assignment_wrapper{
    padding: 20px 20px 7px !important;

    .status{
        align-items: center;
        border-radius: 2px;
        display: flex;
        font: 12px/16px $Poppins;
        height: 20px;
        justify-content: center;

        &.green{
            background: rgba(84, 204, 176, 0.4);
            color: #05DA80;
            width: 65px;
        }

        &.orange{
            background: rgba(255, 198, 86, 0.4);
            color: #FFC656;
            width: 117px;
        }

        &.red{
            background: rgba(217, 75, 75, 0.4);
            color: #F56B6B;
            width: 90px;
        }
    }

    .student_assignment_header{
        align-items: center;
        border-bottom: 1px solid #333333;
        display: flex;
        justify-content: flex-start;
        margin-bottom: 7px;
        padding-bottom: 18px;

        p{
            color: #fff;
            font: 12px/16px $Poppins;
            font-weight: 400;
            margin: 0 10px 0 0;

            >span{
                font-weight: 600;
            }
        }
    }
}

#give_assignment_feedback{
    .assignment_feedback_header{
        align-items: center;
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.14), rgba(255, 255, 255, 0.14)), #121212;
        display: flex;
        height: 51px;
        justify-content: flex-start;
        padding: 0 20px 0 0;

        .close_assignment_feedback{
            background: none;
            border: none;
            outline: none;
            margin-right: 10px;

            svg{
                color: rgba(255, 255, 255, 0.88);
            }
        }

        h3{
            color: rgba(255, 255, 255, 0.88);
            font: 14px/18px $Poppins;
            font-weight: 700;
            margin: 0 0 3px 0;
        }
    }

    .assignment_feedback_body{
        background-color: #292929;
        padding: 16px 24px;

        .student_name{
            color: rgba(255, 255, 255, 0.88);
            display: block;
            font: 14px/18px $Poppins;
            font-weight: 600;
            margin-bottom: 19px;
        }

        .breadcrumbs{
            font: 12px/14px $Poppins;
            font-weight: 300;
            margin-bottom: 24px;

            span{
                margin-left: 10px;

                &:first-child{
                    margin-left: 0;
                }

                svg{
                    font-size: 10px;
                    margin-left: 6px;
                }

                &.green{
                    color: #05DA80;
                }
            }
        }

        #student_assignment_feedback{
            background: linear-gradient(0deg, rgba(255, 255, 255, 0.24), rgba(255, 255, 255, 0.24)), #121212;
            border-radius: 4px;
            border: none;
            color: rgba(255, 255, 255, 0.58);
            font: 14px/18px $Poppins;
            font-weight: 500;
            max-height: 300px;
            min-height: 300px;
            outline: none;
            padding: 20px;
            width: 100%;
        }
    }

    .assignment_feedback_footer{
        display: flex;

        >button{
            background: rgba(204, 224, 255, 0.14);
            border-radius: 2px;
            border: none;
            color: #81A0EA;
            font: 14px/18px $Poppins;
            font-weight: 600;
            height: 40px;
            margin: 20px 0 0 auto;
            width: 68px;
        }
    }
}