$Poppins: "Poppins", sans-serif;
$blue: #2C6BFF;
$white: #fff;

.table_container#course_table{
    border-radius: 4px;
    height: 100%;
    margin-top: 0px;
    overflow: auto;
    position: relative;
    width: 100%;
    #table_loading_container{
        align-items: center;
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.14), rgba(255, 255, 255, 0.14)), #121212;
        display: flex;
        flex-direction: column;
        left: 50%;
        margin-left: 135px;
        position: fixed;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 50px;
    
        div{
            background-image: url("https://assets.codingdojo.com/learn_platform/admin/global/table_loader.gif");
            background-size: cover;
            height: 30px;
            margin-bottom: 7px;
            mix-blend-mode: lighten;
            width: 30px;
        }
    
        span{
            color: rgba(255, 255, 255, 0.88);
            font: normal 500 14px/130% $Poppins;
        }
    }
    *::-webkit-scrollbar {
        height: 9px;
        width: 9px;
    }
    .sticky-table{
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.14), rgba(255, 255, 255, 0.14)), #121212;
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.07);
        width: 100%;
        &.table_loading{
            .sticky-table-table{
                height: 100%;
                #no_results_found{
                    align-items: center;
                    justify-content: center;
                    width: 50px;
                }
            }
        }
        .checkbox{
            display: flex;
            justify-content: center;
            input[type="checkbox"]{
                display: none;
            }
            .fa-check{
                display: none;
            }
            input[type="checkbox"]:checked ~ label{
                .checkbox_container{
                    background-color: $blue;
                    .fa-check{
                        color: $white;
                        display: inline;
                        font-size: 10px;
                    }
                }    
            }
            label{
                align-items: center;
                cursor: pointer;
                display: flex;
                margin-bottom: 0px;
                .checkbox_container{ 
                    align-items: center;
                    background: $white;
                    border: 1px solid transparent;
                    cursor: pointer;
                    display: flex;
                    height: 15px;
                    justify-content: center;
                    width: 15px;
                }                       
            }
        }
        .sticky-table-table{
            width: 100%;
        }
        .sticky-table-row{
            /* Table Head */
            &:nth-child(1){
                .sticky-table-cell{
                    background: linear-gradient(0deg, rgba(255, 255, 255, 0.24), rgba(255, 255, 255, 0.24)), #121212;
                    color: rgba(255, 255, 255, 0.6);
                    font: normal 600 12px/130% $Poppins;
                    &:first-child{
                        cursor: unset;
                    }
                    &:nth-child(2){
                        width: unset;
                    }
                    &:last-child{
                        cursor: unset;
                    }
                    span{
                        display: inline-block;
                        padding-bottom: 4px;
                        vertical-align: bottom;
                    }
                    .sort_icon_container{
                        cursor: pointer;
                        display: inline-flex;
                        flex-direction: column;
                        margin-left: 10px;
                        svg{
                            color: rgba(255, 255, 255, 0.6);
                            height: 12px;
                            width: 10px;
                            &:first-child{
                                transform: translateY(1.5px) scale(1.2);
                            }
                            &:last-child{
                                transform: translateY(-1.5px) scale(1.2);
                            }
                            &.fa-caret-up.sort_desc{
                                color: #81A0EA !important;
                            }
                            &.fa-caret-down.sort_asc{
                                color: #81A0EA !important;
                            }
                        }
                    }
                    
                }
            }
            /* Table Body */
            &:nth-child(n+2){
                height: 86px;
                &:nth-child(even){
                    .sticky-table-cell{
                        background: #3D3D3D ;
                    }
                }
                &:nth-child(odd){
                    .sticky-table-cell{
                        background: #444444 ;
                    }
                }
                .sticky-table-cell{
                    background: #333333;
                    border-top: 1px solid #5D5D5D;
                    color: $white;
                    font: normal 400 14px/130% $Poppins;
                    &:nth-child(2){
                        cursor: pointer;
                        .course_name{
                            align-items: center;
                            color: $white;
                            display: flex;
                            font: normal 400 14px/130% $Poppins;
                            margin-bottom: 6px;
                            span{
                                background: #595959;
                                border-radius: 20px;
                                color: rgba(255, 255, 255, 0.8);
                                font: normal 400 8px/130% $Poppins;
                                margin-left: 20px;
                                padding-block: 4px;
                                padding-inline: 12px;
                                text-align: center;
                                &:nth-child(2){
                                    margin-left: 5px;
                                }
                            }
                        }
                        .course_description{
                            align-items: center;
                            color: rgba(255, 255, 255, 0.5);
                            display: flex;
                            font: normal 400 12px/130% $Poppins;
                            margin-bottom: 6px;
                        }
                    }
                    &:nth-child(3){
                        .course_category{
                            align-items: center;
                            color: rgba(255, 255, 255, 0.5);
                            display: flex;
                            font: normal 400 12px/130% $Poppins;
                            margin-top: 4px;
                        }
                    }
                    &:last-child{
                        position: relative;
                    }
                }
            }
        }
    }
}

#needs_translation_popover{
    background-color: #282828;
    border: none;
    color: #fff;
    font-family: "Poppins", sans-serif;
    padding: 2px 5px;
    .arrow::after{
        border-right-color: #282828;
    }
}