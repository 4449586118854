$black: #000;
$blue: #2C6BFF;
$border_gray: #F5F5F5;
$dark_blue: #152C61;
$gray: #707070;
$none: transparent;
$orange: #EF7E1B;
$red: #E1554C;
$Poppins: "Poppins", sans-serif;
$white: #fff;


.user_details{
    align-items: center;
    display: flex;
    margin-bottom: 20px;
    position: relative;
    width: 100%;

    .normal_details, .anonymous{
        display: flex;
        align-items: center;
    }
    img{
        border-radius: 50px;
        border: 2px solid #FFFFFF;
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.07);
        box-sizing: border-box;
        height: 48px;
        margin-right: 16px;
        width: 48px; 
    }

    .user_type{
        align-items: center;
        border-radius: 4px;
        display: flex;
        font-family: $Poppins;
        font-size: 12px; 
        font-style: normal;
        font-weight: 500;
        height: 26px;
        justify-content: center;
        line-height: 18px;
        margin-right: 10px;
        text-transform: capitalize;

        &.student{
            background: #EBF1FF;
            color: $blue;
            width: 84px;
        }
        &.instructor{
            background: #FDEFE2;
            color: $orange;
            width: 96px;
        }
        &.anonymous{
            background: #F0F0F0;
            color: $gray;
            width: 139px;
        }
    }

    .user_name{
        color: rgba(0, 0, 0, 0.6);
        cursor: pointer;
        font-family: $Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        margin-right: 12px;
    }

    .date_posted{
        color: rgba(0, 0, 0, 0.6);
        font-family: $Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: normal;
        line-height: 24px;
    }

    .is_edited_label{
        color: rgba(0, 0, 0, 0.6);
        font-family: $Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: normal;
        line-height: 24px;
        margin-left: 5px;
    }
}

.voting_container{
    align-items: center;
    display: flex;
    flex-direction: column;
    flex: none;
    justify-content: center;
    margin-right: 30px;
    width: 52px;

    button{
        align-items: center;
        background-color: $none;
        border-radius: 2px;
        border: none;
        display: flex;
        height: 20px;
        justify-content: center;
        outline: none !important;
        width: 20px;
        &:hover{
            background: #F0F0F0;
        }
        
        &.up_vote_btn{
            &.selected{
                background: #C2D4FF;
                svg{
                    color: $blue;
                }
            }
        }
        
        &.down_vote_btn{
            &.selected{
                background: #FCEEEE;
                svg{
                    color: $red;
                }
            }
        }

        svg{
            color: #5E6871;
            font-size: 14px;
        }
    }

    span{
        color: $dark_blue;
        font-family: $Poppins;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 30px;
        margin: 8px 0px 5px 0px;
    }
}

.post_container{
    flex: 1;

    h5{
        color: $dark_blue;
        font-family: $Poppins;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 30px;
        margin-bottom: 17px;
        word-break: break-word;
    }

    .post_description{
        color: $dark_blue;
        font-family: $Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: 24px;

        .show_full_answer_description_btn{
            background-color: transparent;
            border: none;
            color: $blue;
            font-family: $Poppins;
            font-size: 16px;
            font-style: normal;
            font-weight: 300;
            line-height: 24px;
            outline: none;
            padding: 0px;
        }

        a{
            color: $blue;
        }

        img{
            cursor: pointer;
            max-height: 200px;
            max-width: 100%;
        }

        p{
            margin-bottom: 0px;
            word-break: break-word;
        }

        .answer_description{
            white-space: break-spaces;
        }

        pre{
            background-color: #23241f;
            border-radius: 3px;
            color: #f8f8f2;
            margin-bottom: 5px;
            margin-top: 5px;
            overflow: visible;
            padding: 5px 10px;
        }
        .ql-syntax{
            background: #F8FAFF;
            border: 1px solid #DEE5EF;
            color: #DF477D;
        }

        ul{
            list-style-type: disc;
        }

        ul, ol{
            margin-bottom: 0px;

            li{
                margin-bottom: 10px;
            }
        }
    }

    .menu_container{
        align-items: center;
        display: flex;
        margin-top: 20px;

        button{
            &.answers_btn{
                align-items: center;
                background-color: $none;
                border: none;
                color: $gray;
                display: flex;
                font-family: $Poppins;
                font-size: 16px;
                font-style: normal;
                font-weight: normal;
                line-height: 24px;
                margin-right: 24px;
                outline: none;
                padding: 0px;

                .btn_icon{
                    background-image: url(./../../../../assets/images/courses/assignment_forum_icons_sprite.png);
                    background-position: -72px 0px;
                    display: flex;
                    height: 24px;
                    margin-right: 9px;
                    width: 24px;
                }
            }

            &.view_answer_btn{
                align-items: center;
                background-color: $none;
                border: none;
                color: $blue;
                display: flex;
                font-family: $Poppins;
                font-size: 16px;
                font-style: normal;
                font-weight: normal;
                line-height: 24px;
                margin-right: 24px;
                outline: none;
                padding: 0px;

                .btn_icon{
                    background-image: url(./../../../../assets/images/courses/assignment_forum_icons_sprite.png);
                    background-position: 24px 0px;
                    display: flex;
                    height: 24px;
                    margin-right: 9px;
                    width: 24px;
                }
            }

            &.answer_btn{
                align-items: center;
                background-color: $none;
                border: none;
                color: $blue;
                display: flex;
                font-family: $Poppins;
                font-size: 16px;
                font-style: normal;
                font-weight: normal;
                line-height: 24px;
                margin-right: 24px;
                outline: none;
                padding: 0px;

                .btn_icon{
                    background-image: url(./../../../../assets/images/courses/assignment_forum_icons_sprite.png);
                    background-position: -2px -27px;
                    display: flex;
                    height: 24px;
                    margin-right: 9px;
                    width: 24px;
                }
            }
            &.share_btn{
                align-items: center;
                background-color: $none;
                border: none;
                color: $gray;
                display: flex;
                font-family: $Poppins;
                font-size: 16px;
                font-style: normal;
                font-weight: normal;
                line-height: 24px;
                margin-right: 24px;
                outline: none;
                padding: 0px;

                .btn_icon{
                    background-image: url(./../../../../assets/images/courses/assignment_forum_icons_sprite.png);
                    background-position: -96px 0px;
                    display: flex;
                    height: 24px;
                    margin-right: 9px;
                    width: 24px;
                }
            } 


            &.edit_btn{
                align-items: center;
                background-color: $none;
                border: none;
                color: $blue;
                display: flex;
                font-family: $Poppins;
                font-size: 16px;
                font-style: normal;
                font-weight: normal;
                line-height: 24px;
                margin-right: 24px;
                outline: none;
                padding: 0px;

                .btn_icon{
                    background-image: url(./../../../../assets/images/courses/assignment_forum_icons_sprite.png);
                    background-position: -2px -27px;
                    display: flex;
                    height: 24px;
                    margin-right: 9px;
                    width: 24px;
                }
            }

            &.delete_btn{
                align-items: center;
                background-color: $none;
                border: none;
                color: $gray;
                display: flex;
                font-family: $Poppins;
                font-size: 16px;
                font-style: normal;
                font-weight: normal;
                line-height: 24px;
                margin-right: 24px;
                outline: none;
                padding: 0px;

                &.is_admin_btn{
                    -webkit-transition: opacity .3s;
                    margin-right: 0px;
                    opacity: 0;
                    position: absolute;
                    right: 0;
                    top: 4px;
                    transition: opacity .3s;  

                    span{
                        display: none;
                    }
                    .btn_icon{
                        &:hover{
                            background-position: -264px -27px;
                        }
                    }
                }

                .btn_icon{
                    background-image: url("./../../../../assets/images/courses/assignment_forum_icons_sprite.png");
                    background-position: 48px 0px;
                    display: flex;
                    height: 24px;
                    margin-right: 9px;
                    width: 24px;
                }
            } 

            &.endorse_btn{
                align-items: center;
                background-color: $none;
                border: none;
                color: $gray;
                display: flex;
                font-family: $Poppins;
                font-size: 16px;
                font-style: normal;
                font-weight: normal;
                line-height: 24px;
                opacity: 0;
                outline: none;
                padding: 0px;
                position: absolute;
                right: 44px;
                top: 4px;

                &.answer_endorsed{
                    opacity: 1;
                    right: 0;

                    .btn_icon{
                        background-position: -32px -27px;
                        &:hover{
                            background-position: -32px -27px;
                        }
                    }

                    p{
                        color: #333333;
                        display: flex;
                        font-family: $Poppins;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: normal;
                        line-height: 18px;
                        margin-bottom: 0px;
                        
                        span{
                            color: $orange;
                            display: flex;
                            font-weight: 500;
                            margin-left: 5px;
                        }
                    }
                }
                p{
                    display: none;
                }

                .btn_icon{
                    background-image: url(./../../../../assets/images/courses/assignment_forum_icons_sprite.png);
                    background-position: -63px -27px;
                    display: flex;
                    height: 24px;
                    margin-right: 9px;
                    width: 24px;
                    &:hover{
                        background-position: -33px -27px;
                    }
                }
            } 
        }

        >p{
            align-items: center;
            color: $gray;
            display: flex;
            font-family: $Poppins;
            font-size: 16px;
            font-style: normal;
            font-weight: normal;
            line-height: 24px;
            margin-bottom: 0px;

            i{
                background-image: url(./../../../../assets/images/courses/assignment_forum_icons_sprite.png);
                display: flex;
                height: 24px;
                margin-right: 9px;
                width: 24px; 

                &.student_icon{
                    background-position: 0px 0px;
                }

                &.instructor_icon{
                    background-position: -24px 0px;
                }
            }
            
            span{
                margin-right: 4px;
                
                &.student_name{
                    color: $blue;
                    font-weight: 500;
                }

                &.instructor_name{
                    color: $orange;
                    font-weight: 500;
                }
            }
        }
    }
}

#assignment_forum_container{
    form{
        margin-top: 17px;
    
        .form_head_container{
            align-items: center;
            display: flex;
            margin-bottom: 13px;
    
            >p{
                color: rgba(0, 0, 0, 0.6);
                font-family: $Poppins;
                font-size: 12px;
                font-style: normal;
                font-weight: normal;
                line-height: 18px;
                margin-bottom: 0px;
                margin-right: 12px;
                
                img{
                    border-radius: 100%;
                    border: 2px solid #FFFFFF;
                    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.07);
                    box-sizing: border-box;
                    height: 32px;
                    margin-right: 15px;
                    width: 32px;
                }
            }
    
            .forum_post_type_dropdown{
                background: #F8FAFF;
    
                &.show{
                    .dropdown-toggle{
                        .arrow_down{
                            -webkit-transform: rotate(225deg);
                            -webkit-transition: all .3s;
                            margin-top: 3px;
                            transform: rotate(225deg);
                            transition: all .3s;  
                        }
                    }
                }
    
                .dropdown-toggle{
                    align-items: center;
                    border-color: #2C6BFF;
                    border-radius: 20px;
                    box-shadow: none;
                    color: rgba(0, 0, 0, 0.6);
                    display: flex;
                    font-family: "Poppins", sans-serif;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: normal;
                    height: 24px;
                    justify-content: center;
                    line-height: 18px;
                    outline: none;
                    padding: 0px .75rem;
                    text-transform: capitalize;
            
                    &:after{
                        display: none;
                    }
    
                    svg{
                        color: $dark_blue;
                        margin-right: 6px;
                    }
            
                    .arrow_down{
                        -webkit-transform: rotate(45deg);
                        -webkit-transition: all .3s;
                        border: solid rgba(0, 0, 0, 0.6);
                        border-width: 0 2px 2px 0;
                        display: inline-block;
                        margin-left: 9px;
                        margin-top: -4px;
                        padding: 3px;
                        transform: rotate(45deg);
                        transition: all .3s;  
                    }
                }
    
                .dropdown-menu{
                    background: #F8FAFF;
                    border-radius: 6px;                            
                    border: 1px solid #DEE5EF;
                    box-sizing: border-box;
                    min-width: 116px;
                    width: 116px;
    
                    .dropdown-item{
                        color: rgba(0, 0, 0, 0.6);
                        font-family: $Poppins;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: normal;
                        line-height: 18px;
                        outline: none;
                        text-transform: capitalize;
                        
                        &.active, &:active, &:focus{
                            background-color: $none;
                        }
                    }
                }
            }
        }
    
        >input{
            background: #F5F8FF;
            border-radius: 6px;
            border: none;
            box-shadow: none;
            color: $dark_blue;
            font-family: $Poppins;
            font-size: 16px;
            font-style: normal;
            font-weight: 300;
            height: 60px;
            line-height: 24px;
            margin-bottom: 20px;
            outline: none;
            padding: 20px 25px;
            width: 100%;
        }
    
        .button_container{
            display: flex;
            justify-content: flex-end;
            margin-top: 24px;
    
            button{
                border: none;
                font-family: $Poppins;
                font-size: 16px;
                font-style: normal;
                font-weight: normal;
                line-height: 24px;
                outline: none;
    
                &.disabled{
                    background-color: #BDBDBD !important;
                    pointer-events: none;
                }
                
                &[type="button"]{
                    background: $none;
                    color: rgba(0, 0, 0, 0.6);
                }
    
                &[type="submit"]{
                    background: #2C6BFF;
                    border-radius: 6px;
                    color: $white;
                    height: 36px;
                    margin-left: 13px;
                    width: 183px;
    
                    &.disabled{
                        background: #BDBDBD;
                        pointer-events: none;
                    }
                }
            }
        }
    }
}

#share_question_tooltip{
    height: 26px;
    margin-left: 10px; 
    width: 236px;

    .arrow{
        display: none;
    }    

    .tooltip-inner{
        color: $white;
        font-family: $Poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        max-width: 236px;
    }
}

#assignment_forum_question_container{
    align-items: flex-start;
    border-top: 1px solid $border_gray;
    display: flex;
    flex-wrap: wrap;
    margin: 0px 55px;
    padding-bottom: 42px;
    padding-top: 35px;

    .voting_container{
        margin-right: 11px;
    }

    .post_container{
        form{
            margin-top: 45px;
            word-break: break-word;
        }
    }
}
#assignment_forum_question_container, #forum_question_answers_container{
    form{
        .form_head_container{
            background: #F5F8FF;
            border-top-left-radius: 6px;
            border-top-right-radius: 6px;
            border: 1px solid #C2D4FF;
            border-bottom: none;
            margin-bottom: 0px;
            min-height: 80px;
            padding: 0px 32px;
        }
        .quill{
            background-color: $white;
            border-bottom-left-radius: 6px;
            border-bottom-right-radius: 6px;
            border-top: 0px;
            border: 1px solid #C2D4FF;
            padding: 0px;
            padding: 14px 32px;
            .ql-syntax{
                background: #F8FAFF;
                border: 1px solid #DEE5EF;
                color: #DF477D;
            }
            .ql-toolbar{
                .ql-formats{
                    &:first-child{
                        button{
                            border-radius: 2px;
                            &:hover{
                                background: #F0F0F0;
                            }
                            &:active, &.ql-active{
                                background: #C2D4FF;
                                color: #2C6BFF;
                            }
                        }
                    }
                }
            }
        }

        .warning_msg{
            color: $red;
        }

        .button_container{
            button{
                &[type="submit"]{
                    width: 113px;
                }
            }
        }
    }
} 

#forum_question_answers_container{
    border-top: 1px solid $border_gray;
    margin: 0px 55px;
    
    >p{
        color: $gray;
        font-family: $Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: normal;
        line-height: 24px;
        margin: 21px 0px 39px 0px;
        
        span{
            color: $gray;
            font-family: $Poppins;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
        }
    }

    #answers_list{
        list-style: none;
        padding-left: 0px;
        
        >li{
            align-items: flex-start;
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 40px;
            position: relative;

            &:hover{
                .post_container {
                    .menu_container {
                        button{
                            &.endorse_btn.answer_endorsed.is_admin_btn, &.endorse_btn.is_admin_btn{
                                right: 52px !important;
                            }

                            &.endorse_btn.is_owner_btn{
                                right: 0px;
                                
                                .btn_icon{
                                    margin-right: 0px;
                                }
                            }

                            &.delete_btn.is_admin_btn{
                                -webkit-transition: opacity .3s;
                                opacity: 1;
                                transition: opacity .3s;  
                            }

                            &.endorse_btn{
                                -webkit-transition: opacity .3s;
                                opacity: 1;
                                transition: opacity .3s;  
                            }
                        }
                    }
                }
            }

            &:last-child{
                margin-bottom: 0px;
            }

            form{
                margin-top: 0px;
                width: 100%;
            }

            .user_details{
                img{
                    height: 32px;
                    margin-right: 20px;
                    width: 32px;
                }
            }

            .voting_container{
                margin-right: 25px;
                width: 28px;
            }
        }
    }


    >button{
        align-items: center;
        background-color: $none;
        border: none;
        color: #333333;
        display: flex;
        font-family: $Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        margin: 49px auto 0px auto;
        outline: none;
        text-align: center;

        svg{
            color: $blue;
            font-size: 14px;
            margin-right: 10px;
        }
    }
}

/* DARK MODE */ 
.is_dark_mode{
    .no_question_available_container{
        button{
            background: rgba(204, 224, 255, 0.14);
            color: #81A0EA;
        }
    }
    .voting_container{
        .up_vote_btn, .down_vote_btn  {
            &:hover{
                background: rgba(204, 224, 255, 0.14);
            }
            svg{
                color: #707070;
            }
        }
        .down_vote_btn  {
            &.selected{
                background: rgba(217, 75, 75, 0.4);
                svg{
                    color: #E1554C;
                }
            }
        }

        .up_vote_btn{
            &.selected{
                background: #C0D0F4;
                svg{
                    color: #81A0EA;
                }
            }
        }

        span{
            color: #CCCCCC;
        }
    }

    .post_container{
        h5{
            color: #CCCCCC;
        }

        .post_description{
            color: #CCCCCC;
            *{
                color: #CCCCCC;
            }

            a, .show_full_answer_description_btn{
                color: #81A0EA;
            }
            .ql-syntax{
                background: rgba(204, 224, 255, 0.14);
                border: 1px solid rgba(204, 224, 255, 0.14);
                color: #EF7E1B;
            }
        }
        .menu_container{
            button{
                &.answer_btn{
                    color: #81A0EA;

                    .btn_icon{
                        background-position: -2px 27px;
                    }
                }
                
                &.share_btn{
                    color: #707070;
                }
                
                &.view_answer_btn{
                    color: #81A0EA;
                    .btn_icon{
                        background-position: 24px 54px;
                    }
                }

                &.endorse_btn{
                    p{
                        color: #CCCCCC;
            
                        span{
                            color: #F2994A;
                        }
                    }
                }

                &.edit_btn{
                    .btn_icon{
                        background-position: -2px 27px;
                    }
                    span{
                        color: #81A0EA;
                    }
                }

                &.delete_btn{
                    span{
                        color: #707070;
                    }
                }
            }

            p{
                color: #CCCCCC; 

                .student_name{
                    color: #81A0EA;
                }

                .instructor_name{
                    color: $orange;
                }

                .anonymous_name{
                    color: #D1D7E6;
                }
            }
        }

    }

    .user_details{
        .user_type{
            &.student{
                background: rgba(204, 224, 255, 0.14);
                color: #81A0EA;
            }
            &.instructor{
                background: rgba(249, 207, 169, 0.2);
                color: #F2994A;
            }
            &.anonymous{
                background: rgba(240, 240, 240, 0.3);
                color: #D1D7E6;
            }
        }
    
        .user_name{
            color: #CCCCCC;
        }
    
        .date_posted{
            color: #CCCCCC;
        }
    }

    #assignment_forum_question_container{
        border-top: 1px solid #8A8A8A;

        .post_container{
            form{
                .form_head_container{
                    background: rgba(204, 224, 255, 0.14);
                    border-bottom: none;
                    >p{
                        color: #E5E5E5;
                    }
    
                    .forum_post_type_dropdown{
                        background: rgba(204, 224, 255, 0.14);
                        border-radius: 20px;
                        border: none;
    
                        *{
                            color: #E5E5E5;
                        }
    
                        .dropdown-toggle{
                            .arrow_down{
                                border: solid #E5E5E5;
                                border-width: 0 2px 2px 0;
                            }
                        }
                        .dropdown-menu{
                            background: #2c2f33;
                            border-color: #2c2f33;
    
                            *{
                                color: #E5E5E5;
                            }

                            .dropdown-item{
                                &:hover{
                                    background: rgba(204, 224, 255, 0.14);
                                }
                            }
    
                        }
                    }
                }
    
                input{
                    background: rgba(204, 224, 255, 0.14);
                    color: #E5E5E5;
                }
    
                .quill{
                    background: #2A2A2A;
                    color: #E5E5E5;
                    
                    .ql-toolbar {
                        border-bottom: 1px solid rgba(204, 224, 255, 0.14) !important;
                    
                        .ql-formats{
                            button{
                                color: #E5E5E5;
                
                                &[value="ordered"]{
                                    .list_icon{
                                        background-position: -120px -48px;
                                    }
                                }
                                &[value="bullet"]{
                                    .list_icon{
                                        background-position: -142px -48px;
                                    }
                                }

                                i{
                                    &.bold_icon{
                                        background-position: 0 -48px;
                                    }
                                    &.italic_icon{
                                        background-position: -24px -48px;
                                    }
                                    &.strike_icon{
                                        background-position: -48px -48px;
                                    }
                                    &.link_icon{
                                        background-position: -72px -48px;
                                    }
                                    &.code_block_icon{
                                        background-position: -96px -48px;
                                    }
                                    &.image_icon{
                                        background-position: -168px -48px;
                                    }
                                }
                            }
                        }
                    }
                
                    .ql-container{
                        .ql-editor{
                            color: #E5E5E5;
                            
                            &.ql-blank{
                                &:before{
                                    color: rgba(255, 255, 255, 0.48);
    
                                }
                            }
                
                            a{
                                color: #81A0EA;
                            }

                            pre{
                                background: #000000;
                                border-radius: 6px;
                                border: 1px solid #DEE5EF;
                                box-sizing: border-box;
                                color: #FFFFFF;
                                &.ql-syntax{
                                    background: rgba(204, 224, 255, 0.14);
                                    border: 1px solid rgba(204, 224, 255, 0.14);
                                    color: #EF7E1B;
                                }
                            }
                        }
    
                        .ql-tooltip{
                            background: #4B4B4B;    
                            border: none;
                            box-shadow: none;
                            *{
                                font-family: $Poppins;
                                font-style: normal;
                                font-weight: normal;
                            }
    
                            &:before{
                                color: #E5E5E5;
                                font-family: $Poppins;
                                font-style: normal;
                                font-weight: normal;
                            }
    
                            .ql-preview{
                                color: #81A0EA;
                                font-family: $Poppins;
                                font-style: normal;
                                font-weight: normal;
                            }
    
                            input{
                                color: #E5E5E5;
                                outline: none;
                            }
    
                            .ql-action{
                                &:after{
                                    color: #81A0EA;
                                    font-family: $Poppins;
                                    font-style: normal;
                                    font-weight: normal;
                                }
                            }
    
                            .ql-remove{
                                &:before{
                                    color: $red;
                                    font-family: $Poppins;
                                    font-style: normal;
                                    font-weight: normal;
                                }
                            }
                        }
                    }
                }
                
                .button_container{
                    button{
                        &[type="button"]{
                            color: rgba(255, 255, 255, 0.48);
    
                        }
    
                        &[type="submit"]{
                            background: rgba(204, 224, 255, 0.14);
                            color: #81A0EA;
    
                            &.disabled{
                                background: rgba(204, 224, 255, 0.14) !important;
                                color: rgba(255, 255, 255, 0.48) !important;
                            }
                        }
                    }
                }
            }
        }
    }

    #forum_question_answers_container{
        border-top: 1px solid #8A8A8A;

        >button{
            color: #CCCCCC;
            svg{
                color: #81A0EA;
            }
        }

        >p{
            color: #808080;
            span{
                color: #808080;
            }
        }

        #answers_list{
            >li{
                form{
                    .form_head_container{
                        background: rgba(204, 224, 255, 0.14);
                        border-bottom: none;
                        >p{
                            color: #E5E5E5;
                        }
        
                        .forum_post_type_dropdown{
                            background: rgba(204, 224, 255, 0.14);
                            border: none;
        
                            *{
                                color: #E5E5E5;
                            }
        
                            .dropdown-toggle{
                                .arrow_down{
                                    border: solid #E5E5E5;
                                    border-width: 0 2px 2px 0;
                                }
                            }
                            .dropdown-menu{
                                background: #2c2f33;
                                border-color: #2c2f33;
        
                                *{
                                    color: #E5E5E5;
                                }
    
                                .dropdown-item{
                                    &:hover{
                                        background: rgba(204, 224, 255, 0.14);
                                    }
                                }
        
                            }
                        }
                    }
        
                    input{
                        background: rgba(204, 224, 255, 0.14);
                        color: #E5E5E5;
                    }
        
                    .quill{
                        background: #2A2A2A;
                        color: #E5E5E5;
                       
                        
                        .ql-toolbar {
                            border-bottom: 1px solid rgba(204, 224, 255, 0.14) !important;
                            &:first-child{
                                button{
                                    border-radius: 2px;
                                    &:hover{
                                        background: rgba(204, 224, 255, 0.14);
                                    }
                                    &:active, &.ql-active{
                                        color: #81A0EA;
                                        background: #C0D0F4;
                                    }
                                }
                            }
                        
                            .ql-formats{
                                button{
                                    color: #E5E5E5;

                                    &[value="ordered"]{
                                        .list_icon{
                                            background-position: -120px -48px;
                                        }
                                    }
                                    &[value="bullet"]{
                                        .list_icon{
                                            background-position: -142px -48px;
                                        }
                                    }
                    
                                    i{
                                        &.bold_icon{
                                            background-position: 0 -48px;
                                        }
                                        &.italic_icon{
                                            background-position: -24px -48px;
                                        }
                                        &.strike_icon{
                                            background-position: -48px -48px;
                                        }
                                        &.link_icon{
                                            background-position: -72px -48px;
                                        }
                                        &.code_block_icon{
                                            background-position: -96px -48px;
                                        }
                                        &.image_icon{
                                            background-position: -168px -48px;
                                        }
                                    }
                                }
                            }
                        }
                    
                        .ql-container{
                            
                            .ql-editor{
                                color: #E5E5E5;
                                
                                &.ql-blank{
                                    &:before{
                                        color: rgba(255, 255, 255, 0.48);
        
                                    }
                                }
                    
                                a{
                                    color: #81A0EA;
                                }
    
                                pre{
                                    background: #000000;
                                    border-radius: 6px;
                                    border: 1px solid #DEE5EF;
                                    box-sizing: border-box;
                                    color: #FFFFFF;
                                    &.ql-syntax{
                                        background: rgba(204, 224, 255, 0.14);
                                        border: 1px solid rgba(204, 224, 255, 0.14);
                                        color: #EF7E1B;
                                    }
                                }
                            }
        
                            .ql-tooltip{
                                background: #4B4B4B;    
                                border: none;
                                box-shadow: none;
                                *{
                                    font-family: $Poppins;
                                    font-style: normal;
                                    font-weight: normal;
                                }
        
                                &:before{
                                    color: #E5E5E5;
                                    font-family: $Poppins;
                                    font-style: normal;
                                    font-weight: normal;
                                }
        
                                .ql-preview{
                                    color: #81A0EA;
                                    font-family: $Poppins;
                                    font-style: normal;
                                    font-weight: normal;
                                }
        
                                input{
                                    color: #E5E5E5;
                                    outline: none;
                                }
        
                                .ql-action{
                                    &:after{
                                        color: #81A0EA;
                                        font-family: $Poppins;
                                        font-style: normal;
                                        font-weight: normal;
                                    }
                                }
        
                                .ql-remove{
                                    &:before{
                                        color: $red;
                                        font-family: $Poppins;
                                        font-style: normal;
                                        font-weight: normal;
                                    }
                                }
                            }
                        }
                    }
                    
                    .button_container{
                        button{
                            &[type="button"]{
                                color: rgba(255, 255, 255, 0.48);
        
                            }
        
                            &[type="submit"]{
                                background: rgba(204, 224, 255, 0.14);
                                color: #81A0EA;
        
                                &.disabled{
                                    background: rgba(204, 224, 255, 0.14) !important;
                                    color: rgba(255, 255, 255, 0.48) !important;
                                }
                            }
                        }
                    }
                }
                .user_details{
                
                    .user_name{
                        color: #8A8A8A;
                    }
                
                    .date_posted, .is_edited_label{
                        color: #808080;
                    }
                }

                .post_container{
                    .menu_container{
                        button{
                            &.edit_btn{
                                color: #81A0EA;
                            }

                            &.delete_btn{
                                color: #CCCCCC;
                                .btn_icon{
                                   
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
@media only screen and (max-width: 768px){
    #assignment_forum_question_container{
        width: 1188px;
    }
}