.modal{
    &.narrow{
        #report_issue_modal{
            max-width: 450px;
            width: 450px;
        }
    }
    &.wide{
        #report_issue_modal{
            max-width: 690px;
            width: 690px;
        }
    }
}
#report_issue_modal{
    font-family: 'Poppins', sans-serif;
    .modal-content{
        background: #FFFFFF;
        border-radius: 8px;
        box-shadow: 0px 4px 2px rgba(0, 0, 0, 0.05);
        padding: 24px 24px 40px 24px;
        .modal-header{
            border: none;
            justify-content: center;
            margin-bottom: 35px;
            padding: 0;
            position: relative;
            h4{
                color: #152C61;
                font-size: 26px;
                font-weight: 600;
                margin-top: 23px;
            }
            button{
                background-color: transparent;
                border: none;
                outline: none;
                padding: 0px;
                position: absolute;
                right: -10px;
                top: -10px;
                .close_icon{
                    background-image: url("https://assets.codingdojo.com/learn_platform/global/close_icon_new.png");
                    display: inline-block;
                    height: 33px;
                    width: 33px;
                }
            }
        }
        .modal-body{
            padding: 0;
            text-align: center;
            form{
                text-align: left;
                p{
                    color: #575757;
                    font-size: 14px;
                    margin-bottom: 20px;
                }
                select{
                    -moz-appearance: none;
                    -webkit-appearance: none;
                    background-image: url("data:image/svg+xml;utf8,<svg fill='blue' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
                    background-position-x: 100%;
                    background-position-y: 5px;
                    background-repeat: no-repeat;
                    background: transparent;
                    height: 32px;
                    line-height: 20px;
                    padding-block: 0;
                }
                .issue_category{
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 8px;
                    label{
                        border-radius: 9px;
                        border: 0.5px solid rgba(0, 0, 0, 0.2);
                        box-sizing: border-box !important;
                        color: #002C94 !important;
                        font-size: 10px;
                        height: 150px;
                        position: relative;
                        width: 200px;
                        &:nth-child(2){
                            background: url("https://assets.codingdojo.com/learn_platform/user/contact_us/issue_category.svg") -6px -161px;
                            cursor: pointer;
                            span{
                                position: absolute;
                                top: 114px;
                            }
                        }
                        &:nth-child(4){
                            background: url("https://assets.codingdojo.com/learn_platform/user/contact_us/issue_category.svg") -226px -161px;
                            cursor: pointer;
                            span{
                                position: absolute;
                                top: 114.3px;
                            }
                        }
                        &:nth-child(6){
                            background: url("https://assets.codingdojo.com/learn_platform/user/contact_us/issue_category.svg") -446px -161px;
                            cursor: pointer;
                            span{
                                position: absolute;
                                top: 114px;
                            }
                        }

                        span{
                            text-align: center;
                            width: 100%;
                        }
                    }
                    input[type=radio]{
                        height: 0;
                        opacity: 0;
                        position: absolute;
                        width: 0;
                        &:hover + label{
                            border: 3px solid #2C6BFF !important;
                        }
                        &:checked + label{
                            border-radius: 10px;
                            border: 3px solid #2C6BFF !important;
                            &:nth-child(2){
                                background: url("https://assets.codingdojo.com/learn_platform/user/contact_us/issue_category.svg") -7px -162px;
                                span{
                                    top: 113px;
                                }
                            }
                            &:nth-child(4){
                                background: url("https://assets.codingdojo.com/learn_platform/user/contact_us/issue_category.svg") -227px -162px;
                                span{
                                    top: 113.3px;
                                }
                            }
                            &:nth-child(6){
                                background: url("https://assets.codingdojo.com/learn_platform/user/contact_us/issue_category.svg") -447px -162px;
                                span{
                                    top: 113px;
                                }
                            }
                        }
                        &:hover + label{
                            border: 3px solid #2C6BFF !important;
                        }
                    }
                }
                label{
                    color: #575757;
                    font-size: 14px;
                    margin-bottom: 10px;
                }
                #email{
                    border-radius: 4px !important;
                    border: 1px solid rgba(0, 0, 0, 0.2);
                    display: block;
                    height: 32px;
                    margin-bottom: 18px;
                    width: 100%;
                }
                textarea{
                    border-radius: 4px;
                    border: 1px solid rgba(0, 0, 0, 0.2) !important;
                    margin-bottom: 20px;
                    min-height: 159px;
                    resize: none;
                }
                #upload_issue_label{
                    border-radius: 2px;
                    border: 2px dashed #2C6BFF;
                    box-sizing: border-box;
                    color: #2C6BFF;
                    display: block;
                    margin: 0px auto 20px;
                    min-height: 52px;
                    padding: 13.5px;
                    text-align: center;
                    small{
                        color: #2C6BFF;
                        display: block;
                        font-size: 14px;
                    }
                }
                button{
                    background: #2C6BFF;
                    border-radius: 8px;
                    border: none;
                    color: #fff;
                    display: block;
                    font-weight: 600;
                    height: 50px;
                    margin: 0px auto;
                    width: 159px;
                    &:disabled{
                        background-color: #96B5FF;
                        cursor: not-allowed;
                    }
                }
            }
        }
    }
    .success_container{
        .modal-header{
            padding-top: 10px;
            h4{
                text-align: center;
                margin-top: 11px;
                margin-bottom: 4px;
            }
            button{
                right: -10px;
                top: -12px;
            }
        }
        .modal-body{
            p{
                margin-bottom: 0;
            }
        }
    }
    @media only screen and (max-width: 768px){
        padding: 20px !important;
        .modal-content{
            border-radius: 6px !important;
            padding: 0px !important;
            .modal-header{
                margin-bottom: 0 !important;
                padding-top: 28px !important;                
                button{
                    right: 14px;
                    top: 21px;
                }
                h4{
                    font-size: 16px !important; 
                }
                button{
                    right: 13px !important;
                    top: 13px !important;
                }
            }
            .modal-body{
                padding: 17px 16px 20px 16px;
                form{
                    font-size: 14px;
                    margin: 0px auto;
                    max-width: 460px; 
                    p{
                        margin-bottom: 24px;
                    }
                    .issue_category{
                        flex-direction: column;
                        margin-bottom: 20px !important;
                        min-height: 100px;
                        label{
                            border-radius: 6px;
                            border: 1px solid #94B4FF !important;
                            color: #2C6BFF !important;
                            flex: 0;
                            font-size: 16px;
                            font-weight: 500;
                            margin-bottom: 20px !important;
                            padding-block: 17px;
                            text-align: center;
                            width: 100%;
                            &:nth-child(2){
                                background: unset;
                                span{
                                    position: unset;
                                }
                            }
                            &:nth-child(4){
                                background: unset;
                                span{
                                    position: unset;
                                }
                            }
                            &:nth-child(6){
                                background: unset;
                                span{
                                    position: unset;
                                }
                            }
                        }
                        input[type=radio]{
                            &:checked + label{
                                background: #2C6BFF !important;
                                border-radius: 6px;
                                border: 1px solid #94B4FF !important;
                                color: #fff !important;
                            }
                        }
                    }
                    label{
                        margin-bottom: 10px;
                    }
                    #email{
                        margin-bottom: 21px;
                    }
                    #upload_issue_label{
                        border: 2px dashed #2C6BFF;
                        margin-bottom: 25px;
                    }
                    .form-control{
                        border-radius: 4px;
                        border: 1px solid #BDBDBD;
                        font-size: 14px;
                        margin-bottom: 24px;
                    }
                    textarea{
                        margin-bottom: 20px !important;
                        min-height: 130px !important;
                    }
                    button{
                        height: 36px;
                        width: 136px;
                    }
                }
            }
            .success_container{
                .modal-header{
                    padding-top: 40px;
                }
                .modal-body{
                    p{
                        margin-bottom: 20px;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 768px){
    body > div.fade{
        &.wide.modal.show,
        &.narrow.modal.show{
            background: transparent !important;
            padding-right: 0 !important;
        }
    }   
}

.is_dark_mode{
    #report_issue_modal{
        .modal-content{
            background-color: #2E2E2E !important;
            .modal-body{
                p{
                    color: #e2e2e2 !important;
                }
                form{
                    .issue_category{
                        label{
                            &:nth-child(2){
                                background: url("https://assets.codingdojo.com/learn_platform/user/contact_us/issue_category.svg") -6px -6px;
                            }
                            &:nth-child(4){
                                background: url("https://assets.codingdojo.com/learn_platform/user/contact_us/issue_category.svg") -226px -6px;

                            }
                            &:nth-child(6){
                                background: url("https://assets.codingdojo.com/learn_platform/user/contact_us/issue_category.svg") -446px -6px;
                            }
                        }
                        input[type=radio]{
                            &:hover + label{
                                border: 3px solid #557FE2 !important;
                            }
                            &:checked + label{
                                background-color: #557FE2 !important;
                                border: 3px solid #557FE2 !important;
                                &:nth-child(2){
                                    background: url("https://assets.codingdojo.com/learn_platform/user/contact_us/issue_category.svg") -7px -7px;
                                }
                                &:nth-child(4){
                                    background: url("https://assets.codingdojo.com/learn_platform/user/contact_us/issue_category.svg") -227px -7px;
    
                                }
                                &:nth-child(6){
                                    background: url("https://assets.codingdojo.com/learn_platform/user/contact_us/issue_category.svg") -447px -7px;
                                }
                            }
                            &:hover + label{
                                border: 4px solid #557FE2 !important;
                            }
                        }
                    }
                    select{
                        background-color: #444444 !important;
                        border: 1px solid rgba(0, 0, 0, 0.2) !important;
                    }
                    label{
                        color: #e2e2e2;
                    }
                    #email{
                        background-color: #444444 !important;
                        border-radius: 4px !important;
                        display: block;
                        height: 32px;
                        margin-bottom: 20px;
                        width: 100%;
                    }
                    textarea{
                        background-color: #444444 !important;
                    }
                    .form-control:focus{
                        box-shadow: 0 0 0 0.2rem rgba(130, 162, 235, 0.50);
                    }
                    #upload_issue_label{
                        background-color: #2E2E2E !important;
                        border: 2px dashed #557FE2;
                        color: #557FE2 !important;
                        small{
                            color: #557FE2 !important;
                        }
                    }
                    button{
                        background-color: #45454580 !important;
                        color: #557fe2 !important;
                        &:disabled{
                            background-color: #1d1d1dae !important;
                            color: #557fe2ae !important;
                            cursor: not-allowed;
                        }
                    }
                }
            }
        }
    }
    @media only screen and (max-width: 768px){
        #report_issue_modal{
            padding: 20px !important;
            .modal-header{
                margin-bottom: 0;
                padding-top: 40px;
                h4{
                    font-size: 20px;
                }
            }
            .modal-content{
                border-radius: 6px !important;
                padding: 0px !important;
                .modal-body{
                    padding-bottom: 20px;
                    form{
                        .issue_category{
                            margin-bottom: 20px !important;
                            label{
                                background-color: #575757 !important;
                                border: none !important;
                                color: #e2e2e2 !important;
                                margin-bottom: 20px !important;
                                &:nth-child(2){
                                    background: unset;
                                    span{
                                        position: unset;
                                    }
                                }
                                &:nth-child(4){
                                    background: unset;
                                    span{
                                        position: unset;
                                    }
                                }
                                &:nth-child(6){
                                    background: unset;
                                    span{
                                        position: unset;
                                    }
                                }
                            }
                            input[type=radio]{
                                &:checked + label{
                                    border: none !important;
                                    color: #557fe2 !important;
                                    background: #e2e2e2 !important;
                                }
                            }
                        }
                        button{
                            height: 36px;
                            width: 136px;
                        }
                    }
                }
            }
        }
    }
}