$black: #000;
$blue: #2C6BFF;
$border_gray: #F5F5F5;
$dark_blue: #152C61;
$gray: #707070;
$none: transparent;
$orange: #EF7E1B;
$red: #E1554C;
$Poppins: "Poppins", sans-serif;
$white: #fff;

.quill{
    background: #F5F8FF;
    border: none;
    font-family: $Poppins;
    font-style: normal;
    font-weight: normal;
    padding: 13px 25px;

    .ql-toolbar {
        border-bottom: 1px solid #DEE5EF !important;
        border: none !important;
        margin-bottom: 18px;
        padding: 0px 114px  8px 0px !important;
        position: relative;

        .ql-formats{
            &:first-child{
                button{
                    border-radius: 2px;
                    &:hover{
                        background: #F0F0F0;
                    }
                    &:active, &.ql-active{
                        background: #C2D4FF;
                        color: #2C6BFF;
                    }
                }
            }
            button{
                color: #5E6871;
                height: 24px;
                margin-right: 14px;
                padding: 0px;
                width: 24px;


                &[value="ordered"]{
                    .list_icon{
                        background-position: -120px 0px;
                    }
                }
                &[value="bullet"]{
                    .list_icon{
                        background-position: -142px -0px;
                    }
                }

                i{
                    background-image: url("./../../../../assets/images/global/assignment_forum_toolbar_icons.png");
                    display: flex;
                    height: 100%;
                    width: 100%;
                    
                    &.italic_icon{
                        background-position: -24px 0px;
                    }
                    &.strike_icon{
                        background-position: -48px 0px;
                    }
                    &.link_icon{
                        background-position: -72px 0px;
                    }
                    &.code_block_icon{
                        background-position: -96px 0px;
                    }
                    // 144px;
                    &.image_icon{
                        background-position: -168px 0px;
                    }
                }

                &.ql-image{
                    align-items: center;
                    color: rgba(0, 0, 0, 0.6);
                    content: "Attach Image";
                    display: flex;
                    font-family: $Poppins;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: normal;
                    line-height: 18px;
                    margin-right: 0px;
                    position: absolute;
                    right: 0px;
                    top: 2px;
                    width: 114px;
                    
                    i{
                        height: 24px;
                        margin-right: 7px;
                        width: 24px;
                    }
                }
            }
        }
      
    }

    .ql-container{
        border: none !important;

        .ql-editor{
            color: $dark_blue;
            font-family: $Poppins;
            font-size: 16px;
            font-style: normal;
            font-weight: 300;
            line-height: 24px;
            min-height: 160px;
            padding: 0px !important;

            &.ql-blank{
                &:before{
                    color: #8A8A8A;
                    font-family: $Poppins;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 300;
                    left: 0px;
                    line-height: 24px;
                }
            }

            a{
                color: $blue;
            }

            img{
                max-height: 200px;
                max-width: 100%;
            }

            p{
                margin-bottom: 0px;
            }

            ul, ol{
                margin-bottom: 0px;
                li{
                    margin-bottom: 10px;
                }
            }
            .ql-syntax{
                background: #F8FAFF;
                border: 1px solid #DEE5EF;
                color: #DF477D;
            }
        }
    }
}

.modal{
    &.introjs-fixParent{
        position: absolute;
    }
}

#ask_forum_question_modal{    
    max-width: 1060px;
    width: 1060px;
    .modal-content{
        border: none;
        height: 100%;
        .modal-header{
            border: none;
            justify-content: center;
            padding: 25px 30px 28px 30px;
            position: relative;

            .modal-title{
                color: $black;
                font-family: $Poppins;
                font-size: 16px;
                font-style: normal;
                font-weight: normal;
                line-height: 24px;
            }

            button{
                background-color: transparent;
                border: none;
                outline: none;
                padding: 0px;
                position: absolute;
                right: 33px;
                top: 19px;

                .close_icon{
                    background-image: url("https://assets.codingdojo.com/learn_platform/global/close_icon_new.png");
                    display: inline-block;
                    height: 33px;
                    width: 33px;
                }
            }
        }

        .modal-body{
            padding: 0px 41px 32px 41px; 

            #best_practices_container{
                background: #F5F8FF;
                border-radius: 6px;
                padding: 14px;

                h5{
                    color: $blue;
                    font-family: $Poppins;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 24px;
                    margin-bottom: 12px;
                    text-align: center;
                }

                ul{
                    li{
                        color: $blue;
                        font-family: $Poppins;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: normal;
                        line-height: 24px;

                        img{
                            margin: 8px 0 33px;
                        }
                    }
                }
            }

            form{
                margin-top: 17px;

                .form_head_container{
                    align-items: center;
                    display: flex;
                    margin-bottom: 13px;

                    >p{
                        color: rgba(0, 0, 0, 0.6);
                        font-family: $Poppins;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: normal;
                        line-height: 18px;
                        margin-bottom: 0px;
                        margin-right: 12px;
                        
                        img{
                            height: 32px;
                            margin-right: 9px;
                            width: 32px;
                        }
                    }

                    .forum_post_type_dropdown{
                        background: #F8FAFF;
                        border-radius: 20px;

                        &.show{
                            .dropdown-toggle{
                                .arrow_down{
                                    -webkit-transform: rotate(225deg);
                                    -webkit-transition: all .3s;
                                    margin-top: 3px;
                                    transform: rotate(225deg);
                                    transition: all .3s;  
                                }
                            }
                        }

                        .dropdown-toggle{
                            align-items: center;
                            border: 1px solid #DEE5EF;
                            box-shadow: none;
                            color: rgba(0, 0, 0, 0.6);
                            display: flex;
                            font-family: "Poppins", sans-serif;
                            font-size: 12px;
                            font-style: normal;
                            font-weight: normal;
                            height: 24px;
                            justify-content: center;
                            line-height: 18px;
                            outline: none;
                            padding: 0px .75rem;
                            text-transform: capitalize;

                            &:after{
                                display: none;
                            }

                            svg{
                                color: $dark_blue;
                                margin-right: 6px;
                            }
                    
                            .arrow_down{
                                -webkit-transform: rotate(45deg);
                                -webkit-transition: all .3s;
                                border: solid rgba(0, 0, 0, 0.6);
                                border-width: 0 2px 2px 0;
                                display: inline-block;
                                margin-left: 9px;
                                margin-top: -4px;
                                padding: 3px;
                                transform: rotate(45deg);
                                transition: all .3s;  
                            }
                        }

                        .dropdown-menu{
                            background: #F8FAFF;
                            border-radius: 6px;                            
                            border: 1px solid #DEE5EF;
                            box-sizing: border-box;
                            min-width: 116px;
                            width: 116px;

                            .dropdown-item{
                                color: rgba(0, 0, 0, 0.6);
                                font-family: $Poppins;
                                font-size: 12px;
                                font-style: normal;
                                font-weight: normal;
                                line-height: 18px;
                                outline: none;
                                text-transform: capitalize;

                                &.active, &:active, &:focus{
                                    background-color: $none;
                                }
                            }
                        }
                    }
                }

                >input{
                    background: #F5F8FF;
                    border-radius: 6px;
                    border: none;
                    box-shadow: none;
                    color: $dark_blue;
                    font-family: $Poppins;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 300;
                    height: 60px;
                    line-height: 24px;
                    margin-bottom: 20px;
                    outline: none;
                    padding: 20px 25px;
                    width: 100%;
                }

                .warning_msg{
                    color: $red;
                }

                .button_container{
                    display: flex;
                    justify-content: flex-end;
                    margin-top: 20px;

                    button{
                        border: none;
                        font-family: $Poppins;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: normal;
                        line-height: 24px;
                        outline: none;

                        &.disabled{
                            background-color: #BDBDBD !important;
                            pointer-events: none;
                        }
                        
                        &[type="button"]{
                            background: $none;
                            color: rgba(0, 0, 0, 0.6);
                        }

                        &[type="submit"]{
                            background: #2C6BFF;
                            border-radius: 6px;
                            color: $white;
                            height: 36px;
                            margin-left: 13px;
                            width: 183px;

                            &.disabled{
                                background: #BDBDBD;
                                pointer-events: none;
                            }
                        }
                    }
                }
            }
        }
    }
}

/* DARK MODE */ 
.is_dark_mode{
    #ask_forum_question_modal{
        .modal-content{
            background: #333333;
            .modal-header{
                .modal-title{
                    color: #E5E5E5;
                }

                button{
                    .close_icon{
                        background-position: -61px 0px;
                    }
                }
            }

            .modal-body{
                #best_practices_container{
                    background: #4B4B4B;
                    
                    h5{
                        color: #81A0EA;
                    }

                    ul{
                        li{
                            color: #E5E5E5;
                        }
                    }
                }

                form{
                    .form_head_container{
                        >p{
                            color: #E5E5E5;
                        }

                        .forum_post_type_dropdown{
                            background: rgba(204, 224, 255, 0.14);
                            border-radius: 20px;
                            border: none;

                            *{
                                color: #E5E5E5;
                            }

                            .dropdown-toggle{
                                .arrow_down{
                                    border: solid #E5E5E5;
                                    border-width: 0 2px 2px 0;
                                }
                            }
                            .dropdown-menu{
                                background: #2c2f33;
                                border-color: #2c2f33;

                                *{
                                    color: #E5E5E5;
                                }

                                .dropdown-item{
                                    &:hover{
                                        background-color: #4b4b4b !important;
                                    }
                                }
                            }
                        }
                    }

                    input{
                        background: rgba(204, 224, 255, 0.14);
                        color: #E5E5E5;
                    }

                    .quill{
                        background: rgba(204, 224, 255, 0.14);
                        color:#E5E5E5;
                        
                        .ql-toolbar {
                            border-bottom: 1px solid rgba(204, 224, 255, 0.14) !important;
                            &:first-child{
                                button{
                                    border-radius: 2px;
                                    &:hover{
                                        background: rgba(204, 224, 255, 0.14);
                                    }
                                    &:active, &.ql-active{
                                        background: #C0D0F4;
                                        color: #81A0EA;
                                    }
                                }
                            }
                        
                            .ql-formats{
                                button{
                                    color: #E5E5E5;

                                    &[value="ordered"]{
                                        .list_icon{
                                            background-position: -120px -48px;
                                        }
                                    }
                                    &[value="bullet"]{
                                        .list_icon{
                                            background-position: -142px -48px;
                                        }
                                    }
                    
                                    i{
                                        &.bold_icon{
                                            background-position: 0 -48px;
                                        }
                                        &.italic_icon{
                                            background-position: -24px -48px;
                                        }
                                        &.strike_icon{
                                            background-position: -48px -48px;
                                        }
                                        &.link_icon{
                                            background-position: -72px -48px;
                                        }
                                        &.code_block_icon{
                                            background-position: -96px -48px;
                                        }
                                        &.image_icon{
                                            background-position: -168px -48px;
                                        }
                                    }
                                }
                            }
                        }
                    
                        .ql-container{

                            .ql-editor{
                                color: #E5E5E5;
                                
                                &.ql-blank{
                                    &:before{
                                        color: rgba(255, 255, 255, 0.48);

                                    }
                                }
                    
                                a{
                                    color: #81A0EA;
                                }
                                
                                pre{
                                    background: #000000;
                                    border-radius: 6px;
                                    border: 1px solid #DEE5EF;
                                    box-sizing: border-box;
                                    color: #FFFFFF;
                                }
                                .ql-syntax{
                                    background: rgba(204, 224, 255, 0.14);
                                    border: 1px solid rgba(204, 224, 255, 0.14);
                                    color: #EF7E1B;
                                }
                            }

                            .ql-tooltip{
                                background: #4B4B4B;    
                                border: none;
                                box-shadow: none;
                                *{
                                    font-family: $Poppins;
                                    font-style: normal;
                                    font-weight: normal;
                                }

                                &:before{
                                    color: #E5E5E5;
                                    font-family: $Poppins;
                                    font-style: normal;
                                    font-weight: normal;
                                }

                                .ql-preview{
                                    color: #81A0EA;
                                    font-family: $Poppins;
                                    font-style: normal;
                                    font-weight: normal;
                                }

                                input{
                                    color: #E5E5E5;
                                    outline: none;
                                }

                                .ql-action{
                                    &:after{
                                        color: #81A0EA;
                                        font-family: $Poppins;
                                        font-style: normal;
                                        font-weight: normal;
                                    }
                                }

                                .ql-remove{
                                    &:before{
                                        color: $red;
                                        font-family: $Poppins;
                                        font-style: normal;
                                        font-weight: normal;
                                    }
                                }
                            }
                        }
                    }
                    
                    .button_container{
                        button{
                            &[type="button"]{
                                color: rgba(255, 255, 255, 0.48);

                            }

                            &[type="submit"]{
                                background: rgba(204, 224, 255, 0.14);
                                color: #81A0EA;

                                &.disabled{
                                    background: rgba(204, 224, 255, 0.14) !important;
                                    color: rgba(255, 255, 255, 0.48) !important;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
@media only screen and (max-width: 768px){
    #ask_forum_question_modal{
        .modal-content{
            .modal-body{
                padding: 0px 20px 32px 20px;
            }

        }

    }
}