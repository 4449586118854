$black: #000;
$blue: #2C6BFF;
$border_gray: #F5F5F5;
$dark_blue: #152C61;
$gray: #707070;
$none: transparent;
$orange: #EF7E1B;
$red: #E1554C;
$Poppins: "Poppins", sans-serif;
$white: #fff;

.no_question_available_container{
    color: #8A8A8A;
    font-family: $Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    margin: 30px 0px;
    text-align: center;

    p{
        color: #8A8A8A;
        font-family: $Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 21px;
        margin: 22px 0px 20px 0px;
    }

    button{
        background: #2C6BFF;
        border-radius: 6px;
        border: none;
        color: $white;
        font-family: $Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        height: 36px;
        line-height: 24px;
        outline: none;
        padding: 0px 40px;
    }
    
}

#assignment_forum_container{
    background: $white;
    border-radius: 6px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
    margin: 20px 0px 0px;
    padding: 21px 30px 30px 30px;
    width: 100%;

    #forum_head_container{
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        h2{
            margin-bottom: 0px;
        }

        >a{
            color: #2C6BFF;
            font-family: $Poppins;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 18px;
            margin-right: 55px;
            text-decoration-line: underline;
        }

        #forum_menu_container{
            margin-top: 37px;
            width: 100%;
        }
    }
    
    h2{
        align-items: center;
        color: $dark_blue;
        display: flex;
        font-family: $Poppins;
        font-size: 26px;
        font-style: normal;
        font-weight: 500;
        line-height: 39px;

        span{
            background: #FAFBFF;
            border-radius: 6px;
            color: #2C6BFF;
            font-family: $Poppins;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 18px;
            margin-left: 20px;
            padding: 6px 10px;
        }
    }
    #forum_menu_container{
        align-items: center;
        display: flex;
        justify-content: space-between;
        margin: 27px 55px 19px 55px;

        button#back_to_questions_list_btn{
            background-color: transparent;
            border: none;
            color: #333333;
            font-family: $Poppins;
            font-size: 16px;
            font-style: normal;
            font-weight: normal;
            line-height: 24px;
            outline: none;
            padding: 0px;
            
            &.not_visible{
                visibility: hidden;
            }

            svg{
                margin-right: 10px;
                color: #5E6871;
            }
        }

        button#ask_question_btn{
            background: $blue;
            border-radius: 6px;
            border: none;
            color: $white;
            font-family: $Poppins;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            height: 38px;
            line-height: 24px;
            outline: none;
            text-align: center;
            width: 176px;
        }
    }

    #questions_list_container{
        border-top: 1px solid $border_gray;
        margin: 0px 55px;

        >p{
            color: $gray;
            font-family: $Poppins;
            font-size: 16px;
            font-style: normal;
            font-weight: normal;
            line-height: 24px;
            margin: 21px 0px 39px 0px;
            
            span{
                color: $blue;
                font-family: $Poppins;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 24px;
            }
        }

        ul#questions_list{
            list-style: none;
            padding-left: 0px;

            >li{
                align-items: flex-start;
                background: $white;
                border-radius: 6px;
                border: 1px solid #DCE6FF;
                box-sizing: border-box;
                display: flex;
                margin-bottom: 20px;
                padding: 25px 27px 28px 16px;
                position: relative;

                &:last-child{
                    margin-bottom: 0px;
                }


                button.delete_btn{
                    background-color: transparent;
                    border: none;
                    display: flex;
                    outline: none;

                    .btn_icon{
                        background-image: url("./../../../../assets/images/courses/assignment_forum_icons_sprite.png");
                        background-position: -120px 0px;
                        display: flex;
                        height: 24px;
                        width: 24px;
                    }
                }


                .voting_container{
                    align-items: center;
                    display: flex;
                    flex-direction: column;
                    flex: none;
                    justify-content: center;
                    margin-right: 30px;
                    width: 52px;

                    button{
                        align-items: center;
                        background-color: $none;
                        border-radius: 2px;
                        border: none;
                        display: flex;
                        height: 20px;
                        justify-content: center;
                        outline: none !important;
                        width: 20px;
                        &:hover{
                            background: #F0F0F0;
                        }
                        
                        &.up_vote_btn{
                            &.selected{
                                background: #C2D4FF;
                                svg{
                                    color: $blue;
                                }
                            }
                        }
                        
                        &.down_vote_btn{
                            &.selected{
                                background: #FCEEEE;
                                svg{
                                    color: $red;
                                }
                            }
                        }
                
                        svg{
                            color: #5E6871;
                            font-size: 14px;
                        }
                    }

                    span{
                        color: $dark_blue;
                        font-family: $Poppins;
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 30px;
                        margin: 8px 0px 5px 0px;
                    }
                }

                .post_container{
                    cursor: pointer;
                    
                    h5{
                        color: $dark_blue;
                        font-family: $Poppins;
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 30px;
                        margin-bottom: 17px;
                    }

                    .post_description{
                        color: $dark_blue;
                        font-family: $Poppins;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 300;
                        line-height: 24px;
                    }

                    .menu_container{
                        align-items: center;
                        display: flex;

                        button{
                            &.answers_btn{
                                align-items: center;
                                background-color: $none;
                                border: none;
                                color: $gray;
                                display: flex;
                                font-family: $Poppins;
                                font-size: 16px;
                                font-style: normal;
                                font-weight: normal;
                                line-height: 24px;
                                margin-right: 15px;
                                outline: none;
                                padding: 0px;

                                .btn_icon{
                                    background-image: url("./../../../../assets/images/courses/assignment_forum_icons_sprite.png");
                                    background-position: -72px 0px;
                                    display: flex;
                                    height: 24px;
                                    margin-right: 9px;
                                    width: 24px;
                                }
                            }
                            &.endorse_btn{
                                margin-right: 24px;
                                opacity: 1;
                                position: unset;
                                right: unset;
                                top: unset;

                                .btn_icon{
                                    background-position: -32px -27px;
                                }
                                span{
                                    color: #F2994A;
                                }
                            }

                            &.delete_btn{
                                margin-right: 0px;
                            }
                        }

                        >p{
                            align-items: center;
                            color: $gray;
                            display: flex;
                            font-family: $Poppins;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: normal;
                            line-height: 24px;
                            margin-bottom: 0px;
                            margin-right: 27px;

                            &.anonymous_type{
                                i{
                                    background-position: 0px 0px;
                                }
                                span{
                                    color: $gray;
                                }
                            }
                            &.student_type{
                                i{
                                    background-position: -24px 0px;
                                }
                                span{
                                    color: $blue;
                                }
                            }

                            &.instructor_type{
                                i{
                                    background-position: -48px 0px;
                                }
                                span{
                                    color: $orange;
                                }
                            }

                            .user_type_icon{
                                background-image: url("./../../../../assets/images/courses/assignment_forum_icons_sprite.png");
                                display: flex;
                                height: 24px;
                                margin-right: 9px;
                                width: 24px; 
                            }
                        }
                    }
                }
            }
        }

        >button{
            align-items: center;
            background-color: $none;
            border: none;
            color: #333333;
            display: flex;
            font-family: $Poppins;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
            margin: 49px auto 0px auto;
            outline: none;
            text-align: center;

            svg{
                color: $blue;
                font-size: 14px;
                margin-right: 10px;
            }
        }
    }
}

/* DARK MODE */ 
.is_dark_mode{
    #assignment_forum_container{
        background: #2A2A2A;

        #forum_head_container{
            >a{
                color: #81A0EA;
            }
        }

        h2{
            color: $white;

            span{
                background: rgba(204, 224, 255, 0.14);
                color: #81A0EA;
            }
        }

        #forum_menu_container{
            #back_to_questions_list_btn{
                color: #CCCCCC;
                svg{
                    color: #81A0EA;
                }
            }

            #ask_question_btn{
                background: rgba(204, 224, 255, 0.14);
                color: #81A0EA;
            }
        }

        #questions_list_container{
            border-top: 1px solid #8A8A8A;

            >button{
                color: #CCCCCC;
                svg{
                    color: #81A0EA;
                }
            }
            
            >p{
                color: #707070;
                span{
                    color: #81A0EA;
                }
            }

            #questions_list{
                >li{
                    background: #333333;
                    border: 1px solid #4B4B4B;
                    &:hover{
                        .delete_btn.is_admin_btn{
                            -webkit-transition: opacity .3s;
                            opacity: 1;
                            transition: opacity .3s;  
                        }
                    }
                    .delete_btn.is_admin_btn{
                        -webkit-transition: opacity .3s;
                        opacity: 0;
                        transition: opacity .3s;  
                    }

                    .voting_container{
                        .up_vote_btn, .down_vote_btn  {
                            &:hover{
                                background: rgba(204, 224, 255, 0.14);
                            }
                            svg{
                                color: #707070;
                            }
                        }
                        .down_vote_btn  {
                            &.selected{
                                background: rgba(217, 75, 75, 0.4);
                                svg{
                                    color: #E1554C;
                                }
                            }
                        }
                
                        .up_vote_btn{
                            &.selected{
                                background: #C0D0F4;
                                svg{
                                    color: #81A0EA;
                                }
                            }
                        }
                
                        span{
                            color: #CCCCCC;
                        }
                    }

                    .post_container{
                        h5{
                            color: $white;
                        }

                        .post_description{
                            *{
                                color: #CCCCCC;
                            }
                            a{
                                color: #81A0EA;
                            }
                            .ql-syntax{
                                background: rgba(204, 224, 255, 0.14);
                                border: 1px solid rgba(204, 224, 255, 0.14);
                                color: #EF7E1B;
                            }
                        }
                        .menu_container{
                            button.answers_btn{
                                color: #CCCCCC;

                                .btn_icon{
                                    background-position: -72px 54px;
                                }
                            }

                            p{
                                color: #CCCCCC; 
                                &.anonymous_type{
                                    i{
                                        background-position: 0px 54px;
                                    }
                                    span{
                                        color: #D1D7E6;
                                    }
                                }
                                &.student_type{
                                    i{
                                        background-position: -24px 54px;
                                    }
                                    span{
                                        color: #81A0EA;
                                    }
                                }

                                &.instructor_type{
                                    i{
                                        background-position: -48px 54px;
                                    }
                                    span{
                                        color: $orange;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
@media only screen and (max-width: 768px){
    #assignment_forum_container{
        display: none;
        margin: 20px auto 0px;
        width: 1309px;
        #questions_list_container{
            width: 1188px;
        }
        h2{
            flex-direction: column;
            span{
                margin-left: 0px;
                margin-top: 10px;
            }
        }
        .no_question_available_container{
            margin-bottom: 0px;
            button{
                font-size: 14px;
                height: unset;
                padding: 10px 40px;
            }
        }
    }
}