
#downloading_modal{
    .modal-content{
        align-items: center;
        display: flex;
        flex-direction: column;
        padding: 40px;
        
        span{
            background-image: url("./../../../../assets/images/admin/rostering/export_loader.gif");
            background-size: cover;
            display: flex;
            height: 30px;
            margin-bottom: 7px;
            mix-blend-mode: lighten;
            width: 30px;
        }
        p{
            color: rgba(255, 255, 255, 0.88);
            font-family: 'Poppins', sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 130%;
            margin: 13px 0px 20px 0px;
        }
        button{
            background-color: transparent;
            border: none;
            color: #81A0EA;
            font-family: 'Poppins', sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 130%;
            outline: none;
        }
    }
}
