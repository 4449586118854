#chapter_survey_form{
    margin: 0px auto;
    width: 83%;

    >p{
        color: #152C61;
        font-family: 'Poppins', sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: 24px;
        margin: 28px 0px 47px 0px;
    }
    .mobile_legend_list{
        display: none;
        list-style: none;
        padding-left: 0px;
    }

    #chapter_survey_questions{
        >li{
            margin-bottom: 62px;

            &:last-child{
                margin-bottom: 0px;
            }

            >p{
                color: #152C61;
                font-family: 'Poppins', sans-serif;
                font-size: 22px;
                font-style: normal;
                font-weight: 500;
                line-height: 33px;
            }

            textarea{
                background: #FFFFFF;
                border-radius: 2px;    
                border: 2px solid #E6E6E6;
                box-shadow: none;
                box-sizing: border-box;
                color: #152C61;
                font-family: 'Poppins', sans-serif;
                font-size: 14px;
                font-style: normal;
                font-weight: 300;
                height: 80px;
                line-height: 21px;
                min-height: 80px;
                outline: none;
                padding: 25px 26px 30px 26px;
                resize: none;
            }
        }
    }
    @media only screen and (max-width: 768px){
        width: 100%;
        p{
            margin: 28px 0px 26px 0px;
        }
        >p{
            margin-bottom: 32px;
            margin-top: 31px;
        }
        .mobile_legend_list{
            display: block;
            margin-bottom: 43px;
            li{
                color: #152C61;
                font-family: 'Poppins', sans-serif;
                font-size: 12px;
                font-weight: 300;
                margin-bottom: 3px;
            }
        }
        
        #chapter_survey_questions{
            
            > li{
                .slide_ratings_list{
                    align-items: center;
                    flex-wrap: wrap;
                    justify-content: center;
                    margin: 29px auto 16px;
                    max-width: 337px;
                    label{
                        display: flex;
                        flex: unset;
                        margin-bottom: 34px;
                        width: 55px;
                        &:nth-child(6) {
                            .rating_value{
                                border-radius: 0px 5px 5px 0px;
                            }
                        }
                        &:nth-child(7) {
                            .rating_value{
                                border-radius: 5px 0px 0px 5px;
                            }
                        }
                        .rating_label{
                            display: none;
                        }
                    }
                }
                > p{
                    font-weight: 300;
                    margin-bottom: 34px;
                }
                textarea{
                    height: unset;
                    padding: 22px 20px 30px 22px;
                }

            }
        }
    }
    @media only screen and (max-width: 400px){
        
        #chapter_survey_questions{
            
            > li{
                .slide_ratings_list{
                    max-width: 283px;
                    label{
                        width: 40px;
                    }
                }
            }
        }
    }
}
body.is_dark_mode{
    #course_container.container{
        .right_container{
            #chapter_survey_form{
                #chapter_survey_questions {
                    > li{
                        .slide_ratings_list{
                            label{
                                .rating_value{
                                    color: rgba(255, 255, 255, 0.88);
                                    background: #505050 !important;
                                }

                            }
                            input[type=radio]:checked ~ span.rating_value{
                                color: #81A0EA;

                            }
                        }
                    }
                }
            }
        }
    }
}
@media only screen and (max-width: 768px){
    .is_dark_mode{
        #chapter_survey_form{
            .mobile_legend_list{
                li{
                    color: rgba(255, 255, 255, 0.88);
                }
            }

        }
    }
}