#maintenance_container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 10px;
    text-align: center;

    img{
        display: flex;
        margin: 0px auto;
        width: 418.07px;
    }

    h1{
        color: #002C94;
        font-family: 'Poppins', sans-serif;
        font-size: 22px;
        font-style: normal;
        font-weight: 300;
        line-height: 33px;
        margin: 39px 0px 20px 0px;
        opacity: 0.8;
    }

    p{
        color: #333333;
        font-family: 'Poppins', sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        margin: 0px auto;
        max-width: 790px;
        opacity: 0.8;
    }
}

.is_dark_mode{
    #maintenance_container{
        h1{
            color: #81A0EA;
        }

        p{
            color:rgba(255,255,255,0.88);
        }
    }
}   

@media only screen and (max-width: 768px){
    #maintenance_container{
        img{
            width: 254.48px;
        }   
    }
}