#tracks_container{
    .loading{
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 80px;
    }

    #tracks_list{
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        >li{
            background: #FFFFFF;
            border-radius: 6px;
            margin-bottom: 20px;
            padding: 21px 29px;
            width: 100%;

            &#no_tracks_found{
                color: #152C61;
                font-family: 'Poppins', sans-serif;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                text-align: center;
            }

            >h5{
                color: #152C61;
                font-family: 'Poppins', sans-serif;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 24px;
                margin-bottom: 40px;

                span{
                    color: #666666;
                    font-family: 'Poppins', sans-serif;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    letter-spacing: 2px;
                    line-height: 21px;
                    margin-left: 20px;
                    text-transform: uppercase;
                }
            }
            .courses_list{
                display: grid;
                grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
                overflow: auto;
            }
        }    
    }
}
@media only screen and (max-width: 768px){
    #tracks_container{
        padding: 22px 20px 70px;
        width: 100%;
        #tracks_list{
            >li{
                box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
                margin-bottom: 24px;
                padding: 23px 29px;
                position: relative;
                &.is_open{
                    > h5{
                        margin-bottom: 23px;
                        button{
                            -ms-transform: rotate(0deg);
                            -webkit-transform: rotate(0deg);
                            transform: rotate(0deg);
                            transition: transform .2s ease-in-out;
                        }
                    }
                    .courses_list{
                        display: grid;
                        grid-template-columns: repeat(auto-fill, minmax(108px, 1fr));
                    }
                }
                > h5{
                    display: flex;
                    flex-direction: column;
                    margin-bottom: 0px;

                    span{
                        margin-left: 0px;
                        margin-top: 5px;
                    }

                    button{
                        -ms-transform: rotate(180deg);
                        -webkit-transform: rotate(-180deg);
                        background: transparent;
                        border: none;
                        color: #2C6BFF;
                        outline: none !important;
                        position: absolute;
                        right: 10px;
                        top: 21px;
                        transform: rotate(-180deg);
                        transition: transform .2s ease-in-out;
                    }
                }
                .courses_list{
                    display: none;
                    .course_item:nth-last-child(-1n+8){
                        margin-bottom: 18px;
                    }
                    .course_item{
                        &.completed:before{
                            right: 38px;
                            top: 25px;
                            transform: scale(.7);
                        }
                        span{
                            font-size: 14px;
                        }
                        button{
                            img{
                                height: 40px;
                                width: 35px;
                            }
                        }
                    }
                }
            }
        }
    }
    .is_dark_mode{
        #tracks_container{
            #tracks_list{
                > li {
                    button{
                        color: #81A0EA;
                    }
                }
            }
        }
    }
}
.is_dark_mode{
    #tracks_container{
        #tracks_list{
            >li#no_tracks_found{
                color: rgba(255, 255, 255, 0.88);
            }
        }
    }
}