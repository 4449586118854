#card_information_container{
    max-width: 789px;
    .be_error_message{
        align-items: center;
        background-color:#FBCACA;
        border-radius: 5px;
        border: 1px solid #FDE5E5;
        display: flex;
        font: normal 400 14px/normal "Poppins";
        margin-block: 10px;
        max-width: 789px;
        min-height: 69px;
        padding: 13px 28px 12px 60px;
        position: relative;
        &::before{
            background: url("https://codingdojo_assets.s3.us-east-1.amazonaws.com/learn_platform/global/alumni_pass_sprite.png") no-repeat -1038px -38px;
            content: "";
            height: 24px;
            left: 22px;
            position: absolute;
            top: 22px;
            width: 23px;
        }
    }
    h1{
        font: normal 700 32px/48px "Poppins";
        margin-bottom: 19px;
        text-align: center;
    }
    form{
        max-width: 789px;
        width: 100%;      
        label{
            background-color: #f1f1f1;
            border-radius: 6px;
            display: block;
            margin-bottom: 10px;
            position: relative;
            span{
                color: #B3B3B3;
                font: normal 600 16px/24px "Poppins";
                left: 9px;
                position: absolute;
                top: 4px;
            }
            input, .StripeElement{
                background-color: transparent;
                border: 2px solid transparent;
                font: normal 400 16px/30px "Poppins";
                height: 50px;
                padding: 25px 0 0 6px;
                width: 100%;
                &::placeholder{
                    color: #B3B3B3;
                    font: normal 300 16px/30px "Poppins";
                }
                &:focus, &.StripeElement--focus{
                    border-radius: 6px;
                    border: 2px solid transparent;
                    outline: none;
                }
                &.with_errors{
                    border: 2px solid #CC392E;
                    border-radius: 6px;
                }
            }
            & + p {
                display: none;
            }
            &.with_errors{
                margin-bottom: 0px;
                & + p{
                    color: #CC392E;
                    display: block;
                    font: normal 400 0.93rem/17px "Poppins";
                    margin: 0 0 5px 0;
                }
            }
        }
        .label_container{
            label{
                & + p {
                    display: none;
                }
                &.with_errors{
                    margin-bottom: 0px;
                    & + p{
                        color: #CC392E;
                        display: block;
                        font: normal 400 0.93rem/17px "Poppins";
                        margin: 0 0 5px 0;
                    }
                }
            }
        }
        #card_details_container{
            display: flex;
            gap: 10px;
            .label_container{
                flex-basis: 100%;
            }
        }
    }
    .selected_plan{
        align-items: center;
        background-color: #f1f1f1;
        border-radius: 6px;
        display: flex;
        height: 50px;
        justify-content: space-between;
        margin-top: 10px;
        padding: 0 8px;
        span{
            font: normal 500 16px/30px "Poppins"; 
        }
        .dropdown-toggle{
            background: none;
            border: none;
            box-shadow: none;
            color: #2C6BFF;
            cursor: pointer;
            font: normal 500 16px/normal "Poppins";
            &::after{
                margin-left: 10px;
            }
        }
        .dropdown-menu{
            background-color: #fbfbfb;
            border-radius: 6px;
            border: 1px solid #B3B3B3;
            box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.14), 0px 0px 9px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
            padding: 0;
            right: -8px !important;
            .dropdown-item{
                padding: 5px 10px;
                &:first-child{
                    border-top-left-radius: 5px;
                    border-top-right-radius: 5px;
                }
                &:last-child{
                    border-bottom-left-radius: 5px;
                    border-bottom-right-radius: 5px;
                }
                &.active, &.disabled{
                    background-color: #2C6BFF;
                    color: #fff;
                    opacity: 1;
                }
                &:active{
                    background-color: none;
                    color: none;
                }
            }
        }
    }
    .email_questions{
        font: italic 300 12px/normal "Poppins";
        margin: 20px auto 0 auto;
        max-width: 789px;
        a{
            color: #2C6BFF;
            text-decoration: none;
        }
    }
    .disclaimer{
        color: #7e7e7e;
        font: normal 300 12px/normal "Poppins";
        margin: 5px 0 0 0;
        span{
            font-weight: 500;
        }
    }
    .checkbox_container{
        align-items: center;
        color: #7e7e7e;
        display: flex;
        font: normal 300 12px/normal "Poppins";
        margin-top: 10px;
        input{
            display: none;
            &:checked{
                & + #sign_up_input .checkbox{
                    align-items: center;
                    background-color: #2C6BFF;
                    border: none;
                    display: flex;
                    justify-content: center;
                    svg{
                        color: #fff;
                        display: block;
                        transform: scale(.7);
                    }
                }
            }
        }
        #sign_up_input{
            background-color: transparent !important;
            cursor: pointer;
            margin: 0;
            padding-left: 25px;
            position: relative;
            .checkbox{
                border-radius: 4px;
                border: 1px solid #B3B3B3;
                display: inline-block;
                flex-shrink: 0;
                height: 15px;
                left: 0;
                margin-right: 5px;
                position: absolute;
                top: 8px;
                width: 15px;
                svg{
                    display: none;
                }
            }
            a{
                text-decoration: none;
            }
        }
    }
    .button_container{
        display: flex;
        gap: 20px;
        justify-content: space-between;
        margin: 20px auto 0 auto;
        max-width: 789px;
        button{
            border-radius: 6px;
            font: normal 500 16px/24px "Poppins";
            height: 60px;
            max-width: 229px;
            width: 100%;
            &:nth-child(1){
                background: #FFFFFF;
                border: 2px solid #2C6BFF;
                color: #2C6BFF;
            }
            &:nth-child(2){
                background: #2C6BFF;
                border: 2px solid #2C6BFF;
                color: #FFFFFF;
                &:disabled{
                    &:disabled{
                        cursor: not-allowed;
                        opacity: .5;
                    }
                }
            }
        }
    }
}

.is_dark_mode #card_information_container{
    h1, .email_questions, input{
        color: #FFFFFF;
    }
    p a{
        color: #81A0EA;
    }
    form{
        label{
            background-color: #333;
        }
        .label_container{
            label{
                & + p {
                    display: none;
                }
                &.with_errors{
                    margin-bottom: 0px;
                    & + p{
                        color: #CC392E;
                        display: block;
                        font: normal 400 0.93rem/17px "Poppins";
                        margin: 0 0 5px 0;
                    }
                }
            }
        }
        #card_details_container{
            display: flex;
            gap: 10px;
            .label_container{
                flex-basis: 100%;
            }
        }
    }
    .selected_plan{
        background-color: #333;
        span{
            color: #fff;
            font: normal 500 16px/30px "Poppins"; 
        }
        .dropdown-toggle{
            background: none;
            border: none;
            box-shadow: none;
            color: #81A0EA;
            cursor: pointer;
            font: normal 500 16px/normal "Poppins";
        }
        .dropdown-menu{
            background-color: #333;
            border-radius: 6px;
            border: 1px solid #B3B3B3;
            box-shadow: unset;
            padding: 0;
            .dropdown-item{
                color: #fff;
                padding: 5px 10px;
                &.active, &.disabled{
                    background-color: #81A0EA;
                    color: #fff;
                    opacity: 1;
                }
                &:active{
                    background-color: none;
                    color: none;
                }
                &:hover{
                    background-color: #222;
                }
            }
        }
    }
    .disclaimer{
        color: #b3b3b3;
    }
    .checkbox_container{
        color: #b3b3b3;
        input{
            &:checked{
                & + label .checkbox{
                    background-color: #81A0EA;
                }
            }
        }
        label{
            a{
                color: #81A0EA;
            }
        }
    }
    .button_container{
        button{
            &:nth-child(1){
                background: none;
                border: 2px solid #81A0EA;
                color: #81A0EA;
            }
            &:nth-child(2){
                background: #81A0EA;
                border: 2px solid #81A0EA;
                color: #FFFFFF;
            }
        }
    }
}

@media only screen and (max-width: 768px){
    #card_information_container{
        .button_container{
            flex-direction: column-reverse;
            button{
                max-width: unset;
            }
        }
    }
}