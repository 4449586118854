$Poppins: 'Poppins', sans-serif;

/* COLOR */
$white: #fff;
$dark_gray: #282828;
$black: #000;

.admin_modal{
    #edit_course_modal{
        max-width: 776px;
        width: 776px;
        .modal-content{
            .modal-header{
                justify-content: flex-start;
                h4{
                    color: rgba(255, 255, 255, 0.88);
                    font: normal 700 14px/130% $Poppins;
                }
            }
            form{
                .modal-body{
                    padding: 20px 24px;
                    .input_details_block{
                        position: relative;
                        .label_title{
                            color: rgba(255, 255, 255, 0.4);
                            display: block;
                            font: 600 12px/14px $Poppins;
                            margin-bottom: 10px;
                        }
                        #course_name::placeholder{
                            color: rgba(255, 255, 255, 0.58);
                            font: 400 14px/140% $Poppins;
                        }
                        .react-dropdown-select-content{
                            font: 400 14px/140% $Poppins;
                        }
                        input[type="text"], textarea, .react-dropdown-select{
                            background: rgba(204, 224, 255, 0.14);
                            border-radius: 4px;
                            border: none;
                            color: $white;
                            font: 400 14px/18px $Poppins;
                            height: 40px;
                            margin-bottom: 20px;
                            outline: none;
                            padding: 0 15px;
                            width: 100%;
                        }
                        textarea{
                            height: 120px;
                            margin-bottom: 0px;
                            padding: 15px;
                            resize: none;
                        }
                        >div{
                            .react-dropdown-select{
                                .react-dropdown-select-dropdown{
                                    width: 100%;
                                }
                            }
                        }
                    }
                    .course_dropdown_container{
                        display: flex;
                        justify-content: space-between;
                        & > * {
                            width: 350px;
                        }
                    }
                }
                .modal-footer{
                    button{
                        background: rgba(204, 224, 255, 0.14);
                        border-radius: 2px;
                        color: #81A0EA;
                        font: normal 600 14px/18.2px $Poppins;
                        height: 40px;
                        width: 135px;
                        &:disabled{
                            background: #3D3F41;
                            color: #5A698E;
                        }
                    }
                }
            }
        }
    }
}