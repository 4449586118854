$Poppins: "Poppins", sans-serif;

/* COLOR */
$dark_gray: #282828;
$white: #fff;
$black: #000;

.student_access_date_picker{
    position: relative;

    .student_access_date_picker_label{
        color: #81a0ea !important;
        font-weight: 600 !important;
        margin-left: 5px;
    }

    .react-datepicker-wrapper{
        width: 100%;
    }

    .message_error{
        bottom: 9px;
        left: 0;
    }

    .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle{
        border-top-color: #333333;
    }

    .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after{
        border-bottom-color: $dark_gray;
    }

    .react-datepicker{
        background-color: #333333;
        .react-datepicker__month-container{
            .react-datepicker__header{
                background-color: $dark_gray;
                padding: 20px;

                .react-datepicker__current-month{
                    color: $white;
                    font: 14px/18px $Poppins;
                }

                .react-datepicker__day-names{
                    .react-datepicker__day-name{
                        color: $white;
                        font: 14px/18px $Poppins;
                        margin-top: 10px;
                    }
                }
            }
            
            .react-datepicker__month{
                .react-datepicker__week{
                    .react-datepicker__day{
                        align-items: center;
                        color: $white;
                        display: inline-flex;
                        flex-wrap: wrap;
                        font: 12px/16px $Poppins;
                        height: 30px;
                        justify-content: center;
                        width: 30px;

                        &.react-datepicker__day--selected, &.react-datepicker__day--today{
                            background-color: $dark_gray;
                        }

                        &.react-datepicker__day--outside-month{
                            opacity: .5;
                        }

                        &:hover{
                            background-color: $dark_gray;
                            opacity: .5;
                        }
                    }
                }
            }
        }

        .react-datepicker__navigation{
            top: 20px;

            .react-datepicker__navigation-icon{
                &::before{
                    border-color: $white;
                    opacity: .5;
                }

                &:hover{
                    &::before{
                        opacity: 1;
                    }
                }
            }
        }

        .react-datepicker__navigation--previous{
            left: 17px;
        }
        
        .react-datepicker__navigation--next{
            right: 17px;
        }
    }
}