$Poppins: "Poppins", sans-serif;
$blue: #81A0EA;
$white: #fff;

.table_container#course_checklist_table{
    border-radius: 4px;
    height: 100%;
    margin-top: 0px;
    overflow: auto;
    position: relative;
    width: 100%;
    *::-webkit-scrollbar {
        height: 9px;
        width: 9px;
    }
    .sticky-table{
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.14), rgba(255, 255, 255, 0.14)), #121212;
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.07);
        width: 100%;
        &.table_loading{
            .sticky-table-table{
                height: 100%;
                #no_results_found{
                    align-items: center;
                    justify-content: center;
                    width: 50px;
                }
            }
        }
        .sticky-table-row{
            &.checklist_loading_container{
                height: 100px !important;
                position: relative;
                #checklist_loading{
                    align-items: center;
                    display: flex;
                    flex-direction: column;
                    padding-block: 20px;
                    position: absolute;
                    width: 100%;
                    div{
                        background-image: url("https://codingdojo_assets.s3.us-east-1.amazonaws.com/learn_platform/admin/global/table_loader.gif");
                        background-size: cover;
                        height: 30px;
                        margin-bottom: 7px;
                        mix-blend-mode: lighten;
                        width: 30px;
                    }
                    span{
                        color: rgba(255, 255, 255, 0.88);
                        font: normal 500 14px/130% $Poppins;
                    }
                }
            }
            &.no_data_found{
                div{
                    left: 50%;
                    margin-top: 10px;
                    position: absolute;
                    transform: translateX(-50%);
                }
            }
            /* Table Head */
            &:nth-child(1){
                .sticky-table-cell{
                    background: linear-gradient(0deg, rgba(255, 255, 255, 0.24), rgba(255, 255, 255, 0.24)), #121212;
                    color: rgba(255, 255, 255, 0.6);
                    cursor: unset;
                    font: normal 600 12px/130% $Poppins;
                    &:nth-child(1){
                        text-align: unset;
                    }
                    &:nth-child(2){
                        width: unset;
                    }
                    &:last-child{
                        width: 100%;
                    }
                    span{
                        display: inline-block;
                        vertical-align: bottom;
                    }
                }
            }
            /* Table Body */
            &:nth-child(n+2){
                height: 45px;
                &.checklist_unit{
                    position: relative;
                    width: 100%;
                    cursor: pointer;
                    & > .sticky-table-cell{
                        background: rgba(217, 217, 217, 0.05);
                    }
                    .unit_title{
                        align-items: center;
                        background: rgba(217, 217, 217, 0.05);
                        border-bottom: 1px solid #5D5D5D;
                        color: #fff;
                        display: flex;
                        font: normal 400 12px/140% $Poppins;
                        height: 45px;
                        padding-left: 12px;
                        position: absolute;
                        width: 100%;
                        .sort_icon_container{
                            display: flex;
                            margin-bottom: 2px;
                            svg{
                                color: rgba(255, 255, 255, 0.6);
                                height: 15px;
                                margin-left: 20px;
                            }
                        }
                    }
                }
                .sticky-table-cell{
                    background: transparent;
                    border-bottom: 1px solid #5D5D5D;
                    border-top: 1px solid transparent;
                    color: $white;
                    font: normal 400 12px/140% $Poppins;
                    &:nth-child(1){
                        text-align: left;
                    }
                    .checkbox{
                        input[type="checkbox"], .fa-check{
                            display: none;
                        }
                        input[type="checkbox"]:checked ~ label{
                            .checkbox_container{
                                background: linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), #121212;
                                border-radius: 4px;                                
                                border: 1px solid #415075;
                                .fa-check{
                                    color: #81A0EA;
                                    display: inline;
                                    font-size: 10px;
                                }
                            }    
                        }
                        label{
                            align-items: center;
                            cursor: pointer;
                            display: flex;
                            margin-bottom: 0px;
                            width: 100%;
                            .checkbox_container{ 
                                align-items: center;
                                background: $white;
                                border-radius: 4px;
                                border: 1px solid transparent;
                                cursor: pointer;
                                display: flex;
                                height: 15px;
                                justify-content: center;
                                margin-right: 10px;
                                width: 15px;
                            }                       
                        }
                    }
                    input{
                        background: transparent;
                        border: none;
                        color: $white;
                        font: normal 400 12px/140% $Poppins;
                        width: 100%;
                        &:focus{
                            outline: none;
                        }
                        &::placeholder{
                            color: $white;
                        }
                    }
                    .course_select{
                        background-color: transparent;
                        max-height: unset;
                        max-width: unset;
                        padding: 0 !important;
                        width: 100%;
                        &.react-dropdown-select[aria-expanded="true"]{
                            border: unset !important;
                            .react-dropdown-select-dropdown{
                                border: unset !important;
                                max-height: 300px;
                            }
                        }
                        &.has_value{
                            border: none !important;
                            .react-dropdown-select-content{
                                color: rgba(255, 255, 255, 0.88);
                            }
                        }
                        &.has_value_week{
                            .react-dropdown-select-content{
                                justify-content: flex-start;
                                &::before{
                                    content: "Week";
                                    margin-right: 2px;
                                }
                            }
                        }
                        .react-dropdown-select-content{
                            font-weight: 400;
                        }
                        .react-dropdown-select-dropdown-handle{
                            margin: unset !important;
                        }
                        .react-dropdown-select-dropdown{
                            width: 100%;
                            top: -14px;
                            .react-dropdown-select-item{
                                font: normal 400 14px/140% $Poppins;
                                padding: 17px 20px;
                                &:hover{
                                    background: #595959;
                                }
                            }
                            .react-dropdown-select-item-selected{
                                background: #595959 !important;
                                &:after{
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }
        }
        #no_results_found{
            margin-left: unset;
            position: absolute;
        }
    }
}