#sidebar_container{
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.12)), #121212;
    width: 62px;
    min-width: 62px;
    height: 100%;
    padding: 96px 0px 10px 0px;
    position: relative;

    #sidebar_buttons_list{
        margin-bottom: 0px;

        li{
            display: flex;
            justify-content: center;
            margin-bottom: 8px;

            &:last-child{
                margin-bottom: 0px;
            }

            &.active{
                a{
                    background: rgba(204, 224, 255, 0.14);
                    border-radius: 2px;
                    color:  #81A0EA;
                    span{
                        &.users{
                            background-position: -33px 0px;
                        }
                        &.key{
                            background-position: -102px 0px;
                        }
                        &.calendar{
                            background-position: -169px 0px;
                        }
                    }
                }
            }
            
            a{
                align-items: center;
                border-radius: 2px;
                color: #fff;
                display: flex;
                height: 38px;
                justify-content: center;
                text-decoration: none;
                width: 42px;
                span{
                    background-image: url("https://assets.codingdojo.com/learn_platform/admin/global/side_navigation_icons_v2.svg");
                    background-position: 0px 0px;
                    height: 23px;
                    width: 23px;
                    &.key{
                        background-position: -70px 0px;
                    }
                    &.calendar{
                        background-position: -136px 0px;
                    }
                }

            }
            
        }
    }

    #settings_button{
        width: 42px;
        height: 38px;
        position: absolute;
        bottom: 10px;
        left: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
    }
}