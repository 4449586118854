$Poppins: "Poppins", sans-serif;


/* TO BE TRANSFERRED */ 
.popover.profile_popover{
    box-sizing: border-box;
    max-width: 352px;
    width: 352px;
    z-index: 9999999;
    
    &.student{
        border: 1px solid #C2D4FF;
        
        .popover_header{
            background-image: url("../../../assets/images/global/student_profile_bg.png");
            img{
                border: 4px solid #2C6BFF;
            }
        }
    }
    
    &.instructor{
        border: 1px solid #F9CFA9;
        .popover_header{
            background-image: url("../../../assets/images/global/instructor_profile_bg.png");
            img{
                border: 4px solid #EF7E1B;
            }
        }
    }

    .arrow{
        display: none;
    }

    .popover_header{
        background-color: gray;
        display: flex;
        height: 95px;
        justify-content: center;
        position: relative;

        img{
            border-radius: 50px;
            box-sizing: border-box;
            height: 100px;
            object-fit: contain;
            position: relative;
            top: 47px;
            width: 100px;
        }
    }

    .popover_body{
        padding: 76px 38px 39px 38px;
        text-align: center;

        h5{
            color: #333333;
            font-family: $Poppins;
            font-size: 13px;
            font-style: normal;
            font-weight: 500;
            line-height: 19px;
            margin-bottom: 10px;
        }

        >span{
            color: #8A8A8A;
            display: block;
            font-family: $Poppins;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: 19px;
            margin-bottom: 9px;
        }

        .earned_belt_list{
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            margin-bottom: 18px;
            margin-top: 20px;

            .belt_item{
                margin-right: 15px;

                &:last-child{
                    margin-right: 0px;
                }

                .belt_icon{
                    background-repeat: no-repeat;
                    display: inline-block;
                    height: 46px;
                    margin-bottom: 11px;
                    width: 62px;
                }
            }
        }

        .assignment_forum_details{
            align-items: center;
            display: flex;
            justify-content: space-between;
            margin-bottom: 0px;

            li{
                display: flex;
                flex-direction: column;

                span{
                    &:first-child{
                        color: #333333;
                        font-family: $Poppins;
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 30px;
                        margin-bottom: 8px;
                    }

                    &:last-child{
                        color: #8A8A8A;
                        font-family: $Poppins;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 18px;
                        text-align: center;
                        text-transform: uppercase;
                    }
                }
            }
        }
    }
}

.is_dark_mode{
    .popover.profile_popover{
        background: #333333;
        
        &.student{
            border: 1px solid #C2D4FF;
            .popover_header{
                img{
                    border: 4px solid #2C6BFF;
                }
            }
        }
        
        &.instructor{
            border: 1px solid #F9CFA9;
            .popover_header{
                img{
                    border: 4px solid #EF7E1B;
                }
            }
        }
    
        .popover_body{
            h5{
                color: #E5E5E5;
            }
    
            >span{
                color: rgba(255, 255, 255, 0.48);
            }
            .assignment_forum_details{
                li{
                    span{
                        &:first-child{
                            color: #E5E5E5;
                        }
    
                        &:last-child{
                            color: rgba(255, 255, 255, 0.48);
                        }
                    }
                }
            }
        }
    }
}