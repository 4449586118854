#unit_details_container{
    -webkit-transition: all .5s;
    background: #121212;
    bottom: 0;
    height: calc(100% - 73px);
    padding-right: 30px;
    position: absolute;
    right: -100%;
    transition: all .5s;  
    width: calc(100% - 30px);
    z-index: 1039;

    &.show{
        -webkit-transition: all .5s;
        right: 0;
        transition: all .5s;  
    }
    #unit_detail_navigation{
        align-items: center;
        display: flex;
        margin: 20px 0px 10px 0px;
        #return_button{
            background-color: rgba(204, 224, 255, 0.14);
            border-radius: 2px;
            border: none;
            margin-right: 26px;
            outline: none;
            padding: 8px 8px 2px 8px;
            span{
                background: url("https://assets.codingdojo.com/learn_platform/admin/curriculum_management/admin_curriculum_management_sprite.png") no-repeat -245px -22px;
                display: inline-block;
                height: 16px;
                width: 16px;
            }
        }
        .unit_details_breadcrumb{
            align-items: center;
            display: flex;
            gap: 15px;
            margin-right: auto;
            p{
                color: #8F8F8F;
                font: 400 12px/14.4px "Poppins";
                margin: 0;
            }
            .arrow_icon{
                background: url("https://assets.codingdojo.com/learn_platform/admin/curriculum_management/admin_curriculum_management_sprite.png") no-repeat -306px -22px;
                flex-shrink: 0;
                height: 16px;
                opacity: .6;
                width: 16px;
            }
        }
        .unit_action{
            background: rgba(204, 224, 255, 0.14);
            border-radius: 2px;
            border: none;
            color: #81A0EA;
            font: normal 600 12px/130% "Poppins";
            margin-left: 10px;
            outline: none;
            padding: 9px 15px 8px 14px;
            text-align: center;
            &:nth-child(3){
                max-width: 90px;
                min-width: 90px;
            }
        }
    }
    #units_details_loading_container{
        align-items: center;
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.14), rgba(255, 255, 255, 0.14)), #121212;
        display: flex;
        flex-direction: column;
        left: 50%;
        padding: 155px;
        position: sticky;
        transform: translate(-50%, -4%);
        width: 50px;
        div{
            background-image: url("https://codingdojo_assets.s3.us-east-1.amazonaws.com/learn_platform/admin/global/table_loader.gif");
            background-size: cover;
            height: 30px;
            margin-bottom: 7px;
            mix-blend-mode: lighten;
            width: 30px;
        }
        span{
            color: rgba(255, 255, 255, 0.88);
            font: normal 500 14px/130% 'Poppins';
        }
    }
    
    #unit_detail_container{
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.14), rgba(255, 255, 255, 0.14)), #121212;
        border-radius: 4px;
        height: calc(100vh - 166px);
        overflow: auto;
        padding: 30px 20px;
    
        div.unit_details_content_list{
            display: flex;
            div.unit_icon{
                align-self: flex-start;
                cursor: pointer;
                display: inline-flex;
                justify-content: center;
                margin: 8px 16px 0px 24px;
                vertical-align: top;
                width: 48px;
                &.is_disabled{
                    pointer-events: none;
                }
                &:hover{
                    img{
                        display: none;
                    }
                    div.select_unit_image{
                        display: inline-flex;
                    }
                }
    
                img{
                    cursor: pointer;
                    height: 48px;
                    vertical-align: top;
                    width: 43px;
                    &:hover{
                        visibility: hidden;
                    }
                }
            
                div.select_unit_image{
                    background: #595959;
                    border-radius: 4px;
                    border: 1px dashed #81A0EA;
                    cursor: pointer;
                    display: none;
                    height: 48px;
                    justify-content: center;
                    vertical-align: top;
                    width: 48px;
                    &::before{
                        background: url("https://assets.codingdojo.com/learn_platform/admin/curriculum_management/admin_curriculum_management_sprite.png") no-repeat -763px -23px;
                        content: "";
                        display: block;
                        height: 14px;
                        margin: auto;
                        width: 14px;
                    }
                }
            }
            #unit_details_header{
                padding-top: 3px;
                h3{
                    display: inline;
                    font: normal 700 16px/130% 'Poppins';
                    line-height: 25px;
                }
                h4{
                    font: normal 400 12px/130% 'Poppins';
                    margin: 2px 0px 16px 0px;
                    opacity: 0.5;
                }
                span.unit_type{
                    background: #595959;
                    border-radius: 20px;
                    font: normal 400 12px/130% 'Poppins';
                    margin-left: 10px;
                    opacity: 0.8;
                    padding: 3px 12px 4px 13px;
                    vertical-align: text-top;
                }
                ul{
                    display: grid;
                    grid-template-columns: repeat(5, 1fr);
                    list-style: none;
                    margin: 15px 0px 0px 0px;
                    max-width: 826px;
                    padding-left: 0;
                    row-gap: 17px;
                    width: 100%;
                    li.unit_details{
                        &:nth-child(6){
                            text-transform: capitalize;
                        }
                        &:nth-child(7){
                            word-wrap: break-word;
                        }
                        &.empty_unit_details, &.language{
                            min-width: max-content;
                        } 
                        span.unit_icon{
                            color: rgba(255, 255, 255, 0.88);
                            font: normal 400 12px/120% "Poppins";
                            opacity: 0.5;
                            &::before{
                                content: "";
                                display: inline-block;
                                margin-right: 6px;
                            }
                            &.year_icon{
                                &::before{
                                    background: url("https://assets.codingdojo.com/learn_platform/admin/curriculum_management/admin_curriculum_management_sprite.png") no-repeat -399px -25px;
                                    height: 12px;
                                    width: 12px;
                                }
                            }
                            &.pacing_icon{
                                &::before{
                                    background: url("https://assets.codingdojo.com/learn_platform/admin/curriculum_management/admin_curriculum_management_sprite.png") no-repeat -429px -25px;
                                    height: 12px;
                                    width: 12px;
                                }
                            }
                            &.version_icon{
                                &::before{
                                    background: url("https://assets.codingdojo.com/learn_platform/admin/curriculum_management/admin_curriculum_management_sprite.png") no-repeat -703px -25px;
                                    height: 10px;
                                    width: 11px;
                                }
                            }
                            &.status_icon{
                                &::before{
                                    background: url("https://assets.codingdojo.com/learn_platform/admin/curriculum_management/admin_curriculum_management_sprite.png") no-repeat -281px -25px;
                                    height: 10px;
                                    width: 10px;
                                }
                            }
                            &.language_icon{
                                &::before{
                                    background: url("https://assets.codingdojo.com/learn_platform/admin/curriculum_management/admin_curriculum_management_sprite.png") no-repeat -518px -25px;
                                    height: 10px;
                                    width: 12px;
                                }
                            }
                            &.discipline_icon{
                                &::before{
                                    background: url("https://assets.codingdojo.com/learn_platform/admin/curriculum_management/admin_curriculum_management_sprite.png") no-repeat -732px -25px;
                                    height: 10px;
                                    width: 12px;
                                }
                            }
                            &.created_by_icon{
                                &::before{
                                    background: url("https://assets.codingdojo.com/learn_platform/admin/curriculum_management/admin_curriculum_management_sprite.png") no-repeat -579px -24px;
                                    height: 12px;
                                    width: 12px;
                                }
                            }
                            &.last_modified_by_icon{
                                &::before{
                                    background: url("https://assets.codingdojo.com/learn_platform/admin/curriculum_management/admin_curriculum_management_sprite.png") no-repeat -609px -24px;
                                    height: 12px;
                                    width: 11px;
                                }
                            }
                        }
                        span.last_modified_date{
                            color: rgba(255, 255, 255, 0.88);
                            font: normal 400 10px/120% "Poppins";
                            padding: 0px 0px 0px 18px;
                            white-space: nowrap;
                        }
                        span:nth-child(2){
                            display: block;
                            font: normal 600 14px/120% "Poppins";
                            padding: 3px 0px 0px 18px;
                        }
                    }
                }
            }
        }
    
        #units_details_table_buttons_container{
            align-items: center;
            border-bottom: 1px solid rgba(204, 224, 255, 0.14);
            display: flex;
            justify-content: space-between;
            margin-bottom: 10px;
            margin-top: 21px;
            .units_details_table_header{
                display: flex;
                list-style-type: none;
                margin: 0;
                padding: 0;
                li{
                    align-items: center;
                    border-bottom: 2px solid transparent;
                    color: rgba(255, 255, 255, 0.88);
                    cursor: pointer;
                    display: flex;
                    font: normal 400 14px/120% "Poppins";
                    justify-content: center;
                    letter-spacing: 0.35px;
                    min-height: 60px;
                    min-width: 120px;
                    width: 120px;
                    &:nth-child(2){
                        width: 240px;
                    }
                    &:nth-child(3){
                        width: 169px;
                    }
                    &:nth-child(4){
                        margin-left: 0 auto;
                    }
                    &.active_tab{
                        border-bottom: 2px solid rgba(255, 255, 255, 0.88);
                        font: normal 700 14px/120% "Poppins";
                        letter-spacing: 0px;
                    }
                }
            }

            .add_chapter_btn{
                background: rgba(204, 224, 255, 0.14);
                border-radius: 4px;
                border: none;
                color: #81A0EA;
                height: 32px;
                font: normal 600 12px/120% "Poppins";
                width: 120px;
            }
        }
    }
    #units_details_table_container{
        height: calc(100% - 290px);
    }
}
