$Poppins: 'Poppins', sans-serif;

#unit_select_icon_modal{
    left: 50%;
    max-width: none;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 892px;
    .modal-content{
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.24), rgba(255, 255, 255, 0.24)), #121212;
        border-radius: 0px;
        border: none;
        box-shadow: 0px 4px 2px rgba(0, 0, 0, 0.05);
        color: rgba(255, 255, 255, 0.88);
        font-family: $Poppins;
        .modal-header{
            background: linear-gradient(0deg, rgba(255, 255, 255, 0.14), rgba(255, 255, 255, 0.14)), #121212;
            border-radius: 0px;
            border: none;
            justify-content: left;
            padding: 18px 23px;
            position: relative;
    
            h4{
                font: normal 700 14px/130% 'Poppins';
                margin-bottom: 0px;
            }
            button{
                background-color: transparent;
                background-image: url("https://codingdojo_assets.s3.us-east-1.amazonaws.com/learn_platform/admin/edit_course/admin_edit_course_sprite.png");
                background-position: -65px 0px;
                border: none;
                height: 15px;
                outline: none;
                padding: 0px;
                position: absolute;
                right: 22px;
                top: 20px;
                width: 15px;
            }
        }
        .modal-body{
            background: #292929;
            padding: 20px 0px 0px 0px;
            #search_unit_icon_form{
                padding: 0px 31px 0px 22px;
                svg{
                    color: #73839C;
                    font-size: 13px;
                    left: 34px;
                    margin-right: 10px;
                    position: absolute;
                    top: 32px;
                    z-index: 2;
                }
                input{
                    align-items: center;
                    background: rgba(204, 224, 255, 0.14);
                    border-radius: 2px;
                    border: 1px solid transparent;
                    color: rgba(255, 255, 255, 0.88);
                    font: normal 400 12px/140% 'Poppins';
                    height: 40px;
                    line-height: 140%;
                    padding: 0px 12px 0px 36px;
                    position: relative;
                    width: 100%;
                    &.border_red{
                        border: 1px solid transparent !important;
                        &:focus{
                            border: 1px solid #E1554C !important;
                            box-shadow: 0px 0px 6px #E85353;
                        }
                    }
        
                    &:focus{
                        outline: none;
                    }
                }
            }
            form#unit_icon_form{
                #units_icons_loading_container{
                    align-items: center;
                    display: flex;
                    flex-direction: column;
                    margin: 50px 0;
                    div{
                        background: url("https://codingdojo_assets.s3.us-east-1.amazonaws.com/learn_platform/admin/global/table_loader.gif") no-repeat center / cover;
                        height: 30px;
                        margin-bottom: 7px;
                        width: 30px;
                    }
                    span{
                        color: rgba(255, 255, 255, 0.88);
                        font: normal 500 14px/130% "Poppins";
                    }
                }

                ul.unit_icon_list{
                    align-content: flex-start;
                    display: flex;
                    flex-wrap: wrap;
                    list-style-type: none;
                    margin: 20px 0px 0px 22px;
                    max-height: 288px;
                    min-height: 288px;
                    overflow-x: auto;
                    padding-left: 0px;
                    li{
                        margin: 0px 32px 35px 0px;
                        position: relative;
                        text-align: center;
                        input[type=radio]{
                            -webkit-appearance: none;
                            appearance: none;
                            background-color: transparent;
                            cursor: pointer;
                            height: 68px;
                            outline: none;
                            position: relative;
                            width: 64px;
                            z-index: 1;
                            &:checked{
                                border: 2px solid #81A0EA;
                                border-radius: 4px;
                            }
                        }
                        img{
                            height: 52px;
                            left: 0;
                            margin: auto;
                            position: absolute;
                            right: 0;
                            top: 9px;
                            width: 47px;
                        }
                    }
                }
                .form_button{
                    background: linear-gradient(0deg, rgba(255, 255, 255, 0.14), rgba(255, 255, 255, 0.14)), #121212;
                    border-radius: 2px;
                    border: none;
                    display: flex;
                    justify-content: flex-end;
                    padding: 18px 21px 18px 24px;
                    button{
                        background: transparent;
                        border: none;
                        color: #fff;
                        font: normal 600 14px/130% 'Poppins';
                        outline: none;
                        &[type="submit"]{
                            background: #494B50;
                            border-radius: 2px;
                            color: #81A0EA;
                            margin-left: 30px;
                            padding: 11px 16px;
                        }
                        &.activate_btn{
                            background: rgba(204, 224, 255, 0.14);
                            color: #81A0EA;
                        }
                    }
                }
            }
        }
    }
}