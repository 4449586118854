$color-white: #fff;
$color-blue: #2c6bff;
$color-dark-blue: #001d61;
$box-shadow: 0px 4px 8px 0px rgba(#000, .05);

#contact_us_container.container{
    column-gap: 20px;
    display: flex;
    flex-wrap: wrap;
    font: normal normal 16px/24px 'Poppins', sans-serif;
    justify-content: space-between;
    margin-top: 80px;
    padding: 0 0 70px 0;
    row-gap: 25px;
    #contact_card{
        align-items: flex-start;
        background: $color-white;
        border-radius: 6px;
        box-shadow: $box-shadow;
        display: flex;
        flex: 2;
        gap: 40px;
        min-height: 280px;
        padding: 40px;
        img{
            height: 140px;
            width: 110px;
        }
        > div{
            h4{
                color: $color-dark-blue;
                font: normal 700 26px/39px 'Poppins', sans-serif;
                margin-bottom: 10px;
                max-width: 230px;
            }
            p{
                color: #707070;
                margin-bottom: 0;
                &:nth-child(3){
                    margin-bottom: 23px;
                }
            }
        }
    }
    #contact_card_faq,
    #contact_card_touch{
        border-radius: 6px;
        box-shadow: $box-shadow;
        display: flex;
        flex-direction: column;
        flex: 1;
        min-height: 280px;
        padding: 40px 20px 20px 20px;
        h4{
            font: normal 700 26px/39px 'Poppins', sans-serif;
        }
        p{
            color: #707070;
        }
        button, a{
            align-items: center;
            border-radius: 8px;
            border: none;
            color: $color-white;
            display: flex;
            font: normal 500 16px 'Poppins', sans-serif;
            justify-content: center;
            margin-top: auto;
            text-decoration: none;
            width: fit-content;

            &:hover{
                opacity: .8;
            }

            &.big_faq_button{
                height: 60px;
                padding: 20px;
            }
        }
    }
    #contact_card_faq{
        background: #fcf3d9;
        h4{
            color: #c89a0f;
        }
        button, a{
            background: #eeba1b;
            height: 45px;
            min-width: 105px;
        }
    }
    #contact_card_touch{
        background: #c2d4ff;
        h4{
            color: $color-blue;
            max-width: 220px;
        }
        button{
            background: $color-blue;
            height: 45px;
            width: 160px;
        }
    }
}

@media only screen and (max-width: 768px){
    #contact_us_container.container{
        flex-direction: column;
        margin-top: 25px;
        max-width: 100%;
        padding: 0px 20px 120px;
        row-gap: 20px;
        #contact_card{
            flex-direction: column;
            flex: 1;
            gap: 20px;
            padding: 40px 20px 28px;
        }
        #contact_card_faq,
        #contact_card_touch{
            flex: 1;
        }
        #contact_card_touch{
            h4{
                max-width: 190px;
            }
            button{
                width: 175px
            }
        }
        #contact_card_faq{
            h4{
                max-width: 250px;
            }
            button, a{
                width: 115px;

                &.big_faq_button{
                    height: auto;
                    min-height: 60px;
                    padding: 10px 20px;
                    width: 100%;
                }
            }
        }
        h3{
            text-align: center;
            width: 100%;
        }
    }
}

body.is_dark_mode{
    #contact_us_container.container{
        #contact_card{
            background: #2A2A2A;
            h3, 
            h4,
            p{
                color: $color-white;
            }
        }
        #contact_card_touch,
        #contact_card_faq{
            button, a {
                background: $color-white;
                font-weight: 600;
            }
            h4{
                color: $color-white;
            }
            p{
                color: $color-white;
            }
        }
        #contact_card_touch{
            background: #002C94;
            button{
                color: #2C6BFF;
            }
        }
        #contact_card_faq{
            background: #BD7D00;
            button, a{
                color: #FFB524;
            }
        }
    }
}