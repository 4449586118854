$Poppins: "Poppins", sans-serif;

.course_schedule_dropdown_select{
    background: rgba(204, 224, 255, 0.14);
    border-radius: 4px;
    height: 40px !important;
    margin-bottom: 20px;
    padding: 5px 14px 4px 14px !important;
    width: 350px !important;
    ::-webkit-input-placeholder {
        /* Edge */
        color: rgba(255, 255, 255, 0.58) !important;
        font: normal 400 14px/140% $Poppins;
    }

    :-ms-input-placeholder {
        /* Internet Explorer */
        color: rgba(255, 255, 255, 0.58) !important;
        font: normal 400 14px/140% $Poppins;
    }

    ::placeholder {
        color: rgba(255, 255, 255, 0.58) !important;
        font: normal 400 14px/140% $Poppins;
    }
    .react-dropdown-select-content{
        color: rgba(255, 255, 255, 0.58) !important;
        font: normal 400 14px/140% $Poppins !important;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        .react-dropdown-select-input{
            color: rgba(255, 255, 255, 0.58);
            font: normal 400 14px/140% $Poppins;
            padding: unset;
            width: unset;
            &:focus{
                outline: none;
            }
            &:focus-within{
                border: none !important;
                box-shadow: none !important;
            } 
        }
        .react-dropdown-select-option{
            display: none !important;
            visibility: hidden !important;
        }
    }
    .react-dropdown-select-dropdown{
        border-radius: 4px;
        color: rgba(255, 255, 255, 0.88);
        top: 0px !important;
        width: 350px;
        .react-dropdown-select-item{
            border: none;
            font: normal 400 14px/140% $Poppins;
            padding: 14px 20px 14px 20px; 
            &.react-dropdown-select-item-selected{
                background: transparent;
                color: #81A0EA;
                position: relative;
                &:after{
                    background-image: url("https://assets.codingdojo.com/learn_platform/admin/access_control/acl_icons_sprite.svg");
                    background-position: 14px -12px;
                    content: "";
                    display: inline-block;
                    height: 11px;
                    position: absolute;
                    right: 19px;
                    width: 13px;
                }
            }
        }
    }
    .react-dropdown-select-dropdown-handle{
        color: #81a0ea;
        height: 29px;
    }

}

.course_schedule_dropdown_select[aria-expanded="true"]{
    .react-dropdown-select-dropdown-handle{
        left: 296px;
        position: absolute; 
        top: 22px;
        z-index: 9999;
    }
    .react-dropdown-select-dropdown{
        .dropdown_search_container{
            position: relative;
        }
        .dropdown_menu{
            .tooltip{
                margin-bottom: -7px;
                .tooltip-inner {
                    background: #121212;
                }
            }
            .dropdown_item{
                span{
                    width: 100%; 
                    &.has_tooltip{
                        width: unset;
                    }
                }
            }
        }
    }
}

.had_value{
    .react-dropdown-select-content{ 
        color: #FFFFFF !important;
    }
}

.program_course_schedule{
    margin-bottom: 0px;
    .react-dropdown-select-content{  
        color: rgba(255, 255, 255, 0.58) !important; 
    }   
}

.support_instructor_course_schedule{
    margin-bottom: 0px;
}

span.selected_multi_item{
    background: #000000; 
    border-radius: 2px;
    border: 2px solid rgba(154, 194, 255, 0.3); 
    color: #FFFFFF;
    font: normal 600 12px/130% $Poppins;
    margin: 10px 10px 0px 0px;
    padding: 7px 33px 7px 12px;
    position: relative;
    span.remove{
        background: url("https://assets.codingdojo.com/learn_platform/admin/global/dropdown_icons.png") no-repeat -24px -4px;
        cursor: pointer;
        display: inline-block;
        height: 12px;
        margin-left: 9.17px;
        margin-top: -1px;
        position: absolute;
        right: 10px;
        top: 10px;
        vertical-align: middle;
        width: 12px;
    } 
}