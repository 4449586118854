
#bookmarks_modal{
    height: 826px;
    max-width: 791px;
    width: 791px;
    .modal-content{
        background: #FFFFFF;
        border-radius: 6px;
        box-shadow: 0px 4px 2px rgba(0, 0, 0, 0.05);
        height: 100%;
        .modal-header{
            border: none;
            justify-content: center;
            padding: 41px 40px 23px 40px;
            position: relative;
            .modal-title{
                color: #152C61;
                font: normal 500 36px/54px 'Poppins', sans-serif;
                text-align: center;
            }
            button{
                background-color: transparent;
                border: none;
                outline: none;
                padding: 0px;
                position: absolute;
                right: 26px;
                top: 26px;
                .close_icon{
                    background-image: url("https://assets.codingdojo.com/learn_platform/global/close_icon_new.png");
                    display: inline-block;
                    height: 33px;
                    width: 33px;
                }
            }
        }
        .modal-body{
            overflow: auto;
            padding-bottom: 53px; 
            >p{
                color: #152C61;
                font: normal 300 16px/24px 'Poppins', sans-serif;
                text-align: center;
            }
            >ul{
                margin: 0px auto;
                width: 640px;
                >li{
                    background: #FFFFFF;
                    border-radius: 2px;
                    border: 2px solid #DCE6FF;
                    box-sizing: border-box;
                    margin-bottom: 10px;
                    padding: 14px 20px 20px 20px;
                    position: relative;
                    &:last-child{
                        margin-bottom: 0px;
                    }
                    form{
                        position: absolute;
                        right: 14px;
                        top: 5px;
                        button{
                            background-color: transparent;
                            border: none;
                            color: #4CE164;
                            font-size: 26px;
                            outline: none;
                            &.outline_button{
                                color: #152C61;
                            }
                        }
                    }
                    a{
                        align-items: flex-start;
                        display: flex;
                        text-decoration: none;
                        .left_container{
                            margin-right: 20px;
                            min-width: 182px;
                            h5{
                                color: #152C61;
                                font: normal 500 20px/30px 'Poppins', sans-serif;
                                margin-bottom: 27px;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap; 
                            }
                            .icons_container{
                                display: flex;
                                align-items: center;
                                span{
                                    align-items: center;
                                    background-color: #EEC10C;
                                    border-radius: 100%;
                                    display: flex;
                                    height: 30px;
                                    justify-content: center;
                                    margin-right: 25px;
                                    width: 30px;
                                    &.todo{
                                        background-color: #B286C3;
                                        .icon{
                                            background-position: -73px 0px;
                                        }
                                    }
                                    &.lesson{
                                        background-color: #EEC10C;
                                        .icon{
                                            background-position: -2px 0px;
                                        }
                                    }
                                    &.chapter_survey{
                                        background-color: #E06666;
                                        .icon{
                                            background-position: -20px 0px;
                                        }
                                    }
                                    &.quiz{
                                        background-color: #F78723;
                                        .icon{
                                            background-position: -54px 0px;
                                        }
                                    }
                                    .icon{
                                        background-image: url("https://assets.codingdojo.com/learn_platform/global/icons.png");
                                        background-repeat: no-repeat;
                                        height: 18px;
                                        width: 18px;
                                        &.lesson{
                                            background-position: -73px 0px;
                                        }
                                        &.chapter_survey{
                                            background-position: -73px 0px;
                                        }
                                    }
                                }
                                img{
                                    height: 33px;
                                    width: 28px;
                                }
                            }                        
                        }
                        .right_container{
                            align-content: space-between;
                            display: flex;
                            flex-wrap: wrap;
                            min-height: 90px;
                            width: 394px;
                            ul{
                                display: flex;
                                padding-right: 35px;
                                li{
                                    color: #152C61;
                                    font: normal 300 14px/21px 'Poppins', sans-serif;
                                    margin-right: 15px;
                                    padding-right: 10px;
                                    position: relative;
                                    &:last-child{
                                        margin-right: 0px;
                                        padding-right: 0px;
                                        &:after{
                                            display: none;
                                        }
                                    }
                                    &:after{
                                        background-image: url("https://assets.codingdojo.com/learn_platform/global/small_arrow_right.png");
                                        content: "";
                                        display: inline-block;
                                        height: 11px;
                                        position: absolute;
                                        right: -6px;
                                        top: 5px;
                                        width: 7px;
                                    }
                                }
                            }
                            p{
                                color: #152C61;
                                font: normal 300 14px/21px 'Poppins', sans-serif;
                                margin-bottom: 0px;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap; 
                            }
                        }
                    }
                }
            }
        }
    }
    @media only screen and (max-width: 768px){
        .modal-content{
            font: normal 500 16px/24px 'Poppins', sans-serif;
            .modal-header{
                .modal-title{
                    font: normal 500 16px/24px 'Poppins', sans-serif;
                }
                
            }
            .modal-body{
                padding-top: 10px;
                >p{
                    margin-top: 23px;
                }
                > ul{
                    width: 100%;
                    > li{
                        margin-bottom: 24px;
                        form{
                            top: 14px;
                        }
                        a{
                            .left_container{
                                min-width: unset;
                                width: 91px;
                                h5{
                                    font: normal 500 16px/24px 'Poppins', sans-serif;
                                }
                                .icons_container{
                                    span{
                                        margin-right: 14px;
                                    }
                                }

                            }
                            .right_container{
                                padding-right: 20px;
                                width: 100%;
                                p{
                                    -webkit-box-orient: vertical;
                                    -webkit-line-clamp: 3;
                                    display: -webkit-box;
                                    overflow: hidden;
                                    white-space: unset;
                                    word-break: break-word;
                                }
                                ul{ 
                                    flex-wrap: wrap;     
                                    margin-bottom: 11px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}