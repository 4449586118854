$Poppins: "Poppins", sans-serif;

#create_course_schedule_modal{
    font-family: "Poppins", sans-serif;
    max-width: 400px;
    width: 400px;
    z-index: 9999; 
    .modal-content{
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), #121212;
        border: none;
        box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.15);
        box-sizing: border-box;

        .modal-header{
            background: linear-gradient(0deg, rgba(255, 255, 255, 0.14), rgba(255, 255, 255, 0.14)), #121212;
            border: none;
            justify-content: flex-start;
            padding: 17px 24px;
            position: relative;

            .modal-title{
                color: rgba(255, 255, 255, 0.88);
                font-family: $Poppins;
                font-size: 14px;
                font-style: normal;
                font-weight: bold;
                line-height: 130%;
            }

            button{
                background-color: transparent;
                border: none;
                outline: none;
                padding: 0px;
                position: absolute;
                right: 17px;
                top: 9px;

                .close_icon{
                    background-image: url("https://assets.codingdojo.com/learn_platform/global/close_icon_new.png");
                    background-position: -61px 0px;
                    display: inline-block;
                    height: 33px;
                    width: 33px;
                }
            }
        }
        form{
            .modal-body{
                display: flex;
                flex-wrap: wrap;
                padding: 20px 24px 40px 26px; 

                h5{
                    align-items: center;
                    color: rgba(255, 255, 255, 0.88);
                    display: flex;
                    font-family: $Poppins;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 130%;
                    margin-bottom: 20px;
                    width: 100%;
                }
                label{
                    color: rgba(255, 255, 255, 0.4);
                    font: normal 600 12px/130% $Poppins;
                    margin-bottom: 9px;
                    width: 100%;
                }
                label.course{
                    margin-top: 20px;
                }
                div{
                    div.programs_course_schedule,
                    div.support_instructor{
                        margin-bottom: 0px;
                    }
                }
                .duration_date_picker{
                    align-items: center;
                    background: rgba(204, 224, 255, 0.14);
                    border-radius: 4px;
                    color: #FFFFFF;
                    display: flex;
                    margin: 1px 0px 18px 0px;
                    padding: 8px 11px;
                    position: relative;
                    width: 100%;
                    .calendar_icon, 
                    .date_pickers, 
                    .arrow_left_icon, 
                    .date_pickers{
                        opacity: .58;
                    }
                }
                .has_start_date{
                    .calendar_icon,
                    span:nth-child(2){
                        opacity: 1;
                    }
                }

                .has_end_date{ 
                    .arrow_left_icon, 
                    span:nth-child(4){
                        opacity: 1;
                    }
                }

                div.course_type_container{
                    align-items: center;
                    display: flex;
                    margin-bottom: 19px;
                    &.discussion_is_selected{
                        margin-bottom: 0px !important;
                    }        
                    label{
                        cursor: pointer;
                        font: normal 400 14px/140% 'Poppins';
                        margin: 0px 24px 0px 12px;
                    }
                }

                div.attendance_recording_container{
                    align-items: center;
                    display: flex;
                    flex-wrap: wrap;
                    margin-bottom: 19px;        
                    label{
                        font: normal 400 14px/140% 'Poppins';
                        margin: 0px 0px 0px 12px;
                        width: 315px;
                    }
                    input[type=radio], label{
                        cursor: pointer;
                        margin-top: 9px;
                    }
                }
                
                div.instruction_days_container{
                    column-gap: 24px;
                    display: grid;
                    grid-template-columns: 50px 50px 50px 50px 50px;
                    margin-top: 5px;
                    row-gap: 10px;
                    div.days_checkbox{
                        background: rgba(204, 224, 255, 0.14);
                        border-radius: 4px;
                        height: 32px;
                        position: relative;
                        input[type=checkbox]{
                            -webkit-appearance: none;
                            appearance: none;
                            background-color: transparent;
                            cursor: pointer;
                            height: 32px;
                            position: relative;
                            width: 50px;
                            z-index: 1;
                        }
                        input[type=checkbox]:checked{
                            -webkit-appearance: none;
                            appearance: none;
                            background: rgba(204, 224, 255, 0.14);
                            border-radius: 4px;
                            border: 1px solid #81A0EA;
                        }
                        label{
                            align-items: center;
                            color: rgba(255, 255, 255, 0.88);
                            cursor: pointer;
                            display: flex;
                            flex-direction: column;
                            font: normal 600 14px/140% 'Poppins';
                            height: 100%;
                            justify-content: center;
                            position: absolute;
                            top: 0; 
                            z-index: 0;
                        }
                    }
                }

            }
        }

        .modal-footer{
            background: linear-gradient(0deg, rgba(255, 255, 255, 0.14), rgba(255, 255, 255, 0.14)), #121212;
            border: none;
            display: flex;
            padding: 20px 24px;
            
            button{
                background: rgba(204, 224, 255, 0.14);
                border-radius: 4px;
                border: none;
                color: #81A0EA;
                font-family: $Poppins;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                height: 40px;
                margin: 0px;
                outline: none;
                padding: 10px 14px;
            }
            button.prev_button{
                background: transparent;
            }
        }
    }
}
