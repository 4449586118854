$Poppins: "Poppins", sans-serif;

.curriculum_management_unit_container{

    #curriculum_management_unit_tools{
        align-items: center;
        border-bottom: 1px solid  rgba(#D9D9D9, 0.45);
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
    
        #create_unit_btn{
            background-color: rgba(204, 224, 255, 0.14);
            border-radius: 2px;
            border: none;
            color: #81A0EA;
            cursor: pointer;
            font: 600 12px/15.6px $Poppins;
            height: 32px;
            outline:none;
            width: 143px;  
        }
    }
    
    #search_by_keyword_form{
        display: flex;
        position: absolute;
        p{
            color: #B2B2B2;
            display: none;
            font-family: "Poppins", sans-serif;
            font-size: 12px;
            line-height: 14px;
            margin:6px 0px 0px 10px;
            width: 320px;
        }
        svg{
            color: #73839C;
            font-size: 14px;
            left: 12px;
            margin-right: 10px;
            position: absolute;
            top: 12px;
            z-index: 2;
        }
        input{
            align-items: center;
            background: linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), #121212;
            border-radius: 4px;
            border: 1px solid transparent;
            color: rgba(255, 255, 255, 0.88);
            font-family: $Poppins;
            font-size: 12px;
            font-style: normal;
            font-weight: normal;
            height: 40px;
            line-height: 140%;
            padding: 0px 12px 0px 36px;
            position: relative;
            width: 256px;
    
            &:focus{
                background: #2A2A2A;
                border: 1px solid #81A0EA;
                box-shadow: 0px 0px 6px #81A0EA;
                outline: none;
            }
    
            &:focus + p{
                display: block;
            }
        }
    }


    .checked_buttons_container {
        margin-bottom: 17px;
        button {
            background: #2B2E33;
            border-radius: 2px;
            border: none;
            color: #E5E5E5;
            line-height: 130%;
            margin-right: 10px;
            padding: 7px 12px 7px 40px;
            position: relative;

            &::before {
                background: url("https://assets.codingdojo.com/learn_platform/admin/curriculum_management/admin_curriculum_management_sprite.png") no-repeat;
                content: "";
                height: 20px;
                left: 10px;
                position: absolute;
                top: 5px;
                width: 20px;
            }
            &:nth-child(1){
                &::before{
                    background-position: -361px -19px;
                }
            }
            &:nth-child(2){
                &::before{
                    background-position: -361px -19px;
                }
            }
            &:nth-child(3){
                &::before{
                    background-position: -84px -19px;
                }
            }
            &:nth-child(4){
                &::before{
                    background-position: -16px -19px;
                }
            }
            &:nth-child(5){
                &::before{
                    background-position: -330px -19px;
                }
            }
            &:disabled{
                opacity: 0.5;
            }
        }
    }
}