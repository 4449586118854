$Poppins: 'Poppins', sans-serif;


#pagination_container{
    align-items: center;
    display: flex;
    margin-top: 22px;

    div {
        align-items: center;
        display: flex;

        span{
            color: rgba(255, 255, 255, 0.88);
            font-family: $Poppins;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 130%;
            margin-right: 20px;
        }

        #pagination_btns_container{
            display: flex;

            >button{
                background-color: transparent;
                border: none;
                color: #fff;
                font-size: 15px;
                outline: none;

                &:first-child{
                    margin-right: 8px;
                }

                &:last-child{
                    margin-left: 8px;
                }
            }

            @for $ctr from 1 through 1000 {
                ul[activePage="#{$ctr}"] {
                    li{
                        /* Previous ... */ 
                        &:nth-child(#{$ctr - 3}):not(:nth-child(1)) {
                            display: inline-block;
                            width: 39px;
                        
                            button{
                                display: none;
                            }
        
                            &:after {
                                color: #5D6064;
                                content: '...';
                                font-size: 32px;
                                left: 5px;
                                letter-spacing: 4px;
                                position: absolute;
                                top: -8px;
                            }
                        }

                        &:nth-child(#{$ctr - 2}), &:nth-child(#{$ctr - 1}), &:nth-child(#{$ctr + 1}), &:nth-child(#{$ctr + 2}) {
                            display: inline-block;
                        }
                        
                        /* Active Page */ 
                        &:nth-child(#{$ctr + 1}) {
                            color: #fff;
                            display: inline-block;
                        }
                        
                        /* Next ... */ 
                        &:nth-child(#{$ctr + 3}):not(:nth-last-child(1)) {
                            display: inline-block;
                            width: 39px;
                        
                            button{
                                display: none;
                            }
                            &:after {
                                color: #5D6064;
                                content: '...';
                                font-size: 32px;
                                left: 5px;
                                letter-spacing: 4px;
                                position: absolute;
                                top: -8px;
                            }
                        }
                    }
                }
            }

            ul{
                display: flex;
                flex-wrap: wrap;
                margin: 0px;

                &:not([actpage="1"]) &_i:nth-child(1) {
                display: inline-block;
                }

                li{
                    display: none;
                    margin-right: 8px;
                    position: relative;

                    &:last-child,
                    &:nth-child(1),
                    &:nth-last-child(1) {
                        display: inline-block;
                    }

                    &.active{
                        display: inline-block;
                        button{
                            background-color: transparent;
                            border: 2px solid rgba(204, 224, 255, 0.14);
                        }
                    }

                    &:last-child{
                        margin-right: 0px;
                    }

                    button{
                        align-items: center;
                        background: rgba(204, 224, 255, 0.14);
                        border-radius: 4px;
                        border: 2px solid transparent;
                        color: rgba(255, 255, 255, 0.88);
                        display: flex;
                        font-family: $Poppins;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 500;
                        justify-content: center;
                        line-height: 130%;
                        min-height: 30px;
                        min-width: 30px;
                        outline: none;
                        padding: 0px 3px;
                    }
                }
            }
        }
    }

    #show_pagination_control_button{
        background-color: transparent;
        border: none;
        color: rgba(255, 255, 255, 0.88);
        display: flex;
        outline: none;

        svg{
            font-size: 18px;
            margin-left: 5px
        }
    }
}