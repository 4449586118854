#stats_belt_exam_history_container{
    background: #fff;
    border-radius: 6px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
    min-height: 570px;
    padding: 28px 24px;
    h3{
        display: inline-block;
        font: normal 500 16px/24px 'Poppins', sans-serif;
        margin-bottom: 24px;
        margin-right: 22px;
        vertical-align: top;
    }
    .scroll_container{
        overflow-x: auto;
    }
}