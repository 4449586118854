.widget_block{
    -khtml-transition: transform .2s;
    -moz-transition: transform .2s;
    -ms-transition: transform .2s;
    -o-transition: transform .2s;
    -webkit-transition: transform .2s;
    align-items: center;
    background: #fff;
    border-radius: 6px;
    border: 2px solid transparent;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
    display: inline-flex;
    flex-direction: column;
    font: normal 400 12px/18px 'Poppins', sans-serif;
    margin-right: 20px;
    min-height: 406px;
    padding: 30px 5px;
    transition: transform .2s;
    width: 469px;
    &.expanded_block{
        width: 847px;
    }

    &.attendance_rate{
        .progress_container{
            &:after{
                left: -10px;
                top: 34px;
            }
        }
    }
    
    &:hover{
        box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.05);
        -khtml-transform: scale(1.02);
        -moz-transform: scale(1.02);
        -ms-transform: scale(1.02);
        -o-transform: scale(1.02);
        -webkit-transform: scale(1.02);
        transform: scale(1.02);     
    }
    &.is_active{
        border: 2px solid #2C6BFF;
    }
    &:last-child{
        margin-right: 0px;
    }

    #maintenance_widget{
        text-align: center;

        img{
            margin: 20px 0px 30px 0px;
            width: 120px;
        }
    }

    h3{
        font: normal 500 16px/24px 'Poppins', sans-serif;
        margin-bottom: 20px;
        text-align: center;
    }
    
    button{
        background: transparent;
        border: none;
        color: #707070;
        margin-right: 39px;
        outline: none !important;
        padding: 0px;
        &:last-child{
            margin-right: 0px;
        }
        &.active{
            border-bottom: 1px solid #2C6BFF;
            color: #2C6BFF;
            font: normal 600 12px/18px 'Poppins', sans-serif;
        }
    }
    p{
        color: #333333;
        font: normal 400 12px/18px 'Poppins', sans-serif;
        margin-bottom: 20px;
        max-width: 233px;
        min-height: 36px;
        text-align: center;
        &.note{
            color: #707070;
            margin: 21px 0px 0px !important;
            text-align: center;
        }
    }
    #coming_soon_container{
        img{
            width: 197px;
        }
        p{
            font: normal normal 14px/18px 'Poppins', sans-serif;
        }
    }
    .progress_container{
        border-radius: 100%;
        height: 160px;
        position: relative;
        width: 160px;
        &:after{
            background-image: url("https://assets.codingdojo.com/learn_platform/user/stats/triangle.png");
            content: "";
            height: 12px;
            left: 26px;
            position: absolute;
            top: -5px;
            width: 10px;
        }
        .CircularProgressbar-text{
            font-weight: 500;
        }
    }
    .button_container{
        margin-bottom: 26px;
    }
}
.is_dark_mode{
    #stats_widget_container{
        .widget_block{
            background: #292929;
        }
        #coming_soon_container{
            h5{
                color: #6F81AF;
            }
        }
    } 
}