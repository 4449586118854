#todo_description{
    margin: 0px auto;
    width: 83%;

    *{
        color: #152C61;
        font-family: "Poppins", sans-serif !important;
        text-shadow: none;
    }

    iframe{
        border: none !important;
        height: 410px !important;
        margin-bottom: 20px;
        width: 100% !important;
    }
    p, li{
        color: #152C61;
        font-family: 'Poppins', sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: 200% !important;
    }

    li{
        margin-bottom: 4px;
    }

    a{
        color: #2C6BFF !important;
        cursor: pointer;
        font-family: 'Poppins', sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 200%;
        text-decoration-line: underline;
    }

    img{
        max-width: 100%;
    }

    h1, h2, h3{
        color: #152C61;
        margin-bottom: 20px;
    }
    h1{
        font-size: 32px;
        font-weight: 500;
        line-height: 48px;
    }
    h2{
        font-size: 26px;
        font-weight: 500;
        line-height: 39px;
    }
    h3, h4{
        font-size: 22px;
        font-weight: 500;
        line-height: 33px;
    }
    h5, h6{
        font-size: 18px;
        font-weight: 500;
        line-height: 24px;
    }
}

#todo_info_list{
    align-items: center;
    display: flex;
    justify-content: center;
    margin-bottom: 58px;
    margin-top: 26px;

    li{
        border-right: 2px solid #DCE6FF;
        color: #152C61;
        font-family: 'Poppins', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 21px;
        margin-right: 25px;
        padding-right: 25px;

        &:last-child{
            border-right: 0px;
            margin-right: 0px;
            padding-right: 0px;
        }
    }
    @media only screen and (max-width: 640px){
        margin-bottom: 14px;
        li{
            font-size: 12px;
            margin-right: 15px;
            padding-right: 15px;
        }
    }
    @media only screen and (max-width: 540px){
        margin-bottom: 14px;
        li{
            font-size: 12px;
            margin-right: 10px;
            padding-right: 10px;
        }
    }
    @media only screen and (max-width: 490px){
        display: flex;
        flex-direction: column;
        justify-content: left;
        text-align: left;
        li{
            border-right: 0px;
            font-size: 14px;
            margin-right: 0px;
            text-align: left;
            width: 100%;
        }
    }
}