$Poppins: 'Poppins', sans-serif;
.assignment_legend_icon{
    font-size: 11px;
    margin-bottom: 5px;
}

#assignment_legend_tooltip{
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.24), rgba(255, 255, 255, 0.24)), rgba(6, 6, 6, 0.94);
    border-radius: 4px;
    border: 1px solid rgba(204, 224, 255, 0.14);
    box-sizing: border-box;
    margin-bottom: 10px;
    width: 507px;
    
    .arrow{
        bottom: 2px;
    }
    
    .tooltip-inner{
        background-color: transparent;
        max-width: 100%;
        padding: 0px;

        table{
            td,th{
                padding: 10px 17px;
            }
            thead{
                tr{
                    th{
                        color: rgba(255, 255, 255, 0.88);
                        font-family: $Poppins;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 300;
                        line-height: 130%;
                        padding: 13px 10px 0px 10px;

                        &:first-child{
                            width: 208px;
                        }
                    }
                }
            }
            tbody{
                font-size: 12px;
    
                tr{
                    td{
                        text-align: center;
                        &:first-child{
                            color: rgba(255, 255, 255, 0.88);
                            font-family: $Poppins;
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 130%;
                            text-align: left;
                        }
                        &:after{
                            content: "";
                            display: inline-block;
                            height: 10px;
                            width: 10px;
                        }
                        &.completed{
                            &:after{
                                background: #05DA80;
                                border: 2px solid #05DA80;
                            }
                        }
                        &.missed {
                            &:after{
                                background: #6a6a6a;
                                border: 2px solid #6a6a6a;
                            }
                        }
                        &.late{
                            &:after{
                                background: #F59D6B;
                                border: 2px solid #F59D6B;
                            }
                        }
                        &.square{
                            &:after{
                                border-radius: 2px;
                            }
                        }
                        &.solid_circle{
                            &:after{
                                border-radius: 100%;
                            }
                        }
                        &.circle{
                            &:after{
                                background: #4a4a4a;
                                border-radius: 100%;
                            }
                        }
                    }
                }
            }
        }
    }
    &[x-placement^=top] .arrow::before, &.bs-tooltip-top .arrow::before{
        top: 8px;
    }
}