.admin_modal{
    #pagination_controls_modal{
        max-width: 554px;
        width: 554px;

        .modal-content{
            .modal-header{
                justify-content: flex-start;
                h4{
                    color: rgba(255, 255, 255, 0.88);
                    font-family: "Poppins";
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 130%;
                }
            }

            form{
                .modal-body{
                    padding: 20px 24px;
                    h6{
                        color: rgba(255, 255, 255, 0.88);
                        font-family: "Poppins";
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 160%;
                    }
                    ul{
                        display: flex;
                        margin-bottom: 0px;
                        margin-top: 10px;

                        li{
                            margin-right: 20px;
                             
                            input[type=radio]{
                                display:none;
                            }

                            input[type=radio]:checked ~ label{
                                .fill_container{
                                    background: #81A0EA;
                                }
                            }

                            label{
                                align-items: center;
                                cursor: pointer;
                                display: flex;

                                .fill_container{
                                    align-items: center;
                                    background: #E5E5E5;
                                    border-radius: 14px;
                                    display: flex;
                                    height: 14px; 
                                    justify-content: center;
                                    margin: 0px;
                                    width: 14px;

                                    .fill{
                                        background: #E5E5E5;
                                        border-radius: 7px;
                                        display: flex;
                                        height: 7px;
                                        width: 7px;
                                    }
                                }
                                
                                .radio_label{
                                    color: rgba(255, 255, 255, 0.88);
                                    font-family: "Poppins";
                                    font-size: 12px;
                                    font-style: normal;
                                    font-weight: 600;
                                    line-height: 130%;
                                    margin-left: 5px;
                                }
                            }
                        }
                    }
                }

                .modal-footer{
                    button{
                        width: 135px;

                        &[type=button]{
                            background-color: transparent;
                            color:rgba(255, 255, 255, 0.88);
                        }
                    }
                }
            }
        }
    }
}