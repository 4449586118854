$Poppins: 'Poppins', sans-serif;

#edit_module_modal{
    max-width: 608px;
    width: 608px;

    .modal-content{
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.14), rgba(255, 255, 255, 0.14)), #121212;
        border: none;

        .modal-header{
            border-bottom: 1px solid #DEE5EF;
            padding: 15px 24px 13px 24px;
            
            .modal-title{
                color: #DEE5EF;
                font-family: $Poppins;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 140%;
            }

            button{
                background-color: transparent;
                border: none;
                outline: none;
                padding: 0px;
                position: absolute;
                right: 24px;
                top: 12px;
            }
        }

        .modal-body{
            padding: 17px 25px 16px 25px;

            .form_group_container{
                align-items: flex-start;
                display: flex;
                margin-bottom: 25px;
                
                &:last-child{
                    flex-wrap: wrap;
                    margin-bottom: 3px;

                    input[type="checkbox"]{
                        display: none;
                    }

                    .checkbox{
                        &:last-child{
                            margin-left: 40px;
                        }

                        .fa-check{
                            display: none;
                        }
                        input[type="checkbox"]:checked ~ label{
                            .checkbox_container{
                                background-color: #81A0EA;
                                .fa-check{
                                    color: #fff;
                                    display: inline;
                                    font-size: 10px;
                                }
                            }    
                        }
                        label{
                            align-items: center;
                            display: flex;
                            margin-bottom: 0px;
                            cursor: pointer;
                            .checkbox_container{ 
                                align-items: center;
                                border-radius: 2px;
                                border: 1px solid #81A0EA;
                                cursor: pointer;
                                display: flex;
                                height: 16px;
                                justify-content: center;
                                margin-right: 10px;
                                width: 16px;
                            }
                            >span{
                                color: #DEE5EF;
                                font-family: $Poppins;
                                font-size: 18px;
                                font-style: normal;
                                font-weight: bold;
                                letter-spacing: -0.01em;
                                line-height: 120%;
                            }                        
                        }
                    }
                }

                >span{
                    color: #DEE5EF;
                    font-family: $Poppins;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: normal;
                    line-height: 140%;
                    margin-bottom: 10px;
                    opacity: 0.4;
                    width: 100%;
                }

                .form-group{
                    position: relative;
                    &:first-child{
                        margin-right: 80px;
                    }
                    label{
                        color: #DEE5EF;
                        font-family: $Poppins;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: normal;
                        line-height: 140%;
                        opacity: 0.4;
                        width: 100%;

                        &:last-child{
                            -webkit-transition: all .3s;
                            bottom: 6px;
                            color: #DEE5EF;
                            cursor: pointer;
                            margin-bottom: 0px;
                            opacity: 1;
                            position: absolute;
                            right: 0;
                            transition: all .3s;     
                            width: auto;
                        }
                    }

                    input[type="text"]{
                        align-items: center;
                        background-color: transparent;
                        border-bottom: 1px solid #F6F9FD !important;
                        border: none;
                        color: #DEE5EF;
                        display: flex;
                        font-family: $Poppins;
                        font-size: 18px;
                        font-style: normal;
                        font-weight: bold;
                        letter-spacing: -0.01em;
                        line-height: 120%;
                        outline: none;
                        padding: 3px 24px 3px 0px;
                        width: 239px;

                        &:focus{
                            padding: 3px 0px 3px 0px;  
                        }

                        &:focus ~ label{
                            opacity: 0;
                        }
                    }
                }
            }

            #module_type{
                margin-bottom: 41px;

                >span{
                    color: #DEE5EF;
                    display: flex;
                    font-family: $Poppins;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: normal;
                    line-height: 140%;
                    opacity: 0.4;
                    width: 100%;
                }

                .btn-group{
                    align-items: center;
                    display: flex;
                    margin-top: 10px;

                    input{
                        display: none;

                    }

                    input:checked + label{
                        background: rgba(196, 196, 196, 0.1);
                        border: 2px solid #81A0EA;

                        span{
                            color: #81A0EA;
                            opacity: 1;
                        }
                    }                    

                    label{
                        align-items: center;
                        background: rgba(246, 249, 253, 0.16);
                        border: 1px solid #394A64;
                        display: flex;
                        flex: 1;
                        height: 50px;
                        justify-content: center;
                        margin: 0px;
                        
                        &[for="lesson_module"]{
                            border-radius: 4px 0px 0px 4px;
                        }

                        &[for="admin_module"]{
                            border-radius: 0px 4px 4px 0px;
                        }

                        span{
                            color: #F6F9FD;   
                            font-family: $Poppins;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 140%;
                            opacity: 0.4;                         
                        }
                    }
                }
            }
        }

        .modal-footer{
            border-color: #DEE5EF;
            padding: 14px 21px 16px 21px;
            form{
                display: flex;
                justify-content: flex-start;
                width: 100%;
            
                button{
                    align-items: center;
                    border: none;
                    display: flex;
                    font-family: $Poppins;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 600;
                    height: 40px;
                    justify-content: center;
                    line-height: 130%;
                    width: 90px;
                    &.btn-default{
                        background: rgba(196, 196, 196, 0.1);
                        color: #DEE5EF;
                    }

                    &.btn-primary{
                        background: rgba(131, 179, 252, 0.26);
                        color: #81A0EA;
                        margin-left: 14px;
                    }
                }
            }
        }
    }
}