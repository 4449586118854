$Poppins: 'Poppins', sans-serif;
$blue: #81A0EA;
$white: rgba(255, 255, 255, 0.88);

#take_attendance_modal{
    font-family: $Poppins;
    max-width: 400px;
    .modal-content{
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.24), rgba(255, 255, 255, 0.24)), #121212;
        border-radius: 0px;
        border: none;
        color: #fff;

        .modal-header{
            background: linear-gradient(0deg, rgba(255, 255, 255, 0.14), rgba(255, 255, 255, 0.14)), #121212;
            border-radius: 0px;
            border: none;
            padding: 17px 25px 10px;

            h4{
                color: rgba(255, 255, 255, 0.88);
                font-size: 14px;
                font-weight: 700;
            }

            button{
                background-color: transparent;
                border: none;
                outline: none;
                padding: 0px;
                position: absolute;
                right: 16px;
                top: 9px;
                .close_icon{
                    background-image: url("./../../../../assets/images/admin/student_progress/close_icon.png");
                    background-position: 10px 9px;
                    background-repeat: no-repeat;
                    display: inline-block;
                    height: 33px;
                    width: 33px;
                }
            }
        }
        .modal-body{
            background: linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), #121212;
            font-size: 12px;
            padding: 40px 24px;

            >p:not(#success_message){
                font-family: $Poppins;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 140%;
                color: rgba(255, 255, 255, 0.88);

                span{
                    font-weight: 600;
                    margin-right: 10px;
                }
            }

            p#success_message{
                color: rgba(255, 255, 255, 0.88);
                display: flex;
                font-family: $Poppins;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 130%;
                margin: 0px auto;
                text-align: center;
                width: 282px;
            }

            #update_attendance_steps{
                align-items: center;
                display: flex;
                justify-content: space-between;
                margin: 0px auto 40px auto;
                width: 222px;

                li{
                    align-items: center;
                    background: linear-gradient(0deg, rgba(255, 255, 255, 0.14), rgba(255, 255, 255, 0.14)), #121212;
                    border-radius: 100%;
                    border: 1px solid rgba(255, 255, 255, 0.6);
                    color: rgba(255, 255, 255, 0.8);
                    display: flex;
                    font-family: $Poppins;
                    font-size: 11px;
                    font-style: normal;
                    font-weight: 500;
                    height: 22px;
                    justify-content: center;
                    line-height: 130%;
                    position: relative;
                    width: 22px;

                    &.active{
                        background: transparent;
                        border-color: #05DA80;
                    }

                    &.completed{
                        background: #05DA80;
                        border-color: #05DA80;

                        &:before{
                            background-color: rgba(84, 204, 176, 0.4);
                            border-radius: 100%;
                            content: "";
                            height: 28px;
                            position: absolute;
                            width: 28px;
                        }

                        &:after{
                            background: #05DA80 !important;
                        }
                    }

                    &:nth-child(1), &:nth-child(2){
                        &:after{
                            background: #121212;
                            content: "";
                            height: 2px;
                            position: absolute;
                            right: -79px;
                            width: 78px;
                        }
                    }
                }
            }

            h6{
                color: rgba(255, 255, 255, 0.88);
                font-size: 14px;    
                margin-bottom: 20px;
            }

            #select_date_attendance{
                align-items: baseline;
                display: flex;
                flex-wrap: wrap;
                >p{
                    color: rgba(255, 255, 255, 0.58);
                    font-family: $Poppins;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: normal;
                    margin: 0px 0px 8px 0px;
                    width: 100%;
                }

                >div{
                    display: inline-block;
                    margin-top: 13px;
                    vertical-align: top;

                    .take_attendance_select{
                        background: transparent;
                        border-bottom: 1px solid rgba(255, 255, 255, 0.88) !important;
                        height: 22px;
                        margin-right: 20px;
                        min-height: 22px !important;
                        padding: 0px !important;
                        width: unset;
                        .react-dropdown-select-item{
                            border: none !important;
                            &.react-dropdown-select-item-selected{
                                background: #81A0EA;
                            }
                        }
                        .react-dropdown-select-content{
                            color: #81A0EA;
                            font-size: 14px;

                            .react-dropdown-select-input{
                                margin-left: -3px;
                            } 
                        }
                        .react-dropdown-select-dropdown-handle{
                            margin: 0 0 1px 5px;
                            svg{
                                height: 27px;
                                width: 27px;
                            }
                        }
                    }
                }

                >span{
                    font-family: $Poppins;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 140%;
                    color: #FFFFFF;
                }
            }

            #selected_week_day{
                color: rgba(255, 255, 255, 0.88);
                font-family: $Poppins;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 130%;
                margin-bottom: 20px;
            }

            #select_attendance_slot_container{
                align-items: center;
                display: flex;
                flex-wrap: wrap;

                >p{
                    color: rgba(255, 255, 255, 0.58);
                    display: flex;
                    font-family: $Poppins;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: normal;
                    margin-bottom: 10px;
                    width: 100%;
                }

                label{
                    cursor: pointer;
                    margin-bottom: 0px;
                    margin-right: 20px;

                    span{
                        align-items: center;
                        background: rgba(204, 224, 255, 0.14);
                        border-radius: 4px;
                        display: inline-flex;
                        font-size: 14px;
                        font-weight: 500;
                        height: 32px;
                        justify-content: center;
                        vertical-align: top;
                        width: 32px;
                    }
                    input{
                        display: none;
                    }
                    input:checked ~ span{
                        background: rgba(204, 224, 255, 0.14);
                        border: 1px solid #81A0EA;
                        color: #81A0EA;
                    }
                }

                >button{
                    align-items: flex-end;
                    background-color: transparent;
                    border: none;
                    color: #81A0EA;
                    font-family: $Poppins;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: normal;
                    outline: none;
                }
            }

            #selected_attendance_container{    
                align-items: center;
                display: flex;
                
                span{
                    color: rgba(255, 255, 255, 0.88);
                    font-family: $Poppins;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 130%;
                }

                ul{
                    display: flex;
                    margin: 0px;

                    li{
                        align-items: center;
                        background: rgba(204, 224, 255, 0.14);
                        border-radius: 4px;
                        border: 1px solid #81A0EA;
                        box-sizing: border-box;
                        color: #81A0EA;
                        display: flex;
                        font-family: $Poppins;
                        font-size: 11px;
                        font-style: normal;
                        font-weight: 500;
                        height: 20px;
                        justify-content: center;
                        margin-left: 10px;
                        width: 20px;
                    }
                }
            }

            #attendance_status_container{
                border-top: 1px solid rgba(255,255,255,.1);
                flex-wrap: wrap;
                margin-top: 34px;
                padding-top: 20px;

                >span{
                    color: rgba(255, 255, 255, 0.58);
                    display: flex;
                    font-family: $Poppins;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: normal;
                    margin-bottom: 19px;
                    width: 100%;
                }

                label{
                    cursor: pointer;
                    margin-bottom: 0px;
                    margin-right: 10px;
                    &:last-child{
                        margin-right: 0px;
                    }

                    span{
                        align-items: center;
                        background: linear-gradient(0deg, rgba(255, 255, 255, 0.24), rgba(255, 255, 255, 0.24)), #121212;
                        border-radius: 4px;
                        border: 1px solid rgba(204, 224, 255, 0.14);
                        box-sizing: border-box;
                        color: rgba(255, 255, 255, 0.58);
                        display: flex;
                        font-family: $Poppins;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 500;
                        height: 36px;
                        justify-content: center;
                        padding: 10px 9px;
                    }
                    input{
                        display: none;
                    }
                    input:checked ~ span{
                        background: rgba(204, 224, 255, 0.14);
                        border: 1px solid #81A0EA;
                        color: #81A0EA;


                        &.cd_red{
                            border-color: #F56B6B !important;
                            color: #F56B6B !important;
                        }
                        &.cd_green{
                            border-color: #05DA80 !important;
                            color: #05DA80 !important;
                        }
                        &.cd_yellow{
                            border-color: #FFC656 !important;
                            color: #FFC656 !important;
                        }
                        &.cd_violet{
                            border-color:#FFBCE8 !important;
                            color: #FFBCE8 !important;
                        }
                    }
                }

                p{
                    color: #F56B6B;
                    font-family: $Poppins;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: normal;
                    margin: 20px 0px 0px 0px;
                }
            }
        }
        .modal-footer{
            background: linear-gradient(0deg, rgba(255, 255, 255, 0.14), rgba(255, 255, 255, 0.14)), #121212;
            border-radius: 0px;
            border: none;
            padding: 20px 24px;

            button{
                background: transparent;
                border-radius: 4px;
                border: none;
                font-family: $Poppins;
                font-size: 14px;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                height: 40px;
                line-height: 130%;
                margin: 0px;
                outline: none;
                padding: 10px 16px;

                &#next_btn, &#confirm_btn, &#save_attendance_status_btn{    
                    background: rgba(204, 224, 255, 0.14);
                    color: #81A0EA;

                }
                &#back_btn{  
                    color: rgba(255, 255, 255, 0.88);
                    margin-right: 20px;  
                }
            }
        }
    }
}