#admin_student_progress_program_table{
    .sticky-table{
        height: calc(100% - 34px);
        .sticky-table-row:nth-child(n+2) {
            .sticky-table-cell:nth-child(1) {
                text-align: left;
            }

            .name, .email{
                cursor: pointer;
            }
            
            .sticky-table-cell:nth-child(2) {
                cursor: unset;
            }
        } 
        
        .sticky-table-row:nth-child(1) {
            .sticky-table-cell:nth-child(1) {
                text-align: left;
            }
            .sticky-table-cell:nth-last-child(1){
                cursor: unset;
            }
        } 
        .sticky-table-table{
            width: 100%;
            .sticky-table-row{
                /* Table Head */ 
                &:nth-child(1){
                    .sticky-table-cell{
                        color: #F6F9FD;
                        background: linear-gradient(0deg, rgba(255, 255, 255, 0.24), rgba(255, 255, 255, 0.24)), #121212;
                        width: unset;
                    }
                }

                .sticky-table-cell{
                    background: #333333;

                    ul.track_history_list{
                        margin-bottom: 0px;
                        li{
                            margin-right: 4px;
                            span{
                                font-weight: 600;
                    
                                &:first-child{
                                    margin-right: 5px;
                                }
                            }
                        }
                    }
                    
                    .belt_icon{
                        background-image: url('./../../../../assets/images/admin/rostering/belt_icons.png');
                        display: flex;
                        height: 13px;
                        margin-left: 7px;
                        width: 12px;
                        
                        &.yellow{
                            background-position: -26px 0px;
                        }
                        &.red{
                            background-position: -15px 0px;
                        }
                        &.black{
                            background-position: -4px 0px;
                        }
                        &.orange{
                            background-position: -40px 0px;
                        }
                        &.white{
                            background-position: -52px 0px;
                        }
                    }
                }
            }
        }
    }
}