div.curriculum_management_toast{
    left: 50%;
    max-width: unset;   
    position: fixed;
    text-align: center;
    top: 84px;
    transform: translate(-50%, -50%);
    z-index: 9999;

    &.error_toast{
        z-index: 10000;
        div.toast-body{
            background: #E1554C;
        }
    }

    div.toast-body{
        background: #04AA64;
        border-radius: 4px;
        box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.14), 0px 3px 3px rgba(0, 0, 0, 0.12), 0px 1px 8px rgba(0, 0, 0, 0.2);
        padding: 9px 12px 8px 12px;
        position: relative;
        padding-right: 31px;
        
        p{
            color: #fff;
            display: inline-block;
            font: normal 400 14px/21px 'Poppins';
            margin-bottom: 0px;
            margin-right: 15px; 

            span{
                font-weight: 600 !important; 
            }
        }
        >div{
            padding-right: 10px;
        }

        #config_errors{
            margin-top: 3px;
            text-align: left;
        }

        button{
            background-color: transparent;
            background: url("https://assets.codingdojo.com/learn_platform/admin/curriculum_management/admin_curriculum_management_sprite.png") no-repeat -175px -23px;
            border: none;
            color: rgba(255, 255, 255, 0.88);
            height: 15px; 
            outline: none;
            position: absolute;
            right: 13px;
            top: 13px;
            width: 15px;
        } 
    }
}
