#quiz_questions_container{
    ol{
        >li{
            margin-bottom: 33px;

            &:last-child{
                margin-bottom: 0px;
            }

            >p{
                color: #152C61;
                font: normal 500 16px/24px 'Poppins', sans-serif;
                margin: 0px auto 22px auto;
                width: 78%;
            }

            h5{
                color: #152C61;
                font: normal 300 14px/21px 'Poppins', sans-serif;
                margin: 17px auto 14px auto;
                width: 78%;
            }

            .blank_question_container{
                margin: 0px auto;
                width: 78%;

                input{ 
                    background: transparent;
                    border-radius: 2px;
                    border: 2px solid #A4B0C0;
                    box-sizing: border-box;
                    color: #000;
                    font: normal 300 16px/24px 'Poppins', sans-serif;
                    height: 49px;
                    width: 320px;
                    padding: 12px 24px;
                    outline: none;

                    &:focus{
                        border: 2px solid #2C6BFF;
                    }
                }
            }

            .multiple{
                margin: 0px auto 0px auto;
                width: 78%;

                >li{ 
                    margin-bottom: 10px;

                    &:last-child{
                        margin-bottom: 0px;
                    }
                   
                    input[type="checkbox"]{
                        display: none;
                    }

                    input[type="checkbox"]:checked ~ label{
                        background: #DCE6FF !important;
                        border-radius: 2px !important;
                        border: 2px solid #2C6BFF !important;
                        box-sizing: border-box;
                        color: #000 !important;
                        font: normal 300 16px/24px 'Poppins', sans-serif;

                        &::before{
                            opacity: 1;
                            visibility: visible;
                            left: 144px;
                            background-color: #295EDB !important;
                        }
    
                        &::after{
                            background: url("https://assets.codingdojo.com/learn_platform/admin/student_progress/quizzes_sprite.png") no-repeat -118px -22px !important;
                            border: 2px solid #295EDB !important;
                        }
                    }
                    label{
                        align-items: center;
                        background: #FFFFFF;
                        border: 2px solid #E5EEFD;
                        border-radius: 2px;
                        box-sizing: border-box;
                        color: #000000;         
                        cursor: pointer;
                        display: flex;
                        font: normal 300 16px/24px 'Poppins', sans-serif;
                        margin-bottom: 0px;
                        min-height: 50px;
                        padding: 10px 57px;
                        width: 100%;

                        &::after,
                        &::before{
                            border-radius: 2px !important;
                            content: "";
                            position: absolute;
                        }

                        &::after{
                            border: 1px solid #A4B0C0;
                            height: 16px;
                            left: 144px;
                            width: 16px;
                        }

                        &::before{
                            background: transparent;
                            height: 16px;
                            left: 144px;
                            opacity: 0;
                            visibility: hidden;
                            width: 16px;
                        }
                    }

                }
            }

            ul{
                margin: 0px auto 0px auto;
                width: 78%;

                li{ 
                    margin-bottom: 10px;

                    &:last-child{
                        margin-bottom: 0px;
                    }
                   
                    input[type="radio"]{
                        display: none;
                    }

                    input[type="radio"]:checked ~ label{
                        background: #DCE6FF;
                        border-radius: 2px;
                        border: 2px solid #2C6BFF;
                        box-sizing: border-box;
                        font: normal 300 16px/24px 'Poppins', sans-serif;

                        &::before{
                            opacity: 1;
                            visibility: visible;
                        }
    
                        &::after{
                            border: 4px solid #295EDB;
                        }
                    }

                    label{
                        align-items: center;
                        background: #FFFFFF;
                        border-radius: 2px;
                        border: 2px solid #E5EEFD;
                        box-sizing: border-box;
                        color: #000000;         
                        cursor: pointer;
                        display: flex;
                        font: normal 300 16px/24px 'Poppins', sans-serif;
                        margin-bottom: 0px;
                        min-height: 50px;
                        padding: 10px 57px;
                        width: 100%;

                        &::after,
                        &::before{
                            border-radius: 50%;
                            content: "";
                            position: absolute;
                        }

                        &::after{
                            border: 1px solid #A4B0C0;
                            height: 16px;
                            left: 144px;
                            width: 16px;
                        }

                        &::before{
                            background: transparent;
                            height: 16px;
                            left: 144px;
                            opacity: 0;
                            visibility: hidden;
                            width: 16px;
                        }
                    }

                }
            }
        }
    }

    .btns_container{
        align-items: center;
        display: flex;
        justify-content: space-between;
        margin: 60px auto 0px auto;
        width: 78%;

        button{
            font: normal 500 15px/22px 'Poppins', sans-serif;
            height: 46px;
            outline: none;
            text-align: center;

            &#previous_question_btn{
                background-color: #fff;                
                border-radius: 6px;
                border: 2px solid #505170;
                box-sizing: border-box;
                color: #505170;    
                width: 120px;
            }

            &#next_question_btn, &#show_quiz_results_btn{
                background: #505170;
                border-radius: 6px;
                border: none;
                color: #fff;
                width: 158px;

                &:disabled{
                    opacity: 0.5;
                }
            }
        }

        span{
            color: rgba(21, 44, 97, 0.6);
            font: normal 500 16px/24px 'Poppins', sans-serif;
            text-align: right;
        }
    }
}

.is_dark_mode{
    #quiz_questions_container{

        .blank_question_container{
            input{
                background: #404040;
                color: rgba(255, 255, 255, 0.88);
            }
        }

        .multiple{
            margin: 0px auto 0px auto;
            width: 78%;

            >li{ 
                input[type="checkbox"]:checked ~ label{
                    background: rgba(204, 224, 255, 0.14) !important;
                    border: 2px solid rgba(154, 194, 255, 0.3) !important;
                    color: rgba(255, 255, 255, 0.88) !important;
                }
            }
        }
    }
}