$Poppins: 'Poppins', sans-serif;

#change_password_modal{
    font-family: $Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    max-width: 380px;
    .modal-content{
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.24), rgba(255, 255, 255, 0.24)), #121212;
        border-radius: 2px;
        color: rgba(255, 255, 255, 0.58);
        .modal-header{
            align-items: center;
            border-bottom: none;
            flex-direction:column;
            justify-content: center;
            padding-bottom: 16px;
            p{
                margin-bottom: 0px;
                text-align: center;
            }
        }
        p{
            font-weight: 400;
        }
        .modal-body{
            padding: 0px 20px 20px;
            label{
                display: block;
                margin-top: 19px;
                &:first-child{
                    margin-top: 0px;
                }
            }
            input{
                background: #2A2A2A;
                border-radius: 4px;
                border: 1px solid #2A2A2A;
                color: #FFFFFF;
                height: 40px;
                margin-bottom: 6px;
                outline: none;
                padding-left: 15px;
                width: 100%;
                &:focus{
                    border: 1px solid #81A0EA;
                    box-shadow: 0px 0px 6px #81A0EA;
                }
                &.border_red{
                    border: 1px solid #F56B6B; 
                }
            }
            p{
                color: #F56B6B;
                &#confirm_password_error{
                    margin-bottom: 8px;
                }
            }
            button{
                background: rgba(204, 224, 255, 0.14);
                border-radius: 4px;
                border: none;
                color: #81A0EA;
                font-size: 14px;
                height: 40px;
                margin-top: 15px;
                width: 100%;
            }
        }
    }
}