$Poppins: 'Poppins', sans-serif;

#add_exam_record_modal{
    max-width: none;
    width: 700px;
    .modal-content{
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.24), rgba(255, 255, 255, 0.24)), #121212;
        border-radius: 0px;
        box-shadow: 0px 4px 2px rgba(0, 0, 0, 0.05);
        color: rgba(255, 255, 255, 0.88);
        font-family: $Poppins;
        .modal-header{
            background: linear-gradient(0deg, rgba(255, 255, 255, 0.14), rgba(255, 255, 255, 0.14)), #121212;
            border-radius: 0px;
            border: none;
            justify-content: left;
            padding: 18px 23px;
            position: relative;
    
            h4{
                font-family: 'Poppins', sans-serif;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: 16px;
                margin-bottom: 0px;
            }
    
            button{
                background-color: transparent;
                background-image: url("https://assets.codingdojo.com/learn_platform/admin/edit_course/admin_edit_course_sprite.png");
                background-position: -65px 0px;
                border: none;
                height: 15px;
                outline: none;
                padding: 0px;
                position: absolute;
                right: 24px;
                top: 17px;
                width: 15px;
            }
        }
        form{
            position: relative;
            >p{
                color: rgba(255, 255, 255, 0.4);
                font-size: 12px;
                position: absolute;
                right: 24px;
                top: 18px;
                z-index: 9;
            }
            .modal-body{
                background: #292929;
                padding: 20px 24px;
                text-align: left;
                input[type="number"] {
                    -moz-appearance:textfield;
                }
                input::-webkit-outer-spin-button,
                input::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                }
                button{
                    outline: none !important;
                }
                
                h6{
                    color: rgba(255, 255, 255, 0.4);
                    font-family: $Poppins, sans-serif;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 600;
                    margin-bottom: 12px;
                }
                #search_student_input{
                    background: rgba(204, 224, 255, 0.14);
                    border-radius: 4px;
                    border: none;
                    color: rgba(255, 255, 255, 0.88);
                    font-family: $Poppins;
                    font-size: 12px;
                    height: 40px;
                    outline: none;
                    padding: 10px 16px;
                    width: 100%;
                }
                span.download_loader{
                    background-image: url(https://assets.codingdojo.com/learn_platform/global/loading.svg);
                    background-size: cover;
                    display: inline-block;
                    height: 17px;
                    margin-right: 5px;
                    vertical-align: middle;
                    width: 17px;
                }
                .content{
                    align-items: flex-start;
                    display: flex;
                    flex-direction: column;
                    margin-bottom: 24px;
                    &#exam_type_and_option_container, &#exam_dates_container, &#exam_file_and_url_container{
                        flex-direction: row;
                    }
                    &#exam_time_window_container{
                        input{
                            background: rgba(204, 224, 255, 0.14);
                            border-radius: 4px;
                            border: none;
                            color: rgba(255, 255, 255, 0.88);
                            font-family: "Poppins", sans-serif;
                            font-size: 12px;
                            height: 40px;
                            margin-right: 21px;
                            outline: none;
                            padding: 10px 16px;
                            width: 147px;
                        }
                        .checkbox{
                            display: flex;
                            justify-content: left;
                            
                            input[type="checkbox"]{
                                display: none;
                            }
                
                            .fa-check{
                                display: none;
                            }
                            input[type="checkbox"]:checked ~ label{
                                .checkbox_container{
                                    background-color: #81A0EA;
                                    .fa-check{
                                        color: #fff;
                                        display: inline;
                                        font-size: 10px;
                                    }
                                }    
                            }
                            label{
                                align-items: center;
                                color: #E5E5E5;
                                cursor: pointer;
                                display: flex;
                                font-size: 12px;
                                margin-bottom: 0px;
                                .checkbox_container{ 
                                    align-items: center;
                                    background: rgba(204, 224, 255, 0.14);
                                    border-radius: 4px;
                                    border: 1px solid transparent;
                                    cursor: pointer;
                                    display: flex;
                                    height: 15px;
                                    justify-content: center;
                                    margin-right: 10px;
                                    width: 15px;
                                }                       
                            }
                        }
                    }
                    &#exam_score_and_belt_container{
                        margin-bottom: 0px;
                        position: relative;
                        flex-direction: row;

                        .left_container{
                            margin-right: 30px;
                            input{
                                background: #333333;
                                border-radius: 4px;
                                border: none;
                                font-family: $Poppins;
                                font-size: 32px;
                                font-weight: 600;
                                height: 60px;
                                outline: none;
                                text-align: center;
                                width: 60px;
                                &.show_not_applicable_text ~ .not_applicable_text{
                                    display: block;
                                }
                                &:focus{
                                    background: #121212;
                                
                                    ~ .not_applicable_text{
                                        display: none;
                                    }
                                }
                            }
                            .belt_icon{
                                background-image: url("https://assets.codingdojo.com/learn_platform/admin/exam/admin_exam_icons.png");
                                display: inline-flex;
                                height: 17px;
                                left: 66px;
                                position: absolute;
                                top: 47px;
                                width: 14px;
                                &.red_belt{
                                    background-position: -173px -2px;
                                }
                                &.black_belt{
                                    background-position: -200px -2px;
                                }
                                &.orange_belt{
                                    background-position: -227px -2px;
                                }
                                &.yellow_belt{
                                    background-position: -254px -2px;
                                }
                            }
                            .invalid_grade{
                                font-size: 12px;
                                margin: 7px 0px 0px;
                                width: 127px;
                            }
                            .not_applicable_text{
                                display: none;
                                left: 15px;
                                pointer-events: none;
                                position: absolute;
                                top: 44px;
                            }
                        }

                        .right_container{
                            flex: 1;
                            
                            textarea{
                                background: rgba(204, 224, 255, 0.14);
                                border-radius: 4px;
                                border: none;
                                color: rgba(255, 255, 255, 0.88);
                                font-family: "Poppins", sans-serif;
                                font-size: 12px;
                                outline: none;
                                padding: 10px 16px;
                                resize: none;                                
                                width: 100%;
                            }
                        }
                    
                    }
                    &#exam_file_and_url_container{
                        >div{
                            width: 50%;
                            &.left_container{
                                input{
                                    display: none;
                                }
                                label{
                                    color: #81A0EA;
                                    font-family: $Poppins;
                                    font-size: 12px;
                                    cursor: pointer;
                                }
                                .inner_content{
                                    align-items: center;
                                    display: flex;
                                    padding-top: 14px;
                                    .upload_loading_animation{
                                        align-items: center;
                                        background: rgba(204, 224, 255, 0.14);
                                        border-radius: 4px;
                                        display: flex;
                                        height: 10px;
                                        margin-right: 34px;
                                        width: 203px;
                                        p{
                                            margin-top: -46px;
                                            position: absolute;
                                        }
                                        span{
                                            background: #81A0EA;
                                            border-radius: 4px;
                                            display: inline-block;
                                            height: 100%;
                                            transition: 1s;
                                            width: 0%;
                                        }
                                    }
                                    p{
                                        display: -webkit-box;
                                        font-family: $Poppins;
                                        font-size: 12px;
                                        line-clamp: 3;
                                        margin-bottom: 0px;
                                        margin-right: 16px;
                                        overflow: hidden;
                                        padding-right: 32px;
                                        position: relative;
                                        text-overflow: ellipsis;
                                        width: 221px;
                                        -webkit-box-orient: vertical;
                                        -webkit-line-clamp: 3;
                                        #file_download{
                                            background-color: transparent;
                                            background-image: url("https://assets.codingdojo.com/learn_platform/admin/exam/admin_exam_icons.png");
                                            background-position: -139px -5px;
                                            border: none;
                                            display: inline-block;
                                            height: 14px;
                                            outline: none;
                                            position: absolute;
                                            right: 6px;
                                            top: 0px;
                                            width: 21px;
                                        }
                                    }
                                    label{
                                        color: #81A0EA;
                                        cursor: pointer;
                                        font-family: $Poppins;
                                        font-size: 12px;
                                        font-weight: 600;
                                        margin-bottom: 0px;
                                    }
                                }
                            }
                            &.right_container{
                                input{
                                    background: rgba(204, 224, 255, 0.14);
                                    border-radius: 4px;
                                    border: none;
                                    color: rgba(255, 255, 255, 0.88);
                                    font-family: $Poppins;
                                    font-size: 12px;
                                    height: 40px;
                                    margin-bottom: 24px;
                                    outline: none;
                                    padding: 10px 16px;
                                    width: 100%;
                                }
                            }
                        }
                    }
                    #user_not_found_error{
                        font-size: 12px;
                        margin-bottom: 0px;
                        margin-top: 10px;
                    }
                    #search_list{
                        background: #5E6065;
                        border-radius: 4px;
                        font-size: 12px;
                        list-style: none;
                        margin-bottom: 0px;
                        padding: 15px;
                        position: absolute;
                        top: 85px;
                        z-index: 9;
                        li{
                            margin-bottom: 10px;
                            &:last-child{
                                margin-bottom: 0px;
                            }
                            &:hover{
                                color: #81A0EA;
                            }
                        }
                    }
                
                    .exam_select {
                        border-radius: 4px;
                        width: 316px;
                    }
                    .left_container{
                        margin-right: 20px;
                    }
                    
                    .date_picker_container{
                        input{
                            background: rgba(204, 224, 255, 0.14);
                            border-bottom-left-radius: 4px;
                            border-top-left-radius: 4px;
                            border: none;
                            color: rgba(255, 255, 255, 0.88);
                            font-family: $Poppins, sans-serif;
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 400;
                            height: 40px;
                            outline: none;
                            padding: 8px 11px;
                            vertical-align: top;
                            width: 275px;
                        }
                        button{
                            background: linear-gradient(0deg, rgba(255, 255, 255, 0.14), rgba(255, 255, 255, 0.14)), #121212;
                            border-radius: 0px 4px 4px 0px;
                            border: none;
                            color: #81A0EA;
                            height: 40px;
                            padding: 8px 13px;
                            vertical-align: top;
                        }
                    }
                }
               
                .rmdp-container{
                    .rmdp-day.rmdp-today span{
                        background: #282828;
                        color: #e8e8e9;
                    }
                    .rmdp-day.rmdp-selected span:not(.highlight){
                        background: #81A0EA;
                        box-shadow: none;
                        color: #e8e8e9;
                    }
                    .rmdp-month-picker, .rmdp-year-picker{
                        background: #282828;
                    }
                    .rmdp-disabled{
                        span{
                            background: #282828;
                            color: #404347;
                        }
                    }
                    .rmdp-header-values, .rmdp-week-day, .rmdp-day{
                        color: #e8e8e9;
                        font-family: $Poppins, sans-serif;
                    }
                    .rmdp-ep-arrow{
                        display: none;
                    }
                    .rmdp-arrow {
                        border: solid #e8e8e9;
                        border-width: 0 2px 2px 0;
                        display: inline-block;
                        height: 10px;
                        margin-top: 5px;
                        padding: 2px;
                        width: 10px;
                    }
                    .rmdp-day span{
                        border-radius: 4px;
                        font-family: $Poppins, sans-serif;
                        font-size: 12px;
                    }
                    .rmdp-arrow-container:hover{
                        background: transparent;
                        box-shadow: none;
                        opacity: .8;
                    }
                    
                    .rmdp-wrapper{
                        background-color: #282828;
                        border-radius: 4px;
                        .rmdp-calendar{
                            padding: 0px;
                            .rmdp-header{
                                background: #282828;
                                height: unset;
                                margin-top: 0px;
                                padding: 11px 11px 0px;
                            }
                            .rmdp-week-day{
                                background: #282828;
                            }
                            .rmdp-week{
                                background: #333333;
                                padding: 0px 11px;
                                &:first-child{
                                    background: #282828;
                                    border-bottom: 1px solid #AEAEAE;
                                    padding: 0px 11px 11px;
                                }
                                &:nth-child(2){
                                    padding-top: 10px;
                                }
                                &:last-child{
                                    padding-bottom: 10px;
                                }
                            }
                            .rmdp-day-picker{
                                padding: 0px;
                            }
                        }
                    }
                    .rmdp-time-picker{
                        div{
                            input[name="hour"], input[name="minute"]{
                                pointer-events: none;
                            }
                            .rmdp-am{
                                color: #e8e8e9;
                                font-size: 12px;
                            }
                            input{
                                background: transparent;
                                color: #e8e8e9;
                                font-size: 12px;
                                width: 22px;
                            }
                        }
                    }
                }
                .color_red{
                    color: #F56B6B;
                }
                .color_green{
                    color: #05DA80;
                }
            }
            h4{
                font-size: 14px;
                text-align: center;
            }
        }
        .modal-footer{
            align-items: center;
            background: #333333;
            border: none;
            display: flex;
            height: 79px;
            justify-content: end;
            padding: 0px 21px;

            button{
                background: transparent;
                border: none;
                color: #fff;
                font-size: 14px;
                outline: none;
                &[type="submit"]{
                    background: rgba(204, 224, 255, 0.14);
                    border-radius: 2px;
                    color: #81A0EA;
                    margin-left: 30px;
                    padding: 8px 16px;
                }
            }
        }
    }
}