$Poppins: 'Poppins', sans-serif;

#admin_access_control_container{
    background-color: #121212;
    display: flex;
    height: 100vh;

    #admin_right_container{
        display: flex;
        flex-direction: column;
        flex: 1;
        overflow: hidden;
        padding: 12px 30px 20px 30px;
        position: relative;
        
        #access_control_table_filter_container{
            padding-right: 215px;
            position: relative;

            #create_survey_btn, #settings_survey_btn{
                align-items: center;
                background-color: rgba(204, 224, 255, 0.14);
                border-radius: 2px;
                border: none;
                color: #81A0EA;
                display: flex;
                font-family: $Poppins;
                font-size: 12px;
                font-style: normal;
                font-weight: 600;
                height: 32px;
                justify-content: center;
                outline:none;
                position: absolute;
                right: 0px;
                top: 20px;

                &#create_survey_btn{
                    width: 143px;  
                }

                &#settings_survey_btn{
                    font-size: 18px;
                    width: 38px;
                }
            }
            
        }
    }
}

form#search_admin_survey_form{
    display: flex;
    position: absolute;
 
    svg{
        color: #73839C;
        font-size: 14px;
        left: 12px;
        margin-right: 10px;
        position: absolute;
        top: 12px;
        z-index: 2;
    }
    input{
        align-items: center;
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), #121212;
        border-radius: 4px;
        border: 1px solid transparent;
        color: rgba(255, 255, 255, 0.88);
        font-family: $Poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: normal;
        height: 40px;
        line-height: 140%;
        padding: 0px 12px 0px 36px;
        position: relative;
        width: 256px;

        &:focus{
            background: #2A2A2A;
            border: 1px solid #81A0EA;
            box-shadow: 0px 0px 6px #81A0EA;
            outline: none;
        }
    }
}