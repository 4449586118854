
$Poppins: 'Poppins', sans-serif;

#widget_container{
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), #121212;
    border-radius: 2px;
    display: flex;
    margin-bottom: 20px;
    position: relative;
    &.closed{
        height: 40px;
        padding: 11px 0px 11px 20px;
        &:before{
            color: rgba(255, 255, 255, 0.58);
            content:"Course Performance Summary";
            font-family: "Poppins", sans-serif;
            font-weight: 500;
        }
        .blk{
            display: none;
        }
        button{
            background-position: -238px -2px;
            right: 16px;
            top: 10px;
        }
    }
    button{
        background-color: transparent;
        background-image: url('./../../../../assets/images/admin/student_progress/stack_progress_summary.png');
        background-position: -205px -2px;
        border: none;
        height: 20px;
        outline: none;
        position: absolute;
        right: 16px;
        top: 10px;
        width: 20px;
    }
    .blk{
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), #121212;
        border-radius: 2px;
        padding: 13px 20px 20px;
        position: relative;
        h6{
            color: rgba(255,255,255,0.58);
            font-size: 14px;                    
            margin-bottom: 15px;                
        }
        /* all */
        .rate{
            font-size: 18px;
            font-weight: 700;
        }
        ul{
            margin-bottom: 0px;
        }
        &#stack_belt_exam_container{
            margin-right: 20px;
            min-width: 639px;
            width: 50%;
            ul{
                li{
                    align-items: center;
                    background: linear-gradient(0deg, rgba(255, 255, 255, 0.14), rgba(255, 255, 255, 0.14)), #121212;
                    border-radius: 2px;
                    border: 1px solid rgba(204,224,255,0.14);
                    display: inline-flex;
                    margin-bottom: 10px;
                    margin-right: 10px;
                    padding: 3px 14px;
                    vertical-align: top;
                    .green{
                        color: #05DA80;
                    }
                    &:nth-last-child(-n+3) {
                        margin-bottom: 0px;
                    }
                    &:last-child{
                        margin-right: 0px;
                    }
                    .icon{
                        background-color: #292929;
                        background-image: url('./../../../../assets/images/admin/student_progress/belt_icons.png');
                        background-position: 1px -1px;
                        background-repeat: no-repeat;
                        border-radius: 100%;
                        height: 24px;
                        margin-right: 11px;
                        width: 24px;
                        &.yellow_belt{
                            background-position: -44px 4px;
                        }
                        &.red_belt{
                            background-position: -20px 4px;
                        }
                        &.black_belt{
                            background-position: 4px 4px;
                        }
                        &.orange_belt{
                            background-position: -68px 4px;
                        }
                    }
                    .name{
                        margin-right: 12px;
                    }
                }
            }
        }
        &#stack_progress_container{
            width: 50%;
            ul{   
                display: flex;
                justify-content: space-between;
                margin-top: 22px;
                li{
                    display: inline-block;
                    margin-right: 20px;
                    text-align: center;
                    &:last-child{
                        margin-right: 0px;
                    }
                    .icon{
                        background-color: #232323;
                        background-image: url('./../../../../assets/images/admin/student_progress/stack_progress_summary.png');
                        background-position: 0px 0px;
                        background-repeat: no-repeat;
                        border-radius: 4px;
                        display: inline-block;
                        height: 24px;
                        width: 24px;
                        
                        &.attendance{
                            background-position: 0px 0px;
                        }
                        &.assignment{
                            background-position: -42px 0px;
                        }
                        &.postpone{
                            background-position: -82px 0px;
                        }
                        &.retakes{
                            background-position: -123px 0px;
                        }
                        &.drops{
                            background-position: -164px 0px;
                        }
                    }
                    .name{
                        display: block;
                        font-size: 12px;
                        font-weight: 400;
                        margin-bottom: 1px;
                        text-transform: uppercase ;
                    }
                }
            }
        }
    }
    @media screen and (max-width: 1404px) {
        .blk#stack_belt_exam_container{
            margin-right: 0px;
            min-width: 591px;
            ul{
                li{
                    margin-bottom: 7px;
                    margin-right: 7px;
                    padding: 3px 7px;
                }
            }
        }
    }
    @media screen and (max-width: 1342px) {
        .blk#stack_belt_exam_container{
            min-width: unset;
        }
    }
}