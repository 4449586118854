$Poppins: "Poppins", sans-serif;

#quiz_result_modal{
    max-width: 870px;

    .modal-content{
        background: #282828;
        min-height: 500px;
    }

    .modal-header{
        align-items: center;
        background: #333333;
        border: none;
        padding: 18px 20px;

        .quiz_title{
            align-items: center;
            color: #E5E5E5;
            display: flex;
            font: 500 12px/15.6px $Poppins;
            gap: 10px;
            margin-bottom: 3px;
    
            > p {
                margin-bottom: 0;
                max-width: 200px;
            }
    
            .right_icon{
                background: transparent url("https://assets.codingdojo.com/learn_platform/admin/student_progress/quizzes_sprite.png") no-repeat -87px -21px;
                display: block;
                height: 12px;
                width: 12px;
            }
        }

        .close_btn{
            background: transparent url("https://assets.codingdojo.com/learn_platform/global/close_icon_white.png") no-repeat center / contain;
            border: none;
            height: 24px;
            outline: none;
            width: 24px;
        }
    }

    .questions_list{
        padding: 45px 0;
    }

    ol{
        li{
            margin-bottom: 52px;

            &:last-child{
                margin-bottom: 0px;
            }

            .blank_question_container{
                margin: 18px auto 0;
                width: 84%;

                input{ 
                    background: #595959;
                    border-radius: 2px;
                    border: 2px solid #A4B0C0;
                    box-sizing: border-box;
                    color: #fff;
                    font: normal 300 16px/24px $Poppins;
                    height: 49px;
                    outline: none;
                    padding: 12px 24px;
                    width: 320px;

                    &.wrong{
                        border: 2px solid #CC392E !important;
                    }
    
                    &.correct{
                        border: 2px solid #05DA80 !important;
                    }
                }
            }

            .question_header{
                align-items: center;
                display: flex;
                justify-content: space-between;
                margin: 0px auto;
                width: 84%;
                                    
                p{
                    color: #E5E5E5;
                    font: normal 500 16px/24px $Poppins;
                    margin: 0px 20px 0px 0px;
                }

                >img{
                    height: 22px;
                    width: 22px;
                }
            }

            .question_choice{
                align-items: center;
                background: #404040;
                border-radius: 2px;
                box-sizing: border-box;
                color: #fff;         
                display: flex;
                font: normal 300 16px/24px $Poppins;
                margin: 18px auto 0px auto;
                min-height: 50px;
                padding: 10px 10px 10px 51px;
                position: relative;
                width: 84%;

                &::before{
                    content: "";
                    height: 16px;
                    position: absolute;
                    width: 16px;
                }

                &.checked{
                    &::before{
                        background: #81A0EA url("https://assets.codingdojo.com/learn_platform/admin/student_progress/quizzes_sprite%20%281%29.png") no-repeat -153px -22px !important;
                        border-radius: 4px !important;
                        border: 2px solid #81A0EA !important;
                        left: 19px;
                    }
                }

                &.checkbox{
                    &::before{
                        background: #fff;
                        border-radius: 4px !important;
                        border: 1px solid #A4B0C0;
                        left: 20px;
                    }
                }

                &.selected{
                    &::before{
                        background: #fff;
                        border-radius: 50%;
                        border: 4px solid #81A0EA;
                        height: 16px;
                        left: 19px;
                    }
                }

                &.radio_btn{
                    &::before{
                        background: #fff;
                        border-radius: 50%;
                        border: 1px solid #A4B0C0;
                        left: 20px;
                    }
                }

                &.wrong{
                    background: #595959;
                    border: 2px solid #F56B6B !important;
                    box-sizing: border-box;
                }

                &.correct{
                    background: #595959;
                    border: 2px solid #05DA80 !important;
                    box-sizing: border-box;
                }
            }

            .code_editor_container{
                background: #F8FAFF;
                margin: 19px 0px 20px 0px;
                
                .editor{
                    counter-reset: line;

                    textarea {
                        outline: none;
                        padding: 21px 0px 20px 80px !important;
                    }
                      
                    pre {
                        padding: 21px 0px 20px 80px !important;
                        position: relative;

                        &::before, 
                        &::after{
                            background: #DCE6FF;
                            content: "";
                            height: 21px;
                            left: 0;
                            position: absolute;
                            width: 60px;
                        }

                        &:before{
                            top: 0;
                        }

                        &:after{
                            bottom: 0;
                        }
                    }
                      
                    .editor_line_number {
                        background: #DCE6FF;
                        color: #BDBDBD;
                        font: 500 16px/24px $Poppins;
                        left: 0px;
                        padding: 0px 20px;
                        position: absolute;
                        text-align: left;
                        width: 60px;
                    }
                      
                }
                
                .dc_iframe{
                    iframe{
                        width: 100% !important;
                    }
                }
            }

            .notes{
                align-items: flex-start;
                border-radius: 2px;
                box-sizing: border-box;
                display: flex;
                flex-direction: column;
                font: normal 400 12px/18px $Poppins;
                margin: 18px auto 0px auto;
                min-height: 50px;
                padding: 12px 24px;
                width: 84%;

                &.notes_correct_answer{
                    background: #4B4B4B;
                    border: 1px solid #E5EEFD;
                    color: #E9E9E9;         
                }
    
                &.notes_wrong_answer{
                    background: #FBCACA ;
                    border: 1px solid #FCE4E5;      
                    color: #5B1010;
                }

                p{
                    margin: 0 !important;
                }
            }
        }
    }

    #loading_container{
        align-items: center;
        display: flex;
        flex-direction: column;
        left: 50%;
        margin-left: 0;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 50px;

        div{
            background-image: url("https://assets.codingdojo.com/learn_platform/admin/global/table_loader.gif");
            background-size: cover;
            height: 30px;
            margin-bottom: 7px;
            mix-blend-mode: lighten;
            width: 30px;
        }

        span{
            color: rgba(255, 255, 255, 0.88);
            font: 500 14px/18.2px $Poppins;
        }
    }
}


.student_side{
    #quiz_result_modal{
        .modal-content{
            background: #fff;
        }
        .modal-header{
            background: #fff;
            .close{
                color: #152C61;
                opacity: 1;
            }
            button{
                transform: scale(1.2);
            }
            .student_header{
                color: #152C61;
                font: normal 500 26px/24px $Poppins;
                margin: 0;
            }
        }    
        .question_header{
            p{
                color: #152C61;
            }
        }
        .question_choice{
            background: #fff;
            border: 2px solid #E5EEFD;
            color: #152C61;
            &.correct, &.wrong{
                background: #F8FAFF;
            }
            &.checked{
                &::before{
                    background: #295EDB url("https://assets.codingdojo.com/learn_platform/admin/student_progress/quizzes_sprite%20%281%29.png") no-repeat -118px -22px !important;
                    border-radius: 4px !important;
                    border: 2px solid #295EDB !important;
                    left: 19px;
                }
            }
        }
        #loading_container{
            &.student_loading{
                div{
                    background-image: url("https://codingdojo_assets.s3.us-east-1.amazonaws.com/learn_platform/user/stats/loading.gif");
                    transform: scale(0.6);
                    width: 67px;
                }
                span{
                    color: rgba(0, 0, 0, 0.88);
                    font: 500 14px/2px $Poppins;
                }
            }
        }
    }
}
body.is_dark_mode{
    .student_side{
        #quiz_result_modal{
            .modal-content{
                background: #2a2a2a !important;
            }
            .modal-header{
                background: #2a2a2a;
                .close{
                    color: #fff;
                    opacity: 1;
                }
                button{
                    transform: scale(1.2);
                }
                .student_header{
                    color: #fff;
                    font: normal 500 26px/24px $Poppins;
                    margin: 0;
                }
            }    
            .question_header{
                p{
                    color: #fff;
                }
            }
            .question_choice{
                background: #404040;
                border: 2px solid transparent;
                color: #fff;
                &.correct, &.wrong{
                    background: #595959;
                }
                &.checked{
                    &::before{
                        background: #81A0EA url("https://assets.codingdojo.com/learn_platform/admin/student_progress/quizzes_sprite%20%281%29.png") no-repeat -153px -22px !important;
                        border-radius: 4px !important;
                        border: 2px solid #81A0EA !important;
                        left: 19px;
                    }
                }
            }
            #loading_container{
                &.student_loading{
                    div{
                        background-image: url("https://codingdojo_assets.s3.us-east-1.amazonaws.com/learn_platform/dark_mode/stats/loading_dot_white.gif");
                    }
                    span{
                        color: rgba(255, 255, 255, 0.88);
                    }
                }
            }
        }
    }
}