$tooltip_text_color: #E5E5E5;

#tooltip_data_presentation_popover{
    background-color: #434343;
    border-radius: 4px;
    border: solid 1px #56595d;
    max-width: 1000px;
    opacity: 1;
    padding: 0;

    &.bs-popover-right .arrow::after{
        border-right-color: #434343;
    }
    &.bs-popover-left .arrow::after{
        border-left-color: #434343;
    }
    &.bs-popover-top .arrow::after{
        border-top-color: #434343;
    }
    &.bs-popover-bottom .arrow::after{
        border-bottom-color: #434343;
    }

    .popover-body{
        color: $tooltip_text_color;
        font: 400 12px "poppins";
        height: 168px;
        padding: 19px 24px 23px 20px;
        text-align: start;
        width: 515px;

        > h6{
            font: 700 12px/130% "poppins";
            margin-bottom: 14px;
        }

        ul{
            display: flex;
            list-style: none;
            padding: 0;

            li{
                align-items: start;
                display: flex;
                justify-content: space-between;

                &:after{
                    border-radius: 2px;
                    content: "";
                    height: 10px;
                    margin-top: 31px;
                    position: absolute;
                    width: 10px;
                }

                &.green{
                    margin-right: 51px;
                    &:after{
                        background: #04aa64;
                        margin-left: 18px;
                    }
                }
                &.yellow{
                    margin-right: 69px;
                    &:after{
                        background: #ffb524;
                        margin-left: 13px;
                    }
                }
                &.red{
                    margin-right: 43px;
                    &:after{
                        background: #ea1010;
                        margin-left: 9px;
                    }
                }
                &.gray{
                    margin-right: 51px;
                    &:after{
                        background: #656565;
                        margin-left: 27px;
                    }
                }
                &.number{
                    &:before{
                        content: "Number";
                        margin-top: 27px;
                        position: absolute;
                    }
                }
            }
        }
    }
}