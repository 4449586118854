#workspace_dropdown{
    background: #3D3D3D;

    &.show{
        .dropdown-toggle{
            .arrow_down{
                -webkit-transform: rotate(225deg);
                -webkit-transition: transform .3s;
                margin-top: 3px;
                transform: rotate(225deg);
                transition: transform .3s;  
            }
        }
    }

    .dropdown-toggle{
        align-items: center;
        box-shadow: none;
        color: #FFFFFF;
        display: flex;
        font-family: "Poppins", sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: normal;
        height: 60px;
        justify-content: center;
        line-height: 24px;
        outline: none;
        width: 100%;


        &:after{
            display: none;
        }

        .arrow_down{
            -webkit-transform: rotate(45deg);
            -webkit-transition: transform .3s;
            border: solid #fff;
            border-width: 0 2px 2px 0;
            display: inline-block;
            margin-left: 9px;
            margin-top: -4px;
            padding: 3px;
            transform: rotate(45deg);
            transition: transform .3s;  
        }
    }

    .dropdown-menu{
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.28), rgba(255, 255, 255, 0.28)), #121212;
        border-radius: 2px;
        border: 1px solid #C2D4FF;
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.07);
        box-sizing: border-box;
        margin-top: -10px !important;
        max-height: 300px;
        overflow: auto;
        width: 244px;
        z-index: 99991;

        .dropdown-header{
            align-items: center;
            color: rgba(255, 255, 255, 0.4);
            display: flex;
            font-family: "Poppins", sans-serif;
            font-size: 12px;
            font-style: normal;
            font-weight: normal;
            line-height: 140%;
            padding: 13px 20px 1px 20px;
        }

        .dropdown-item{
            align-items: center;
            color: rgba(255, 255, 255, 0.88);
            display: flex;
            font-family: "Poppins", sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: normal;
            line-height: 140%;
            padding: 14px 20px;
            outline: none !important;

            &.active{
                background-color: transparent;
            }

            &:focus, &:hover{
                background-color: #3D3D3D !important;
            }

            &.active{
                color: #81A0EA;
                font-weight: 500;
                position: relative;

                &:after{
                    background: #81A0EA;
                    border-radius: 100%;
                    content: "";
                    height: 6px;
                    position: absolute;
                    right: 30px;
                    top: 20px;
                    width: 6px;
                }
            }
        }
    }
}