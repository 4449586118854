#todo_solution_modal{
    max-width: 1230px;
    width: 1230px;
    .modal-content{
        background: #FFFFFF;
        border-radius: 6px;
        border: none;
        box-shadow: 0px 4px 2px rgba(0, 0, 0, 0.05);
        height: 100%;
        .modal-header{
            background: #2C6BFF;
            border: none;
            padding: 0px 28px 0px 28px;
            position: relative;
            .modal-title{
                color: #fff;
                font-family: 'Poppins', sans-serif;
                font-size: 22px;
                font-style: normal;
                font-weight: 500;
                line-height: 44px;
                text-align: center;
                text-align: left;
                width: 100%;
            }
            button{
                background-color: transparent;
                border: none;
                outline: none;
                padding: 0px;
                position: absolute;
                right: 19px;
                top: 6px;
            }
        }
        .modal-body{
            display: flex;
            min-height: 470px; 
            padding: 88px 28px 30px 28px;
            position: relative; 

            /* all loading */
            img#loading_solution{
                height: 100px;
                margin-top: 153px;
                margin: 0px auto;
                width: 100px;
            }

            img#solution_image{
                height: 647px;
                object-fit: contain;
                width: 914px;
            }
            #solution_tab_list{
                display: flex;
                left: -12px;
                position: absolute;
                top: 30px;
                li{
                    list-style: none;
                    button{
                        background: transparent;
                        border: navajowhite;
                        color: #A3A3A3;
                        font-family: 'Poppins', sans-serif;
                        font-size: 16px;
                        font-weight: 500;
                        font-weight: 600;
                        margin-right: 30px;
                        outline: none;
                        padding: 0px;
                        &:hover{
                            opacity: .8;
                        }
                        &.active{
                            border-bottom: 1px solid #2C6BFF;
                            color: #2C6BFF;
                            padding-bottom: 3px;
                        }
                    }
                }

            }

            div:not(#solution_code_container){
                border-radius: 6px;
                border: 1px solid #DCE6FF;
                box-shadow: 0px 4px 2px rgba(0, 0, 0, 0.05);
                box-sizing: border-box;
                margin-right: 20px;
                min-width: 240px;
                padding: 20px 30px 30px 30px;
                width: 240px;
                &#failed_to_fetch_container{
                    align-items: center;
                    border: none;
                    box-shadow: none;
                    display: flex;
                    flex-direction: column;
                    margin: 0px;
                    padding-bottom: 65px;
                    width: 100%;
                }
                &.loading_bar {
                    background-color: transparent;
                    border: none;
                    height: 4px;
                    overflow: hidden;
                    padding: 0;
                    position: absolute;
                    right: 11px;
                    top: 85px;
                    width: 907px;
                    
                    &::after {
                        animation: moveLoadBar 2s cubic-bezier(0.09, 0.89, 0.7, 0.71) infinite;
                        background-color: #2C6BFF;
                        box-shadow: 10px 0 30px 15px #272822, -10px 0 30px 15px #272822;
                        content: '';
                        height: 5px;
                        left: 0;
                        position: absolute;
                        top: 0;
                        width: 160px;
                    }
                }
                  
                @keyframes moveLoadBar {
                    0% {
                        left: -10%;
                    }
                    100% {
                        left: 110%;
                    }
                }
                  
                &#watch_video_container{
                    align-items: center;
                    border: none;
                    box-shadow: none;
                    display: flex;
                    justify-content: center;
                    margin-right: 0px;
                    min-height: 470px;  
                    padding: 30px;
                    width: 100%;
                   
                    button{
                        color: #2c6bff;
                        left: 22px;
                        position: absolute;
                        top: 20px;
                    }
                    >img{
                        height: 100px;
                        width: 100px;
                    }
                }
                *::-webkit-scrollbar {
                    height: 8px;
                    width: 8px;
                }
                
                /* all */
                button{
                    background: transparent;
                    border: none;
                    font-family: 'Poppins', sans-serif;
                    outline: none;
                    &.cd_blue_btn{
                        background: #2C6BFF;
                        border-radius: 6px;
                        color: #fff;
                        height: 46px;
                        padding: 0px 10px;
                        width: 100%;
                    }
                    &.active{
                        color: #2C6BFF;
                        font-weight: 600;
                    }
                    img{
                        height: 20px;
                        margin: 0px;
                        width: 20px;
                    }
                    svg{
                        margin-left: 5px;
                    }
                }
                ul{
                    list-style: none;
                    padding-left: 23px;

                    button{
                        align-items: center;
                        color: #152C61;
                        display: inline-flex;
                        padding: 10px 0px 10px 0px;
                        text-align: left;
                        width: 100%;
                    }
                }
                >ul{
                    height: 520px;
                    margin-bottom: 30px;
                    overflow: auto;
                    padding-left: 0px;
                    width: 100%;
                }
            }

            #solution_code_container{
                height: 648px;
                overflow: auto;
                width: 100%;

                pre{
                    height: 100%;
                    margin-bottom: 0px !important;
                    width: 100%;
                }
            }
            
            #solution_buttons_container{
                text-align: center;
                div{
                    align-items: center;
                    display: flex;
                    justify-content: center;
                    margin-top: 42px;
                    span{
                        color: #152C61;
                        font-family: 'Poppins', sans-serif;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 300;
                        line-height: 21px;
                        margin: 0px 38px;
                        text-align: center;
                    }
                }
            }
            #watch_video_container{
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                iframe{
                    height: 411px;
                    width: 100%;
                }
                .btn-primary{
                    margin-top: 40px;   
                }
            }
        }
    }
}
@media only screen and (max-width: 768px){
    #todo_solution_modal{
        .modal-content{
            .modal-header{
                background: #fff;
                border-bottom: 1px solid #E4E6EC;
                padding: 34px 28px 1px;
                .modal-title{
                    color: #152C61;
                    font-size: 16px;
                    text-align: center;
                }
                #mobile_header{
                    display: none;
                }
                button{
                    left: 28px;
                    position: absolute;
                    right: unset !important;
                    top: 40px !important;
                }
                &.show_editor{
                    #mobile_header{
                        background: #ffffff;
                        display: block;
                        height: 80px;
                        left: 0px;
                        position: absolute;
                        top: 0px;
                        width: 100%;
                        z-index: 2;
                        button{
                            top: 43px !important;   
                        }
                        .modal-title{
                            padding: 33px 20px 0px 50px;
                        }
                    }
                }
            }
            .modal-body{
                padding: 84px 20px 30px 20px;
                &.show_editor {
                    padding: 0px 0px 30px;
                    #solution_tab_list, div{
                        display: none;
                    }
                    #solution_code_container{
                        border-radius: 0px;
                        display: block;
                        height: 100%;
                        pre{
                            border-radius: 0px;
                        }
                    }
                }
                img#loading_solution{
                    height: 50px;
                    width: 50px;
                }
                
                .swiper-container, .swiper-wrapper, .swiper-slide{
                    border: none !important;
                    box-shadow: none !important;
                    min-width: unset !important;
                    padding: unset !important;
                }
                div.swiper-container{
                    display: flex;
                    left: 15px;
                    position: absolute;
                    top: 30px;
                    .swiper-wrapper{
                        .swiper-slide{
                            padding-bottom: 3px;
                            width: unset !important;
                            &.active{
                                a{
                                    border-bottom: 2px solid #2c6bff;
                                    color: #152c61;
                                    font: normal 600 16px/24px "Poppins",sans-serif;
                                    opacity: 1;
                                }
                            }
                            a{
                                color: #000;
                                font: normal normal 16px/24px "Poppins",sans-serif;
                                opacity: .3;
                                text-align: center;
                                text-decoration: none;
                            }
                        }
                    }
                }
            
                #solution_tab_list{
                    left: -20px;
                    li{
                        button.active{
                            color: #152C61;
                            border-bottom: 2px solid #2C6BFF;
                        }
                    }
                }
                div:not(#solution_code_container){
                    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
                    min-width: 100%;
                    width: 100%;
                }
              
            }
    
        }
    }
    body.is_dark_mode{
        .modal{
            #todo_solution_modal{
                .modal-content{
                    .modal-header{
                        border-bottom: 1px solid #4b4b4b;
                        .modal-title{
                            color: rgba(255, 255, 255, 0.88);
                        }
                        &.show_editor{
                            #mobile_header{
                                background: #4b4b4b;
                                .modal-title{
                                    background: #4b4b4b;
                                }

                            }
                        }
                        button{
                            color: #81a0ea;
                            img{
                                display: none;
                            }
                        }
                    }
                    .modal-body{
                        .swiper-container{
                            background: #333333 !important;
                            .swiper-wrapper {
                                background: #333333 !important;
                                .swiper-slide{
                                    background: #333333 !important;
                                    button{
                                        background: #333333;
                                        color: rgba(255, 255, 255, 0.88) !important;
                                        &.active{
                                            color: #81a0ea !important;
            
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}