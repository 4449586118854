.todo_confirmation_modal{
    .modal-dialog{
        .modal-content{
            .modal-body{
                p{
                    width: 600px !important;
                    
                    a{
                        color: #2C6BFF !important;
                        font-family: 'Poppins', sans-serif;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 24px;
                        text-align: center;
                        text-decoration: underline;
                    }
                }
            }
            .modal-footer{
                justify-content: space-between !important;
                padding: .75rem 140px 60px 140px;
            }
        }
    }
}
#todo_modal{
    max-width: 791px;
    width: 791px;
    .modal-content{
        background: #FFFFFF;
        border-radius: 6px;
        box-shadow: 0px 4px 2px rgba(0, 0, 0, 0.05);
        .modal-header{
            border: none;
            justify-content: center;
            padding: 61px 40px 34px 40px;
            position: relative;
            .modal-title{
                color: #152C61;
                font-family: 'Poppins', sans-serif;
                font-size: 22px;
                font-style: normal;
                font-weight: 500;
                line-height: 33px;
            }
            button{
                background-color: transparent;
                border: none;
                outline: none;
                padding: 0px;
                position: absolute;
                right: 26px;
                top: 26px;
                .close_icon{
                    background-image: url("https://assets.codingdojo.com/learn_platform/global/close_icon_new.png");
                    display: inline-block;
                    height: 33px;
                    width: 33px;
                }
            }
        }
        .modal-body{
            padding: 5px 0px 0px 0px;
            text-align: center;

            img{
                margin-bottom: 41px;
            }
            p{
                color: #152C61;
                font-family: 'Poppins', sans-serif;
                font-size: 16px;
                font-style: normal;
                font-weight: normal;
                line-height: 24px;
                margin: 0px auto 24px auto;
                text-align: center;
                width: 360px;
                &#extend_eaa_description{
                    width: 485px;
                }
                >span{
                    color: #A93333;
                }
                a{
                    color: #152C61;

                    span{
                        color: #2C6BFF;
                    }
                }
            }
        }

        .modal-footer{
            border: none;
            justify-content: center;
            padding-bottom: 60px;

            >*{
                margin: 0px;
            }
            button{
                align-items: center;
                border-radius: 6px;
                display: flex;
                font-family: 'Poppins', sans-serif;
                font-size: 18px;
                font-style: normal;
                font-weight: 500;
                height: 46px;
                justify-content: center;
                line-height: 27px;
                text-align: center;
                width: 210px;

                &.btn-primary{
                    background: #2C6BFF;
                    color: #fff;
                }

                &.btn-outline-primary{
                    background-color: #fff;
                    border: 1px solid #2C6BFF;
                    color: #2C6BFF;
                }
            }
        }
    }
    @media only screen and (max-width: 768px){
        .modal-content{
            .modal-header{
                text-align: center;
            }
            .modal-body{
                flex: unset;
                padding: 5px 20px 0px 20px;
                p{
                    width: 100% !important;
                }
            }
            .modal-footer{
                .btn-outline-primary{
                    margin-bottom: 16px;
                }
            }
        }
    }
}