$Poppins: "Poppins", sans-serif;
/* COLOR */
$dark_gray: #282828;
$black: #000;
$white: #fff;

#edit_survey_container{
    background: #121212;
    height: calc(100% - 68px);
    overflow: auto;
    padding-right: 30px;
    position: absolute;
    right: -1000%;
    top: 68px;
    transition: .5s ease-in-out;
    width: calc(100% - 30px);
    z-index: 99;

    &.show{
        right: 0px;
        transition: .25s ease-in-out;
    }
    button{
        background: transparent;
        color: #E5E5E5;
        outline: none;
    }
    .header_container{
        color: #B3B3B3;
        display: flex;
        padding: 23px 0px 17px;
        button{
            border: none;
            margin-right: 18px;
        }
        h6{
            align-items: center;
            display: flex;
            font-family: $Poppins, sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            margin-bottom: 0px;
            svg{
                height: 4px;
                margin: 0px 10px;
                width: 4px;
            }
        }
    }
    .edit_content{
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.14), rgba(255, 255, 255, 0.14)), #121212;
        border-radius: 4px;
        max-height: calc(100vh - 230px);
        min-width: 1045px;
        overflow-y: auto;
        padding: 19px 19px 25px;
        width: 100%;


        .content{
            border-bottom: 1px solid rgba(204, 224, 255, 0.14);
            color: rgba(255, 255, 255, 0.88);
            margin-bottom: 22px;
            padding-bottom: 26px;
            &:first-child{
                border-top-left-radius: 4px;
                border-top-right-radius: 4px;
                display: flex;
            }
            &:last-child{
                border-bottom-left-radius: 4px;
                border-bottom-right-radius: 4px;
                border: none;
                margin-bottom: 0px;
                padding-bottom: 0px;
                .survey_select {
                    .react-dropdown-select-dropdown{
                        min-height: 239px;
                    }
                }
            }
            .left_container{
                margin-right: 30px;
            }
            .selected_multi_item{
                display: inline-block;
                .remove{
                    background: url("https://assets.codingdojo.com/learn_platform/admin/global/dropdown_icons.png") no-repeat -24px -4px;
                    border: none;
                    cursor: pointer;
                    height: 12px;
                    margin-left: 9.17px;
                    margin-top: -1px;
                    outline: none;
                    position: absolute;
                    right: 10px;
                    top: 10px;
                    vertical-align: middle;
                    width: 12px;
                }
            }
            h5{
                font-family: $Poppins, sans-serif;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                margin-bottom: 17px;
            }
            p{
                color: rgba(255, 255, 255, 0.88);
                font-family: $Poppins, sans-serif;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                margin-bottom: 19px;
                &.info_text{
                    color: rgba(255, 255, 255, 0.4);
                    font-size: 11px;
                    font-style: italic;
                    margin-top: -12px;
                    &.error_message{
                        color: #F56B6B;
                        font-style: normal;
                    }
                }
            }
            label, .label_title{
                align-items: center;
                color: rgba(255, 255, 255, 0.4);
                display: block;
                font-family: $Poppins, sans-serif;
                font-size: 12px;
                font-weight: 600;
                margin-bottom: 9px;
            }
    
            input[type="checkbox"]{
                display: none;
            }
            .datepicker_container{
                position: relative;
                .fa-calendar{
                    color: rgba(255, 255, 255, 0.58);
                    font-size: 14px;
                    left: 16px;
                    position: absolute;
                    top: 39px;
                }
            }
    
            .checkbox{
                margin-bottom: 34px;
                width: 352px;
                &:last-child{
                    margin-left: 40px;
                }
    
                .fa-check{
                    display: none;
                }
                input[type="checkbox"]:checked ~ label{
                    .checkbox_container{
                        background-color: #81A0EA;
                        .fa-check{
                            color: #fff;
                            display: inline;
                            font-size: 10px;
                        }
                    }    
                }
                label{
                    align-items: flex-start;
                    cursor: pointer;
                    display: flex;
                    margin-bottom: 0px;
                    .checkbox_container{ 
                        align-items: center;
                        border-radius: 2px;
                        border: 1px solid #81A0EA;
                        cursor: pointer;
                        display: flex;
                        height: 18px;
                        justify-content: center;
                        margin: 2px 10px 0 0;
                        min-width: 18px;
                    }
                    >span{
                        color: rgba(255, 255, 255, 0.88);
                        font-style: normal;
                        font: 14px/18px $Poppins;
                    }                        
                }
            }
    
            input[type="text"], input[type="url"], textarea, .react-dropdown-select{
                background: rgba(204, 224, 255, 0.14);
                border-radius: 4px;
                border: none;
                color: rgba(255, 255, 255, 0.88);
                font: 14px/18px $Poppins;
                height: 40px;
                margin-bottom: 17px;
                outline: none;
                padding: 0 15px;
                resize: none;
                width: 525px;
                &.start_date_survey, &.end_date_survey{
                    padding-left: 41px;
                    width: 350px;
                }
                &.end_date_survey{
                    margin-bottom: 0px;
                }
            }
            .react-datepicker-wrapper{
                width: 100%;
                .survey_duration_button{
                    align-items: center;
                    background: #3E4146;
                    border-radius: 4px;
                    border: none;
                    color: #ffff;
                    display: flex;
                    outline: none;
                    padding: 10px 10px 10px 20px;
                    max-width: 525px;
                    width: 100%;

                    span{
                        font: 14px/18px $Poppins;
                        margin-right: 12px;
                    }
                    svg{
                        margin-right: 12px;
                    }
                }
            }
            .react-datepicker__triangle{
                display: none;
            }
            .react-datepicker__day--disabled{
                opacity: .2;
            }
            .react-datepicker__day--selected{
                border-radius: 100%;
            }
            .react-datepicker__day--today{
                background: $dark_gray;
                border-radius: 100%;
            }
            .react-datepicker__day--in-range{
                border-radius: 0px;
                &.react-datepicker__day--range-start{
                    border-radius: 8px 0 0 8px !important;
                }
                &.react-datepicker__day--range-end{
                    border-radius:  0 8px 8px 0 ;
                }
            }
            .react-datepicker__day--in-selecting-range{
                border-radius: 0px;
                &.react-datepicker__day--selected.react-datepicker__day--selecting-range-start{
                    border-radius: 8px 0 0 8px;
                }
            }

            .react-datepicker{
                background-color: #4B4B4B;
                border: none;
                display: flex;
                .react-datepicker__day--in-range, .react-datepicker__day--in-selecting-range{
                    background: #2F2F2F !important;
                    &:hover{
                        background: #5865F2 !important;
                        border-radius:  0 8px 8px 0 ;
                    }
                }
                .react-datepicker__day--range-start, .react-datepicker__day--range-end, .react-datepicker__day--selected{
                    background: #5865F2 !important;
                }
                .react-datepicker__month-container{
                    .react-datepicker__header{
                        background-color: #4B4B4B;
                        border-bottom: none;
                        padding: 20px 20px 0px;

                        .react-datepicker__current-month{
                            color: $white;
                            font: 14px/18px $Poppins;
                        }

                        .react-datepicker__day-names{
                            display: flex;
                            justify-content: space-around;
                            .react-datepicker__day-name{
                                color: $white;
                                font: 14px/18px $Poppins;
                                margin-top: 16px;
                            }
                        }
                    }
                    .react-datepicker__month{
                        margin: 0.4rem 21px;
                        .react-datepicker__week{
                            display: flex;
                            .react-datepicker__day{
                                align-items: center;
                                color: $white;
                                display: inline-flex;
                                flex-wrap: wrap;
                                font: 12px/16px $Poppins;
                                height: 30px;
                                justify-content: center;
                                margin: 2px 0px;
                                width: 100%;
                                &:hover{
                                    background-color: $dark_gray;
                                }

                                &.react-datepicker__day--selected{
                                    background-color: $dark_gray;
                                }

                                &.react-datepicker__day--outside-month{
                                    opacity: .5;
                                }
                            }
                            .react-datepicker__day--keyboard-selected{
                                background: transparent;
                            }
                        }
                    }
                }

                .react-datepicker__navigation{
                    outline: none;
                    top: 20px;
                    .react-datepicker__navigation-icon{
                        &::before{
                            border-color: $white;
                            opacity: .5;
                        }

                        &:hover{
                            &::before{
                                opacity: 1;
                            }
                        }
                    }
                }

                .react-datepicker__navigation--previous{
                    left: 17px;
                }

                .react-datepicker__navigation--next{
                    right: 17px;
                }
            }
    
            textarea{
                height: 120px;
                margin-bottom: 0px;
                padding: 15px;
            }
            #add_custom_survey_type_button{
                background: rgba(255, 255, 255, 0.18);
                border-radius: 2px;
                color: rgba(255, 255, 255, 0.58);
                font-family: $Poppins, sans-serif;
                font-size: 12px;
                font-style: italic;
                font-weight: 600;
                padding: 11px 33px;
                svg{
                    font-size: 14px;
                    margin-right: 8px;
                }
            }
        }
    }
    #save_survey_container{
        display: flex;
        justify-content: left;
        padding: 20px 0px;
        button{
            background: rgba(204, 224, 255, 0.14);
            border-radius: 2px;
            border: none;
            color: #81A0EA;
            font-family: $Poppins, sans-serif, sans-serif;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            padding: 7px 11px;
        }
    }
}
