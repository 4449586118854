$Poppins: "Poppins", sans-serif;


button.customize_column_btn{
    background-color: transparent;
    border-bottom: 2px solid transparent;
    border: none;
    color: rgba(255, 255, 255, 0.48);
    display: flex;
    font-family: $Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: normal;
    justify-content: space-between;
    line-height: 130%;
    outline: none !important;
    padding: 8px 16px;

    .customize_column_icon{
        background-image: url("https://assets.codingdojo.com/learn_platform/admin/student_progress/table_navigation_icons.png");
        background-position: -52px -28px;
        background-repeat: no-repeat;
        display: flex;
        height: 16px;
        margin-right: 12px;
        width: 16px;
    }
}


button.customize_column_btn[aria-describedby="customize_columns_popover"]{
    color: #81A0EA !important;
    font-weight: 600 !important;

    .customize_column_icon{
        background-position: -52px -4px !important;
    }
}

#customize_columns_popover{
    background: #333333;
    border-radius: 2px;
    border: 1px solid #81A0EA;
    margin-top: 0px !important;
    width: 300px;

    .arrow{
        display: none;
    }

    .popover-body{
        padding: 20px;

        h5{
            color: #FFFFFF;
            font-family: $Poppins;
            font-size: 12px;
            font-style: normal;
            font-weight: 600;
            line-height: 130%;
        }

        ul{
            margin-bottom: 0px;
            max-height: 500px;
            overflow: auto;
            
            li{
                align-items: center;
                background: #2E2E2E;
                border-radius: 2px;
                border: 1px solid rgba(178, 178, 178, 0.2);
                color: #FFFFFF;
                display: flex;
                font-family: $Poppins;
                font-size: 12px;
                font-style: normal;
                font-weight: 600;
                justify-content: space-between;
                line-height: 130%;
                margin-bottom: 10px;
                padding: 10px 20px;

                &:last-child{
                    margin: 0px;
                }

                .checkbox_button_container{
                    input{
                        display: none;
                    }

                    input:checked ~ label{
                        justify-content: flex-end;
                        .fill{
                            background: #81A0EA;
                        }
                    }

                    label{
                        align-items: center;
                        background: #121212;
                        border-radius: 48px;
                        cursor: pointer;
                        display: flex;
                        height: 16px;
                        margin-bottom: 0px;
                        padding: 0px 2px;
                        width: 32px;
                        
                        .fill{
                            background-color: #B2B2B2;
                            border-radius: 100%;
                            display: flex;
                            height: 12px;
                            width: 12px;
                        }
                    }
                }
            }
        }
    }
}