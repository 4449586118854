#to_do_container{
    background: #FFFFFF;
    border-radius: 6px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
    margin-bottom: 50px;
    padding: 30px 25px 7px 25px; 
    width: 100%;
    h2{
        font: normal 500 23px/34px 'Poppins';
        margin-bottom: 9px;
    }
    ul{
        list-style: none;
        padding: 0;
        user-select: none;
        li:nth-child(1){
            label{
                border: none;    
            }
        }
        li{
            label{
                border-top: 1px solid #DEE5EF;
                padding: 16px 0px 3px 32px;
                position: relative;
                width: 100%;

                input{
                    display: none;
                }

                &::before{
                    border-radius: 2px;
                    border: 1px solid #152C61;
                    content: "";
                    height: 16px;
                    left: 0;
                    position: absolute;
                    top: 16px;
                    width: 16px;
                }

                &::after{
                    background: rgba(44, 107, 255, 1) url("https://assets.codingdojo.com/learn_platform/user/dashboard/dashboard_redesign_icons.png") no-repeat -528px -15px;
                    border-radius: 2px;
                    border: none;
                    content: "";
                    display: none;
                    height: 16px;
                    left: 0;
                    position: absolute;
                    top: 16px;
                    width: 16px;
                }

                #to_do_type{
                    color: rgba(21, 44, 97, .6);
                    font-family: "Poppins", sans-serif;
                    font-size: 10px;
                    margin-bottom: 4px;
                }

                #to_do_title{
                    color: rgba(21, 44, 97, 1);
                    font-family: "Poppins", sans-serif;
                    font-size: 12px;
                    font-weight: 600;
                    margin-bottom: 4px;
                }

                #to_do_due_date{
                    color: rgba(21, 44, 97, .6);
                    font-family: "Poppins", sans-serif;
                    font-size: 10px;
                    margin-bottom: 0px;

                    strong{
                        color: rgba(44, 107, 255, 1);
                        font-weight: 600;
                        &.overdue{
                            color: rgba(204, 57, 46, 1);
                        }
                    }
                }

                &.done{
                    #to_do_title{
                        color: rgba(151, 151, 151, 1);
                        text-decoration: line-through;
                    }

                    #to_do_due_date{
                        color: rgba(163, 163, 163, 1);
                        font-weight: 600;
                        margin-bottom: 0px;
                    }

                    &::after{
                        display: block;
                    }
                }
            }
        }
    }
}

.is_dark_mode{
    #to_do_container{
        background-color: #333;

        .arrow::after{
            border-bottom-color: #333;
        }

        h2{
            color: #E5E5E5;
        }
        ul{
            label{
                border-top-color: #666666;
                #to_do_type, #to_do_title{
                    color: #E5E5E5;
                }
                #to_do_due_date{
                    color: #B2B2B2;
                    strong{
                        color: #81A0EA;
                        &.overdue{
                            color: #F56B6B;
                        }
                    }
                }
                &::before{
                    border-color: #81A0EA;
                }
                &::after{
                    background-color: #81A0EA;
                }
            }
        }
    }
}

@media only screen and (max-width: 768px){
    #to_do_container{
        & ~ * {
            display: none !important;
        }
    }
}