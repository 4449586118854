$Poppins: "Poppins", sans-serif;

#custom_survey_type_container{
    background: #121212;
    height: 100%;
    max-height: calc(100% - 81px);
    overflow: auto;
    position: absolute;
    right: -1000%;
    top: 68px;
    transition: .5s ease-in-out;
    width: calc(100% - 30px);
    z-index: 99;
    &.show{
        padding-right: 30px;
        right: 0px;
        transition: .25s ease-in-out;
    }
    button{
        background: transparent;
        border: none;
        color: #E5E5E5;
        outline: none;
    }
    .header_container{
        color: #B3B3B3;
        display: flex;
        padding: 23px 0px 17px;
        button{
            margin-right: 18px;
        }
        h6{
            align-items: center;
            display: flex;
            font-family: $Poppins, sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            margin-bottom: 0px;
            svg{
                height: 4px;
                margin: 0px 10px;
                width: 4px;
            }
        }
    }
    
    .content{
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.14), rgba(255, 255, 255, 0.14)), #121212;
        border-radius: 4px;
        color: rgba(255, 255, 255, 0.88);
        max-height: calc(100% - 169px);
        min-width: 1040px;
        overflow-y: scroll;
        padding: 31px 19px;
        width: 100%;
        h6{
            font-family: $Poppins, sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            margin-bottom: 11px;
        }
        p{
            color: rgba(255, 255, 255, 0.88);
            font-family: $Poppins, sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            margin-bottom: 19px;
        }
        label{
            align-items: center;
            color: rgba(255, 255, 255, 0.4);
            display: block;
            font-family: $Poppins, sans-serif;
            font-size: 12px;
            font-weight: 600;
        }
        table{
            border-bottom: 1px solid rgba(204, 224, 255, 0.14);
            margin-bottom: 32px;
            width: 100%;
            tbody{
                tr{
                    td{
                        padding-bottom: 30px;
                        position: relative;
                        vertical-align: top;
                        &:first-child{
                            width: 364px;
                        }
                        &:nth-child(2){
                            width: 444px;
                        }
                        &:nth-child(3){
                            width: 194px;
                        }
                        h5{
                            align-items: center;
                            color: rgba(255, 255, 255, 0.4);
                            display: flex;
                            font-family: $Poppins, sans-serif, sans-serif;
                            font-size: 12px;
                            font-weight: 600;
                            svg{
                                margin-left: 10px;
                            }
                        }
                        input{
                            background: rgba(204, 224, 255, 0.14);
                            border-radius: 4px;
                            border: none;
                            color: rgba(255, 255, 255, 0.58);
                            font-family: $Poppins, sans-serif, sans-serif;
                            font-size: 14px;
                            outline: none;
                            padding: 10px 16px;
                            &.border_error{
                                border: 1px solid #EB5757;
                            }
                            &.survey_type_name_input{
                                width: 340px;
                            }
                            &.typeform_link_input{
                                width: 420px;
                            }
                        }
                        svg{
                            color: #AAAAAA;
                        }
                        button{
                            margin-top: 33px;
                            outline: none;
                            svg{
                                color: #858585;
                            }
                        }

                        .switch_container{
                            display: inline-block;
                            margin-bottom: 0px;
                            margin-top: 18px;
                            vertical-align: top;
                            input[type=checkbox]{
                                display: none;
                            }
                            label {
                                background: linear-gradient(0deg, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.3)), #121212;
                                border-radius: 100px;
                                cursor: pointer;
                                display: block;
                                height: 12px;
                                margin-bottom: 0px;
                                position: relative;
                                text-indent: -9999px;
                                width: 24px;
                                
                            }
                            label:after {
                                background: #B3B3B3;
                                border-radius: 90px;
                                content: '';
                                height: 10px;
                                left: 1px;
                                position: absolute;
                                top: 1px;
                                transition: 0.3s;
                                width: 10px;
                            }
                            input:checked + label {
                                background: rgba(84, 204, 176, 0.4);
                            }
                            input:checked + label:after {
                                background: #05DA80;
                                left: calc(100% - 1px);
                                transform: translateX(-100%);
                            }
                            label:active:after {
                                width: 10px;
                            }
                        }
                    }
                }
            }
        }
        #add_custom_survey_type_button{
            background: rgba(255, 255, 255, 0.18);
            border-radius: 2px;
            color: rgba(255, 255, 255, 0.58);
            font-family: $Poppins, sans-serif;
            font-size: 12px;
            font-style: italic;
            font-weight: 600;
            padding: 11px 33px;
            svg{
                font-size: 14px;
                margin-right: 8px;
            }
        }
    }
    #save_custom_survey_container{
        display: flex;
        justify-content: right;
        padding: 32px 0px;
        min-width: 1040px;
        button{
            background: rgba(204, 224, 255, 0.14);
            border-radius: 2px;
            color: #81A0EA;
            font-family: $Poppins, sans-serif, sans-serif;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            padding: 7px 11px;
        }
    }
}
