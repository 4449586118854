$Poppins: 'Poppins', sans-serif;
#assign_to_instructor_popover{
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.24), rgba(255, 255, 255, 0.24)), linear-gradient(180deg, #121212 0%, rgba(18, 18, 18, 0) 100%);
    border-radius: 4px;
    bottom: 80px;
    left: 50%;
    padding: 10px 18px 10px;
    position: absolute;
    transform: translate(-50%, 0%);
    width: 575px;
    z-index: 9;
    div.content{
        align-items: center;
        display: flex;
        height: 50px;
        justify-content: space-between;
    }

    p{
        color: rgba(255, 255, 255, 0.88);
        font-family: $Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: normal;
        line-height: 130%;
        margin-bottom: 0px;
        span{
            font-weight: 500;
        }
    }
    >ul{
        color: #fff;
        margin-bottom: 6px;
        margin-top: 13px;
        padding-left: 0px;
        li{
            display: inline-block;
            list-style: none;
            margin-right: 4px;
        }
    }
    button{
        background: transparent;
        border: none;
        cursor: pointer;
        font-family: $Poppins;
        font-size: 14px;
        outline: none;
    }
    button[type="submit"]{
        background: rgba(204, 224, 255, 0.14);
        border-radius: 2px;
        color: #ffffff;
        padding: 8px 19px;
    }
    #full_list_button{
        color: #81A0EA;
    }
    .dropdown_container{
        position: relative;
        .key_icon{
            color: #fff;
            font-size: 14px;
            left: 13px;
            position: absolute;
            top: 11px;
        }
        .react-dropdown-select {
            border-radius: 4px;
            border: 1px solid #81A0EA !important;
            height: 38px;
            padding-left: 32px !important;
            width: 228px;
            input{
                color: #ffff;
                font-family: $Poppins;
                font-size: 14px;
                &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
                    color: #ffff;
                    font-family: $Poppins;
                    font-size: 14px;
                    opacity: 1; /* Firefox */
                }
                
                &:-ms-input-placeholder { /* Internet Explorer 10-11 */
                    color: #ffff;
                    font-family: $Poppins;
                    font-size: 14px;
                }
                
                &::-ms-input-placeholder { /* Microsoft Edge */
                    color: #ffff;
                    font-family: $Poppins;
                    font-size: 14px;
                }
            }
            &[aria-expanded="true"]{
                .react-dropdown-select-dropdown-handle{
                    top: 3px;
                }
            }
            .react-dropdown-select-dropdown-handle{
                color: #fff;
                position: absolute;
                right: 7px;
                top: 8px;
            }
            .react-dropdown-select-content{
                justify-content: unset;
            }
            .react-dropdown-select-dropdown{
                min-width: 440px;
                padding: 17px;
                top: unset;
    
                .dropdown_item{
                    color: #fff;
                    cursor: pointer;
                    font-family: $Poppins;
                    font-size: 14px;
                    margin-bottom: 15px;
                    &:hover{
                        opacity: .8;
                    }
                    
                    &:last-child{
                        margin-bottom: 0px;
                    }
                    span{
                        font-family: $Poppins;
                        font-size: 12px;
                    }
                }
            }
        }
    }
    #assign_to_instructor_button{
        align-items: center;
        background-color: transparent;
        border-radius: 4px;
        border: 1px solid #81A0EA;
        box-sizing: border-box;
        display: flex;
        height: 38px;
        justify-content: center;
        outline: none;
        padding: 10px;
        width: 180px;

        &#copy_emails_button{
            width: 220px;
        }
        
        svg{
            color: #fff;
            font-size: 16px;
            margin-right: 10px;
        }

        span{
            color: rgba(255, 255, 255, 0.88);
            font-family: $Poppins;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 130%;
        }
    }
}