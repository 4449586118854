#privacy_policy_modal{
    max-width: 802px;
    .modal-content{
        color: rgba(21,44,97,1);
        font-family: 'Poppins', sans-serif;
        font-size: 16px;
        text-align: center;
        .modal-header{
            border: none;
            justify-content: flex-end;
            padding: 22px 22px 7px;
            button{
                background: transparent;
                border: none;
                .close_icon{
                    background-image: url("https://assets.codingdojo.com/learn_platform/global/close_icon_new.png");
                    display: inline-block;
                    height: 33px;
                    width: 33px;
                }
            }
        }
        .modal-body{
            font-weight: 500;
            p{
                margin-bottom: 24px;
            }
            ul{
                list-style: none;
                margin: 89px 0px 0px;
                padding: 0px 0px 35px;
                li{
                    margin-bottom: 39px;
                    a{
                        font-size: 18px;
                        text-decoration: none;
                    }
                }
            }
        }
    }
}