$Poppins: 'Poppins', sans-serif;

#add_user_access_modal{
    left: 50%;
    max-width: none;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 400px;
    .modal-content{
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.24), rgba(255, 255, 255, 0.24)), #121212;
        border-radius: 0px;
        box-shadow: 0px 4px 2px rgba(0, 0, 0, 0.05);
        color: rgba(255, 255, 255, 0.88);
        font-family: $Poppins;
        .modal-header{
            background: linear-gradient(0deg, rgba(255, 255, 255, 0.14), rgba(255, 255, 255, 0.14)), #121212;
            border-radius: 0px;
            border: none;
            justify-content: left;
            padding: 18px 23px;
            position: relative;
    
            h4{
                font-family: 'Poppins', sans-serif;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: 16px;
                margin-bottom: 0px;
            }
    
            button{
                background-color: transparent;
                background-image: url("https://codingdojo_assets.s3.us-east-1.amazonaws.com/learn_platform/admin/edit_course/admin_edit_course_sprite.png");
                background-position: -65px 0px;
                border: none;
                height: 15px;
                outline: none;
                padding: 0px;
                position: absolute;
                right: 22px;
                top: 17px;
                width: 15px;
            }
        }
        form{
            .modal-body{
                background: #292929;
                padding: 20px 24px;
                text-align: left;
                p{
                    font-family: $Poppins;
                    font-size: 12px;
                    font-weight: 400;
                    margin: 12px 0px 0px;
                    &.invalid_text{
                        color: #F56B6B;
                    }
                    span{
                        font-weight: 400;
                    }
                }
                input{
                    background: linear-gradient(0deg, rgba(255, 255, 255, 0.24), rgba(255, 255, 255, 0.24)), #121212;
                    border-radius: 4px;
                    border: 1px solid #4B4B4B;
                    color: #FFFFFF;
                    display: flex;
                    font-size: 12px;
                    padding: 15px 16px;
                    width: 100%;
                    &:focus{
                        outline: none;
                    }
                    &:focus-within{
                        border: 1px solid #81A0EA !important;
                        box-shadow: 0px 0px 6px #81A0EA !important;
                    }
                    &.border_red{
                        border: 1px solid #F56B6B;
                    }
                    &#user_email_input.disabled{
                        opacity: 1 !important;
                    }
                }
                label{
                    font-size: 14px;
                    &#who_label{
                        margin-top: 20px;
                    }
                }
                #suggestion_email_list{
                    background: #4B4B4B;
                    border-radius: 4px;
                    border: 1px solid #81A0EA;
                    box-shadow: 0px 0px 6px #81a0ea;
                    font-size: 14px;
                    list-style: none;
                    margin-bottom: 0px;
                    max-height: 260px;
                    overflow-x: auto;
                    padding-left: 0px;
                    position: absolute;
                    width: 350px;
                    z-index: 12;
                    li{
                        padding: 10px 20px;
                    }
                }
                .form-content{
                    display: flex;
                    margin-bottom: 20px;
                    input{
                        &#first_name_input{
                            margin-right: 20px;
                        }
                    }
                }
                .user_role_select{
                    background: #4B4B4B;
                    border-radius: 6px;
                    height: 50px;
                    padding: 2px 14px !important;
                    &[aria-expanded="true"]{
                        border: 1px solid #81A0EA !important;
                        box-shadow: 0px 0px 6px #81A0EA !important;
                    }
                    .react-dropdown-select-input{
                        background: transparent;
                        border: none;
                        left: 0px;
                        margin-left: 0px;
                        position: absolute;
                        &:focus{
                            outline: none;
                        }
                        &:focus-within{
                            border: none !important;
                            box-shadow: none !important;
                        }
                    }
                    .react-dropdown-select-dropdown{
                        border-radius: 4px;
                        border: 1px solid #81A0EA;
                        box-shadow: 0px 0px 6px #81A0EA;
                        margin-top: 15px;
                        width: 100%;
                        .react-dropdown-select-item{
                            border: none;
                            font-size: 12px;
                            &.react-dropdown-select-item-selected{
                                background: transparent;
                                color: #81A0EA;
                                position: relative;
                                &:after{
                                    background-image: url("https://assets.codingdojo.com/learn_platform/admin/access_control/acl_icons_sprite.svg");
                                    background-position: 14px -12px;
                                    content: "";
                                    display: inline-block;
                                    height: 11px;
                                    position: absolute;
                                    right: 19px;
                                    width: 13px;
                                }
                            }
                        }
                    }
                    .react-dropdown-select-dropdown-handle{
                        height: 29px;
                        position: absolute;
                        right: 12px;
                    }
                }
                #success_container{
                    text-align: center;
                    p{
                        margin:  0px 0px 13px;
                    }
                }
            }
            .modal-footer{
                align-items: center;
                background: #333333;
                border: none;
                display: flex;
                height: 79px;
                justify-content: end;

                button{
                    background: transparent;
                    border: none;
                    color: #fff;
                    font-size: 14px;
                    &[type="submit"]{
                        background: rgba(204, 224, 255, 0.14);
                        border-radius: 2px;
                        color: #81A0EA;
                        margin-left: 30px;
                        padding: 8px 16px;
                    }
                }
            }
            .provide_access_label{
                align-items: center;
                cursor: pointer;
                display: flex;
                font: 400 12px/130% "Poppins" !important;
                margin: 19px 0px 0px;
                input[type="checkbox"]{
                    border-radius: 10px;
                    cursor: pointer;
                    display: inline-block;
                    height: 15px;
                    margin-right: 13px;
                    width: 15px;
                    &:focus{
                        outline: none;
                    }
                    &:focus-within{
                        border: none !important;
                        box-shadow: none !important;
                    }
                }
                span{
                    font-weight: 600;
                    margin-left: 5px;
                }
            }
        }
    }
}