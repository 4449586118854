#copy_to_clipboard_container{
    align-items: center;
    background: linear-gradient(0deg , rgba(255, 255, 255, 0.24), rgba(255, 255, 255, 0.24)), #121212;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    min-height: 34px;
    position: fixed;
    width: 300px;
    z-index: 9;

    &.fade_in {
        opacity: 1;
        transition: opacity .35s ease;
    }
    
    &.fade_out {
        opacity: 0;
        pointer-events: none;
        top: -34px;
        transition: opacity .35s ease;
    }
    
    span{
        align-items: center;
        color: #81A0EA;
        display: flex;
        font-family:'Poppins', sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: normal;
        line-height: 130%;
        margin-left: 5px;
    }
}

