$Poppins: 'Poppins', sans-serif;

#my_files_modal{
    max-width: 600px;

    .modal-content{
        border: none;
        .modal-header{
            border: none;
            margin-bottom: 29px;
            padding-bottom: 9px;
            padding-top: 40px;
            position: relative;
            .modal-title{
                align-items: center;
                color: #152C61;
                display: flex;
                font: normal 500 26px/30px "Poppins", sans-serif;
                justify-content: center;
                text-align: center;
                width: 100%;

                span.info_icon{
                    background-image: url("https://assets.codingdojo.com/learn_platform/global/modals/my_files_icons.png");
                    background-position: -24px 0px;
                    display: flex;
                    height: 24px;
                    margin-left: 16px;
                    width: 24px;
                }
            }
            button{
                background-color: transparent;
                border: none;
                outline: none;
                padding: 0px;
                position: absolute;
                right: 31px;
                top: 26px;
                .close_icon{
                    background-image: url("https://assets.codingdojo.com/learn_platform/global/close_icon_new.png");
                    display: inline-block;
                    height: 33px;
                    width: 33px;
                }
            }
        }
        .modal-body{
            font-family: 'Poppins', sans-serif;
            padding: 0 20px 20px;

            #no_files_yet{
                color: #152C61;
                text-align: center;
            }

            span.download_loader{
                background-image: url(https://assets.codingdojo.com/learn_platform/global/loading.svg);
                background-size: cover;
                display: inline-block;
                height: 36px;
                vertical-align: middle;
                width: 36px;
            }

            form{
                align-items: center;
                color: #2C6BFF;
                display: flex;
                justify-content: flex-end;
                padding: 0px 99px 27px;
                .upload_icon, .upload_resume_icon{
                   background-image: url("https://assets.codingdojo.com/learn_platform/global/my_files_sprite.png");
                   display: inline-block;
                   height: 17px;
                   margin-right: 10px;
                   width: 21px;
                }
                .upload_resume_icon{
                    background-position: -29px 0px;
                    width: 15px;
                }
                label{
                    align-items: center;
                    cursor: pointer;
                    display: flex;
                    &:first-child{
                        margin-right: 42px;
                    }
                }
                img{
                    height: 25px;
                    width: 25px;
                }
            }
            #file_tabbings{
                align-items: center;
                display: flex;
                justify-content: flex-start;
                list-style: none;
                margin: 0 0 24px 0;
                padding: 0;

                li{
                    cursor: pointer;
                    margin-right: 12px;

                    >span{
                        align-items: center;
                        background: url("https://assets.codingdojo.com/learn_platform/user/dashboard/tabbing_my_files_icon.svg") no-repeat 4px 4px;
                        display: flex;
                        height: 25px;
                        justify-content: center;
                        margin: 0;
                        width: 25px;
                    }

                    &.card_view{
                        >span{
                            background-position: 4px 4px;
                        }

                        &.active{
                            >span{
                                background-position: -49px 4px;
                            }
                        }
                    }

                    &.list_view{
                        >span{
                            background-position: -22px 4px;
                        }

                        &.active{
                            >span{
                                background-position: -75px 4px;
                            }
                        }
                    }
                }
            }

            #file_view_card_list{
                align-items: center;
                display: flex;
                flex-wrap: wrap;
                justify-content: flex-start;
                margin-bottom: 0px;
                max-height: 600px;
                max-width: 745px;
                overflow-x: hidden;
                overflow-y: auto;
                padding: 0;

                li{
                    align-items: center;
                    border: 2px solid #DCE6FF;
                    border-radius: 6px;
                    box-sizing: border-box;
                    display: inline-flex;
                    height: 200px;
                    justify-content: center;
                    list-style: none;
                    margin: 0 20px 20px 0;
                    padding: 30px 35px;
                    width: 170px;

                    &:nth-child(3n) {
                        margin-right: 0px;
                    }

                    button{
                        background-color: transparent;
                        border: none;
                        outline: none;
                        text-align: center;
                        &:hover{
                            opacity: .8;
                        }
                    }

                    .file_icon{
                        background-image: url("https://assets.codingdojo.com/learn_platform/global/modals/my_files_icons.png");
                        background-position: 60px 0px;
                        display: flex;
                        height: 60px;
                        margin: 0px auto 15px auto;
                        width: 60px;
                    }

                    .date_created{
                        font: 400 12px/16px "Poppins", sans-serif;
                    }
                   
                    .file-lock{
                        -webkit-box-orient: vertical;
                        color: #152C61;
                        display: -webkit-box;
                        font: 500 14px/18px "Poppins", sans-serif;
                        height: 36px;
                        -webkit-line-clamp: 2;
                        margin-bottom: 10px;
                        min-width: 120px;
                        overflow: hidden;
                        padding: 0px 20px;
                        position: relative;
                        text-overflow: ellipsis;
                        word-break: break-word;

                        &:before{
                            background-image: url("https://assets.codingdojo.com/learn_platform/global/modals/my_files_icons.png");
                            background-position: 0px 0px;
                            content: "";
                            display: flex;
                            height: 24px;
                            left: -3px;
                            position: absolute;
                            top: -4px;
                            width: 24px;
                        }
                    }

                    .description{
                        -webkit-box-orient: vertical;
                        color: #152C61;
                        display: -webkit-box;
                        font: 500 14px/18px "Poppins", sans-serif;
                        height: 36px;
                        -webkit-line-clamp: 2;
                        margin-bottom: 10px;
                        min-width: 120px;
                        overflow: hidden;
                        padding: 0px 20px;
                        position: relative;
                        text-overflow: ellipsis;
                        word-break: break-word;
                    }
                    span{
                        color: #BDBDBD;     
                        font-size: 14px;
                    }
                }
            }

            #my_files_list_view_wrapper{
                max-height: 600px;
                overflow: auto;

                table{
                    width: 100%;

                    thead{
                        position: sticky;
                        top: 0;
                        z-index: 5;

                        tr{
                            background: #EAF0FF;
                            border-radius: 4px;

                            th{
                                height: 45px;
                                padding: 0 20px;
                                button{
                                    background: transparent;
                                    border: none;
                                    color: #81A0EA;
                                    font: 500 14px/18px $Poppins;
                                    outline: none;
                                    svg{
                                        margin-left: 5px;
                                    }
                                }
                            }
                        }
                    }

                    tbody{
                        .file_name{
                            display: block;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            width: 125px;
                        }

                        tr{
                            &:nth-child(even){
                                background: #F6F6F6;
                            }
                        }

                        td{
                            color: #666666;
                            font: 400 14px/18px $Poppins;
                            height: 45px;
                            padding: 0 20px;

                            &.disabled_td{
                                pointer-events: none;
                            }

                            span.download_loader{
                                height: 15px;
                                margin-right: 5px;
                                width: 15px;
                            }

                            &:first-child{
                                a{
                                    text-decoration: none;
                                }

                                .description, .file-lock{
                                    color: #666666;
                                    cursor: pointer;
                                    display: block;
                                    overflow: hidden;
                                    position: relative;
                                    text-overflow: ellipsis;
                                    white-space: nowrap;
                                    width: 150px;

                                    &.file-lock{
                                        padding-left: 30px;

                                        &:before{
                                            background-image: url("https://assets.codingdojo.com/learn_platform/global/modals/my_files_icons.png");
                                            background-position: 0px 0px;
                                            content: "";
                                            display: flex;
                                            height: 24px;
                                            left: -3px;
                                            position: absolute;
                                            top: -4px;
                                            width: 24px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }        
    }        
}

#my_files_info_tooltip, .file_name_tooltip{
    opacity: 1;
    z-index: 9999 !important;

    .arrow{
        &:before{
            border-bottom-color: #EEEEEE !important;
            border-top-color: #EEEEEE !important;
        }
    }
    &.bs-tooltip-auto[x-placement^=top] .arrow::before, &.bs-tooltip-top .arrow::before{
        border-top-color: #EEEEEE !important;
    }
    .tooltip-inner{
        background-color: #EEEEEE !important;
        color: #152C61 !important;
        font: normal 400 16px/24px "Poppins", sans-serif !important;
        max-width: 524px !important;
        padding: 32px !important;
        text-align: justify !important;
    }
}

#confirm_download_file{
    max-width: 380px;
    width: 380px;
    .modal-content{
        border: none;
        .modal-header{
            border: none;
            padding-bottom: 30px;
            position: relative;
            button{
                background-color: transparent;
                border: none;
                outline: none;
                padding: 0px;
                position: absolute;
                right: 8px;
                top: 8px;
                .close_icon{
                    background-image: url("https://assets.codingdojo.com/learn_platform/global/close_icon_new.png");
                    display: inline-block;
                    height: 33px;
                    width: 33px;
                }
            }
        }
        .modal-body{
            p{
                font-family: "Poppins", sans-serif;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
            }

            button{
                align-items: center;
                background: #2C6BFF;
                border: none;
                border-radius: 6px;
                color: #FFFFFF;
                display: flex;
                font-family: "Poppins", sans-serif;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                height: 45px;
                justify-content: center;
                line-height: 27px;
                margin: 0px auto 6px auto;
                outline: none;
                text-align: center;
                text-decoration: none;
                width: 145px;
            }
        }        
    }  
}

body.is_dark_mode{
    #my_files_info_tooltip, .file_name_tooltip{
        .tooltip-inner{
            background-color: #080808 !important;
            color: #fff !important;
        }
    }

    #my_files_modal{
        .modal-content{
            .modal-header{
                .modal-title{
                    span.info_icon{
                        background-position: -24px 60px;
                    }
                }
            }
            .modal-body{
                #no_files_yet{
                    color: rgba(255, 255, 255, 0.88);
                    text-align: center;
                }
                #file_tabbings{
                    .set_file_view_btn{
                        background: transparent;
                    }
                }
                #my_files_list_view_wrapper{
                    table{
                        thead{
                            tr{
                                th{
                                    background: linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), #121212;
                                    color: rgba(255, 255, 255, 0.88);
                                }
                            }
                        }
                        tbody{
                            tr{
                                &:nth-child(even){
                                    background: linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), #121212;
                                }

                                td{
                                    color: rgba(255, 255, 255, 0.88);

                                    a{
                                        .description{
                                            color: #81A0EA !important;
                                        }
                                    }

                                    div{
                                        span{
                                            color: #81A0EA !important;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                #file_view_card_list{
                    li{
                        span.file_icon{
                            background-position: 60px 60px;
                        }

                        .description, .file-lock{
                            color: rgba(255, 255, 255, 0.88);
                        }
                    }
                }
            }
        }
    }
    #confirm_download_file{
        .modal-content{
            .modal-header{
                button{
                    .close_icon{
                        background-position: -61px 0px;
                    }
                }
            }
            .modal-body{
                p{
                    color: #fff;
                }
                a{
                    background-color: #81A0EA;
                }
            }
        }
    }
}

@media only screen and (max-width: 768px){
    #my_files_modal{
        .modal-content{
            .modal-header{
                .modal-title{
                    font-size: 16px;
                }
            }
            .modal-body{
                #file_view_card_list{
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;
                    padding: 20px;
                    width: 100%;

                    li{
                        height: 164px;
                        margin: 0 20px 20px 0;
                        padding: 10px;
                        width: 120px;

                        .description{
                            min-width: 96px;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 625px){
    #my_files_modal{
        .modal-content{
            .modal-header{
                .modal-title{
                    font-size: 16px;
                }
            }
            .modal-body{
                #file_view_card_list {
                    li{
                        &:nth-child(3n){
                            margin-right: 20px;
                        }
                    }
                }
            }
        }
    }
}