#daily_tip_modal{
    font-family: 'Poppins', sans-serif;
    max-width: 791px;
    width: 791px;
    .modal-content{
        background: #FFFFFF;
        border-radius: 6px;
        box-shadow: 0px 4px 2px rgba(0, 0, 0, 0.05);
        .modal-header{
            border: none;
            justify-content: center;
            padding: 59px 40px 19px 40px;
            position: relative;
            h4{
                color: #152C61;
                font: normal 500 36px/54px 'Poppins', sans-serif;
                margin-bottom: 0px;
            }
            button{
                background-color: transparent;
                border: none;
                outline: none;
                padding: 0px;
                position: absolute;
                right: 26px;
                top: 26px;
                .close_icon{
                    background-image: url("https://assets.codingdojo.com/learn_platform/global/close_icon_new.png");
                    display: inline-block;
                    height: 33px;
                    width: 33px;
                }
            }
        }
        .modal-body{
            padding: 20px 80px 42px;
            text-align: center;
            form{
                margin-bottom: 44px;
                input{
                    border-radius: 25px;
                    border: 2px solid #E6E6E6;
                    box-shadow: none;
                    font-size: 14px;
                    outline: none;
                    padding: 10px;
                    text-align: center;
                    width: 100%;
                }
            }
            ul{
                margin: 0px 0px 31px;
                li{
                    margin-bottom: 40px;
                    position: relative;
                    text-align: left;
                    &:last-child{
                        margin-bottom: 0px;
                    }
                    &.no_result{
                        text-align: center;
                    }
                    span{
                        font-size: 14px;
                        position: absolute;
                        right: 0;
                        top: 0;
                    }
                    h6{
                        margin-bottom: 20px;
                    }
                }
            }
            button{
                background: transparent;
                border: none;
                color: #2C6BFF;
                font-weight: 600;
                outline: none;
            }
        }
    }
    @media only screen and (max-width: 768px){
        .modal-content{
            box-shadow: none;
            .modal-header{
                h4{
                    font-size: 28px;
                }
            }
            .modal-body{
                padding: 20px 20px 42px;
            }
            #coming_soon_container{
                img{
                    width: 100%;
                }
            }
        }
    }
}