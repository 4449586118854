#quiz_module_container{
    margin-top: 22px;
    width: 100%;

    .visibility_hidden{
        visibility: hidden;
    }

    >p{
        color: #152C61;
        font: normal 300 16px/24px 'Poppins', sans-serif;
    }

    #quiz_introduction{
        >p{
            color: #152C61;
            font: normal 400 16px/24px 'Poppins', sans-serif;
            margin: 50px 76px;
        }
    }

    #quiz_description{
        margin: 0px auto 20px auto;
        width: 78%;
    
        *{
            color: #152C61;
            font-family: 'Poppins', sans-serif;
            text-shadow: none;
        }
    
        iframe{
            border: none !important;
            height: 410px !important;
            margin-bottom: 20px;
            width: 100% !important;
        }
        p, li{
            color: #152C61;
            font-family: 'Poppins', sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 300;
            line-height: 200% !important;
        }
    
        li{
            margin-bottom: 4px;
        }
    
        a{
            color: #2C6BFF;
            cursor: pointer;
            font-family: 'Poppins', sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 200%;
            text-decoration-line: underline;
        }
    
        img{
            max-width: 100%;
        }
    
        h1, h2, h3{
            color: #152C61;
            margin-bottom: 20px;
        }
        h1{
            font-size: 32px;
            font-weight: 500;
            line-height: 48px;
        }
        h2{
            font-size: 26px;
            font-weight: 500;
            line-height: 39px;
        }
        h3, h4{
            font-size: 22px;
            font-weight: 500;
            line-height: 33px;
        }
        h5, h6{
            font-size: 18px;
            font-weight: 500;
            line-height: 24px;
        }
    }

    .code_editor_container{
        .dc_iframe{
            background: #fff;
            width: 100%;
            
            iframe{
                width: 100% !important;
            }
        }

        .editor{
            background: #F8FAFF;
            counter-reset: line;
            pointer-events: none;
            width: 100%;

            textarea {
                outline: none;
                padding: 21px 0px 20px 80px !important;
            }
              
            pre {
                padding: 21px 0px 20px 80px !important;
                position: relative;

                &:before{
                    background: #DCE6FF;
                    content: "";
                    height: 21px;
                    left: 0;
                    position: absolute;
                    top: 0;
                    width: 60px;
                }

                &:after{
                    background: #DCE6FF;
                    bottom: 0;
                    content: "";
                    height: 21px;
                    left: 0;
                    position: absolute;
                    width: 60px;
                }
            }
              
            .editorLineNumber {
                background: #DCE6FF;
                color: #BDBDBD;
                font-family: 'Poppins', sans-serif;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                left: 0px;
                line-height: 24px;
                padding: 0px 20px;
                position: absolute;
                text-align: left;
                width: 60px;
            }
        }
    }

    #quiz_loading_icon{
        background-image: url("https://assets.codingdojo.com/learn_platform/global/loading.svg");
        background-size: contain;
        display: flex;
        height: 80px;
        margin: 0px auto;
        width: 80px;
    }
    
    button{
        &.btn-primary{
            align-items: center;
            background: #505170;
            border-color: #505170;
            border-radius: 6px;
            color: #FFFFFF;
            display: flex;
            font-family: 'Poppins', sans-serif;
            font-size: 18px;
            font-style: normal;
            font-weight: normal;
            height: 46px;
            justify-content: center;
            line-height: 27px;
            margin: 63px auto 0px;
            text-align: center;
            width: 158px;
        }
    }
}
.is_dark_mode{
    #quiz_module_container{
        #quiz_loading_icon{
            background-image: url("https://assets.codingdojo.com/learn_platform/global/loader.gif");
        }

        #quiz_introduction{
            p{
                color: #fff;
            }
        }
    }
}