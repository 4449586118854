$cd_blue: #2C6BFF;
#exam_header{
    .navbar-brand{
        .belt_exam{
            font-weight: bold;
            font-size: 28px;
            color: $cd_blue;
        }
    }
    .navbar-nav{
        #back_to_learn_btn{
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: flex-end;
            opacity: 1 !important;
            color: $cd_blue;
        }
    }
    a{
        text-decoration: none !important;
    }
} 
#taking_exam_container{
    color: #152C61;
    font-family: 'Poppins', sans-serif;
    padding-top: 35px;

    a{
        text-decoration: none !important;
    }
    .blk{
        text-align: center;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
        border-radius: 6px;
        margin-bottom: 10px;
        padding: 34px 40px 40px;
        background-color: #fff;

        .top{
            margin-bottom: 66px;
            h6{
                font-weight: 500;
                font-size: 20px;
                margin-bottom: 9px;
                svg{
                    margin-left: 23px;
                    color: $cd_blue;
                    font-size: 14px;
                }
            }
            a{
                font-size: 16px;
                color: #152C61;
                font-weight: 500;
                &.link{
                    margin-left: 35px;
                    color: $cd_blue;
                }
            }
        }
        ul{
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 0px;
            li{
                font-weight: 500;
                margin-right: 40px;
                &.timer{
                    width: 332px;
                }
                span{
                    margin:0px 2px;
                    small{
                        font-family: 'Poppins', sans-serif;
                        font-size: 16px;
                        font-weight: normal;
                        width: 20px;
                        text-align: right;
                        display: inline-block;
                    }
                }
                &.exam_end_item{
                    h4, h5{
                        color: $cd_blue;
                    }
                }
                &:last-child{
                    margin-right: 0px;
                }
                h6{
                    font-size: 16px;
                    margin-bottom: 14px;
                }
                h4{
                    font-weight: bold;
                    font-size: 46px;
                    margin-bottom: 19px;
                }
                h5{
                    font-weight: bold;
                    font-size: 26px;
                    margin-bottom: 0px;
                }
            }
        }
    }
    form{
        display: flex;
        justify-content: center;
        div{
            h6{
                font-weight: 500;
                font-size: 17px;
                margin-bottom: 40px;
            }
            p{
                font-size: 14px;
                margin-bottom: 20px;
            }
            #video_url_input{
                border-radius: 6px;
                border: 2px solid #C1C1C1;
                padding: 9px 20px;
                margin-right: 72px;
            }
            button{
                background: transparent;
                border: transparent;
                color: #2C6BFF;
                font-size: 16px;
                font-size: 18px;
            }
            .invalid{
                color:#E1554C;
            }
            &.left_container{
                margin-right: 170px;
                label{
                    border-radius: 6px;
                    background: $cd_blue;
                    color: #fff;
                    padding: 11px 36px;
                    margin-bottom: 0px;
                    &.invalid{
                        border-color:#E1554C;
                    }
                }
                small{
                    display: block;
                    margin-top: 16px;
                }
            }
        }
    }
}