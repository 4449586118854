#to_do_popover{
    -moz-filter: drop-shadow(0px 2px 10px rgba(0, 0, 0, 0.1));
    -webkit-filter: drop-shadow(0px 2px 10px rgba(0, 0, 0, 0.1));
    border: none;
    filter: drop-shadow(0px 2px 10px rgba(0, 0, 0, 0.1));
    max-width: 380px;
    top: 8px !important;
    width: 380px;

    .arrow::before{
        border-bottom-color: transparent;
    }

    h2{
        color: #152C61;
        font-family: "Poppins", sans-serif;
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 9px;
    }

    p{
        margin-bottom: 0;
    }

    #popover_content{
        padding: 42px 32px 13px;

        ul{
            list-style: none;
            padding: 0;
            user-select: none;

            label{
                border-top: 1px solid #DEE5EF;
                padding: 16px 0px 3px 32px;
                position: relative;
                width: 100%;

                input{
                    display: none;
                }

                &::before{
                    border-radius: 2px;
                    border: 1px solid #152C61;
                    content: "";
                    height: 16px;
                    left: 0;
                    position: absolute;
                    top: 16px;
                    width: 16px;
                }

                &::after{
                    background: rgba(44, 107, 255, 1) url("https://assets.codingdojo.com/learn_platform/user/dashboard/dashboard_redesign_icons.png") no-repeat -528px -15px;
                    border-radius: 2px;
                    border: none;
                    content: "";
                    display: none;
                    height: 16px;
                    left: 0;
                    position: absolute;
                    top: 16px;
                    width: 16px;
                }

                #to_do_type{
                    color: rgba(21, 44, 97, .6);
                    font-family: "Poppins", sans-serif;
                    font-size: 10px;
                    margin-bottom: 4px;
                }

                #to_do_title{
                    color: rgba(21, 44, 97, 1);
                    font-family: "Poppins", sans-serif;
                    font-size: 12px;
                    font-weight: 600;
                    margin-bottom: 4px;
                }

                #to_do_due_date{
                    color: rgba(21, 44, 97, .6);
                    font-family: "Poppins", sans-serif;
                    font-size: 10px;

                    strong{
                        color: rgba(44, 107, 255, 1);
                        font-weight: 600;
                        &.overdue{
                            color: rgba(204, 57, 46, 1);
                        }
                    }
                }

                &.done{
                    #to_do_title{
                        color: rgba(151, 151, 151, 1);
                        text-decoration: line-through;
                    }

                    #to_do_due_date{
                        color: rgba(163, 163, 163, 1);
                        font-weight: 600;
                    }

                    &::after{
                        display: block;
                    }
                }
            }
        }
    }
}

.is_dark_mode{
    #to_do_popover{
        background-color: #333;

        .arrow::after{
            border-bottom-color: #333;
        }

        h2{
            color: #E5E5E5;
        }

        #popover_content{
            ul{
                label{
                    border-top-color: #666666;
                    #to_do_type, #to_do_title{
                        color: #E5E5E5;
                    }
                    #to_do_due_date{
                        color: #B2B2B2;
                        strong{
                            color: #81A0EA;
                            &.overdue{
                                color: #F56B6B;
                            }
                        }
                    }
                    &::before{
                        border-color: #81A0EA;
                    }
                    &::after{
                        background-color: #81A0EA;
                    }
                }
            }
        }
    }
}