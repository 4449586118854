$Poppins: 'Poppins', sans-serif;

$white: #fff;

#student_profile_account_modal{
    margin: 50px auto;
    max-width: none;
    width: 869px;

    .modal-content{
        background: #333333;
        border-radius: 0px;
        box-shadow: 0px 4px 2px rgba(0, 0, 0, 0.05);
        color: rgba(255, 255, 255, 0.88);
        font-family: $Poppins;
        position: relative;

        .backdrop{
            background: rgba(0, 0, 0, 0.6);
            display: none;
            height: 100%;
            position: absolute;
            right: 0px;
            top: 0px;
            width: 100%;
        }

        .has_table{
            background-color: #484B50;
            border-radius: 2px;
            padding: 20px;
        }

        .student_profile_table{
            width: 100%;

            thead{
                tr{
                    background: #484B50;
                    position: sticky;
                    top: 0;

                    th{
                        color: #B2B2B2;
                        font-weight: 400;
                        font: 12px/16px $Poppins;
                        padding: 10px 10px 6px;
                    }
                }
            }

            tbody{
                tr{
                    height: 34px;

                    &:nth-child(odd){
                        background-color: #5A6069;
                        border-radius: 2px;
                    }

                    td{
                        color: #FFFFFF;
                        font: 12px/16px $Poppins;
                        padding: 10px;

                        &.no_results_found{
                            background: #484b50;
                            padding-top: 20px;
                            text-align: center;
                        }

                        .action_icon{
                            background: url("https://assets.codingdojo.com/learn_platform/admin/student_access/assignment_icon.svg") no-repeat;
                            border: none;
                            display: inline-block;
                            height: 16px;
                            margin-right: 16px;
                            outline: none;
                            text-decoration: none;
                            width: 16px;

                            &.copy_link{
                                background-position: -38px 0px;
                            }

                            &.download_link{
                                background-position: -19px 0px;
                            }

                            &.give_feedback{
                                margin-right: 0;
                            }
                        }
                    }
                }
            }
        }

        .modal-header{
            align-items: flex-start;
            border-radius: 0px;
            border: none;
            display: flex;
            justify-content: left;
            padding: 18px 23px 0;
            position: relative;

            &.active_dropdown{
                position: relative;

                .backdrop{
                    display: block;
                }

                .student_profile_header_details{
                    .header_action_btn{
                        button{
                            &:nth-child(2){
                                border: 1px solid #81a0ea;
                                padding: 4px 6px;
                                position: relative;
                                z-index: 2;
                            }
                        }

                        ul{
                            display: block;
                            z-index: 2;
                        }
                    }
                }
            }
    
            .student_profile_header_details{
                align-items: flex-start;
                display: flex;
                justify-content: flex-start;

                img{
                    border-radius: 50%;
                    height: 36px;
                    width: 36px;
                }

                .student_profile_details_header_block{
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    margin-left: 13px;
                    div{
                        display: flex;
                        margin-bottom: 3px;

                        h2{
                            align-items: center;
                            color: rgba(255, 255, 255, 0.88);
                            font-weight: 600;
                            font: 14px/18px $Poppins;
                            margin: 0;
                            max-width: 520px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        }
                        span.student_status{
                            align-items: center;
                            background: rgba(84, 204, 176, 0.4);
                            border-radius: 4px;
                            color: #05DA80;
                            display: flex;
                            font-weight: 600;
                            font: 12px/16px $Poppins;
                            height: 20px;
                            justify-content: center;
                            margin-left: 10px;
                            padding: 0 6px;
                        }
                    }

                    >span{
                        color: rgba(255, 255, 255, 0.58);
                        font: 12px/16px $Poppins;
                        max-width: 520px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                }

                .header_action_btn{
                    margin-left: 55px;
                    position: relative;

                    .view_lp3, .action_ellipsis_btn{
                        align-items: center;
                        background: rgba(204, 224, 255, 0.14);
                        border-radius: 2px;
                        border: none;
                        color: #81A0EA;
                        display: inline-flex;
                        font-size: 12px;
                        font-weight: 500;
                        height: 28px;
                        outline: none;
                        padding: 4px 8px;
                        text-decoration: none;
                        
                        &.view_lp3{
                            margin-right: 10px;

                            svg{
                                margin-right: 7px;
                            }
                        }
                    }

                    ul{
                        background: linear-gradient( 0deg, rgba(255, 255, 255, 0.24), rgba(255, 255, 255, 0.24)), #121212;
                        border: 1px solid #81A0EA;
                        color: #fff;
                        display: none;
                        padding: 20px 16px;
                        position: absolute;
                        right: 0px;
                        top: 37px;
                        width: max-content;
                        z-index: 2;

                        li{
                            list-style: none;
                            margin-bottom: 20px;

                            &:last-child{
                                margin-bottom: 0px;
                            }

                            #disabled_generate_transcript_tooltip, #disabled_download_transcript_tooltip{
                                opacity: 1;
                                .tooltip-inner{
                                    background-color: #333;
                                    position: relative;

                                    &::before{
                                        border-left: 7px solid transparent;
                                        border-right: 7px solid transparent;
                                        border-top: 7px solid #333;
                                        bottom: -7px;
                                        content: "";
                                        height: 0;
                                        left: calc(50% - 7px);
                                        position: absolute;
                                        width: 0; 
                                    }
                                }
                            }

                            a{
                                color: rgba(255, 255, 255, 0.88);
                                font-weight: 400;
                                font: 12px/16px $Poppins;
                                text-decoration: none;

                                &.disabled_link{
                                    opacity: 0.6; 
                                    pointer-events: none;
                                }
                                &:hover{
                                    opacity: .8;
                                }
                                span{
                                    background-image: url("https://assets.codingdojo.com/learn_platform/admin/student_access/student_profile_icons.png");
                                    display: inline-block;
                                    height: 16px;
                                    margin-right: 8px;
                                    vertical-align: middle;
                                    width: 16px;

                                    &.eye{
                                        background-position: -126px 0px;
                                    }
                                    &.enroll_app{
                                        background-position: -152px 0px;
                                    }
                                    &.transcript{
                                        background-position: -178px 0px;
                                    }
                                    &.progress_report{
                                        background-position: -204px 0px;
                                    }
                                    &.warning{
                                        background-position: -229px 0px;
                                    }
                                    &.download_transcript{
                                        background-image: url("https://assets.codingdojo.com/learn_platform/admin/student_access/assignment_icon.svg");
                                        background-position: -19px 0px;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            >button{
                background-color: transparent;
                background-image: url("https://codingdojo_assets.s3.us-east-1.amazonaws.com/learn_platform/admin/edit_course/admin_edit_course_sprite.png");
                background-position: -65px 0px;
                border: none;
                height: 15px;
                outline: none;
                padding: 0px;
                position: absolute;
                right: 22px;
                top: 20px;
                width: 15px;
            }
        }
        .modal-body{
            padding: 24px 24px 20px;

            &.active_dropdown{
                position: relative;

                .backdrop{
                    display: block;
                }
            }

            .student_details_wrapper{
                .belt_exam_list{
                    align-items: stretch;
                    border-bottom: 1px solid rgba(204, 224, 255, 0.14);
                    display: flex;
                    justify-content: flex-start;
                    list-style: none;
                    margin: 0;
                    padding: 0 0 19px 0;

                    >li{
                        align-items: flex-start;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        margin-right: 20px;
                        width: 90px;

                        &:last-child{
                            margin-right: 0;
                        }

                        .belt_icon{
                            display: block;
                            height: 46px;
                            margin: 0 auto 0px auto;
                            width: 62px;
                        }

                        .belt_language_name, .belt_score{
                            color: rgba(255, 255, 255, 0.58);
                            font-weight: 400;
                            font: 12px/16px $Poppins;
                            margin: 0 auto;
                            text-align: center;

                            &.belt_language_name{
                                margin-bottom: 5px;
                            }

                            &.belt_score{
                                margin: auto auto 0 auto;
                            }
                        }
                    }
                }
            }

            #location_region_wrapper{
                border-bottom: 1px solid rgba(204, 224, 255, 0.14);
                padding: 16px 0 11px;

                #location_region_list{
                    align-items: flex-start;
                    display: flex;
                    justify-content: flex-start;
                    list-style: none;
                    margin: 0 0 12px 0;
                    padding: 0;
    
                    li{
                        margin-right: 60px;
                        max-width: 188px;

                        &:last-child{
                            margin-right: 0px;
                        }

                        h6{
                            align-items: baseline;
                            color: rgba(255, 255, 255, 0.58);
                            display: flex;
                            font-size: 12px;
                            font-weight: 400;
                            margin-bottom: 5px;
    
                            span{
                                background-image: url("https://assets.codingdojo.com/learn_platform/admin/student_access/admin_student_access_icon.svg");
                                display: inline-block;
                                height: 16px;
                                margin-right: 4px;
                                vertical-align: top;
                                width: 16px;
    
                                &.program{
                                    background-position: -195px 1px;
                                }
    
                                &.location{
                                    background-position: -265px 1px;
                                }
    
                                &.region{
                                    background-position: -220px 1px;
                                }

                                &.core_acr{
                                    background-position: -285px 1px;
                                }
    
                                &.attendace_rate{
                                    background-position: -305px 1px;
                                }
                            }
                        }

                        h5{
                            font-size: 14px;
                            font-weight: 500;
                            padding-left: 20px;

                            &.cd_green_text{
                                color: #05DA80;
                            }

                            &.cd_yellow_text{
                                color: #FFC656;
                            }
                        }
                    }
                }

                .user_detail_block{
                    width: 225px;

                    .access_locked{
                        color: #F56B6B !important;
                        font-weight: 400 !important;
                    }

                    h6{
                        align-items: baseline;
                        color: rgba(255, 255, 255, 0.58);
                        display: flex;
                        font-size: 12px;
                        font-weight: 400;
                        margin-bottom: 5px;

                        span{
                            background-image: url("https://assets.codingdojo.com/learn_platform/admin/student_access/admin_student_access_icon.svg");
                            background-position: -242px 1px;
                            display: inline-block;
                            height: 16px;
                            margin-right: 4px;
                            vertical-align: top;
                            width: 16px;
                        }
                    }
                    .access_info_container{
                        align-items: center;
                        display: flex;
                        >label{
                            align-items: center;
                            cursor: pointer;
                            display: flex;
                            justify-content: flex-start;
                            margin-bottom: 0px;
        
                            input[type="checkbox"]{
                                display: none;
                            }
        
                            .is_valid_checkbox_style{
                                background: rgba(204, 224, 255, 0.14);
                                border-radius: 12px;
                                height: 12px;
                                margin-right: 10px;
                                position: relative;
                                transition: .25s ease-in-out;
                                width: 24px;
        
                                &::after{
                                    background: #B2B2B2;
                                    border-radius: 50%;
                                    content: "";
                                    height: 10px;
                                    margin-left: 1px;
                                    position: absolute;
                                    top: 1px;
                                    transition: .25s ease-in-out;
                                    width: 10px;
                                }
                            }
        
                            input[type="checkbox"]:checked ~ .is_valid_checkbox_style{
                                background: #28352E;
                                transition: .25s ease-in-out;
        
                                &::after{
                                    background: #27AE60;
                                    margin-left: 13px;
                                    transition: .25s ease-in-out;
                                }
                            }
        
                            span{
                                &:nth-child(3), &:nth-child(4){
                                    color: rgba(255, 255, 255, 0.88);
                                    font: 14px/18px $Poppins;
                                }

                                &.access_locked{
                                    color: #F56B6B;
                                    font-weight: 400;
                                }

                                &.valid_till_label{
                                    width: 57px;
                                }
                            }
                        }

                        .student_access_date_picker{
                            .react-datepicker-wrapper{
                                align-items: center;
                                display: flex;
                                height: 18px;
                                .react-datepicker__input-container{
                                    align-items: center;
                                    display: flex;
                                    height: 18px;
                                    input{
                                        background-color: transparent;
                                        border: none;
                                        caret-color: transparent;
                                        color: #81A0EA;
                                        cursor: pointer;
                                        font-weight: 600 !important;
                                        font: 14px/18px $Poppins;
                                        margin-left: 5px;
                                        outline: none;
                                        padding: 0px;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            #student_profile_tabs{
                align-items: center;
                background: linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), #121212;
                border-radius: 2px;
                display: flex;
                height: 40px;
                justify-content: flex-start;
                list-style: none;
                margin: 20px 0 0 0;
                padding: 0 8px;

                li{
                    cursor: pointer;
                    margin-right: 10px;

                    &:last-child{
                        margin-right: 0;
                    }

                    span{
                        align-items: center;
                        border-radius: 2px;
                        color: #B3B3B3;
                        display: flex;
                        font: 12px/16px $Poppins;
                        height: 28px;
                        justify-content: center;
                        padding: 0 10px;
                    }
                    
                    &.active{
                        span{
                            background: rgba(204, 224, 255, 0.14);
                            color: #81A0EA
                        }
                    }
                }
            }

            #student_profile_component_render{
                margin-top: 20px;
                max-height: 380px;
                overflow: auto;
                position: relative;

                &.is_show_component{
                    margin-top: 10px;
                    max-height: unset;
                    overflow: unset;
                }
            }
        }
    }
}