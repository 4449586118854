$Poppins: "Poppins", sans-serif;

#dashboard_container.container{
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
    position: relative;

    .maintenance_banner{
        align-items: center;
        background: #FFFFFF;
        border-radius: 5px;
        border: 1px solid #F2994A;
        box-sizing: border-box;
        display: flex;
        margin: -8px 0px 20px 0px;
        padding: 17px 54px 15px 19px;
        position: relative;
        width: 100%;
        
        &.payment_overdue{
            border-color: #E1554C;
            justify-content: space-between;
            padding: 13px 10px 13px 54px;

            svg{
                color: #E1554C;
            }
            p{
                &::before{
                    background-image: url("https://assets.codingdojo.com/learn_platform/user/dashboard/dashboard_redesign_icons.png");
                    background-position: -331px -12px;
                    content: "";
                    left: 19px;
                    height: 21px;
                    margin-right: 12px;
                    position: absolute;
                    width: 21px;
                }
            }
        }

        &.hidden{
            display: none !important; 
        }

        .sprite_icon{
            background-image: url("https://assets.codingdojo.com/learn_platform/user/dashboard/dashboard_redesign_icons.png");
            height: 21px;
            margin-right: 12px;
            max-width: 21px;
            width: 100%;
        }
        .info_icon{
            background-position: -398px -12px;
        }
        .dollar_icon{
            background-position: -331px -12px;
        }
        button{
            background-color: transparent;
            border: none;
            font: 400 16px "Poppins", sans-serif;
            outline: none;
            padding: 0px;
            &.close_button{
                background-image: url("https://assets.codingdojo.com/learn_platform/user/dashboard/dashboard_redesign_icons.png");
                background-position: -431px -12px;
                bottom: 0;
                display: inline-block;
                height: 21px;
                margin: auto 0;
                position: absolute;
                right: 26px;
                top: 0;
                width: 22px;

            }
            &.red_button{
                background: #E1554C;
                border-radius: 6px;
                color: #fff;
                padding: 4px 14px;
                font: normal 500 16px/24px $Poppins;    
            }
        }

        form{
            display: flex;
            .payment_overdue_select{
                background: transparent;
                width: unset;
                svg{
                    margin-right: 0px;
                }
                .react-dropdown-select-item-selected{
                    background: transparent;
                    color: #152C61;
                    font-weight: 700;
                }
                .react-dropdown-select-dropdown{
                    background: #FFFFFF;
                    border-radius: 4px;
                    border: 1px solid #DCE6FF;
                    box-shadow: 0px 10px 8px rgba(0, 0, 0, 0.05);
                    color: #152C61;
                    left: -47px;
                    margin-top: 3px;
                    min-width: 128px;
                    padding: 10px 0px;
                    .react-dropdown-select-item{
                        font-family: "Poppins", sans-serif;
                        font-size: 12px;
                        padding: 9px 13px;
                    }
                }
                .dropdown_icon{
                    background: url("https://assets.codingdojo.com/learn_platform/user/dashboard/dashboard_redesign_icons.png") -362px -13px;
                    display: inline-block;
                    height: 17px;
                    margin-right: 2px;
                    margin-top: 4px;
                    width: 21px;
                }
                .react-dropdown-select-content{
                    color: #E1554C;
                    font-size: 16px;
                    margin-right: 4px;
                    .react-dropdown-select-input{
                        display: none;
                    }
                }
            }
        }
        
        p{
            color: #152C61;
            font-family: "Poppins", sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: normal;
            line-height: 24px;
            margin-bottom: 0px;
            
            b{
                &.orange_text{
                    color: #f2994a;
                }
                &.red_text{
                    color: #E1554C;
                }
            }
        }
    }

    #sub_navigation_skeleton{
        display: flex;
        height: 43px;
        list-style: none;
        margin: 44px 0px 43px 0px;
        padding: 0px;
        width: 100%;
        .skeleton_loading{
            width: 46px;
            &:first-child{
                border-radius: 6px;
                margin-right: auto;
                min-width: 257px;
            }
            &:not(:first-child){
                border-radius: 100%;
                margin-left: 20px;
            }
            &:last-child{
                border-radius: 26px;
                margin-left: 40px;
                width: 117px;
            }
        }
    }

    #ssn_banner{
        background: #FFFFFF;
        border-radius: 5px;
        border: 1px solid #F2994A;
        box-sizing: border-box;
        display: flex;
        margin: 0px 0px 20px 0px;
        padding: 17px 19px 15px 19px;
        width: 100%;
        svg{
            color: #F2994A;
            font-size: 20px;
            margin-right: 20px; 
        }
        p{
            color: #152C61;
            font: normal normal 16px/24px $Poppins;
            margin-bottom: 0px;
            span{
                color: #F2994A;
                font-weight: 700;
            }
            a{
                color: #2C6BFF;
                text-decoration: underline;
            }
        }
    }

    #my_class_container{
        background-color: #FFFFFF;
        border-radius: 6px;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
        margin-bottom: 26px;
        padding: 34px 28px 0px;
        width: 522px;

        > header{
            align-items: center;
            display: flex;
            justify-content: space-between;
            margin-bottom: 33px;

            h5{
                color: rgba(21, 44, 97, 1);
                font-family: "Poppins", sans-serif;
                font-size: 24px;
                font-weight: 500;
                margin-bottom: 3px;
                cursor: unset;
                svg{
                    display: none;
                }
            }
            #week_dates{
                height: 37px;
                button{
                    background-color: #F7F7F7;;
                    border-radius: 6px;
                    border: none;
                    color: #979797;
                    font-family: "Poppins", sans-serif;
                    font-size: 12px;
                    height: 36px;
                    line-height: 13px;
                    margin-left: 8px;
                    outline: 5px transparent;
                    width: 42.61px;

                    &.current_day{
                        background-color: #2C6BFF;
                        border: none;
                        color: white;
                        font-weight: 700;
                    }

                    &.is_viewed{
                        background-color: white;
                        border: 2px solid #2C6BFF;
                        color: #2C6BFF;
                        font-weight: 700;
                    }

                }
                ul{
                    display: flex;
                    justify-content: space-between;
                    list-style: none;
                    min-width: 350px;
                    padding: 0;
                    li{
                        &.skeleton_loading{
                            border-radius: 6px;
                            height: 36px;
                            width: 43px !important;
                        }
                    }
                }
            }
        }

        h3{
            color: rgba(21, 44, 97, .4);
            font-family: "Poppins", sans-serif;
            font-size: 16px;
            font-weight: 500;

            &.skeleton_loading{
                border-radius: 4px;
                height: 22px;
                margin-bottom: 18px;
                max-width: 136px;
            }
        }

        div.belt_exam_banner{
            margin-top: 13px;
            padding: 5px 16px 4px 22px;
            h6{ 
                font-size: 18px;
            }
            #rating_star_ribbon{
                background: url("https://codingdojo_assets.s3.us-east-1.amazonaws.com/learn_platform/user/dashboard/dashboard_redesign_icons.png") no-repeat -565px -12px;
                display: inline-block !important;
	            height: 18px;
                visibility: visible;
                width: 14px;
            }
        }

        > ul{
            list-style: none;
            padding: 0;

            li{
                background-color: #FBFCFF;
                border-radius: 6px;
                border: 1px solid rgba(21, 44, 97, 0.1);
                padding: 19px 19px 3px 78px;
                position: relative;

                &::before{
                    background: rgba(194, 212, 255, 0.2) url("https://assets.codingdojo.com/learn_platform/user/dashboard/dashboard_redesign_icons.png") no-repeat -276px 3px;
                    border-radius: 100%;
                    content: "";
                    height: 49px;
                    left: 19px;
                    position: absolute;
                    top: 19px;
                    width: 49px;
                }

                &:not(:last-child){
                    margin-bottom: 20px;
                }

                .card_title, .lecture_subject{
                    color: #152C61;
                    font-family: "Poppins", sans-serif;
                    font-size: 14px;
                    font-weight: 500;
                }

                .card_title{
                    margin-bottom: 10px;
                }

                .lecture_subject{
                    margin-bottom: 10px;
                }

                .lecture_time{
                    color: #152C61;
                    font-family: "Poppins", sans-serif;
                    font-size: 12px;
                }

                .lecture_state{
                    background-color: #F7F7F7;
                    border-radius: 6px;
                    color: rgba(151, 151, 151, 1);
                    display: inline-block;
                    font-family: "Poppins", sans-serif;
                    font-size: 12px;
                    font-weight: 500;
                    padding: 3px 4px 3px 14px;
                    position: absolute;
                    right: 19px;
                    text-align: right;
                    top: 18px;

                    &::before{
                        background-color: #979797;
                        border-radius: 100%;
                        content: "";
                        height: 6px;
                        left: 5px;
                        position: absolute;
                        top: 10px;
                        width: 6px;
                    }

                    &.is_live{
                        background-color: #FCEEEE;
                        color: #E1554C;

                        &::before{
                            background-color: #E1554C;
                        }
                    }
                }

                a{
                    background: #2C6BFF;
                    border-radius: 6px;
                    bottom: 14px;
                    color: white;
                    font-family: "Poppins", sans-serif;
                    font-size: 14px;
                    font-weight: 500;
                    padding: 4px 11px 4px 32px;
                    position: absolute;
                    right: 19px;
                    text-decoration: none;

                    &::before{
                        background: url("https://assets.codingdojo.com/learn_platform/user/dashboard/dashboard_redesign_icons.png") no-repeat -251px -14px;
                        border-radius: 100%;
                        content: "";
                        height: 16px;
                        left: 10px;
                        position: absolute;
                        top: 6px;
                        width: 16px;
                    }

                    &.disabled_join_button{
                        opacity: .45;
                        pointer-events: none;
                    }

                    &.view_video_button{
                        background-color: white;
                        border: 2px solid #2C6BFF;
                        color: #2C6BFF;
                        padding: 2px 14px;

                        &::before{
                            display: none;
                        }
                    }
                }
                &.skeleton_loading{
                    border: none;
                    height: 120px;
                    &::before{
                        display: none;
                    }
                }
            }
        }
    }

    #my_stats_container{
        background: #FFFFFF;
        border-radius: 6px;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
        margin-bottom: 26px;
        padding: 31px 30px 30px 30px;
        position: relative;
        width: 522px;
        h5{
            margin-bottom: 33px;
            a{
                color: #152C61;
                font: 500 24px/36px $Poppins;
                text-decoration: none;
            }
        }
        #current_stats_label{
            display: flex;    
            flex-direction: column;

            > div{
                .selected_stats{
                    background: #fff;
                    border-radius: 5px;
                    border: 1px solid rgba(0, 0, 0, 0.2) !important;
                    min-width: 130px;
                    padding: 9px 10px !important;
                    position: absolute;
                    right: 30px;
                    top: 30px;
                    width: auto;

                    .dropdown_item{
                        &:hover{
                            opacity: .7;
                        }
                    }
                    &[aria-expanded="true"] {
                        background-color: #fff;
                        border-radius: 5px;
                        box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.2) !important;

                        .react-dropdown-select-dropdown-handle{
                            svg{
                                -ms-transform: rotate(180deg);
                                -webkit-transition: all .3s;
                                transform: rotate(180deg);
                                transition: all .3s;
                            }
                        }
                    }
    
                    .react-dropdown-select-content{
                        span{
                            color: #152C61;
                            display: inline-block;
                            font: 500 16px/21px $Poppins;
                            margin-right: 18px;
                            max-width: 200px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            width: 100%;
                        }
    
                        .react-dropdown-select-input{
                            display: none;
                        }
                    }
    
                    .react-dropdown-select-dropdown-handle{
                        svg{
                            -webkit-transition: all .3s;
                            color: #2C6BFF;
                            font-size: 16px;
                            height: auto;
                            transition: all .3s;
                        }
                    }
    
                    .react-dropdown-select-dropdown{
                        background: #fff;
                        border-radius: 5px;
                        border: none;
                        box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.2);
                        padding: 12px;
                        top: 49px;

                        .dropdown_search_container{
                            input{
                                color: #666666;
                                font: 14px/21px $Poppins;
                            }
                        }

                        div{
                            background-color: transparent;
                            border: none;
                            color: #666666;
                            cursor: pointer;
                            font: 500 14px/21px $Poppins;
                            letter-spacing: 2px;
                            margin-bottom: 0px;
                            outline: none;
                            padding: 10px;
                            text-transform: uppercase;
                            width: 100%;
                            word-break: break-word;
                        }
                    }
                }
            }

            &.skeleton_loading{
                border-radius: 6px;
                height: 40px;
                position: absolute;
                right: 30px;
                top: 30px;
                width: 151px;
                > *{
                    display: none;
                }
            }
        }
        #rates_container{
            display: flex;
            gap: 20px;
            margin-bottom: 16px;

            > div{
                align-items: center;
                border-radius: 6px;
                border: 1px solid rgba(21, 44, 97, 0.1);
                cursor: pointer;
                display: flex;
                flex-direction: column;
                flex: 1;
                padding: 12px;
                text-align: center;
                transition: transform 0.2s;

                &:hover{
                    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
                    transform: scale(1.02);
                }


                h6{
                    align-items: center;
                    color: #333333;
                    display: grid;
                    font: 500 14px/21px $Poppins;
                    height: 42px;
                    margin-bottom: 9px;
                    place-items: center;
                    width: 130px;
                }

                p{
                    color: #333333;
                    font: 12px/18px $Poppins;
                    margin-bottom: 30px;
                }

                svg{
                    &.passed_rating{
                        path.CircularProgressbar-path{
                            stroke: #4CE164 !important;
                        }
                    }
                    &.not_passed_rating{
                        path.CircularProgressbar-path{
                            stroke: #2C6BFF;
                        }
                    }
                }
                
                > svg{
                    height: 120px;
                    margin-bottom: 27px;
                    width: 120px;

                    .CircularProgressbar-trail{
                        stroke: #EFF4FF;
                    }

                    .CircularProgressbar-text{
                        fill: #152C61;
                        font: 500 16px/24px $Poppins;
                    }
                }

                &.skeleton_loading{
                    border: none;
                    cursor: unset;
                    height: 270px;
                    max-width: 223px;
                    > *{
                        display: none;
                    }

                    &:hover{
                        transform: unset;
                    }
                }
            }
        }
    }
    #belt_exam_history_container{
        background: #FFFFFF;
        border-radius: 6px;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
        margin-bottom: 31px;
        max-width: 522px;
        padding: 30px 30px 12px 29px;
        h5{
            color: #152C61;
            font: 500 normal 24px/36px "Poppins", sans-serif;
            margin: 0px;
        }
        #belt_list{
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            margin: 53px 18px 33px 18px;
            li{
                &.belt_item{
                    align-items: center;
                    display: flex;
                    flex-direction: column;
                    margin-bottom: 20px;
                    &:nth-last-child(-1n+3){
                        margin-bottom: 0px;
                    }
                    span.belt_icon{
                        background-repeat: no-repeat;
                        display: inline-block;
                        height: 46px;
                        margin-bottom: 7px;
                        width: 62px;
                    }
                    span.belt_name{
                        color: #000000;
                        font: 300 normal 14px/21px "Poppins", sans-serif;
                        text-align: center;
                        text-transform: uppercase;
                    }
                    &.hidden{
                        display: none;
                    }
                }
                &.skeleton_loading{
                    border-radius: 100%;
                    height: 68px;
                    width: 68px;
                }
            }
        }
        >img{
            display: block;
            margin: 36px auto 50px;
        }
        >p{
            color: #152C61;
            font: 300 normal 16px/24px "Poppins", sans-serif;
            margin-bottom: 11px;
            text-align: center;
            span{
                color: #2C6BFF;
                font: 500 normal 16px/24px "Poppins", sans-serif;
                text-align: center;
            }
            &.skeleton_loading{
                border-radius: 4px;
                height: 27px;
                margin: 0 auto 11px;
                max-width: 363px;
            }
        }
        table{
            margin-top: 20px;
            width: 100%;
            thead{
                tr{
                    th{
                        border-bottom: 18px solid transparent;
                        color: #152C61;
                        font-family: "Poppins", sans-serif;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 24px;

                        &:last-child{
                            text-align: right;
                        }
                    }
                }
            }
            tbody{
                tr{
                    td{
                        border-bottom: 12px solid transparent;
                        color: #152C61;
                        font-family: "Poppins", sans-serif;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 300;
                        line-height: 24px;
                        &:last-child{
                            text-align: right;
                        }
                    }
                }
            }
        }
        button{
            &.view_more_less_history_btn{
                background-color: transparent;    
                border: none;
                color: #2C6BFF;
                cursor: pointer;   
                display: flex;    
                font: 500 normal 16px/24px "Poppins", sans-serif;;     
                justify-content: center;
                margin: 0 auto;
                outline: none;
                text-decoration: none;
            }                
            &.skeleton_loading{
                border-radius: 4px;
                border: none;
                display: flex;   
                height: 27px;
                justify-content: center; 
                margin: 0 auto;
                width: 213px;
            }
        }
    }
    #dashboard_widget_container{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        position: relative;
        width: 100%;
        >div{
            display: flex;
            flex-direction: column;
        }
        #my_program_container{
            background: #FFFFFF;
            border-radius: 6px;
            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
            margin-bottom: 29px;
            padding: 31px 28px 30px 28px;
            width: 738px;
            .programs_tab{
                list-style: none;
                margin-bottom: 19px;
                margin-top: 25px;
                padding: 0;
                &.skeleton_loading{
                    border-radius: 6px;
                    color: transparent;
                    height: 41px;
                    width: 100%;
                }
                li{
                    cursor: pointer;
                    display: inline-block;
                    font: normal 400 16px/24px "Poppins", sans-serif;
                    height: 24px;
                    letter-spacing: 0.20px;
                    margin-bottom: 9px;
                    margin-right: 26px;
                    &.active{
                        font-weight: 600;
                        letter-spacing: 0;
                        &:after{
                            background: #2C6BFF;
                            content: "";
                            display: block;
                            height: 2px;
                            width: 100%;
                        }
                    }
                    &.program_tab_disabled{
                        pointer-events: none;
                    }
                }
            }
            .courses_list{
                list-style: none;
                padding: 0;
                li{
                    border-radius: 6px;
                    border: 1px solid rgba(44, 107, 255, 0.25);
                    margin-block: 20px;
                    min-height: 166px;
                    padding: 19px;
                    &.course_item{
                        background-image: linear-gradient(266.94deg, rgba(182, 211, 255, 0.25) 30.43%, rgba(251, 252, 255, 0.00651042) 99.82%, rgba(68, 87, 255, 0) 99.83%);
                    }
                    &.skeleton_loading{
                        border-radius: 6px;
                        border: none;
                        width: 100%;
                    }
                    h5{
                        color: #4561A1;
                        font: normal 500 20px/26px "Poppins", sans-serif;
                        margin: 0px;
                    }
                    p{
                        font: normal 500 14px/21px "Poppins", sans-serif;
                        margin: 0;
                    }
                    .completed_percentage{
                        color: #152C61;
                        margin-bottom: 13px;
                        margin-top: 13px;
                    }
                    .units_completed{
                        color: #4561A1;
                        margin-bottom: 10px;
                        svg{
                            color: #4561A1;
                            margin-right: 4px;
                        }
                    }
                    .button_progress_bar_section{
                        align-items: start;
                        display: flex;
                        justify-content: space-between;
                        position: relative;
                        .course_completed_progress_bar{
                            background: rgba(163, 163, 163, 0.3);
                            border-radius: 6px;
                            height: 4px;    
                            width: 400px;
                            .on_time_progress{
                                background-color: #2C6BFF;
                                border-radius: 6px;
                                height: 100%;
                                &.completed{
                                    background-color: #4CE164 !important;
                                } 
                            }
                        }
                        a{
                            align-items: center;
                            background-color: #2C6BFF;
                            border-radius: 6px;
                            border: none;
                            color: #FFFFFF;
                            cursor: pointer;
                            display: flex;
                            font: normal 500 14px/21px "Poppins", sans-serif;
                            height: 29px;
                            justify-content: center;
                            position: absolute;
                            right: 0;
                            text-decoration: none;
                            top: -25px;
                            width: 113px;
                            &.completed{
                                background-color: #FFFFFF;
                                border: 2px solid #2C6BFF;
                                color: #2C6BFF;
                            }
                        }
                    }
                }
            }
            h5{
                color: #152C61;
                font: normal 500 24px/36px "Poppins", sans-serif;
                margin: 0px;
                svg{
                    display: none;
                }
            }
            a{
                &#goto_button{
                    align-items: center;
                    border-radius: 6px;
                    display: flex;
                    font: normal 500 16px/24px "Poppins", sans-serif;
                    height: 60px;
                    justify-content: center;
                    text-decoration: none;
                    width: 100%;
                }
                &.skeleton_loading{
                    border: none;
                    color: transparent;
                    display: block;
                    height: 60px;
                    width: 100%;
                }
                &.with_content{
                    background: #2C6BFF;
                    border: 1px solid rgba(21, 44, 97, 0.1);
                    color: #FFFFFF;
                }
            }
        }
        #alumni_pass_container{
            background: #FFFFFF;
            border-radius: 6px;
            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
            height: 199px;
            margin-bottom: 28px;
            padding: 31px 28px 30px 28px;
            width: 738px;
            & > h5{
                color: #152C61;
                font: normal 500 24px/normal "Poppins", sans-serif;
                margin-bottom: 20px;
            }
            .alumni_pass_card{
                align-items: center;
                background: linear-gradient(267deg, rgba(182, 211, 255, 0.25) 30.43%, rgba(251, 252, 255, 0.01) 99.82%, rgba(68, 87, 255, 0.00) 99.83%);
                border-radius: 6px;
                border: 1px solid rgba(44, 107, 255, 0.25);
                display: flex;
                height: 75px;
                justify-content: space-between;
                padding: 20px;
                h5{
                    color: #4561A1;
                    font: normal 500 23px/normal "Poppins", sans-serif;
                    margin: 0;
                }
                a{
                    align-items: center;
                    background-color: #2C6BFF;
                    border-radius: 6px;
                    border: none;
                    color: #FFFFFF;
                    cursor: pointer;
                    display: flex;
                    font: normal 500 14px/21px "Poppins", sans-serif;
                    height: 29px;
                    justify-content: center;
                    text-decoration: none;
                    width: 113px;
                    &.disabled{
                        opacity: 50%;
                        pointer-events: none;
                    }
                }
                .locked_icon{
                    background: url("https://assets.codingdojo.com/learn_platform/user/dashboard/new_lock_icon.png") no-repeat -19px -20px;
                    height: 34px;
                    margin-right: 34px;
                    min-width: 27px;
                }
            }
        }
    }
}

@media only screen and (max-width: 768px){
    nav{
        .container{
            #profile_nav_links{
                .main_links_container{
                    display: none !important;
                    visibility: hidden !important;
                }
            }
        }
    }
    #dashboard_container.container{
        max-width: 100%;
        padding: 13px 20px 88px;
        .left_container{
            width: 100%;
        }
        .maintenance_banner{
            align-items: start;
            border-width: 2px;
            margin: 0px 0px 24px 0px;
            padding: 18px 54px 15px 14px;
            span.sprite_icon{
                margin-top: 6px;
                padding-right: 23px;
            }
            p{
                font-size: 14px;
            }
            &.payment_overdue{
                align-items: flex-start;
                flex-wrap: wrap;
                justify-content: center;
                padding: 18px 10px 23px 49px;
                z-index: 1;
                p{
                    margin-bottom: 16px;
                    &::before{
                        left: 17px;
                        top: 24px;
                    }
                }
                form{
                    flex-wrap: wrap;
                    gap: 11px;
                    button{
                        padding: 4px 39px;
                    }
                    .react-dropdown-select-content{
                        margin-right: 10px;
                    }
                }
            }
        }

        #sub_navigation_container{
            #switch_back_to_classic_learn{
                display: none;
            }

            .release_notes_banner{
                margin-top: 0px;
            }
        }

        #dashboard_widget_container{
            >div{
                width: 100%;
            }
            flex-direction: column-reverse;
            #my_class_container{
                margin-bottom: 5px;
                padding: 20px;
                width: 100%;
                header{
                    align-items: start;
                    flex-direction: column;
                    justify-content: space-between;
                    margin-bottom: 25px;
                    h5{
                        font: normal 500 23px/30px "Poppins";
                        margin-bottom: 25px;
                        position: relative;
                        width: 100%;

                        svg{
                            cursor: pointer;
                            display: unset;
                            height: 16px;
                            position: absolute;
                            right: 0;
                            top: 6px;
                            transform: rotate(90deg);
                            width: 16px;
                        }
                    }
                    #week_dates{
                        column-gap: 6px;
                        display: grid;
                        grid-template-columns: repeat(auto-fit, minmax(42.61px, 1fr));
                        height: auto;
                        justify-items: center;
                        row-gap: 8px;
                        width: 100%;
                        button{
                            font-size: 12px;
                            font-weight: 400;
                            margin-left: 0px;
                        }
                        ul{
                            display: grid;
                            grid-template-columns: repeat(auto-fit, minmax(42.61px, 1fr));
                            height: auto;
                            justify-items: center;
                            min-width: unset;
                            row-gap: 8px;
                            width: 100%;
                        }
                    }
                    &.hide_content{
                        margin: unset;
                        & > h5 {
                            font-size: 20px;
                            margin: unset;
                            svg{
                                transform: rotate(0deg);
                            }
                            & ~ *{
                                display: none !important;
                            }
                        }
                        & ~ * {
                            display: none;
                        }
                    }
                }
                h3{
                    font-weight: 500;
                    font-size: 14px;
                    margin-bottom: 17px;
                    
                }
                .belt_exam_banner{
                    display: none;
                    visibility: hidden;
                }
                ul#lectures_container{
                    margin-bottom: 0px;
                    li{
                        padding: 13px 19px 20px 78px;
                        a{
                            display: block;
                            font-size: 12px;
                            height: 29px;
                            line-height: 22px;
                            margin-top: 16px;
                            position: unset;
                            text-align: center;
                            width: 106px;

                            &::before{
                                left: 88px;
                                top: auto;
                                margin-top: 3px;
                            }
                        }
                        p{
                            font-size: 12px;
                            font-weight: 500;
                            margin-bottom: 4px;
                        }
                        p.lecture_time{
                            font-size: 11px;
                            font-weight: 400;
                        }
                        &::before{
                            left: 11px;
                            top: 18px;
                        }
                        .lecture_state{
                            font-size: 10px;
                            margin-bottom: 10px;
                            padding: 2px 4px 2px 14px;
                            position: relative;
                            right: unset;
                            top: unset;
                            &.is_live{
                                padding: 1px 4px 1px 14px;
                            }
                            &::before{
                                top: 6px;
                            }
                        }
                    }
                }
            }
            #belt_exam_history_container{
                cursor: pointer;
                margin-bottom: 24px;
                padding: 20px;
                max-width: unset;
                width: 100%;
                p{
                    font-size: 14px;
                }
                table{
                    margin-top: 29px;
                    th,td{
                        font-size: 14px;
                    }
                    tbody{

                        tr{
                            td{
                                &:first-child{
                                    padding-right: 10px;
                                }
        
                            }
                        }
                    }
                }
                #belt_list{
                    gap: 20px;
                    grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));
                    margin: 31px 20px 14px 20px;
                    li.belt_item{
                        span.belt_name{
                            font-size: 12px;
                        }
                    }
                }
                h5{
                    margin-bottom: 19px;
                    position: relative;
                    width: 100%;
                }
                button{
                    font-size: 14px;
                }
            }
            #my_stats_container{
                cursor: pointer;
                margin-bottom: 24px;
                order: 3;
                padding: 20px;
                width: 100%;
                h5{
                    font: 500 20px/30px $Poppins;
                    margin-bottom: 26px;
                    width: 100%;
                }
                #current_stats_label{
                    > div{
                        .selected_stats{
                            margin-bottom: 20px;
                            position: relative;
                            right: initial;
                            top: initial;
                            width: 100%;
                        }
                        .react-dropdown-select-dropdown{
                            left: 0;
                        }
                    }

                    &.skeleton_loading{
                        margin-bottom: 20px;
                        position: unset;
                        width: unset;
                    }
                }
                #rates_container{
                    flex-direction: column;
                    margin-bottom: 23px;
                    .skeleton_loading{
                        max-width: 100%;
                        min-height: 293px;
                    }
                }
                .belt_exam_banner{
                    display: none;
                    visibility: hidden;
                }
            }
            #my_program_container{
                cursor: pointer;
                margin-bottom: 20px;
                order: 1;
                padding: 20px;
                position: relative;
                right: unset;
                top: unset;
                width: 100%;            
                h5{
                    margin-bottom: 19px;
                    width: 100%;
                    font: 500 23px/34.5px $Poppins;
                }
                &.left_shadow::before{
                    background: linear-gradient(269.68deg, #fff 43.41%, rgba(255, 255, 255, 0.19) 125.34%);
                    content: "";
                    display: block;
                    height: 25px;
                    left: 0;
                    opacity: 1;
                    position: absolute;
                    top: 80px;
                    transform: rotate(180deg);
                    width: 36px;
                }
                &.right_shadow::after{
                    background: linear-gradient(269.68deg, #fff 43.41%, rgba(255, 255, 255, 0.19) 125.34%);
                    content: "";
                    display: block;
                    height: 25px;
                    opacity: 1;
                    position: absolute;
                    right: 0;
                    top: 80px;
                    width: 36px;
                }
                &.hide_left_shadow::before, &.hide_right_shadow::after{
                    opacity: 0;
                }
                button{
                    font-size: 14px;
                }
                .programs_tab{
                    -ms-overflow-style: none;  
                    overflow-x: scroll;
                    overflow-y: hidden;
                    scrollbar-width: none;  
                    white-space: nowrap;
                    &::-webkit-scrollbar {
                        display: none;
                    }
                    li{
                        font: normal 400 14px/21px "Poppins", sans-serif;
                        margin-right: 24px;
                        &:last-child{
                            margin-right: 0px;
                        }
                    }
                }
                .courses_list{
                    li{
                        h5{
                            font: normal 500 18px/27px "Poppins", sans-serif;
                        }
                        .units_completed{
                            font: normal 500 12px/18px "Poppins", sans-serif;
                        }
                        .button_progress_bar_section{
                            align-items: start;
                            display: flex;
                            flex-direction: column;
                            justify-content: space-between;
                            position: unset;
                            .course_completed_progress_bar{
                                background: rgba(163, 163, 163, 0.3);
                                border-radius: 6px;
                                height: 4px;    
                                margin-bottom: 13px;
                                width: 100%;
                                .on_time_progress{
                                    background: #2C6BFF;
                                    border-radius: 6px;
                                    height: 100%;    
                                }
                            }
                            a{
                                background-color: #2C6BFF;
                                border-radius: 6px;
                                border: none;
                                color: #FFFFFF;
                                font: normal 500 14px/21px "Poppins", sans-serif;
                                height: 37px;
                                position: unset;
                                width: 100%;
                            }
                        }
                    }
                }
            }
            #alumni_pass_container{
                order: 2;
                width: 100%;
                h5{
                    font: 500 23px/34.5px $Poppins;
                    margin-bottom: 19px;
                    width: 100%;
                }
            }
            .hide_section{
                display: none;
            }
        }
    }
    #dashboard_footer{
        display: none;
        visibility: hidden;
    }

    .is_dark_mode{
        #dashboard_container.container{
            #my_class_container{
                > header{
                    &.hide_content{
                        h5{
                            color: #81A0EA !important;
                        }
                    }
                }
            }
            #dashboard_widget_container{
                div:nth-child(2){
                    #my_program_container{
                        &.left_shadow::before{
                            background: linear-gradient(269.68deg, #2a2a2a 43.41%, rgba(42, 42, 42, 0.19) 125.34%) !important;
                        }
                        &.right_shadow::after{
                            background: linear-gradient(269.68deg, #2a2a2a 43.41%, rgba(42, 42, 42, 0.19) 125.34%) !important;
                        }
                        ul.courses_list{
                            li.course_item{
                                div.button_progress_bar_section{
                                    a.completed{
                                        background: linear-gradient( 0deg , rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), #121212 !important;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .maintenance_banner{
                background: #121212 !important;
                border-radius: 10px;
            }
        }
    }
}
.is_dark_mode{
    #dashboard_container.container{
        #dashboard_widget_container{
            div:nth-child(2){
                #my_program_container{
                    ul.courses_list{
                        li.course_item{
                            div.button_progress_bar_section{
                                a.completed{
                                    background: linear-gradient( 0deg , rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), #121212 !important;
                                }
                            }
                        }
                    }
                }
            }
        }
        .maintenance_banner{
            border: 1px solid #2A2A2A;
            .red_button{
                background-color: #2873FF;
            }
            &.payment_overdue{
                form{
                    .payment_overdue_select{
                        .react-dropdown-select-content{
                            color: #81A0EA;
                        }
                        .dropdown_icon{
                            background-position: -590px -13px;
                        }
                        .react-dropdown-select-dropdown{
                            background-color: #121212;
                            border-color: #81A0EA;
                            color: #b3b3b3;
                        }
                        .react-dropdown-select-item{
                            border: none;
                        }
                        .react-dropdown-select-item-selected{
                            color: #b3b3b3;
                        }
                    }
                }
            }
        }
    }
    #dashboard_footer{
        border-top: 1px solid #333333;
        div.container{
            div{
                p,a{
                    color: #B3B3B3 !important;
                } 
            }
            div.coding_dojo_container_info{
                span#coding_dojo_logo{
                    background: url("https://assets.codingdojo.com/learn_platform/global/coding_dojo_white_new_version.svg") no-repeat 0 0;
                    background-size: cover;
                    display: inline-block;
                    height: 24px;
                    margin-bottom: 12px;
                    width: 130px;
                }  
            }
        }
    }
}