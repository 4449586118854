$Poppins: 'Poppins', sans-serif;

.survey_select{
    background-color: transparent;
    outline: none;
    padding: 0 16px !important;
    &.has_value{
        border: none !important;
        .react-dropdown-select-content{
            color: rgba(255, 255, 255, 0.88);

        }
    }
    &.programs{
        margin-bottom: 0px !important;
    }

    .react-dropdown-select-content{
        color: #757575;
        span{
            font-family: $Poppins;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
        }
    }
    .react-dropdown-select-dropdown-handle{
        color: #81A0EA;
        display: flex;
        margin: 0px 0px 0px 3px;

        svg{
            height: 12px;
            width: 12px;
        }
    }

    .react-dropdown-select-dropdown {
        background: #5E6065;
        border-radius: 4px;
        top: 40px;
        .dropdown_search_container{
            input{
                margin-bottom: 0px !important;
                width: 100% !important;
            }
        }

        .dropdown_menu{
            &.multi_select{
                padding: 33px 10px 24px 20px;
                .dropdown_item{
                    margin-bottom: 27px;
                    .checkbox{
                        margin: 0px 0px 0px 10px !important;
                        opacity: 1 !important;
                        width: unset !important;
                        .fa-check{
                            display: none;
                        }
                        input[type="checkbox"]:checked ~ label{
                            .checkbox_container{
                                background-color: #2C6BFF !important;
                                border: none !important;
                                .fa-check{
                                    color: #fff;
                                    display: inline;
                                    font-size: 10px;
                                }
                            }    
                        }
                        label{
                            align-items: center;
                            cursor: pointer;
                            display: flex;
                            margin-bottom: 0px;
                            .checkbox_container{ 
                                align-items: center !important;
                                background: #FFFFFF !important;
                                border-radius: 4px !important;
                                border: 1px solid #A4B0C0 !important;
                                cursor: pointer !important;
                                display: flex !important;
                                min-width: unset !important;
                                height: 15px !important;
                                justify-content: center !important;
                                width: 15px !important;
                            }
                            >span{
                                color: #DEE5EF;
                                font-family: $Poppins;
                                font-size: 18px;
                                font-style: normal;
                                font-weight: bold;
                                letter-spacing: -0.01em;
                                line-height: 120%;
                            }                        
                        }
                    }
                    span{
                        margin-bottom: 0px;
                    }
                }
            }
        }

        span{
            background-color: transparent !important;
            border: none;
            color: rgba(255, 255, 255, 0.88);
            font-family: $Poppins;
            font-size: 12px;
            font-style: normal;
            font-weight: normal;
            line-height: 130%;
            margin-bottom: 0;
            padding: 0px;

            &:not(:last-child){
                margin-bottom: 27px;
            }

            &.react-dropdown-select-item-selected{
                color: #81A0EA;
                font-weight: 600;
            }
        }
    }
}