#student_matching_table_container{
    margin-right: 68px;
    text-align: center;
    width: 593px;
    .scroll_container{
        border-right: 2px dashed #f6f9fd29;
        &.is_selected_ondrag{
            background: #333333;
            border-right: 2px dashed rgba(154, 194, 255, 0.3) !important;
            table{
                tbody{
                    tr{
                        td{
                            background: #333333;
                        }
                    }
                }
            }
        }
        table{
            thead{
                tr{
                    th{
                        &:first-child{
                            width: 39px;
                            svg{
                                margin-left: 0px;
                            }
                        }
                        &:nth-child(2){
                            width: 204px;
                        }
                        &:nth-child(3) {
                            width: 61px;
                        }
                        &:nth-child(4){
                            min-width: 134px;
                        }
                        &:nth-child(5){
                            width: 100px;
                        }
                        &:nth-child(6){
                            width: 75px;
                        }

                        svg{
                            margin-left: 2px;
                        }
                    }
                }
            }
            tbody{
                tr{
                    td{
                        background: #2a2a2a;
                        input[type="checkbox"]:checked ~ label{
                            background: #81A0EA;
                        }
                        span{
                            cursor: pointer;
                        }
                        .last_belt{
                            background-color: rgba(84, 204, 176, 0.4);
                            border-radius: 4px;
                            color: rgba(5, 218, 128, 1);
                            display: block;
                            font-size: 12px;
                            font-weight: 500;
                            padding: 2px 19px 2px 6px;
                            position: relative;
                            width: 106px;
                            &:after{
                                background-image: url("https://assets.codingdojo.com/learn_platform/admin/rostering/belts_icon_new.png");
                                background-position: 0px 0px;
                                background-repeat: no-repeat;
                                content: "";
                                height: 10px;
                                position: absolute;
                                right: 6px;
                                top: 5px;
                                width: 10px;
                            }
                            &.yellow{
                                &:after{
                                    background-position: 0px 0px;
                                }
                            }
                            &.red{
                                &:after{
                                    background-position: -12px 0px;
                                }
                            }
                            &.black{
                                &:after{
                                    background-position: -24px 0px;
                                }
                            }
                            &.orange{
                                &:after{
                                    background-position: -36px 0px;
                                }
                            }
                            &.white{
                                &:after{
                                    background-position: -48px 0px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .student_matching_header{
        &.is_assign_email{
            align-items: center;
            display: flex;
            justify-content: flex-start;
            padding: 0 0 20px 14px;

            h4{
                padding-bottom: 0;
            }

            .assign_via_email_btn{
                align-items: center;
                background: rgba(204, 224, 255, 0.14);
                border-radius: 2px;
                border: 1px solid #81A0EA;
                box-sizing: border-box;
                color: #81A0EA;
                display: flex;
                font-family: "Poppins", sans-serif;
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                height: 32px;
                justify-content: center;
                margin: 0 0 0 auto;
                width: 143px;
            }
        }
    }
    
    @media screen and (min-width: 1367px) {
        flex-grow: 1;
    }
    @media screen and (max-width: 1440px) {
        margin-right: 40px;
        .scroll_container{

            table{
                thead{
                    tr{
                        th:last-child {
                            width: 178px;
                        }
                    }
                }
            }
        } 
    }
    @media screen and (max-width: 1476px) {
        margin-right: 40px;
        .scroll_container{

            table{
                thead{
                    tr{
                        th{
                            &:nth-child(3){
                                width: 204px;
                            }
                        }
                    }
                }
            }
        } 
    }
    @media screen and (max-width: 1400px) {
        .scroll_container{
            table{
                thead{
                    tr{
                        th{
                            &:nth-child(4){
                                width: 149px;
                            }
                            &:nth-child(2) {
                                width: 96px;
                            }
                        }
                    }
                }
            }
        }
    }
    @media screen and (max-width: 1366px) {
        margin-right: 20px;
        .scroll_container{

            table{
                thead{
                    tr{
                        th{
                            &:last-child{
                                width: 168px;
                            }
                            &:nth-child(2){
                                width: 109px;
                            }
                            &:nth-child(3){
                                width: 96px;
                            }
                            &:nth-child(4){
                                width: 213px;
                            }
                            &:nth-child(4){
                                width: 134px;
                            }
                        }
                    }
                }
            }
        }
    }
    @media screen and (min-width: 1667px) {
        flex-grow: 1;
    }
}
