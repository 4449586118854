#event_popover{
    background-color: transparent;
    border: none;
    min-width: 356px ;
    pointer-events: none;
    right: -8px !important;

    button:focus{
        outline: 5px transparent;
    }

    .arrow{
        display: none;
    }

    p{
        margin-bottom: 0;
    }

    .popover-body{
        background-color: #333333;
        border-radius: 5px;
        border: 1px solid rgba(129, 160, 234, 1);
        box-shadow: 0px 4px 5px 0px #00000024;
        color: #d9d9d9;
        font-family: 'Poppins', sans-serif;
        font-size: 14px;
        max-height: 90vh;
        overflow: auto;
        padding: 0;
        pointer-events: all;
        position: relative;
    }

    header{
        font-size: 14px;
        font-weight: 600;
        padding: 24px 19px 1px;

        .close_button{
            background-color: transparent;
            background: url("https://assets.codingdojo.com/learn_platform/admin/course_calendar/course_calendar_icon.png") no-repeat -66px -5px;
            border: none;
            display: inline-block;
            height: 14px;
            position: absolute;
            right: 24px;
            width: 14px;
        }
    }

    .program_info{
        display: flex;
        flex-wrap: wrap;
        padding: 22px 19px 21px;
        position: relative;

        &.has_many_calendar_events{
            cursor: pointer;
            &:hover {
                background-color: rgba(255, 255, 255, 0.1); 
            }
        }

        &:not(:nth-child(2))::before{
            content: "";
            border-top: 1px solid rgba(95, 95, 95, 1);
            position: absolute;
            width: 316px;
            top: 0;
        }
    }

    .event_type{
        margin: 0px auto 12px 35px;
        position: relative;

        &::before{
            border-radius: 50%;
            content: "";
            height: 16px;
            left: -31px;
            position: absolute;
            top: 2px;
            width: 16px;
        }
        &.cohort::before{
            background-color: #1DAA33;
        }
        &.break::before{
            background-color: #D251FF;
        }
        &.training::before{
            background-color: #FFB524;
        }
        &.holiday::before{
            background-color: #81A0EA;
        }

        span{
            font-size: 16px;
            font-weight: 500;
            letter-spacing: 2px;
            margin-right: 10px;
            text-transform: uppercase;
        }
    }

    .delete_button{
        background: url("https://assets.codingdojo.com/learn_platform/admin/course_calendar/course_calendar_icon.png") no-repeat -92px -4px;
        border: none;
        cursor: pointer;
        margin: -1px 21px 0px 0px;
        height: 18px;
        width: 14px;
    }

    .edit_button{
        background: url("https://assets.codingdojo.com/learn_platform/admin/course_calendar/course_calendar_icon.png") no-repeat -5px -39px;
        border: none;
        cursor: pointer;
        height: 16px;
        width: 16px;
    }

    .date_range{
        margin-bottom: 15px;
        margin-left: 35px;
        position: relative;
        width: 100%;

        &::before{
            background: url("https://assets.codingdojo.com/learn_platform/admin/course_calendar/course_calendar_icon.png") no-repeat -36px -9px;
            content: "";
            height: 16px;
            left: -31px;
            position: absolute;
            top: 2px;
            width: 16px;
        }
    }

    .created_by{
        font-size: 12px;
        width: 100%;
    }

    .event_approval_section{
        display: flex;
        gap: 10px;
        margin-top: 22px;
        width: 100%;
    
        button{
            border-radius: 4px;
            font-family: 'Poppins', sans-serif;
            font-weight: 600;
            padding-block: .55em;
            width: 50%;
        }
    
        .reject_button{
            background-color: transparent;
            border: 1px solid rgba(255, 255, 255, 0.4);
            color: white;
        }
    
        .approve_button{
            background-color: #484B50;
            border: 1px solid #484B50;
            color: #81A0EA;
        }
    }

    ::-webkit-scrollbar-track {
        box-shadow: none;
    }

    ::-webkit-scrollbar-thumb {
        background: #595959;
    }
}