$Poppins: "Poppins", sans-serif;

.student_progress_quiz{

    #search_by_name{
        display: flex;
        position: absolute;
        svg{
            color: #73839C;
            font-size: 14px;
            left: 12px;
            margin-right: 10px;
            position: absolute;
            top: 12px;
            z-index: 2;
        }
        input{
            align-items: center;
            background: linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), #121212;
            border-radius: 4px;
            border: 1px solid transparent;
            color: rgba(255, 255, 255, 0.88);
            font: 400 12px/16.8px $Poppins;
            height: 40px;
            padding: 0px 12px 0px 36px;
            position: relative;
            width: 256px;
    
            &:focus{
                background: #2A2A2A;
                border: 1px solid #81A0EA;
                box-shadow: 0px 0px 6px #81A0EA;
                outline: none;
            }
    
            &:focus + p{
                display: block;
            }
        }
    }
}