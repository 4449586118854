$Poppins: 'Poppins', sans-serif;

div.course_schedule_action_container{
    align-items: center;
    border-bottom: 1px solid rgba(217, 217, 217, 0.45);
    display: flex;
    button{
        outline: none;
        &.add_course_schedule_button{
            background: rgba(204, 224, 255, 0.14);
            border-radius: 2px;
            border: none;
            color: #81A0EA;
            font: normal 600 12px/130% 'Poppins';
            height: 31px; 
            margin-left: auto;
            padding: 8px 20px;
            white-space: nowrap;
        }
    }
}

div.course_schedule_toast{
    left: 50%;
    max-width: unset;   
    position: fixed;
    text-align: center;
    top: 84px;
    transform: translate(-50%, -50%);
    &.error_toast{
        z-index: 10000;
        div.toast-body{
            background: #E1554C;
        }
    }

    div.toast-body{
        background: #04AA64;
        border-radius: 4px;
        box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.14), 0px 3px 3px rgba(0, 0, 0, 0.12), 0px 1px 8px rgba(0, 0, 0, 0.2);
        padding: 9px 12px 8px 12px;
        position: relative;
        padding-right: 31px;
        
        p{
            display: inline-block;
            font: normal 400 14px/21px 'Poppins';
            margin-bottom: 0px;

            span{
                font-weight: 600 !important; 
            }
        }
        >div{
            padding-right: 10px;
        }

        #config_errors{
            margin-top: 3px;
            text-align: left;
        }

        button{
            background-color: transparent;
            background: url("https://assets.codingdojo.com/learn_platform/admin/course_calendar/course_calendar_icon.png") no-repeat -67px -29px;
            border: none;
            color: rgba(255, 255, 255, 0.88);
            height: 12px; 
            margin-left: 15px; 
            outline: none;
            position: absolute;
            right: 13px;
            top: 13px;
            width: 12px;
        } 
    }
}

#event_type_checkboxes_container{
    background-color: #2e2e2e;
    border-radius: 2px;
    display: flex;
    font-size: 14px;
    margin-top: 20px;
    padding: 11px 10px 8px 11px;

    label{
        -moz-user-select: none;
        -ms-user-select: none;
        -webkit-user-select: none;
        color: rgba(255,255,255,.88);
        cursor: pointer;
        font-family: "Poppins", sans-serif;
        margin-bottom: 0;
        margin-right: 40px;
        position: relative;
        user-select: none;

        input{
            margin-right: 8px;
        }
        
        &::before{
            background-color: #2e2e2e;
            border-radius: 2px;
            border: 1px solid rgba(255, 255, 255, 0.55);
            content: "";
            height: 16px;
            left: 0;
            position: absolute;
            top: 1px;
            width: 16px;
        }

        &::after{
            background: url("https://assets.codingdojo.com/learn_platform/admin/course_calendar/course_calendar_icon.png") no-repeat -67px -51px;
            content: "";
            display: none;
            height: 8px;
            left: 3px;
            position: absolute;
            top: 5px;
            width: 10px;
        }
        &:has(input:checked){
            &::after{
                display: block;
            }

            &::before{
                border: none;
            }

            &#holiday::before{
                background-color: rgba(129, 160, 234, 1);
            }
            &#break::before{
                background-color: rgba(210, 81, 255, 1);
            }
            &#training::before{
                background-color: rgba(255, 181, 36, 1);
            }
        }
    }
}

div.timeline_table_container{
    cursor: grab;
    height: 100vh;
    margin-top: 19px;
    max-width: max-content;
    overflow-x: auto;
    position: relative;
    table{
        border-collapse: separate;
        border-spacing: 0;
        border: none;
        height: 100%;
        table-layout: fixed;
        thead{
            tr{
                position: sticky;
                top: 0;
                z-index: 4;
                th{
                    border-bottom: 1px solid rgba(255, 255, 255, 0.4) !important;
                    border: none;
                    color: rgba(255, 255, 255, 0.88);
                    font: normal 500 14px/20px 'Poppins'; 
                    max-width: 112px;
                    min-width: 112px;
                    padding-bottom: 9px;
                    text-align: center;
                    z-index: 2;
                    div.current_date_border{
                        left: 0;
                        position: absolute;
                        top: 0;
                        span{
                            display: block;
                        }
                        #date_pin{
                            background-color:#2CB48C;
                            height: 9px;
                            left: 50%;
                            margin-left: 1px;
                            margin-top: 23px;
                            top: 50%;
                            transform: rotate(-45deg);
                            transform-origin: 0 100%;
                            width: 9px;
                        }
                    }
                }
                th:first-child {
                    background-color: #121212;
                    left:0;
                    min-width: 200px !important;
                    position:sticky;
                }
            }
        }
        tr{
            background-color: #121212;
            td{
                border: none;
                border-right: 1px solid rgba(255, 255, 255, 0.4);
                color: #121212;
                min-width: 112px;
                max-width: 112px;
                padding: 40px 0px 40px 0px;
                &:first-child{
                    background-color: #121212;
                    border-bottom: none;
                    border-left: none;
                    border-right: 2px solid rgba(255, 255, 255, 0.8); 
                    color: #ffffff;
                    left:0;
                    position:sticky;
                    top: 0;
                    vertical-align: top;
                    z-index:3;
                }
                &.course_schedule_container{
                    position: relative;
                }
                &.end_of_month{
                    border-right: 2px solid rgba(255, 255, 255, 0.8); 
                }
                &.current_date_container{
                    div.current_date_border{
                        height: 100%;
                        left: 0;
                        position: absolute;
                        top: 0;
                        z-index: 1; 
                        span{
                            display: block;
                        }
                        #date_pin_border{
                            border-left: 2px solid #2CB48C;
                            height: 100%;
                        }
                    } 
                }
            }
            &.program_container{
                position: relative;
                td{
                    border-top: 1px solid rgba(255, 255, 255, 0.45);
                    &:first-child{
                        .program_label{
                            position: sticky;
                            top: 71px;
                        }
                    }
                    &.course_schedule_container{
                        position: relative;
                        vertical-align: top;
                    }
                }
            }
        }
    }
}

.course_schedule_popover.popover{
    background: #333333;
    border-radius: 5px;
    border: 1px solid #81A0EA;
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.2);
    cursor: default !important;
    margin-left: 0px !important;
    min-width: 356px;
    padding: 15px 7px 13px 7px;  
    z-index: 5;
    &[data-popper-reference-hidden=true]{
        display: none;
    } 
    
    div.arrow{
        display: none;
    }

    .popover-body{
        display: flex;
        flex-wrap: wrap; 
    
        h4{
            color: #D9D9D9;
            font: normal 600 14px/140% $Poppins;
            margin-right: auto;
            max-width: 220px;
        }
        button{
            background: url("https://assets.codingdojo.com/learn_platform/admin/course_calendar/course_calendar_icon.png");
            border: none;
            outline: none;
          
        }
        .delete_disabled_container{
            position: absolute;
            right: 87px;
            top: 21px;
            width: 14px;
            .delete_button{
                background-position: -91px -3px;
                background-repeat: no-repeat;
                height: 20px;
                width: 16px;
                &.has_completed{
                    opacity: .5;
                    pointer-events: none;
                }
            }
        }
        button.close_button{
            background-position: -66px -5px;
            background-repeat: no-repeat;
            height: 14px;
            margin-left: 20px;
            position: absolute;
            right: 15px;
            top: 25px;
            width: 14px;
        }
       
        
        .edit_disabled_container{
            position: absolute;
            right: 50px;
            top: 22px;
            button.edit_button{
                background-position: -4px -38px;
                background-repeat: no-repeat;
                height: 16px;
                width: 16px;
               
                &.has_completed{
                    opacity: .5;
                    pointer-events: none;
                }
            }
        }
        .has_rostered_message{
            color: #B3B3B3;
            font: italic 14px "Poppins", sans-serif;
            margin-bottom: 0px;
            padding: 11px 0px 5px 0px;
        }
        p.course_schedule{
            color: rgba(255, 255, 255, 0.88);
            font: normal 400 14px/140% $Poppins;
            padding: 15px 0px 5px 0px;
            width: 100%;
            span.calendar_icon{
                background: url("https://assets.codingdojo.com/learn_platform/admin/course_calendar/course_calendar_icon.png") no-repeat -36px -9px;
                border: none;
                display: inline-block;
                height: 16px;
                margin-left: 1px; 
                margin-right: 12px;
                vertical-align: top;
                width: 16px;
            }
        }
        h5{
            align-items: center;
            color: rgba(255, 255, 255, 0.4);
            font: normal 600 12px/130% $Poppins;
            margin-bottom: 10px;
            width: 100%;
        }
        ul{
            column-gap: 16px;
            display: grid;
            grid-template-columns: 50px 50px 50px 50px 50px;
            list-style: none;
            margin-bottom: 0px;
            padding-left: 0px;
            row-gap: 10px;
            width: 100%;
            li{
                background: rgba(204, 224, 255, 0.14);
                border-radius: 4px;
                color: rgba(255, 255, 255, 0.88);
                font: normal 600 14px/140% $Poppins;
                height: 32px; 
                padding: 6px 7px;
                text-align: center;
            }
            li.selected_instruction_days{
                border: 1px solid #81A0EA;
                color: #81A0EA;
                padding: 6px;
            }
        }
        h6{
            color: rgba(255, 255, 255, 0.88);
            font: normal 600 14px/140% $Poppins;
            margin-bottom: 0px;
            padding: 24px 0px 22px 0px;
            width: 100%;
        }
        h6:nth-child(7){
            padding-top: 20px;
        }
        h6:nth-child(10){
            padding-top: 22px; 
        }
        img{
            border-radius: 100%;
            display: inline-block; 
            height: 20px;  
            margin-right: 14px; 
            vertical-align: middle;
            width: 20px;
        }
        span.instructors_avatar{
            align-items: center;
            color: rgba(255, 255, 255, 0.88);
            display: flex;
            font: normal 600 14px/140% $Poppins;
            margin-bottom: 0px !important;
        }
    }
}