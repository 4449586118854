$Poppins: "Poppins", sans-serif;

#course_details_container {
    -webkit-transition: all .5s;
    background: #121212;
    display: flex;
    flex-direction: column;
    height: calc(100% - 74px);
    padding-right: 30px;
    position: absolute;
    right: -100%;
    top: 74px;
    transition: all .5s;  
    width: calc(100% - 30px);
    z-index: 2000;
    &.show{
        -webkit-transition: all .5s;
        right: 0;
        transition: all .5s;  
    }

    header{
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
        margin-top: 20px;
        nav{
            display: flex;
            button{
                background: rgba(204, 224, 255, 0.14) url("https://assets.codingdojo.com/learn_platform/admin/curriculum_management/admin_curriculum_management_sprite.png") no-repeat -237px -15px;
                border-radius: 2px;
                border: none;
                flex-shrink: 0;
                height: 32px;
                margin-right: 26px;
                width: 32px;
            }
            h6{
                align-items: center;
                color: rgba(255, 255, 255, 0.88);
                display: flex;
                font: normal 400 12px/120% $Poppins;
                margin-bottom: 0px;
                .right_arrow{
                    background: url("https://assets.codingdojo.com/learn_platform/admin/curriculum_management/admin_curriculum_management_sprite.png") no-repeat -311px -26px;
                    display: inline-block;
                    flex-shrink: 0;
                    height: 8px;
                    margin-inline: 15px;
                    width: 8px;
                }
            }
        }
        .nav_buttons_container{
            flex-shrink: 0;
            .nav_buttons{
                background: rgba(204, 224, 255, 0.14);
                border-radius: 2px;
                border: none;
                color: #81A0EA;
                font: normal 600 12px/15.6px $Poppins;
                min-height: 32px;
                padding: 0;
                width: 90px;
                &:first-child{
                    margin-right: 10px;
                }
            }
        }
    }
    #course_details {
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.14), rgba(255, 255, 255, 0.14)), #121212;
        border-radius: 4px;
        display: flex;
        flex-direction: column;
        height: calc(100% - 84px);
        overflow: auto;
        padding: 19px 19px 25px;
        width: 100%;
        #course_details_loading_container{
            display: flex;
            flex-direction: column;
            left: 50%;
            position: absolute;
            text-align: center;
            top: 45%;
            transform: translate(-50%, 50%);
            div{
                background-image: url("https://codingdojo_assets.s3.us-east-1.amazonaws.com/learn_platform/admin/global/table_loader.gif");
                background-size: cover;
                height: 30px;
                margin-bottom: 7px;
                margin: 0 auto;
                mix-blend-mode: lighten;
                width: 30px;
            }
            span{
                color: rgba(255, 255, 255, 0.88);
                font: normal 500 14px/130% $Poppins;
            }
        }
        .course_name{
            align-items: center;
            color: #fff;
            display: flex;
            font: normal 700 16px/130% $Poppins;
            margin-bottom: 6px;
            span{
                background: #595959;
                border-radius: 20px;
                color: rgba(255, 255, 255, 0.8);
                font: normal 400 12px/130% $Poppins;
                margin-left: 20px;
                padding-block: 4px;
                padding-inline: 14px;
                text-align: center;
            }
        }
        .course_description{
            align-items: center;
            color: rgba(255, 255, 255, 0.5);
            display: flex;
            font: normal 400 12px/130% $Poppins;
            justify-content: space-between;
            span{
                color: #81A0EA;
                cursor: pointer;
                flex-shrink: 0;
                font-size: 14px;
                margin-left: auto;
                svg{
                    margin-left: 5px;
                }
            }
        }
        .course_details_block{
            display: grid;
            grid-template-columns: repeat(5, 1fr);
            margin-top: 30px;
            max-width: 826px;
            row-gap: 30px;
            .course_details_item{
                .course_details_item_title{
                    color: rgba(255, 255, 255, 0.88);
                    font: normal 400 12px/120% $Poppins;
                    &::before{
                        background: url("https://assets.codingdojo.com/learn_platform/admin/curriculum_management/admin_curriculum_management_sprite.png") no-repeat 0px 0px;
                        content: "";
                        display: inline-block;
                        height: 14px;
                        margin-right: 5px;
                        vertical-align: middle;
                        width: 14px;
                    }
                }
    
                .course_details_value{
                    color: #fff;
                    font: normal 600 14px/120% $Poppins;
                    margin-left: 20px;
                    margin-top: 10px;
                }

                .course_details_sub_value{
                    font: normal 400 10px/120% $Poppins;
                    margin-left: 20px;
                    margin-top: 3px;
                }
    
                &:nth-child(1) .course_details_item_title::before{
                    background-position: -398px -24px;
                }
                &:nth-child(2) .course_details_item_title::before{
                    background-position: -428px -23px;
                }
                &:nth-child(3) .course_details_item_title::before{
                    background-position: -457px -23px;
                }
                &:nth-child(4) .course_details_item_title::before{
                    background-position: -487px -23px;
                }
                &:nth-child(5) .course_details_item_title::before{
                    background-position: -279px -23px;
                }
                &:nth-child(6) .course_details_item_title::before{
                    background-position: -517px -23px;
                }
                &:nth-child(7) .course_details_item_title::before{
                    background-position: -548px -23px;
                }
                &:nth-child(8) .course_details_item_title::before{
                    background-position: -578px -23px;
                }
                &:nth-child(9) .course_details_item_title::before{
                    background-position: -608px -23px;
                }
            }
        }
        #course_details_table_buttons_container{
            align-items: center;
            border-bottom: 1px solid rgba(204, 224, 255, 0.14);
            display: flex;
            justify-content: space-between;
            margin-bottom: 10px;
            margin-top: 21px;
            .course_details_table_header{
                display: flex;
                list-style-type: none;
                margin: 0;
                padding: 0;
                li{
                    align-items: center;
                    border-bottom: 2px solid transparent;
                    color: rgba(255, 255, 255, 0.88);
                    cursor: pointer;
                    display: flex;
                    font: normal 400 14px/120% $Poppins;
                    justify-content: center;
                    letter-spacing: 0.35px;
                    min-height: 60px;
                    min-width: 120px;
                    width: 120px;
                    &:nth-child(2){
                        width: 240px;
                    }
                    &:nth-child(3){
                        width: 169px;
                    }
                    &.active_tab{
                        border-bottom: 2px solid rgba(255, 255, 255, 0.88);
                        font: normal 700 14px/120% $Poppins;
                        letter-spacing: 0px;
                    }
                }
            }
            button{
                background: rgba(204, 224, 255, 0.14);
                border-radius: 2px;
                border: none;
                color: #81A0EA;
                font: normal 600 12px/15.6px $Poppins;
                min-height: 32px;
                padding: 0;
                width: 120px;

                &:disabled{
                    opacity: .5;
                }
            }
        }
        > button {
            background: #4B4B4B;
            border-radius: 2px;
            border: none;
            color: #E5E5E5;
            margin: 10px 0 18px 0;
            padding: 7px 12px 7px 40px;
            position: relative;
            width: fit-content !important;
            &::before{
                background: url("https://assets.codingdojo.com/learn_platform/admin/curriculum_management/admin_curriculum_management_sprite.png") no-repeat -639px -20px;
                content: "";
                display: inline-block;
                height: 20px;
                left: 0px;
                margin-left: 13px;
                position: absolute;
                width: 20px;
            }
        }
    }
}