#track_courses_modal{
    max-width: 630px;
    width: 630px;
    .modal-content{
        background: #FFFFFF;
        border-radius: 6px;
        box-shadow: 0px 4px 2px rgba(0, 0, 0, 0.05);
        min-height: 332px;
        .modal-header{
            border: none;
            padding: 22px 29px 19px 29px;
            .modal-title{
                color: #152C61;
                font-family: 'Poppins', sans-serif;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 24px;
            }

            button{
                background-color: transparent;
                border: none;
                outline: none;
                padding: 0px;
                position: absolute;
                right: 26px;
                top: 15px;
                .close_icon{
                    background-image: url("https://assets.codingdojo.com/learn_platform/global/close_icon_new.png");
                    display: inline-block;
                    height: 33px;
                    width: 33px;
                }
            }
        }
        .modal-body{
            padding: 0px 29px 20px 29px;
            ul{
                align-items: flex-start;
                display: flex;
                flex-wrap: wrap; 
                margin-bottom: 0px;
                li{
                    margin-bottom: 20px;
                    margin-right: 10px;
                    text-align: center;
                    width: 106px;

                    button{
                        background: transparent;
                        border: none;
                        outline: none !important;
                        width: inherit;
                    }

                    &.completed{
                        position: relative;
                        &:before{
                            background-image: url("https://assets.codingdojo.com/learn_platform/global/check_circle.png");
                            background-repeat: no-repeat;
                            content: "";
                            display: inline-block;
                            height: 26px;
                            position: absolute;
                            right: 19px;
                            top: 45px;
                            width: 26px;
                        }
                    }

                    &:nth-child(5n+5){
                        margin-right: 0px;
                    }
                    &:nth-last-child(-n+5){
                        margin-bottom: 0px;
                    }
                    img{
                        height: 65px;
                        width: 57px;
                    }
                    span{
                        color: #152C61;
                        display: inline-block;
                        font-family: 'Poppins', sans-serif;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: normal;
                        line-height: 24px;
                        margin-top: 12px;
                        overflow: hidden;
                        text-align: center;
                        text-overflow: ellipsis; 
                        white-space: nowrap; 
                        width: 100%; 
                    }
                }
            }
        }
    }
}
@media only screen and (max-width: 768px){
    #track_courses_modal{
        .modal-content{
            .modal-header{
                padding: 22px 29px 35px 29px;
            }
            .modal-body{
                ul{
                    li{
                        width: 88px;
                        &:nth-last-child(-n+5){
                            margin-bottom: 20px;
                        }
                        img{
                            height: 40px;  
                            width: 36px;
                        }
                    }
                }
            }
        }
    }
}