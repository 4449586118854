$Poppins: 'Poppins', sans-serif;

#admin_live_lecture_container{
    background-color: #121212;
    display: flex;
    height: 100vh;
    
    button{
        outline: none;
    }

    #admin_right_container{
        display: flex;
        flex-direction: column;
        flex: 1;
        overflow: hidden;
        padding: 12px 30px 20px 30px;
        position: relative;
        
        #live_lecture_table_filter_container{
            position: relative;
            #add_live_lecture_btn{
                background-color: rgba(204, 224, 255, 0.14);
                background-image: url("https://codingdojo_assets.s3.us-east-1.amazonaws.com/learn_platform/admin/global/admin_cta_icons.svg");
                background-position: 208px 0px;
                border-radius: 2px;
                border: none;
                height: 32px;
                position: absolute;
                right: 0px;
                top: 17px;
                width: 38px;
                &.clear{
                    background-position: 20px;
                }
            }
        }

        #search_by_lecture_title{
            display: flex;
            position: absolute;
            svg{
                color: #73839C;
                font-size: 14px;
                left: 12px;
                margin-right: 10px;
                position: absolute;
                top: 12px;
                z-index: 2;
            }
            input{
                align-items: center;
                background: linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), #121212;
                border-radius: 4px;
                border: 1px solid transparent;
                color: rgba(255, 255, 255, 0.88);
                font: 400 12px/16.8px $Poppins;
                height: 40px;
                padding: 0px 12px 0px 36px;
                position: relative;
                width: 256px;
        
                &:focus{
                    background: #2A2A2A;
                    border: 1px solid #81A0EA;
                    box-shadow: 0px 0px 6px #81A0EA;
                    outline: none;
                }
        
                &:focus + p{
                    display: block;
                }
            }
        }
    }
}

.live_lecture_action_popover, .live_lecture_programs_popover{
    border: none;
    z-index: 2;
    &.table_popover{
        z-index: 0 !important;
    }
    &.live_lecture_programs_popover{
        .popover-body{
            span{
                color: #fff;
                font: 12px/18px "Poppins", sans-serif;
            }
        }
    }
    .popover-body{
        background: #4B4B4B;
        display: flex;
        flex-direction: column;
        padding: 12px 14px;
        button{
            align-items: center;
            background: transparent;
            border: none;
            color: #fff;
            display: flex;
            font: 12px/18px $Poppins;
            margin-bottom: 10px;
            outline: none;
            padding: 0px;
            &:last-child{
                margin-bottom: 0px;
            }
            span{
                background-image: url("https://codingdojo_assets.s3.us-east-1.amazonaws.com/learn_platform/admin/global/admin_cta_icons.svg");
                display: inline-block;
                height: 16px;
                margin-right: 14px;
                vertical-align: top;
                width: 14px;
            }
            .disabled_icon{
                background-position: -60px -9px;
            }
            .delete_icon{
                background-position: -92px -9px;
                width: 16px;
            }
            .enable_icon{
                background-position: 96px -9px;
                width: 16px;
            }
            .edit_icon{
                background-position: 17px -7px;
            }
            .new_live_lecture, .new_video_record{
                background-image: url("https://codingdojo_assets.s3.us-east-1.amazonaws.com/learn_platform/admin/instruction/live_lecture/live_lecture_popover_icon.svg");
                background-position: 0px 0px;
                height: 23px;
                margin-right: 8px;
                width: 21px;
            }
            .new_video_record{
                background-position: -40px 0px;
            }
        }
    }
    &.bs-popover-auto[x-placement^=bottom]>.arrow::after, &.bs-popover-bottom>.arrow::after{
        border-bottom-color: #4b4b4b !important;
    }
    &.bs-popover-auto[x-placement^=left]>.arrow::after, &.bs-popover-left>.arrow::after{
        border-left-color: #4b4b4b !important;
    }
    &.bs-popover-auto[x-placement^=right]>.arrow::after, &.bs-popover-right>.arrow::after{
        border-right-color: #4b4b4b !important;
    }
    &.bs-popover-auto[x-placement^=top]>.arrow::after, &.bs-popover-top>.arrow::after{
        border-top-color: #4b4b4b !important;
    }
}