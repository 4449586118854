$Poppins: 'Poppins', sans-serif;

#write_function_modal{
    max-width: 624px;
    width: 624px;

    .modal-content{
        background: #FFFFFF;
        border-radius: 4px;        
        border: none;
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.07);

        .modal-header{
            border-bottom: 1px solid #DEE5EF;
            padding: 15px 24px 13px 24px;
            
            .modal-title{
                color: #051226;
                font-family: $Poppins;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 140%;
            }

            button{
                background-color: transparent;
                border: none;
                outline: none;
                padding: 0px;
                position: absolute;
                right: 24px;
                top: 12px;
            }
        }

        .modal-body{
            padding: 0px;

            form{
                padding: 20px 0px 0px 0px;

                #write_function_editor_container{
                    margin: 0px 24px;

                    p{
                        color: #394A64;
                        font-family: $Poppins;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 130%;
                        margin-bottom: 8px;
                    }
    
                    textarea{
                        color: #152C61;
                        font-family: $Poppins;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 300;
                        height: 111px;
                        line-height: 24px;
                        resize: none;
                    }
                }

                .btn_container{
                    border-top: 1px solid #DEE5EF;
                    display: flex;
                    justify-content: flex-end;
                    margin-top: 19px;
                    padding: 24px;

                    button{
                        background: rgba(131, 179, 252, 0.26);
                        border-radius: 4px;    
                        border: none;
                        color: #2C6BFF;
                        font-family: $Poppins;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 600;
                        height: 40px;
                        line-height: 130%;
                        width: 61px;
                    }
                }
            }
        }
    }
}