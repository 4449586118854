$Poppins: "Poppins", sans-serif;
$black: #000;
$blue: #2C6BFF;
$dark_blue: #152C61;
$purple: #81A0EA;
$white: #fff;

#notification_container.container{
    margin-bottom: 80px;
    #sub_notification_content,
    #notification_content{
        background: $white;
        border-radius: 6px;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
        padding: 25px;
        width: 100%;

        #notification_header{
            position: relative;
            h2{
                color: $dark_blue;
                font: 500 26px/39px $Poppins;
            }
        }
        .dropdown{
            border: none;
            bottom: 8px;
            position: absolute;
            right: 0px;
            &.show{
                #notification_dropdown{
                    background: #C2D4FF;
                    color: $blue;
                    &:hover{
                        background: #C2D4FF;
                    }
                }
            }
            button{
                background: transparent;
                border: none;
                box-shadow: none;
                color: #5E6871;
                outline: none;
                padding: 0px;
    
                &:after{
                    display: none;
                }
            }
            #notification_dropdown{
                border-radius: 2px;
                color: #5E6871;
                height: 24px;
                width: 24px;
                &:hover{
                    background: #F0F0F0;
                }
            }
            .dropdown-menu.show{
                border-radius: 6px;
                border: 1px solid #DCE6FF;
                box-shadow: 0px 10px 8px rgba(0, 0, 0, 0.05);
                box-sizing: border-box;
                display: flex;
                flex-direction: column;
                min-width: 132px;
                button, .dropdown-item{
                    color: $dark-blue;
                    font: 12px/18px $Poppins;
                    padding: 10px 14px;
                    text-align: left;
                    &:hover{
                        color: $dark-blue;
                        font-weight: bold;
                    }
                    &:active{
                        color: #A3A3A3;
                        font-weight: normal;
                    }
                    &:disabled{
                        color: #A3A3A3;
                        font-weight: normal;
                        &:hover{
                            color: #A3A3A3;
                            font-weight: normal;
                        }
                    }
                    &:hover{
                        background: none;
                    }
                }
            }
        }
        #notification_tab{
            margin: 11px 0 31px;
            button{
                background: none;
                border: none;
                color: #333333;
                cursor: pointer;
                font: 400 16px/24px $Poppins;
                margin-right: 13px;
                outline: none;
                padding: 0 0 3px 0;
                position: relative;
                width: fit-content;
                &.active{
                    color: $dark-blue;
                    font: 600 16px/24px $Poppins;
                    &::before{
                        background: $blue;
                        border-radius: 10px;
                        bottom: 0;
                        content: "";
                        height: 2px;
                        left: 0;
                        position: absolute;
                        width: 100%;
                    }
                }
            }
        }
        #empty_box_icon{
            background-image: url("https://assets.codingdojo.com/learn_platform/global/modals/empty_box_icon.png");
            height: 60px;
            margin: 22px auto 3px;
            width: 53px;
        }
        p{
            color: rgba(21, 44, 97, 0.3);
            font-family: "Poppins", sans-serif;
            font-size: 12px;
            margin-bottom: 7px;
            text-align: center;
        }
        form{
            text-align: center;
            button{
                background: transparent;
                border-radius: 6px;
                border: none;
                color: #8A8A8A;
                height: 33px;
                outline: none !important;
                width: 203px;
                svg{
                    color: #8A8A8A;
                    font-size: 21px;
                    margin-left: 8px;
                    vertical-align: top;
                }
            }
        }
        #mark_all_as_read_form{
            font-weight: 500;
            padding: 44px 0px 17px;
            button{
                background: #FAFBFF;
                color: $blue;
            }
        }
        .block{
            padding: 0px 0px 10px;
            h2{
                color: $dark-blue;
                font: 600 12px/18px $Poppins;
            }
            ul{
                list-style: none;
                margin-bottom: 0px;
                padding: 0px;
                li{
                    margin: 10px 0px 2px;
                    padding-bottom: 20px;
                    position: relative;

                    > div{
                        position: relative;
                        width: fit-content;
                        >a, .question_text{
                            -webkit-box-orient: vertical;
                            -webkit-line-clamp: 2;
                            color: $dark-blue;
                            display: -webkit-box;
                            font: 12px/18px $Poppins;
                            overflow: hidden;
                            padding-right: 28px;
                            text-decoration: none;
                            text-overflow: ellipsis;
                            white-space: initial;
                            &:hover{
                                .cd_blue:not(.name){
                                    text-decoration: underline;
                                }
                            }
                        }
                    }

                    &:not(:first-of-type){
                        margin-top: 18px;
                    }
                    &:not(:last-of-type){
                        border-bottom: 1px solid #dee5ef;
                    }
                    .question_text{
                        color: $dark-blue;
                        margin-bottom: 5px;
                        padding-right: 27px;
                        text-align: left;
                        &:hover{
                            -webkit-box-orient: unset; 
                            -webkit-line-clamp: unset; 
                            display: -webkit-box;
                            overflow: unset; 
                            text-decoration: unset; 
                            text-overflow: unset; 
                            white-space: unset;
                        }
                        a, button{
                            color: $blue;
                            &:hover{
                                text-decoration: underline;
                            }
                        }
                        button{
                            background: none;
                            border: none;
                            margin: 0px;
                            outline: none;
                            padding: 0px;
                        }
                    }
                    .response_text{
                        color: #E1554C;
                        font: 10px/15px $Poppins;
                        text-align: left;
                        ~ .delete_notification_form{
                            top: 26px;
                        }
                    }
                    .reminder_text{
                        align-items: center;
                        display: flex;
                        flex-direction: row;
                        font: 12px/18px $Poppins;
                        margin-top: 8px;
                        p{
                            color: $dark-blue;
                            margin: 0 0 0 25px;
                            position: relative;
                            &::before{
                                background: #5E6871;
                                border-radius: 50%;
                                content: "";
                                height: 5px;
                                left: -15px;
                                position: absolute;
                                top: 50%;
                                transform: translateY(-50%);
                                width: 5px;
                            }
                        }
                        a{
                            color: $blue;
                        }
                    }
                    .dropdown{
                        position: initial;  
                        z-index: 1;
    
                        .dropdown-menu.show{
                            left: 24px !important;
                        }
                    }
                    &.unread_notification{
                        padding-left: 0px;
    
                        .question_text{
                            position: relative;
                            &::before{
                                background: #E1554C;
                                border-radius: 100%;
                                content: "";
                                display: block;
                                height: 10px;
                                position: absolute;
                                right: 10px;
                                top: 3px;
                                width: 10px;
                            }
                        }
         
                        &:hover{
                            .question_text::before{
                                display: none;
                            }
                        }
                        a{
                            &:hover{
                                background-position-x: 637px;
                            }
                        }
                    }
                    &:hover{
                        .delete_notification_form{
                            display: block;
                        }
                    }
                    .delete_notification_form{
                        display: none;
                        margin: 0px;
                        position: absolute;
                        right: 10px;
                        top: 5px;
                        width: unset;
                        button{
                            background: #8A8A8A;
                            display: block;
                            height: 10px;
                            margin: 0px;
                            padding: 1px;
                            width: 10px;
                            svg{
                                color: #fff;
                                font-size: 8px;
                                margin: 0px;
                            }
                        }
                    }
                    svg{
                        margin-right: 24px;
                    }
    
                    .date_text{
                        color: #A3A3A3;
                        font: 10px/15px $Poppins;
                    }
                    .cd_blue{
                        color: $blue;
                    }
                }
            }
        }
        #view_all{
            color: $blue;
            display: block;
            font: 500 14px/21px $Poppins;
            padding-bottom: 2px;
            text-align: center;
        }

        .notification_loading{
            display: block;
            height: 100px;
            margin: 20px auto;
            width: 100px;
        }
    }
}

@media only screen and (max-width: 768px){
    #sub_notification_content{
        & ~ * {
            display: none !important;
        }
    }
    #notification_container.container{
        max-width: 100%;
        padding: 24px 20px 30px;
        #sub_notification_content,
        #notification_content{
            margin-bottom: 100px;
            padding: 25px 21px;

            #notification_header{
                h2{
                    font: 500 23px/34.5px $Poppins;
                }
            }
            #notification_tab{
                margin: 17px 0 22px;
                button{
                    font: 600 12px/18px $Poppins;
                    padding-bottom: 10px;
                    &.active{
                        font: 600 12px/18px $Poppins;
                    }
                }
            }
            .block{
                ul{
                    li{
                        &:last-of-type{
                            border: none;
                        }
                        >div{
                            width: 100%;
                        }
                    }
                }
            }
        }
    }

}
.is_dark_mode{
    #notification_container.container{
        #sub_notification_content,
        #notification_content{
            background: #333333;
            #notification_header{
                h2{
                    color: #E5E5E5;
                }
            }
            .dropdown{
                &.show{
                    #notification_dropdown{
                        background: #C0D0F4;
                        color: #81A0EA;
                        &:hover{
                            background: #C0D0F4;
                        }
                    }
                }
                #notification_dropdown{
                    color: #81A0EA;
                    &:hover{
                        background: #cce0ff24;
                    }
                }
                .dropdown-menu.show{
                    background: #333333;
                    border: 1px solid #DCE6FF;
                    button, .dropdown-item{
                        color: #ffffff;
                        &:active{
                            color: rgba(255, 255, 255, 0.48);
                        }
                        &:disabled{
                            color: rgba(255, 255, 255, 0.48);
                            &:hover{
                                color: rgba(255, 255, 255, 0.48);
                            }
                        }
                    }
                }
            }

            #notification_tab{
                button{
                    color: #B2B2B2;
                    &.active{
                        color: #E5E5E5;
                        &::before{
                            background: $purple;
                        }
                    }
                }
            }
            .block{
                h2{
                    color: #E5E5E5;
                }
            }
            ul{
                li{
                    &:not(:last-of-type){
                        border-bottom: 1px solid #666666;
                    }
                    >div{
                        a, .question_text{
                            color: #E5E5E5;
                        }
                        .cd_blue{
                            color: $purple;
                        }
                    }

                    .response_text{
                        color: #F56B6B;
                    }
                    &.unread_notification{
                        .question_text{
                            &::before{
                                background: #F56B6B;
                            }
                            a{
                                color: $purple;
                            }
                        }
                    }
                    .reminder_text{
                        p{
                            color: #E5E5E5;
                        }
                        a{
                            color: $purple;
                        }
                    }
                    .delete_notification_form{
                        button{
                            background: #ffffff7a;
                            svg{
                                color: #333333;
                            }
                        }
                    }
                    .date_text{
                        color: #B2B2B2;
                    }
                    .question_text{
                        a, button{
                            color: $purple;
                        }
                    }
                }
            }
            #empty_box_icon{
                background-position: -87px 0px;
            }
            p{
                color: #e2e2e2;
            }
        }
    }
}
