#assignment_monitoring_tooltip{
    .tooltip-inner{
        background: #121212;
    }
    .arrow:before{
        border-top-color: #121212 !important;
    }
}
#assignment_monitoring{
    background: #121212 !important;
    min-height: 100vh;
    padding-top: 50px;

    #assignment_monitoring_container{
        background: #333333;
        border-radius: 6px;
        color: #fff;
        font-family:'Poppins', sans-serif;
        font-size: 12px;
        height: calc(100vh - 87px);
        margin: 0px auto 50px;
        max-width: calc(100% - 100px);
        padding: 20px;
        #table_loading_container{
            align-items: center;
            background: linear-gradient(0deg, rgba(255, 255, 255, 0.14), rgba(255, 255, 255, 0.14)), #121212;
            display: flex;
            flex-direction: column;
            left: 50%;
            padding: 155px;
            position: sticky;
            top: 35%;
            transform: translate(-50%, -4%);
            width: 50px;
            div{
                background-image: url("https://codingdojo_assets.s3.us-east-1.amazonaws.com/learn_platform/admin/global/table_loader.gif");
                background-size: cover;
                height: 30px;
                margin-bottom: 7px;
                mix-blend-mode: lighten;
                width: 30px;
            }
            span{
                color: rgba(255, 255, 255, 0.88);
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 130%;
            }
        }
    
        form{
            align-items: self-end;
            display: flex;
            margin-bottom: 20px;
            .form-group{
                margin-bottom: 0px;
                margin-right: 10px;
                width: 220px;
                .react-dropdown-select{
                    height: 32px !important;
                    .react-dropdown-select-item-selected{
                        background: #81a0ea;
                    }
                    .react-dropdown-select-dropdown{
                        width: 100%;
                    }
                    .react-dropdown-select-content{
                        -ms-flex-wrap: inherit;
                        -webkit-flex-wrap: inherit;
                        flex-wrap: inherit; 
                        overflow: hidden;
                        white-space: nowrap;
                    }
                    .react-dropdown-select-option{
                        background: transparent !important;
                        border: 1px solid #81a0ea !important;
                    }
                }
                input{
                    font-size: 12px;
                }
            }
            button{
                border-radius: 0px;
                border: 1px solid #81A0EA;
                color: #81A0EA;
                font-size: 14px;
                margin-left: 10px;
                padding: 4px 20px;
            }
        }
        .scroll_container{
            height: calc(100vh - 285px);
            overflow-x: scroll;
            table{
                width: 2090px;
                margin-bottom: 0px;
                thead{
                    tr{
                        th{
                            border-bottom: 2px solid #494c50;
                            button{
                                border: none;
                                background: transparent;
                                color: #939393;
                                font-size: 16px;
                            }
                        }
                    }
                }
                th, td{
                    color: #fff;
                    text-align: center;
                    border-top: 1px solid #494c50;
                }
                tr#no_results_found_tr{
                    td{
                        padding: 60px;
                    }
                }
                a{
                    color:#81a0ea;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2;
                    display: -webkit-box;
                    overflow: hidden;
                    text-align: left;
                    text-overflow: ellipsis;
                    white-space: initial;
                    max-width: 180px;
                }
            }
        }
    }
}