$Poppins: "Poppins", sans-serif;
$purple: #81A0EA;

#program_details_container{
    -webkit-transition: all .5s;
    background: #121212;
    bottom: 0;
    height: calc(100% - 73px);
    padding-right: 30px;
    position: absolute;
    right: -100%;
    transition: all .5s;  
    width: calc(100% - 30px);
    z-index: 2000;

    &.show{
        -webkit-transition: all .5s;
        right: 0;
        transition: all .5s;  
    }

    .program_details_header{
        align-items: center;
        display: flex;
        margin-top: 20px;

        .program_details_back_btn{
            background: #2C2F33 url("https://assets.codingdojo.com/learn_platform/admin/curriculum_management/admin_curriculum_management_sprite.png") no-repeat -237px -14px;
            border-radius: 2px;
            border: none;
            height: 32px;
            margin-right: 26px;
            outline: none;
            width: 32px;
        }

        .program_details_breadcrumb{
            display: flex;
            gap: 15px;
            p{
                color: #8F8F8F;
                font: 400 12px/14.4px $Poppins;
                margin: 0;
            }
            .arrow_icon{
                background: url("https://assets.codingdojo.com/learn_platform/admin/curriculum_management/admin_curriculum_management_sprite.png") no-repeat -306px -22px;
                height: 16px;
                opacity: .6;
                width: 16px;
            }
        }
    }

    .program_details_body{
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.14), rgba(255, 255, 255, 0.14)), #121212;
        border-radius: 4px;
        height: calc(100% - 83px);
        margin-top: 10px;
        padding: 25px 20px;
        position: relative;

        .program_name{
            color: #fff;
            font: 700 16px/20.8px $Poppins;
            margin-bottom: 15px;
        }

        .detail_group{
            padding-left: 30px;
            span{
                color: #9F9F9F;
                display: block;
                font: 400 12px/14.4px $Poppins;
                margin-bottom: 10px;
                position: relative;
                &::before{
                    background: url("https://assets.codingdojo.com/learn_platform/admin/curriculum_management/admin_curriculum_management_sprite.png") no-repeat -279px -21px;
                    content: "";
                    height: 16px;
                    left: -20px;
                    position: absolute;
                    top: -2px;
                    width: 16px;
                }
            }
            p{
                color: #E7E7E7;
                font: 600 14px/16.8px $Poppins;
            }
        }
        
        .table_tab_container{
            border-bottom: 1px solid #484B50;
            display: flex;
            justify-content: space-between;
            margin-top: 47px;

            .tab{
                background: none;
                border-bottom: 2px solid #E7E7E7;
                color: #E7E7E7;
                font: 700 14px/16.7px $Poppins;
                margin-bottom: 0;
                text-align: center;
                width: 120px;
            }

            .add_course_btn{
                background: #494B50;
                border-radius: 2px;
                border: none;
                color: $purple;
                font: 600 12px/15.6px $Poppins;
                height: 32px;
                margin-bottom: 10px;
                outline: none;
                width: 120px;

                &:disabled{
                    opacity: .5;
                }
            }
        }

        #program_details_loading_container{
            display: flex;
            flex-direction: column;
            left: 50%;
            position: absolute;
            text-align: center;
            top: 45%;
            transform: translate(-50%, 50%);
            div{
                background-image: url("https://codingdojo_assets.s3.us-east-1.amazonaws.com/learn_platform/admin/global/table_loader.gif");
                background-size: cover;
                height: 30px;
                margin-bottom: 7px;
                margin: 0 auto;
                mix-blend-mode: lighten;
                width: 30px;
            }
            span{
                color: rgba(255, 255, 255, 0.88);
                font: normal 500 14px/130% $Poppins;
            }
        }
    }
}