$blue: #2C6BFF;
#reset_password_modal{
    max-width: 802px;
    width: 802px;
    .modal-content{
        border: none;
        .modal-header{
            border: none;
            padding-bottom: 23px;
            padding-top: 62px;
            position: relative;
            .modal-title{
                color: #152C61;
                font: normal 500 36px/54px 'Poppins', sans-serif;
                text-align: center;
                width: 100%;
            }
            button{
                background-color: transparent;
                border: none;
                outline: none;
                padding: 0px;
                position: absolute;
                right: 26px;
                top: 26px;
                .close_icon{
                    background-image: url("https://assets.codingdojo.com/learn_platform/global/close_icon_new.png");
                    display: inline-block;
                    height: 33px;
                    width: 33px;
                }
            }
        }
        .modal-body{
            padding-bottom: 60px;
            form#reset_password_form{
                margin: 42px auto 0px auto;
                text-align: center;
                width: 294px;
                .form-group{
                    align-items: center;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    margin-bottom: 40px;
                    &.error{
                        label{
                            color: #E1554C;
                        }
                        .form-control{
                            border-color: #E1554C;
                        }
                        span.error_message{
                            display: inline;
                        }
                    }
                    label{
                        color: #152C61;
                        font: normal 500 14px/21px 'Poppins', sans-serif;
                        margin-bottom: 0px;
                    }
                    span.error_message{
                        color: #E1554C;
                        display: none;
                        font: normal 300 14px/21px 'Poppins', sans-serif;
                        text-align: right;
                    }
                    .form-control{
                        border-radius: 0px;
                        border: none;
                        border-bottom: 2px solid $blue;
                        box-shadow: none;
                        font: normal 300 14px/21px 'Poppins', sans-serif;
                        outline: none;
                        padding: 19px 0px;
                        &::placeholder{
                            color: rgba(21, 44, 97, 0.4);
                        }
                    }
                }
                button[type=submit]{
                    background-color: $blue;
                    border-radius: 6px;
                    border: none;
                    font: normal 500 18px/27px 'Poppins', sans-serif;
                    height: 46px;
                    margin-top: 40px;
                    text-align: center;
                    width: 158px;
                }
            }
            #reset_password_success_container{
                .lock_icon{
                    align-items: center;
                    background: #EBF1FF;
                    border-radius: 6px;
                    display: flex;
                    height: 90px;
                    justify-content: center;
                    margin: 0px auto;
                    width: 90px;
                    &:before{
                        background-image: url("https://assets.codingdojo.com/learn_platform/user/login/lock_unlock_icon.png");
                        background-position: 1px -1px;
                        content: "";
                        height: 49px;
                        width: 40px;
                    }
                    &.is_locked{
                        &:before{
                            background-position: -39px -1px;
                        }
                    }
                }
                p{
                    color: #152C61;
                    font: normal normal 16px/24px 'Poppins', sans-serif;
                    margin: 41px 0 0px 0;
                    text-align: center;
                }
            }
        }        
    }        
}
@media only screen and (max-width: 768px){
    #reset_password_modal{
        .modal-content{
            .modal-header{
                .modal-title{
                    font-size: 16px;
                }
                
            }
            .modal-body{
                padding: 0px 30px;
                form#reset_password_form{
                    margin: 0px auto 0px auto;
                    width: 100%;
                }
            }
        }
    }
}