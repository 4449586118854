$Poppins: 'Poppins', sans-serif;

/* COLOR */
$white: #fff;

/* COLOR */
$dark_gray: #282828;
$black: #000;

#schedule_survey_modal{
    left: 50%;
    max-width: none;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 400px;

    .modal-content{
        font-family: $Poppins;
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.24), rgba(255, 255, 255, 0.24)), #121212;
        box-shadow: 0px 4px 2px rgba(0, 0, 0, 0.05);
        color: rgba(255, 255, 255, 0.88);
        border: none;

        .modal-body{
            align-items: center;
            display: flex;
            flex-direction: column;
            padding: 0px;
            .required_text{
                color: rgba(255, 255, 255, 0.4);
                font: 400 12px/14px "Poppins", sans-serif;
                position: absolute;
                right: 25px;
                top: 71px;
                &.is_audience{
                    top: 193px;
                    &.is_checked{
                        opacity: .5;
                    }
                }
                &.is_schedule{
                    top: 123px;
                }
            }

            .survey_wrapper{
                width: 100%;
                .survey_header{
                    align-items: center;
                    background: linear-gradient(0deg, rgba(255, 255, 255, 0.14), rgba(255, 255, 255, 0.14)), #121212;
                    display: flex;
                    height: 51px;
                    justify-content: flex-start;
                    padding: 0 10px 0 24px;

                    h2{
                        color: $white;
                        font: 700 14px/18px $Poppins;
                        margin: 0;
                    }

                    button{
                        align-items: center;
                        background: transparent;
                        border: none;
                        display: flex;
                        justify-content: center;
                        margin-left: auto;
                        outline: none;

                        .close_icon{
                            background-image: url("https://assets.codingdojo.com/learn_platform/global/close_icon_new.png");
                            background-position: -30px 0px;
                            display: inline-block;
                            height: 33px;
                            width: 33px;
                        }
                    }
                }
                .survey_details{
                    background: #282828;
                    padding: 20px 24px;

                    >p{
                        color: rgba(255, 255, 255, 0.88);
                        font: 14px/18px $Poppins;
                        font-style: normal;
                    }
                    .react-datepicker-wrapper{
                        width: 100%;
                        .survey_duration_button{
                            align-items: center;
                            background: #3E4146;
                            border-radius: 4px;
                            border: none;
                            color: #ffff;
                            display: flex;
                            outline: none;
                            padding: 10px 10px 10px 37px;
                            width: 100%;
                        
                            span{
                                font: 14px/18px $Poppins;
                                margin-right: 12px;
                            }
                            svg{
                                margin-right: 12px;
                            }
                        }
                    }


                    .input_details_block{
                        position: relative;
                        .info_text{
                            color: rgba(255, 255, 255, 0.4);
                            font-size: 11px;
                            font-style: italic;
                            margin-top: -16px;
                            &.error_message{
                                color: #F56B6B;
                                font-style: normal;
                            }
                        }
                        .react-datepicker__triangle{
                            display: none;
                        }
                        .react-datepicker__day--selected{
                            border-radius: 100%;
                        }
                        .react-datepicker__day--today{
                            background: $dark_gray;
                            border-radius: 100%;
                        }
                        .react-datepicker__day--in-range{
                            border-radius: 0px;
                            &.react-datepicker__day--range-start{
                                border-radius: 8px 0 0 8px !important;
                            }
                            &.react-datepicker__day--range-end{
                                border-radius:  0 8px 8px 0 ;
                            }
                        }
                        .react-datepicker__day--in-selecting-range{
                            border-radius: 0px;
                            &.react-datepicker__day--selected.react-datepicker__day--selecting-range-start{
                                border-radius: 8px 0 0 8px;
                            }
                        }

                        .react-datepicker{
                            background-color: #4B4B4B;
                            border: none;
                            display: flex;
                            .react-datepicker__day--in-range, .react-datepicker__day--in-selecting-range{
                                background: #2F2F2F !important;
                                &:hover{
                                    background: #5865F2 !important;
                                    border-radius:  0 8px 8px 0 ;
                                }
                            }
                            .react-datepicker__day--range-start, .react-datepicker__day--range-end, .react-datepicker__day--selected{
                                background: #5865F2 !important;
                            }
                            .react-datepicker__month-container{
                                .react-datepicker__header{
                                    background-color: #4B4B4B;
                                    border-bottom: none;
                                    padding: 20px 20px 0px;

                                    .react-datepicker__current-month{
                                        color: $white;
                                        font: 14px/18px $Poppins;
                                    }

                                    .react-datepicker__day-names{
                                        display: flex;
                                        justify-content: space-around;
                                        .react-datepicker__day-name{
                                            color: $white;
                                            font: 14px/18px $Poppins;
                                            margin-top: 16px;
                                        }
                                    }
                                }
                                .react-datepicker__month{
                                    margin: 0.4rem 21px;
                                    .react-datepicker__week{
                                        display: flex;
                                        .react-datepicker__day{
                                            align-items: center;
                                            color: $white;
                                            display: inline-flex;
                                            flex-wrap: wrap;
                                            font: 12px/16px $Poppins;
                                            height: 30px;
                                            justify-content: center;
                                            margin: 2px 0px;
                                            width: 100%;
                                            &:hover{
                                                background-color: $dark_gray;
                                            }

                                            &.react-datepicker__day--selected{
                                                background-color: $dark_gray;
                                            }

                                            &.react-datepicker__day--outside-month{
                                                opacity: .5;
                                            }
                                        }
                                        .react-datepicker__day--keyboard-selected{
                                            background: transparent;
                                        }
                                    }
                                }
                            }

                            .react-datepicker__navigation{
                                outline: none;
                                top: 20px;
                                .react-datepicker__navigation-icon{
                                    &::before{
                                        border-color: $white;
                                        opacity: .5;
                                    }

                                    &:hover{
                                        &::before{
                                            opacity: 1;
                                        }
                                    }
                                }
                            }

                            .react-datepicker__navigation--previous{
                                left: 17px;
                            }
                            
                            .react-datepicker__navigation--next{
                                right: 17px;
                            }
                        }
                        

                        .label_title{
                            color: rgba(255, 255, 255, 0.4);
                            display: block;
                            font: 600 12px/14px $Poppins;
                            margin-bottom: 10px;
                        }

                        input[type="text"], textarea, .react-dropdown-select{
                            background: rgba(204, 224, 255, 0.14);
                            border-radius: 4px;
                            border: none;
                            color: rgba(255, 255, 255, 0.88);
                            font: 400 14px/18px $Poppins;
                            height: 40px;
                            margin-bottom: 20px;
                            outline: none;
                            padding: 0 15px;
                            width: 100%;
                        }

                        textarea{
                            height: 120px;
                            margin-bottom: 0px;
                            padding: 15px;
                            resize: none;
                        }

                        &.disabled{
                            .react-dropdown-select{
                                pointer-events: none;
                            }
                        }
                        .date_picker{
                            input{
                                padding-left: 46px;
                            }
                        }
                        .react-datepicker__day--disabled{
                            opacity: .2;
                        }
                        .fa-calendar{
                            color: rgba(255, 255, 255, 0.58);
                            left: 10px;
                            position: absolute;
                            top: 11px;
                        }

                        >div{
                            .react-dropdown-select{
                                .react-dropdown-select-dropdown{
                                    width: 100%;
                                }
                            }
                        }
                    }

                    input[type="checkbox"]{
                        display: none;
                    }

                    .checkbox{
                        margin-bottom: 34px;
                        &:last-child{
                            margin-left: 40px;
                        }

                        .fa-check{
                            display: none;
                        }
                        input[type="checkbox"]:checked ~ label{
                            .checkbox_container{
                                background-color: #81A0EA;
                                .fa-check{
                                    color: #fff;
                                    display: inline;
                                    font-size: 10px;
                                }
                            }    
                        }
                        label{
                            align-items: flex-start;
                            display: flex;
                            margin-bottom: 0px;
                            cursor: pointer;
                            .checkbox_container{ 
                                align-items: center;
                                border-radius: 2px;
                                border: 1px solid #81A0EA;
                                cursor: pointer;
                                display: flex;
                                height: 18px;
                                justify-content: center;
                                margin: 2px 10px 0 0;
                                min-width: 18px;
                            }
                            >span{
                                color: rgba(255, 255, 255, 0.88);
                                font: 14px/18px $Poppins;
                                font-style: normal;
                            }                        
                        }
                    }
                    .selected_multi_item{
                        display: inline-block;
                        &:last-child{
                            margin-bottom: 15px;
                        }
                        .remove{
                            background: url("https://assets.codingdojo.com/learn_platform/admin/global/dropdown_icons.png") no-repeat -24px -4px;
                            border: none;
                            cursor: pointer;
                            height: 12px;
                            margin-left: 9.17px;
                            margin-top: -1px;
                            outline: none;
                            position: absolute;
                            right: 10px;
                            top: 10px;
                            vertical-align: middle;
                            width: 12px;
                        }
                    }
                }

                .survey_footer{
                    align-items: center;
                    background: #333333;
                    display: flex;
                    height: 79px;
                    justify-content: flex-end;

                    button{
                        background: transparent;
                        border-radius: 2px;
                        border: none;
                        color: $white;
                        font: 600 14px/18px $Poppins;
                        height: 40px;
                        min-width: 64px;
                        outline: none;

                        &.next_btn{
                            background: rgba(204, 224, 255, 0.14);
                            color: #81A0EA;
                            margin: 0 24px 0 20px;
                        }
                    }
                }
            }
        }
    }
}