$Poppins: 'Poppins', sans-serif;

#roster_guidelines_modal{
    left: 50%;
    max-width: none;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 960px;

    .modal-content{
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.24), rgba(255, 255, 255, 0.24)), #121212;
        border-radius: 6px;
        box-shadow: 0px 4px 2px rgba(0, 0, 0, 0.05);
        color: rgba(255, 255, 255, 0.88);

        .modal-header{
            border: none;
            justify-content: center;
            padding: 27px 23px 7px 23px;
            position: relative;

            h4{
                font-family: 'Poppins', sans-serif;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 16px;
                margin-bottom: 0px;
            }

            button{
                background-color: transparent;
                background-image: url("https://codingdojo_assets.s3.us-east-1.amazonaws.com/learn_platform/admin/edit_course/admin_edit_course_sprite.png");
                background-position: -65px 0px;
                border: none;
                height: 15px;
                outline: none;
                padding: 0px;
                position: absolute;
                right: 22px;
                top: 17px;
                width: 15px;
            }
        }

        .modal-body{
            height: 650px;
            overflow: auto;
            scroll-behavior: smooth;

            ul{
                li{
                    align-items: center;
                    display: flex;
                    flex-direction: column;
                    margin-bottom: 20px;
                    &#step_1{
                        div{
                            background-image: url(./../../../../assets/images/admin/rostering/guidelines_1.gif);
                        }
                    }
                    &#step_2{
                        div{
                            background-image: url(./../../../../assets/images/admin/rostering/guidelines_2.gif);
                        }
                    }
                    &#step_3{
                        div{
                            background-image: url(./../../../../assets/images/admin/rostering/guidelines_3.gif);
                        }
                    }

                    p{
                        color: rgba(255, 255, 255, 0.58);
                        font-family: $Poppins;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 140%;
                        margin-bottom: 20px;
                    }

                    div{
                        width: 800px;
                        height: 450px;
                        background-color:  #000;
                    }

                    a{
                        background-color: transparent;
                        border: none;
                        margin-top: 20px;
                        outline: none;

                        svg{
                            color: #2E2E2E;
                            font-size: 30px;
                        }
                    }
                }
            }
        }
    }
}