#quiz_performance_container{
    -khtml-transition: transform .2s;
    -moz-transition: transform .2s;
    -ms-transition: transform .2s;
    -o-transition: transform .2s;
    -webkit-transition: transform .2s;
    align-items: center;
    background: #fff;
    border-radius: 6px;
    border: 2px solid transparent;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
    display: inline-flex;
    flex-direction: column;
    font: normal 400 12px/18px 'Poppins', sans-serif;
    margin-right: 20px;
    min-height: 406px;
    padding: 30px 50px;
    transition: transform .2s;
    width: 412px;
    &:hover{
        -khtml-transform: scale(1.02);
        -moz-transform: scale(1.02);
        -ms-transform: scale(1.02);
        -o-transform: scale(1.02);
        -webkit-transform: scale(1.02);
        box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.05);
        transform: scale(1.02);     
    }
    &.is_active{
        border: 2px solid #2C6BFF;
    }
    &:last-child{
        margin-right: 0px;
    }
    h3{
        font: normal 500 16px/24px 'Poppins', sans-serif;
        margin-bottom: 20px;
    }
    p{
        color: #333333;
        font: normal 400 12px/18px 'Poppins', sans-serif;
        margin-bottom: 80px;
        max-width: 179px;
        text-align: center;
    }
    #quiz_performance_rating_container {
        border-radius: 100%;
        max-height: 160px;
        max-width: 160px;
        .CircularProgressbar-path {
            stroke: #2C6BFF;
        }
        .CircularProgressbar-trail {
            stroke: #E3FFE8;
        }
        .CircularProgressbar-text{
            font: normal 500 16px/39px "Poppins", sans-serif;
            fill: #000;
        }
    }
}
.is_dark_mode{
    #quiz_performance_rating_container {
        .CircularProgressbar-text{
            fill: #fff !important;
        }
    }
}   