$Poppins: 'Poppins', sans-serif;
$blue: #2C6BFF;
$white: #fff;

#curriculum_management_unit_table{
    border-radius: 4px;
    height: 100%;
    margin-top: 0px;
    overflow: auto;
    position: relative;
    width: 100%;
    #table_loading_container{
        align-items: center;
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.14), rgba(255, 255, 255, 0.14)), #121212;
        display: flex;
        flex-direction: column;
        left: 50%;
        margin-left: 135px;
        position: fixed;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 50px;

        div{
            background-image: url("https://assets.codingdojo.com/learn_platform/admin/global/table_loader.gif");
            background-size: cover;
            height: 30px;
            margin-bottom: 7px;
            mix-blend-mode: lighten;
            width: 30px;
        }

        span{
            color: rgba(255, 255, 255, 0.88);
            font-family: $Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 130%;
        }
    }
    *::-webkit-scrollbar {
        height: 9px;
        width: 9px;
    }
    .sticky-table{
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.14), rgba(255, 255, 255, 0.14)), #121212;
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.07);
        width: 100%;
        &.table_loading{
            .sticky-table-table{
                height: 100%;
                #no_results_found{
                    align-items: center;
                    justify-content: center;
                    width: 50px;
                }
            }
        }
        .checkbox{
            display: flex;
            justify-content: center;
            input[type="checkbox"]{
                display: none;
            }
            .fa-check{
                display: none;
            }
            input[type="checkbox"]:checked ~ label{
                .checkbox_container{
                    background-color: $blue;
                    .fa-check{
                        color: $white;
                        display: inline;
                        font-size: 10px;
                    }
                }    
            }
            label{
                align-items: center;
                cursor: pointer;
                display: flex;
                margin-bottom: 0px;
                .checkbox_container{ 
                    align-items: center;
                    background: $white;
                    border-radius: 4px;
                    border: 1px solid transparent;
                    cursor: pointer;
                    display: flex;
                    height: 15px;
                    justify-content: center;
                    width: 15px;
                }                       
            }
        }
        .sticky-table-table{
            background: linear-gradient(0deg, rgba(255, 255, 255, 0.24), rgba(255, 255, 255, 0.24)), #121212;
            width: 100%;
        }
        .sticky-table-row{
            /* Table Head */
            &:nth-child(1){
                .sticky-table-cell{
                    background: linear-gradient(0deg, rgba(255, 255, 255, 0.24), rgba(255, 255, 255, 0.24)), #121212;
                    color: rgba(255, 255, 255, 0.6);
                    cursor: pointer;
                    font: normal 600 12px/130% $Poppins;
                    &:first-child{
                        cursor: unset;
                    }
                    &:nth-child(2){
                        width: unset;
                    }
                    &:last-child{
                        cursor: unset;
                        width: unset;
                    }
                    span{
                        display: inline-block;
                        padding-bottom: 7px;
                        vertical-align: bottom;
                    }
                    .sort_icon_container{
                        cursor: pointer;
                        display: inline-flex;
                        flex-direction: column;
                        margin-left: 10px;
                        svg{
                            color: rgba(255, 255, 255, 0.6);
                            height: 15px;
                            width: 10px;
                            &:first-child{
                                transform: translateY(3px);
                            }

                            &:last-child{
                                transform: translateY(-3px);
                            }
                            
                            &.fa-caret-up.sort_desc{
                                color: #81A0EA !important;
                            }

                            &.fa-caret-down.sort_asc{
                                color: #81A0EA !important;
                            }
                        }
                    }
                }
            }
            /* Table Body */
            &:nth-child(n+2){
                background: #3D3D3D ;
                height: 86px;

                &:nth-child(even){
                    .sticky-table-cell{
                        background: #3D3D3D ;
                    }
                }
                &:nth-child(odd){
                    background: #444444 ;
                    .sticky-table-cell{
                        background: #444444 ;
                    }
                }
                .sticky-table-cell{
                    background: #333333;
                    border-top: 1px solid #5D5D5D;
                    color: $white;
                    font: normal 400 14px/130% $Poppins;
                    &:nth-child(2){
                        cursor: pointer;
                        .course_name{
                            align-items: center;
                            color: $white;
                            display: flex;
                            margin-bottom: 6px;
                            font: normal 400 14px/130% $Poppins;
                            span{
                                background: #595959;
                                border-radius: 20px;
                                color: rgba(255, 255, 255, 0.8);
                                font: normal 400 8px/130% $Poppins;
                                margin-left: 20px;
                                padding-block: 4px;
                                padding-inline: 12px;
                                text-align: center;
                            }
                        }
                        .course_description{
                            align-items: center;
                            color: rgba(255, 255, 255, 0.5);
                            display: flex;
                            font: normal 400 12px/130% $Poppins;
                            margin-bottom: 6px;
                        }
                    }
                    &:nth-child(4){
                        text-transform: capitalize;
                    }
                    &:last-child{
                        position: relative;
                    }
                    img{
                        width: 22px;
                        position: absolute;
                        top: 31px;

                    }
                    p{
                        padding-left: 45px;
                        margin-bottom: 7px;
                    }
                    .unit_name{
                        font: normal 400 14px/18.2px "Poppins", sans-serif;
                    }
                    .unit_description{
                        font: normal 400 12px/15px "Poppins", sans-serif;
                        margin-bottom: 0px;
                        opacity: .5;
                    }
                    .unit_origin{
                        background: #595959;
                        border-radius: 20px;
                        font: normal 400 8px/10px "Poppins", sans-serif;
                        margin-left: 19px;
                        opacity: .8;
                        padding: 4px 10px;
                    }
                    
                }
            }
        }
    }
}

#curriculum_management_unit_popover{
    background: #4B4B4B;
    border-radius: 2px;
    border: 1px solid rgba(204, 224, 255, 0.14);
    right: -30px !important;
    width: 247px;
    z-index: 1 !important;
    .popover-body{
        color: #E5E5E5;
        height: 129px;
        padding: 6px 10px 0px 10px;

        ul{
            display: flex;
            flex-direction: column;
            height: 100%;
            list-style-type: none;
            margin-bottom: 0;
            padding-left: 0;
            width: 100%;
            li{
                cursor: pointer;
                flex: 1;
                font: normal 400 14px/130% $Poppins;
                padding-left: 30px;
                padding-top: 5px;
                position: relative;
                
                
                &::before{
                    background: url("https://assets.codingdojo.com/learn_platform/admin/curriculum_management/admin_curriculum_management_sprite.png") no-repeat;
                    content: "";
                    display: inline-block;
                    height: 20px;
                    left: 0px;
                    margin-right: 13px;
                    position: absolute;
                    top: 4px;
                    width: 20px;
                }
                &:nth-child(1){
                    &::before{
                        background-position: -361px -20px;
                    }
                }
                &:nth-child(2){
                    &::before{
                        background-position: -84px -20px;
                    }
                }
                &:nth-child(3){
                    &::before{
                        background-position: -16px -20px;
                    }
                }
                &:nth-child(4){
                    &::before{
                        background-position: -51px -22px;
                    }
                }
            }
        }
    }
    .arrow{
        display: none;
    }
}