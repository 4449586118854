.course_item{
    margin-bottom: 18px;
    &.inactive{
        opacity: .3;
    }

    &.completed{
        position: relative;
        &:before{
            background-image: url("https://assets.codingdojo.com/learn_platform/global/check_circle.png");
            background-repeat: no-repeat;
            content: "";
            display: inline-block;
            height: 26px;
            position: absolute;
            right: 42px;
            top: 45px;
            width: 26px;
            z-index: 1;
        }
    }

    &:nth-last-child(-1n+8){
        margin-bottom: 0px;
    }
    button{
        align-items: center;
        background-color: transparent;
        border: none;
        display: flex; 
        flex-direction: column;
        outline: none !important;
        overflow: hidden;
        text-decoration: none;          
        width: 100%;
    }
    img{
        height: 65px;
        width: 57px;
    }
    span{     
        color: #152C61;   
        font-family: 'Poppins', sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: normal;
        line-height: 24px;
        margin: 12px 0px 0px 0px;
        text-align: center;   
        width: 100%;
        word-wrap: break-word;
    }
}