$blue: #2C6BFF;
$orange: #F2994A;
$red: #E1554C;
$green: #4CE164;

#core_assignment_completion_rate_container{
    background: #fff;
    border-radius: 6px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
    min-height: 570px;
    padding: 28px 24px;
    h3{
        display: inline-block;
        font: normal 500 16px/24px 'Poppins', sans-serif;
        margin-bottom: 24px;
        margin-right: 22px;
        vertical-align: top;
    }
    #core_assignment_completion_rate_table{
        margin-bottom: 20px;
        thead{
            tr{
                th{
                    &:first-child{
                        width: 218px;
                    }
                    &:nth-child(2){
                        width: 337px;
                    }
                    &:nth-child(3){
                        width: 240px;
                    }
                    &:nth-child(4){
                        width: 288px;
                    }
                    &:nth-child(5){
                        width: 158px;
                    }

                }
            }
        }
        tbody{
            tr{
                td{
                    color: #333333;
                    a{
                        color: #2C6BFF;
                    }

                    /* No submission */ 
                    .assignment_status_1{
                        color: #152C61;
                    }

                    /* On-time */ 
                    .assignment_status_2{
                        color: #38903C;
                    }

                    /* Out-of-schedule */ 
                    .assignment_status_3{
                        color: #EF7E1B;
                    }

                    /* Pending Review */ 
                    .assignment_status_4{
                        color: #E1554C;
                    }
                }
            }
        }
    }
}
.is_dark_mode{
    #core_assignment_completion_rate_container{
        #core_assignment_completion_rate_table{
            tbody{
                tr{
                    td{
                        color: #E5E5E5;

                        /* No submission */ 
                        .assignment_status_1{
                            color: #81A0EA;
                        }
    
                        /* On-time */ 
                        .assignment_status_2{
                            color: #05DA80;
                        }
    
                        /* Out-of-schedule */ 
                        .assignment_status_3{
                            color: #EB9040;
                        }

                        /* Pending Review */ 
                        .assignment_status_4{
                            color: #E1554C;
                        }
                        
                        a{
                            color: #81A0EA;
                        }
                    }
                }
            }
        }
    }
}