$Poppins: 'Poppins', sans-serif;

#major_stacks_wrapper, #minor_stacks_wrapper{
    .input_details_block{
        margin-bottom: 12px;

        &:last-child{
            margin-bottom: 0;
        }

        .label_title{
            color: rgba(255, 255, 255, 0.58);
            font: 12px/16px $Poppins;
            font-style: italic;
            font-weight: 600;
        }

        p{
            color: rgba(255, 255, 255, 0.88);
            font-family: "Poppins";
            font-size: 12px;
            font-style: normal;
            font-weight: 300;
            margin-top: 10px;
            line-height: 140%; 
        }
    }

    .add_stack_btn{
        align-items: center;
        background: rgba(255, 255, 255, 0.18);
        border-radius: 2px;
        border: none;
        color: rgba(255, 255, 255, 0.58);
        display: flex;
        font-weight: 600;
        font: 12px/16px $Poppins;
        height: 40px;
        justify-content: center;
        margin-top: 20px;
        outline: none;
        width: 100%;

        svg{
            font-size: 15px;
            margin-right: 8px;
        }
    }
}