#default_content_block{
    background: #fff;
    border-radius: 6px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
    padding: 75px 0px;
    text-align: center;
    img{
        margin-bottom: 25px;
    }
    p{
        font: normal 500 16px/24px 'Poppins', sans-serif;
    }
}