div.belt_exam_banner{
    align-items: center;
    background: #FEEFC6;
    border-radius: 6px;
    display: flex;
    justify-content: space-between;
    margin: 17px 0px 15px 0px;
    overflow: hidden;
    padding: 11px 16px 11px 13px;
    position: relative;
    h6{
        color: #F2994A;
        font: normal 700 16px/24px 'Poppins';
        margin-bottom: 0px;
    }
    span.uniform_icon{
        background-image: url('https://assets.codingdojo.com/learn_platform/user/dashboard/dashboard_redesign_icons.png');
        background-position: -463px -1px;
        display: inline-block;
	    height: 39px;
        width: 52px;
    }
    button.take_exam_button{
        background: #F2994A;
        border-radius: 6px;
        border: 2px solid rgba(242, 153, 74, 0.2);
        color: #F8FAFF;
        font: normal 600 15px/22px 'Poppins';
        padding: 4px 10px;
        text-align: center;
    }
    span.circles{
        background: #F2994A;
        border-radius: 50%;
        height: 50px;
        opacity: 0.2;
        position: absolute;
        width: 50px;
    }
    #rating_star_ribbon{
        display: none;
        visibility: hidden;
    }
    span:nth-child(6){
        left: 351px;
        top: 42px;
    }
    span:nth-child(5){
        left: 423px;
        top: -38px;
    }
}