$Poppins: "Poppins", sans-serif;

#admin_student_progress_revamp_program_table{
    #table_navigation{
        background:#121212;
        display: flex;
        list-style: none;
        margin: 0px;
        padding: 0px;
        
        li{
            align-items: center;
            display: flex;

            &.active{
                #asignment_filter_dropdowns{
                    opacity: 1;
                    pointer-events: initial;
                }
            }

            #asignment_filter_dropdowns{
                opacity: .4;
                pointer-events: none;
            }

            &:first-child{
                >button{
                    margin-right: 15px;
                }
            }

            &.active{
                button{
                    border-bottom: 2px solid #81A0EA;
                    color: #81A0EA;
                    font-weight: 600;
                }

                .checkbox_label{
                    &:after{
                        color: rgba(255, 255, 255, 0.88);
                    }
                }
            }
            >button{
                background-color: transparent;
                border-bottom: 2px solid transparent;
                border: none;
                color: rgba(255, 255, 255, 0.48);
                display: flex;
                font-family: $Poppins;
                font-size: 12px;
                font-style: normal;
                font-weight: normal;
                justify-content: space-between;
                line-height: 130%;
                outline: none;
                padding: 8px 16px;

                span{
                    background-image: url("https://assets.codingdojo.com/learn_platform/admin/student_progress/table_navigation_icons.png");
                    background-repeat: no-repeat;
                    display: flex;
                    height: 16px;
                    margin-right: 12px;
                    width: 16px;
                }

                .checklist_icon{
                    background-position: -4px -4px;
                }

                .calendar_icon{
                    background-position: -28px -4px;
                }
            }
            /* checkbox */
            input[type="checkbox"]{
                display: none;
                &:checked ~ span{
                    background: #81A0EA;
                }
                &:checked ~ span{
                    svg{
                        opacity: 1;
                    }
                }
            }
            .checkbox_label{
                cursor: pointer;
                display: block;
                margin-bottom: 0px;
                margin-right: 20px;
                position: relative;

                &:after{
                    color: rgba(255, 255, 255, 0.48);
                    content: attr(data-text);
                    font-family: $Poppins;
                    font-size: 12px;
                    margin-left: 6px;
                    vertical-align: middle;
                }
                &[disabled]{
                    cursor: not-allowed;
                    opacity: .3;
                }
                span{
                    align-items: center;
                    background: rgba(204, 224, 255, 0.14);
                    border-radius: 4px;
                    cursor: pointer;
                    display: inline-flex;
                    height: 15px;
                    justify-content: center;
                    vertical-align: middle;
                    width: 15px;

                    svg{
                        font-size: 9px;
                        left: 3px;
                        opacity: 0;
                        position: absolute;
                        top: 6px;
                    }
                }
            }
            #asignment_filter_dropdowns{
                display: flex;
                >div{
                    margin-right: 12px;
                }

                .react-dropdown-select{
                    background-color: transparent;
                    border: none !important;
                    margin: 0px;
                    max-width: inherit;
                    min-height: 22px !important;
                    padding: 0px !important;

                    &[aria-expanded="true"]{
                        .react-dropdown-select-dropdown-handle{
                            margin-left: 0px;
                            margin-top: -2px;
                        }
                    }

                    .react-dropdown-select-content{
                        color: rgba(255, 255, 255, 0.88);
                        font-family: $Poppins;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: normal;
                        margin-right: 4px;
                        
                        .react-dropdown-select-input{
                            display: none;
                        }
                    }

                    .react-dropdown-select-dropdown-handle{
                        color: #81A0EA;
                        margin: 0px;
                    }

                    .react-dropdown-select-dropdown{
                        background: linear-gradient(0deg, rgba(255, 255, 255, 0.24), rgba(255, 255, 255, 0.24)), #121212;
                        border-radius: 6px;
                        border: 1px solid #81A0EA;
                        box-sizing: border-box;
                        min-width: 160px;
                        
                        span{
                            border: none;
                            color: rgba(255, 255, 255, 0.88);
                            font-family: $Poppins;
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 18px;
                            padding: 10px 15px;
                            position: relative;

                            &.react-dropdown-select-item-selected{
                                background-color: transparent;

                                &:after{
                                    color: #81A0EA;
                                    content: "\2714";
                                    position: absolute;
                                    right: 16px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .sticky-table{
        height: calc(100% - 32px);
        .sticky-table-row:nth-child(n+2) .sticky-table-cell:nth-child(2) {
            cursor: unset;

            &.course_name{
                cursor: pointer;
            }
        }

        .sticky-table-row:nth-child(n+2) .sticky-table-cell:nth-child(1) {
            cursor: unset;

            &.course_name{
                cursor: pointer;
            }
        }

        .sticky-table-table{
            .sticky-table-row{
                /* Table Head */ 
                &:nth-child(1){
                    .sticky-table-cell{
                        background: linear-gradient(0deg, rgba(255, 255, 255, 0.24), rgba(255, 255, 255, 0.24)), #121212;
                    
                        @media screen and (min-width: 1770px) {
                            width: 11% !important;
                        }
                    }
                }

                .sticky-table-cell{
                    background: #333333;
                    text-align: left;
                }
            }
        }
    }
}