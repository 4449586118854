#search_modal{
    height: 666px;
    max-width: 791px;
    min-height: 666px;
    width: 791px;
    .modal-content{
        background: #FFFFFF;
        border-radius: 6px;
        box-shadow: 0px 4px 2px rgba(0, 0, 0, 0.05);
        height: 100%;
        .modal-header{
            border: none;
            justify-content: center;
            padding: 44px 40px 23px 40px;
            position: relative;
            button{
                background-color: transparent;
                border: none;
                outline: none;
                padding: 0px;
                position: absolute;
                right: 26px;
                top: 26px;
                .close_icon{
                    background-image: url("https://assets.codingdojo.com/learn_platform/global/close_icon_new.png");
                    display: inline-block;
                    height: 33px;
                    width: 33px;
                }
            }
        }
        .modal-body{
            padding: 0px 75px;
            .loading_icon{
                display: block;
                height: 80px;
                margin: 0px auto;
                width: 80px;
            }
            form{
                input{
                    background: #FFFFFF;
                    border-radius: 25px;
                    border: 2px solid #E6E6E6;
                    box-sizing: border-box;
                    color: #152C61;   
                    font: normal normal 14px/21px 'Poppins', sans-serif;
                    height: 46px;
                    padding: 5px 20px;              
                }
            }
            .nav-tabs{
                border: none;
                flex-direction: row;
                margin-bottom: 34px;
                margin-top: 37px;
                a{
                    border: none;
                    color: #000000;
                    font: normal normal 16px/24px 'Poppins', sans-serif;
                    margin-right: 55px;
                    opacity: 0.3;
                    outline: none;
                    padding: 0px 0px 1px 0px;
                    &.active{
                        border-bottom: 2px solid #2C6BFF;
                        color: #152C61;
                        font-size: 16px;
                        font-weight: 600;
                        line-height: 24px;
                        opacity: 1;
                    }
                }
            }
            .tab-content{
                .tab-pane{
                    >p{
                        color: #152C61;
                        font: normal 300 14px/21px 'Poppins', sans-serif;
                        text-align: center;
                    }
                }
            }
            #chapters_list, #courses_list{
                height: 424px;
                overflow: auto;
                *{
                    text-decoration: none;
                }
                li{
                    align-items: center;
                    background: #FFFFFF;
                    border-radius: 2px;
                    border: 2px solid #DCE6FF;
                    box-sizing: border-box;
                    display: flex;
                    margin-bottom: 10px;
                    min-height: 120px;
                    padding: 14px 20px 20px 20px;
                    position: relative;
                    img{
                        height: 52px;
                        margin-right: 30px;
                        width: 47px;
                    }
                    span{
                        color: #000000;
                        font: normal 500 20px/30px 'Poppins', sans-serif;
                    }
                }
            }
            #modules_list{
                height: 424px;
                overflow: auto;
                >li{
                    background: #FFFFFF;
                    border-radius: 2px;
                    border: 2px solid #DCE6FF;
                    box-sizing: border-box;
                    margin-bottom: 10px;
                    padding: 14px 20px 20px 20px;
                    position: relative;
                    &:last-child{
                        margin-bottom: 0px;
                    }
                    form{
                        position: absolute;
                        right: 20px;
                        top: 14px;
                        button#bookmark_module_btn{
                            background-color: transparent;
                            background-image: url("https://assets.codingdojo.com/learn_platform/user/courses/bookmark_and_unbookmark_icon.png");
                            background-repeat: no-repeat;
                            border: none;
                            height: 30px;
                            outline: none;
                            width: 20px;
                            &.bookmarked_module_fill{
                                background-position: -21px 0px;
                            }
                        }
                    }
                    a{
                        align-items: flex-start;
                        display: flex;
                        text-decoration: none;
                        .left_container{
                            margin-right: 20px;
                            min-width: 182px;
                            h5{
                                color: #152C61;
                                font: normal 500 20px/30px 'Poppins', sans-serif;
                                margin-bottom: 27px;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap; 
                            }
                            .icons_container{
                                align-items: center;
                                display: flex;
                                span{
                                    align-items: center;
                                    background-color: #EEC10C;
                                    border-radius: 100%;
                                    display: flex;
                                    display: flex;
                                    height: 30px;
                                    justify-content: center;
                                    margin-right: 25px;
                                    width: 30px;
                                    &.todo{
                                        background-color: #B286C3;
                                        .icon{
                                            background-position: -73px 0px;
                                        }
                                    }
                                    &.lesson{
                                        background-color: #EEC10C;
                                        .icon{
                                            background-position: -2px 0px;
                                        }
                                    }
                                    &.chapter_survey{
                                        background-color: #E06666;
                                        .icon{
                                            background-position: -20px 0px;
                                        }
                                    }
                                    &.quiz{
                                        background-color: #F78723;
                                        .icon{
                                            background-position: -54px 0px;
                                        }
                                    }
                                    .icon{
                                        background-image: url("https://assets.codingdojo.com/learn_platform/global/icons.png");
                                        background-repeat: no-repeat;
                                        height: 18px;
                                        width: 18px;
                                        &.lesson{
                                            background-position: -73px 0px;
                                        }
                                        &.chapter_survey{
                                            background-position: -73px 0px;
                                        }
                                    }
                                }
                                img{
                                    width: 28px;
                                    height: 33px;
                                }
                            }                        
                        }
                        .right_container{
                            align-content: space-between;
                            display: flex;
                            flex-wrap: wrap;
                            min-height: 90px;
                            width: 394px;
                            ul{
                                display: flex;
                                padding-right: 35px;
                                li{
                                    color: #152C61;
                                    font: normal 300 14px/21px 'Poppins', sans-serif;
                                    margin-right: 15px;
                                    padding-right: 10px;
                                    position: relative;
                                    &:last-child{
                                        margin-right: 0px;
                                        padding-right: 0px;
                                        &:after{
                                            display: none;
                                        }
                                    }
                                    &:after{
                                        background-image: url("https://assets.codingdojo.com/learn_platform/global/small_arrow_right.png");
                                        content: "";
                                        display: inline-block;
                                        height: 11px;
                                        position: absolute;
                                        right: -6px;
                                        top: 5px;
                                        width: 7px;
                                    }
                                }
                            }
                            p{
                                color: #152C61;
                                font: normal 300 14px/21px 'Poppins', sans-serif;
                                margin-bottom: 0px;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap; 
                            }
                        }
                    }
                }
            }
        }
    }
    @media only screen and (max-width: 768px){
        .modal-content{
            .modal-body{
                padding: 0px 20px;
                .loading_icon{
                    height: 40px;
                    width: 40px;
                }
                .nav-tabs{
                    justify-content: center;
                    margin-bottom: 45px;
                    margin-top: 46px;
                    a{
                        margin-right: 20px;
                    }
                }
                #chapters_list, #courses_list, #modules_list{
                    height: calc(100vh - 232px);
                    margin-bottom: 0px;
                }
                #chapters_list, #courses_list{
                    li{
                        margin-bottom: 17px;
                        min-height: 82px;
                        padding: 16px 24px 16px 14px;
                        a{
                            padding-left: 64px;
                        }
                        img{
                            left: 13px;
                            position: absolute;
                            top: 23px;
                        }
                        span{
                            font: normal 500 16px/24px 'Poppins', sans-serif;
                        }
                    }
                }
                #modules_list{
                    overflow-x: hidden;
                    > li{
                        padding: 14px 13px 20px 13px;
                        form{
                            right: 14px;
                            top: 14px;
                        }
                        a{
                            .left_container{
                                min-width: unset;
                                width: 91px;
                                h5{
                                    font: normal 500 16px/24px 'Poppins', sans-serif;
                                    margin-bottom: 23px;
                                }
                            }
                            .right_container{
                                width: calc(100% - 132px);
                                ul{
                                    flex-direction: column;
                                    flex-wrap: wrap;
                                }
                            }

                        }
                    }
                }
                form{
                    input{
                        background: #F8FAFF;
                    }
                }
            }
        }
    }
}