$Poppins: 'Poppins', sans-serif;
#manage_program_and_stack_popover, #manage_stack_and_version_popover{
    z-index: 10000;
    .dropdown_search_container{
        border-bottom: 1px solid #E5E7EB;
        padding: 20px 20px 20px 20px;
        input{
            background: rgba(204, 224, 255, 0.14);
            border-radius: 4px;
            border: none !important;
            color: #fff;
            font-family: $Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: normal;
            min-height: 32px;
            outline: none;
            padding: 0px 12px;
            width: 100%;
    
            &::placeholder {
                color: #A4B0C0;
                opacity: 1;
            }
            
            &:-ms-input-placeholder {
                color: #A4B0C0;
            }
            
            &::-ms-input-placeholder {
                color: #A4B0C0;
            }
        }
    }
    
    .dropdown_menu{
        max-height: 327px;
        overflow: auto;
        padding: 24px 26px;
        position: relative;
    
        .no_results_found{
            align-items: center;
            color: rgba(255, 255, 255, 0.4);
            display: flex;
            font-family: $Poppins;
            font-size: 12px;
            font-style: normal;
            font-weight: normal;
            justify-content: center;
            line-height: 140%;
            padding: 10px 0px;
        }
        p{
            color: rgba(255, 255, 255, 0.4);
        }
        .dropdown_item{
            align-items: flex-start;
            color: rgba(255, 255, 255, 0.88);
            cursor: pointer;
            display: flex;
            font-family: $Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            justify-content: space-between;
            margin-bottom: 20px;
            min-height: auto;
    
            &.active{
                span{
                    color: #81A0EA;
                    font-weight: 500;
                }
            }
    
            &:last-child{
                margin-bottom: 0px;
            }
    
            input{
                display: none;
            }
    
            .checkbox{
                margin-left: 10px;
                margin-top: 2px;
    
                .fa-check{
                    display: none;
                }
                input[type="checkbox"]:checked ~ label{
                    .checkbox_container{
                        background-color: #81A0EA;
                        .fa-check{
                            color: #fff;
                            display: inline;
                            font-size: 10px;
                        }
                    }    
                }
                label{
                    align-items: center;
                    cursor: pointer;
                    display: flex;
                    margin-bottom: 0px;
                    .checkbox_container{ 
                        align-items: center;
                        background: #FFFFFF;
                        border-radius: 4px;
                        border: 1px solid #A4B0C0;
                        cursor: pointer;
                        display: flex;
                        height: 16px;
                        justify-content: center;
                        width: 16px;
                    }
                    >span{
                        color: #DEE5EF;
                        font-family: $Poppins;
                        font-size: 18px;
                        font-style: normal;
                        font-weight: bold;
                        letter-spacing: -0.01em;
                        line-height: 120%;
                    }                        
                }
            }
    
            span{
                flex: 1;
                position: relative;
    
                &:after{
                    content: "";
                    display: flex;
                    height: 100%;
                    position: absolute;
                    right: -26px;
                    top: 0;
                    width: 25px;
                }
            }
        }
    }
}
#manage_stack_and_version_popover {
    &.bs-popover-auto[x-placement^=right]>.arrow::after, &.bs-popover-right>.arrow::after{
        border-right-color: #4b4b4b !important;
    }
    &.bs-popover-auto[x-placement^=top]>.arrow::after, &.bs-popover-top>.arrow::after{
        border-top-color: #4b4b4b !important;
    }
    &.is_open_version{
        min-width: 483px;
        width: 483px;
        .popover-body{
            .dropdown_content{
                ul{
                    display: inline-flex;
                    flex-direction: column;
                }
            }
        }
    }
    .popover-body{
        padding-top: 0px;
        .dropdown_search_container{
            input{
                text-align: center;
            }
        }

        .dropdown_content{
            display: flex;
            padding: 13px 0px 0px;
            
            .dropdown_menu{
                overflow-x: hidden;
                padding: 0px;
                width: 233px;
                .dropdown_item{
                    margin-bottom: 0px;
                    padding: 10px 10px 10px 20px;
                    position: relative;
                    span{
                        padding-right: 50px;
                        word-break: break-all;
                    }
                    &.active{
                        background: rgba(204, 224, 255, 0.14);
                        &:after{
                            background-position: -92px 0px;
                        }
                    }
                    &.has_version{
                        &:before{
                            background-image: url(https://codingdojo_assets.s3.us-east-1.amazonaws.com/learn_platform/admin/workspace_management/curriculum_tab_icons.svg);
                            background-position: -126px 0px;
                            bottom: 0px;
                            content: "";
                            display: inline-block;
                            height: 18px;
                            margin: auto;
                            position: absolute;
                            right: 32px;
                            top: 0px;
                            vertical-align: top;
                            width: 22px;
                            
                        }
                    }
                    &:after{
                        background-image: url(https://codingdojo_assets.s3.us-east-1.amazonaws.com/learn_platform/admin/workspace_management/curriculum_tab_icons.svg);
                        background-position: -58px 0px;
                        bottom: 0px;
                        content: "";
                        display: inline-block;
                        height: 18px;
                        margin: auto;
                        position: absolute;
                        right: 17px;
                        top: 0px;
                        vertical-align: top;
                        width: 23px;
                    }
                }
        
            }
            ul{
                background: rgba(204, 224, 255, 0.14);
                border-left: 1px solid rgba(204, 224, 255, 0.14);
                display: none;
                list-style: none;
                margin: 0px;
                max-height: 328px;
                overflow-y: auto;
                padding: 10px 0px 0px;
                width: 255px;
                li{
                    color: rgba(255, 255, 255, 0.88);
                    font-family: $Poppins;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: normal;
                    padding: 13px 43px 13px 19px;
                    position: relative;
                    &.is_active{
                        &:before{
                            background-image: url(https://codingdojo_assets.s3.us-east-1.amazonaws.com/learn_platform/admin/workspace_management/curriculum_tab_icons.svg);
                            background-position: -126px 0px;
                            content: "";
                            display: inline-block;
                            height: 18px;
                            position: absolute;
                            right: 11px;
                            vertical-align: top;
                            width: 23px;
                        }
                    }
                }
            }
        }
    }
}