$blue: #2C6BFF;
#retrieve_password_modal{
    max-width: 791px;
    width: 791px;
    .modal-content{
        border: none;
        .modal-header{
            border: none;
            padding-bottom: 23px;
            padding-top: 62px;
            position: relative;
            .modal-title{
                color: #152C61;
                font: normal 500 36px/54px 'Poppins', sans-serif;
                text-align: center;
                width: 100%;
            }
            button{
                background-color: transparent;
                border: none;
                outline: none;
                padding: 0px;
                position: absolute;
                right: 26px;
                top: 26px;
            }
        }
        .modal-body{
            padding-bottom: 60px;
            .lock_icon{
                align-items: center;
                background: #EBF1FF;
                border-radius: 6px;
                display: flex;
                height: 90px;
                justify-content: center;
                margin: 0px auto;
                width: 90px;
                &:before{
                    background-image: url("https://assets.codingdojo.com/learn_platform/user/login/lock_unlock_icon.png");
                    background-position: 1px -1px;
                    content: "";
                    height: 49px;
                    width: 40px;
                }
                &.is_locked{
                    &:before{
                        background-position: -39px -1px;
                    }
                }
            }
            form#retrieve_password_form{
                margin: 0px auto;
                text-align: center;
                width: 368px;
                p{
                    color: #152C61;
                    font: normal normal 16px/24px 'Poppins', sans-serif;
                    margin: 41px 0 29px 0;
                    text-align: center;
                }
                .form-group{
                    .form-control{
                        border-radius: 0px;
                        border: none;
                        border-bottom: 2px solid  $blue;
                        box-shadow: none;
                        font: 300 14px/21px 'Poppins', sans-serif;
                        outline: none;
                        padding: 19px 0px;
                        &::placeholder{
                            color: rgba(21, 44, 97, 0.4);
                        }
                    }
                }
                button[type=submit]{
                    background-color: $blue;
                    border-radius: 6px;
                    border: none;
                    font: normal 500 18px/27px 'Poppins', sans-serif;
                    height: 46px;
                    margin-top: 24px;
                    text-align: center;
                    width: 158px;
                }
            }
            #request_submitted_container{
                margin: 0px auto;
                text-align: center;
                width: 407px;
                p{
                    color: #152C61;
                    font: normal normal 16px/24px 'Poppins', sans-serif;
                    text-align: center;
                    &:last-child{
                        margin-bottom: 0px;
                    }
                    &:nth-last-child(2){
                        margin-top: 41px;
                        margin-bottom: 23px;
                    }
                }
            }
        }        
    }       
    @media only screen and (max-width: 768px){
        .modal-content{
            .modal-header{
                .modal-title{
                    font: normal 500 16px/24px 'Poppins', sans-serif;
                }
            }
            .modal-body{
                #request_submitted_container{
                    width: 100%;
                }
                form#retrieve_password_form{
                    max-width: 450px;
                    width: 100%;
                    p{
                        font: normal normal 14px/24px 'Poppins', sans-serif;
                    }
                    button[type=submit]{
                        font: normal 500 16px/24px 'Poppins', sans-serif;
                        height: 40px;
                    }
                }
            }
        }
    } 
}