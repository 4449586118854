$Poppins: "Poppins", sans-serif;

#course_popover{
    background: #4B4B4B;
    border-radius: 2px;
    border: 1px solid rgba(204, 224, 255, 0.14);
    right: -30px !important;
    width: 247px;
    z-index: 1 !important;
    .popover-body{
        color: #E5E5E5;
        height: 129px;
        padding: 6px 10px 0px 10px;

        ul{
            display: flex;
            flex-direction: column;
            height: 100%;
            list-style-type: none;
            margin-bottom: 0;
            padding-left: 0;
            width: 100%;
            li{
                cursor: pointer;
                flex: 1;
                font: normal 400 14px/130% $Poppins;
                padding-left: 30px;
                padding-top: 5px;
                position: relative;
                &::before{
                    background: url("https://assets.codingdojo.com/learn_platform/admin/curriculum_management/admin_curriculum_management_sprite.png") no-repeat;
                    content: "";
                    display: inline-block;
                    height: 20px;
                    left: 0px;
                    margin-right: 13px;
                    position: absolute;
                    top: 4px;
                    width: 20px;
                }
                &:nth-child(1){
                    &::before{
                        background-position: -361px -20px;
                    }
                }
                &:nth-child(2){
                    &::before{
                        background-position: -84px -20px;
                    }
                }
                &:nth-child(3){
                    &::before{
                        background-position: -16px -20px;
                    }
                }
                &:nth-child(4){
                    &.unmark{
                        padding-bottom: 5px;
                    }
                    &::before{
                        background-position: -330px -20px;
                    }
                }
            }
        }
    }
    .arrow{
        display: none;
    }
}

.curriculum_popover_btn{
    background: transparent url("https://assets.codingdojo.com/learn_platform/admin/curriculum_management/admin_curriculum_management_sprite.png") no-repeat -112px -18px;
    border: none;
    height: 24px;
    outline: none;
    width: 24px;
}