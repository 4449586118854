$Poppins: "Poppins", sans-serif;

#release_notes_modal{
    max-width: 880px;
    width: 880px;

    .modal-content{
        background: #FFFFFF;
        border-radius: 6px;
        border: none;
        box-shadow: 0px 10px 8px rgba(0, 0, 0, 0.05);
        
        .modal-header{
            background: #2C6BFF;
            border: none;
            justify-content: flex-start;
            padding: 21px 40px 19px 40px;
            position: relative;

            .modal-title{
                color: #FFFFFF;
                font-family: $Poppins;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 24px;
            }


            button{
                background-color: transparent;
                border: none;
                outline: none;
                padding: 0px;
                position: absolute;
                right: 26px;
                top: 15px;

                .close_icon{
                    background-image: url("https://assets.codingdojo.com/learn_platform/global/close_icon_new.png");
                    background-position: -61px 0px;
                    display: inline-block;
                    height: 33px;
                    width: 33px;
                }
            }
        }

        .modal-body{
            margin: 40px 10px 0px 0px;
            max-height: 500px;
            min-height: 150px;
            overflow: auto;
            padding: 0px 30px 46px 40px;

            #dojo_bot{
                color: #2C6BFF;
                font-family: "Poppins", sans-serif;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                left: 14px;
                line-height: 21px;
                margin-bottom: 10px;
                position: relative;
                top: -12px;
            }

            h5{
                font-family: $Poppins;
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                color: #152C61;
                margin-bottom: 21px;
                &:not(:first-child){
                    border-top: 1px solid #C2D4FF;
                    margin-top: 41px;
                    padding-top: 42px;
                }
            }

            >img{
                margin: 17px 0px 38px 23px;
            }


            ul{
                margin-top: 20px;
                padding-left: 20px;
                li{
                    color: #152C61;                            
                    font-family: $Poppins;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: normal;
                    line-height: 21px;
                    margin-bottom: 10px;
                    &::marker{
                        color: #2C6BFF;
                    }
                    >ul{
                        margin-top: 10px;
                    }
                }
            }

            >div{
                margin-left: 20px;
            }

            p{
                color: #152C61;                            
                font-family: $Poppins;
                font-size: 14px;
                font-style: normal;
                font-weight: normal;
                line-height: 21px;
                b{
                    font-family: $Poppins;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 21px;
                }
            }            

            img{
                margin-bottom: 27px;
                width: 100%;
            }
        }
    }
}
@media only screen and (max-width: 768px){
    #release_notes_modal{
        .modal-content{
            .modal-header{
                padding: 21px 20px 19px;
                button{
                    top: 15px !important;
                }
            }
            .modal-body{
                padding: 0px 20px 46px 20px;

                >img{
                    margin-left: 0px;
                    width: 100%;
                }


                #time_for_change_banner{
                    padding: 25px;
    
                    p{
                        width: 100%;
                    }
                }

                ul{
                    padding-left: 20px;
                    img{
                        width: 100%;
                    }
                }
            }

        }

    }
}


.is_dark_mode{
    #release_notes_modal{
        .modal-content{
            background: linear-gradient(0deg, rgba(255, 255, 255, 0.14), rgba(255, 255, 255, 0.14)), #121212 !important;

            .modal-header{
                background-color: #81A0EA;
            }

            .modal-body{
                #dojo_bot{
                    color: #81A0EA;
                }

                h5{
                    color: #81A0EA !important;
                }

                ul{
                    margin-bottom: 40px;
                    li{
                        color: rgba(255, 255, 255, 0.88);

                        &::marker{
                            color: #81A0EA;
                        }
                       
                    }
                }

                p{
                    color: rgba(255, 255, 255, 0.88) !important;
                    b{
                        color: #81A0EA !important;
                    }
                }
                a{
                    color: #81A0EA;
                }
            }
        }
    }
}