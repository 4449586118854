$Poppins: 'Poppins', sans-serif;

#custom_recurrence_modal{
    max-width: none;
    width: 525px;
    .modal-content{
        background: #4B4B4B;
        border-radius: 0px;
        border: none;
        box-shadow: 0px 4px 2px rgba(0, 0, 0, 0.05);
        color: rgba(255, 255, 255, 0.88);
        font-family: $Poppins;
        .modal-header{
            background: #4B4B4B;
            border-radius: 0px;
            border: none;
            justify-content: left;
            padding: 18px 23px;
            position: relative;
    
            h4{
                font-family: 'Poppins', sans-serif;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: 16px;
                margin-bottom: 0px;
            }
            button{
                background-color: transparent;
                background-image: url("https://codingdojo_assets.s3.us-east-1.amazonaws.com/learn_platform/admin/edit_course/admin_edit_course_sprite.png");
                background-position: -65px 0px;
                border: none;
                height: 15px;
                outline: none;
                padding: 0px;
                position: absolute;
                right: 22px;
                top: 20px;
                width: 15px;
            }
        }
        .modal-body{
            background: #4B4B4B;
            font-size: 12px;
            padding: 0px;
            .form-group{
                display: flex;
                padding: 0px 24px 0px 120px;
                position: relative;
                input[type="number"]{
                    -moz-appearance:textfield;
                    outline: none;
                    &::-webkit-outer-spin-button,
                    &::-webkit-inner-spin-button {
                        -webkit-appearance: none;
                    }
                }
                &.repeat_every_container{
                    input[type="number"]{
                        background: #333333;
                        border-radius: 4px;
                        border: none;
                        color: #fff;
                        height: 28px;
                        margin-right: 4px;
                        text-align: center;
                        width: 27px;
                        &:not(:placeholder-shown) {
                            border: 1px solid #81A0EA;
                        }
                    }
                }
             
                .react-datepicker-wrapper{
                    .react-datepicker__input-container{
                        label.has_value{
                            border: 1px solid #81A0EA;
                        }
                    }
                }
                .react-datepicker-popper{
                    font-family: $Poppins;
                    font-size: 14px;
                    button{
                        outline: none !important;
                    }
                    .react-datepicker__triangle{
                        display: none;
                    }
                    .react-datepicker{
                        background: #4B4B4B;
                        border: 1px solid rgba(255, 255, 255, 0.88);
                        .react-datepicker__day, .react-datepicker__time-name{
                            color: rgba(255, 255, 255, 0.88);
                            font-family: $Poppins;
                            &:hover{
                                background: transparent;
                            }
                        }
                        .react-datepicker__navigation{
                            top: 12px;
                        }
                        .react-datepicker__day--outside-month, .react-datepicker__day--disabled{
                            color: rgba(255, 255, 255, 0.88);
                            opacity: 0.4;
                        }
                        .react-datepicker__day-name{
                            color: #AEB9C5;
                        }
                        .react-datepicker__day--keyboard-selected, .react-datepicker__day--selected{
                            background: transparent;
                            color: #81A0EA;
                        }
                    }
                    .react-datepicker__header:not(.react-datepicker__header--has-time-select){
                        background: #4B4B4B;
                        border: none;
                        padding-top: 0px;
                        .react-datepicker__current-month{
                            background: #81A0EA;
                            color: rgba(255, 255, 255, 0.88);
                            height: 40px;
                            margin-top: -1px;
                            padding-top: 7px;
                        }
                    }
                }
                .react-dropdown-select{
                    background: #333333;
                    border-radius: 4px !important;
                    height: 28px;
                    min-height: 28px !important;
                    padding: 7px 11px !important;
                    &.has_value{
                        border: 1px solid #81A0EA !important;
                    }
                    .react-dropdown-select-dropdown-handle{
                        display: flex;
                    }
                    .react-dropdown-select-content{    
                        display: inline;
                        span{
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            width: 220px;
                            ~ input{
                                display: none;
                            }
                        }
                        input{
                            background: transparent;
                            font-size: 12px;
                            height: unset;
                            padding: 0px;
                            width: 100%;
                        }
                    }
                    .react-dropdown-select-dropdown{
                        background: #333333;
                        border-radius: 4px;
                        margin-top: -6px;
                        max-height: 320px;
                        min-width: 120px;
                        padding: 10px 0px;
                        .react-dropdown-select-item{
                            border: none;
                            font-size: 12px;
                            padding: 6px 18px;
                            &.react-dropdown-select-item-disabled{
                                background: transparent;
                                opacity: .5;
                                ins{
                                    display: none;
                                }                            
                            }
                            &.react-dropdown-select-item-selected{
                                background: transparent;
                                color: #81A0EA;
                                position: relative;
                                &:after{
                                    background-image: url("https://assets.codingdojo.com/learn_platform/admin/access_control/acl_icons_sprite.svg");
                                    background-position: 14px -12px;
                                    content: "";
                                    display: inline-block;
                                    height: 11px;
                                    position: absolute;
                                    right: 19px;
                                    width: 13px;
                                }
                            }
                        }
                    }
                }
                h6{
                    font-family: $Poppins;
                    font-size: 12px;
                    font-weight: normal;
                    left: 20px;
                    margin-bottom: 0px;
                    position: absolute;
                    top: 8px;
                }
                input[type="radio"], input[type="checkbox"]{
                    display: none;
                    &:checked  + label {
                        border: 1px solid #81A0EA;
                    }
                }
                label{
                    align-items: center;
                    background: #333333;
                    border-radius: 4px;
                    border: 1px solid transparent;
                    cursor: pointer;
                    display: flex;
                    font-family: $Poppins;
                    font-size: 12px;
                    height: 28px;
                    justify-content: center;
                    margin-bottom: 0px;
                    margin-right: 14px;
                    padding: 0px 7px;
                }
                .occurance_container{
                    align-items: center;
                    background: #333333;
                    border-radius: 4px;
                    border: 1px solid #81A0EA;
                    display: flex;
                    justify-content: center;
                    padding: 0px 10px;
                    input{
                        background: #333333;
                        border: none;
                        color: #fff;
                        width: 27px;
                    }
                    .button_container{
                        display: flex;
                        flex-direction: column;
                        margin-right: 19px;                    
                        button{
                            align-items: center;
                            background: transparent;
                            border: none;
                            color: #fff;
                            display: flex;
                            font-family: $Poppins;
                            font-size: 10px;
                            height: 13px;
                            justify-content: center;                            
                            outline: none !important;
                            padding: 0px;
                            width: 10px;
                        }
                    }
                    p{
                        margin-bottom: 0px;
                    }
                }

            }
            .footer{
                background: linear-gradient(0deg, rgba(255, 255, 255, 0.14), rgba(255, 255, 255, 0.14)), #121212;
                border: none;
                height: 79px;
                margin-top: 22px;
                padding: 21px 24px;
                text-align: right;
                button{
                    background: transparent;
                    border: none;
                    color: #fff;
                    font-family: $Poppins;
                    font-size: 14px;
                    &[type="submit"]{
                        background: rgba(204, 224, 255, 0.14);
                        border-radius: 2px;
                        color: #81A0EA;
                        margin-left: 30px;
                        padding: 8px 16px;
                    }
                }
            }
        }
    }
}