$Poppins: 'Poppins', sans-serif;

#student_access_list{
    height: 100%;
    list-style: none;
    margin: 0;
    overflow: auto;
    padding: 0;

    li{
        background: #282828;
        border-radius: 4px;
        cursor: pointer;
        margin-bottom: 20px;
        padding: 12px 24px 11px 24px;

        &.no_student_list_result{
            align-items: center;
            background: transparent;
            display: flex;
            flex-direction: column;
            height: 100%;
            justify-content: center;
            margin-bottom: 0;
            width: 100%;

            .global_search_icon{
                background: url("https://assets.codingdojo.com/learn_platform/admin/student_access/global_search_icon.svg") no-repeat;
                display: block;
                height: 52px;
                margin-bottom: 20px;
                width: 48px;
            }

            span{
                color: #E5E5E5;
                font-weight: 500;
                font: 12px/16px $Poppins;
            }
        }

        &:hover{
            .student_access_header{
                .user_profile_details{
                    .user_details_block{
                        p{
                            .copy_link_icon, .sent_email_icon{
                                display: block;
                            }
                        }
                    }
                }
            }
        }

        .student_access_header{
            align-items: center;
            display: flex;
            justify-content: flex-start;
            margin-bottom: 16px;

            .user_profile_details{
                align-items: center;
                display: flex;

                img{
                    border-radius: 50%;
                    height: 36px;
                    margin-right: 10px;
                    width: 36px;
                }

                .user_details_block{
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    width: 215px;
                    

                    .name{
                        color: rgba(255, 255, 255, 0.88);
                        font-weight: 600;
                        font: 14px/18px $Poppins;
                        margin-bottom: 3px;
                        word-break: break-all;

                        .note_icon{
                            background: url("https://assets.codingdojo.com/learn_platform/admin/student_progress/assignment_checklist_icons.png") no-repeat -49px -2px;
                            display: inline-flex;
                            height: 12px;
                            margin-left: 5px;
                            outline: none;
                            width: 14px;
                        }
                    }

                    p{
                        align-items: center;
                        display: flex;
                        margin: 0;

                        span.email_text{
                            color: rgba(255, 255, 255, 0.58);
                            font-weight: 400;
                            font: 12px/16px $Poppins;
                            margin-bottom: 0px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        }

                        .copy_link_icon, .sent_email_icon{
                            background: url("https://assets.codingdojo.com/learn_platform/admin/student_access/admin_student_access_icon.svg") no-repeat;
                            display: none;
                            height: 14px;
                            min-width: 14px;

                            &.copy_link_icon{
                                background-position: -152px -4px;
                                border: none;
                                margin-left: 5px;
                                outline: none;
                            }
                            &.sent_email_icon{
                                background-position: -174px -4px;
                                margin-left: 3px;
                            }
                        }
                    }
                }
            }

            .user_details{
                align-items: initial;
                margin: 0;

                .user_detail_block{
                    margin-left: 27px;
                    padding-left: 22px;
                    position: relative;
                    max-width: 145px;
                    min-width: 70px;

                    &:before{
                        background-image: url("https://assets.codingdojo.com/learn_platform/admin/student_access/admin_student_access_icon_v2.svg");
                        content: "";
                        height: 19px;
                        left: 0;
                        position: absolute;
                        top: 4px;
                        width: 16px;
                    }


                    /* Start & End Date */ 
                    &:nth-child(2), &:nth-child(3){
                        &:before{
                            background-position: -24px 0px;
                        }
                    }

                    /* Current Date */ 
                    &:nth-child(4){
                        &:before{
                            background-position: -48px 0px;
                        }
                    }

                    /* At Risk */ 
                    &:nth-child(5){
                        &:before{
                            background-position: -328px 0px;
                            width: 19px;
                            height: 20px;
                        }
                    }
                    /* Access */ 
                    &:nth-child(6){
                        &:before{
                            background-position: -72px 0px;
                        }
                    }

                    span{
                        color: #B3B3B3;
                        font-weight: 400;
                        font: 12px/14px $Poppins;

                        &:last-child{
                            color: rgba(255, 255, 255, 0.88);
                            display: block;
                            font-weight: 400;
                            font: 14px/18px $Poppins;
                        }
                    }

                    .access_info_container{
                        align-items: center;
                        display: flex;

                        >label{
                            align-items: center;
                            cursor: pointer;
                            display: flex;
                            justify-content: flex-start;
                            margin-bottom: 0px;

                            input[type="checkbox"]{
                                display: none;
                            }

                            .is_valid_checkbox_style{
                                background: rgba(204, 224, 255, 0.14);
                                border-radius: 12px;
                                height: 12px;
                                margin-right: 10px;
                                position: relative;
                                transition: .25s ease-in-out;
                                width: 24px;

                                &::after{
                                    background: #B2B2B2;
                                    border-radius: 50%;
                                    content: "";
                                    height: 10px;
                                    margin-left: 1px;
                                    position: absolute;
                                    top: 1px;
                                    transition: .25s ease-in-out;
                                    width: 10px;
                                }
                            }

                            input[type="checkbox"]:checked ~ .is_valid_checkbox_style{
                                background: #28352E;
                                transition: .25s ease-in-out;

                                &::after{
                                    background: #27AE60;
                                    margin-left: 13px;
                                    transition: .25s ease-in-out;
                                }
                            }


                            .valid_till_label{
                                color: rgba(255, 255, 255, 0.88);
                                display: block;
                                font-weight: 400;
                                font: 14px/18px "Poppins", sans-serif;
                                width: 60px;
                            }

                            span{
                                &:nth-child(3), &:nth-child(4){
                                    color: rgba(255, 255, 255, 0.88);
                                    font: 14px/18px $Poppins;
                                }

                                &.access_locked{
                                    color: #F56B6B;
                                    font-weight: 400;
                                }
                            }
                        }

                        .student_access_date_picker{
                            .react-datepicker-wrapper{
                                align-items: center;
                                display: flex;
                                height: 18px;
                                .react-datepicker__input-container{
                                    align-items: center;
                                    display: flex;
                                    height: 18px;
                                    input{
                                        background-color: transparent;
                                        border: none;
                                        caret-color: transparent;
                                        color: #81A0EA;
                                        cursor: pointer;
                                        font-weight: 600 !important;
                                        font: 14px/18px $Poppins;
                                        margin-left: 5px;
                                        outline: none;
                                        padding: 0px;
                                    }
                                }
                            }
                        }

                    }
                }
            }
        }

        .major_tracks, .minor_tracks{
            &.major_tracks{
                margin-bottom: 10px;
            }

            h4{
                color: #B2B2B2;
                font-weight: 600;
                font: 12px/16px $Poppins;
                margin: 0 0 10px 0;
            }

            .tracks_list{
                align-items: center;
                display: flex;
                flex-wrap: wrap;
                justify-content: flex-start;
                list-style: none;
                margin: 0;
                padding: 0;
                
                li{
                    background: #27AE60;
                    border-radius: 4px;
                    color: #DEE5EF;
                    font-weight: 600;
                    font: 12px/16px $Poppins;
                    height: 20px;
                    margin: 0 10px 10px 0px;
                    padding: 2px 6px;

                    &:last-child{
                        margin-right: 0;
                    }

                    &.is_lock{
                        background: #73839C;
                        color: #DEE5EF;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1550px) {
    body{
        #student_access_list{
            >li{
                min-width: 1220px;
            }
        }
    }
}   