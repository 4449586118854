$dark_bg:#121212;
$purple_color: #81A0EA;
$semi_dark_bg:linear-gradient( 0deg , rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), #121212;
$white_color:rgba(255,255,255,0.88);
$dark_mode_bg: #2A2A2A;

body.is_dark_mode{
    background-color: #121212 !important;

    /* Scrollbar width */
    *::-webkit-scrollbar {
        height: 6px;
        width: 6px;
    }
    /* Scrollbar Track */
    *::-webkit-scrollbar-track {
        border-radius: 10px;
        box-shadow: inset 0 0 5px #81a0ea;
    }
    /* Scrollbar Handle */
    *::-webkit-scrollbar-thumb {
        background: rgba(129, 160, 234, .8);
        border-radius: 10px;
    }
    /* Scrollbar Handle on hover */
    *::-webkit-scrollbar-thumb:hover {
        background: #E0E0E0;
    }
    
    ::-moz-selection{
        background-color: rgba(129, 160, 234, .4);
    }
    ::selection{
        background-color: rgba(129, 160, 234, .4);
    }
    &::after{
        background: #121212;
        bottom: 0px;
        content: "";
        display: block;
        height: 100%;
        position: fixed;
        width: 100%;
        z-index: -1;
    }
    #root{
        background-color: #121212 !important;
        min-height: 100vh;
    }
    #login_container{
        .left_container{
            background: #fff;
        }
    }
   
    /* ALL MODALS */
    .modal{
        .modal-content{
            background: $dark_bg !important;
            .modal-header{
                h4{
                    color: $white_color !important;
                }
            }
        }
        #edit_discussion_board_modal{
            .modal-content{
                color: $white_color;
                background: #292929 !important;
                .modal-header{
                    background: #4B4B4B;
                    border: none;
                }
                .modal-body{
                    input,textarea{
                        color: $white_color;
                        background: transparent;
                    }
                    .react-datepicker-wrapper{
                        svg{
                            color: $purple_color;
                        }
                    }
                    .blk{
                        button{
                            color: $white_color;
                        }
                        button[type="submit"]{
                            background: rgba(204, 224, 255, 0.14);
                            color: $purple_color;
                        }
                    }
                }
            }
        }
        #todo_modal{
            .modal-content{
                .modal-header{
                    button{
                        .close_icon{
                            background-position: -61px 0px;
                        }
                    }
                    .modal-title{
                        color: $white_color;
                    }
                }
                .modal-body{
                    p{
                        color: $white_color;
                        a{
                            color: $purple_color !important;
                        }
                    }
                }
                .modal-footer{
                    .btn-primary{
                        background: $semi_dark_bg;
                        border: none;
                        color: $purple_color;
                    }
                    button.btn-outline-primary{
                        background: transparent;
                        border: none;
                        color: $white_color;
                    }
                }
            }
        }
        #delete_discussion_modal, #delete_discussion_module_modal{
            .modal-content{
                background: linear-gradient(0deg, rgba(255, 255, 255, 0.28), rgba(255, 255, 255, 0.28)), #121212 !important;
                color: $white_color;
                form{
                    .btn{
                        color: $white_color;
                        &#delete_discussion_btn, #delete_discussion_module_btn{
                            background: #F56B6B;
                            &:hover{
                                background: rgba(217, 75, 75, 1);
                                color: #fff;
                            }
                        }
                    }
                }
            }
        }
        #survey_modal{
            .modal-content{
                .modal-header{
                    button{
                        .close_icon{
                            background-position: -61px 0px;
                        }
                    }
                    .modal-title{
                        color: $white_color;
                    }
                }
                .modal-body{
                    p{
                        color: $white_color;
                    }
                }
                .modal-footer{
                    .btn-primary{
                        background: $semi_dark_bg;
                        border: none;
                        color: $purple_color;
                    }
                }
            }
        }
        #todo_solution_modal{
            .modal-content{
                .modal-header{
                    background: #4b4b4b;
                }
                .modal-body{
                    background: #333333;
                    #solution_tab_list{
                        li{
                            button.active{
                                border-bottom: 1px solid #81A0EA;
                                color: #81A0EA;
                            }
                        }
                    }
                    div{
                        border: none;
                        background: #4b4b4b;
                        &:not(#solution_code_container){
                            ul{
                                button{
                                    color: $white_color;
                                    &.active{
                                        color: $purple_color;
                                    }

                                }
                            }
                            button.cd_blue_btn{
                                background: #81A0EA;
                            }
                        }
                        &#watch_video_container{
                            background: transparent;
                            button{
                                color: $purple_color !important;
                            }
                        }
                        ul{
                            button{
                                color: $white_color;
                                &.active{
                                    color: $purple_color;
                                }
                            }
                        }
                        button.cd_blue_btn{
                            background: rgba(255, 255, 255, 0.18);
                            color: $purple_color;
                        }
                    }
                    textarea{
                        border: none;
                    }
                }
            }
        }
        #track_courses_modal{
            .modal-content{
                .modal-header{
                    button{
                        .close_icon{
                            background-position: -61px 0px;
                        }
                    }
                    .modal-title{
                        color: $white_color;
                    }
                }
                .modal-body{
                    ul{
                        li{
                            span{
                                color: $white_color;
                            }
                        }
                    }
                }
            }
        }
        &.tuition_reminder_modal{
            .modal-dialog{
                .modal-content{
                    .modal-header{
                        .modal-title{
                            color:$white_color;
                        }
                    }
                    .modal-body{
                        .clock_icon{
                            background-image: url("https://assets.codingdojo.com/learn_platform/dark_mode/global/clock_red_and_blue_sprite_dark_mode.png");
                            background-position: 0px 0px;
                            &.red_clock{
                                background-position: -91px 0px;
                            }
                        }
                        p{
                            color:$white_color;
                            a{
                                color:$white_color;
                                span{
                                    color: $purple_color;
                                }
                            }
                            > span{
                                color: #F56B6B;
                            }
                        }
                    }
                    .modal-footer{
                        .btn.btn-link{
                            color: $purple_color;
                        } 
                        button.btn-primary{
                            background: rgba(204, 224, 255, 0.14);
                            color: $purple_color;
                        }
                        span{
                            color: $white_color;
                        }
                    }
                }
            }
        }
        &.logout_modal{
            .modal-dialog{
                .modal-content{
                    .modal-header{
                        .modal-title{
                            color:$white_color;
                        }
                    }
                    .modal-body{
                        p{
                            color:$white_color;
                            a{
                                color:$white_color;
                                span{
                                    color: $purple_color;
                                }
                            }
                            > span{
                                color: #F56B6B;
                            }
                        }
                    }
                    .modal-footer{
                        span{
                            color: $white_color;
                        }
                    }
                }
            }
        }
        #my_files_modal{
            .modal-content{
                .modal-header{
                    .modal-title{
                        color: $white_color;
                    }
                    button{
                        .close_icon{
                            background-position: -61px 0px;
                        }
                    }
                }
                .modal-body{
                    ul{
                        li{
                            background: $semi_dark_bg;
                            border: none;
                            .description, .file-lock{
                                color: $purple_color;
                            }
                            svg{
                                color: $purple_color;
                            }
                        }
                    }
                }
            }
        }
        #privacy_policy_modal{
            .modal-content{
                background: $semi_dark_bg;
                color: $white_color;
                .modal-header{
                    button{
                        .close_icon{
                            background-position: -61px 0px;
                        }
                    }
                }
                .modal-body{
                    ul{
                        li{
                            a{
                                color: $purple_color;
                            }
                        }
                    }
                }
            }
        }
        #report_issue_modal{
            color: $white_color;
            .modal-content{
                .modal-header{
                    button{
                        .close_icon{
                            background-position: -61px 0px;
                        }
                    }
                }
                .success_container{
                    a{
                        color: $purple_color;
                    }  
                } 
                .modal-body{
                    p{
                        color: $white_color;
                    }
                    .bug_icon{
                        background-position: -183px 0px;
                    }
                    .success_icon{
                        background-position: -274px 0px;
                    }
                    form{
                        .form-control{
                            background: $semi_dark_bg;
                            border:none;
                            color: $white_color;
                        }
                        #upload_issue_label{
                            background: rgba(154, 194, 255, 0.3);
                            color: $white_color;
                            small{
                                color: $white_color;
                            }
                        }
                    }
                    button{
                        background: $semi_dark_bg;
                        color:$purple_color;
                    }
                }
            }
        }
        #daily_tip_modal{
            .modal-content{
                .modal-header{
                    button{
                        .close_icon{
                            background-position: -61px 0px;
                        }
                    }
                }
                #coming_soon_container{
                    h5,p{
                        color: $white_color;
                    }
                }
            }
        }
        #add_bookmark_modal{
            .modal-content{
                .modal-header{
                    button{
                        .close_icon{
                            background-position: -61px 0px;
                        }
                    }
                    .modal-title{
                        color: $white_color;
                    }
                }
                .modal-body{
                    form{
                        input{
                            background: $semi_dark_bg;
                            border:none;
                            color: $white_color;
                            &::placeholder {
                                color: $white_color;
                                opacity: .5;
                            }
                            &:-ms-input-placeholder {
                                color:  $white_color;
                            }
                            &::-ms-input-placeholder {
                                color:  $white_color;
                            }
                        }
                        .btn_container{
                            button[type=button]{ color: $white_color; }

                            button[type=submit]{
                                background: rgba(204, 224, 255, 0.14);
                                border: none;
                                color: $purple_color;
                            }
                        }
                    }
                }
            }
        }
        #bookmarks_modal{
            .modal-content{
                .modal-header{
                    .modal-title{
                        color: $white_color;
                    }
                    button{
                        .close_icon{
                            background-position: -61px 0px;
                        }
                    }
                }
                .modal-body{
                    > p{
                        color:$white_color;
                    }
                    > ul{
                        > li{
                            background: $semi_dark_bg;
                            border:none;
                            form{
                                button{
                                    color: #05DA80;
                                }
                            }
                            a{
                                .left_container{
                                    h5{
                                        color: $white_color;
                                    }
                                }
                                .right_container{
                                    ul{
                                        li{
                                            color: $white_color;
                                            &:after{
                                                background-image: url("https://assets.codingdojo.com/learn_platform/dark_mode/global/small_arrow_right_dark_mode.png");
                                            }
                                        }
                                    }
                                    p{
                                        color: $white_color;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        #search_modal{
            .modal-content{
                .modal-header{
                    button{
                        .close_icon{
                            background-position: -61px 0px;
                        }
                    }
                }
                .modal-body{
                    #chapters_list, #courses_list{
                        li{
                            background: $semi_dark_bg; 
                            border: none;
                            span{
                                color: $white_color;
                            }
                        }
                    }
                    #modules_list{
                        > li{
                            background: $semi_dark_bg;
                            border: none;
                            a{
                                .left_container{
                                    h5{
                                        color: $white_color;
        
                                    }
                                }
                                .right_container{
                                    p{
                                        color: $white_color;
                                    }
                                    ul{
                                        li{
                                            color: $white_color;
                                            &:after{
                                                background-image: url("https://assets.codingdojo.com/learn_platform/dark_mode/global/small_arrow_right_dark_mode.png");
                                            }
                                        }
                                    }
                                }
                            }
                            form{
                                button#bookmark_module_btn{
                                    background-image: url("https://assets.codingdojo.com/learn_platform/dark_mode/courses/bookmark_and_unbookmark_icon_dark_mode.png");
                                }
                            }
                        }
                    }
                    .tab-content{
                        .tab-pane{
                            > p{
                                color: $white_color;
                            }
                        }
                    }
                    form{
                        input{
                            background: $semi_dark_bg;
                            border: none;
                            color: $white_color;
                        }
                    }
                    .nav-tabs{
                        a{
                            color: $white_color;
                            opacity: 0.3;
                            &.active{
                                background: transparent;
                                border-bottom: 2px solid $purple_color;
                                color: $purple_color;
                                opacity: 1;
                            }
                        }
                    }
                }
            }
        }
        #retrieve_password_modal{
            .modal-content{
                background: #ffffff !important;
            }
        }
        #reset_password_modal{
            .modal-content{
                .modal-header{
                    .modal-title{
                        color: $white_color;
                    }
                    button{
                        .close_icon{
                            background-position: -61px 0px;
                        }
                    }
                }
                .modal-body{
                    #reset_password_success_container{
                        p{
                            color: $white_color;
                        }
                    }
                    form#reset_password_form{
                        .form-group{
                            label{
                                color: $white_color;
                            }
                            .form-control{
                                background: $semi_dark_bg;
                                border-bottom: none;
                                color: $purple_color;
                                padding: 0px 10px;
                            }
                        }
                        button[type=submit]{
                            background: $semi_dark_bg;
                            color: $purple_color;
                        }
                    }
                }
            }
        }
        #late_assignment_submission_modal{
            .modal-content{
                .modal-body{
                    p{
                        color: $white_color;
                    }
                    button{
                        background: rgba(204, 224, 255, 0.14);
                        color: #81A0EA;
                    }
                }

            }
        }
    }
    //HEADER
    .navbar-expand{
        background: #121212;
        max-width: 100%;
        width: 100%;
        .container{
            .navbar-brand{
                color: $white_color;
            }
            .fa-caret-right{
                color: $purple_color;
            }
            .dropdown{
                .dropdown-toggle{
                    color: $white_color;
                }
                .dropdown-menu.show{
                    background: $semi_dark_bg;
                    a, button{
                        color: $purple_color;
                    }
                }
            }
            button#show_search_modal{
                color: #B3B3B3;
                background-image: url('https://assets.codingdojo.com/learn_platform/dark_mode/global/search_icon_new.png');
                border: 2px solid #595959;

            }
            .main_links_container{
                > a{
                    color: rgba(255, 255, 255, 0.58);
                    opacity: 1;
                    &.active{
                        color: $purple_color;
                    }
                }
            }
        }
    }
    .container{
        background: #121212;
        #sub_navigation_container{
            .nav-item{
                background-color: transparent;
                border: 1px solid $purple_color !important;
                &.show{
                    background: linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), #121212;
                    border: none !important;
                    a#basic-nav-dropdown{
                        padding-left: 11px;
                    }
                }
                a#basic-nav-dropdown{
                    span{
                        color: $purple_color;
                        svg{
                            color: $purple_color;
                        }    
                    }
                }
                div.dropdown-menu{
                    background: linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), #121212;
                    border-top: none !important;
                    button, span{
                        background-color: transparent;
                        color: #E5E5E5;
                        padding: 10px 23px 9px 10px;
                        &.active{
                            color: $purple_color;
                        }
                    }
                }
            }
            #sub_nav_buttons{
                button{
                    background: #292929;
                    position: relative;
                    &::before{
                        background: url("https://assets.codingdojo.com/learn_platform/user/dashboard/dashboard_redesign_icons.png") no-repeat 0px 0px;
                        content: '';
                        height: 32px;
                        left: 50%;
                        position: absolute;
                        top: 50%;
                        transform: translate(-50%, -50%);
                        width: 32px;
                    }
                    &#todo_list_btn{
                        &::before{
                            background-position: -617px -7px;
                        }
                        &[aria-describedby="to_do_popover"]{
                            background-color: $purple_color; 
                            &::before{
                                background-position: -107px -7px;
                            }
                            span{
                                border-color: $purple_color;
                            }
                        }
                        span{
                            background: #F56B6B;
                            border-color: #292929;
                        }
                    }
                    &#bookmarks_btn::before{
                        background-position: -680px -7px;
                    }
                    &#notification_btn{
                        &::before{
                            background-position: -649px -7px;
                        }
                        &[aria-describedby="notification_popover"]{
                            background-color: $purple_color;
                            &::before{
                                background-position: -176px -7px;
                            }
                            span{
                                border-color: $purple_color;
                            }
                        } 
                        span{
                            background: #F56B6B;
                            border-color: #292929;
                        }
                    }
                }
            }
            ul{
                li{
                    a{
                        color: rgba(255, 255, 255, 0.58);
                        opacity: 1;
                    }
                    &.active{
                        a{
                            color: $purple_color;
                            border-bottom: 2px solid $purple_color;
                        }
                    }
                }
            }
        }
    }
    #dashboard_container.container{
        .maintenance_banner{
            background: $dark_mode_bg !important;

            form{
                .payment_overdue_select{
                    .dropdown_icon{
                        background: url("https://assets.codingdojo.com/learn_platform/user/dashboard/dashboard_redesign_icons.png") -593px -13px !important; 
                    }
                }
            }
            p{
                color: $white_color !important;
            }
            button.close_button{
                background: url("https://assets.codingdojo.com/learn_platform/global/close_icon_new.png") -532px -7px;
                transform: scale(0.9);
            }
        }
        .payment_overdue{
            border-color: #81A0EA;
            form{
                .red_button{
                    background: #2873FF;
                }
                .react-dropdown-select-item-selected{
                    background: #121212;
                    color: #B2B2B2;
                    font-weight: 700;
                }
                .react-dropdown-select-dropdown{
                    background: #121212;
                    border-color: #81A0EA;
                    color: #B2B2B2;
                    .react-dropdown-select-item{
                        border-bottom: 1px solid #121212;
                    }
                }
                .react-dropdown-select-content{
                    color: #81A0EA;
                }
            }
        }

        #ssn_banner{
            background: linear-gradient(0deg, rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.12)), #121212 !important;
            p{
                color: $white_color !important;
                a{
                    color: $purple_color !important;
                }
            }
        }
    }
    #dashboard_container.container{
        #dashboard_widget_container{
            #my_class_container{
                background: $dark_mode_bg;
                > header{
                    h5{
                        color: #e5e5e5;
                        svg{
                            color: #81A0EA;
                        }
                    }
                    button{
                        background-color: #595959;
                        color: #b2b2b2;
                        &.current_day{
                            background-color: #2873FF;
                            color: white;
                            &.is_viewed{
                                background: transparent !important;
                            }
                        }
                        &.is_viewed:not(.current_day, .is_belt_exam_day){
                            background-color: transparent;
                            border: 2px solid #2873FF;
                            color: #b2b2b2;
                            font-weight: 700;
                        }
                    }

                }

                h3{
                    color: rgba(178, 178, 178, .4);
                }

                > ul{
                    li{
                        background-color: #333;
                        &::before{
                            background-color: #2E2E2E;
                        }
                        .card_title, .lecture_subject, .lecture_time{
                            color: #e5e5e5;
                        }
                        .lecture_state{
                            background: linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), #121212;
                            color: #b3b3b3;
                            &::before{
                                background-color: #b3b3b3;
                            }
                            &.is_live{
                                background: transparent;
                                color: #F56B6B;
                                border: 1px solid #F56B6B;
                                padding: 1px 4px 1px 14px !important;
        
                                &::before{
                                    background-color: #F56B6B;
                                    top: 6px;
                                }
                            }
                        }
                        a{
                            background-color: #2873FF;
                            &::before{
                                background-position-x: -718px;
                            }
                            &.view_video_button{
                                background-color: transparent;
                                border: 2px solid #2873FF;
                                color: #2873FF;
                            }
                        }
                    }
                }
            }
            #belt_exam_history_container{
                background: $dark_mode_bg;
                h5, >p{
                    color: $white_color;
                }
                #belt_list{
                    li.belt_item{
                        span.belt_name{
                            color: $white_color;
                        }
                    }
                }
                .view_more_less_history_btn{
                    color: #81A0EA;
                }
                > p{
                    span{
                        color: #81A0EA;
                    }
                }
                table{
                    thead{
                        tr{
                            th{
                                color:$white_color;
                            }
                        }
                    }
                    tbody{
                        tr{
                            td{
                                color:$white_color;
                                &:last-child{
                                    color:#81A0EA;
                                }
                            }
                        }
                    }
                }
            }
            #my_stats_container{
                background: $dark_mode_bg;
                h5{
                    a{
                        color: #e5e5e5;
                    }
                }
                #rates_container{
                    h6, p{
                        color: #b3b3b3;
                    }
                    a{
                        color: $purple_color;
                    }
                }
                #rates_container h6, #rates_container p{
                    color: #b3b3b3;
                }

                #current_stats_label{
                    > div{
                        .selected_stats{
                            background: $semi_dark_bg;
                            border: 1px solid $purple_color !important;

                            .react-dropdown-select-dropdown{
                                background: #333;
                                box-shadow: 0 10px 8px rgba(0, 0, 0, .05);
    
                                span, div, input{
                                    color: #FFFFFF;
                                }
                            }
                            .react-dropdown-select-content{
                                span{
                                    color: #E5E5E5;
                                }
                            }
                            .react-dropdown-select-dropdown-handle{
                                svg{
                                    color: $purple_color;
                                }
                            }
                        }
                    }
                }

                #rates_container{
                    > div {
                        border: 1px solid #666666;
                        &.skeleton_loading{
                            border: none !important;
                        }
                        > svg{
                            .CircularProgressbar-text{
                                fill: #E5E5E5;
                            }

                            .CircularProgressbar-path{
                                stroke: $purple_color;
                            }
                        }
                    }
                }
            }
            #my_program_container{
                background: $dark_mode_bg;
                h5{
                    color: #E5E5E5;
                }
                .programs_tab{
                    li{
                        color: #B3B3B3;
                        &.active{
                            color: #E5E5E5;
                            &:after{
                                background: #81A0EA;
                            }
                        }
                    }
                }
                .courses_list{
                    li{
                        border: none;
                        &.course_item{
                            background: #333333;
                        }
                        .completed_percentage{
                            color: #81A0EA;
                        }
                        .units_completed{
                            color: #B2B2B2;
                            svg {
                                color: #B3B3B3;
                            }
                        }
                        .button_progress_bar_section{
                            .course_completed_progress_bar{
                                background: #595959;
                                .on_time_progress{
                                    background: #81A0EA;
                                }
                            }
                            button{
                                background-color: #2873FF;
                                &.completed{
                                    background-color: #FFFFFF;
                                    border: 2px solid #2873FF;
                                    color: #2873FF;
                                }
                            }
                            .disabled{
                                background: #81A0EA;
                            }
                        }
                    }
                }
                a#goto_button{
                    background: #2873FF;
                }
            }
            #alumni_pass_container{
                background: $dark_mode_bg;
                & > h5{
                    color: #E5E5E5;
                }
                .alumni_pass_card{
                    background: #333333;
                    border: none;
                    h5{
                        color: #E5E5E5;
                    }
                    a{
                        background: #2873FF;
                    }
                    .locked_icon{
                        background: url("https://assets.codingdojo.com/learn_platform/user/dashboard/new_lock_icon.png") no-repeat -65px -20px;
                    }
                }
            }
        }
    }
    #tracks_container{
        #tracks_list{
            > li{
                background: $semi_dark_bg;
                > h5{
                    color: $white_color;

                    span{
                        color: $white_color;
                    }
                }
            }
            .course_item{
                a{
                    text-decoration: none;
                    &:hover{
                        opacity: .8;
                    }    
                }
                span{
                    color: $white_color;
                }
            }
        }
    }
    #course_container{
        aside.left_container{
            background: $semi_dark_bg;
            h2, >span{
                color: $white_color;
            }
            #toggle_collapse_sidebar_btn{
                &:before{
                    background: rgba(204, 224, 255, 0.14);
                }
                svg{
                    color: $purple_color;
                }
            }
            #course_navigation_container{
                div{
                    span{
                        color: $white_color;
                    }
                }
                a{
                    color: $purple_color;
                }
            }
            .accordion{
                .card{
                    background: transparent;
                    .card-header{
                        span.chapter_percent{
                            background: rgba(204, 224, 255, 0.14);
                            color: $purple_color;
                        }
                        .chapter_name{
                            span{
                                color: $white_color;
                                &.chapter_percent{
                                    background: rgba(204, 224, 255, 0.14);
                                    color: $purple_color;
                                }
                            }
                            .fa-caret-down, .fa-caret-up{
                                color: $white_color;
                            }
                        }
                    }
                    
                    .collapse, .collapsing{
                        .card-body{
                            ul{
                                li{
                                    span.module_name{
                                        color: $white_color;
                                    }
                                    a{
                                        text-decoration: none;
                                    }
                                    &.completed{
                                        .completed_icon{
                                            background-image: url("https://assets.codingdojo.com/learn_platform/dark_mode/global/completed icon.png");
                                        }
                                    }
                                    .icon_container{
                                        .fa-check-circle{
                                            color: #3A6B5F;
                                        }
                                        .fa-play-circle{
                                            color: $purple_color;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .right_container{
            background: $semi_dark_bg;
            .module_name{
                color: $white_color;
            }
            &.is_online_discussion{
                #online_discussion_cta_container{
                    .edit_discussion_btn{
                        background-position: -40px 0px;
                    }
                    .delete_discussion_btn{
                        background-position: -113px 0px;
                    }
                }
            }
            button#bookmark_module_btn{
                background-color: transparent;
                background-image: url("https://assets.codingdojo.com/learn_platform/dark_mode/courses/bookmark_and_unbookmark_icon_dark_mode.png");
            }
            #course_edit_btn{
                background-image: url("https://assets.codingdojo.com/learn_platform/dark_mode/courses/course_edit_icon_dark_mode.png");
            }
            #lesson_content{
                color: $white_color;
                pre[data-language], pre[data-language]{
                    background: $dark_bg !important;
                }
                *{
                    color: $white_color;
                }
                a{
                    color: $purple_color;
                    b{
                        color: $purple_color;
                    }
                }
                .mark, mark{
                    background: #635202;
                }
                select{
                    background: transparent;
                    option{
                        background: #2a2a2a;
                    }
                }
            }
            #copy_to_clipboard_value{
                background: #292929;
                opacity: 0;
            }
            #chapter_survey_form{
                > p{
                    color: $white_color
                }
                #chapter_survey_questions{
                    > li{
                        textarea{
                            background: rgba(255, 255, 255, 0.18);
                            border: none;
                            color: $white_color;
                        }
                        > p{
                            color: $white_color
                        }
                        .slide_ratings_list{
                            label{
                                color: $white_color;
                            }
                            input[type=radio]:checked ~ span{
                                color:$purple_color;
                            }
                        }
                    }
                }
            }
            .content{
                pre:not([data-language]), pre[data-language=""]{
                    background: $dark_bg !important;
                }

                code{
                    color: #FF6666 !important;
                    background: rgba(255, 209, 209, 0.16);
                }
                hr{
                    border-top: 1px solid rgba(255, 255, 255, 0.88);
                }
                #discussion_container{
                    .blk{
                        *{
                            color: $white_color; 
                        }
                    }
                    form{
                        textarea{
                            color: $white_color;
                            background: $semi_dark_bg;
                        }
                        p{
                            color: $purple_color;
                        }
                        button{
                            color: $purple_color;
                            background: rgba(204, 224, 255, 0.14);
                        }
                    } 
                }
                #todo_info_list{
                    li{
                        color: $white_color; 
                    }
                }
                #todo_task_items{
                    *{
                        color: $white_color;
                    }
                    li{
                        label{
                            .item_checkbox{
                                border: 2px solid $purple_color;
                            }
                            p{
                                a{
                                    color: $purple_color;
                                }
                            }
                        }
                        input[type="checkbox"]:checked ~ label .item_checkbox{
                            background-color: #81a0ea38;
                        }
                    }
                }
                #todo_description{
                    *{
                        color: $white_color;
                    }
                    p, li{
                        color: $white_color;
                    }
                    a{
                        color: $purple_color !important;
                    }
                    h1, h2, h3{
                        color: $white_color;
                    }
                }
            }
            #quiz_module_container{
                #quiz_results_container{
                    ol{
                        li{
                            .question_header{
                                p{
                                    color: $white_color;
                                }
                            }

                            .question_choice{
                                font-weight: 600;

                                &.wrong{
                                    background: rgba(217, 75, 75, 0.4);
                                    border-color: #F56B6B;
                                    color: #F56B6B;
                                    
                                }
                                &.correct{
                                    background: rgba(84, 204, 176, 0.4);
                                    border-color: #05DA80;
                                    color: #05DA80;
                                }
                            }
                        }
                    }
                    button{
                        border: 2px solid $purple_color;
                        color: $purple_color;
                    }
                }
                #quiz_description{
                    color: $white_color;

                    *{
                        color: $white_color;
                    }
                    p, li{
                        color: $white_color;
                    }
                    a{
                        color: $purple_color;
                    }
                    h1, h2, h3{
                        color: $white_color;
                    }
                }
                #quiz_questions_container{
                    .btns_container{
                        button#previous_question_btn{
                            background: transparent;
                            color: $white_color;
                        }

                        #show_quiz_results_btn, #next_question_btn{
                            background: rgba(204, 224, 255, 0.14);
                            border-radius: 6px;
                            color: #81A0EA;
                            font-size: 18px;
                            font-style: normal;
                            font-weight: normal;
                            line-height: 27px;
                        }

                        span{
                            color: $white_color;
                        }
                    }
                    ol{
                        > li{
                            label{
                                color: #fff;
                                background: linear-gradient(0deg, rgba(255, 255, 255, 0.14), rgba(255, 255, 255, 0.14)), #121212;
                                border: none;
                            }
                            input[type="radio"]:checked ~ label{
                                background: rgba(204, 224, 255, 0.14);
                                border: 2px solid rgba(154, 194, 255, 0.3);
                            }
                            > p{
                                color: $white_color;
                            }
                        }
                    }
                }
            }
        }
        #discussion_answer_container{
            background: $semi_dark_bg;
            color: $white_color;
            ul{
                li{
                    background: linear-gradient(0deg, rgba(255, 255, 255, 0.14), rgba(255, 255, 255, 0.14)), #121212;
                    .blk{
                        h6{
                            color: rgba(255, 255, 255, 0.58);
                        }
                        form{
                            label{
                                input:checked ~ span{
                                    &.like_icon{
                                        background-color: #407165;
                                    }
                                    &.dislike_icon{
                                        background-color: #87504d;
                                    }
                                }
                            }
                        }
                    }
                    button:not(#edit_button, #remove_button){
                        background: #333;
                        color: #A9A9A9;
                    }
                }
            }
        }
        #check_solutions_container{
            background: $semi_dark_bg;
            button, h4{
                color: $purple_color;
            }
        }
        #todo_submit_container{
            background: $semi_dark_bg;
            h5{
                color: $white_color;
            }
            #upload_solution_form{
                border: 2px dashed #91ACED;
                p{
                    color: rgba(255, 255, 255, 0.48);
                }
                label{
                    color: $purple_color;
                }
            }
            #re_upload_assignment_form{
                background: #333333;
                label{
                    background: $purple_color;
                }
            }
            #is_uploading_container{
                border: 2px dashed #91ACED;
                p{
                    color: $purple_color;
                    span{
                        color: $purple_color;
                    }
                }
                #progress_container{
                    div{
                        background: $purple_color;
                    }
                }
            }
            #right_container{
                &:before{
                    color: #E5E5E5;
                }
                form{
                    background: #4B4B4B;
                    input{
                        color: #E5E5E5;
                    }
                    svg{
                        color: rgba(255, 255, 255, 0.48);
                    }
                    button{
                        background: #81A0EA;
                    }
                    &#add_other_link_form{
                        button{
                            background: rgba(204, 224, 255, 0.14);
                            color: #81A0EA;
                        }
                    }
                }
            }
            table{
                tbody{
                    tr{
                        td{
                            &:first-child{
                                a{
                                    color: $purple_color;
                                }
                            }
                            &:nth-child(2){
                                color: $white_color;
                            }
                            &:last-child{
                                form{
                                    button{
                                        color: $purple_color;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            #uploaded_file_container{
                a{
                    color: $purple_color;
                }
                button{
                    svg{
                        color: #E5E5E5;
                    }
                }
                #successfully_submitted_indicator{
                    color: $white_color;
                    span{
                        background: rgba(217, 75, 75, 0.1);
                        color: #E1554C;
                    }
                }
            }
            #upload_assignment_form_container{
                > span{
                    color: $white_color;
                }
                form{
                    button[type=submit]{
                        color: $purple_color;
                    }
                    label{
                        background: rgba(204, 224, 255, 0.14);
                        color: $purple_color;
                    }
                    input{
                        background: transparent;
                        border: 2px solid rgba(204, 224, 255, 0.14);
                        color: $purple_color;
                    }
                }
            }
        }
        #module_navigation_container{
            a, .btn-primary{
                color: $white_color;
            }
            .btn-primary{
                background: rgba(204, 224, 255, 0.14);
                border: none;
                color: $purple_color;
            }
        }
        #privacy_policy_container{
            button{
                color: $white_color;
            }
        }
    }
    #faq_container{
        background: #2a2a2a;
        #toggle_show_faq_btn{
            color: $white_color;
        }
        h5{
            color: $white_color;
        }
        ul{
            li{
                border-bottom: 2px solid rgba(255, 255, 255, 0.18);
                p{
                    color: $white_color;
                }
                .question_head{
                    .question_title{
                        color: $white_color;
                    }
                    .creator_container{
                        .details {
                            .position, .name{
                                color: $white_color;
                            }
                        }
                    }
                }
            }
        }
    }
    #progress_container{
        a{
            color: $purple_color;
        }

        #no_tracks_progress_indicator{
            color: $white_color;
        }

        #student_progress_header{
            background: $semi_dark_bg;
            .left_container{
                h6{
                    margin-bottom: 22px;
                    color: $white_color;
                }
                p{
                    color: rgba(255, 255, 255, 0.58);
                }
            }
        }
        .blk{
            button{
                color: $white_color;
                &.active{
                    color: $purple_color;
                }
            }
            background: #292929;
            ul{
                background: #2F2F2F;
            }
            #accordion_container{
                .accordion{
                    .card{
                        background: #333333;
                        border: none;
                        .collapse, .collapsing{
                            >span{
                                color: $white_color;
                            }
                            table{
                                border-collapse: collapse;
                                border-spacing: 0;
                                thead{
                                    tr{
                                        th{
                                            color: $white_color;
                                            opacity: 0.3;
                                        }
                                    }
                                }
                                tbody{
                                    tr{
                                        td{
                                            color: $white_color;
                                            border-bottom: 1px solid  rgba(154, 194, 255, 0.3);
                                            a{
                                                svg{
                                                    color: $purple_color;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        .card-header{
                            .right_container{
                                button{
                                    color: $purple_color;
                                }
                                .badge{
                                    background: rgba(84, 204, 176, 0.4);
                                    &.blue{
                                        background: $semi_dark_bg;
                                        color: $purple_color;
                                    }
                                }
                            }
                            .left_container{
                                h6{
                                    color: $white_color;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    #contact_us_container{
        p.bold{
            color: $white_color;
        }
        p{
            color: $white_color;
            a, button{
                color: $purple_color;
            }
        }
    }

    .popover {
        &#course_completed_popover{
            background: linear-gradient(0deg, rgba(255, 255, 255, 0.14), rgba(255, 255, 255, 0.14)), #121212;
            border: none;
    
            *{
                color: $white_color !important;
            }
            .popover-header{
                background: linear-gradient(0deg, rgba(255, 255, 255, 0.14), rgba(255, 255, 255, 0.14)), #121212;
                
                &:before{
                    display: none;
                }
            }
    
            .popover-body{
                .course_completed_progress_bar{
                    .late_progress{
                        &:before{
                            background-color: transparent;
                        }
                    }
                }
            }
        }
    }
    .skeleton_loading{
        background-image: linear-gradient(to right, #2A2A2A 25%, #1C1C1C
        50%, #2A2A2A 75%);
    }
}

