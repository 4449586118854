#todo_submit_container{
    align-items: center;
    background: #FFFFFF;
    border-radius: 6px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
    display: flex;
    flex-wrap: wrap;
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    justify-content: center;
    margin-top: 20px;
    padding: 21px 30px 50px 30px;
    width: 100%;
    #todo_submit_error{
        color: #E1554C;
        display: flex;
        font-family: "Poppins", sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: 21px;
        margin-bottom: 14px;
        text-align: left;
        width: 100%;
    }

    h5{
        color: #152C61;
        font-family: "Poppins", sans-serif;
        font-size: 26px;
        font-style: normal;
        font-weight: 500;
        line-height: 39px;
        margin-bottom: 8px;
        width: 100%;
    }

    >p{
        color: rgba(0, 0, 0, 0.62);
        display: flex;
        font-family: "Poppins", sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 180%;
        width: 100%;

        a{
            color: #2C6BFF;
            margin: 0px 5px;
        }
    }

    #uploaded_file_container{
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 10px auto 0px auto;
        margin-top: 24px;
        padding-left: 42px;
        position: relative;
        width: 643px;
        
        a{
            color: #2C6BFF;
            display: block;
            font-family: "Poppins", sans-serif;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px;
            max-width: 567px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        img{
            left: 0;
            position: absolute;
            top: 3px;
        }

        form{
            margin-bottom: 2px;

            button.download-assignment{
                color: #2C6BFF;
                display: block;
                font-family: "Poppins", sans-serif;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 18px;
                max-width: 567px;
                outline: none;
                overflow: hidden;
                position: relative !important;
                text-overflow: ellipsis;
                white-space: nowrap;

                span.download_loader{
                    background-image: url(https://assets.codingdojo.com/learn_platform/global/loading.svg);
                    background-size: cover;
                    display: inline-block;
                    height: 17px;
                    margin-right: 5px;
                    vertical-align: middle;
                    width: 17px;
                }
            }

            button{
                background: transparent;
                border: none;
                font-size: 12px;
                outline: none;
                position: absolute;
                right: 0px;
                top: 0px;
                &:hover{
                    opacity: .8;
                }
                svg{
                    color: #152C61;
                }
            }
        }
        #successfully_submitted_indicator{
            color: #000000;
            display: flex;
            font-family: "Poppins", sans-serif;
            font-size: 11px;
            font-style: normal;
            font-weight: normal;
            line-height: 18px;
    
            time{
                margin-left: 5px;
            }
            span{
                background: rgba(217, 75, 75, 0.4);
                border-radius: 4px;
                color: #F56B6B;
                font-size: 12px;
                font-weight: 600;
                margin-left: 7px;
                padding: 0px 7px;
            }
        }
    }

    #is_uploading_container{
        border: 2px dashed #6190FF;
        border-radius: 6px;
        box-sizing: border-box;
        display: inline-flex;
        flex-direction: column;
        height: 160px;
        justify-content: center;
        margin-top: 24px;
        padding-left: 90px;
        position: relative;
        width: 480px;

        img{
            left: 43px;
            position: absolute;
        }
        p{
            align-items: center;
            color: #152C61;
            display: flex;
            font-size: 12px;
            font-weight: 500;
            margin-bottom: 0px;
            padding-right: 40px;
            position: relative;
            width: 283px;
            
            span{
                &:first-child{
                    display: block;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    width: 230px;
                }

                &:last-child{
                    color: #295EDB;
                    position: absolute;
                    right: 0px;
                }
            }

            span.loading_icon{
                background-image: url(https://assets.codingdojo.com/learn_platform/global/loading.svg);
                background-size: contain;
                display: inline-block;
                height: 20px;
                left: 10px;
                position: relative;
                width: 20px;
            }
        }

        #progress_container{
            background: #D8E1F8;
            border-radius: 20px;
            height: 6px;
            overflow: hidden;
            width: 283px;

            div{
                background: #295EDB;
                height: 6px;
            }
        }  
    }

    #upload_solution_form{
        align-items: center;
        border: 2px dashed #6190FF;
        border-radius: 6px;
        box-sizing: border-box;
        display: inline-flex;
        flex-direction: column;
        height: 160px;
        justify-content: center;
        margin-top: 24px;
        width: 480px;

        &.is_active_drag{
            border: 1px solid red;
        }
        img{
            filter: drop-shadow(0px 100px 80px rgba(0, 0, 0, 0.07)) drop-shadow(0px 41.7776px 33.4221px rgba(0, 0, 0, 0.0503198)) drop-shadow(0px 22.3363px 17.869px rgba(0, 0, 0, 0.0417275)) drop-shadow(0px 12.5216px 10.0172px rgba(0, 0, 0, 0.035)) drop-shadow(0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0282725)) drop-shadow(0px 2.76726px 2.21381px rgba(0, 0, 0, 0.0196802));
            margin-bottom: 13px;
            margin-top: 20px;
        }
        p{
            color: #8A8A8A;
            margin-bottom: 7px;
        }
        small{
            color: #8A8A8A;
        }
        input{
            display: none;
        }
        label{
            color: #2C6BFF;
            cursor: pointer;
            &:hover{
                opacity: .8;
            }
        }
      
    }
    #re_upload_assignment_form{
        align-items: center;
        background: #FEFBFB;
        border: 2px dashed #E87E77;
        border-radius: 6px;
        display: inline-flex;
        flex-direction: column;
        height: 160px;
        justify-content: center;
        margin-top: 24px;
        width: 408px;

        p{
            color: #E1554C;
            font-size: 12px;
            margin-bottom: 11px;
            margin-top: 10px;
        }
        input{
            display: none;
        }
        label{
            align-items: center;
            background: #295EDB;
            border-radius: 6px;
            color: #fff;
            cursor: pointer;
            display: flex;
            font-size: 14px;
            height: 33px;
            justify-content: center;
            width: 120px;
            &:hover{
                opacity: .8;
            }
        }
    }

    form{
        &.disable_form{
            opacity: .5;
            pointer-events: none !important;
        }

        button[disabled]{
            opacity: .5;
        }
    }
   
    #right_container{
        margin-top: 30px;
        padding-left: 100px;
        position: relative;
        &:before{
            color: #152C61;
            content: "Or";
            font-family: "Poppins", sans-serif;
            font-weight: 500;
            left: 40px;
            position: absolute;
            top: 19px;
        }
        p{
            color: #E1554C;
            font-family: "Poppins", sans-serif;
            font-size: 12px;
            font-weight: 500;
            margin-bottom: 20px;
            margin-top: -10px;
        }

        form{
            align-items: center;
            background: #F0F0F0;
            border-radius: 6px;
            display: flex;
            height: 60px;
            justify-content: center;
            margin-bottom: 20px;
            padding: 20px;
            position: relative;
            width: 480px;

            &.border_red{
                border: 1px solid #E1554C;
            }            
            
            &#add_other_link_form{
                margin-bottom: 12px;
                button{
                    background: #505170;
                }
            }
            svg{
                color: #8A8A8A;
                font-size: 18px;
                left: 20px;
                position: absolute;
            }
           
            input{
                background: transparent;
                border: none;
                color: #152C61;
                font-family: "Poppins", sans-serif;
                margin-bottom: 0px;
                outline: none !important;
                padding: 0px 26px;
                width: 100%;
                &:focus{
                    padding-left: 0px;
                }
            }
            input:focus ~ svg{
                display: none;
            }
            button{
                background: #295EDB;
                border: none;
                border-radius: 6px;
                color: #fff;
                font-family: "Poppins", sans-serif;
                font-size: 14px;
                height: 33px;
                width: 59px;
            }
        }
    }

    #upload_assignment_form_container{
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        margin: 6px auto 0px ;
        width: 762px;

        >img{
            height: 50px;
            margin-bottom: 20px;
            padding: 0px 357px;
            width: 100%;
        }

        >span{
            color: #152C61;
            font-family: "Poppins", sans-serif;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 27px;
            margin: 0px 60px;
            text-align: center;
        }
        form{
            input[type=file]{
                display: none;
            }
            label{
                align-items: center;
                background: #505170;
                border: none;
                border-radius: 6px;
                color: #FFFFFF;      
                cursor: pointer;
                display: flex;
                font-family: "Poppins", sans-serif;
                font-size: 18px;
                font-style: normal;
                font-weight: 500;
                height: 46px;   
                justify-content: center;
                line-height: 27px;
                margin-bottom: 0px;
                outline: none;   
                width: 191px;
            }
            input{
                background: #FFFFFF;
                border: 2px solid #C1C1C1;
                border-radius: 6px;
                color: rgba(0, 0, 0, 0.6); 
                font-family: "Poppins", sans-serif;
                font-size: 18px;
                font-style: normal;
                font-weight: normal;
                height: 46px;
                line-height: 27px;
                text-align: center;
                width: 252px;
            }
            button[type=submit]{
                background-color: transparent;
                border: none;
                color: #2C6BFF;
                font-family: "Poppins", sans-serif;
                font-size: 18px;
                font-style: normal;
                font-weight: 500;
                line-height: 27px;
                margin-left: 22px;
                outline: none;
                text-align: center;
                width: 158px;
            }
        }
    }
}

body.is_dark_mode{
    #todo_submit_container{
        >p{
            color: rgba(255, 255, 255, 0.48);

            a{
                color: #81A0EA;
            }
        }
    }
}

@media only screen and (max-width: 768px){
    #todo_submit_container{
        margin: 20px auto 0px;
        padding: 20px 20px;
        width: calc(100% - 40px);
        &.hide_upload{
            #upload_solution_form, #re_upload_assignment_form{
                display: none;
            }
            #right_container{
                display: block;
                margin-top: 0px;
            }
        }

        #mobile_tab_container{
            align-items: flex-start;
            display: flex;
            margin-bottom: 24px;
            width: 100%;
            button{
                background: transparent;
                border: none;
                font-family: "Poppins", sans-serif;
                font-size: 14px;
                margin-right: 24px;
                outline: none !important;
                padding: 0 0 5px 0;
                &.active{
                    border-bottom: 2px solid #2C6BFF;
                    font-weight: 600;
                }
            }
        }
    
        h5{
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 12px;
        }
        #right_container{
            display: none;
            max-width: 380px;
            padding-left: 0px;
            width: 100%;
            &:before{
                display: none;
            }
            #submit_assignment_btn{
                background: #505170;
                border: none;
                border-radius: 6px;
                color: #ffffff;
                height: 60px;
                width: 100%;
            }
            form{
                margin-bottom: 12px;
                width: 100%;
                input{
                    font-size: 14px;
                }
                button{
                    display: none;
                }
                svg{
                    font-size: 15px;
                }
    
            }
        }
        #upload_solution_form{
            margin-top: 0px;
            order: 3;
        }
        #uploaded_file_container{
            margin-top: 0px;
            width: 100%;
            #successfully_submitted_indicator{
                flex-wrap: wrap;
            }
            form{
                width: 100%;
            }
            a{
                width: calc(100% - 33px);
            }
        }

        #is_uploading_container{
            margin-top: 0px;
            width: 100%;

            p{
                width: 100%;

                span{
                    &:first-child{
                        display: block;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        width: calc(100% - 60px);
                    }

                    &:last-child{
                        right: 40px;
                    }
                }
            }

            #progress_container{
                width: calc(100% - 40px);
            }
        }
    }
    body.is_dark_mode{
        #todo_submit_container{
            #mobile_tab_container{
                button{
                    color: rgba(255, 255, 255, 0.88);
                    &.active{
                        border-bottom: 2px solid #505170;
                        color: #81a0ea;
                    }
                }
            }
        }
    }
}
#check_solutions_container{
    background: #FFFFFF;
    border-radius: 6px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
    cursor: pointer;
    display: flex;
    justify-content: center;
    margin-top: 20px;
    padding: 21px 30px 19px 30px;
    width: 100%;

    h4{
        color: #2C6BFF;
        font-family: "Poppins", sans-serif;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 27px;
        margin-bottom: 0px;
    }

    button{
        align-items: center;
        background-color: transparent;
        border: none;
        color: #2C6BFF;
        display: flex;
        font-family: "Poppins", sans-serif;
        font-size: 26px;
        font-style: normal;
        font-weight: 500;
        line-height: 39px;
        outline: none;
        padding: 0px;
    }
    @media only screen and (max-width: 768px){
        margin: 20px auto 0px;
        padding: 20px;
        width: calc(100% - 40px);
    
        button{
            font-size: 16px;
            line-height: 24px;
        }
    }
}