
#google_rating_modal, #trustpilot_rating_modal{
    max-width: 802px;
    width: 802px;

    &#google_rating_modal{
        .modal-content{
            .modal-footer{
                a, button{
                    .rating_button_icon{
                        background-position: -90px 0px;
                    }
                }
            }
        }
    }
    &#trustpilot_rating_modal{
        .modal-content{
            .modal-footer{
                a, button{
                    .rating_button_icon{
                        background-position: -113px 0px;
                    }
                }
            }
        }
    }
    .modal-content{
        background: #FFFFFF;
        border-radius: 6px;
        box-shadow: 0px 4px 2px rgba(0, 0, 0, 0.05);
        
        .modal-header{
            border: none;
            justify-content: center;
            padding: 61px 40px 34px 40px;
            position: relative;

            .modal-title{
                color: #152C61;
                font-family: 'Poppins', sans-serif;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 24px;
                text-align: center;
                width: 356px;
            }
            button{
                background-color: transparent;
                border: none;
                outline: none;
                padding: 0px;
                position: absolute;
                right: 26px;
                top: 26px;
            }
        }

        .modal-body{
            padding: 5px 0px 0px 0px;
            text-align: center;

            .rating_icon{
                background-image: url("https://assets.codingdojo.com/learn_platform/user/dashboard/rating_modal_icons.png");
                background-position: 0px 0px;
                height: 90px;
                margin: 0px auto 41px;
                width: 90px;
            }

            p{
                color: #152C61;
                font-family: 'Poppins', sans-serif;
                font-size: 16px;
                font-style: normal;
                font-weight: normal;
                line-height: 24px;
                margin: 0px auto 24px auto;
                text-align: center;
                width: 489px;
            }
        }

        .modal-footer{
            border: none;
            justify-content: center;
            padding-bottom: 60px;
            >*{
                margin: 0px;
            }
            a, button{
                align-items: center;
                background-color: #2C6BFF;
                border-radius: 6px;
                border: none;
                color: #fff;
                display: flex;
                font-family: 'Poppins', sans-serif;
                font-size: 18px;
                font-style: normal;
                font-weight: 500;
                height: 46px;
                justify-content: center;
                line-height: 27px;
                outline: none;
                text-align: center;
                width: 380px;

                .rating_button_icon{
                    background-image: url("https://assets.codingdojo.com/learn_platform/user/dashboard/rating_modal_icons.png");
                    height: 24px;
                    margin-right: 12px;
                    width: 24px;
                }
            }
        }
    }
}
