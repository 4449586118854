$Poppins: 'Poppins', sans-serif;
.tooltip{
    &.user_name_tooltip{
        .tooltip-inner{
            background: #121212 !important;
        }
        .arrow:before {
            border-top-color: #121212 !important;
        }

    }
} 
#user_profile_popover{
    color: #E5E5E5;
    font-family: $Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    max-width: 300px;
    z-index: 99991;
    
    .popover-header, .popover-body{
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.24), rgba(255, 255, 255, 0.24)), #121212;
        border-radius: 2px;
        border: none;
        padding: 20px;
    }
    .popover-header{
        padding: 17px 20px 0px;
        p{
            color: #B3B3B3;
            font-size: 14px;
            font-weight: 400;            
        }
        label{
            border-radius: 100%;
            border: 2px solid #293854;
            cursor: pointer;
            height: 40px;
            left: 20px;
            margin-bottom: 0px;
            overflow: hidden;
            padding: 2px;
            position: absolute;
            position: absolute;
            text-align: center;
            width: 40px;
            &:hover{
                background-image: url("https://assets.codingdojo.com/learn_platform/global/profile_placeholder.png");
                background-repeat: no-repeat;
                background-size: cover;
                svg{
                    background-color: #37373785;
                    border-radius: 100%;
                    color: #81A0EA;
                    display: inline-block;
                    font-size: 16px;
                    height: 40px;
                    margin-left: -4px;
                    margin-top: -4px;
                    padding: 12px;
                    width: 40px;
                }
                img{
                    display: none;
                }
            }
            svg{
                display: none;
            }
            .is_uploading{
                margin-bottom: 3px;
            }
            img{
                border-radius: 100%;
                cursor: pointer;
                height: 100%;
                width: 100%;
            }
        }
        #info_container{
            border-bottom: 1px solid #5D5D5D;
            padding-bottom: 16px;
            padding-left: 49px;
            p{
                font-size: 12px;
                margin-bottom: 0px;
            }
            h6{
                display: flex;
                margin-bottom: 1px;
                padding-top: 1px;
                .name{
                    cursor: pointer;
                    display: inline-block;
                    font-size: 14px;
                    font-weight: 600;
                    margin-top: 2px;
                    max-width: 78px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    vertical-align: top;
                    white-space: nowrap;
                }
                .user_level{
                    background: rgba(204, 224, 255, 0.14);
                    border-radius: 4px;
                    color: #81A0EA;
                    display: inline-block;
                    font-size: 12px;
                    margin-left: 5px;
                    padding: 4px 10px;
                    vertical-align: top;
                }
            }
        }
    }
    .popover-body{
        h6{
            color: #B3B3B3;
            font-size: 12px;
            font-weight: 400;
            margin-bottom: 22px;
        }
        ul{
            list-style: none;
            padding-left: 0px;
            &:nth-child(2){
                border-bottom: 1px solid #5D5D5D;
                margin-bottom: 20px;
                padding-bottom: 4px;
            }
            &:last-child{
                margin-bottom: 6px;
                li{
                    &:last-child{
                        margin-bottom: 0px;
                    }
                }
            }
            li{
                border-radius: 2px;
                margin-bottom: 16px;
                padding: 5px 10px;
                &:hover{
                    background: rgba(129, 160, 234, 0.3);
                }
                a, button{
                    align-items: center;
                    background: transparent;
                    border: none;
                    color: #E5E5E5;
                    display: flex;
                    outline: none !important;
                    padding: 0px;
                    text-decoration: none;
                    .icon{
                        background-image: url("https://assets.codingdojo.com/learn_platform/admin/rostering/user_profile_icons.png");
                        display: inline-block;
                        height: 14px;
                        margin-right: 14px;
                        width: 14px;
                        &#lp3_user_view_icon{
                            background-position: -3px -3px;   
                        }
                        &#lp2_admin_view_icon{
                            background-position: -24px -3px;
                        }
                        &#lets_rock_crm_view_icon{
                            background-position: -45px -3px;
                        }
                        &#change_password_icon{
                            background-position: -66px -3px;
                        }
                        &#logout_icon{
                            background-position: -87px -3px;
                        }
                    }
                }

            }
        }
    }
    &[x-placement^=left]>.arrow::after, >.arrow::after{
        border-left-color: #4b4b4b;
    }
}