
$Poppins: "Poppins", sans-serif;

.product_tour_tooltip{
    background: #fff;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    padding: 20px;
    position: relative;
    width: 425px;

    .step{
        background: #fff;
        border-radius: 50%;
        border: 2px solid #2c6bff;
        color: #2c6bff;
        display: grid;
        font: 500 16px/24px $Poppins;
        height: 35px;
        left: -20px;
        place-items: center;
        position: absolute;
        top: -10px;
        width: 35px;
    }

    .current_step{
        display: none;
    }

    > .content{
        color: #152c61;
        font: 400 12px/15.6px $Poppins;
        margin-bottom: 35px;
    }

    > h3{
        color: #DCE6FF;
        font: 500 14px/21px $Poppins;        
        margin-bottom: 10px;
    }

    .product_tour_btn_container{
        display: flex;
        margin-top: 5px;
        width: 100%;

        button {
            font: 400 14px/21px $Poppins;
            height: 30px;

            &.skip_btn{
                background: none;
                border-radius: 6px;
                border: 1px solid #dce6ff;
                color: #2c6bff;
                margin-right: auto;
                width: 80px;
            }
    
            &.prev_btn{
                background: none;
                border: none;
                color: #2c6bff;
                margin-left: auto;
                margin-right: 20px;
                outline: none;

                &:disabled{
                    cursor: initial;
                    opacity: .5;
                }
            }
    
            &.next_btn{
                background: #2c6bff;
                border-radius: 6px;
                border: none;
                color: #fff;
                font: 400 14px/21px $Poppins;
                outline: none;
                width: 80px;
            }
        }
    }
}

.is_dark_mode{
    .product_tour_tooltip{
        background: #444444;

        & + .__floater__arrow{
            span{
                svg{
                    polygon{
                        fill: #444444;
                    }
                }
            }
        }

        > .content{
            color: #fff;
        }

        button{
            &.skip_btn{
                border: 1px solid #81a0ea;
                color: #81a0ea;
            }
            &.next_btn{
                background: #81a0ea;
                color: #FFFFFF;
            }
            &.prev_btn{
                color: #81a0ea;
            }
        }
    }
}

@media only screen and (max-width: 768px){
    .product_tour_tooltip{
        .step{
            display: none;
        }
        .current_step{
            display: initial;
        }
    }
    .__floater__open{
        &[x-placement="bottom-start"]{
            .product_tour_tooltip{
                .step{
                    top: -15px;
                }
            }
        }
    }
}
