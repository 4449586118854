$Poppins: "Poppins", sans-serif;

#subscriptions_container{
    width: 790px;

    > h1{
        color: #152C61;
        font: 700 32px/65px $Poppins;
        text-align: center;
    }
    > p{
        color: #152C61;
        font: 500 18px/27px $Poppins;
        text-align: center;
    }

    #subscriptions_options{
        display: flex;
        gap: 45px;
        justify-content: center;
        padding: 40px 0;

        .subscription{
            align-items: center;
            background: linear-gradient(212.99deg, rgba(182, 211, 255, 0.25) 5.69%, rgba(68, 87, 255, 0) 93.13%);
            border-radius: 6px;
            cursor: pointer;
            display: flex;
            flex-direction: column;
            height: 270px;
            justify-content: center;
            outline: 1px solid rgba(44, 107, 255, 0.25);
            position: relative;
            width: 355px;

            &.active{
                outline: 3px solid #2C6BFF;
                position: relative;

                &::before{
                    background: url("https://assets.codingdojo.com/learn_platform/global/alumni_pass_sprite.png") no-repeat -938px -27px;
                    content: "";
                    height: 45px;
                    position: absolute;
                    top: -25px;
                    width: 45px;
                }
            }

            .price{
                color: #4561A1;
                font: 700 63px/40px $Poppins;
            }

            .plan{
                color: #152C61;
                font: 500 29px $Poppins;
                margin-bottom: 0;
                text-transform: uppercase;
            }

            span{
                align-items: center;
                background: url("https://assets.codingdojo.com/learn_platform/global/alumni_pass_sprite.png") no-repeat -692px -33px;
                color: #fff;
                display: block;
                display: flex;
                font: 500 12px $Poppins;
                height: 35px;
                justify-content: center;
                padding-bottom: 5px;
                position: absolute;
                right: -37px;
                top: 22px;
                transform: rotate(41.32deg);
                width: 166px;
            }
        }
    }

    #actions_container{
        display: flex;
        justify-content: space-between;

        > button, a{
            background: none;
            border-radius: 6px;
            border: none;
            font: 500 16px/24px $Poppins;
            height: 60px;
            outline: none;
            width: 230px;

            &.btn_logout{
                align-items: center;
                border: 2px solid #2C6BFF;
                color: #2C6BFF;
                display: flex;
                justify-content: center;
                text-decoration: none;
            }
        
            &.btn_continue{
                background: #2C6BFF;
                color: #fff;

                &:disabled{
                    cursor: not-allowed;
                    opacity: .5;
                }
            }
        }
    }
}

@media only screen and (max-width: 625px){
    #alumni_pass_page_container{
        overflow: hidden;
    }

    #subscriptions_options{
        align-items: center;
        flex-direction: column;
        width: 100% !important;
        .subscription{
            max-width: 355px !important;
            width: 100% !important;
        }
    }

    #subscriptions_container{
        #actions_container{
            flex-direction: column-reverse;
            gap: 20px;
            > button, a{
                width: 100%;

                &.btn_logout{
                    margin-bottom: 20px;
                }
            }
        }
    }
}

.is_dark_mode #subscriptions_container{

    h1, p{
        color: #fff;
    }

    #subscriptions_options{
        .subscription{
            background: #333333;
            outline: 1px solid rgba(129, 160, 234, 0.25);
            &.active{
                outline-color: #81A0EA;
            }

            .price{
                color: #fff;
            }

            .plan{
                color: #fff;
            }
        }
    }

    #actions_container{
        > button, a{
            &.btn_logout{
                border-color: #81A0EA;
                color: #81A0EA;
            }
        
            &.btn_continue{
                background: #81A0EA;
            }
        }
    }
}