$Poppins: "Poppins", sans-serif;
$blue: #2C6BFF;
$white: #fff;

.table_container#course_unit_table{
    border-radius: 4px;
    height: 100%;
    margin-top: 0px;
    overflow: auto;
    position: relative;
    width: 100%;
    *::-webkit-scrollbar {
        height: 9px;
        width: 9px;
    }
    .sticky-table{
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.14), rgba(255, 255, 255, 0.14)), #121212;
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.07);
        width: 100%;
        &.table_loading{
            .sticky-table-table{
                height: 100%;
                #no_results_found{
                    align-items: center;
                    justify-content: center;
                    width: 50px;
                }
            }
        }
        .checkbox{
            display: flex;
            justify-content: center;
            input[type="checkbox"]{
                display: none;
            }
            .fa-check{
                display: none;
            }
            input[type="checkbox"]:checked ~ label{
                .checkbox_container{
                    background-color: $blue;
                    .fa-check{
                        color: $white;
                        display: inline;
                        font-size: 10px;
                    }
                }    
            }
            label{
                align-items: center;
                cursor: pointer;
                display: flex;
                margin-bottom: 0px;
                .checkbox_container{ 
                    align-items: center;
                    background: $white;
                    border-radius: 4px;
                    border: 1px solid transparent;
                    cursor: pointer;
                    display: flex;
                    height: 15px;
                    justify-content: center;
                    width: 15px;
                }                       
            }
        }
        .sticky-table-row{
            /* Table Head */
            &:nth-child(1){
                .sticky-table-cell{
                    background: linear-gradient(0deg, rgba(255, 255, 255, 0.24), rgba(255, 255, 255, 0.24)), #121212;
                    color: rgba(255, 255, 255, 0.6);
                    cursor: unset;
                    font: normal 600 12px/130% $Poppins;
                    width: 100% !important;
                    &:nth-child(1){
                        text-align: unset;
                        > .checkbox{
                            position: absolute;
                        }
                        span{
                            margin-left: 40px;
                        }
                    }
                    &:nth-child(2){
                        width: unset;
                    }
                    &:last-child{
                        width: 100%;
                    }
                    span{
                        display: inline-block;
                        vertical-align: bottom;
                    }
                    .sort_icon_container{
                        display: inline-flex;
                        flex-direction: column;
                        margin-left: 5px;
                        svg{
                            color: rgba(255, 255, 255, 0.6);
                            height: 12px;
                            transform: translateY(1.5px) scale(1.2);
                            width: 10px;
                            &.fa-caret-up.sort_desc{
                                color: #81A0EA !important;
                            }
                            &.fa-caret-down.sort_asc{
                                color: #81A0EA !important;
                            }
                        }
                    }
                }
            }
            &.no_unit_container{
                position: relative;
                p{  
                    color: #fff;
                    left: 50%;
                    opacity: .5;
                    position: absolute;
                    top: 40px;
                    transform: translateX(-50%);

                    button{
                        background: none;
                        border: none;
                        color: #81A0EA;
                        padding: 0;
                        &:hover{
                            text-decoration: underline;
                        }

                    }
                }
            }
        }
        .react_sortable{
            display: contents;
            /* Table Body */
            .sticky-table-row:nth-child(n+1){
                &.disable_row{
                    pointer-events: none !important;
                    button{
                        display: none !important;
                    }
                    .checkbox{
                        display: none !important;
                    }
                    .drag_drop_icon{
                        display: none !important;
                    }
                }
                height: 86px;
                .sticky-table-cell{
                    background: #333333;
                    border-top: 1px solid #5D5D5D;
                    color: $white;
                    cursor: unset;
                    font: normal 400 14px/130% $Poppins;
                    position: unset;
                    &:nth-child(1){
                        > div{
                            align-items: center;
                            display: flex;
                            .checkbox{
                                margin-right: 25px;
                            }
                            .drag_drop_icon{
                                background: url("https://assets.codingdojo.com/learn_platform/admin/curriculum_management/admin_curriculum_management_sprite.png") no-repeat -144px -17px;
                                cursor: grab;
                                height: 27px;
                                margin-right: 25px;
                                width: 13px;
                            }
                            img{
                                margin-right: 20px;
                                width: 22px;
                            }
                            .unit_name{
                                align-items: center;
                                color: $white;
                                display: flex;
                                font: normal 400 14px/130% $Poppins;
                                margin-bottom: 6px;
                                span{
                                    background: #595959;
                                    border-radius: 20px;
                                    color: rgba(255, 255, 255, 0.8);
                                    font: normal 400 8px/130% $Poppins;
                                    margin-left: 20px;
                                    padding-block: 4px;
                                    padding-inline: 12px;
                                    text-align: center;
                                }
                            }
                            .unit_description{
                                align-items: center;
                                color: rgba(255, 255, 255, 0.5);
                                display: flex;
                                font: normal 400 12px/130% $Poppins;
                                margin-bottom: 6px;
                            }
                        }
                    }
                    &:last-child{
                        button{
                            background: none;
                            border: none;
                            color: #81A0EA;
                            padding: 0;
                        }
                    }
                }
            }
        }
    }

    #units_loading{
        display: flex;
        flex-direction: column;
        left: 50%;
        position: absolute;
        text-align: center;
        top: 45%;
        transform: translate(-50%, 50%);
        div{
            background-image: url("https://codingdojo_assets.s3.us-east-1.amazonaws.com/learn_platform/admin/global/table_loader.gif");
            background-size: cover;
            height: 30px;
            margin-bottom: 7px;
            margin: 0 auto;
            mix-blend-mode: lighten;
            width: 30px;
        }
        span{
            color: rgba(255, 255, 255, 0.88);
            font: normal 500 14px/130% $Poppins;
        }
    }
}