$Poppins: "Poppins", sans-serif;
#generate_exam_code_modal{
    max-width: none;
    width: 800px;
    .modal-content{
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.24), rgba(255, 255, 255, 0.24)), #121212;
        border-radius: 0px;
        box-shadow: 0px 4px 2px rgba(0, 0, 0, 0.05);
        color: rgba(255, 255, 255, 0.88);
        font-family: $Poppins;
        .modal-header{
            background: linear-gradient(0deg, rgba(255, 255, 255, 0.14), rgba(255, 255, 255, 0.14)), #121212;
            border-radius: 0px;
            border: none;
            justify-content: left;
            padding: 18px 23px;
            position: relative;
            h4{
                font-family: 'Poppins', sans-serif;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: 16px;
                margin-bottom: 0px;
            }
            button{
                background-color: transparent;
                background-image: url("https://assets.codingdojo.com/learn_platform/admin/edit_course/admin_edit_course_sprite.png");
                background-position: -65px 0px;
                border: none;
                height: 15px;
                outline: none;
                padding: 0px;
                position: absolute;
                right: 24px;
                top: 17px;
                width: 15px;
            }
        }
        .modal-body{
            background: #292929;
            padding: 20px 24px;
            text-align: left;
            input[type="number"] {
                -moz-appearance:textfield;
            }
            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button {
                -webkit-appearance: none;
            }
            .scroll_container{
                max-height: 255px;
                overflow: auto;
            }
            button{
                outline: none !important;
            }
            .content{
                align-items: flex-start;
                display: flex;
                flex-direction: column;
                margin-bottom: 24px;
                &#exam_type_and_option_container, &#exam_start_and_end_date_container{
                    flex-direction: row;
                }
                &#exam_time_window_container{
                    input{
                        background: rgba(204, 224, 255, 0.14);
                        border-radius: 4px;
                        border: none;
                        color: rgba(255, 255, 255, 0.88);
                        font-family: "Poppins", sans-serif;
                        font-size: 12px;
                        height: 40px;
                        margin-right: 21px;
                        outline: none;
                        padding: 10px 16px;
                        width: 147px;
                    }
                    .checkbox{
                        display: flex;
                        justify-content: left;
                        
                        input[type="checkbox"]{
                            display: none;
                        }
                        .fa-check{
                            display: none;
                        }
                        input[type="checkbox"]:checked ~ label{
                            .checkbox_container{
                                background-color: #81A0EA;
                                .fa-check{
                                    color: #fff;
                                    display: inline;
                                    font-size: 10px;
                                }
                            }    
                        }
                        label{
                            align-items: center;
                            color: #E5E5E5;
                            cursor: pointer;
                            display: flex;
                            font-size: 12px;
                            margin-bottom: 0px;
                            .checkbox_container{ 
                                align-items: center;
                                background: rgba(204, 224, 255, 0.14);
                                border-radius: 4px;
                                border: 1px solid transparent;
                                cursor: pointer;
                                display: flex;
                                height: 15px;
                                justify-content: center;
                                margin-right: 10px;
                                width: 15px;
                            }                       
                        }
                    }
                    .input_container{
                        margin-bottom: 13px;
                    }
                }
                h6{
                    color: rgba(255, 255, 255, 0.4);
                    font-family: $Poppins, sans-serif;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 600;
                }
                .exam_select {
                    width: 364px;
                }
                .left_container{
                    margin-right: 20px;
                }
                
                .date_picker_container{
                    input{
                        background: rgba(204, 224, 255, 0.14);
                        border-bottom-left-radius: 4px;
                        border-top-left-radius: 4px;
                        border: none;
                        color: rgba(255, 255, 255, 0.88);
                        font-family: $Poppins, sans-serif;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 400;
                        height: 40px;
                        outline: none;
                        padding: 8px 11px;
                        vertical-align: top;
                        width: 325px;
                    }
                    button{
                        background: linear-gradient(0deg, rgba(255, 255, 255, 0.14), rgba(255, 255, 255, 0.14)), #121212;
                        border-radius: 0px 4px 4px 0px;
                        border: none;
                        color: #81A0EA;
                        height: 40px;
                        padding: 8px 13px;
                        vertical-align: top;
                    }
                }
            }

            form{
                border-bottom: 1px solid #3F3F3F;
                margin-bottom: 20px;
                padding-bottom: 20px;
                #generate_code_button{
                    align-items: center;
                    background: rgba(204, 224, 255, 0.14);
                    border-radius: 4px;
                    border: 1px solid #81A0EA;
                    color: #81A0EA;
                    display: flex;
                    font-family: $Poppins, sans-serif;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 600;
                    justify-content: space-around;
                    padding: 8px 0px;
                    text-align: center;
                    width: 100%;
                    &:disabled{
                        cursor: pointer;
                        opacity: .5;
                        pointer-events: none;
                    }
                }
                .rmdp-container{
                    .rmdp-day.rmdp-today span{
                        background: #282828;
                        color: #e8e8e9;
                    }
                    .rmdp-day.rmdp-selected span:not(.highlight){
                        background: #81A0EA;
                        box-shadow: none;
                        color: #e8e8e9;
                    }
                    .rmdp-month-picker, .rmdp-year-picker{
                        background: #282828;
                    }
                    .rmdp-disabled{
                        span{
                            background: #282828;
                            color: #404347;
                            
                        }
                    }
                    .rmdp-header-values, .rmdp-week-day, .rmdp-day{
                        color: #e8e8e9;
                        font-family: $Poppins, sans-serif;
                    }
                    .rmdp-ep-arrow{
                        display: none;
                    }
                    .rmdp-arrow {
                        border: solid #e8e8e9;
                        border-width: 0 2px 2px 0;
                        display: inline-block;
                        height: 10px;
                        margin-top: 5px;
                        padding: 2px;
                        width: 10px;
                    }
                    .rmdp-day span{
                        border-radius: 4px;
                    }
                    .rmdp-arrow-container:hover{
                        background: transparent;
                        box-shadow: none;
                        opacity: .8;
                    }
                    
                    .rmdp-wrapper{
                        background-color: #282828;
                        border-radius: 4px;
                        .rmdp-calendar{
                            padding: 0px;
                            .rmdp-header{
                                background: #282828;
                                height: unset;
                                margin-top: 0px;
                                padding: 11px 11px 0px;
                            }
                            .rmdp-week-day{
                                background: #282828;
                            }
                            .rmdp-week{
                                background: #333333;
                                padding: 0px 11px;
                                &:first-child{
                                    background: #282828;
                                    border-bottom: 1px solid #AEAEAE;
                                    padding: 0px 11px 11px;
                                }
                                &:nth-child(2){
                                    padding-top: 10px;
                                }
                                &:last-child{
                                    padding-bottom: 10px;
                                }
                            }
                            .rmdp-day-picker{
                                padding: 0px;
                            }
                        }
                    }
                }
                #has_active_code_container{
                    margin-top: 38px;
                    p{
                        color: #F56B6B;
                        font-family: $Poppins, sans-serif;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                        margin-bottom: 15px;
                        text-align: center;
                    }
                    div{
                        align-content: stretch;
                        display: flex;
                        justify-content: space-evenly;
                        button{
                            background: transparent;
                            border: none;
                            color: #81A0EA;
                            font-family: "Poppins", sans-serif, sans-serif;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 600;
                            padding: 8px 0px;
                            width: 316px;   

                        }
                        button[type="submit"]{
                            background: rgba(204, 224, 255, 0.14);
                            border-radius: 4px;
                            border: 1px solid #81A0EA;
                            display: flex;
                            justify-content: space-around;
                        }
                    }
                }
            }
            h4{
                font-size: 14px;
                text-align: center;
            }
            table{
                width: 100%;
                thead{
                    position: sticky;
                    top: 0px;
                    z-index: 1;
                    tr{
                        th{     
                            background: linear-gradient(0deg, rgba(255, 255, 255, 0.24), rgba(255, 255, 255, 0.24)), #121212;
                            border-bottom: 1px solid #5D5D5D;
                            padding: 10px;
                            &:nth-child(4){
                                button{
                                    width: 106px;
                                }
                            }
                            button{
                                background: transparent;
                                border: none;
                                color: rgba(255, 255, 255, 0.88);
                                font-family: $Poppins, sans-serif;
                                font-size: 12px;
                                font-weight: 500;
                                outline: none;
                                padding: 0px;
                            }
                            svg{
                                color: rgba(255, 255, 255, 0.4);
                                margin-left: 6px;
                            }
                        }
                    }
                }
                tbody{
                    tr{
                        &.no_results_found{
                            td{
                                padding: 20px;
                                text-align: center;
                            }
                        }
                        &.disabled_row{
                            opacity: .4;
                            td{
                                cursor: none;
                                pointer-events: none;
                                span{
                                    color: #F56B6B;

                                }
                            }
                        }
                        td{
                            background: linear-gradient(0deg, rgba(255, 255, 255, 0.14), rgba(255, 255, 255, 0.14)), #121212;
                            border-bottom: 1px solid #474747;
                            color: rgba(255, 255, 255, 0.88);
                            font-family: $Poppins, sans-serif;
                            font-size: 12px;
                            font-weight: 600;
                            padding: 10px;
                            white-space: nowrap;
                            &:nth-child(2){
                                white-space: break-spaces;
                                word-break: break-all;
                            }
                            &:first-child{ 
                                white-space: pre-wrap;
                            }
                            
                            span{
                                align-items: center;
                                background: rgba(204, 224, 255, 0.14);
                                border-radius: 4px;
                                color: #81A0EA;
                                display: inline-flex;
                                margin-left: 8px;
                                padding: 2px 10px;
                            }
                            button{
                                background: transparent;
                                border: none;
                                color: rgba(255, 255, 255, 0.88);
                                font-family: $Poppins, sans-serif;
                                font-size: 12px;
                                font-weight: 500;
                                outline: none;
                                &:hover{
                                    color: #81A0EA;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}