$Poppins: "Poppins", sans-serif;

#survey_management_table{
    -ms-overflow-style: none;
    background: #333333;
    overflow-y: auto;
    scrollbar-width: none;
    .sticky-table-table{
        width: 100%;
        .sticky-table-row{
            .sticky-table-cell{
                vertical-align: middle;
            }
            /* Table Head */
            &:nth-child(1){
                height: 40px;
                z-index: 5;
                .sticky-table-cell{
                    background: linear-gradient(0deg, rgba(255, 255, 255, 0.24), rgba(255, 255, 255, 0.24)), #121212;
                    color: rgba(255, 255, 255, 0.6);
                    cursor: pointer;
                    font-family: $Poppins;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 130%;
                    text-align: left;
                    width: unset;
                    &:first-child{
                        z-index: 5;
                        padding-left: 20px;
                    }
                    &:last-child{
                        cursor: default;
                    }
                    &:nth-child(2){
                        width: max-content;
                    }

                    .fa-caret-down, .fa-caret-up{
                        color: #fff;
                        margin-left: 6px;
                        &.light{
                            color: rgba(255, 255, 255, 0.4);
                        }
                    }
                }
            }

            /* Table Body */
            &:nth-child(n+2){
                .sticky-table-cell{
                    background: linear-gradient(0deg, rgba(255, 255, 255, 0.14), rgba(255, 255, 255, 0.14)), #121212;
                    border-top: 1px solid #5D5D5D;
                    color: rgba(255, 255, 255, 0.88);
                    font-family: $Poppins;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    text-align: left;
                    width: unset;
                    &:nth-child(2){
                        width: max-content;
                    }
                    &:nth-child(5), &:nth-child(2){
                        white-space: normal;
                    }

                    .survey_action_btn{
                        background-color: transparent;
                        background-image: url("https://assets.codingdojo.com/learn_platform/admin/survey_management/survey_management_icons.png");
                        background-position: 4px;                    
                        background-repeat: no-repeat;
                        border: none;
                        height: 29px;
                        width: 10px;
                    }
                  
                    .active{
                        background: rgba(84, 204, 176, 0.4);
                        border-radius: 4px;
                        color: #05DA80;
                        font-weight: 600;
                        padding: 2px 6px;
                    }
                    .disabled{
                        background: rgba(217, 75, 75, 0.4);
                        border-radius: 4px;
                        color: #F56B6B;
                        font-weight: 600;
                        opacity: 1;
                        padding: 2px 6px;
                        pointer-events: unset;
                    }
                    .pending{
                        background: rgba(255, 255, 255, 0.18);
                        border-radius: 4px;
                        color: rgba(255, 255, 255, 0.88);
                        font-weight: 600;
                        padding: 2px 6px;
                    }
                    &:first-child{
                        background: linear-gradient(0deg, rgba(255, 255, 255, 0.24), rgba(255, 255, 255, 0.24)), #121212;
                        font-size: 14px;
                        padding-left: 20px;
                        z-index: 3;
                    }
                    &:last-child{
                        padding-left: 30px;
                        button{
                            outline: none;
                        }
                    }

                    p{
                        margin-bottom: 0px;
                        white-space: normal;
                        word-break: break-word;
                    }
                }
            }
        }
    }

    #survey_management_action_popover{
        border: none;
        z-index: 2;
        &.bs-popover-left>.arrow::after{
            border-left-color: #4b4b4b;
        }
        &#survey_management_action_popover[data-popper-reference-hidden=true]{
            pointer-events: none;
            visibility: hidden !important;
        }
        
        .popover-body{
            background: #4B4B4B;
            border-radius: 4px;
            display: flex;
            flex-direction: column;
            padding: 12px 14px;
            button{
                align-items: center;
                background: transparent;
                border: none;
                color: #fff;
                display: flex;
                font-family: $Poppins;
                font-size: 12px;
                margin-bottom: 10px;
                outline: none;
                padding: 0px;
                &:last-child{
                    margin-bottom: 0px;
                }
                span{
                    background-image: url("https://assets.codingdojo.com/learn_platform/admin/survey_management/survey_management_icons.png");
                    background-repeat: no-repeat;
                    display: inline-block;
                    height: 16px;
                    margin-right: 14px;
                    vertical-align: top;
                    width: 14px;
                }
                .disable_icon{
                    background-position: -25px -4px;
                }
                .delete_icon{
                    background-position: -99px -4px;
                    width: 14px;
                }
                .edit_icon{
                    background-position: -139px -4px;
                    margin-right: 11px;
                    width: 17px;
                }
                .enable_icon{
                    background-position: -61px -5px;
                    margin-right: 11px;
                    width: 16px;
                }
            }
        }
        
    }
    &::-webkit-scrollbar {
        display: none;
    }

    #survey_multiple_program{
        &[x-placement="bottom"]{
            .arrow{
                &::before{
                    border-bottom-color: #4B4B4B !important;
                }
            }
        }
        &[x-placement="left"] {
            .arrow{
                &::before{
                    border-top-color: unset !important;
                    border-left-color: #4B4B4B !important;
                }
            }
        }
        .tooltip-inner{
            background: linear-gradient(0deg, rgba(255, 255, 255, 0.24) 0%, rgba(255, 255, 255, 0.24) 100%), rgba(6, 6, 6, 0.94);
            border-radius: 4px;
            border: 1px solid rgba(204, 224, 255, 0.14);
            box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.20), 0px 3px 3px 0px rgba(0, 0, 0, 0.12), 0px 3px 4px 0px rgba(0, 0, 0, 0.14);
            color: rgba(255, 255, 255, 0.88);
            font: normal 600 12px/130% $Poppins;
            max-width: 467px;
            padding: 13px 19px 13px 19px;
        }
    }
}
