#progress_container.container{
    font-family: 'Poppins', sans-serif;
    p{
        font-size: 16px;
    }
    #no_tracks_progress_indicator{
        justify-content: center;
        padding: 20px;
    }
    .blk{
        background: #fff;
        display: flex;
        button{
            background: transparent;
            border: none;
            box-shadow: none;
            color: #152C61;
            font: normal normal 16px/24px 'Poppins', sans-serif;
            opacity: 0.3;
            outline: none;
            &.active{
                color: #2C6BFF;
                font-weight: 600;
                opacity: 1;
            }
        }
        ul{
            align-items: center;
            background: #F8FAFF;
            margin-bottom: 0px;
            padding: 40px;
            width: 310px;
            li{
                margin-bottom: 41px;
                text-align: center;
                button{
                    span{
                        display: block;
                        text-align: center;
                        font-size: 0.7em;
                    }
                }
            }
        }
        #accordion_container{
            padding: 40px 30px;
            width: 100%;
            .accordion{
                .card{
                    border-radius: 2px;
                    border: 2px solid #E5EEFD;
                    box-sizing: border-box;
                    margin-bottom: 20px;
                    .card-header{
                        align-items: center;
                        background: none;
                        border: none;
                        cursor: pointer;
                        display: flex;
                        justify-content: space-between;
                        padding: 20px 30px;
                        .left_container{
                            align-items: center;
                            display: flex;
                            img{
                                height: 50px;
                                margin-right: 30px;
                                width: 46px;
                            }
                            h6{
                                color: #152C61;
                                display: inline-block;
                                font: normal 500 16px/24px 'Poppins', sans-serif;
                                margin-bottom: 0px;
                            }
                        }
                        .right_container{
                            .badge{
                                background: #E3FFE8;
                                border-radius: 6px;
                                color: #4CE164;
                                font-size: 16px;
                                margin-right: 25px;
                                padding: .25rem 12px;
                                &.blue{
                                    background: #E5EEFD;
                                    color: #2C6BFF;
                                }
                            }
                            button{
                                color: #2C6BFF;
                                font-size: 20px;
                                opacity: 1;
                            }
                        }
                    }
                    .collapse, .collapsing{
                        padding: 0px 28px;
                        >span{
                            align-items: center;
                            color: rgba(21, 44, 97, 0.6);
                            display: flex;
                            justify-content: center;
                            margin-bottom: 35px;
                            text-align: center;
                            width: 100%;
                        }
                        td,th{          
                            padding: 10px;
                            text-align: center;
                        }
                        table{
                            border-collapse: unset !important;
                            color: #152C61;
                            margin-bottom: 93px;
                            width: 100%;
                            thead{
                                tr{
                                    th{
                                        border: none;
                                        color: #152C61;
                                        font: normal normal 13px/19px 'Poppins', sans-serif;
                                        opacity: 0.3;
                                        &:nth-child(4){
                                            text-align: left;
                                        }
                                        &.estimate_time_th{
                                            width: 97px;
                                        }
                                        &.due_date_th{
                                            width: 131px;
                                        }
                                    }
                                }
                            }
                            tbody{
                                tr{
                                    td{
                                        border-bottom: 1px solid #E5EEFD;
                                        border-top: none;
                                        color: #152C61;
                                        font: normal normal 14px/21px 'Poppins', sans-serif;
                                        &:nth-child(4){
                                            text-align: left;
                                        }
                                        .outside_sched_status{
                                            color: #F2994A;
                                            svg{
                                                color: #F2994A;
                                            }
                                        }
                                        .review_pending_status{
                                            color: #E1554C;
                                        }
                                        &:first-child{
                                            text-align: left;
                                        }
                                        svg{
                                            color: #4CE164;
                                        }
                                        a{
                                            svg{
                                                color: #2C6BFF;
                                                font-size: 12px;
                                                margin-right: 8px;
                                            }
                                        }

                                        .assignment_name_container{

                                            a{
                                                line-height: 18px;
                                            }
                                            
                                            .feedback_icon{
                                                background: url("https://codingdojo_assets.s3.us-east-1.amazonaws.com/learn_platform/user/assignment/assignment_checklist_icons.png") no-repeat -48px 0px;
                                                height: 13px;
                                                margin-left: 5px;
                                                opacity: 1;
                                                width: 16px;

                                                &.unread_feedback{
                                                    background-position: -32px 0px;
                                                }
                                            }
                                        }

                                        .assignment_file_container {

                                            button{
                                                background: transparent;
                                                border: none;
                                                line-height: 18px;
                                                opacity: 1!important;

                                                svg{
                                                    color: #2C6BFF;
                                                    font-size: 12px;
                                                    margin-right: 8px;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
@media only screen and (max-width: 768px){
    #progress_container.container{
        padding: 22px 20px 100px;
        width: 100%;
        .hide_courses{
            #accordion_container{
                display: none;
            }
        }
        .show_courses{
            ul{
                display: none;
            }
        }
        .blk{
            background: #FBFBFB;
            flex-direction: column;
            padding-top: 31px;
            button{
                color: #B9C0D0;
                opacity: 1;
                text-align: left;
                &.active{
                    color: #B9C0D0;
                    font-weight: normal;
                }
            }
            #accordion_container{
                padding: 0px 0px;
                h5{
                    border-bottom: 1px solid #E4E6EC;
                    font: 500 normal 16px/24px 'Poppins', sans-serif;
                    margin-bottom: 24px;
                    padding-bottom: 16px;
                    padding-left: 30px;
                    text-align: center;
                    button{
                        color: #152C61;
                        left: 20px;
                        position: absolute;
                    }
                }
                .accordion{
                    .card{
                        border-radius: 6px;
                        border: none;
                        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
                        margin-bottom: 25px;
                        .collapse, .collapsing{
                            overflow-y: auto;
                            table{
                                margin-bottom: 21px;
                                width: 780px;
                            }
                        }
                        .card-header{
                            padding: 20px 16px;
                            .left_container{
                                max-width: calc(100% - 115px);
                                padding-right: 10px;
                                img{
                                    height: 34px;
                                    margin-right: 14px;
                                    width: 30px;
                                }
                                h6{
                                    word-break: break-all;
                                }
                            }
                            .right_container{
                                text-align: right;
                                width: 115px;
                                button{
                                    font-size: 14px;
                                }
                                .badge{
                                    margin-right: 9px;
                                }
                            }
                        }
                    }
                }
            }
          
            ul{
                background: #ffffff;
                border-radius: 6px;
                padding: 20px 10px;
                width: 100%;
                li{
                    margin: 19px 0px;
                    text-align: left;
                    width: 100%;
                    &:first-child{
                        margin-top: 0px;
                    }
                    &:last-child{
                        margin-bottom: 0px;
                    }
                    button{
                        align-items: center;
                        display: flex;
                        justify-content: space-between;
                        outline: none;
                        width: 100%;
                        svg{
                            color: #2C6BFF;
                        }
                    }
                }
            }
        }
    }
    .is_dark_mode{
        #progress_container.container{
            .blk{
                background: #121212;
                ul{
                    li{
                        button{
                            &.active{
                                color: rgba(255, 255, 255, 0.88);
                            }
                            svg{
                                color: #81A0EA;
                            }
                        }
                    }
                }
                #accordion_container{
                    h5{
                        border-bottom: 1px solid #1e2022;
                        color: rgba(255, 255, 255, 0.88);
                        button{
                            color: #81A0EA;
                        }
                    }
                }
            }

        }
    }
}