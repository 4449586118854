$Poppins: 'Poppins', sans-serif;
$blue: #81A0EA;
$white: rgba(255, 255, 255, 0.88);

.assignment_details_popover.popover{
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.24), rgba(255, 255, 255, 0.24)), rgba(6, 6, 6, 0.94);
    border-radius: 4px;
    border: 1px solid rgba(204, 224, 255, 0.14);
    box-sizing: border-box;
    color: #fff;
    font-family: $Poppins;
    font-size: 12px;
    max-width: 429px;
    padding: 20px 20px 23px 20px;
    width: 429px;

    &.behind{
        z-index: 0;
    }

    &[x-placement^=bottom]>.arrow::after, >.arrow::after{
        border-bottom-color: #4a4a4a;
    }
    &[x-placement^=top]>.arrow::after, >.arrow::after{
        border-top-color: #4a4a4a;
    }

    .popover-body{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 0px;

        &.loading_container{
            align-items: center;
            height: 124px;
            justify-content: center;
            
            .loader{
                background-image: url("https://assets.codingdojo.com/learn_platform/admin/global/table_loader.gif");
                background-position: initial;
                background-size: cover;
                height: 20px;
                width: 20px;
            }
        }


        #assignment_breadcrumb_navigation{
            cursor: pointer;
            display: flex;
            margin-bottom: 12px;
            max-width: 85%;

            li{
                color: #E5E5E5;
                font-family: $Poppins;
                font-size: 12px;
                font-style: normal;
                font-weight: 300;
                line-height: 130%;
                position: relative;

                &:not(:last-child){
                    margin-right: 20px;
                    
                    &:after{
                        -webkit-transform: rotate(-45deg);   
                        border: solid #E5E5E5;
                        border-width: 0 1.5px 1.5px 0;
                        content: "";
                        display: inline-block;
                        padding: 1.5px;
                        position: absolute;              
                        right: -10px;
                        top: 5px;
                        transform: rotate(-45deg);
                        vertical-align: middle;   
                    }
                }
            }
        }

        >span{
            color: #E5E5E5;
            font-family: $Poppins;
            font-size: 12px;
            font-style: normal;
            font-weight: 300;
            line-height: 130%;
        }

        >p{
            color: #E5E5E5;
            font-family: $Poppins;
            font-size: 12px;
            font-style: normal;
            font-weight: 300;
            line-height: 130%;
            margin-bottom: 0px;
            width: 100%;
        }

        #uploaded_assignment_details{
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            margin-bottom: 31px;
            width: 100%;

            a, button{
                color: #81A0EA;
                font-family: $Poppins;
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: 130%;
                margin-bottom: 15px;
                outline: none;
                padding: 0px;
                text-align: left;
                width: 100%;

                svg{
                    margin-left: 5px;
                }
            }
            button{
                background-color: transparent;
                border: none;
                outline: none;
                padding: 0px;
                text-align: left;
            }
            p{
                color: #E5E5E5;
                font-family: $Poppins;
                font-size: 12px;
                font-style: normal;
                font-weight: 300;
                margin-bottom: 0px;

                span{
                    font-weight: 500;
                    margin-left: 4px;
                }
            }

            .react-dropdown-select{
                background-color: transparent;
                min-height: auto !important;
                outline: none;
                padding: 0px !important;
                

                &.on-time{
                    .react-dropdown-select-content{
                        span{
                            color: #05DA80;
                        }
                    }
                }

                &.out-of-schedule{
                    .react-dropdown-select-content{
                        span{
                            color: #F59D6B;
                        }
                    }
                }

                &.need-review{
                    .react-dropdown-select-content{
                        span{
                            color: #F56B6B;
                        }
                    }
                }

                .react-dropdown-select-content{
                    span{
                        font-family: $Poppins;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 500;
                    }
                }
                .react-dropdown-select-dropdown-handle{
                    color: #E5E5E5;
                    display: flex;
                    margin: 0px 0px 0px 3px;
                    svg{
                        height: 12px;
                        width: 12px;
                    }
                }

                .react-dropdown-select-dropdown {
                    background: #5E6065;
                    border-radius: 4px;
                    left: -47px;
                    min-width: 144px;
                    padding: 10px 20px;
                    top: 20px;
                    width: 144px;

                    span{
                        background-color: transparent !important;
                        border: none;
                        color: rgba(255, 255, 255, 0.58);
                        font-family: $Poppins;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: normal;
                        line-height: 130%;
                        padding: 0px;

                        &:not(:last-child){
                            margin-bottom: 10px;
                        }

                        &.react-dropdown-select-item-selected{
                            color: #81A0EA;
                            font-weight: 600;
                        }
                    }
                }
            }
        }

        #view_assignment_checklist_button_container{
            border-top: 1px solid #666666;
            bottom: 8px;
            left: 0;
            position: absolute;
            text-align: center;
            width: 100%;

            button{
                background-color: transparent;
                border: none;
                color: #81A0EA;
                font-family: $Poppins;
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                margin-top: 8px;
                outline: none;
            }
        }
    }
}