#mobile_side_nav_backdrop.show{
    background-color: rgba(0, 0, 0, .7);
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
}
#mobile_side_nav{
    background: #EFF4FF;
    border-radius: 0px 16px 16px 0px;
    bottom: 0;
    left: -100%;
    max-width: 377px;
    overflow: auto;
    padding: 24px 12px 0px 20px;
    position: fixed;
    top: 0;
    transition: all ease-out .3s;
    width: 88%;
    z-index: 11;

    &.show{
        left: 0;
    }

    #default_logo{
        height: 24px;
        margin-bottom: 30px;
    }
    
    .nav-item{
        margin-bottom: 37px;
        padding: 5px 10px;
        &.disabled{
            opacity: 1;
        }
        a#basic-nav-dropdown{
            padding: 3px 0px 0px 0px;
            &::after{
                display: none;
            }
            span{
                color: #2c6bff;
                display: inline-block;
                font: normal 600 14px "Poppins", sans-serif;
                padding-right: 29px;
                position: relative;
                white-space: initial;
                width: 100%;
                svg{
                    color: #2c6bff;
                    font-size: 15px;
                    position: absolute;
                    right: 0px;
                    top: 2px;
                }
            }
        }
        div.dropdown-menu{
            border-radius: 6px;
            margin: 0px -1px -0px -1px !important;
            max-height: 340px;
            min-width: -webkit-fill-available;
            overflow-x: hidden;
            overflow-y: auto;
            padding: 6px 25px 7px 0px;
            button, span{
                background-color: transparent !important;
                border: none;
                color: #152C61;
                font: normal 500 14px/24px "Poppins", sans-serif;
                padding: 5px 10px;
                text-align: start;

                &.active{
                    color: #2c6bff;
                }
            }
        }
    }

    #dashboard_nav, #dashboard_sub_nav{
        margin-bottom: 10px;
        padding: 0 0 7px 0;
        position: relative;

        li{
            list-style: none;
            a{
                display: block;
                text-decoration: none;
            }
            button, a{
                background-color: transparent;
                border: none;
                color: #152C61;
                font: 600 14px "Poppins", sans-serif;
                letter-spacing: 0.1px;
                padding: 18px 0px 18px 51px;
                position: relative;
                text-align: left;
                width: 100%;

                &:focus{
                    outline: 5px transparent;
                }

                &.active{
                    background-color: #DFE9FF;
                    border-radius: 50px;
                }

                &::before{
                    background: url("https://assets.codingdojo.com/learn_platform/user/dashboard/dashboard_redesign_icons_mobile.png") no-repeat 0px 0px;
                    content: "";
                    left: 17px;
                    position: absolute;
                    top: 19px;
                }

                &.my_class::before{
                    background-position: -118px -8px;
	                height: 18px;
                    width: 21px;
                }

                &.my_program::before{
                    background-position: -179px -8px;
	                height: 18px;
                    top: 20px;
                    width: 24px;
                }

                &.my_stats::before{
                    background-position: -243px -8px;
	                height: 17px;
                    top: 20px;
                    width: 20px;
                }

                &.my_belts::before{
                    background-position: -296px -5px;
	                height: 23px;
                    top: 16px;
                    width: 20px;
                }

                &.learn::before{
                    background-position: -350px -8px;
	                height: 18px;
                    width: 24px;
                }

                &.contact::before{
                    background-position: -410px -6px;
	                height: 22px;
                    top: 17px;
                    width: 22px;
                }
            }
        }

        &::before{
            background-color: #CAC4D0;
            bottom: -2px;
            content: "";
            height: 1px;
            left: 8px;
            position: absolute;
            width: 93%;
        }
    }

    h2{
        color: #152C61;
        font: 600 14px "Poppins", sans-serif;
        letter-spacing: .1px;
        margin: 29px 0px 33px 8px;
    }

    #light_toggle, #dark_toggle{
        background-color: transparent;
        border: none;
        color: #152C61;
        display: block;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: .1px;
        margin-bottom: 33px;
        margin-left: 8px;
        padding-left: 45px;
        position: relative;

        &:focus{
            outline: 5px transparent;
        }
    }

    #light_toggle::before{
        background: url("https://assets.codingdojo.com/learn_platform/user/dashboard/dashboard_redesign_icons_mobile.png") no-repeat -481px -5px;
        content: "";
        height: 24px;
        left: -2px;
        position: absolute;
        top: 0px;
        width: 24px;
    }
    
    #dark_toggle::before{
        background: url("https://assets.codingdojo.com/learn_platform/user/dashboard/dashboard_redesign_icons_mobile.png") no-repeat -541px -6px;
        content: "";
	    height: 23px;
        left: 2px;
        position: absolute;
        top: 0px;
        width: 16px;
    }

    &::-webkit-scrollbar{
        height: 0px;
        width: 0px;
    }
}

.is_dark_mode{
    #mobile_side_nav{
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), #121212;
        h2{
            color: #fff !important;
        }

        a,
        button{
            color: #fff !important;

            &.active{
                background: #333333 !important;
            }

            &::before{
                background: url("https://assets.codingdojo.com/learn_platform/user/dashboard/dashboard_redesign_icons_mobile.png") no-repeat 0px 0px !important;
                content: "";
                left: 17px;
                position: absolute;
                top: 19px;
            }

            &.my_class::before{
                background-position: -148px -8px !important;
                height: 18px;
                width: 22px;
            }

            &.my_program::before{
                background-position: -210px -8px !important;
                height: 18px;
                top: 19px !important;
                width: 22px;
            }

            &.my_stats::before{
                background-position: -269px -8px !important;
                height: 18px;
                top: 21px;
                width: 23px;
            }

            &.my_belts::before{
                background-position: -322px -5px !important;
                height: 20px;
                top: 16px;
                width: 20px;
            }

            &.learn::before{
                background-position: -380px -8px !important;
                height: 18px;
                width: 22px;
            }

            &.contact::before{
                background-position: -437px -6px !important;
                height: 20px;
                top: 18px;
                width: 20px;
            }
        }

        .nav-item{
            a#basic-nav-dropdown{
                span{
                    color: #81A0EA;
                    svg{
                        color: #81A0EA;
                    }
                }
            }
            div.dropdown-menu{
                background: linear-gradient(0deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,.1)),#121212;
                border: 1px solid #666!important;
                box-shadow: 0 2px 5px rgba(0,0,0,.07);
                button, span{
                    opacity: 1;
                    &.active{
                        color: #81A0EA !important;
                    }
                }
            }
        }

        #light_toggle::before{
            background: url("https://assets.codingdojo.com/learn_platform/user/dashboard/dashboard_redesign_icons_mobile.png") no-repeat -508px -1px !important;
            height: 34px;
            top: -5px;
            width: 34px; 
        }
        
        #dark_toggle::before{
            background: url("https://assets.codingdojo.com/learn_platform/user/dashboard/dashboard_redesign_icons_mobile.png") no-repeat -560px -0px !important;
            height: 34px;
            left: -2px;
            top: -6px;
            width: 34px; 
        }
        #dashboard_nav, #dashboard_sub_nav{    
            &::before{
                background-color: #666666;
                bottom: -2px;
                content: "";
                height: 1px;
                left: 8px;
                position: absolute;
                width: 93%;
            }
        }
    }
}