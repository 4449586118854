$Poppins: "Poppins", sans-serif;

#quiz_table_data_component{
    height: 100%;
    overflow: auto;

    #table_loading_container{
        align-items: center;
        background: #545454;
        display: flex;
        flex-direction: column;
        left: 50%;
        margin-left: 0;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 50px;

        div{
            background-image: url("https://assets.codingdojo.com/learn_platform/admin/global/table_loader.gif");
            background-size: cover;
            height: 30px;
            margin-bottom: 7px;
            mix-blend-mode: lighten;
            width: 30px;
        }

        span{
            color: rgba(255, 255, 255, 0.88);
            font: 500 14px/18.2px $Poppins;
        }
    }

    #name_sort_table_head{
        align-items: center;
        display: flex;
        gap: 11px;

        #name_sort_icon{
            display: flex;
            flex-direction: column;
            justify-content: center;

            svg{
                color: #B2B2B2;
                transform: scale(1.2);

                &.light{
                    color: #81A0EA;
                }

                &:first-child{
                    margin-bottom: -1px;
                }
                &:last-child{
                    margin-top: -1px;
                }
            }
        }
    }

    .sticky-table{
        background: #545454;
        height: calc(100% - 55px);
        overflow: auto;
        width: 100%;

        .attempts_container{
            display: flex;
            gap: 8px;

            .attempt_box{
                border-radius: 2px;
                cursor: pointer;
                display: grid;
                font: 400 10px/13px $Poppins;
                height: 16px;
                place-items: center;
                width: 16px;

                &.green{
                    background: #31B980;
                    color: #024629;
                }
                &.yellow{
                    background: #FFC24B;
                    color: #695108;
                }
                &.red{
                    background: #EE3B3B;
                    color: #5B0606;
                }
                &.grey{
                    background: #737373;
                }
            }
        }

        .sticky-table-table{
            background: #545454;
            width: 100%;
        }

        .sticky-table-row{
            background: #3C3C3C;

            .sticky-table-cell{
                background: #545454;
                font: 400 14px/18.2px $Poppins;
                height: 64px;
                vertical-align: middle;
                width: fit-content;

                &:nth-child(1){
                    background: #4B4B4B;
                    cursor: pointer;
                    overflow: hidden;
                    padding-left: 20px;
                    width: 0px;

                    .student_container{
                        display: flex;
                        flex-direction: column;
                        gap: 8px;

                        > p {
                            color: #fff;
                            height: 14px;
                            margin: 0;
                            max-width: 450px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;

                            &.fullname{
                                font: 600 14px/11px $Poppins;
                            }
                            &.email{
                                font: 400 10px/12px $Poppins;
                            }
                        }
                    }
                }
                &:nth-child(2){
                    min-width: 100px;
                    width: 100px;
                }
            }

            &:not(:nth-child(1)){
                background: #545454;
                .sticky-table-cell{
                    padding-top: 16px;
                    &:first-child{
                        border-bottom: 1px solid #565656;
                    }
                    &:nth-child(2){
                        color: #B7B7B7;
                        font: 400 12px/15.6px $Poppins;
                    }
                }
            }

            /* Table Head */
            &:nth-child(1){
                .sticky-table-cell{
                    background: #3C3C3C;
                    color: rgba(255, 255, 255, .6);
                    font: 500 12px/15.6px $Poppins;
                    height: 40px;

                    &:nth-child(1){
                        cursor: pointer;
                        overflow: hidden;
                        padding-left: 20px;
                        width: 0px;
                    }
                    &:nth-child(2){
                        min-width: 100px;
                        width: 100px;
                    }
                }
            }
        }

        &.table_compact{
            .sticky-table-row{
                .sticky-table-cell{
                    &:nth-child(2){
                        width: 100%;
                    }
                }
            }
        }
    }

    #view_by_sort_container{
        display: flex;
        margin-top: 18px;
        width: 100%;

        > button{
            background: none;
            border-color: #2A2A2A;
            border-style: solid;
            border-width: 0 0 1px 0;
            color: #fff;
            font: 400 12px/15.6px $Poppins;
            margin-bottom: 10px;
            outline: none;
            padding: 0 13px 10px 13px;
            position: relative;

            &.active{
                color: #81A0EA;
                font-weight: 600;

                &::before{
                    background: #81A0EA;
                    border-radius: 10px;
                    bottom: -1px;
                    content: "";
                    height: 2px;
                    left: 0;
                    position: absolute;
                    width: 100%;
                }
            }
        }

        .tooltip_info_icon{
            background: transparent url("https://assets.codingdojo.com/learn_platform/admin/student_progress/quizzes_sprite.png") no-repeat -21px -21px;
            height: 14px;
            margin-left: 10px;
            width: 14px;
        }
    }
}

.quiz_attempt_popover{
    background: #434343 !important;
    border: 1px solid #56595D  !important;
    max-height: 220px;
    max-width: 500px !important;
    min-height: 100px;
    min-width: 300px;
    width: fit-content;

    #loading_container{
        background: #434343;
        left: 50%;
        margin-left: 0;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);

        div{
            background-image: url("https://assets.codingdojo.com/learn_platform/admin/global/table_loader.gif");
            background-size: cover;
            height: 30px;
            mix-blend-mode: lighten;
            width: 30px;
        }

        span{
            color: rgba(255, 255, 255, 0.88);
            font: 500 14px/18.2px $Poppins;
        }
    }

    .quiz_attempt_header{
        background: #434343;
        left: 0;
        padding-top: 20px;
        position: sticky;
        top: 0;
    }

    .popover-body{
        max-height: 220px;
        overflow: auto;
        padding: 0 20px 20px;
    }

    .quiz_title{
        align-items: center;
        color: #E5E5E5;
        display: flex;
        font: 300 12px/15.6px $Poppins;
        gap: 10px;
        margin-bottom: 10px;

        > p {
            margin-bottom: 0;
            max-width: 130px;
        }

        .right_icon{
            background: transparent url("https://assets.codingdojo.com/learn_platform/admin/student_progress/quizzes_sprite.png") no-repeat -87px -21px;
            display: block;
            height: 12px;
            width: 12px;
        }
    }

    .list_header{
        border-bottom: 1px solid #666666;
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
        p{
            color: #E5E5E5;
            font: 600 12px/15.6px $Poppins;
            margin-bottom: 10px;

            &:nth-child(1){
                width: 90px;
            }
            &:nth-child(2){
                width: 77px;
            }
            &:nth-child(3){
                width: 105px;
            }
            &:nth-child(4){
                width: 40px;
            }
        }
    }

    .attempt_list{
        color: #E5E5E5;
        font: 300 12px/15.6px $Poppins;
        list-style-type: none;
        margin: 0;
        padding: 0;
        li{
            display: flex;
            justify-content: space-between;

            &:not(:last-of-type){
                margin-bottom: 17px;
            }
            button{
                background: none;
                border: none;
                color: #81A0EA;
                font: 500 12px/15.6px $Poppins;
                outline: none;
                text-align: right;
                width: 42px;
            }
            p{
                margin-bottom: 0;
                &:nth-child(1){
                    width: 90px;
                }
                &:nth-child(2){
                    width: 77px;
                }
                &:nth-child(3){
                    width: 105px;
                }
            }
        }

        .no_attempts{
            margin: 14px 0 0;
            text-align: center;
        }
    }

    &.bs-popover-right .arrow::after{
        border-right-color: #434343;
    }
    &.bs-popover-left .arrow::after{
        border-left-color: #434343;
    }
    &.bs-popover-top .arrow::after{
        border-top-color: #434343;
    }
    &.bs-popover-bottom .arrow::after{
        border-bottom-color: #434343;
    }
}