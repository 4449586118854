$Poppins: 'Poppins', sans-serif;

.program_calendar_dropdown {
    background: rgba(204, 224, 255, 0.14);
    border: 1px solid transparent !important;
    box-shadow: none !important;
    min-height: 32px !important;
    padding: 2px 11px !important;
    &.has_value{
        background: #000000;
        border: 2px solid rgba(154, 194, 255, 0.3) !important;
    }
    &[aria-expanded="true"]{
        .react-dropdown-select-dropdown-handle{
            svg{
                margin-top: -6px;
            }
        }
    }

    .react-dropdown-select-content{
        align-items: center;
        color: rgba(255, 255, 255, 0.88);
        display: flex;
        font-family: $Poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        justify-content: space-between;
    }

    .react-dropdown-select-dropdown-handle{
        position: absolute;
        right: 10px;
        width: 20px;
        svg{
            color: transparent;
            height: 3px;
            margin-left: 6px;
            margin-top: -3px;
            width: 3px;
        }
    }

    .multi_select_indicator{
        font-weight: 600;
        margin: 0px 4px 0px 5px;
    }

    .dropdown_handle_icon{
        background-image: url(./../../../../assets/images/admin/dropdown_icons.png);
        background-position: 0px 0px;
        height: 20px;
        margin-left: 4px;
        width: 20px;

        &.clear{
            background-position: 20px;
        }
    }

    .react-dropdown-select-dropdown{
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.24), rgba(255, 255, 255, 0.24)), #121212;
        border-radius: 2px;
        border: none;
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.07);
        max-height: 391px;
        min-width: 244px;
        top: 33px;
        z-index: 991;

        .dropdown_search_container{
            padding: 20px 20px 20px 20px;
            border-bottom: 1px solid #E5E7EB;
            input{
                background: rgba(204, 224, 255, 0.14);
                border-radius: 4px;
                border: none !important;
                color: #fff;
                font-family: $Poppins;
                font-size: 14px;
                font-style: normal;
                font-weight: normal;
                min-height: 32px;
                outline: none;
                padding: 0px 12px;
                width: 100%;

                &::placeholder {
                    color: #A4B0C0;
                    opacity: 1;
                }
                
                &:-ms-input-placeholder {
                    color: #A4B0C0;
                }
                
                &::-ms-input-placeholder {
                    color: #A4B0C0;
                }
            }
        }

        .dropdown_menu{
            max-height: 327px;
            overflow: auto;
            padding: 24px 26px;
            position: relative;

            .major_stack, .minor_stack{
                &.major_stack{
                    margin-bottom: 30px;
                }
                >span{
                    color: rgba(255, 255, 255, 0.4);
                    display: flex;
                    font-family: $Poppins;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 140%;
                    margin-bottom: 10px;
                }
            }

            .no_results_found{
                align-items: center;
                color: rgba(255, 255, 255, 0.4);
                display: flex;
                font-family: $Poppins;
                font-size: 12px;
                font-style: normal;
                font-weight: normal;
                justify-content: center;
                line-height: 140%;
                padding: 10px 0px;
            }
            .dropdown_item{
                align-items: flex-start;
                color: rgba(255, 255, 255, 0.88);
                display: flex;
                font-family: $Poppins;
                font-size: 14px;
                font-style: normal;
                font-weight: normal;
                justify-content: space-between;
                margin-bottom: 20px;
                min-height: auto;

                &.active{
                    span{
                        color: #81A0EA;
                        font-weight: 500;
                    }
                }

                &:last-child{
                    margin-bottom: 0px;
                }

                input{
                    display: none;
                }

                .checkbox{
                    margin-left: 10px;
                    margin-top: 2px;

                    .fa-check{
                        display: none;
                    }
                    input[type="checkbox"]:checked ~ label{
                        .checkbox_container{
                            background-color: #81A0EA;
                            .fa-check{
                                color: #fff;
                                display: inline;
                                font-size: 10px;
                            }
                        }    
                    }
                    label{
                        align-items: center;
                        cursor: pointer;
                        display: flex;
                        margin-bottom: 0px !important;
                        margin-top: 0px !important;
                        .checkbox_container{ 
                            align-items: center;
                            background: linear-gradient(0deg, rgba(255, 255, 255, 0.14), rgba(255, 255, 255, 0.14)), #121212;
                            border-radius: 4px;
                            cursor: pointer;
                            display: flex;
                            height: 16px;
                            justify-content: center;
                            width: 16px;
                        }
                        >span{
                            color: #DEE5EF;
                            font-family: $Poppins;
                            font-size: 18px;
                            font-style: normal;
                            font-weight: bold;
                            letter-spacing: -0.01em;
                            line-height: 120%;
                        }                        
                    }
                }
            }
        }
    }
}

#instructor_name_tooltip{
    .arrow{
        &:before{
            border-top-color: #121212 !important;
        }
    }
    .tooltip-inner{
        background: #121212;
    }   
}