$Poppins: 'Poppins', sans-serif;

#header_container{
    align-items: center;
    background-color: #121212;
    border-bottom: 1px solid #2A2A2A;
    display: flex;
    height: 61px;
    justify-content: space-between;
    padding-bottom: 20px;
    width: 100%;
    
    form{
        display: flex;
        position: relative;
        p{
            color: #B2B2B2;
            display: none;
            font-family: "Poppins", sans-serif;
            font-size: 12px;
            line-height: 14px;
            margin:6px 0px 0px 10px;
            width: 320px;
        }
        svg{
            color: #73839C;
            font-size: 14px;
            left: 12px;
            margin-right: 10px;
            position: absolute;
            top: 12px;
            z-index: 2;
        }
        input{
            align-items: center;
            background: linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), #121212;
            border-radius: 4px;
            border: 1px solid transparent;
            color: rgba(255, 255, 255, 0.88);
            font-family: $Poppins;
            font-size: 12px;
            font-style: normal;
            font-weight: normal;
            height: 40px;
            line-height: 140%;
            max-width: calc(100vw - 1093px);
            min-width: 256px;
            padding: 0px 12px 0px 36px;
            position: relative;
            &.border_red{
                border: 1px solid transparent !important;
                &:focus{
                    border: 1px solid #E1554C !important;
                    box-shadow: 0px 0px 6px #E85353;
                }
            }

            &:focus{
                background: #2A2A2A;
                border: 1px solid #81A0EA;
                box-shadow: 0px 0px 6px #81A0EA;
                outline: none;
            }

            &:focus + p{
                display: block;
            }
        }
    }

    .right_container{
        align-items: center;
        display: flex;

        #workspace_dropdown{
            background: #121212;
            margin-right: 30px;

            .dropdown-toggle{
                font-size: 14px;
                padding: 0px;
            }
        }

        >button{
            background-color: transparent;
            border: none;
            color: rgba(255, 255, 255, 0.88);
            font-family: $Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: normal;
            line-height: 140%;
            margin-right: 10px;
            outline: none;
            padding: 0px;
        }
        
        #admin_profile{
            align-items: center;
            display: flex;
            #user_level{
                color: rgba(255, 255, 255, 0.88);
                font-weight: 400;
                line-height: 29px;
                margin-right: 30px;
            }
            span{
                color: rgba(255, 255, 255, 0.88);
                font-family: $Poppins;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 130%;
                margin-right: 16px; 
        
            }
            .name{
                display: inline-block;
                margin-right: 6px;
                max-width: 98px;
                overflow: hidden;
                text-overflow: ellipsis;
                vertical-align: top;
                white-space: nowrap;
            }

            img{
                border-radius: 22px;
                border: 1px solid #293854;
                box-sizing: border-box;
                cursor: pointer;
                height: 32px;   
                width: 32px;
                &[aria-describedby="user_profile_popover"]{
                    filter: brightness(0.7);
                }
            }
        }
    }
}