$Poppins: 'Poppins', sans-serif;

#change_right_answer_modal{
    max-width: 624px;
    width: 624px;

    .modal-content{
        background-color: #121212;
        border: none;

        .modal-header{
            border-bottom: 1px solid #DEE5EF;
            padding: 15px 24px 13px 24px;
            
            .modal-title{
                color: #DEE5EF;
                font-family: $Poppins;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 140%;
            }

            button{
                background-color: transparent;
                border: none;
                outline: none;
                padding: 0px;
                position: absolute;
                right: 24px;
                top: 12px;
            }
        }

        .modal-body{
            padding: 21px 24px 16px 24px;
            
            p{
                color: #DEE5EF;
                font-family: $Poppins;
                font-size: 14px;
                font-style: normal;
                font-weight: normal;
                line-height: 140%;
                margin-bottom: 0px;
            }
        }

        .modal-footer{
            border: none;
            justify-content: flex-start;
            padding: 0px 20px 18px 20px;

            button{
                align-items: center;
                border: none;
                display: flex;
                font-family: $Poppins;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                height: 40px;
                justify-content: center;
                line-height: 130%;
                width: 90px;
                &.btn-default{
                    background: rgba(196, 196, 196, 0.1);
                    color: #DEE5EF;
                }

                &.btn-primary{
                    background: rgba(131, 179, 252, 0.26);
                    color: #81A0EA;
                    margin-left: 14px;
                }
            }
        }
    }
}