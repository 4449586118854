#late_assignment_submission_modal{
    width: 417px;
    .modal-content{
        border: none;
        height: 100%;
        .modal-body{
            color: #152C61;
            font-family: 'Poppins', sans-serif;
            font-size: 12px;
            font-weight: 400;
            padding: 17px 28px 20px;
            p{
                margin-bottom: 18px;
            }
            button{
                background: #2C6BFF;
                border-radius: 6px;
                border: none;
                color: #fff;
                display: block;
                font-size: 18px;
                margin: 0px auto;
                padding: 10px 38px;
            }
        }
    }
}