#quiz_summary_table_container{
    background: #fff;
    border-radius: 6px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
    height: 100%;
    min-height: 570px;
    padding: 28px 24px 40px;
    position: relative;
    h3{
        display: inline-block;
        font: normal 500 16px/24px 'Poppins', sans-serif;
        margin-bottom: 24px;
        margin-right: 22px;
        vertical-align: top;
    }
    table{
        tr{
            th, td{
                &:nth-child(1){
                    width: 396px;
                }
                &:nth-child(2){
                    width: 161px;
                }
                &:nth-child(3){
                    width: 209px;
                }
                &:nth-child(4){
                    width: 180px;
                }
                &:nth-child(5){
                    width: 158px;
                }
            }
            td{
                &:nth-child(1){
                    padding-left: 17px;
                }
                svg{
                    color: rgba(0, 0, 0, 0.60) !important;
                    margin-right: 16px;
                    &.past_attempts_open{
                        transform: rotate(90deg);
                    }
                }
            }
        }
        .with_past_attempts_container{
            td:first-child{
                cursor: pointer;
                &.no_past_attempts{
                    cursor: unset;
                    svg{
                        visibility: hidden;
                    }
                }
            }
            &:last-child{
                td{
                    border-bottom: 1px solid #d6d6d6 !important;
                }
            }
            &.with_past_attempts_container_open{
                td{
                    border-bottom: 1px solid rgba(51, 51, 51, 0.1) !important;
                    position: relative;
                    &:nth-child(1){
                        border-bottom: none !important;
                        &::after {
                            border-bottom: 1px solid rgba(51, 51, 51, 0.1);
                            bottom: 0;
                            content: '';
                            position: absolute;
                            right: 0; 
                            width: calc(100% - 36px); 
                        }
                    }
                }
            }
        }
        .past_attempts_container{
            td{
                border-bottom: 1px solid rgba(51, 51, 51, 0.1);
                width: 127px !important;
                &:nth-child(1){
                    border-bottom: none !important;
                }
            }
            &.past_attempts_container_open{
                td{
                    border-bottom: 1px solid #d6d6d6 !important;
                    &.attempt_column{
                        &::after{
                            border-bottom: none;
                        }
                    }
                }
            }
        }
        .attempt_column{
            border-bottom: none;
            position: relative;
            span{
                position: absolute;
                right: 144px;
                top: 10px;
            }
            &::after {
                border-bottom: 1px solid rgba(51, 51, 51, 0.1);
                bottom: 0;
                content: '';
                position: absolute;
                right: 0; 
                width: calc(100% - 36px); 
            }
        }
    }
    #pagination_btns_container{
        bottom: 15px;
        left: 50%;
        position: absolute;
        transform: translateX(-50%);
        ul{
            padding-block: 4px !important;
            li{
                button{
                    line-height: 21px !important;
                }
            }
        }
    }
}

.is_dark_mode{
    #quiz_summary_table_container {
        table{
            tr{
                td{
                    svg{
                        color: #81A0EA !important;
                    }
                    button{
                        color: #81A0EA !important;
                    }
                }
            }
            .with_past_attempts_container{
                &.with_past_attempts_container_open{
                    td{
                        border-bottom: 1px solid rgba(255, 255, 255, 0.1) !important;
                        &:nth-child(1){
                            border-bottom: none !important;
                            &::after {
                                border-bottom: 1px solid rgba(255, 255, 255, 0.1);
                            }
                        }
                    }
                }
            }
            .past_attempts_container{
                td{
                    border-bottom: 1px solid rgba(255, 255, 255, 0.1);

                }
                &:last-child{
                    td{
                        border-bottom: 1px solid rgba(255, 255, 255, 0.1) !important;
                        &:first-child {
                                border-bottom: none !important;
                        }
                    }
                }
            }
            .attempt_column{
                &::after {
                    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
                }
            }
        }
    }
}

@media screen and (max-width: 768px) {
    #quiz_summary_table_container {
        table {
            .attempt_column {
                span {
                    right: 95px;
                    top: 10px;
                }
            }
        }
    }
}