.logout_modal{
    .modal-dialog{
        max-width: 791px;
        width: 791px;
        .modal-content{
            background: #FFFFFF;
            border-radius: 6px;
            box-shadow: 0px 4px 2px rgba(0, 0, 0, 0.05);
            .modal-header{
                border: none;
                justify-content: center;
                padding: 34px 40px;
                position: relative;
                .modal-title{
                    color: #152C61;
                    font-family: 'Poppins', sans-serif;
                    font-size: 36px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 54px;
                }
            }
            .modal-body{
                padding: 5px 0px 30px 0px;
                text-align: center;
                p{
                    color: #152C61;
                    font-family: 'Poppins', sans-serif;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: normal;
                    line-height: 24px;
                    margin: 0px auto 5px auto;
                    text-align: center;
                    width: 435px;
                    &#extend_eaa_description{
                        width: 485px;
                    }
                    >span{
                        color: #A93333;
                    }
                    a{
                        color: #152C61;

                        span{
                            color: #2C6BFF;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 768px){
    .logout_modal{
        .modal-dialog{
            .modal-content{
                .modal-body{
                    p{
                        width: 100%;
                    }
                }
            }
        }
    }
}