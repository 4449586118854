$Poppins: 'Poppins', sans-serif;

#no_results_found{
   margin-top: 100px;
}

#live_lecture_table{
    background: #333333;
    #table_loading_container{
        margin-top: 100px;
        background: transparent;
    }
    .sticky-table-table{
        width: 100%;
        .sticky-table-row{
            .sticky-table-cell{
                &:nth-child(1){
                    background-color: red;
                    max-width: 364px;
                    > span {
                        display: block;
                        font: 500 normal 12px/1.5 $Poppins !important;
                        max-width: 364px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;

                        .arrow::after{
                            border-right-color: #4b4b4b !important;
                        }
                    }
                }
            }
            .sticky-table-cell{
                vertical-align: middle;
                &:nth-child(4){
                    text-align: left;
                }
            }
            &.record_row {
                .sticky-table-cell{
                    &:nth-child(3), &:nth-child(4), &:nth-child(5), &:nth-child(6), &:nth-child(7){
                        text-align: left;
                    }
                }
            }
            &:nth-child(1){
                height: 40px;
                .sticky-table-cell{
                    background: linear-gradient(0deg, rgba(255, 255, 255, 0.24), rgba(255, 255, 255, 0.24)), #121212;
                    color: #F6F9FD;
                    cursor: pointer;
                    font-family: $Poppins;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 130%;
                    &:first-child{
                        padding-left: 60px;
                    }
                    &:last-child{
                        cursor: default;
                    }

                    .fa-caret-down, .fa-caret-up{
                        color: #fff;
                        margin-left: 6px;
                        &.light{
                            color: rgba(255, 255, 255, 0.4);
                        }
                    }
                }
            }

            &:nth-child(n+2){
                .sticky-table-cell{
                    background: linear-gradient(0deg, rgba(255, 255, 255, 0.14), rgba(255, 255, 255, 0.14)), #121212;
                    color: rgba(255, 255, 255, 0.88);
                    font-family: $Poppins;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    .action_btn{
                        background-color: transparent;
                        background-image: url("https://codingdojo_assets.s3.us-east-1.amazonaws.com/learn_platform/admin/global/admin_cta_icons.svg");
                        background-position: 2px 0px;                    
                        border: none;
                        height: 32px;
                        width: 10px;
                    }
                    .live{
                        background: rgba(84, 204, 176, 0.4);
                        border-radius: 4px;
                        color: #05DA80;
                        font-weight: 600;
                        padding: 2px 6px;
                    }
                    .active{
                        background: #81a0ea42;
                        border-radius: 4px;
                        color: #81a0ea;
                        font-weight: 600;
                        padding: 2px 6px;
                    }
                    .disabled{
                        background: rgba(217, 75, 75, 0.4);
                        border-radius: 4px;
                        color: #F56B6B;
                        font-weight: 600;
                        opacity: 1;
                        padding: 2px 6px;
                        pointer-events: unset;
                    }
                    .inactive{
                        background: rgba(204, 224, 255, 0.14);
                        border-radius: 4px;
                        color: #B3B3B3;
                        font-weight: 600;
                        padding: 2px 6px;
                    }
                    .record{
                        background: rgba(255, 255, 255, 0.18);
                        border-radius: 4px;
                        color: rgba(255, 255, 255, 0.88);
                        font-weight: 600;
                        padding: 2px 6px;
                    }
                    &:first-child{
                        font-size: 14px;
                        padding-left: 60px;
                        span{
                            display: block;
                            font-size: 10px;
                            font-weight: 400;
                        }
                    }
                    &:last-child{
                        padding-left: 30px;
                        button{
                            outline: none;
                        }
                    }
                }
            }
        }
    }
}