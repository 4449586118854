$Poppins: 'Poppins', sans-serif;
$blue: #81A0EA;
$white: rgba(255, 255, 255, 0.88);

div.course_schedule{
    background: dodgerblue;
    border-radius: 2px;
    color: rgba(255, 255, 255, 0.88);
    cursor: pointer;
    display: flex;
    font: normal 400 10px/140% 'Poppins';
    margin-bottom: 1px;
    overflow: hidden;
    padding: 9px !important;
    position: relative;
    &.is_newly_added_or_updated{
        border: 1px solid #e6db74!important;
        box-shadow: 0 0 2px #e6db74, inset 0 0 2px #e6db74;
    }
    img{
        border-radius: 100%;
        height: 14px;
        margin-right: 9px; 
        vertical-align: middle;
        width: 14px;
    }
    span:nth-child(1){
        margin-right: auto;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    span:nth-child(3){
        overflow: hidden;
        text-overflow: ellipsis; 
        white-space: nowrap;
    }
}