$Poppins: "Poppins", sans-serif;

.update_attendance_popover.popover{
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.24), rgba(255, 255, 255, 0.24)), #121212;
    border-radius: 4px;
    border: none;
    min-width: 317px;
    
    &.behind{
        z-index: 0;
    }

    &[x-placement^=top]>.arrow::after, >.arrow::after{
        border-top-color: #4a4a4a;
        border-bottom-color: #4a4a4a;
    }

    .hidden{
        display: none !important;
    }

    .popover-body{
        padding: 20px;

        #attendance_details_container{
            display: flex;

            &.none_attendance, &.--_attendance{
                button{
                    background: rgba(204, 224, 255, 0.14);
                    color: rgba(255, 255, 255, 0.88);   
                }   
                >span{
                    color: rgba(255, 255, 255, 0.88);
                }
            }
            &.present_attendance{
                button{
                    background: rgba(84, 204, 176, 0.4);  
                    color: #05DA80;       
                }   
                >span{
                    color: #05DA80;      
                }
            }

            &.late_attendance{
                button{
                    background: rgba(255, 198, 86, 0.4);  
                    color: #FFC656;         
                }    
                >span{
                    color: #FFC656;    
                }                               
            }

            &.absent_attendance{
                button{
                    background: rgba(217, 75, 75, 0.4);
                    color: #F56B6B;       
                }
                >span{
                    color: #F56B6B;  
                }               
            }

            &.excused_attendance{
                button{
                    background: rgba(252, 187, 230, 0.3);
                    color: rgba(255, 188, 232, 0.88);       
                }   
                >span{
                    color: rgba(255, 188, 232, 0.88);       
                }            
            }

            button{
                background: rgba(84, 204, 176, 0.4);
                border-radius: 4px;
                border: none;
                color: #05DA80;
                cursor: pointer;
                font-family: $Poppins;
                font-size: 20px;
                font-style: normal;
                font-weight: 600;
                height: 40px;
                line-height: 130%;
                outline: none;
                position: relative;
                width: 40px;

                svg{
                    bottom: 4px;
                    color: #fff;
                    font-size: 12px;
                    position: absolute;
                    right: 4px;
                }
            }
            div{
                flex: 1;
                margin-left: 12px;

                h5{
                    color: rgba(255, 255, 255, 0.88);
                    font-family: $Poppins;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 130%;
                    margin-bottom: 0px;
                }

                span{
                    color: rgba(255, 255, 255, 0.58);
                    font-family: $Poppins;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: normal;
                    line-height: 130%;
                }
            }

            >span{
                color: #05DA80;
                font-family: $Poppins;
                font-size: 12px;
                font-style: normal;
                font-weight: 600;
                line-height: 130%;
                max-width: 65px;
                position: absolute;
                right: 20px;
                text-align: right;
                text-transform: capitalize;
            }
        }

        #attendance_status_container{
            align-items: center;
            display: flex;
            margin-top: 20px;

            label{
                margin-bottom: 0px;
                margin-right: 20px;

                input{
                    display: none;
                }

                input:checked ~ span{
                    border: 1px solid #81A0EA;
                }
                
                span{
                    align-items: center;
                    background: rgba(204, 224, 255, 0.14);
                    border-radius: 4px;
                    cursor: pointer;
                    display: flex;
                    font-family: $Poppins;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 600;
                    height: 28px;
                    justify-content: center;
                    line-height: 130%;
                    width: 28px;    

                    &.cd_green{
                        background: rgba(84, 204, 176, 0.4);  
                        color: #05DA80;                      
                    }

                    &.cd_yellow{
                        background: rgba(255, 198, 86, 0.4);  
                        color: #FFC656;                      
                    }

                    &.cd_red{
                        background: rgba(217, 75, 75, 0.4);
                        color: #F56B6B;
                    }

                    &.cd_violet{
                        background: rgba(252, 187, 230, 0.3);
                        color: rgba(255, 188, 232, 0.88);
                    }
                }
            }
        }
    }
}