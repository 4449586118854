$blue: #81A0EA;
$Poppins: 'Poppins', sans-serif;
$white: #fff;

#admin_student_access_container{
    #student_access_right_container{
        #table_loading_container{
            align-items: center;
            background: linear-gradient(0deg, rgba(255, 255, 255, 0.14), rgba(255, 255, 255, 0.14)), #121212;
            display: flex;
            flex-direction: column;
            left: 50%;
            padding: 155px;
            position: sticky;
            top: 35%;
            transform: translate(-50%, -4%);
            width: 50px;
            div{
                background-image: url("https://assets.codingdojo.com/learn_platform/admin/global/table_loader.gif");
                background-size: cover;
                height: 30px;
                margin-bottom: 7px;
                mix-blend-mode: lighten;
                width: 30px;
            }
            span{
                color: rgba(255, 255, 255, 0.88);
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 130%;
            }
        }
    }
}

#admin_student_access_container{
    background-color: #121212;
    display: flex;
    height: 100vh;

    #student_access_right_container{
        display: flex;
        flex-direction: column;
        flex: 1;
        overflow: hidden;
        padding: 12px 30px 20px 30px;
        position: relative;

        #table_loading_container{
            align-items: center;
            background: #121212;
            display: flex;
            flex-direction: column;
            height: 100%;
            justify-content: center;
            left: 50%;
            padding: 155px 155px 195px;
            position: sticky;
            top: 35%;
            transform: translate(-50%, -4%);
            width: 50px;
            div{
                background-image: url("https://assets.codingdojo.com/learn_platform/admin/global/table_loader.gif");
                background-size: cover;
                height: 30px;
                margin-bottom: 7px;
                mix-blend-mode: lighten;
                width: 30px;
            }
            span{
                color: rgba(255, 255, 255, 0.88);
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 130%;
            }
        }

        .release_notes_banner{
            background-color: transparent;
            border-radius: 5px;
            border: 1px solid #81A0EA;
            box-sizing: border-box;
            margin: 16px 0px 0px 0px;
            padding: 14px 20px;

            p{
                color: #fff;
                font-size: 12px;
                svg, b{
                    color: #81A0EA;
                }
            }
            button{
                align-items: center;
                background-color: #81A0EA;
                display: flex;
                font-size: 12px;
                font-weight: 500;
                height: 28px;
                justify-content: center;
                margin-right: 0px;
            }
        }

        #table_tools_container{
            align-items: flex-end;
            display: flex;
            justify-content: space-between;
            
            #other_tools_container{
                align-items: center;
                display: flex;
                margin-bottom: 14px;

                button, a{
                    align-items: center;
                    background: rgba(204, 224, 255, 0.14);
                    border-radius: 2px;
                    border: none;
                    display: flex;
                    height: 32px;
                    justify-content: center;
                    margin-left: 12px;
                    outline: none;
                    width: 38px;
                    
                    svg{
                        color: #81A0EA;
                    }

                    span{
                        display: block;
                        height: 24px;
                        width: 24px;
                        background: url("https://assets.codingdojo.com/learn_platform/admin/student_access/student_access_header_icons.svg") no-repeat;

                        &.download_csv_icon{
                            background-position: -62px 0px;
                        }

                        &.create_bulk_student_access_icon{
                            background-position: -30px 0px;
                        }
                        
                        &.create_student_access_icon{
                            background-position: 0px 0px;
                        }
                    }
                }
            }
        }

        #program_filter_note{
            align-items: flex-end;
            color: #B3B3B3;
            display: flex;
            font-family: $Poppins;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 130%;
        }
    }
}