aside.left_container.online_discussion_sidebar{
    .accordion{
        .card{
            .collapse, .collapsing{
                .card-body{
                    ul{
                        li{
                            &.online_discussion {
                                .icon_container{
                                    .module_icon{
                                        background-position-x: -105px;
                                    }
                                }
                                &.completed{
                                    .has_discussion{
                                        padding-right: 0px;
                                    }
                                } 
                            }
                            a.has_discussion{
                                padding-right: 0px;
                            }
                            .discussion_count{
                                background: #FFF1C7;
                                border-radius: 6px;
                                color: #F2994A;
                                font-family: 'Poppins', sans-serif;
                                font-size: 16px;
                                font-style: normal;
                                font-weight: 600;
                                position: absolute;
                                right: 0;
                                text-align: center;
                                vertical-align: middle;
                                width: 67px;
                            }
                        }
                    }
                }
            }
        }
    }
}